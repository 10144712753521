<script>
  import debounce from 'lodash/debounce';
  import get from 'lodash/get';
  import Header from 'root/components/Header.svelte';
  import Navigation from 'root/components/Navigation.svelte';
  import PropertiesSearch from "../../../routes/AdvancedHotelSearch";

  import ResultsTable from './ResultsTableWithHeader.component.svelte';
  import CheckBox from './Checkbox.component.svelte';
  import FiltersBar from './filters/SearchFilters.component.svelte';
  import Paginator from './Paginator.component.svelte';
  import Select from 'components/molecules/inputs/Select.svelte';
  import Actions from './actions/Actions.component.svelte';
  import Hotel from './Hotel.component.svelte';
  import ExportMenu from 'src/pages/hotels/advanced-search/ExportMenu.svelte';

  import {loadFilterTypes, searchHotels, loadHMCs} from './search.service';
  import {loadActions} from './actions.service';
  import {DateFormatter} from 'root/utils/internationalization.service.js';


  let hotelManagemetCompanies = [];
  const {params: {collection}} = PropertiesSearch.$createStateFromURL(window.location.hash);

  const dateFormatter = DateFormatter();

  const filterTypes = loadHMCs().then(companies => {
    hotelManagemetCompanies = companies.sort((a, b) => a.name > b.name? 1: -1);
    return loadFilterTypes(companies);
  }),
    isShowingUnvalidated = collection === 'UnvalidatedHotel',
    isShowingValidated = collection === 'Hotel',
    tableConfig = {
      selectColumn: { width: '50px' },
      imageColumn: { width: '85px' },
      columns: [
        {label: 'Property Name', key: 'name', width: '20%'},
        {
          label: 'Type',
          key: hotel => ({ value: hotel.requestType === 'REMOVE' ? 'REMOVE/ REBRAND' : hotel.requestType }),
          width: '100px',
          hidden: isShowingValidated
        },
        {label: 'Property Address', key:'location.fullAddress', width: '25%'},
        {label: 'Primary', key: 'contact.emailAddress', width: '25%', hidden: isShowingUnvalidated},
        {label: 'Phone Number', key: 'phone', width: '150px', hidden: isShowingValidated},
        {label: 'Sabre Code', key: 'sabreCode', width: '50px'},
        {label: 'Brand', key: 'chain.name', width: '100px'},
        {label: 'Chain', key: 'chain.masterChain.name', width: '100px'},
        {label: 'HMC', key: 'hotelManagementCompany.name', width: '100px', hidden: isShowingUnvalidated },
        {label: 'DTP Bids', key:'bids.DIRECT.count', width: '50px', hidden: isShowingUnvalidated},
        {label: 'Chain Bids', key:'bids.CHAIN.count', width: '50px', hidden: isShowingUnvalidated},
        {label: 'Comp Bids', key:'bids.COMPETITIVE.count', width: '50px', hidden: isShowingUnvalidated},
        {label: 'Requested at', key: hotel => ({value: dateFormatter.format(get(hotel, 'requested.at', get(hotel, 'created.at', '')))}) , width: '120px', hidden: isShowingValidated},
        {label: 'Requested by', key: hotel => {
          const by = get(hotel, 'requested.by', get(hotel, 'created.by', ''));
          return {value: by ? `${by.fullName} <${by.emailAddress}>` : '---'};
          } , width: '25%', hidden: isShowingValidated},
        {label: 'Status', key:'rejectionMessage', width: '100px', hidden: isShowingValidated},
      ].filter(({hidden}) => !hidden)
    };

  let filters = [],
    andFilters = true,
    promise = null,
    currentPage = {
      page: 1,
    },
    selectedList = [],
    loading = false,
    sorter = {
      dir: 'asc',
      by: 'none-asc',
      key: 'none',
    }, largeButtons, onLarge = true,
    results,
    allSelected,
    someSelected,
    sorters,
    listOfSelectedHotels;

  filterTypes.then(types => filters = types.filter(({id}) => ['name', 'address', 'sabreCode', 'city', 'state', 'country'].includes(id)))

  $: results = currentPage.data || [];
  $: allSelected = selectedList.length > 0 && selectedList.length === results.length;
  $: someSelected = selectedList.length > 0 && selectedList.length < results.length;
  $: sorters = (function() {
    const options = [
      {
        label: 'Hotel Name',
        value: 'name',
      },
      {
        label: 'Address',
        value: 'location.address.address1',
      },
      {
        label: 'City',
        value: 'location.address.city',
      },
      {
        label: 'State/Region',
        value: 'location.address.state',
        key: ({location: {address: {state, region}}}) => {
          return state || region;
        }
      },
      {
        label: 'Country',
        value: 'location.address.country',
      },
      {
        label: 'Primary',
        value: 'contact.emailAddress'
      },
      {
        label: 'Hotel Brand',
        value: 'chain.name',
      },
      {
        label: 'Hotel Chain',
        value: 'chain.masterChain.name',
      },
      {
        label: 'HMC',
        value: 'hotelManagementCompany.name',
      },
      {
        label: 'DTP Bids',
        value: 'bids.DIRECT.count',
      },
      {
        label: 'Chain Bids',
        value: 'bids.CHAIN.count',
      },
      {
        label: 'Comp Bids',
        value: 'bids.COMPETITIVE.count',
      },
      {
        label: 'Requested at',
        key: hotel => new Date(get(hotel, 'requested.at', get(hotel, 'created.at', ''))),
        hidden: isShowingValidated
      },
      {
        label: 'Requested By',
        key: hotel => get(hotel, 'requested.by.fullName', get(hotel, 'created.by.fullName', '')),
        hidden: isShowingValidated
      },
      {
        label: 'Request Type',
        value: 'requestType',
        hidden: isShowingValidated
      },
    ].filter(({hidden}) => !hidden);

    return [
      {
        label: 'None',
        value: 'none',
        key: null,
      },
      ...options.reduce((acc, option) => [...acc, make(option), make(option, false)], [])
    ];

    function make({label, value, key}, asc = true) {
      const dir = asc? 'asc': "desc";
      return {
        label: `${label} - ${dir.toUpperCase()}`,
        value: `${value}-${dir}`,
        key: key || value,
        dir,
      }
    }
  })();
  $: listOfSelectedHotels = selectedList.map(id => results.find(({_id}) => _id === id));
  $: actions = loadActions(listOfSelectedHotels, collection);
  $: pageTitle = collection === "Hotel"? "Advanced": "Unvalidated "

  function clearFilters() {
    filters = [];
  }

  function applyFilters() {
    selectedList = [];
    return search();
  }

  function search(page = 1) {
    loading = true;

    promise = searchHotels(collection, filters, andFilters, page).then((pagination) => {
      currentPage = pagination;
      loading = false;
      responsiveBar();

      return pagination.data;
    });
  }

  function next() {
    search(currentPage.page + 1);
  }

  function prev() {
    search(currentPage.page - 1);
  }

  function responsiveBar() {
    onLarge = true;
    setTimeout(() => {
      onLarge = !largeButtons || largeButtons.offsetLeft <= largeButtons.children[0].offsetLeft;
    }, 100);
  }

  function toggleAllSelect() {
    if(!loading) {
      if(allSelected) {
        selectedList = [];
      }else{
        selectedList = results.map(({_id}) => _id);
      }
    }
  }

  function sort({ detail: by }){
    const option = sorters.find(({value}) => value === by);
    if(option) {
      const key = option.key,
        dir = option.dir;

      sorter = { by, dir, key };
      promise = Promise.resolve(results);
    }
  }

  function actionCompleted({detail: {selected, id, action, response}}) {
    switch(id) {
      case "editImage":
        applyFilters();
        break;
      default:
        break;
    }
  }


  if(collection != "Hotel")
    search();
</script>

<svelte:window on:resize={debounce(responsiveBar, 100)}/>

<div class="rbPage rbDark">
  <Header/>
  <div class="pageContainer">
    <div class="rbCard navigation"><Navigation/></div>

    <div class="content">
      <div class="heading">
        {#if selectedList.length === 0}
          <div class="column page-label">
            <i class="material-icons page-icon">search</i>
            <div class="labels">
              <h1 class="label-title">{ pageTitle } Property Search</h1>
              <p class="label-sub-title">Click on "filters" to see all filter options</p>
            </div>
          </div>
        {:else}
          <div class="column">
            <Actions {actions} selected={listOfSelectedHotels} {hotelManagemetCompanies} {collection} on:completed={actionCompleted}/>
          </div>
        {/if}

        <div class="column buttons" bind:this={largeButtons} class:hide={!onLarge}>
          <div class="sorter">
            <Select id="sort by" options={sorters} value={sorter.by} iconStart="sort" on:input={sort}>Sort By</Select>
          </div>
          <button class="rbButton asDark operator" class:or={!andFilters} data-icon={ !andFilters? 'height': 'link' } on:click={() => andFilters = !andFilters}>Use &lsaquo;{ andFilters? 'AND':'OR' }&rsaquo; Logic</button>
          <button class="rbButton asDark" data-icon="delete_sweep" on:click={clearFilters} disabled={ filters.length === 0 }>Clear All Filters</button>
          <Paginator results={currentPage} on:next={next} on:prev={prev} />

          <ExportMenu />
        </div>
        <div class="column buttons onSmall" class:hide={onLarge}>
          <div class="sorter">
            <Select id="sort by" options={sorters} value={sorter.by} iconStart="sort" on:input={sort}>Sort By</Select>
          </div>
          <button class="rbButton asDark operator iconOnly" class:or={!andFilters} data-icon={ !andFilters? 'height': 'link' } on:click={() => andFilters = !andFilters}></button>
          <button class="rbButton asDark iconOnly" data-icon="delete_sweep" on:click={clearFilters} disabled={ filters.length === 0 }></button>
          <Paginator results={currentPage} on:next={next} on:prev={prev} />

          <ExportMenu />
        </div>
      </div>
      <div class="table-header">
        <CheckBox indeterminate={someSelected} selected={allSelected} on:toggle={toggleAllSelect} />
        {#await filterTypes}
          loading
        {:then data}
          <FiltersBar bind:filters {andFilters} filterTypes={data} let:hasValues>
            <button class="rbButton asDarkMain small searchButton" data-icon="search" on:click={applyFilters} disabled={loading}>Search</button>
          </FiltersBar>
        {/await}
      </div>

      <ResultsTable {promise} {sorter} config="{tableConfig}">
        <div slot="item" let:item>
          <Hotel hotel={item} bind:selectedList config="{tableConfig}" {collection}/>
        </div>
      </ResultsTable>

    </div>
  </div>
</div>

<style type="text/stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: relative;
  z-index: 3;
}
.page-label {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
i.material-icons.page-icon {
  color: #00b8ab;
  height: 40px;
  width: 40px;
  background: #273238;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.labels {
  margin-left: 10px;
}
h1.label-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
p.label-sub-title {
  margin: 0;
  font-size: 10px;
}
.table-header {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #00a995 0%, #006eb9 50%, #00aa4f 100%) 0 1 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}
.operator.or::before {
  transform: rotate(90deg);
}
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.buttons > :global(*) {
  flex-shrink: 0;
}
.sorter {
  display: inline-block;
  min-width: 135px;
  margin-right: 10px;
}
.sorter :global(.rbInput .container) {
  margin-top: 0;
}
.sorter :global(.rbInput .input) {
  min-width: 53px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 53px;
  width: 53px;
  text-overflow: ellipsis;
}
.column.buttons.hide {
  display: none;
}
.column.buttons.onSmall :global(.total) {
  display: none;
}
.small {
  height: 30px;
  font-size: 12px;
}
.small::before {
  font-size: 18px;
}
.searchButton {
  z-index: 1;
}
</style>
