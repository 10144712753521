<script>
  import { tick, createEventDispatcher } from 'svelte';
  import { disableFilter, setFilterValue } from './Filters.store';

  export let filter = {};

  const dispatchEvent = createEventDispatcher();

  let focused;

  async function focusOnInput(){
    await tick();
    const el = document.getElementById(filter.id);
    if(el) el.focus();
  }

  function clearFilter(){
    filter.isEmpty ? disableFilter(filter) : setFilterValue(filter,'');
    dispatchEvent('change');
  }
</script>

<div class="Component"
     class:Focused="{focused}"
     class:Empty="{filter.isEmpty}"
     data-icon-before="add_circle"
     on:click={() => focusOnInput()}
>
  <label for="{filter.id}">{filter.label}</label>

  <span>&nbsp;-&nbsp;</span>

  <input class="input"
         id="{filter.id}"
         placeholder="Keywords here..."
         value="{filter.value}"
         on:input="{e => setFilterValue(filter, e.target.value)}"
         on:focus="{() => focused = true}"
         on:blur="{() => focused = false}"
  />

  <button class="rbButton iconOnlySmall ClearButton"
          data-icon="cancel"
          on:click="{() => clearFilter()}"
  ></button>
</div>

<style lang="stylus">.Component {
  height: 24px;
  background: #313c42;
  border: 1px solid #cfd8dc;
  color: #00a99d;
  font-size: 13px;
  display: flex;
  align-items: center;
  border-radius: 13px;
  padding-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.Component:before {
  width: 0;
  font-size: 18px;
  overflow: hidden;
}
.Component .input {
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: transparent;
  border: 0;
  outline: 0;
  color: #fff;
  font-size: inherit;
  max-width: 160px;
}
.Component .input::placeholder {
  color: #78909c;
}
.Component.Empty {
  color: #cfd8dc;
  padding-left: 0;
}
.Component.Empty:before {
  width: 26px;
}
.Component.Empty .input {
  max-width: 0;
}
.Component.Focused {
  color: #00a99d;
  padding-left: 0;
  border: 1px solid #313c42;
}
.Component.Focused:before {
  width: 26px;
}
.Component.Focused .input {
  max-width: 160px;
}
label {
  cursor: pointer;
}
.ClearButton {
  height: 24px;
  width: 26px;
  border: 0;
  background: transparent;
  opacity: 0.2;
  color: #cfd8dc;
}
.ClearButton:before {
  width: 30px;
  font-size: 18px;
}
.ClearButton:hover,
.ClearButton:focus {
  opacity: 1;
}
</style>
