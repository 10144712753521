import component from './users.module.svelte';
export const USERS = 'users';

export default {
    component,
    id: USERS,
    text: 'Users',
    icon: 'people',
    isAvailable() { return true },
    subItems: [
        {
            id: 'main',
            text: 'Registered Users',
            icon: 'remove_red_eye'
        },
        {
            id: 'invites',
            text: 'Pending Invitations',
            icon: 'remove_red_eye'
        },
    ]
};