<script>
  import DialogTemplate from 'components/modals/DialogTemplate.basic.svelte';
  import {loadSubscribers} from './emailAddressFixServiceApi';
  import {DateFormatter} from 'src/utils/internationalization.service';

  export let cancel;

  const df = DateFormatter()

  let loading = true, subscribers = [];

  loadSubscribers()
    .then(r => {
      subscribers = r.data.map(parseSubscriber);
      loading = false;
    })

  function parseSubscriber(subscriber){
    return {
      accountName: `[${subscriber.account.type}] ${subscriber.account.name}`,
      startOn: df.format(subscriber.startOn),
      startedBy: `${subscriber.startedBy.fullName} <${subscriber.startedBy.emailAddress}>`
    }
  }

</script>

<div class="Component">
  <DialogTemplate cancel="{cancel}" closeOnClick="{false}" wide="{true}">
    <div class="Container" style="min-height: 600px">
      <div class="Title">
        Fix Email Address Service Subscribers
      </div>

      <div class="Content">
        {#if loading}
          <div>Loading ... </div>
        {:else }
          {#each subscribers as s}
            <div class="Row">
              <div>{s.startOn}</div>
              <div>{s.accountName}</div>
              <div>{s.startedBy}</div>
            </div>
          {:else}
            <div>No Subscribers</div>
          {/each}
        {/if}
      </div>
    </div>
  </DialogTemplate>
</div>

<style lang="stylus">.Component :global(.dialog) {
  background: #313c42;
  border-color: #313c42;
  overflow: hidden;
}
.Container {
  font-size: 14px;
  color: #cfd8dc;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
}
.Title {
  padding: 5px 10px;
  padding-bottom: 15px;
  font-size: 13px;
  border-bottom: 1px solid #546e7a;
}
.Content {
  overflow: auto;
  padding: 0 10px;
}
.Row {
  display: grid;
  grid-template-columns: 100px 1fr 1fr;
  height: 36px;
  align-items: center;
}
</style>
