<button class="rbButton {focused ? 'focused' : ''}"
        data-icon="{icon}"
        bind:this={targetElement}
        on:focus="{() => focused = true}"
        on:blur="{() => focused = false}">
  {#if selected}
    <div class="Label">{label}</div>
    <span class="Value">{ selected.label }</span>
  {:else}
    <span class="ValueLabel">{ label }</span>
  {/if}
</button>

{#if focused}
  <SelectModalMenu {targetElement} isDarkStyle="{true}">
    {#each listOfOptions as item}
      <div class="SelectOption"
           class:Selected={ value === item.value }
           on:mousedown|preventDefault
           on:click={() => onSelect(item.value)}>
        <span class="Ellipsis">{item.label}</span>
      </div>
    {/each}
  </SelectModalMenu>
{/if}

<script>
  import { createEventDispatcher } from 'svelte';
  import SelectModalMenu from './SelectModalMenu.svelte';

  export let label, value, options = [], icon = 'sort';

  const dispatch = createEventDispatcher();
  let selected, listOfOptions, focused, targetElement;

  $: listOfOptions = loadOptions(options);
  $: selected = listOfOptions.find( o => o.value === value );

  function loadOptions(options){
    return options.reduce( (acc, o) => {
      acc.push(o.constructor.name === 'Object' ? o : { value: o, label: o });
      return acc;
    }, []);
  }

  function onSelect(value){
    dispatch('change', value);
  }
</script>


<style lang="stylus">.rbButton {
  position: relative;
  border: 1px solid #263238;
  border-radius: 30px;
  background: transparent;
  color: #72828a;
}
.rbButton.focused {
  color: #00a99d;
  border-color: #00a99d;
}
.Label {
  position: absolute;
  top: -8px;
  left: 10px;
  padding: 0 5px;
  font-size: 11px;
  line-height: 1.2;
  background: #313c42;
}
.ValueLabel {
  color: #72828a;
}
.Value {
  color: #fff;
}
.SelectOption {
  font-size: 13px;
  display: flex;
  line-height: 36px;
  padding: 0 10px;
  cursor: pointer;
}
.SelectOption:before {
  margin-right: 10px;
}
.SelectOption:hover {
  background: #313c42;
}
.SelectOption.Selected {
  color: #fff;
  background: #313c42;
}
</style>
