<!--suppress ES6UnusedImports -->
<script>
    import { fade } from 'svelte/transition';
    import { createEventDispatcher } from 'svelte';
    import {FREE_TEXT_FILTER_TYPE, MULTI_SELECT_FILTER_TYPE} from './filter-types';
    import {orderBy} from 'lodash';

    import SelectMenu from 'components/molecules/inputs/Select.menu.svelte';
    import CheckBox from '../Checkbox.component.svelte';

    const dispatch = createEventDispatcher();

    export let filter = { add: true };
    export let types = [];
    let filterText = '', searching = false, dropdownOpen, filteredOptions, hasValue, showInput;

    $: dropdownOpen = filter.open && filter.type !== FREE_TEXT_FILTER_TYPE;
    $: filteredOptions = filterText? filter.options.filter(({label}) => `${label.toLowerCase()}`.includes(filterText.toLowerCase())): filter.options
    $: options = filter.dontSort? filteredOptions: orderBy( filteredOptions, 'label');
    $: hasValue = Array.isArray(filter.value)? filter.value.length > 0: !!filter.value;
    $: value = filter.type === FREE_TEXT_FILTER_TYPE? format(filter.value): filterText;
    $: showInput = filter.type === FREE_TEXT_FILTER_TYPE || searching;
    $: allSelected = filter.value && options.length === filter.value.length;
    $: someSelected = filter.value && filter.value.length > 0 && options.length != filter.value.length;

    function toggle() {
        filter.open = !filter.open;
        searching = filter.open;

        dispatch('closed', filter);
        filter.open && focus();
    }

    function blur() {
        setTimeout(() => {
            searching = false;
        }, 200);
    }

    function input(event) {
        const
          value = event.target.value,
          v = typeof value === 'string' ? value.trim() : value;

        if(filter.type === FREE_TEXT_FILTER_TYPE) {
            filter.value = v;
        } else {
            filterText = v;
        }
    }

    async function addNewType(value) {
        dispatch('add', value);
    }

    function format(v = filter.value) {
        if(Array.isArray(v)) {
            return v.map(format).join(', ');
        }else if(typeof v === 'object') {
            return format(v.label);
        }else{
            return v;
        }
    }

    async function focus(field) {
        field && field.focus && field.focus();
    }

    function openInput() {
        if(filter.type === FREE_TEXT_FILTER_TYPE) {
            toggle();
        }else if(filter.open){
            focus();
        }else{
            toggle();
        }
    }

    function remove() {
        dispatch('remove', filter);
    }

    function closeTextDropdown({key, target}) {
        if (key === 'Enter'){
            target && target.blur();
        }
    }

    function selected(option) {
        return Array.isArray(filter.value)? filter.value.find(({value}) => option.value === value): option.value === filter.value.value;
    }

    function selectAll() {
      filter.value = allSelected? []: [...filter.options];
    }

    function select(option) {
        return filter.type === MULTI_SELECT_FILTER_TYPE && selected(option)? removeOption(): addOption();

        function removeOption() {
            filter.value = filter.value.filter(({value}) => value != option.value);
        }

        function addOption() {
            filter.value = Array.isArray(filter.value)? [...filter.value, option]: option;
            if(filter.type !== MULTI_SELECT_FILTER_TYPE){
                toggle(false);
            }
        }
    }

    function clear() {
        setTimeout(() => {
            if(hasValue) {
                filter.value = filter.type === MULTI_SELECT_FILTER_TYPE? []: '';
                searching = true;
                focus();
                filter.open = true;
            }else{
                remove();
            }
        }, 100);
    }
</script>

<div class="filter" class:add={filter.add} on:click={toggle}>
    {#if dropdownOpen}
        <div class="background"></div>
    {/if}

    {#if filter.add}
        <i class="material-icons add">add_circle</i>
        Add Filter
        <SelectMenu
            items={types}
            menuShown={filter.open}
            onSelect={index => addNewType(types[index])}
        />
    {:else}
        {#if !hasValue}
            <i class="material-icons add">add_circle</i>
        {/if}
        <span class="type" class:active={filter.open || hasValue}>{filter.label} { filter.open || hasValue? '-': ''} </span>
        {#if !showInput}
            <span class="value main" on:click|stopPropagation={openInput}>{format(filter.value)}</span>
        {:else}
            <input
                type="text"
                class="free-text value"
                placeholder="Keywords Here"
                on:click|stopPropagation
                value={value}
                on:input={input}
                use:focus
                on:keydown={closeTextDropdown}
                on:blur={blur}
            />
        {/if}
        <i class="material-icons remove" on:click|stopPropagation={clear}>cancel</i>

        {#if  dropdownOpen}
            {#if filter.type !== FREE_TEXT_FILTER_TYPE}
                <div class="Menu options">
                    {#if filter.type === MULTI_SELECT_FILTER_TYPE}
                      <div class="Item select" on:click|stopPropagation={selectAll} class:ItemSelected={allSelected}>
                          <CheckBox selected={allSelected} indeterminate={someSelected}></CheckBox>
                          <div>All</div>
                      </div>
                    {/if}

                    {#each options as option}
                        <div class="Item select" on:click|stopPropagation={() => select(option)} class:ItemSelected={selected(option)}>
                            {#if filter.type === MULTI_SELECT_FILTER_TYPE}
                                <CheckBox selected={selected(option)} />
                            {/if}
                            <div>{option.label}</div>
                        </div>
                    {/each}
                </div>
            {/if}
        {/if}
    {/if}
</div>

<style lang="stylus">.filter {
  border-radius: 16px;
  border: 1px solid #525b61;
  width: fit-content;
  font-size: 12px;
  align-items: center;
  padding: 2px;
  display: inline-flex;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  margin: 1px;
  height: 27px;
  box-sizing: border-box;
}
.filter.add {
  background: #525b61;
  color: #fff;
  margin-left: 5px;
}
.filter :global(.Menu) {
  top: 25px;
  max-height: 60vh;
}
.filter :global(.Menu .Item) {
  cursor: pointer;
}
.material-icons {
  font-size: 18px;
}
.material-icons.remove {
  color: #cfd8dc;
  opacity: 0.2;
  margin-left: 10px;
  z-index: 1;
}
.filter:hover .material-icons.remove {
  opacity: 1;
}
.material-icons.add {
  margin-right: 5px;
}
.filter:hover .add.material-icons,
.filter:hover .type {
  color: #00b8ab;
  opacity: 1;
}
.filter:hover .remove.material-icons {
  opacity: 1;
}
.filter .free-text {
  height: 33px;
  border: none;
  border-bottom: 1px;
  color: #fff;
  background: transparent;
  outline: none;
}
.type {
  color: #cfd8dc;
  text-transform: capitalize;
  margin-right: 5px;
}
.value {
  color: #fff;
}
.value.main {
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 1;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: transparent;
  z-index: 1;
}
.filter :global(.Menu) {
  min-width: 200px;
}
.Item {
  font-size: 14px;
  line-height: 33px;
  padding: 0 30px 0 15px;
  cursor: pointer;
  text-align: left;
  color: #90a4ae;
  white-space: nowrap;
  background: transparent;
}
.Item:hover {
  background: #313c42;
}
.Menu.options {
  top: 30px;
  position: absolute;
  z-index: 1;
  max-height: 400px;
  overflow: auto;
  background: #263337;
  border-radius: 5px;
}
.Menu.options .Item {
  display: flex;
  align-items: center;
}
.Menu.options .Item :global(.check-box) {
  height: 25px;
}
.active {
  color: #00b8ab;
}
</style>
