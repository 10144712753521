<script>
  import FormModel from 'components/molecules/inputs/FormModel.svelte';
  import NotificationTemplate from 'components/modals/NotificationTemplate.basic.svelte';

  import EditForm from './GdsCodes.form.svelte';
  import {getModelFromObect} from 'root/utils';

  export let hide, cancel, codes, names, id;

  let values = getModelFromObect(codes);
</script>

<NotificationTemplate>
  <FormModel bind:values>
    <div class="container">
      <div class="header">
        <div class="title">
          Change Hotel GDS Codes
        </div>
        <div class="subTitle">
          This will affect future RFPs and how the hotel is displayed to the buyers
        </div>
      </div>

      <EditForm {hide} {cancel} {codes} {names} {id} />
    </div>
  </FormModel>
</NotificationTemplate>

<style lang="stylus">.container {
  padding: 25px 15px 20px;
  color: #b0bec5;
}
.header {
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 14px;
  font-weight: bold;
}
.subTitle {
  font-size: 10px;
  opacity: 0.5;
}
</style>
