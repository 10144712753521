import noop from 'lodash/noop';
import logger from 'root/services/logger';
import modals from 'root/services/modals';
import Notification from 'components/modals/Notification.basic.svelte';
import GeneralError from 'root/routes/GeneralError.js'
import GatewayTimeout from 'root/routes/GatewayTimeout.js'
import SignIn from 'root/routes/SignIn.js'
import Forbidden from 'root/routes/Forbidden.js'
import NotFound from 'root/routes/NotFound.js'

const defaultErrorHandlingMap = {
    '-1': displayCheckConnectionDialog,
    '0': displayCheckConnectionDialog,
    '400': toGenericErrorPage,
    '500': toGenericErrorPage,
    '503': toGenericErrorPage,
    '504': toGatewayTimeoutPage,
    '401': toSignIn,
    '403': toForbidden,
    '404': toNotFound,
    'default': toGenericErrorPage
};

export default {
    handle(httpError, options = {}){
        logger.error(httpError);
        const errorStatus = `${httpError.status}`,
            handler = options[errorStatus] || defaultErrorHandlingMap[errorStatus] || defaultErrorHandlingMap.default;

        return handler(httpError) || Promise.reject(httpError)
    }
}


function displayCheckConnectionDialog(httpError) {
    return modals.show(Notification, {
        type: 'warning',
        subtitle: 'Error',
        title: 'No Connection',
        content: 'Please check your Internet connection.',
        resolveLabel: 'OK'
    }).then(() => Promise.reject(httpError));
}

function toGenericErrorPage(){
    GeneralError.go().catch(noop);
}

function toGatewayTimeoutPage() {
    GatewayTimeout.go().catch(noop);
}

function toSignIn() {
    SignIn.go().catch(noop);
}

function toForbidden() {
    Forbidden.go().catch(noop);
}

function toNotFound() {
    NotFound.go().catch(noop);
}
