<script>
    export let hotel, showActions, active = true;

    function image({image}) {
        image = image && image.url || '';
        return image ? ((image.startsWith('http') ? image : `/images/entities/${image}`)): '/images/hotel_placeholder.png';
    }
</script>

<div class="header">
  <div class="hotel">
    {#if !showActions}
      <img src={image(hotel)} alt={hotel.name} class="logo" />
      <div class="details">
        <div class="top">
          {#if !active}
            <i class="material-icons">verified</i>
            <span class="label">Requested Hotel</span>
          {:else if hotel.active}
            <i class="material-icons">check_circle</i>
            <span class="label">Verified Hotel</span>
          {:else}
            <i class="material-icons">unpublished</i>
            <span class="label">Unverified Hotel</span>
          {/if}
        </div>
        <div class="name">{hotel.name}</div>
      </div>
    {:else}
      <slot name="actions" />
    {/if}
  </div>
  <div class="slot"><slot /></div>
</div>

<style type="text/stylus">.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #00a995 0%, #006eb9 50%, #00aa4f 100%) 0 1 100%;
  height: 98px;
  box-sizing: border-box;
}
.logo {
  height: 69px;
  width: 80px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  margin-right: 5px;
  overflow: hidden;
  position: relative;
}
img.logo[alt]:after {
  padding: 5px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-family: 'Helvetica';
  font-weight: 300;
  line-height: 2;
  text-align: center;
  content: attr(alt);
  font-size: 10px;
  box-sizing: border-box;
}
.hotel {
  display: flex;
  align-items: flex-end;
}
.top {
  display: flex;
  align-items: center;
}
.top i.material-icons {
  height: 18px;
  width: 18px;
  font-size: 18px;
  color: #00a995;
}
.label {
  color: #72828a;
  font-size: 12px;
}
.name {
  font-size: 18px;
}
.slot {
  display: flex;
  align-items: flex-end;
}
</style>
