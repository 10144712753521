<script>
  export let subtitle;
  export let title;
</script>

<div class="rbPageDarkCentered page">
  <div class="container">
    <div class="subtitle">{subtitle}</div>
    <div class="title">{title}</div>
    <div class="text"><slot></slot></div>
    <div><button class="rbButton asDarkMain" on:click>Back to Homepage</button></div>
  </div>
</div>

<style lang="stylus">.page {
  background: #263238;
}
.container {
  box-sizing: border-box;
  flex: 0 0 665px;
  min-height: 280px;
  padding: 0 15px 0 267px;
  background: url("~images/plug-out.svg") no-repeat center left;
  background-size: 280px 280px, cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.subtitle {
  line-height: 1.2;
  font-size: 18px;
  color: #78909c;
}
.title {
  line-height: 1.2;
  font-size: 30px;
  color: #37474f;
  color: #fff;
  margin-top: 5px;
}
.text,
.text :global(a),
.text :global(a:hover),
.text :global(a:visited),
.text :global(a:visited:hover) {
  font-size: 13px;
  line-height: 16px;
  color: #72828a;
  margin: 25px 0 30px;
}
</style>

