import {writable} from 'svelte/store';
import {loadBids} from './emailAddressFixServiceApi.js';
import modalsService from 'src/services/modals';
import ErrorsModal from 'src/components/ErrorsModal.svelte';
import {DateFormatter} from 'src/utils/internationalization.service';

const defaultState = {
  initialized: false,
  data: []
};

const store = writable({...defaultState});

const df = DateFormatter()

const EMAIL_ADDRESS = 'emailAddress', EMAIL = 'email';

export default store;

export async function initialize(daysToShow){
  try {
    store.set({
      initialized: false
    });

    const response = await loadBids(daysToShow);
    const ignored = [], active = [];

    response.data.forEach(i => i.email && i.email.ignored ? ignored.push(i) : active.push(i));

    store.update(s => ({
      ...s,
      initialized: true,
      active: prepare(active),
      ignored: prepare(ignored)
    }));
  } catch (e) {
    modalsService.show(ErrorsModal, {
      message: `Failed to load bids`,
      error: e && e.data && e.data.message || null,
    });

    store.update(s => ({
      ...s,
      initialized: true,
      data: [],
    }));
  }
}

export function destroy(){
  store.set(defaultState);
}

function prepare(data){
  const p = data
    .reduce(reduceFn, {[EMAIL]:[], [EMAIL_ADDRESS]:[]});

  return [...p[EMAIL], ...p[EMAIL_ADDRESS]]
    .sort((a,b) => {
      if(b.fixClient === a.fixClient){
        return a.failureTime < b.failureTime ? 1 : -1
      } else {
        return b.fixClient - a.fixClient;
      }
    });

  function reduceFn(acc, bid){
    const problemType = determineProblemType(bid.supplier.contact, bid.email);

    if(problemType === EMAIL){
      return {...acc, [EMAIL]: prepareProblematicEmail(acc[EMAIL], bid)};
    } else if (problemType === EMAIL_ADDRESS) {
      return {...acc, [EMAIL_ADDRESS]: prepareProblematicEmailAddress(acc[EMAIL_ADDRESS], bid)};
    } else {
      return acc;
    }
  }

  function determineProblemType(bidSupplierContact = {}, bidEmail = {}){
    if(['COMPLAINT', 'BOUNCED'].indexOf(bidSupplierContact.emailAddressStatus) === -1) {
      return ['COMPLAINT', 'BOUNCED'].indexOf(bidEmail.status) === -1 ? null : EMAIL;
    } else {
      return EMAIL_ADDRESS;
    }
  }

  function prepareProblematicEmail(acc, bid){
    const
      formattedBid = formatBid(bid, EMAIL),
      row = findOrCreateRow(acc, formattedBid);
    updateRow(row, formattedBid);
    return acc;
  }

  function prepareProblematicEmailAddress(acc, bid){
    const
      formattedBid = formatBid(bid, EMAIL_ADDRESS),
      row = findOrCreateRow(acc, formattedBid);
    updateRow(row, formattedBid);

    return acc;
  }

  function formatBid(bid, problemType){
    const
      supplierContact = bid.supplier.contact || createEmptySupplierContact(bid.supplier.company, bid.hotelRfpType),
      rfp = bid.rfp,
      failure = parseFailure(problemType, bid),
      bidStatusAt = df.format(bid.state.at);

    return {
      problemType,
      bidId: bid.bidId,
      emailAddress: supplierContact.emailAddress,
      fullName: supplierContact.fullName,
      isUser: supplierContact.isUser,
      isUserIcon: supplierContact.isUser ? 'how_to_reg' : 'person_off',
      entityId: supplierContact.company.entityId || supplierContact.company._id,
      entity: supplierContact.company,
      entityTypeIcon: supplierContact.company.type === 'HOTEL' ? 'hotel' : 'local_play',
      fixClient: bid.serviced,
      fixClientIcon: bid.serviced ? 'shield' : 'remove_moderator',
      ...failure,
      rfpId: rfp._id,
      rfpName: rfp.specifications.name,
      rfpDueDate: df.format(rfp.specifications.dueDate),
      rawDueDate: rfp.specifications.dueDate,
      buyerContactCompanyName: bid.buyer.contact.company.name,
      buyerRfpName: `${bid.isCompetitive ? `[COMP] ` : ''}${bid.buyer.contact.company.name} - ${rfp.specifications.name}`,
      bidStatus: bid.state.label,
      bidStatusAt,
      bidStatusFormatted : `[${bidStatusAt}] ${bid.state.label}`,
      raw: bid,
    };
  }

  function createEmptySupplierContact(hotel, bidType){
    return {
      emailAddress: 'none',
      fullName: 'Not Selected',
      isUser: false,
      company: bidType === 'DIRECT' ? hotel : hotel.chain.master
    }
  }

  function parseFailure(problemType, bid){
    if(problemType === EMAIL){
      const email = bid.email;

      return {
        failureEmailId: email._id,
        failureTime: email.time,
        failureTimeFormatted: formatZonedDateTime(email.time),
        failureType: email.status,
        failureTypeIcon: email.status === 'COMPLAINT' ? 'ring_volume' : 'call_missed_outgoing',
      };
    } else {
      const supplierContact = bid.supplier.contact;
      return {
        failureTime: supplierContact.emailAddressStatusTime,
        failureTimeFormatted: formatZonedDateTime(supplierContact.emailAddressStatusTime),
        failureType: supplierContact.emailAddressStatus,
        failureTypeIcon: supplierContact.emailAddressStatus === 'COMPLAINT' ? 'ring_volume' : 'call_missed_outgoing',
      };
    }
  }

  function formatZonedDateTime(s){
    return df.format(s ? new Date(s.replace(/\[.*]/, '')) : undefined);
  }

  function findOrCreateRow(acc, formattedBid){
    let row = acc.find(rw => rw.problemType === formattedBid.problemType && rw.emailAddress === formattedBid.emailAddress && rw.entityId === formattedBid.entityId);
    if(!row){
      row = {
        problemType: formattedBid.problemType,
        problemTypeIcon: formattedBid.problemType === EMAIL ? 'mail_outline' : 'person_outline',
        emailAddress: formattedBid.emailAddress,
        fullName: formattedBid.fullName,
        entityId: formattedBid.entityId,
        entity: formattedBid.entity,
        entityTypeIcon: formattedBid.entityTypeIcon,
        bids: [],
        bidsIds: [],
      };
      acc.push(row);
    }

    return row;
  }

  function updateRow(row, formattedBid){
    row.bids.push(formattedBid);
    row.bidsIds.push(formattedBid.bidId);

    if(!row.fixClient){
      row.fixClient = formattedBid.fixClient;
      row.fixClientIcon = formattedBid.fixClientIcon;
    }

    if(!row.isUser){
      row.isUser = formattedBid.isUser;
      row.isUserIcon = formattedBid.isUserIcon;
    }

    if(row.failureType !== 'COMPLAINT'){
      if(formattedBid.failureEmailId) {
        row.failureEmailId = formattedBid.failureEmailId;
      }
      row.failureType = formattedBid.failureType;
      row.failureTypeIcon = formattedBid.failureTypeIcon;
      row.failureTime = formattedBid.failureTime;
      row.failureTimeFormatted = formattedBid.failureTimeFormatted;
    }

    if(row.buyerRfpName !== formattedBid.buyerRfpName){
      row.buyerRfpName = row.buyerRfpName ? 'Multiple RFPs' : formattedBid.buyerRfpName;
    }

    if(row.bids.length > 1){
      row.bidStatusFormatted = 'Multiple Bids';
    } else {
      row.bidStatusFormatted = formattedBid.bidStatusFormatted;
    }

    if(!row.rawDuedate || row.rawDuedate < formattedBid.rawDueDate){
      row.rawDuedate = formattedBid.rawDueDate;
      row.rfpDueDate = formattedBid.rfpDueDate;
    }
  }
}

export function setFixed(problem, dataSource){
  store.update(s => ({
    ...s,
    [dataSource]: s[dataSource].map(d => d === problem ? {...d, fixed: true} : d)
  }));
}
