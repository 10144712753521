<script>
    import ListQuestion from './QuestionnaireViewListQuestion.component.svelte';

    export let question, response;
    const value = response[question.id];
    const option = question.type === 'SELECT'? question.options.find(o => o.value == value): null;
    const selectValue = option? option.label: '';
</script>

<div class="question" class:value id={question.id}>
    <span>{question.ord}. {question.name}</span>
    {#if value}
        {#if question.type === 'TEXT' || question.type === 'DECIMAL' || question.type === 'NUMBER'}
            <div class="value">{value}</div>
        {:else if question.type === 'LIST'}
            <ListQuestion {...question} {value}/>
        {:else if question.type === 'SELECT'}
            <div class="value">{selectValue}</div>
        {/if}
    {/if}
</div>

<style type="text/stylus">.question {
  flex: 1;
  min-width: 450px;
  max-width: 450px;
  margin: 5px 0 20px 0;
  border-bottom: 1px solid #eceff1;
  padding: 5px 10px;
  box-sizing: border-box;
}
.question.value span {
  font-size: 10px;
  opacity: 1;
}
span {
  color: #546e7a;
  opacity: 0.6;
}
.value {
  color: #546e7a;
}
</style>
