<script>
  import {default as store, service} from './accounts.store';

  import DialogTemplate from 'components/modals/DialogTemplate.basic.svelte';
  import httpNotifications from 'src/api/HttpNotifications';
  import HttpErrorHandler from 'src/api/HttpErrorHandler';
  import api from 'src/pages/users/manager/users-manager-api';

  export let cancel, hide;

  const accountId = $store.account.accountId;

  let
    domainInputElement,
    domains = [],
    loading = true;

  initialize();

  async function initialize(){
    try {
      const httpResponse = await api.getApprovedEmailAddressDomains(accountId);
      domains = httpResponse.data;
      loading = false;
    } catch (e){
      console.error(e);
      HttpErrorHandler.handle(e);
    }
  }

  function addDomain(){
    const clean = cleanUpDomainInput(domainInputElement.value);

    if(clean && domains.indexOf(clean) === -1){
      domains = [...domains, clean];
      domainInputElement.value = '';
    }


    function cleanUpDomainInput(inputValue){
      let v = inputValue.trim().toLowerCase();
      if(!v.length) return v;

      while(v.startsWith('@')){
        v = v.slice(1);
      }
      return v;
    }
  }

  function removeDomain(domain){
    domains = domains.filter(e => e !== domain);
  }

  function submit(){
    if(!loading)
      httpNotifications.handle(api.setApprovedEmailAddressDomains(accountId, domains))
        .then(() => {
          service.updateAccount({...$store.account, userAutoVerification: !!domains.length});
          hide();
        })
  }
</script>


<DialogTemplate width="600" {cancel} closeOnClick="{false}">
  <div class="Container">
    <div class="Heading">
      <div>User Auto Verification Configuration</div>
    </div>

    <div class="Body">
      <section>
        To prevent unauthorized access to account, new users must pass verification by {$store.account.name} or ReadyBid administrators. You can provide domain names, and if a new user's email address matches the provided domains they will be automatically verified, significantly speeding up the process.
      </section>
      {#if $store.account.type === 'CHAIN'}
        <section>Auto Verification also includes all users registering as Hotel Representative for all your Hotels.</section>
      {/if}

      <div>
        <div class="DomainsContainer">
          {#each domains as domain}
            <div class="DomainBlock"
                 data-icon-after="close"
                 on:click={() => removeDomain(domain)}
            >
              {domain}
            </div>
          {:else}
            {#if loading}
              <b>Loading...</b>
            {:else}
              <b>No domains are added yet</b>
            {/if}
          {/each}
        </div>

        <div class="InputContainer">
          <div class="Input">
            <input type="text"
                   bind:this={domainInputElement}
                   id="domain"
                   name="domain"
                   placeholder="Domain Name"
            />
            <label for="domain">Domain Name</label>
          </div>

          <div class="ButtonContainer">
            <button
              class="rbButton asDark"
              data-icon-before="add_circle"
              on:mouseup={addDomain}
            >
              Add
            </button>
          </div>
        </div>
      </div>

      <section>Examples of domain names: <i>gmail.com</i> from email address some_name@<i>gmail.com</i>, <i>readybid.net</i> from support@<i>readybid.net</i>, etc</section>
    </div>

    <div class="ModalButtons">
      <button class="rbButton asDark" on:click={cancel}>Cancel</button>
      <button class="rbButton asDarkMain" on:click={submit}>Confirm</button>
    </div>
  </div>
</DialogTemplate>

<style lang="stylus">.Container {
  display: flex;
  flex-direction: column;
}
.Heading {
  color: #fff;
  font-size: 16px;
  padding: 15px;
  flex: 0 0 auto;
}
.Body {
  padding: 0 15px;
  font-size: 13px;
  line-height: 16px;
  color: #78909c;
  flex: 1 1 100%;
}
.ModalButtons {
  display: flex;
  padding: 15px;
  justify-content: flex-end;
  flex: 0 0 auto;
}
section {
  margin-bottom: 15px;
}
.DomainsContainer {
  min-height: 30px;
  max-height: 90px;
  overflow: auto;
  margin-bottom: 5px;
}
.DomainBlock {
  display: inline-flex;
  color: #fff;
  background: #00a99d;
  padding: 3px 4px 3px 10px;
  border-radius: 20px;
  line-height: 14px;
  font-size: 13px;
  margin: 2px 2px;
  cursor: pointer;
}
.DomainBlock:hover {
  background: #f15a24;
}
.DomainBlock:hover:after {
  color: #f15a24;
}
.DomainBlock:after {
  background: #fff;
  color: #00a99d;
  border-radius: 10px;
  font-size: 16px;
  margin-left: 3px;
}
.InputContainer {
  display: flex;
  margin-bottom: 20px;
}
.Input {
  position: relative;
  margin-top: 10px;
  flex: 1 1 100%;
}
.Input input {
  background-color: #fff;
  border: 1px solid #cfd8dc;
  border-radius: 6px 0 0 6px;
  font-family: "Noto Sans", Sans-serif;
  font-weight: 500;
  color: #37474f;
  font-size: 16px;
  height: 47px;
  padding: 6px 16px;
  width: 100%;
  max-width: 100%;
  line-height: 1.4;
  box-sizing: border-box;
  margin: 0;
  outline: none;
}
.Input input::placeholder {
  color: inherit;
  font-family: inherit;
  opacity: 0.6;
}
.Input input:placeholder-shown ~ label {
  opacity: 0;
  top: 0;
}
.Input input ~ label {
  color: #78909c;
}
.Input input:focus {
  border-color: #00a99d;
}
.Input input:focus ~ label {
  color: #00a99d;
}
.Input label {
  position: absolute;
  box-sizing: border-box;
  background: rgba(255,255,255,0.8);
  transition: all 0.2s ease;
  top: -10px;
  left: 10px;
  padding: 0 5px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1;
  padding-bottom: 4px;
}
.ButtonContainer button {
  margin-top: 10px;
  height: 47px;
  border-radius: 0 6px 6px 0;
  padding-left: 15px;
}
.ButtonContainer button:before {
  margin-right: 5px;
}
</style>
