/**
 * @fileoverview This file contains the implementation of the account store and related functions.
 */

import {writable, get} from 'svelte/store';
import {service as userStoreService} from 'src/pages/users/manager/users.store';
import api from 'src/pages/users/manager/users-manager-api';
import debounce from 'lodash/debounce';

/**
 * Options for account types.
 * @typedef {Object} AccountTypeOption
 * @property {string} value - The value of the account type.
 * @property {string} label - The label of the account type.
 */

/** @type {AccountTypeOption[]} */
const accountTypeOptions = [
  {value: 'ALL', label: 'All Accounts'},
  {value: 'HOTEL', label: 'Hotels'},
  {value: 'CHAIN', label: 'Chains'},
  {value: 'HMC', label: 'Hotel Management Companies'},
  {value: 'COMPANY', label: 'Companies'},
  {value: 'TRAVEL_AGENCY', label: 'Travel Agencies'},
  {value: 'TRAVEL_CONSULTANCY', label: 'Travel Consultancies'},
];

/** @type {Object} */
const initialState = {
  accountTypeOptions,
  accountType: accountTypeOptions[0],

  query: '',
  page: 1,

  showSearch: true,
  accounts: [],
  account: undefined,
};

/** @type {Writable<Object>} */
const store = writable(initialState);

export default store;

/**
 * Initializes the store.
 */
function initialize(){
  store.set(initialState);
  userStoreService.setUsers();
}

/**
 * Sets the account type and updates the application state accordingly.
 *
 * @param {string} type - The account type to set.
 */
function setAccountType(type){
  const accountType = accountTypeOptions.find(eo => eo.value === type);
  store.set({...initialState, accountType});
  userStoreService.setUsers();
}

/**
 * Searches for accounts based on the provided query.
 *
 * @param {string} query - The search query.
 * @returns {Promise<void>} A promise that resolves once the search is complete.
 */
async function search(query = ''){
  if (query.length) {
    const
      $store = get(store),
      type = $store.accountType.value === 'ALL' ? undefined : $store.accountType.value,
      page = 1;

    if ($store.query === query) return;

    store.set({...$store, query, page});

    const result = await searchAccounts(query, type, page);
    store.update($s => $s.query === query
      ? {...$s, accounts: result.data, hasMore: result.total > result.data.length, page}
      : $s);

  } else {
    store.update($s => ({...initialState, accountType: $s.accountType}));
  }
}

/**
 * Searches for accounts based on the provided query, type, and page number.
 *
 * @param {string} query - The search query.
 * @param {string} type - The account type.
 * @param {number} page - The page number.
 * @returns {Promise<{ data: Array<Object>, total: number }>} A promise that resolves with the search result.
 */
async function searchAccounts(query, type, page){
  const result = await api.searchAccounts(query, type, page);

  const data = result.data.map(account => ({
    entityId: account.entityId,
    type: account.type,
    name: account.name,
    address: account.location ? account.location.fullAddress : undefined,
    accountId: account._id,
    accountSuspended: account.state ? account.state.value === 'SUSPENDED' : false,
    agencyName: account.agency ? account.agency.name : undefined,
    userAccountsCount: account.userAccounts || 0,
    userAutoVerification: account.userAutoVerification
  }));

  return { data, total: result.total };
}

/**
 * Loads more accounts based on the current query, account type, and page number.
 *
 * @returns {Promise<void>} A promise that resolves once the accounts are loaded.
 */
async function loadMore(){
  const $store = get(store),
    query = $store.query,
    page = $store.page +1,
    type = $store.accountType.value === 'ALL' ? undefined : $store.accountType.value,
    result = await searchAccounts(query, type, page);

  store.update($s => {
    const accounts = [...$s.accounts, ...result.data];
    return $s.query === query ? {...$s, accounts, hasMore: accounts.length <  result.total, page} : $s;
  });
}

/**
 * Selects an account and updates the application state accordingly.
 *
 * @param {Object} account - The account object to select.
 * @returns {Promise<void>} A promise that resolves once the account selection is complete.
 */
async function selectAccount(account){
  store.update($s => ({ ...$s, account, showSearch: false, }));

  if(account.accountId) {
    userStoreService.setLoading();
    const res = await api.listUsersForAccount(account.accountId)
    userStoreService.setUsers(res.data);
  } else {
    userStoreService.setUsers([]);
  }
}

/**
 * Shows the search UI and updates the application state accordingly.
 */
function showSearch(){
  store.update($s => ({
    ...$s,
    account: undefined,
    showSearch: true,
  }));
}

/**
 * Updates the account object and updates the application state accordingly.
 *
 * @param {Object} account - The updated account object.
 */
function updateAccount(account){
  store.update($s => ({ ...$s, account }));
}

/**
 * The service object containing the exported functions.
 * @namespace service
 */
export const service = {
  initialize,
  setAccountType,
  search: debounce(search, 200),
  loadMore,
  selectAccount,
  showSearch,
  updateAccount
}

