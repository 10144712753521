const id = 'minLength';

export default {
  create(config){
    return {
      id,
      isInvalid (value, values, context, name) {
        return value && value.length < config && {
          id,
          message: `${ name } too short`
        };
      }
    }
  },
}
