<script>
    import {createEventDispatcher} from 'svelte';
    export let checked = true;

    const dispatch = createEventDispatcher();

    function toggle() {
        dispatch('toggle', !checked);
    }
</script>

{#if checked}
    <i class="material-icons on" on:click={toggle}>toggle_on</i>
{:else}
    <i class="material-icons" on:click={toggle}>toggle_off</i>
{/if}


<style type="text/stylus">i.material-icons {
  cursor: pointer;
  margin-right: 10px;
}
i.on {
  color: #00a995;
}
</style>
