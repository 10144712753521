<script>
    import FormModel from 'components/molecules/inputs/FormModel.svelte';
    import Coordinates from 'components/molecules/coordinates/Coordinates.svelte';

    import ProfileRoute from 'root/routes/EntityProfile';
    import { getModelFromObect } from 'root/utils';
    import industries from 'root/assets/data/industries';

    import Header from '../../components/Header.component.svelte';
    import SingleInput from '../../components/SingleInput.svelte';
    import EditBasicInfoFormController from './EditBasicInfo.component.svelte';
    import {BASIC_INFO} from './basic-info.module';
    import {getCompanyAgency, updateChainCode} from '../../services/entity.service';

    export let entity, typeName, type;

    let editing = false, editModel = {}, formModel = {}, travelAgencyPromise;

    if(type === 'COMPANY') {
        travelAgencyPromise = getCompanyAgency(entity.id);
    }

    function edit() {
        makeEditModel();
        editing = true;
    }

    function makeEditModel() {
        var res = getModelFromObect(entity);

        editModel = {
            ...res,
            'location.coordinates.lat': res['location.coordinates.latitude'],
            'location.coordinates.lng': res['location.coordinates.longitude'],
        };;
    }

    function updated({detail}) {
        editing = false;
        entity = {...entity, ...detail};
    }

    function travelAgencyHref({entityId}) {
      return ProfileRoute.href({type: 'TRAVEL_AGENCY', entityId, section: BASIC_INFO});
    }

    function chainHref({id}) {
      return ProfileRoute.href({type: 'CHAIN', entityId: id, section: BASIC_INFO});
    }

    function saveChainCode({detail}) {
      return updateChainCode(entity.id, detail).then(() => entity.code = detail);
    }

    $: website = entity.website && entity.website.match(/^[a-zA-Z]+?:\/\//)? entity.website: `//${entity.website}`;
    $: coordinates = entity.location && entity.location.coordinates? {
        lat: entity.location.coordinates.latitude,
        lng: entity.location.coordinates.longitude,
    }: null;
    $: industry = industries.find(({id}) => entity.industry === id).label;

</script>

{#if !editing}
    <Header {entity} {typeName}>
        <slot />
        <!-- <button class="rbButton asDark" data-icon='power_settings_new'>Disable</button> -->
        <button class="rbButton asDarkMain" data-icon='edit' on:click={edit}>Edit</button>
    </Header>

    <div class="main rbDark">
        <div class="section" id="basic">
            <h2 class="title">{typeName} Basic Information</h2>
            <div class="content">
                <div class="data">
                    <span class="label">{typeName} Name</span>
                    <span class="value">{entity.name}</span>
                </div>
                {#if type === 'CHAIN'}
                    <div class="data">
                        <div class="label">{typeName} Code</div>
                        <div class="value">
                          <SingleInput value={entity.code} on:save={saveChainCode}>
                            {entity.code}
                          </SingleInput>
                        </div>
                    </div>
                    {#if entity.subtype === 'BRAND' && entity.masterChain}
                        <div class="data">
                            <div class="label">Master Chain Name</div>
                            <a href={chainHref(entity.masterChain)} class="value" title="click to open">{entity.masterChainName}</a>
                        </div>
                        <div class="data">
                            <div class="label">Master Chain Code</div>
                            <div class="value" title="click to open">{entity.masterChainCode}</div>
                        </div>
                    {/if}
                {/if}

                <div class="data">
                    <span class="label">Address</span>
                    <span class="value">{entity.location? entity.location.fullAddress: ''}</span>
                </div>
                <div class="data">
                    <span class="label">Industry</span>
                    <span class="value">{industry}</span>
                </div>
                <div class="data">
                    <span class="label">Phone</span>
                    <span class="value">{entity.phone || ''}</span>
                </div>
                <div class="data">
                    <span class="label">Email</span>
                    <span class="value">{entity.emailAddress || ''}</span>
                </div>
                <div class="data">
                    <span class="label">Website</span>
                    {#if entity.website}
                        <a href={website} target="_blank" class="value">{entity.website}</a>
                    {/if}
                </div>
                {#if type === 'COMPANY' && travelAgencyPromise}
                    <div class="data">
                        <span class="label">Travel Agency</span>
                        {#await travelAgencyPromise}
                            <span class="value">loading...</span>
                        {:then data}
                            {#if data}
                                <a href={travelAgencyHref(data)} class="value" title="click to open">{data.name}</a>
                            {/if}
                        {/await}
                    </div>
                {/if}
            </div>
        </div>
        {#if coordinates}
            <Coordinates value={coordinates} zoom={13}></Coordinates>
        {/if}
    </div>
{:else}
    <FormModel bind:values={editModel}>
        <EditBasicInfoFormController bind:formModel {entity} {type} {typeName} bind:editing on:updated={updated} />
    </FormModel>
{/if}


<style type="text/stylus">.label {
  color: #72828a;
  min-width: 250px;
}
a.value {
  color: #fff;
}
.main :global(.mapDiv:not(.expanded)) {
  height: 100px;
  width: 100px;
  max-width: 100px;
  min-width: 0;
  position: fixed;
  bottom: 10px;
  right: 10px;
}
.main :global(.mapDiv:not(.expanded) .coordinates),
.main :global(.mapDiv:not(.expanded) .coordinates .gm-fullscreen-control) {
  display: none;
}
</style>
