<script>
    import InputText from './InputText.svelte';

    export let name = "";
    export let id = name;
    export let disabled;
    export let readonly;
    export let on = [];
    export let iconStart;
    export let iconEnd;

    // setup validations
    export let required;
    export let maxLength;
    export let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    export let format;
</script>

<InputText
    {name}
    {id}
    {disabled}
    {readonly}
    {on}
    {iconStart}
    {iconEnd}
    {required}
    {maxLength}
    {pattern}
    {format}
><slot></slot></InputText>

