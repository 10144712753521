import component from './Amenities.page.svelte';
export const AMENITIES = 'amenities';

export default {
    component,
    id: AMENITIES,
    text: 'Amenities',
    icon: 'loyalty',
    subItems: [
        {
            id: 'basic',
            text: 'Basic Amenities',
        },
        {
            id: 'others',
            text: 'Other Amenities',
        },
    ]
};