<script>
    import NotificationTemplate from 'components/modals/NotificationTemplate.basic.svelte';
    import ImagePicker from 'components/molecules/ImagePicker/ImagePicker.svelte';

    export let hide, cancel, hotel, action = 'image';

    let image;
</script>

<NotificationTemplate>
  <div class="container">
    <div class="header">
      <div class="headerContent">
        <div class="title">Edit {hotel.name} {action}</div>
      </div>
    </div>

    <div class="content">
        <ImagePicker bind:value="{image}"></ImagePicker>
    </div>

    <div class="footer">
        <button class="rbButton asModal" on:click="{cancel}">Cancel</button>
        <button class="rbButton asModalMain" data-icon="save" on:click="{() => hide({image, id: hotel._id || hotel.id})}" disabled={!image}> Save </button>
    </div>
  </div>
</NotificationTemplate>

<style type="text/stylus">.container {
  padding: 25px 15px 20px;
}
.header {
  display: flex;
  flex-direction: row;
}
.headerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  height: 20px;
  font-size: 15px;
  vertical-align: text-bottom;
  color: #546e7a;
  text-shadow: 1px 0 0 rgba(0,0,0,0.15);
  font-weight: normal;
  line-height: 1;
  padding: 0;
  margin: 0;
}
.content {
  margin: 25px 0 30px;
  font-size: 13px;
  line-height: 16px;
  color: #546e7a;
}
.footer {
  text-align: right;
}
</style>
