<script>
  import NotificationTemplate from "components/modals/NotificationTemplate.basic.svelte";
  import Select from "components/molecules/inputs/Select.svelte";
  import SInput from "components/molecules/inputs/Input.svelte";

  export let hide,
    cancel,
    notes = {comment: ''}, types, terms;
</script>

<NotificationTemplate>
  <div class="container">
    <div class="header">
      <div class="headerContent">
        <div class="title">Update Subscription Information</div>
      </div>
    </div>

    <div class="content">
      <Select options={terms} bind:value={notes.terms}>
        Subscription Terms
      </Select>
      <Select options={types} bind:value={notes.type}>Subscription Type</Select>
      <SInput bind:value={notes.amount} type="number">
        Subscription Amount
      </SInput>
      <SInput bind:value={notes.lastPaymentDate} type="date">
        Last Payment Date
      </SInput>
      <SInput bind:value={notes.expiryDate} type="date">
        Payment Expiry Date
      </SInput>
      <div class="text-area">
        <label for="textarea">Comments</label>
        <textarea bind:value={notes.comment} />
      </div>
    </div>

    <div class="footer">
      <button class="rbButton asModal" on:click={cancel}>Cancel</button>
      <button
        class="rbButton asModalMain"
        data-icon="save"
        on:click={() => hide(notes)}
      >
        Save
      </button>
    </div>
  </div>
</NotificationTemplate>

<style type="text/stylus">.container {
  padding: 25px 15px 20px;
}
.header {
  display: flex;
  flex-direction: row;
}
.headerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  height: 20px;
  font-size: 15px;
  vertical-align: text-bottom;
  color: #546e7a;
  text-shadow: 1px 0 0 rgba(0,0,0,0.15);
  font-weight: normal;
  line-height: 1;
  padding: 0;
  margin: 0;
}
.content {
  margin: 20px 0;
  font-size: 13px;
  line-height: 16px;
  color: #546e7a;
}
.footer {
  text-align: right;
}
.content :global(.rbInput.component .container) {
  border: 1px solid #e6e7e8;
  background: transparent;
  color: #546e7a;
}
.content :global(.rbInput.component .container .input) {
  color: #546e7a;
}
.content :global(.rbInput.component .container .label) {
  background: #fff;
}
.text-area {
  margin: 10px 5px;
}
.text-area textarea {
  width: 100%;
  min-height: 200px;
  display: block;
  margin-top: 5px;
  border-radius: 5px;
}
</style>
