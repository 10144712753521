<script>
  import defaultImage from "images/user-image.png";
  import Header from "components/organisms/Header/Header.svelte";

  import currentUserService from "../services/currentUserService.js";
  import SignOut from "../routes/SignOut.js";
  import { Profile } from "root/routes/AdminUserSearch";

  let user = {};

  currentUserService.get().then(cu => {
    user = {
      id: cu.id,
      firstName: cu.firstName,
      companyName: "ReadyBid",
      profileImage: cu.profilePicture
        ? `/images/users/${cu.profilePicture}`
        : defaultImage
    };
  });
</script>

<style>
.header {
  height: 60px;
}
</style>

<div class="header">
  <Header {user}>
    <li class="link">
      <a
        href="#profile"
        on:click|preventDefault|stopPropagation={() => Profile.go({userId: user.id, section: "basic-info"})}>
        <i class="icon material-icons">person</i>
        <span>Profile</span>
      </a>
    </li>
    <li class="link">
      <a
        href="#logout"
        on:click|preventDefault|stopPropagation={() => SignOut.go()}>
        <i class="icon material-icons">logout</i>
        <span>Sign out</span>
      </a>
    </li>
  </Header>
</div>
