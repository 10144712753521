<script>
    import NotificationTemplate from 'components/modals/NotificationTemplate.basic.svelte';
    import {orderBy} from 'lodash';

    export let hide, report;

    const visits = orderBy(Object.keys(report.directoryOpens), key => {
        const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
        const split = key.split('-');
        return [split[0], months.indexOf(split[1].toLowerCase())];
    }, ['asc', 'asc'])
        .splice(0, 12)
        .map(label => ({label, value: report.directoryOpens[label]}));

    const bids = Object.keys(report.bidCounts)
        .sort()
        .splice(0, 12)
        .map(label => ({label, value: report.bidCounts[label]}));
</script>

<NotificationTemplate>
    <div class="container">
        <div class="header">
            <div class="subTitle"> Hotel Directory Report </div>
            <div class="title"> {report.hotelDirectory.account.name} </div>
        </div>
        <div class="content">
            {#if report.hotelDirectory.account.agency}
                <div class="data">
                    <div class="label">Travel Agency:</div>
                    <div class="value">{report.hotelDirectory.account.agency.name}</div>
                </div>
            {/if}
            <div class="data">
                <div class="label">Paying Customer:</div>
                <div class="value">{report.paying? 'Yes': 'No'}</div>
            </div>

            <div class="section">
                <div class="section-title">Hotel Directory Visit Counts</div>
                {#each visits as visit}
                    <div class="data">
                        <div class="label">{visit.label}</div>
                        <div class="value">{visit.value}</div>
                    </div>
                {:else}
                    <div class="empty">No visits recorded yet</div>
                {/each}
            </div>
            <div class="section">
                <div class="section-title">Bid Counts</div>
                {#each bids as bid}
                    <div class="data">
                        <div class="label">{bid.label}</div>
                        <div class="value">{bid.value}</div>
                    </div>
                {:else}
                    <div class="empty">No bids added yet</div>
                {/each}
            </div>
        </div>

        <div class="footer">
            <button class="rbButton asModalMain" on:click={hide}>Ok</button>
        </div>
    </div>
</NotificationTemplate>

<style type="text/stylus">.container {
  padding: 25px 15px 20px;
  color: #546e7a;
}
.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.title {
  font-size: 20px;
}
.subTitle {
  font-size: 12px;
}
.footer {
  text-align: right;
}
.data {
  padding: 5px 0;
  display: flex;
  align-items: center;
  font-size: 12px;
}
.label {
  min-width: 150px;
  font-weight: bolder;
}
.section {
  margin: 10px 0;
}
.section-title {
  margin-bottom: 10px;
  font-size: 14px;
}
.empty {
  font-size: 12px;
  opacity: 0.8;
}
</style>
