<script>
  import noop from 'lodash/noop';
  import { onMount } from 'svelte';
  import NotificationTemplate from './NotificationTemplate.svelte';
  import Spinner from '../atoms/Spinner.svelte';

  export let hide;
  export let label = 'Please wait...';
  export let promise;
  export let transparent = true;

  onMount(() => {
    if(promise){
      promise.finally(() => hide().catch(noop))
    }
  })
</script>

<NotificationTemplate>
  <div class="container" class:filled={!transparent}>
    <Spinner></Spinner>
    {#if label}
      <div class="label">{label}</div>
    {/if}
  </div>
</NotificationTemplate>

<style>

.container {
  text-align: center;
}

.container.filled {
  border-radius: 6px;
  box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #000 !important;
  background: #313c42 !important;
  color: white;
  padding: 20px 50px;
}


.label {
  color: white;
  font-weight: 100;
  margin: 15px auto 0;
  font-size: 11pt;
  padding-left: 11pt;
}

</style>
