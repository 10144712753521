<script>
  import DialogTemplate from 'components/modals/DialogTemplate.basic.svelte';
  import httpNotifications from 'src/api/HttpNotifications';
  import API from '../users-manager-api';


  export let cancel, user = {};

  let urlCopied = false, urlEmailed = false;

  function copyToClipboard(){
    if(urlCopied) return;
    httpNotifications.handle(API.getUserTemporarySignInUrl(user.id), {transparent: false})
      .then(httpResponse => navigator.clipboard.writeText(httpResponse.data))
      .then(() => {
        urlCopied = true;
        setTimeout(() => { urlCopied = false }, 10000);
      })
      .catch(e => {
        console.error(e)
        alert('There was an error.')
      });
  }

  function sendEmail(){
    if(urlEmailed) return;
    httpNotifications.handle(API.sendUserTemporarySignIn(user.id), {transparent: false})
      .then(() => {
        urlEmailed = true;
        setTimeout(() => { urlEmailed = false }, 10000);
      })
      .catch(e => {
        console.error(e)
        alert('There was an error.')
      });
  }

</script>

<DialogTemplate cancel="{cancel}" closeOnClick="{false}">
  <div class="DialogContainer">
    <div class="Title">
      <div class="Important">Get Temporary Sign In Link</div>
      <div>Copy or email an URL that will sign in {user.name} without needing a password for a week.</div>
    </div>

    <div class="Actions">
      <button class="rbButton asDark"
              disabled="{urlCopied}"
              on:click={copyToClipboard}>
        {#if urlCopied}URL copied to the clipboard!{:else}Copy Sign In URL to clipboard{/if}
      </button>

      <button class="rbButton asDark"
              disabled="{urlEmailed}"
              on:click={sendEmail}>
        {#if urlEmailed}URL emailed to the user!{:else}Email Sign In URL to user{/if}
      </button>
    </div>
  </div>

</DialogTemplate>

<style lang="stylus">.DialogContainer {
  padding: 0px 10px;
  max-height: 70vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #b0bec5;
}
.Title {
  flex: 1 1 100%;
  padding: 10px 15px;
  color: #eceff1;
}
.Title .Important {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 5px;
}
.Actions {
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 15px;
}
</style>
