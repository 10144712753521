<script>
    import { Google } from '../../utils/google/googleProvider';
    import cloneDeep from 'lodash/cloneDeep'
    import { onMount, createEventDispatcher } from 'svelte';
    import { fade } from 'svelte/transition';


    export let value;
    export let zoom = 3;
    export let mapTypeId = 'terrain';

    let modelValue = {}, viewValue, mapCenter, map, marker, mapDiv, inExpandedMode = false, viewZoom = zoom;

    const dispatch = createEventDispatcher();

    $: if (modelValue !== value || viewZoom !== zoom) {
        modelValue = value;
        modelFormat();
        modelRender();
    }

    $: if (viewZoom !== zoom) {
        viewZoom = zoom;
        map.setZoom(zoom);
    }

    function toggleExpansion() {
        inExpandedMode = !inExpandedMode;
    }


    async function initMap(){
        const google = await Google();
        map = new google.maps.Map(mapDiv, {
            center: mapCenter,
            zoom,
            mapTypeId,
            fullscreenControlOptions: {
                position: google.maps.ControlPosition.LEFT_TOP
            }
        });
    }

    function modelFormat() {};

    function modelRender() {
        viewValue = modelValue ? cloneDeep(modelValue) : {lat: 40.1451, lng: -99.6680};
        marker && marker.setPosition(viewValue);
        mapCenter = cloneDeep(viewValue);
        return map && map.panTo(mapCenter)
    }

    function modelParse() {
        modelValue = viewValue
    }

    function modelEmit() {
        dispatch('input', modelValue);
        dispatch('change', modelValue);
    }

    function initMarker(){
        return map && Google()
            .then(google => {
                marker = new google.maps.Marker({
                    map,
                    position: viewValue,
                    draggable: true
                });

                marker.addListener('dragend', event => {
                    viewValue = event.latLng.toJSON();
                    modelParse();
                    modelEmit();
                });
            });
    }

    onMount(async () => {
        await initMap()
        await initMarker();
        modelRender();
    });
</script>

{#if inExpandedMode}
    <div class="bg"></div>
{/if}

<div class="mapDiv rbCard" class:expanded={inExpandedMode}>
    <div bind:this={mapDiv} class="map"></div>
    <div class="screen-control" on:click={toggleExpansion}>
        {#if !inExpandedMode}
            <i in:fade class="material-icons">fullscreen</i>
        {:else }
            <i in:fade class="material-icons">fullscreen_exit</i>
        {/if}
    </div>
    <div class="coordinates">
        {#if !!modelValue}
            <b>
                {modelValue.lat}, {modelValue.lng}
            </b>
        {/if}
        <div>
            <i class="material-icons">info</i>
            Drag the red marker to adjust the coordinates
        </div>
    </div>
</div>
<style type="text/stylus">.mapDiv {
  position: relative;
  transition: height 0.3s;
  height: 100%;
  min-width: 100%;
  overflow: hidden;
}
.mapDiv.expanded {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 1px 1px 1px rgba(0,0,0,0.2);
  z-index: 9;
  height: 90vh;
  width: 80vw;
}
.map {
  height: 100%;
}
.screen-control {
  position: absolute;
  right: 10px;
  top: 10px;
  background: rgba(0,0,0,0.3);
  border-radius: 30px;
  cursor: pointer;
  box-sizing: border-box;
  height: 30px;
  width: 30px;
  padding: 3px 0;
  color: #fff;
  text-align: center;
}
.coordinates {
  position: absolute;
  bottom: 2px;
  left: 2px;
  padding: 10px;
  font-size: 10px;
  color: #fff;
  background: rgba(0,0,0,0.5);
  border-radius: 4px;
}
.coordinates > div {
  display: flex;
  align-items: center;
}
.coordinates > div i.material-icons {
  font-size: 18px;
}
.bg {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,0.5);
  z-index: 9;
}
</style>
