<script>
  import UserSearch from './UserSearch.svelte';
  import {loadFilterTypes} from './search.service';

  const filterTypes = loadFilterTypes(),
    initialFilters = ['order']
</script>

<UserSearch
  headerText="Recent Activity Report"
  {filterTypes}
  {initialFilters}
/>
