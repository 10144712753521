import {handle} from 'root/pages/hotels/profile/services/hotel.service';

import {getComments, addComment, deleteComment, editComment} from './api/end-points';

export function loadComments(accountId, page = 1, perPage = 100) {
  return handle(getComments(accountId, {page, perPage}));
}

export function updateComment(accountId, commentId, comment) {
  return handle(editComment(accountId, commentId, comment));
}

export function createComment(accountId, comment) {
  return handle(addComment(accountId, comment));
}

export function dropComment(accountId, commentId) {
  return handle(deleteComment(accountId, commentId));
}

