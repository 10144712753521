<script>
  import rbAdminPanelLogo from 'images/rb-adminPanel.png';
  import noop from 'lodash/noop.js';
  import { signIn as signInApi } from 'root/api/index.js';
  import httpNotifications from 'root/api/HttpNotifications.js';
  import InputText from 'components/molecules/inputs/InputText.svelte';
  import InputPassword from 'components/molecules/inputs/InputPassword.svelte';
  import FormModel from 'components/molecules/inputs/FormModel.svelte';
  import Home from 'root/routes/Home.js'
  import currentUserService from 'root/services/currentUserService';

  let invalid;
  let model = { emailAddress: '', password: '' };
  let authError = false;

  function signIn(){
    authError = false;
    httpNotifications
            .handle(signInApi(model.emailAddress, model.password), { onError: { '401': () => { authError = true } }})
            .then(currentUserService.refresh)
            .then( () => Home.go() )
            .catch( noop );
  }
</script>


<div class="rbPageDarkCentered">
  <FormModel bind:values={model} bind:invalid={invalid}>
    <form class="form rbCard"
          class:rbCardError="{invalid || authError}"
          on:submit|preventDefault="{signIn}">
      <div class="header">
        <img src="{rbAdminPanelLogo}" alt="ReadyBid Admin Panel Logo" class="logo"/>

        {#if authError}
          <div class="rbError">
            Your email or password is incorrect. Please try again.
          </div>
        {/if}
      </div>

      <div class="inputs">
        <InputText id="emailAddress" name="Email Address" maxLength="256">Email Address</InputText>
        <InputPassword id="password" name="Password" maxLength="256">Password</InputPassword>
      </div>

      <div>
        <button class="rbButton asDarkMain" disabled="{invalid}">Sign In</button>
      </div>
    </form>
  </FormModel>
</div>


<style type="text/stylus">.form {
  box-sizing: border-box;
  width: 320px;
  padding: 25px 8.5px 22px;
  text-align: center;
}
.header {
  height: 71px;
}
.logo {
  width: 158px;
}
.inputs :global(.rbInput) {
  margin-top: 0;
  margin-bottom: 30px;
}
</style>
