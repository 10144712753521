import { Route } from '@readybid-team/rb-spa-router';
import Mounter from '@readybid-team/rb-spa-router-svelte-mount';
import { NotFoundPage } from 'components';
import Home from './Home';

const NotFound = new Route('NotFound', '/not-found',
  Mounter(
    () => Promise.resolve({default: NotFoundPage} ),
    () => ({
      goToHome: () => { Home.go(); }
    })
  ));

export default NotFound;
