import 'components/app.styl';
import './styles/index.styl';

import BrowserUpdate from './config/browser-update-config';
import router from './routes';
import { setRouter } from './services/modals.js'
import TooltipListener from './utils/tooltip/tooltip-listener';

BrowserUpdate();
TooltipListener();
setRouter(router);

