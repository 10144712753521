export { getGoogle as Google, GooglePlacesAutocomplete, GoogleMapsGeocode, GoogleAnimation, debouncedGooglePlacesAutocomplete }

let googlePromise, autocompleteService, geocoderService

function debouncedGooglePlacesAutocomplete(timeout){
  let debouncedGooglePlacesAutocompletePromise = null,
    savedRequest

  return request => {
    savedRequest = request
    if(!debouncedGooglePlacesAutocompletePromise){

      debouncedGooglePlacesAutocompletePromise = new Promise( (resolve, reject) => {
        const timeoutId = window.setTimeout(() => {
          getAutocompleteService()
            .then( service => sendRequest(service))
            .finally( () => {
              debouncedGooglePlacesAutocompletePromise = null
              window.clearTimeout(timeoutId)
            })
        }, timeout)

        function sendRequest(service){
          return service.getPlacePredictions( savedRequest, ( results, status ) => {
            ['OK', 'ZERO_RESULTS'].indexOf(status) === -1 ? reject(status) : resolve(results)
          })
        }
      })
    }
    return debouncedGooglePlacesAutocompletePromise
  }
}

function GooglePlacesAutocomplete(request){
  return getAutocompleteService()
    .then( service => sendRequest(service))

  function sendRequest(service){
    return new Promise(( resolve, reject ) => {
      service.getPlacePredictions(request, ( results, status ) => {
        ['OK', 'ZERO_RESULTS'].indexOf(status) === -1 ? reject(status) : resolve(results)
      })
    })
  }
}

function GoogleMapsGeocode(request){
  return getGeocoder()
    .then( service => sendRequest(service))

  function sendRequest(service){
    return new Promise(( resolve, reject ) => {
      service.geocode(request, ( results, status ) => {
        ['OK', 'ZERO_RESULTS'].indexOf(status) === -1 ? reject(status) : resolve(results)
      })
    })
  }
}

function getAutocompleteService(){
  return autocompleteService ? Promise.resolve(autocompleteService) : getGoogle()
    .then( google => createService(google) )

  function createService(google){
    autocompleteService = new google.maps.places.AutocompleteService()
    return autocompleteService
  }
}

function GoogleAnimation(animation){
  return getGoogle()
    .then( ( google ) => {
      const a = animation || ''
      switch (a.toLowerCase()) {
        case 'bounce':
          return google.maps.Animation.BOUNCE
        case 'drop':
        default:
          return google.maps.Animation.DROP
      }
    })
}

function getGeocoder(){
  return geocoderService ? Promise.resolve(geocoderService) : getGoogle()
    .then( google => createService(google) )

  function createService(google){
    geocoderService = new google.maps.Geocoder()
    return geocoderService
  }
}

function getGoogle(){
  if(!googlePromise){
    googlePromise = createPromise()
  }
  return googlePromise

  function createPromise() {
    return new Promise(resolve => {
      const interval = setInterval(provideGoogle, 20)

      function provideGoogle() {
        if(window.google) {
          resolve(window.google)
          clearInterval(interval)
        }
      }
    })
  }
}
