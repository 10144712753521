import component from './BasicInfo.svelte';
export const BASIC_INFO = 'basic-info';

export default {
    component,
    id: BASIC_INFO,
    text: 'Basic Information',
    icon: 'business_center',
    isAvailable() { return true },
};
