import {writable} from 'svelte/store';
import api from 'src/pages/users/manager/users-manager-api';
import {formatUser, formatUserAccount} from 'src/pages/users/manager/formatUsers';
import {service as userAccountActions} from './actions-user-account';
import {service as userActions} from './actions-user';
import httpNotifications from 'src/api/HttpNotifications';

const
  initialState = { userId: null, user: null, loading: false },
  store = writable(initialState);

export default store;

async function initialize(user){
  const userId = user.id;
  store.set({...initialState, userId, user, loading: true});
  const res = await api.loadUserDetails(userId); // todo: HANDLE ERROR
  store.set({userId, user: formatUser(res.data), loading: false});
}

function handleUserAccountAction(actionId, userAccount){
  httpNotifications.handle(executeAndUpdate())

  function executeAndUpdate(){
    return userAccountActions.executeAction(actionId, userAccount)
      .then(ua => store.update($s => ({
        ...$s,
        user: {
          ...$s.user,
          userAccounts: $s.user.userAccounts.map(sua => sua.id === userAccount.id ? formatUserAccount(ua) : sua)
        }
      })));
  }
}

function handleUserAction(actionId, user){
  userActions.executeAction(actionId, user, u => {
    if (u) store.update($s => ({...$s, user: formatUser(u)}));
  });
}


export const service = {
  initialize,
  getUserAccountActions: userAccountActions.listAvailableActions,
  handleUserAccountAction,
  getUserActions: userActions.listAvailableActions,
  handleUserAction,
}
