<script context="module">
    const
            ARROW_UP = Symbol('ARROW_UP'),
            ARROW_DOWN = Symbol('ARROW_DOWN'),
            PAGE_UP = Symbol('PAGE_UP'),
            PAGE_DOWN = Symbol('PAGE_DOWN'),
            HOME = Symbol('HOME'),
            END = Symbol('END'),
            ENTER = Symbol('ENTER'),
            ESCAPE = Symbol('ESCAPE'),
            SPACE = Symbol('SPACE'),

            KEYBOARD_KEYS = Object.freeze({
                'ArrowUp': ARROW_UP,
                'Up': ARROW_UP,
                'ArrowDown': ARROW_DOWN,
                'Down': ARROW_DOWN,
                'PageUp': PAGE_UP,
                'PageDown': PAGE_DOWN,
                'Home': HOME,
                'End': END,
                'Enter': ENTER,
                'Esc': ESCAPE,
                'Escape': ESCAPE,
                ' ': SPACE,
                'Spacebar': SPACE,
            });
</script>

<script>
    import debounce from 'lodash/debounce';
    import { createEventDispatcher } from 'svelte';

    export let id;
    export let options = [];
    export let value = '';

    let items;
    let focused = false;
    let menuShown = false;
    let valueLabel;
    let containerElement;

    const dispatch = createEventDispatcher();

    $: items = loadOptions(options);

    $: valueLabel = updateValueLabel(value, items);

    function loadOptions(options = []){
      if(!options.length || Object.prototype.toString.call(options[0]) === '[object Object]') return options;
      return options.map( option => ({label: option, value: option }));
    }

    function updateValueLabel(value, items){
        const selectedItem = items.find(i => i.value === value);
        return selectedItem ? selectedItem.label : '';
    }

    function onSelect (index) {
        const selectionIndex = index < 0 ? 0 : (index >= items.length ? items.length - 1 : index),
                selectedItem = items[selectionIndex];
        value = selectedItem.value;
    }

    function onShowMenu(toShow){
        menuShown = typeof toShow === 'boolean' ? toShow : !menuShown;
    }

    function setFocus(v){
        focused = v;
        if(!v) onShowMenu(v);
    }

    function onKeyDown(event){
        const { key, altKey, shiftKey, ctrlKey} = event;
        if(shiftKey || ctrlKey) {
            // ignore
        } else if(altKey){
            onAltKey(key, event);
        } else {
            stopEvent(event);
            onKey(key);
        }
    }

    function onAltKey(key, event){
        switch (KEYBOARD_KEYS[key]) {
            case ARROW_UP:
                stopEvent(event);
                menuShown = false;
                break;
            case ARROW_DOWN:
                stopEvent(event);
                menuShown = true;
                break;
            default:
        }
    }

    function onKey(key){
        switch (KEYBOARD_KEYS[key]) {
            case ARROW_UP:
                selectItemByIndexDifferential(-1);
                break;
            case PAGE_UP:
                selectItemByIndexDifferential(-3);
                break;
            case HOME:
                onSelect(0);
                break;

            case ARROW_DOWN:
                selectItemByIndexDifferential(1);
                break;
            case PAGE_DOWN:
                selectItemByIndexDifferential(3);
                break;
            case END:
                onSelect(items.length - 1);
                break;

            case SPACE:
                menuShown = true;
                break;
            case ESCAPE:
                menuShown = false;
                break;
            case ENTER:
                menuShown = !menuShown;
                break;
            default:
        }
    }

    function selectItemByIndexDifferential(indexDiff){
        const indexOfCurrentlySelectedItem = items.findIndex( o => o.value === value ) || 0;
        onSelect(indexOfCurrentlySelectedItem + indexDiff);
    }

    function stopEvent(event){
        if(event) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

</script>

<div class="rbInput component"
     tabindex="0"
     {id}
     on:focus="{() => setFocus(true)}"
     on:blur="{() => { setFocus(false); dispatch('blur'); }}"
     on:keydown="{onKeyDown}"
     bind:this="{containerElement}">

    <slot {items}
          {valueLabel}
          {focused}
          {menuShown}
          {onSelect}
          {onKeyDown}
          {onShowMenu}
    />
</div>

<style lang="stylus">.component {
  width: 100%;
  outline: 0;
  text-align: left;
  cursor: pointer;
  position: relative;
}
</style>
