<script>
    import Spinner from '../../atoms/Spinner.svelte';
    import get from 'lodash/get';

    export let headers = [{
        key: '',
        title: '',
        size: 's',
    }];
    export let contentPromise;
    export let dark;


    function columnSize({size = 's'}) {
        return `${size}Column`;
    }
</script>

<div class="rbTable" class:rbDarkTable={dark}>
    <div class="rbTableRowHead">
        {#each headers as col}
            <div class:rbTableColumnHead={true} class={columnSize(col)}>{col.title}</div>
        {/each}
    </div>
    {#await contentPromise}
        <Spinner></Spinner>
    {:then rows}
        {#each rows as row}
            <div class="rbTableRow">
                {#each headers as col}
                    <div class:rbTableColumn={true} class={columnSize(col)}>{get(row, col.key, '')}</div>
                {/each}
            </div>
        {:else}
            <div class="rbTableNoDataRow">
                No Data available.
            </div>
        {/each}
    {/await}
</div>

