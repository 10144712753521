<script>
  import {DateTimeFormatter} from 'src/utils/internationalization.service';

  export let request = {};

  const dateTimeFormat = DateTimeFormatter(' ');

  let by = '', at = '', requestDetails = '', requestType = '';

  $: by = formatBy(request.requested);
  $: at = formatAt(request.requested);
  $: requestType = formatRequestType(request.requestType);
  $: requestDetails = printDetails(by, at);

  function formatBy(r = {}){
    if(r.by && r.by.emailAddress){
      return `By ${r.by.firstName} ${r.by.lastName} <${r.by.emailAddress}>`
    } else {
      return '';
    }
  }

  function formatAt(r = {}){
    if(r.at){
      return dateTimeFormat.format(new Date(r.at));
    } else {
      return '';
    }
  }

  function formatRequestType(r = ''){
    return r === 'REMOVE' ? 'REMOVE/REBRAND' : r;
  }

  function printDetails(b = '', a = ''){
    return b ? (a && a.length > 1 ? `${b}, ${a}` : b) : a;
  }

</script>

<div class="Header">
  <div>Request to <b>{requestType}</b></div>
  <small>{requestDetails}</small>
</div>

<style lang="stylus">.Header {
  font-size: 14px;
  color: #fff;
  padding: 10px 20px;
}
</style>
