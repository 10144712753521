<script>
  import InputText from 'components/molecules/inputs/InputText.svelte';
  import FormModel from 'components/molecules/inputs/FormModel.svelte';
  import {onMount} from 'svelte';
  import {formContextWrapper} from 'src/utils/email-address-validity-form-wrapper';

  export let
    cancel = () => {},
    values = {},
    invalid = true,
    setValues = () => {},
    touchAll = () => {},
    untouchAll = () => {},
    fixEmailAddress = () => {};

  let formContext, getFormModel, checkEmailAddress = () => {};

  $: checkEmailAddress(formContext && $formContext.values.emailAddress);

  onMount(() => {
    formContext = getFormModel();
    checkEmailAddress = formContextWrapper(formContext);
  })

</script>


<FormModel bind:values bind:invalid bind:setValues bind:untouchAll bind:touchAll bind:getFormModel>
  <form class="Form" on:submit|preventDefault="{fixEmailAddress}">
    <InputText id="firstName"
               name="First Name"
               maxLength="50"
               required
               disabled="{values.isUser}"
    >First Name</InputText>

    <InputText id="lastName"
               name="Last Name"
               maxLength="50"
               required
               disabled="{values.isUser}"
    >Last Name</InputText>

    <InputText id="emailAddress"
               name="Email Address"
               maxLength="256"
               required
               pattern="{/.*@.*/}"
               disabled="{values.isUser}"
    >Email Address</InputText>

    <InputText id="phone"
               name="Phone"
               maxLength="20"
               disabled="{values.isUser}"
    >Phone</InputText>

    <InputText id="jobTitle"
               name="Job Title"
               maxLength="100"
               disabled="{values.isUser}"
    >Job Title</InputText>

    <div class="Actions">
      <button class="rbButton asDark asLarge" type="button" on:click={cancel}>Cancel</button>

      <button class="rbButton asDark asDarkMain asLarge" data-icon="save"
              disabled="{invalid}">Save and Send</button>
    </div>
  </form>
</FormModel>

<style lang="stylus">.Form {
  padding: 0 15px;
}
.Form :global(.rbInput) {
  min-height: 55px;
}
.Actions {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}
</style>
