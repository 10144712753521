<script>
    import FormModel from 'components/molecules/inputs/FormModel.svelte';
    import NotificationTemplate from 'components/modals/NotificationTemplate.basic.svelte';

    import EditForm from './Create.form.svelte';
    import {getModelFromObect} from 'root/utils';

    export let hide, cancel, chain, chains = [];

    let values = getModelFromObect(chain), isMaster = chain.subtype === 'MASTER';
</script>

<NotificationTemplate>
    <FormModel bind:values>
        <div class="container">
            <div class="header">
                <div class="title">
                    {!isMaster? 'Create New Brand': 'Create new Master Chain'}
                </div>
            </div>

            <EditForm {hide} {cancel} {chains} {isMaster}/>
        </div>
    </FormModel>
</NotificationTemplate>

<style type="text/stylus">.container {
  padding: 25px 15px 20px;
}
.header {
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 14px;
  font-weight: bold;
}
</style>
