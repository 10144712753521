<script>
    import FormModel from 'components/molecules/inputs/FormModel.svelte';
    import NotificationTemplate from 'components/modals/NotificationTemplate.basic.svelte';

    import EditForm from './CreateAndEditRep.form.svelte';
    import {getModelFromObect} from 'root/utils';

    export let hide, cancel, user = {}, editing, hotel;

    let values = getModelFromObect(user);
</script>

<NotificationTemplate>
    <FormModel bind:values>
        <div class="container">
            <div class="header">
                <div class="title">
                    {!editing? 'Create New Hotel Representative': 'Edit Hotel Representative'}
                </div>
                <div class="subTitle">
                    for <b>{hotel.name}</b>
                </div>
            </div>

            <EditForm {hide} {cancel} />
        </div>
    </FormModel>
</NotificationTemplate>

<style lang="stylus">.container {
  padding: 25px 15px 20px;
  color: #b0bec5;
}
.header {
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 14px;
  font-weight: bold;
}
.subTitle {
  font-size: 10px;
  opacity: 0.5;
}
</style>
