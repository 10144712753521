<script>
  import FormModel from 'components/molecules/inputs/FormModel.svelte';
  import NotificationTemplate from 'components/modals/NotificationTemplate.basic.svelte';
  import EditForm from './EditRep.form.svelte';
  import { createUser } from '../../../../services/hotel.service';


  export let hide, cancel, contact = {}, entity = {};

  let values = {};

  $: values = createModel(contact)

  function sendInvitation(data){
    createUser(data.entityId, data.entityType, data)
      .then(result => hide(result));
  }

  function createModel(c){
    return {
      entityId: c.entityId,
      entityType: c.accountType,
      firstName: c.firstName,
      lastName: c.lastName,
      emailAddress: c.emailAddress,
      title: c.jobTitle,
      phone: c.phone
    };
  }

</script>

<NotificationTemplate>
  <FormModel bind:values>
    <div class="container">
      <div class="header">
        <div class="title">
          Send Invitation to {contact.firstName} {contact.lastName}
        </div>
        <div class="subTitle">
          for <b>{entity.name}</b>
        </div>
      </div>

      <EditForm hide="{sendInvitation}" {cancel} />
    </div>
  </FormModel>
</NotificationTemplate>

<style lang="stylus">.container {
  padding: 25px 15px 20px;
}
.header {
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 14px;
  font-weight: bold;
}
.subTitle {
  font-size: 10px;
  opacity: 0.5;
}
</style>
