const id = 'required';

export default {
  create(config){
    return {
      id,
      isInvalid (value, values, context, name) {
        return config && !value && {
          id,
          message: `${ name } is required`
        };
      }
    }
  },
}
