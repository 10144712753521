<script>
    /**
     * Form Abstraction
     *
     * Centralizes Form handling
     *
     * FormModel is defined as a parent to all InputModels (like InputText, InputPassword...)
     * Form values, as well as state, can be read or set from FormModel itself. There is no need to set listeners on InputModels
     * InputModels ids must match with FormModel values ids in order to link them
     * All Validation is set on InputModels.
     *
     * @param values {Object} FormModel values. Bind to it to receive changes, and set INITIAL FormModel values. To update value afterwards use setValue or setValues
     * @param context {Object} Form context. If there are some constants needed for validation, they are set here. Must be set at initialization.
     * @param valid {boolean} Is form data valid
     * @param invalid {boolean} Is form data invalid
     * @param touched {boolean} Has form been manipulated by the user
     * @param untouched {boolean} Has form not been manipulated by the user
     * @param setValue {Function} Set value to Form Data
     * @param setValues {Function} Replaces Form Data values
     * @param touchAll {Function} Marks all Inputs as touched and allows error messages to be displayed
     */
    import { setContext } from 'svelte';
    import FormModelStore from '../../validation/FormModelStore.js';

    export let values;
    export let context;
    export let valid;
    export let invalid;
    export let touched;
    export let untouched;

    let formModel = FormModelStore(values, context);
    setContext('formModel', formModel);
    $: values = $formModel.values;
    $: valid = $formModel.valid;
    $: invalid = $formModel.invalid;
    $: touched = $formModel.touched;
    $: untouched = $formModel.untouched;

    export const setValue = (id, value) => formModel.setValue(id, value);
    export const setValues = newValues => formModel.setValues(newValues);
    export const touchAll = () => formModel.touchAll();
    export const untouchAll = () => formModel.untouchAll();
    export const getFormModel = () => formModel;
</script>

<slot></slot>
