<script>
    export let actions = [], execute, icon = 'more_vert', align = 'left', asDarkMain = false, label = 'More Actions';
    let open = false;

    $: style = align === 'left'? `left: 0`: 'right: 0';
</script>

{#if actions && actions.length > 0}
    {#if open}
        <div class="bg"  on:click={() => open = !open}></div>
    {/if}

    <div class="more">
        <button class="rbButton asDark" class:asDarkMain data-icon={icon} on:click={() => open = !open}>
            {label}
            <i class="material-icons">{open?'keyboard_arrow_up':'keyboard_arrow_down'}</i>
        </button>
        {#if open}
            <div class="Menu rbDark" style={style}>
                {#each actions as action}
                    <div class="Item" on:click={() => { open = false; execute(action);}}>
                        <i class="material-icons">{action.icon}</i>
                        {action.label}
                    </div>
                {/each}
            </div>
        {/if}
    </div>
{/if}

<style type="text/stylus">.more {
  position: relative;
  z-index: 2;
  margin: 0 5px;
  display: inline-flex;
}
.bg {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
}
.Menu {
  min-width: 200px;
  position: absolute;
  top: 40px;
  padding: 10px 0;
  border-radius: 5px;
}
.Item {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.Item .material-icons {
  margin-right: 5px;
}
.Item:hover {
  background: #313b42;
}
</style>

