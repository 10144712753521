import noop from 'lodash/noop';

class ModalsMount {

  constructor(container, dialogComponent, dialogData) {
    this.promise = new Promise((resolve, reject) => {
      this.$$promiseResolve = resolve;
      this.$$promiseReject = reject;
    });

    this.$$instance = new dialogComponent({
      target: container,
      props: {
        ...dialogData,
        cancel: reason => this.cancel(reason),
        hide: reason => this.hide(reason),
      },
      intro: true,
    });
  }

  cancel (reason) { return this.$destroy( () => { this.$$promiseReject(reason) } ) }

  hide (reason) { return this.$destroy( () => { this.$$promiseResolve(reason) } ) }

  $destroy( action = noop){
    this.$$instance.$destroy();
    action();
    return this.promise
  }
}

export default ( container, dialogComponent, props ) =>
  new ModalsMount(container, dialogComponent, props )
