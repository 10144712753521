<script>
  import FormModel from "components/molecules/inputs/FormModel.svelte";
  import NotificationTemplate from "components/modals/NotificationTemplate.basic.svelte";

  import EditForm from "./Password.form.svelte";
  import { getModelFromObect } from "root/utils";

  export let hide, cancel, user;

  let values = getModelFromObect({ password: "" });
</script>

<style type="text/stylus">.container {
  padding: 25px 15px 20px;
}
.header {
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 14px;
  font-weight: bold;
}
</style>

<NotificationTemplate>
  <FormModel bind:values>
    <div class="container">
      <div class="header">
        <div class="title">
          Update the Password for
          <b>{user.firstName} {user.lastName}</b>
        </div>
      </div>

      <EditForm {hide} {cancel} />
    </div>
  </FormModel>
</NotificationTemplate>
