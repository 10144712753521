<script>
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let results = null;

    $: min = (results.page - 1) * results.perPage;
    $: length = results.data && results.data.length;
    $: max = min + length;
    $: firstPage = results.page === 1;

    function go(action) {
        dispatch(action);
    }
</script>

{#if results && results.total > 0}
    <div class="paginator">
        <div class="results-count">
            <div class="pages">{ min + 1 } - {max}</div>
            <div class="total">of {results.total}</div>
        </div>
        <button class="rbButton asDark iconOnly" data-icon="navigate_before" on:click={ () => go('prev')} disabled={ firstPage }></button>
        <button class="rbButton asDark iconOnly" data-icon="navigate_next" on:click={() => go('next') } disabled={ results.lastPage }></button>
    </div>
{/if}

<style type='text/stylus'>.paginator {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
}
.results-count {
  display: flex;
}
.pages {
  margin: 0 5px;
  color: #fff;
}
.total {
  opacity: 0.6;
}
</style>
