<script>
  import {get} from 'lodash';
  import {viewProfile} from './actions.service';
  import BidsStatsDialog from './BidsStats.dialog.svelte';
  import modals from 'root/services/modals';

  export let
    collection,
    hotel = {},
    config = {};

  let
    viewData = {},
    hasAction;

  $: viewData = getValue(hotel);
  $: hasAction = getAction(viewData.value)

  function getValue() {
    if(typeof config.key === 'function'){
      return config.key(hotel);
    } else {
      let v = get(hotel, config.key, null);
      if(config.key === 'rejectionMessage') {
        return {value: !v ? 'Pending': 'Rejected', tooltip: v }
      } else {
        return {value: v};
      }
    }
  }

  function getAction(value){
    return typeof config.key === 'string' && (config.key === "name" || (value !== null && config.key.startsWith("bids")))
  }

  function open(){
    switch (config.key) {
      case "name":
        return viewProfile(hotel, undefined, collection);
      case "bids.DIRECT.count":
        return showBidsDetails("Direct Bids", get(hotel, "bids.DIRECT"));
      case "bids.CHAIN.count":
        return showBidsDetails("Chain Bids", get(hotel, "bids.CHAIN"));
      case "bids.COMPETITIVE.count":
        return showBidsDetails("Competitive Bids", get(hotel, "bids.COMPETITIVE"));
    }
  }

  function showBidsDetails(title, bidsStats){
    modals.show(BidsStatsDialog, {title, bidsStats, hotel})
  }
</script>

<div
  class="Cell"
  class:Link="{hasAction}"
  class:hasTip={viewData.tooltip}
  style="--table-cell-width:{config.width};"
  on:click|stopPropagation={hasAction && open}
>
  {viewData.value || '---'}

  {#if viewData.tooltip}
    <div class="Tip">
      {viewData.tooltip}
    </div>
  {/if}
</div>

<style type="text/stylus">.Cell {
  flex: 1 1 auto;
  min-width: var(--table-cell-width, auto);
  margin: 0 5px;
  overflow: hidden;
  position: relative;
}
.Tip {
  position: absolute;
  top: 100%;
  display: none;
  background: #fff;
  padding: 10px;
  right: 0;
  min-width: 200px;
  box-shadow: 0 0 2px 2px rgba(0,0,0,0.1);
  box-sizing: border-box;
  border-radius: 5px;
}
.hasTip {
  overflow: visible;
  cursor: pointer;
  font-weight: bold;
}
.hasTip:hover .Tip {
  display: block;
  font-weight: normal;
}
.Link {
  cursor: pointer;
  text-decoration: underline;
}
.Link:hover {
  text-decoration: none;
}
</style>
