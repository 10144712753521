<script>
  import { Profile as route } from "root/routes/AdminUserSearch";
  import accountTypes from "root/assets/data/account-types";
  import Header from "root/components/Header.svelte";
  import Navigation from "./Navigation.component.svelte";
  import { currentModule } from "./modules";
  import { getUser } from "./services/profile";
  import { changePassword, refreshPins } from "../search/actions.service";

  const {
    params: { userId, section }
  } = route.$createStateFromURL(window.location.hash);
  let user, Page;

  function refresh() {
    getUser(userId).then(u => (user = u));
  }

  refresh();

  function password() {
    changePassword(user).then(refresh);
  }

  function updatePin() {
    refreshPins([user]).then(refresh);
  }

  $: Page = currentModule(section);
</script>

<style type="text/stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.content :global(.section .title) {
  width: 100%;
  border-bottom: 1px solid #37474f;
  font-size: 14px;
  font-weight: normal;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.content :global(.main) {
  height: calc(100vh - 158px);
  overflow: auto;
}
.content :global(.main .section .content) {
  padding: 0 15px;
  font-size: 12px;
}
.content :global(.main .section .content .data) {
  margin: 10px 0;
  display: flex;
  align-items: center;
}
</style>

<div class="rbPage rbDark">
  <Header />
  <div class="pageContainer">
    <Navigation
      {section}
      {userId}
    />
    <div class="content">
      {#if user}
        <svelte:component this={Page} {section} bind:user>
          <button
            class="rbButton asDark"
            data-icon="refresh"
            on:click={updatePin}>
            Generate New Pin
          </button>
          <button
            class="rbButton asDark"
            data-icon="password"
            on:click={password}>
            Change Password
          </button>
        </svelte:component>
      {/if}
    </div>
  </div>
</div>
