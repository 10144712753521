import route from 'root/routes/EntityProfile';
import BasicInfo from './basic-info/basic-info.module';
import Companies from './companies/companies.module';
import Users from './users/users.module';
import Brands from './brands/brands.module';
import Account from './account/account.module';

const modules = {};

const {params: { type }} = route.$createStateFromURL(window.location.hash);

(function() {
    load(BasicInfo);
    load(Users);
    load(Account);
    load(Companies);
    load(Brands);

    function load(module) {
        modules[module.id] = module;
    }
})();

export function navigationItems(subType) {
    return Object.values(modules).filter(({isAvailable}) => isAvailable(type, subType));
}


export function currentModule(module) {
    const current = modules[module];
    return current && current.component;
}
