<script>
    import NotificationTemplate from 'components/modals/NotificationTemplate.basic.svelte';
    import Checkbox from 'root/pages/hotels/advanced-search/Checkbox.component.svelte';


    export let hide, cancel, chains;

    let id;
</script>

<NotificationTemplate>
  <div class="container">
    <div class="header">
      <div class="headerContent">
        <div class="title">Select New Master Chain</div>
      </div>
    </div>

    <div class="content">
        <div class="chain" on:click={() => id = -1}>
            <Checkbox selected={id === -1} />
            <span> --- No Master Chain --- </span>
        </div>
        {#each chains as chain}
            <div class="chain" on:click={() => id = chain.value}>
                <Checkbox selected={chain.value === id} />
                <span>
                    {chain.label}
                </span>
            </div>
        {/each}
    </div>

    <div class="footer">
        <button class="rbButton asModal" on:click="{cancel}">Cancel</button>
        <button class="rbButton asModalMain" data-icon="save" on:click="{() => hide({masterChainId: id === -1? null: id})}" disabled={!id}> Save </button>
    </div>
  </div>
</NotificationTemplate>

<style type="text/stylus">.container {
  padding: 25px 15px 20px;
}
.header {
  display: flex;
  flex-direction: row;
}
.headerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  height: 20px;
  font-size: 15px;
  vertical-align: text-bottom;
  color: #546e7a;
  text-shadow: 1px 0 0 rgba(0,0,0,0.15);
  font-weight: normal;
  line-height: 1;
  padding: 0;
  margin: 0;
}
.content {
  margin: 20px 0;
  font-size: 13px;
  line-height: 16px;
  color: #546e7a;
  max-height: 300px;
  overflow: auto;
  border: 1px solid rgba(0,0,0,0.1);
}
.chain {
  padding: 5px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  cursor: pointer;
}
.footer {
  text-align: right;
}
</style>
