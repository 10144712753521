<script>
  import ImagePack from 'images/image-pack.svg';

  export let name = 'exclamation';

  let href = `${ImagePack}#${name}`;
</script>

<div>
  <svg class="svg" xmlns="http://www.w3.org/2000/svg">
    <use {href}></use>
  </svg>
</div>

