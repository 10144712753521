<script>
  import { onMount, onDestroy } from 'svelte';
  import Popper from 'popper.js';

  export let
    targetElement,
    isDarkStyle,
    placement = 'bottom-end';

  let
    elementPop,
    pop;

  onMount(() => {
    pop = new Popper(targetElement, elementPop, {
      placement,
      modifiers: {
        preventOverflow: {
          boundariesElement: 'window',
        },
      }
    })
  });

  onDestroy(() => {
    if(pop) pop.destroy();
  });
</script>

<div bind:this={elementPop}
     class="Container"
     class:atDark={isDarkStyle}
>
  <slot />
</div>

<style lang="stylus">.Container {
  max-width: 350px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 300;
  border-radius: 5px;
  margin: 3px;
  padding: 3px 0;
  background: #fff;
  border: 1px solid #b0bec5;
  color: #546e7a;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.3);
}
.Container.atDark {
  max-width: 350px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 300;
  border-radius: 5px;
  margin: 3px;
  padding: 3px 0;
  background: #263238;
  border: 1px solid #1a2226;
  color: #90a4ae;
  box-shadow: 0 0 10px 0 rgba(144,164,174,0.2);
}
</style>

