<script>
  import {sampleSize, shuffle} from 'lodash';
  import FormModel from 'components/molecules/inputs/FormModel.svelte';
  import NotificationTemplate from 'components/modals/NotificationTemplate.basic.svelte';
  import InputText from 'components/molecules/inputs/InputText.svelte';
  import InputPhone from 'components/molecules/inputs/InputPhone.svelte';
  import InputEmail from 'components/molecules/inputs/InputEmail.svelte';
  import Select from 'components/molecules/inputs/SelectModel.svelte';

  import HttpNotification from 'root/api/HttpNotifications';
  import {createUserWithPassword} from 'src/pages/users/api';

  export let hide, cancel, entity;

  const accountTypes = [
    { value: 'ACCOUNT_ADMIN', label: 'Administrator' },
    { value: 'ACCOUNT_MANAGER', label: 'Account Manager'}
  ];

  let touchAll, formValid,
    values = {
      entityId: entity.id,
      entityType: entity.type,
      password: generatePassword(),
      role: 'ACCOUNT_ADMIN'
    };

  async function saveUser() {
    touchAll();
    if(formValid) {
      try {
        const response = await HttpNotification.handle(createUserWithPassword(values))
        hide(response);
      } catch (e) {
        console.error(e)
        cancel();
      }
    }
  }

  function generatePassword(lowerCaseAlpha = 7, upperCaseAlpha = 7, numbers = 3, special = 3){
    const
      pickedChars = sampleSize('abcdefghijklmnopqrstuvwxyz', lowerCaseAlpha)
        .concat(sampleSize('ABCDEFGHIJKLMNOPQRSTUVWXYZ', upperCaseAlpha))
        .concat(sampleSize('0123456789', numbers))
        .concat(sampleSize('!$%^&*()', special));
    return shuffle(pickedChars).join('');
  }
</script>

<NotificationTemplate>
  <FormModel bind:values bind:touchAll bind:valid={formValid}>
    <div class="container">
      <div class="header">
        <div class="title">Create User</div>
        <div class="subTitle">for <b>{entity.name}</b></div>
      </div>

      <div class="content">
        <div class="twoColumns">
          <InputText id="firstName" name="First Name" required="true" maxLength="100">First Name</InputText>
          <InputText id="lastName" name="Last Name" required="true" maxLength="100">Last Name</InputText>
        </div>

        <InputEmail id="emailAddress" name="Email Address" maxLength="255" required="true">Email Address</InputEmail>
        <InputText id="password" name="Password" required="true" readonly="true">Password</InputText>

        <div class="twoColumns">
          <InputText id="jobTitle" name="Job Title" required="true" maxLength="100">Job Title</InputText>
          <InputPhone id="phone" name="Phone" maxLength="30">Phone</InputPhone>
        </div>

        <Select id="role" name="Account Role" options="{accountTypes}" required="true">Account Role</Select>


        <div class="Additional">
          <InputEmail id="additionalReceiver" name="Additional Email Address" maxLength="255">Additional Email Address to send credentials to</InputEmail>
        </div>
      </div>

      <div class="footer">
        <button class="rbButton asModal" on:click="{cancel}">Cancel</button>
        <button class="rbButton asModalMain" data-icon="save" on:click={saveUser}>Save</button>
      </div>
    </div>
  </FormModel>
</NotificationTemplate>

<style lang="stylus">.container {
  padding: 15px 15px 20px;
}
.header {
  display: flex;
  flex-direction: column;
  color: #b0bec5;
}
.title {
  font-size: 14px;
  font-weight: bold;
}
.subTitle {
  font-size: 12px;
  opacity: 0.7;
}
.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
.footer {
  margin-top: 30px;
  text-align: right;
}
.Additional {
  margin-top: 40px;
}
</style>
