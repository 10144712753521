import BasicInfo from './basic-info';

const modules = {};

(function() {
    load(BasicInfo);

    function load(module) {
        modules[module.id] = module;
    }
})();

export function navigationItems() {
    return Object.values(modules).filter(({isAvailable}) => isAvailable());
}


export function currentModule(module) {
    const current = modules[module];
    return current && current.component;
}
