<script>
  import Navigation from "root/components/ProfileNavigation.svelte";
  import NavigationItem from "components/organisms/Navigation/NavigationItem.svelte";
  import { Profile } from "root/routes/AdminUserSearch";
  import { navigationItems } from "./modules";

  export let section;
  export let userId;

  $: links = [...navigationItems()];

  function href({ id }) {
    return id && Profile.href({ userId, section: id });
  }

  function opened({ subItems }) {
    return subItems && subItems.find(isActive);
  }

  function isActive({ id }) {
    return section === id;
  }
</script>

<style type="text/stylus">.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.navigation :global(.navigation) {
  height: calc(100vh - 45px);
}
.navigation :global(.navigation .links) {
  height: calc(100vh - 185px);
}
</style>

<div class="rbCard navigation">
  <Navigation let:minimized let:link links={[...links]}>
    <NavigationItem
      {...link}
      {minimized}
      open={opened(link) || isActive(link)}
      active={opened(link) || isActive(link)}
      href={href(link)}>
      {link.text}
    </NavigationItem>
  </Navigation>
</div>
