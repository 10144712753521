import adminServer from 'src/api/AdminServer';
import {checkAndExecute} from 'src/api/helpers';

const emailAddressBlacklistURI = '/fix-email-address/email-addresses';

export function list() {
  return adminServer.get(emailAddressBlacklistURI);
}

export function remove(address){
  return checkAndExecute(
    () => address,
    () => adminServer.axios.delete(emailAddressBlacklistURI, {params: {address}})
  );
}
