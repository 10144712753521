<script>
  import {default as store, handleSelectAll, applyFilters, setTextFilter, setSort, generateCompetitiveBids} from './Rfps.store';
  import {clearAllFilterValues} from 'src/components/filters/Filters.store';
  import HeadingTitle from 'src/components/DocumentHeadingTitle.svelte';
  import SearchInput from 'src/components/inputs/SearchInput.svelte';
  import LabeledSelect from 'src/components/inputs/LabeledSelect.svelte';
  import VirtualList from 'src/components/VirtualList.svelte';
  import FilterBar from 'src/components/filters/FilterBar.svelte';
  import RfpRow from "./RfpRow.svelte";
  import Spinner from 'components/atoms/Spinner.svelte';
  import getSelectionIcon from 'src/utils/selection-icons.service';

  function clearFilterValues(){
    clearAllFilterValues();
    setTextFilter('');
  }

</script>

<div class="DocumentViewContainer">
  <div class="DocumentViewHeading">
    {#if $store.selectionPercentage === 0}
      {#if $store.pendingCount === 0}
        <HeadingTitle icon="folder_special"
                      title="Competitive Bids - Active RFPs"
                      subtitle={'Click on "filters" to see all filter options'}
        />
      {:else}
        <HeadingTitle icon="folder_special"
                      title="Competitive Bids - Active RFPs"
        >
          <div class="GeneratingTitle" slot="subtitle">
            <div class="Spinner"><Spinner/></div>
            <div>Generating Competitive Bids for {$store.pendingCount} RFP{$store.pendingCount === 1 ? '' : 's'}</div>
          </div>
        </HeadingTitle>
      {/if}
    {:else}
      <div class="DocumentViewHeadingActionsInline">
        <div class="DocumentViewHeadingAction">
          <button class="rbButton asDark"
                  data-icon="star"
                  on:click={() => generateCompetitiveBids()}
          >
            Generate COMP Bids
          </button>

          <button class="rbButton asDark"
                  data-icon="send"
                  on:click={() => generateCompetitiveBids({autosend: true})}
          >
            Generate COMP Bids and AutoSend
          </button>
        </div>
      </div>
    {/if}

    <div class="DocumentViewHeadingActionsInline">
      <div class="DocumentViewHeadingAction">
        <SearchInput value="{$store.textFilter}"
                     on:change="{e => setTextFilter(e.detail)}"
        />
      </div>

      <div class="DocumentViewHeadingAction">
        <LabeledSelect label="Sort by"
                       value={$store.sortedBy}
                       options={$store.sortByOptions}
                       on:change={ e => setSort(e.detail) }
        />
      </div>

      <div class="DocumentViewHeadingAction">
        <button class="rbButton asDark"
                data-icon="delete_sweep"
                on:click="{() => clearFilterValues()}"
        >Clear All Filters</button>
      </div>
    </div>
  </div>

  <div class="TableHeading RainbowBottomBorder">
    <button class="rbButton iconTransparent"
            class:Selected="{$store.selectionPercentage === 1}"
            data-icon="{getSelectionIcon($store.selectionPercentage)}"
            on:click={() => handleSelectAll()}
    ></button>

    <FilterBar on:change="{() => applyFilters()}" />
  </div>

  <div class="DocumentViewContentContainer">
    <VirtualList items="{$store.rfps}"
                 let:item={rfp}
                 itemHeight={84}
    >
      <div slot="row">
        <RfpRow {rfp} />
      </div>
    </VirtualList>
  </div>

</div>

<style lang="stylus">.DocumentViewContentContainer {
  background: #313c42;
}
.TableHeading {
  background: #313c42;
  display: flex;
}
.TableHeading:before {
  padding: 0 15px;
  color: #57666f;
}
.rbButton.iconTransparent {
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0 15px;
  color: #57666f;
}
.rbButton.iconTransparent:before {
  width: 24px;
}
.rbButton.iconTransparent.Selected {
  color: #00a99d;
}
.GeneratingTitle {
  height: 20px;
  display: flex;
  align-items: center;
  color: #00a99d;
}
.Spinner {
  transform: scale(0.3) translateX(-20px);
  width: 20px;
}
</style>
