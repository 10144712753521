<script>
    import {noop, orderBy, take} from 'lodash';

    import DropDown from './ActionsHelperDropDown.component.svelte';

    const N_TO_SHOW = 3;

    export let actions, params = [], actionCompleted = noop;
    $: ordered = orderBy(actions, ['primary'], ['asc']);
    $: primary = take(ordered, N_TO_SHOW);
    $: secondary = ordered.slice(N_TO_SHOW);

    function execute({id, action}){
        action(...params).then((res) => actionCompleted({res, id}))
    }
</script>


<div class="actions">
    {#each primary as action}
        <button class="rbButton asDark" data-icon={action.icon} on:click={() => execute(action)}> { action.label } </button>
    {/each}
    <DropDown {execute} actions={secondary} />
</div>

<style type="text/stylus">.actions {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
</style>

