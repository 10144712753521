<script>
    import { scale } from 'svelte/transition';

    export let user = {};

    let dropdownOpened = false
</script>

<div class="current-user">
    <div class="toggler" on:click="{() => { dropdownOpened = !dropdownOpened }}">
        <div>
            <div>Hi {user.firstName}</div>
            <div class="company-name">{user.companyName}</div>
        </div>

        <img class="user-image" src={user.profileImage} alt={user.firstName} />

        <div class="material-icons">{ 'keyboard_arrow_' + (dropdownOpened ? 'up' : 'down') }</div>
    </div>

    {#if dropdownOpened}
        <div class="bg" on:click="{() => dropdownOpened = false }"></div>
        <div class="dropdown"  transition:scale>
            <ul class="links">
                <slot></slot>
            </ul>
        </div>
    {/if}
</div>

<style type="text/stylus">.current-user {
  position: relative;
}
.toggler {
  display: flex;
  height: 42px;
  align-items: center;
  cursor: pointer;
}
.user-image {
  height: 42px;
  width: 42px;
  object-fit: cover;
  margin-left: 10px;
  border-radius: 3px;
}
.company-name {
  font-size: 10px;
  text-align: right;
}
.dropdown {
  position: absolute;
  top: 45px;
  right: 0;
  min-height: 60px;
  min-width: 100px;
  background: #fff;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.1);
  z-index: 999;
}
.links {
  padding: 4px 0;
  list-style-type: none;
}
.links :global(.dropdown-divider) {
  border: 0;
  display: block;
  height: 1px;
  margin: 0;
  overflow: hidden;
  background-color: rgba(120,130,140,0.13);
  padding: 0;
}
.links :global(.link > a ) {
  text-align: left;
  text-decoration: none;
  padding: 0 10px;
  font-size: 12px;
  height: 32px;
  min-width: 190px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: #263238;
  line-height: 2;
}
.links :global(.link) {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.links :global(.link):hover,
.links :global(.link):visited,
.links :global(.link):hover:visited {
  background-color: #eceff1;
  border-color: #eceff1;
  color: #263238;
}
.links :global(.link > a > .material-icons.icon) {
  line-height: 32px;
  font-size: 18px;
  color: #90a4ae;
  margin-right: 10px;
  text-align: center;
  width: 24px;
}
.bg {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0,0.01);
}
</style>
