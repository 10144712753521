<div class="Notice"><slot>Notice</slot></div>

<style lang="stylus">.Notice {
  flex: 0 0 auto;
  text-align: right;
  color: #e65622;
  font-size: 13px;
  padding: 10px;
}
</style>
