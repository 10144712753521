const id = 'in';

export default {
  create(options = []){
    return {
      id,
      isInvalid (value, values, context, name) {
        return options.length && !options.includes(value) && {
          id,
          message: `${ value } is not a valid option for ${ name }`
        };
      }
    }
  },
}
