import adminServer from 'root/api/AdminServer';
import {checkAndExecute} from 'root/api/helpers';

export function loadBids(days) {
  return adminServer.get(`fix-email-address/bids`, {days});
}

export function loadRepresentatives(entityId){
  return checkAndExecute(
    () => entityId,
    () => adminServer.get('fix-email-address/representatives', {entity:entityId})
  );
}

export function loadSubscribers(){
  return adminServer.get('fix-email-address/subscribers');
}


export function isDeliverable(emailAddress){
  return checkAndExecute(
    () => emailAddress,
    () => adminServer.post('email-address/is-deliverable', {emailAddress})
  );
}

export function setRepresentative(data){
  return adminServer.post('supplier-contact/fix-email-address/set-replacement', data);
}

export function chargeableReport(data){
  return checkAndExecute(
    () => data.start && data.end,
    () => adminServer.getAndAutoSaveFile('fix-email-address/chargeable/report', data)
  );
}

export function markEmailAsUnfixable(emailId){
  return checkAndExecute(
    () => emailId,
    () => adminServer.post('fix-email-address/unfixable', {emailId})
  );
}
