<script>
    import Route from 'root/routes/Chains';
    import debounce from 'lodash/debounce';
    import get from 'lodash/get';

    import Header from '../../components/Header.component.svelte';
    import ResultsTable from 'root/pages/hotels/advanced-search/ResultsTable.component.svelte';
    import CheckBox from 'root/pages/hotels/advanced-search/Checkbox.component.svelte';
    import Select from 'components/molecules/inputs/Select.svelte';
    import Actions from 'root/pages/hotels/advanced-search/actions/Actions.component.svelte';
    import Chain from 'root/pages/chains/search/Chain.component.svelte';
    import {loadActions} from 'root/pages/chains/search/actions.service';
    import {loadChainBrands} from 'root/pages/chains/search/search.service'

    export let entity, typeName;

    let promise = null,
        selectedChains = [],
        loading = false,
        sorter = {
            dir: 'asc',
            by: 'name-asc',
            key: 'name',
        },
        props = [
            {
                label: 'Code',
                key: 'code',
            }
        ],
        propWithSizes = [], results = [];

    $: allSelected = selectedChains.length > 0 && selectedChains.length === results.length;
    $: someSelected = selectedChains.length > 0 && selectedChains.length < results.length;

    function load(page = 1) {
        loading = true;

        promise = loadChainBrands(entity.id).then(brands => {
            results = brands;
            loading = false;

            propWithSizes = results.reduce((acc, chain) => {
                return acc.map(prop => {
                    const value = prop.get? prop.get(chain): get(chain, prop.key, ''), size = Math.max(prop.size || 0, (value? value.length: 0) + prop.label.length);
                    return {...prop, size};
                });
            }, props)

            return brands;
        });
    }

    load();

    $: sorters = (function() {
        const options = [
            {
                label: 'Name',
                value: 'name',
            },
            {
                label: 'Code',
                value: 'code',
            },
            {
                label: 'Type',
                value: 'subtype',
            }
        ];

        return [
            {
                label: 'None',
                value: 'none',
                key: null,
            },
            ...options.reduce((acc, option) => [...acc, make(option), make(option, false)], [])
        ];

        function make({label, value, key}, asc = true) {
            const dir = asc? 'asc': "desc";
            return {
                label: `${label} - ${dir.toUpperCase()}`,
                value: `${value}-${dir}`,
                key: key || value,
                dir,
            }
        }
    })();
    $: selectedList = selectedChains.map(c => results.find(({_id, id}) => _id === c || c === id));
    $: actions = loadActions(selectedList);
    $: showActions = actions.length > 0;

    function toggleAllSelect() {
        if(!loading) {
            if(allSelected) {
                selectedChains = [];
            }else{
                selectedChains = results.map(({_id, id}) => _id || id);
            }
        }
    }

    function sort({ detail: by }){
        const option = sorters.find(({value}) => value === by);

        if(option) {
            const key = option.key,
                dir = option.dir;

            sorter = { by, ...option };
            promise = Promise.resolve(results);
        }
    }

    function actionCompleted({detail: {selected, id, action, response}}) {
        switch(id) {
            case "editLogo":
            case "delete":
                load();
                break;
            default:
                break;
        }
    }

    function selectChain({detail: {_id, selected}}) {
        if(selected) {
            selectedChains = selectedChains.filter(id => id !== _id);
        }else{
            selectedChains = [...selectedChains, _id];
        }
    }
</script>

<div class="content">
    <Header {entity} {typeName} {showActions}>
        <div slot="actions">
            <Actions {actions} selected={selectedList} on:completed={actionCompleted}/>
        </div>
        <slot />
        <div class="sort">
            <Select id="sort by" options={sorters} value={sorter.by} iconStart="sort" on:input={sort}>Sort By</Select>
        </div>
    </Header>
    <ResultsTable {promise} {sorter}>
        <div class="header" slot="head">
            <CheckBox indeterminate={someSelected} selected={allSelected} on:toggle={toggleAllSelect} />
            Brands
        </div>
        <div slot="item" let:item>
            <Chain
                chain={item}
                {selectedChains}
                props={propWithSizes}
                on:select={selectChain}
            />
        </div>
    </ResultsTable>
</div>

<style type="text/stylus">.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.sort :global(.rbInput.component) {
  width: 175px;
  margin-right: 5px;
}
.header {
  padding: 5px 0;
  background: #313c42;
  border-bottom: 5px solid #263239;
  display: flex;
  align-items: center;
  color: #aebbc2;
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
