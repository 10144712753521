<script>
    import Navigation from 'components/organisms/Navigation/Navigation.svelte';
    import Home from '../routes/Home.js';
    export let links = [];
</script>

<Navigation {links} showHeaderSlot={true} let:link let:minimized>
    <a href={Home.href()} class="rbButton asDark" data-icon="keyboard_backspace" slot="header"> Back To Dashboard</a>
    <slot {link} {minimized} />
</Navigation>

<style type="text/stylus">.asDark {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  text-decoration: none;
}
.asDark::before {
  color: #00a99d;
}
</style>
