import accountTypes from './account-types';

const EntityType = accountTypes.reduce((acc, account) => ({...acc, [account.id]: account.id}), {});

const subscriptionTypes =  [
  f('CORPORATE_FREE', 'Corporate FREE', EntityType.COMPANY),
  f('CORPORATE_LITE', 'Corporate ELITE', EntityType.COMPANY),
  f('CORPORATE_PREMIER', 'Corporate PREMIER', EntityType.COMPANY),

  f('HOTEL_CHAIN_FREE', 'Hotel Chain FREE', EntityType.CHAIN),
  f('HOTEL_CHAIN_PREMIER', 'Hotel Chain PREMIER', EntityType.CHAIN),
  f('HOTEL_CHAIN_LITE', 'Hotel Chain ELITE', EntityType.CHAIN),

  f('HOTEL_FREE', 'Hotel FREE', EntityType.HOTEL),
  f('HOTEL_MANAGEMENT_COMPANY_FREE', 'HMC FREE', EntityType.HMC),
  f('HOTEL_MANAGEMENT_COMPANY_LITE', 'HMC ELITE', EntityType.HMC),

  f('TRAVEL_AGENCY_LITE', 'TMC ELITE', EntityType.TRAVEL_AGENCY),
  f('TRAVEL_AGENCY_PREMIER', 'TMC PREMIER', EntityType.TRAVEL_AGENCY),

  f('CONSULTANCY_FREE', 'Travel Consultancy FREE', EntityType.TRAVEL_CONSULTANCY),
];

export const subscriptionTerms = [
  {value: 'MONTHLY', label: 'Monthly'},
  {value: 'BIMONTHLY', label: 'Bi Monthly'},
  {value: 'THREE-MONTHS', label: 'Every 3 Months'},
  {value: 'ANNUAL', label: 'Annual'},
];

export default subscriptionTypes;

function f(id, label, entityType) {
  return {id, label, entityType};
}

export function getSubscriptionTypeLabel(type) {
  const subscription = subscriptionTypes.find(({id}) => id === type);
  return subscription? subscription.label: ''
}

export function getEntitySubscriptionOptions(type) {
  return subscriptionTypes.filter(({entityType}) => entityType === type);
}

export function getSubscriptionTermLabel(id) {
  const term = subscriptionTerms.find(({value}) => id === value);
  return term? term.label: '';
}
