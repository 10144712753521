<script>
    import InputText from '../../molecules/inputs/InputText.svelte';
    import Select from '../../molecules/inputs/SelectModel.svelte';
    import { getContext } from 'svelte';
    import allCountries from '../../assets/data/countries.js';
    import allStates from '../../assets/data/states.js';

    export let name = 'location.address';

    const
            id = field => `${name}.${field}`,
            countries = allCountries.map(({id, name}) => ({label: name, value: id}));

    let
            states = [],
            selectedCountry,
            formModel = getContext('formModel');

    $: handleCountryChange($formModel.values[id('country')]);

    function handleCountryChange(country){
        if(country === selectedCountry) return;
        selectedCountry = country;

        if( country === 'US' ){
            formModel.setValue( id('region'), '' );
            states = filterStates('US');
        } else if (country === 'CA'){
            formModel.setValue( id('region'), '' );
            formModel.setValue( id('county'), '' );
            states = filterStates('CA');
        } else {
            formModel.setValue( id('state'), '' );
            formModel.setValue( id('county'), '' );
            states = [];
        }
    }

    function filterStates(country){
        return allStates.filter(s => s.country === country).map(({id, label}) => ({label, value: id}));
    }
</script>

<div class="address-sub-form">
    <Select options={countries} id={id('country')} name='Country' required={true}>
        Country
    </Select>

    {#if selectedCountry === 'CA'}
        <Select options={states} required={true} name="State" id={id('state')}>State</Select>

    {:else if selectedCountry === 'US'}
        <div class="pair">
            <Select options={states} required={true} name="State" id={id('state')}>State</Select>
            <InputText maxLength={100} id={id('county')} name="County">County</InputText>
        </div>

    {:else}
        <InputText maxLength={100} id={id('region')} name="Region">Region</InputText>
    {/if}

    <div class="pair">
        <InputText maxLength={100} required={true} id={id('city')} name="City">City</InputText>
        <InputText maxLength={20} id={id('zip')} name="Zipcode">Zip / Postal Code</InputText>
    </div>
</div>


<style lang="stylus">.address-sub-form {
  min-width: 100%;
}
.pair {
  display: flex;
}
.pair :global(.rbInput:first-child) {
  margin-right: 10px;
}
</style>
