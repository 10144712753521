import adminServer from 'root/api/AdminServer';

export function loadCustomers() {
  return adminServer.get('fix-email-address/customers/candidates');
}

export function loadRfps(accountId) {
  return adminServer.get('fix-email-address/customers/rfps', {accountId});
}

export function getReport(model) {
  return adminServer.post('fix-email-address/customers/report', model);
}
