import Questions from 'root/assets/data/hotel-questionnaire';
import countries from 'components/assets/data/countries.js';

const EXCLUDED_CELLS = ['CS', 'BD', 'ES', 'GRR', 'GMUDQ'];

const UPDATED_QUESTIONS = {
    PROPCOUNTRY: {
        type: 'SELECT',
        options: countries.map(({name: label}) => ({value: label, label})),
    },
    MAINPHONECOUNTRY: {
        type: 'SELECT',
        options: countries.map(({name, code: value}) => ({value, label: `+(${value}) ${name}`})),
    },
    MKT_TIER: {
        type: 'SELECT',
    }
};

function filterExcludedCells(obj) {
    const cells = obj.cells? obj.cells.filter(({id}) => !EXCLUDED_CELLS.includes(id)).map(filterExcludedCells): [];
    return {
        ...obj,
        cells
    }
}

export function getIncludedQuestionsAsMenuSubs() {
    const cells = Questions.cells.filter(({id}) => !EXCLUDED_CELLS.includes(id));

    return cells.map(makeMenuItem);

    function makeMenuItem(question) {
        return {
            id: question.id,
            text: question.name,
            icon: 'remove_red_eye'
        }
    }
}
export function getQuestionnaire(response) {
    return {
        template: getTemplate(),
        response,
    }

    function getTemplate() {
        const model = filterExcludedCells(Questions);
        return updateFormat(model);
    }

    function updateFormat({cells, ...model}) {
        cells = cells? cells.map(updateFormat): null;
        return {
            ...model,
            ...getUpdates(model),
            cells
        }
    }
}

function getUpdates(model) {
    return UPDATED_QUESTIONS[model.id] || {};
}
