<script>
  import {createEventDispatcher} from "svelte";

  export let airport;

  const dispatch = createEventDispatcher();

  function open(){
    dispatch('open');
  }

</script>

<div class="entity">
  <div class="data">
    <div class="details">
      <div class="identifier" on:click|stopPropagation={open}>
        <b>{airport.name} </b>
      </div>
      <div class="props">
        <div class="prop" style={`width: 100px;`}>
          <span class="label">IATA:</span>
          <span class="value">{airport.iata}</span>
        </div>

        <div class="prop" style={`width: 450px;`}>
          <span class="label">Address:</span>
          <span class="value">{airport.location.fullAddress}</span>
        </div>

        <div class="prop" style={`width: 100px;`}>
          <span class="label">Status:</span>
          {#if airport.status.value === 'ACTIVE'}
            <span class="value active">ACTIVE</span>
          {:else if airport.status.value === 'OBSOLETE'}
            <span class="value inactive">DELETED</span>
          {:else}
            <span class="value">{airport.status.value}</span>
          {/if}
        </div>

      </div>
    </div>
  </div>
</div>



<style lang="stylus">.entity {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid rgba(176,190,197,0.239);
}
.data {
  flex: 1;
  display: flex;
  align-items: center;
}
.details {
  flex: 1;
  font-size: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.props {
  opacity: 0.6;
  display: flex;
  flex-wrap: wrap;
}
.prop {
  max-width: 360px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.prop:not(:last-child) {
  border-right: 1px solid;
}
.prop:not(:last-child) {
  margin-right: 5px;
}
.prop span {
  margin-right: 5px;
}
.identifier {
  line-height: 1.5;
  text-decoration: underline;
  cursor: pointer;
}
.identifier:hover {
  text-decoration: none;
}
.value {
  font-weight: bold;
}
.value.active {
  color: #00a99d;
}
.value.inactive {
  color: #f15a24;
}
</style>
