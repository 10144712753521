<script>
  import InputText from './InputText.svelte';
  import { getContext} from 'svelte';

  export let name = "";
  export let id = name;
  export let disabled;
  export let readonly;
  export let on = [];
  export let iconStart;
  export let iconEnd;

  // setup validations
  export let required;
  export let maxLength = 10;
  export let pattern = /^[1-9]\d{0,9}$/;
  export let format;

  const formModel = getContext('formModel');

  on.push(['input', ev => {
    const v = (ev.target.value || '').replaceAll(/\D/g, '').replaceAll(/^0/g, '');
    ev.target.value = v;
    formModel.setValue(id, v);
  }]);

</script>

<InputText
  {name}
  {id}
  {disabled}
  {readonly}
  {on}
  {iconStart}
  {iconEnd}
  {required}
  {maxLength}
  {pattern}
  {format}
><slot></slot></InputText>

