import adminServer from 'root/api/AdminServer';


function search(iata, name, city){
  return adminServer.get('airports/search', {iata, name, city});
}

function remove(id){
  return adminServer.axios.delete(`airports/${id}`);
}

function update(id, data){
  return adminServer.put(`airports/${id}`, data);
}

function create(data){
  return adminServer.post(`airports`, data);
}

export default {
  search, remove, update, create,
}
