import {writable} from 'svelte/store';

import moment from 'moment';
import {DateFormatter} from 'src/utils/internationalization.service';
import {isFailedEmailAddressStatus} from 'src/pages/competitive-bids/bids/badEmailAddress';

export const
  LOADING_STATE = Symbol('LOADING'),
  NO_CONTACTS_STATE = Symbol('NO_CONTACTS_STATE'),
  LIST_STATE = Symbol('LIST_STATE'),
  FORM_STATE = Symbol('FORM_STATE'),

  defaultState = {
    state: LOADING_STATE,
    bid: {},
    raw: [],
    contacts: [],
    selected: false,
    contact: {},
    listModel: {},
    formModel: {}
  },

  store = writable(defaultState),
  dateFormatter = DateFormatter();

  export default store;

export function init(bid = {}, dataPromise = new Promise(null), data = null){
  if(data !== null){
    store.set({...defaultState, ...data});
    return Promise.resolve();

  } else {
    store.set({ ...defaultState, bid });

    return dataPromise
      .then(response => response.data
        .filter(contact => contact.accountType === 'HOTEL')
        .map(c => formatContact(c))
        .sort((a,b) => {
          if(a.badEmailAddress === b.badEmailAddress){
            if(a.isUser === b.isUser) {
              if(a.lastSeen || b.lastSeen){
                return a.lastSeen > b.lastSeen ? -1 : 1;
              } else {
                return b.id === a.id ? 0 : b.id > a.id ? 1 : -1;
              }
            } else {
              return a.isUser ? -1 : 1;
            }
          } else {
            return a.badEmailAddress ? 1 : -1;
          }
        })
      )
      .then(contacts => {
        if(contacts.length){
          store.update(values => ({
            ...values,
            contacts,
            selected: bid.contact.set,
            contact: bid.contact,
            listModel: bid.contact.set ? bid.contact : undefined,
            formModel: {},
            state: LIST_STATE
          }));
        } else {
          store.update(values => ({...values, state: NO_CONTACTS_STATE}));
        }
      })
  }

  function formatContact(contact){
    return {
      ...contact,
      fullName: `${contact.firstName} ${contact.lastName}`,
      badEmailAddress: isFailedEmailAddressStatus(contact.emailAddressStatus),
      lastActive: contact.lastSeen ? dateFormatter.format(bid.contact.lastSeen) : undefined,
      invitation: formatInvitation(contact.invitations),
      raw: contact
    }
  }

  function formatInvitation(i){
    if(!i) return undefined;

    return {
      opened: i.status && ['OPENED', 'USED'].indexOf(i.status.value) > -1,
      statusDate: moment(i.status && i.status.at).fromNow(),
      firstSent: moment(i.firstSent).fromNow(),
      lastSent: i.lastSent ? moment(i.lastSent).fromNow() : undefined,
      first: '1st',
      nth: i.invitationsCount > 1 ? toOrdinal(i.invitationsCount) : undefined,
    }
  }

  function toOrdinal(number) {
    const i = parseInt(number, 10), j = i % 10, k = i % 100;

    if (j === 1 && k !== 11) {
      return `${i}st`;
    } else if (j === 2 && k !== 12) {
      return `${i}nd`;
    } else if (j === 3 && k !== 13) {
      return `${i}rd`;
    } else {
      return `${i}th`;
    }
  }
}

export function updateListSelection(contact){
  store.update(values => ({ ...values, listModel: contact }));
}

export function editContact(model){
  store.update(values => ({
    ...values,
    formModel: {...model},
    state: FORM_STATE,
  }))
}
