<script>
  import Header from 'src/components/Header.svelte';
  import Navigation from 'src/components/Navigation.svelte';
  import InputText from 'components/molecules/inputs/InputText.svelte';
  import FormModel from 'components/molecules/inputs/FormModel.svelte';
  import {list, remove} from './emailAddressBlacklistApi.js';
  import modalsService from 'src/services/modals';
  import ErrorsModal from 'src/components/ErrorsModal.svelte';
  import {DateFormatter} from 'src/utils/internationalization.service';

  const df = DateFormatter()

  let
    loaded = false,
    model = {emailAddress: ''},
    addresses = [],
    filteredAddresses,
    addressesInView;

  $: filteredAddresses = filterAddresses(addresses, model.emailAddress);
  $: addressesInView = filteredAddresses.slice(0, filteredAddresses.length > 500? 500: filteredAddresses.length);

  load();

  function load() {
    list()
      .then(r => {
        addresses = prepare(r.data);
        loaded = true;
      })
      .catch(e => {
        modalsService.show(ErrorsModal, {
          title: 'Error Loading Blacklist',
          error: e && e.data && e.data.message || null,
        });
      });
  }

  function prepare(data = []){
    return data.map(d => ({
      ...d,
      icon: d.status === 'BOUNCED' ? 'call_missed_outgoing' : d.status === 'COMPLAINT' ? 'ring_volume' : 'question_mark',
      formattedAt: df.format(d.at)
    }))
  }

  function filterAddresses(addressList = [], query = ''){
    if(query){
      const pattern = RegExp(query.toLowerCase(), 'i');
      return addressList.filter(({emailAddress}) => pattern.test(emailAddress));
    } else {
      return addressList;
    }
  }

  function removeItem(address){
    const originalList = addresses;
    addresses = addresses.filter(p => p.emailAddress !== address.emailAddress);
    remove(encodeURIComponent(address.emailAddress))
      .catch(e => {
        modalsService.show(ErrorsModal, {
          message: `'${address.emailAddress}' was not removed!`,
          error: e && e.data && e.data.message || null,
        });
        loaded = false;
        load();
      })
  }

</script>

<div class="rbPage rbDark">
  <Header></Header>
  <div class="pageContainer">
    <div class="rbCard navigation"><Navigation></Navigation></div>

    <div class="content">
      <div class="heading">
        <div class="column page-label">
          <i class="material-icons page-icon">unsubscribe</i>
          <div class="labels">
            <h1 class="label-title">Email Address Blacklist</h1>
            <div class="label-subtitle">Email Addresses found bad {#if loaded}(Displaying {addressesInView.length} out of {filteredAddresses.length} addresses){/if}</div>
          </div>
        </div>

        <div class="ActionsContainer">
          <FormModel bind:values={model}>
            <InputText id="emailAddress" name="Filter by Email Address" maxLength="256" iconStart="search">Filter by Email Address</InputText>
          </FormModel>
        </div>
      </div>
      <div class="table-header"></div>
      <div class="ListContainer">
        <div class="List">
          {#if loaded}
            {#each addressesInView as address}
              <div class="ListItem" data-icon-before="{address.icon}">
                <div class="ListItemTextContainer">
                  <div class="ListItemText">{address.emailAddress}</div>
                  <div class="ListItemSubText">{address.formattedAt}</div>
                </div>
                <button
                  class="rbButton asDark iconOnlySmall ListItemButton"
                  data-icon="close"
                  on:click={() => removeItem(address)}
                ></button>
              </div>
            {:else}
              <div class="flex-center">
                {#if model.emailAddress}
                  No matches!
                {:else}
                  Blacklist is empty!
                {/if}
              </div>
            {/each}
          {:else}
            <div class="flex-center">Loading...</div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>

<style type="text/stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.page-label {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
i.material-icons.page-icon {
  color: #00b8ab;
  height: 40px;
  width: 40px;
  background: #273238;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.labels {
  margin-left: 10px;
}
h1.label-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
.label-subtitle {
  margin: 0;
  font-size: 13px;
  font-weight: normal;
  color: #b0bec5;
}
.table-header {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #00a995 0%, #006eb9 50%, #00aa4f 100%) 0 1 100%;
}
.ListContainer {
  overflow: auto;
}
.List {
  width: 90%;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
}
.ListItemTextContainer {
  overflow: hidden;
  flex: 1;
}
.ListItem {
  height: 45px;
  width: 300px;
  background: transparent;
  border: 1px solid #263238;
  color: #b0bec5;
  transition: all 0.1s linear;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 23px;
  margin-bottom: 15px;
  margin-right: 50px;
}
.ListItem:before {
  width: 40px;
}
.ListItemText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ListItemSubText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #78909c;
  font-size: 13px;
}
.ListItemButton {
  flex: 0;
  border-radius: 0 18px 18px 0;
  height: 45px;
  width: 40px;
}
</style>
