<script>
    import FormModel from 'components/molecules/inputs/FormModel.svelte';
    import Input from 'components/molecules/inputs/Input.svelte';
    import Spinner from 'components/atoms/Spinner.svelte';

    import {getModelFromObect} from 'root/utils';

    import QuestionnaireForm from './questionnaire/form/QuestionnaireForm.component.svelte';
    import QuestionnaireView from './questionnaire/view/QuestionnaireView.component.svelte';

    import {getQuestionnaire} from './services/questions.service';
    import {getAnswers} from '../../services/hotel.service';

    export let hotel;

    let editing = false, questionnaire, values, loading = true;

    getAnswers(hotel.id).then(({answers}) => {
        questionnaire = getQuestionnaire(answers);
        values = getModelFromObect(questionnaire.response);
        loading = false;
    });

    const searchListeners = [
        ['input', ev => {
                const v = ev.target.value;
                search(v.toLowerCase());
            }
        ]
    ];

    function search(keyword) {
        const id = searchCell(template);
        if(id) {
            const el = document.getElementById(id);
            if(el) el.scrollIntoView()
        }

        function searchCell({cells, id, name, ord, type}) {
            if(!['ROOT', 'GROUP', 'MODULE'].includes(type) && (ord == keyword || name.toLowerCase().indexOf(keyword) > -1)) {
                return id
            }else if(cells && Array.isArray(cells) && cells.length > 0) {
                for(let i = 0; i < cells.length; i++) {
                    const found = searchCell(cells[i]);
                    if(found) {
                        return found;
                    }
                }
            }

            return null;
        }
    }
</script>
{#if loading}
    <div class="empty">
        <Spinner />
    </div>
{:else if editing}
    <FormModel bind:values>
        <QuestionnaireForm bind:editing {...questionnaire} bind:hotel>
            <div class="search" slot="search">
                <Input iconStart="search" on={searchListeners}>Question number or Keyword</Input>
            </div>
        </QuestionnaireForm>
    </FormModel>
{:else}
    <QuestionnaireView bind:editing {...questionnaire} {hotel}>
        <div class="search" slot="search">
            <Input iconStart="search" on={searchListeners}>Question number or Keyword</Input>
        </div>
        <slot></slot>
    </QuestionnaireView>
{/if}

<style type="text/stylus">.search {
  width: 250px;
  margin-right: 10px;
}
.empty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
