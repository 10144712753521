<script>
    import Spinner from '../../atoms/Spinner.svelte';
    import {tick} from 'svelte';

    export let value, error, id, disabled, fileUrl;

    let elInput, loading, percentage = 0, enlarged, hideInput, cleanFileUrl;

    $: cleanFileUrl = prepareUrl(fileUrl);

    function uploadImage(event) {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            value = file;
            loading = false;
            cleanFileUrl = e.target.result;
            percentage = 0;
            error = null;
        }

        reader.onloadstart = () => {
          loading = true;
        }

        reader.onerror = function (evt) {
          switch(evt.target.error.code) {
            case evt.target.error.NOT_FOUND_ERR:
              error = 'File Not Found!';
              break;
            case evt.target.error.NOT_READABLE_ERR:
              error = 'File Not readable';
              break;
            case evt.target.error.ABORT_ERR:
              error = 'File Upload Cancelled';
              break;
            default:
              error = 'Error Reading File';
          }
        }

        reader.onprogress = (evt) => {
          // evt is an ProgressEvent.
          if (evt.lengthComputable) {
            percentage = Math.round((evt.loaded / evt.total) * 100);
          }
        }

        // Read in the image file as a data URL.
        reader.readAsDataURL(file);
    }

    async function clearValue() {
        if(!disabled) {
            value = null;
            fileUrl = null;
            enlarged = false;
            hideInput = true;
            await tick();
            hideInput = false;
        }

    }

    function select() {
        return !disabled? elInput.click(): disabled;
    }

    function prepareUrl(fUrl){
      if(!fUrl) return fUrl;
      return fUrl.startsWith('http') ? fUrl : `/images/entities/${fUrl}`;
    }
</script>

<div {id} class:disabled class:hidden={cleanFileUrl || loading || error} class="empty image-picker" on:click={select}>
    <i class="material-icons icon">add_a_photo</i>
    ADD IMAGE
    {#if !hideInput}
        <input type="file" accept="image/*" class="hidden image-picker" bind:this={elInput} on:change={uploadImage} />
    {/if}
</div>

<div {id} class:disabled class="empty image-picker" class:hidden={!loading}>
    <Spinner />
    Loading ...({percentage}%)
</div>

<div {id} class:disabled class="rbError image-picker" class:hidden={!error}>
    <i class="material-icons icon" on:click={select}>add_a_photo</i>
    {error}
</div>

<div {id} class:disabled class:hidden={!cleanFileUrl && !loading && !error} class:enlarged class="with-value image-picker">
    <img class="preview" src={cleanFileUrl} alt="Upload Preview" on:click={() => enlarged = true}/>
    <i class="material-icons close remove" class:hidden={!enlarged} on:click={() => enlarged = false}>clear</i>
    <i class="material-icons remove" class:hidden={enlarged} on:click={clearValue}>delete</i>
</div>

<style type="text/stylus">.image-picker {
  width: 101px;
  height: 76px;
  border-radius: 6px;
  background: #263238;
  border: 1px solid #263238;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  overflow: hidden;
  transition: height, width 0.3s;
}
.image-picker.enlarged {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  border-radius: 5px;
  box-shadow: 0 1px 1px 1px rgba(0,0,0,0.2);
  z-index: 9;
  height: 90vh;
  width: 80vw;
  overflow: visible;
}
.disabled {
  opacity: 0.6;
}
.empty {
  color: #727d84;
}
.icon {
  font-size: 35px;
}
.remove {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background: rgba(38,50,56,0.8);
  font-size: 18px;
  border-bottom-left-radius: 5px;
  width: 27px;
  height: 27px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2px;
}
.preview {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.image-picker.enlarged .preview {
  object-fit: contain;
}
.close {
  top: 3px;
  right: 3px;
  border-radius: 27px;
  border: 1px solid #fff;
  padding: 0;
}
.hidden {
  display: none;
}
</style>
