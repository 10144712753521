<script>
  import { getContext } from "svelte";

  import InputText from "components/molecules/inputs/InputText.svelte";
  import InputEmail from "components/molecules/inputs/InputEmail.svelte";
  import Select from "components/molecules/inputs/SelectModel.svelte";
  import InputPassword from 'components/molecules/inputs/InputPassword.svelte';
  import InputPhone from 'components/molecules/inputs/InputPhone.svelte';

  export let hide, cancel, isNew;

  function save() {
    formModel.touchAll();
    if ($formModel.valid) {
      hide($formModel.values);
    }
  }

  const userTypes = [
    {
      label: "Root User",
      value: "root"
    },
    {
      label: "Support Rep",
      value: "false"
    }
  ];

  let formModel = getContext("formModel");
</script>

<style type="text/stylus">.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
.content {
  margin: 10px 0 30px;
  font-size: 13px;
  line-height: 16px;
  color: #546e7a;
}
.footer {
  text-align: right;
}
.content :global(.rbInput.component .container) {
  border: 1px solid #e6e7e8;
  background: transparent;
  color: #546e7a;
}
.content :global(.rbInput.component .container .input) {
  color: #546e7a;
}
.content :global(.rbInput.component .container .label) {
  background: #fff;
}
</style>

<div class="content">
  <div class="twoColumns">
    <InputText id="firstName" required="true">First Name</InputText>
    <InputText id="lastName" required="true">Last Name</InputText>
  </div>

  <div class="twoColumns">
    <InputEmail id="emailAddress" maxLength="255">Email Address</InputEmail>
    <InputPhone id="phone" maxLength="30">Phone</InputPhone>
  </div>

  <Select id="root" options={userTypes}>User Type</Select>

  {#if isNew}
    <InputPassword id="password" name="Password" maxLength="256">Password</InputPassword>
  {/if}
</div>

<div class="footer">
  <button class="rbButton asModal" on:click={cancel}>Cancel</button>
  <button class="rbButton asModalMain" data-icon="save" on:click={save}>
    Save
  </button>
</div>
