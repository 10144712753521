import {writable, get} from 'svelte/store';
import errorHandler from 'root/api/HttpErrorHandler.js';
import {listHMCs} from 'src/api';

const
  CACHE_TTL = 300000, // 5 min
  initialState = {
    hmcs: [],
    hmcsOptions: [],
    ready: false,
  },
  store = writable(initialState);

let responseCache;

export default store;

export async function initialize(){
  const
    hmcs = await getHmcs(),
    hmcsOptions = [
    {value: '', label: 'None'},
    ...hmcs
      .map(h => ({value: h.id, label: h.name}))
      .sort((a, b) => a.label.localeCompare(b.label))
  ];

  store.set({ hmcs, hmcsOptions, ready: true });

  async function getHmcs(){
    if(!responseCache || responseCache.ttl < new Date().getTime()){
      try {
        const response = await listHMCs();
        responseCache = {response, ttl: new Date().getTime() + CACHE_TTL};
        return response;
      } catch (e) {
        errorHandler.handle(e);
        return [];
      }
    } else {
      return responseCache.response;
    }
  }
}

function getHmcById(id = ''){
  if(!id) return '';

  const s = get(store);
  return s.hmcs.find(h => h.id === id);
}


export const hmcsService = {
  getHmcById
};
