<script>
    import { getContext, tick, createEventDispatcher } from 'svelte';

    import AddressInput from 'components/organisms/AddressInput/AddressInput.svelte';
    import AddressSearch from 'components/molecules/AddressSearch/AddressSearch.svelte';
    import InputText from 'components/molecules/inputs/InputText.svelte';
    import InputPhone from 'components/molecules/inputs/InputPhone.svelte';
    import InputUrl from 'components/molecules/inputs/InputUrl.svelte';
    import InputEmail from 'components/molecules/inputs/InputEmail.svelte';
    import Select from 'components/molecules/inputs/SelectModel.svelte';
    import Notification from 'components/modals/Notification.basic.svelte';

    import modals from 'root/services/modals.js';
    import marketTierOptions from 'root/assets/data/hotel-market-tiers';
    import {convertModelToObect} from 'root/utils';

    import CoordinatesModel from './CoordinatesModel.component.svelte';
    import Header from '../../components/Header.component.svelte';
    import {updateBasicInfo} from '../../services/hotel.service';
    import {BASIC_INFO} from './basic-info.module';

    let formModel = getContext('formModel');

    export let editing, hotel;

    const ratingOptions = [1.0, 2.0, 3.0, 4.0, 5.0];
    let address = hotel.location, dispatch = createEventDispatcher();

    async function handleAddressChange(location){
        if(location && Object.keys(location).length) {
            formModel.setValue('location.address.country', location.address.country);
            await tick();
            formModel.setValue('location.address.state', location.address.state);
            formModel.setValue('location.address.region', location.address.region);
            formModel.setValue('location.address.county', location.address.county);
            formModel.setValue('location.address.city', location.address.city);
            formModel.setValue('location.address.address1', location.address.address1);
            formModel.setValue('location.address.address2', location.address.address2);
            formModel.setValue('location.address.zip', location.address.zip);

            formModel.setValue('location.coordinates.lat', location.coordinates.lat);
            formModel.setValue('location.coordinates.lng', location.coordinates.lng);
        }
    }

    function onSubmit(){
        formModel.touchAll();
        if($formModel.valid) {
            const {name, location, emailAddress, phone, marketTier, rating, website} = convertModelToObect($formModel.values);
            updateBasicInfo(hotel.id, {
                name,
                location: {
                    ...location,
                    coordinates: {
                        lat: location.coordinates.lat || location.coordinates.latitude,
                        lng: location.coordinates.lng || location.coordinates.longitude,
                    }
                },
                emailAddress,
                phone,
                marketTier,
                rating,
                website
            })
            .then( h => modals.show(Notification,
                    { title: 'Saved!', subtitle:'Property', content: 'Property was updated!', resolveLabel: 'OK' }
            ).then(() => saved(h)))
        }

        function saved(data) {
            dispatch('updated', data)
        }
    }

    $: handleAddressChange(address);
    $: lat = address.coordinates.lat || address.coordinates.latitude;
    $: lng = address.coordinates.lng || address.coordinates.longitude;
</script>

<Header {hotel}>
    <button class="rbButton asDark" on:click={() => editing = false}>Cancel</button>
    <button class="rbButton asDarkMain" data-icon="save" on:click={onSubmit}>Save Changes</button>
</Header>
<div class="main" id={BASIC_INFO}>
    <div class="inputsRow main rbDark">
        <div class="rbCard inputCard mapContainer">
            <InputText id="name" required="true" maxLength="100">Property Name</InputText>

            <AddressSearch bind:value="{address}"></AddressSearch>
            <AddressInput></AddressInput>

            <InputUrl id="website" required="true" maxLength="256">Website</InputUrl>

            <div class="twoColumns">
                <InputEmail id="emailAddress" maxLength="255">Email Address</InputEmail>
                <InputPhone id="phone" required="true" maxLength="30">Phone</InputPhone>
            </div>

            <div class="twoColumns">
                <Select id="rating" options="{ratingOptions}">Star Rating</Select>
                <Select id="marketTier" options="{marketTierOptions}">Property Market Tier</Select>
            </div>
        </div>
        <div class="inputCard mapContainer">
            <CoordinatesModel value={{lat, lng}} zoom={17}></CoordinatesModel>
        </div>
    </div>
</div>


<style type="text/stylus">.main {
  height: calc(100vh - 158px);
  overflow: auto;
}
.inputsRow {
  flex: 1 0 259px;
  display: flex;
  align-items: flex-start;
  transition: all 0.5s linear;
  overflow: hidden;
  min-height: 100%;
}
.inputCard {
  margin: 7px;
  flex: 1 1 100%;
  overflow: auto;
  min-height: 245px;
  max-height: calc(100% - 16px);
  display: flex;
  flex-direction: column;
}
.mapContainer {
  align-self: stretch;
}
.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
</style>
