<script>
    import Input from 'components/molecules/inputs/Input.svelte';
    import AddressSearch from 'components/molecules/AddressSearch/AddressSearch.svelte';

    export let model = {};
    export let addHotelView = false;

    let sabreCode = '';
    let address = {};

    $: model = {sabreCode, coordinates: address.coordinates || model.coordinates};
</script>


<div class="component">
    <div class="rbTextLarge">Property Search</div>

    <div class="inputContainer">
        <div class="sabreInput">
            <Input on="{[['input', e => { const v = parseInt(e.target.value); sabreCode = isNaN(v) ? '' : ''+v; e.target.value = sabreCode; }]]}">Sabre Code</Input>
        </div>

        <div class="addressInput">
            <AddressSearch bind:value="{address}">Address</AddressSearch>
        </div>
    </div>

    <div class="footer">
        <div class="rbInlineNotice" data-icon="error">
            Before adding a new hotel, please make sure you tried all the possible options to find it in our database.
        </div>

        <button class="rbButton asDarkMain" on:click="{() => addHotelView = !addHotelView}">Add a New Property</button>
    </div>
</div>

<style type="text/stylus">.component {
  padding: 24px 15px;
}
.rbTextLarge {
  color: #fff;
}
.inputContainer {
  display: flex;
  margin: 28px 0;
}
.sabreInput {
  flex: 0 0 118px;
  margin-right: 10px;
}
.addressInput {
  flex: 1 1 100%;
}
.footer {
  display: flex;
}
.rbInlineNotice {
  margin-right: 20px;
}
</style>
