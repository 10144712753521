<script>
    import { createEventDispatcher } from 'svelte';
    import DropDown from '../../profile/components/ActionsHelperDropDown.component.svelte';

    const dispatch = createEventDispatcher();

    export let actions = [];
    export let selected = [];
    export let hotelManagemetCompanies = [];
    export let collection;

    $: primaries = actions.sort(({primary}) => !primary? 1: -1).slice(0, 2);
    $: secondaries = actions.filter(a => !primaries.find(p => p.id === a.id));

    function execute({id, action}) {
        return action(selected, hotelManagemetCompanies, collection).then(response => dispatch('completed', {selected, id, action, response}));
    }
</script>

<div class="actions">
    {#each primaries as action}
        <button class="rbButton asDark" data-icon={action.icon} on:click={() => execute(action)}>{action.label}</button>
    {:else}
        <div class="empty">
            No actions available for your selection
        </div>
    {/each}
    {#if secondaries.length > 0}
        <DropDown actions={secondaries} {execute} />
    {/if}
</div>

<style type="text/stylus">.actions {
  display: flex;
}
</style>
