import getFilters from './filter-types';
import states from 'components/assets/data/states';
import countries from 'components/assets/data/countries';
import httpErrorHandler from 'root/api/HttpErrorHandler';
import {search} from '../api/end-points';
import {extractValue} from 'root/pages/hotels/advanced-search/search.service';

export function loadFilterTypes() {
    return getFilters(
        states.map(({country, id, label}) => ({value: id, label: `${country} - ${label}`})),
        countries.map(({id: value, name: label}) => ({value, label})),
    );
}

export function searchEntities(type, filters, and, page, perPage = 100) {
    const request = {
        filters: filters.reduce((acc, {id, value}) => hasValue(value)? ({...acc, [id]: extractValue(value)}): acc, {}),
        and,
        page,
        perPage,
    };

    function hasValue(value) {
        return Array.isArray(value)? value.length > 0: !!value;
    }

    return search(type, request).catch(httpErrorHandler.handle);
}
