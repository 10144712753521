<script>
  export let config = {
    selectColumn: { width: '50px' }, // leave empty when select column doesn't exist
    imageColumn: { width: '50px' }, // leave empty when image column doesn't exist
    columns: [{ label: 'TestHeader', minWidth: '20px', width: '30%', maxWidth: '70px' }]
  };

</script>

<div class="Header">
  {#if config.selectColumn}
    <div class="StaticColumn" style="width:{config.selectColumn.width}">&nbsp;</div>
  {/if}

  {#if config.imageColumn}
    <div class="StaticColumn" style="width:{config.imageColumn.width}">&nbsp;</div>
  {/if}

  {#each config.columns as column}
    <div class="Column" style="--table-cell-width:{column.width};">{column.label || ''}</div>
  {/each}
</div>

<style type="text/stylus">.Header {
  display: flex;
  height: 50px;
  padding-right: 5px;
  align-items: center;
  border-bottom: 1px solid #263238;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}
.StaticColumn {
  flex: 0 0 auto;
  overflow: hidden;
}
.Column {
  flex: 1 1 auto;
  min-width: var(--table-cell-width, auto);
  margin: 0 5px;
  overflow: hidden;
}
</style>
