import {FREE_TEXT_FILTER_TYPE, MULTI_SELECT_FILTER_TYPE} from 'src/pages/hotels/advanced-search/filters/filter-types';
import industries from 'src/assets/data/industries';
import get from 'lodash/get';

class FilterOption {
  constructor(id, label, type, value, options = []){
    this.id = id;
    this.label = label;
    this.type = type;
    this.value = value;
    this.options = options
  }

  applyFilter(data){
    switch (this.type){
      case FREE_TEXT_FILTER_TYPE: {
        const value = this.value.trim();
        if(value.length){
          const reg = new RegExp(value, 'i')
          return data.filter(i => reg.test(get(i, this.id)));
        } else {
          return data;
        }
      }

      case MULTI_SELECT_FILTER_TYPE: {
        const value = this.value.map(v => v.value);
        return value.length ? data.filter(i => value.indexOf(get(i, this.id)) !== -1) : data;
      }

      default: {
        return data;
      }
    }
  }
}

const filterOptions = [
  () => new FilterOption('name', `Name`, FREE_TEXT_FILTER_TYPE, ''),
  () => new FilterOption('industry', `Industry`, MULTI_SELECT_FILTER_TYPE, [], industries.map(({label}) => ({value: label, label}))),
  data => new FilterOption('agency.name', `Agency`, MULTI_SELECT_FILTER_TYPE, [], data
    .reduce( (acc, i) => {
      const agencyName = i.agency.name;
      if(acc.indexOf(agencyName) === -1) acc.push(agencyName)
      return acc;
    }, [])
    .map(an => ({value: an, label: an}))),
];

export function createFilters(data = []){
  return filterOptions.map(fo => fo(data));
}


