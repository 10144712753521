import { writable } from 'svelte/store';
import * as FormModel from './FormModel';

export default function(values, context){
  const { subscribe, update } = writable(FormModel.create(values, context));
  return {
    subscribe,
    update,
    touch: id => update( fm => FormModel.touch(fm, id) ),
    touchAll: () => update( fm => FormModel.touchAll(fm) ),
    untouchAll: () => update( fm => FormModel.untouchAll(fm) ),
    addModel: config => update( fm => FormModel.addModel(fm, config) ),
    removeModel: id => update( fm => FormModel.removeModel(fm, id) ),
    setValue: (id, value) => update( fm => FormModel.setValue(fm, id, value) ),
    setValues: newValues => update( fm => FormModel.setValues(fm, newValues) ),
  };
}
