<script>
  import Spinner from 'components/atoms/Spinner.svelte';
  import TableHeader from './TableHeader.component.svelte';
  import {orderBy} from 'lodash';
  import {initialize, default as store} from './results-table-data.store';

  export let
    config,
    promise = null,
    sorter = {};

  let data = [];

  $: promise && initialize(promise);
  $: data = sort($store.data, sorter);

  function sort(hotels) {
    return sorter && sorter.key? orderBy(hotels, [sorter.key], [sorter.dir]): hotels
  }
</script>

<div class="hotels-list">
  {#if promise}
    <TableHeader {config} />

    {#if !$store.ready}
      <div class="empty">
        <Spinner />
      </div>
    {:else if data.length}
      {#each data as item}
        <slot name="item" {item} />
      {/each}
    {:else}
      <div class="empty">There were no results found for your search</div>
    {/if}

  {:else}
    <div class="empty">No search parameters...</div>
  {/if}
</div>

<style type="text/stylus">.hotels-list {
  height: calc(100vh - 200px);
  overflow: auto;
  background: #fff;
  flex: 1;
  color: #546e7a;
  position: relative;
}
.hotels-list :global(.empty) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  font-size: 15px;
}
</style>
