<script>
  import DialogTemplate from 'components/modals/DialogTemplate.basic.svelte';
  import Input from 'components/molecules/inputs/Input.svelte';
  import Spinner from 'components/atoms/Spinner.svelte';

  import API from './api/suspended-accounts';
  import {listAccounts} from 'src/pages/customers/api/end-points';

  export let hide, cancel, suspendedAccounts;

  let
    pageState = 'LOADING',
    accounts = [],
    accountsFiltered = [],
    filterQuery = '',
    filterQueryUpp = '';

  initialize();

  $: filterQueryUpp = (filterQuery || '').trim().toUpperCase();
  $: accountsFiltered = accounts.filter(a => a.nameUpp.startsWith(filterQueryUpp));

  async function initialize(){
    pageState = 'LOADING';
    try {
      const
        accList = await listAccounts(['COMPANY', 'TRAVEL_AGENCY']),
        suspendedAccountsIds = suspendedAccounts.map(sa => sa.id);

      accounts = accList.filter(acc => !suspendedAccountsIds.includes(acc))
        .map(acc => ({...acc, nameUpp: acc.name.toUpperCase()}))

      accounts.sort((a, b) => (a.nameUpp < b.nameUpp) ? -1 : ((a.nameUpp > b.nameUpp) ? 1 : 0))

      pageState = 'READY';
    } catch (err){
      console.error(err);
      pageState = 'ERROR';
    }
  }

  function toggle(account){
    accounts = accounts.map(a => a === account ? {...account, selected: !account.selected} : a)
  }

  async function save(){
    pageState = 'LOADING';
    try {
      const toSuspend = accounts.reduce((acc, a) => a.selected ? [...acc, a.id] : acc, []);

      if(toSuspend.length){
        await API.suspendAccounts(toSuspend);
      }

      hide();
    } catch (e) {
      console.error(e);
      pageState = 'ERROR';
    }
  }

</script>

<DialogTemplate closeOnClick="{false}" {cancel} width="600">
  <div class="Container">
    <div class="Header">Suspend Accounts</div>
    {#if pageState === 'LOADING'}
      <div class="Notice">
        <Spinner />
      </div>

      <div class="Footer">
        <button class="rbButton asDark" on:click={cancel}>Cancel</button>
      </div>
    {:else if pageState === 'READY'}
      <div class="Content">
        <div class="Filter">
          <Input bind:value={filterQuery}>Filter</Input>
        </div>

        <div class="List">
          {#each accountsFiltered as account}
            <div class="Item" class:Selected={account.selected}>
              <div class="Check"
                   data-icon-before="{account.selected ? 'check_box' : 'check_box_outline_blank'}"
                   on:click={() => toggle(account)}
              ></div>
              <div class="Details">
                <div class="Name">{account.name}</div>
                <div class="Type">{account.type}</div>
              </div>
            </div>
          {/each}
        </div>
      </div>

      <div class="Footer">
        <button class="rbButton asDark" on:click={cancel}>Cancel</button>
        <button class="rbButton asDarkMain" on:click={save} data-icon="save">Save</button>
      </div>
    {:else if pageState === 'ERROR'}
      <div class="Notice">
        There was an error...
      </div>

      <div class="Footer">
        <button class="rbButton asDark" on:click={cancel}>Cancel</button>
      </div>
    {/if}
  </div>

</DialogTemplate>


<style lang="stylus">.Container {
  color: #b0bec5;
}
.Header {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #eceff1;
  margin-bottom: 20px;
}
.Filter {
  padding: 0 20px;
}
.List {
  height: 400px;
  width: 100%;
  overflow: auto;
  margin-top: 5px;
  border-top: 1px solid #78909c;
  border-bottom: 1px solid #78909c;
}
.Item {
  height: 48px;
  padding: 10px 20px;
  font-size: 14px;
  border-bottom: 1px solid #78909c;
  display: flex;
  align-items: center;
}
.Check {
  flex: 0 0 auto;
  text-align: center;
  margin-right: 15px;
  cursor: pointer;
}
.Details {
  flex: 1 1 100%;
}
.Name {
  font-weight: bold;
  color: #eceff1;
}
.Type {
  font-size: 12px;
}
.Footer {
  padding: 15px 20px;
  text-align: right;
}
.Notice {
  text-align: center;
}
</style>
