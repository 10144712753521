<script>
  import {default as store, applyFilters, setTextFilter, setSort, handleSelectAll, updateBids} from './Bids.store';
  import actions from './Actions.store';
  import {clearAllFilterValues} from 'src/components/filters/Filters.store';
  import HeadingTitle from 'src/components/DocumentHeadingTitle.svelte';
  import SearchInput from 'src/components/inputs/SearchInput.svelte';
  import LabeledSelect from 'src/components/inputs/LabeledSelect.svelte';
  import VirtualList from 'src/components/VirtualList.svelte';
  import FilterBar from 'src/components/filters/FilterBar.svelte';
  import DestinationRow from "./DestinationRow.svelte";
  import BidRow from './BidRow.svelte';
  import RfpsListRoute from 'src/routes/competitive-bids/Rfps';
  import getSelectionIcon from 'src/utils/selection-icons.service';

  export let sendBids;

  const actionsStore = actions(store);

  function clearFilterValues(){
    clearAllFilterValues();
    setTextFilter('');
  }

  function sendSelected(){
    sendBids($store.selected)
  }

</script>

<div class="DocumentViewContainer">
  <div class="DocumentViewHeading">
    <div class="DocumentViewHeadingActionsInline Custom">
      <button class="rbButton asDark BackButton" data-icon="arrow_back" on:click={() => RfpsListRoute.go()}>Back</button>

      {#if $store.selectionPercentage === 0}
        <HeadingTitle icon="folder_special"
                      title="{$store.rfpName} - DUE {$store.rfpDueDate}"
        >
          <div slot="pretitle" class="CompanyName">{$store.companyName}</div>
          <div slot="subtitle" class="CompanyName">{$store.buyerDetails}</div>
        </HeadingTitle>
      {:else}
        <div class="ActionsContainer">
          {#if $actionsStore.empty}
            No Actions Available
          {:else}
            {#each $actionsStore.available as action}
              <button class="rbButton asDark"
                      data-icon="{action.icon}"
                      on:click="{() => action.execute($store.selected, updateBids)}"
              >
                {action.label}
              </button>
            {/each}
          {/if}
        </div>

        <div class="BuyerDetails">
          {$store.companyName} > {$store.rfpName} > {$store.buyerDetails}
        </div>
      {/if}
    </div>

    <div class="DocumentViewHeadingActionsInline">
      <div class="DocumentViewHeadingAction">
        <SearchInput value="{$store.textFilter}"
                     on:change="{e => setTextFilter(e.detail)}"
        />
      </div>

      <div class="DocumentViewHeadingAction">
        <LabeledSelect label="Sort by"
                       value={$store.sortedBy}
                       options={$store.sortByOptions}
                       on:change={ e => setSort(e.detail) }
        />
      </div>

      <div class="DocumentViewHeadingAction">
        <button class="rbButton asDark"
                data-icon="delete_sweep"
                on:click="{() => clearFilterValues()}"
        >Clear All Filters</button>
      </div>
    </div>
  </div>

  <div class="TableHeading RainbowBottomBorder">
    <button class="rbButton iconTransparent"
            class:Selected="{$store.selectionPercentage === 1}"
            data-icon="{getSelectionIcon($store.selectionPercentage)}"
            on:click={() => handleSelectAll()}
    ></button>

    <FilterBar on:change="{() => applyFilters()}" />
  </div>

  <div class="DocumentViewContentContainer">
    <VirtualList items="{$store.bids}"
                 let:item
                 itemHeight={80}
    >
      <div slot="row">
        {#if item.rowType === 'DESTINATION'}
          <DestinationRow destination="{item}" />
        {:else if item.rowType === 'BID'}
          <BidRow bid="{item}" />
        {/if}
      </div>
    </VirtualList>
  </div>

</div>

<style lang="stylus">.DocumentViewContentContainer {
  background: #313c42;
}
.DocumentViewHeadingActionsInline.Custom {
  padding-left: 0;
}
.BackButton {
  border-radius: 0 18px 18px 0;
  font-size: 13px;
  color: #fff;
}
.BackButton:before {
  color: #00a99d;
}
.ActionsContainer {
  font-size: 16px;
  color: #fff;
  padding: 0 12px;
}
.CompanyName {
  font-weight: bold;
  font-size: 13px;
  color: #00b8ab;
  text-transform: uppercase;
}
.TableHeading {
  background: #313c42;
  display: flex;
}
.TableHeading:before {
  color: #57666f;
}
.BuyerDetails {
  position: fixed;
  left: 0;
  top: 44px;
  z-index: 1;
  height: 28px;
  line-height: 28px;
  padding: 0 20px 0 15px;
  border-radius: 0 13px 13px 0;
  background: #263238;
  font-size: 14px;
  color: #72828a;
}
.rbButton.iconTransparent {
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0 15px;
  color: #57666f;
}
.rbButton.iconTransparent:before {
  width: 24px;
}
.rbButton.iconTransparent.Selected {
  color: #00a99d;
}
</style>
