<script>
    import { createEventDispatcher } from 'svelte';
    import SelectInput from './Select.input.svelte';
    import SelectMenu from './Select.menu.svelte';
    import SelectionController from './Select.controller.svelte';

    export let id;
    export let options = [];
    export let value = '';
    export let required = false;
    export let errorMessage = '';
    export let iconStart;

    export let focus;

    const dispatch = createEventDispatcher();

    let valueLabel, focused, menuShown, onShowMenu, items, onSelect;

    $: dispatch('input', value);

</script>

<SelectionController
        {options}
        bind:value
        bind:focus="{focus}"
        let:items="{items}"
        let:valueLabel="{valueLabel}"
        let:menuShown="{menuShown}"
        let:focused="{focused}"

        let:onKeyDown="{onKeyDown}"
        let:onShowMenu="{onShowMenu}"
        let:onSelect="{onSelect}"
        on:blur>

    <SelectInput {iconStart}
                 value="{valueLabel}"
                 {required}
                 {focused}
                 error="{!!errorMessage}"
                 {menuShown}
                 bind:focus="{focus}"
                 {onShowMenu}>
        <slot />
    </SelectInput>

  {#if errorMessage}
      <div class="errorBox">
          {errorMessage}
      </div>
  {/if}

    <SelectMenu {id}
                {items}
                {value}
                {menuShown}
                {onSelect}
                {onShowMenu}
    />
</SelectionController>


<style type="text/stylus">.errorBox {
  font-size: 12px;
  line-height: 1.2;
  color: #f15a24;
  max-height: 30px;
  text-align: left;
  padding: 2px 15px 0;
}
</style>
