import {isNil, set} from 'lodash';

export function getModelFromObect(object) {
    const res = {};
        (function recurse(obj, current) {
            for(const key in obj) {
                if(obj.hasOwnProperty(key)) {
                    const value = obj[key];
                    const newKey = (current ? `${current  }.${  key}` : key);  // joined key with dot
                    
                    if(value && typeof value === 'object') {
                        recurse(value, newKey);  // it's a nested object, so do it again
                    } else {
                        res[newKey] = value;  // it's not an object, so set the property
                    }
                }
                
            }
        })(object);
    return res;
}

export function convertModelToObect(values) {
    return Object.keys(values).reduce( (acc, key) => {
        const v = values[key];

        if(!isNil(v)){
            set(acc, key, v);
        }

        return acc;
    }, {});
}