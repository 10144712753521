class ModalsService {


  constructor(mounter = () => Promise.cancel(), id = 'modal-container') {
    this.$$shownModals = [];
    this.element = getOrCreateElement(id);
    this.mounter = mounter;
  }

  show( modalComponent, props ){
    const modal = this.mounter( this.element, modalComponent, props );
    this.$$shownModals.push(modal);
    return modal.promise
      .finally(() => cleanShownModals(this.$$shownModals, modal));
  }

  hide(reason){
    if(this.$$shownModals.length) {
      return this.$$shownModals[this.$$shownModals.length - 1].hide(reason);
    } else {
      return Promise.resolve(reason);
    }
  }

  cancel(reason){
    if(this.$$shownModals.length) {
      return this.$$shownModals[this.$$shownModals.length - 1].hide(reason);
    } else {
      return Promise.reject(reason);
    }
  }

  clean(){
    if(this.$$shownModals.length){
      this.$$shownModals[0].cancel();
    }
  }
}

export default ModalsService;

function getOrCreateElement(id){
  let e = document.getElementById(id);
  if(!e){
    e = document.createElement('div');
    e.setAttribute('id', id);
    e.style.position = 'relative';
    e.style.zIndex = '10000';
    document.body.appendChild(e);
  }
  return e;
}

// this will close all modals created by the closed modal and remove itself from $$shownModals
function cleanShownModals(shownModals, modal){
  let modalNotRemoved = true;
  while(modalNotRemoved){
    const m = shownModals.pop();
    if(m) m.$destroy();
    modalNotRemoved = m && m !== modal
  }
}
