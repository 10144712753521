import orderBy from 'lodash/orderBy';

export const sorters = [
    {
      label: 'Name',
      value: 'name',
    },
    {
      label: 'Industry',
      value: 'industry',
    },
    {
      label: 'Agency',
      value: 'agency.name',
    },
    {
      label: 'Last Active',
      value: 'lastActive',
    },
    {
      label: '# RFPs with bids',
      value: 'activity.rfps',
    },
    {
      label: '# Bids',
      value: 'activity.bids',
    },
    {
      label: '# Sent Bids',
      value: 'activity.sentBids',
    },
    {
      label: 'Last Bid Sent date',
      value: 'activity.lastSent',
    },
    {
      label: 'Competitive Received',
      value: 'competitive.received',
    },
    {
      label: 'Competitive Accepted',
      value: 'competitive.accepted',
    },
    {
      label: 'Competitive Final Agreement',
      value: 'competitive.fa',
    },
    {
      label: 'Competitive No Thank You',
      value: 'competitive.noty',
    },
  ],
  directions = [
    {
      label: 'Asc',
      value: 'asc',
      icon: 'vertical_align_top'
    },
    {
      label: 'Desc',
      value: 'desc',
      icon: 'vertical_align_bottom'
    },
  ]

export const defaultSort = sorters[0];
export const defaultDirection = directions[0];

export function sort(arr, sortValue, directionValue = 'asc'){
  return orderBy(arr, [sortValue], [directionValue])
}


