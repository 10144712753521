
<script>
    import {getContext} from 'svelte';

    import InputText from 'components/molecules/inputs/InputText.svelte';
    import InputPhone from 'components/molecules/inputs/InputPhone.svelte';
    import InputUrl from 'components/molecules/inputs/InputUrl.svelte';
    import InputEmail from 'components/molecules/inputs/InputEmail.svelte';
    import Select from 'components/molecules/inputs/SelectModel.svelte';
    import ImagePicker from 'components/molecules/ImagePicker/ImagePicker.svelte';

    export let hide, cancel, chains, isMaster = false;
    let logo;

    function save() {
        formModel.touchAll();
        if($formModel.valid) {
            hide({logo, chain: $formModel.values});
        }
    }

    let formModel = getContext('formModel');
</script>

<div class="content">
    <ImagePicker bind:value="{logo}"></ImagePicker>

    <InputText id="name" required="true" maxLength="100">Name</InputText>

    <div class="twoColumns">
        <InputText id="code" required="true">Code</InputText>
        <InputUrl id="website" required="true">Website</InputUrl>
    </div>

    {#if !isMaster}
        <Select id="masterChainId" options="{chains}">Master Chain</Select>
    {/if}

    <div class="twoColumns">
        <InputEmail id="emailAddress" maxLength="255">Email Address</InputEmail>
        <InputPhone id="phone" maxLength="30">Phone</InputPhone>
    </div>
</div>

<div class="footer">
    <button class="rbButton asModal" on:click="{cancel}">Cancel</button>
    <button class="rbButton asModalMain" data-icon="save" on:click={save}> Save </button>
</div>

<style type="text/stylus">.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
.content {
  margin: 10px 0 30px;
  font-size: 13px;
  line-height: 16px;
  color: #546e7a;
}
.footer {
  text-align: right;
}
.content :global(.rbInput.component .container) {
  border: 1px solid #e6e7e8;
  background: transparent;
  color: #546e7a;
}
.content :global(.rbInput.component .container .input) {
  color: #546e7a;
}
.content :global(.rbInput.component .container .label) {
  background: #fff;
}
</style>
