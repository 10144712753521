<script>
    import { createEventDispatcher } from 'svelte';
    import Filter from './Filter.component.svelte';
    import {FREE_TEXT_FILTER_TYPE} from './filter-types';

    export let filters = [];
    export let filterTypes = [];
    export let andFilters = true;

    let hasValues = false;

    const dispatch = createEventDispatcher();

    function addFilter({detail}) {
        detail = Object.assign({}, detail);
        detail.open = true;

        if(detail.type !== FREE_TEXT_FILTER_TYPE) {
            detail.optionsOpen = true;
        }

        filters = [...filters, detail];
        
        checkValues();
    }

    function remove({detail: filter}) {
        filters = filters.filter(({id}) => id != filter.id);
        checkValues();
    }

    function checkValues() {
        hasValues = filters.some(({value}) => Array.isArray(value)? value.length > 0: !!value);
        dispatch('updated', { hasValues });
    }

    $: types = filterTypes.filter(({id: i}) => !filters.find(({id}) => id === i));
</script>

<div class="filters rbDark">
    <div class="filters children">
        {#each filters as filter, index}
            <Filter {filter} on:remove={remove} on:closed={checkValues} />
            {#if index < filters.length - 1 && filters.length > 1}
                <span class="operator" class:or={!andFilters}>
                    <i class="material-icons">{andFilters? 'link': 'height'}</i>
                </span>
            {/if}
        {/each}
        {#if types.length > 0}
            <Filter on:add={addFilter} {types}/>
        {/if}
    </div>
    <slot {hasValues}></slot>
</div>

<style type="text/stylus">.filters {
  border-radius: 16px 16px 0 0;
  margin-right: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.rbDark {
  min-height: 36px;
  flex: 1;
  padding: 0 10px;
}
.children {
  max-width: calc(100% - 106px);
}
.operator {
  margin: 0 3px;
  display: flex;
  color: #b0bec5;
  justify-content: center;
  width: 36px;
  box-sizing: border-box;
}
.operator .material-icons {
  font-size: 18px;
}
.operator.or .material-icons {
  transform: rotate(90deg);
}
</style>