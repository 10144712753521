import * as InputModel from './InputModel.js';

export function create(values = {}, context = {}){
  return {
    models: {},
    values,
    errors: {},
    context,

    valid: true,
    invalid: false,

    pristine: true,
    dirty: false,

    touched: false,
    untouched: true,
  };
}

export function touch(formModel, id){
  return setModel(formModel, InputModel.touch(getModel(formModel, id)));
}

export function touchAll(formModel){
  const {models} = formModel;

  return {
    ...formModel,
    models: Object.keys(models).reduce( (acc, id) => {
      acc[id] = InputModel.touch(models[id]);
      return acc;
    },{}),
    touched: true,
    untouched: false
  };
}

export function untouchAll(formModel){
  const {models} = formModel;

  return {
    ...formModel,
    models: Object.keys(models).reduce( (acc, id) => {
      acc[id] = InputModel.untouch(models[id]);
      return acc;
    },{}),
    touched: false,
    untouched: true
  };
}

export function addModel(formModel, config){
  return setModel(formModel, InputModel.create(config, formModel.values, formModel.context))
}

export function setValue(formModel, id, value){
  const {values, context, models} = formModel,
    model = models[id];

  if(model){
    return stabilize(setModel(
      formModel,
      InputModel.setValue(model, value, values, context)
    ));
  } else {
    return stabilize({...formModel, values: { ...formModel.values, [id]:value }})
  }
}

/**
 * Updates FormModel and its InputModels values with new Values. All ald values
 * are removed. New Values are validated;
 *
 * @param {Object} formModel Object FormModel to apply operation on
 * @param {Object} newValues Values to set to FormModel
 * @returns {*} Updated copy of FormModel
 */
export function setValues(formModel, newValues){
  const {models, context} = formModel;

  const updated = Object.keys(models).reduce( (acc,id) => {
    const model = models[id];

    if(model){
      const updatedModel = InputModel.setValue(model, newValues[id], newValues, context);
      acc.models[id] = updatedModel;
      if(updatedModel.invalid) acc.errors[id] = updatedModel.errors;
    }

    return acc;
  }, { models: {}, errors: {}});

  return stabilize({
    ...formModel,
    values: newValues,
    ...updated,
    ...getFlags(updated.models)
  });
}

export function removeModel(formModel, id){
  const models = remove(formModel.models, id);

  return {
    ...formModel,
    models,
    errors: remove(formModel.errors, id),
    ...getFlags(models),
  };
}

function getModel(formModel, id){
  const model = formModel.models[id];
  if(!model){
    throw new Error(`No model with id ${ id }`);
  }
  return model;
}

/**
 * Because of parent child relations, there is a need to visit all models and
 * give them a chance to update themselves if their parent was modified;
 *
 * @param {Object} formModel FormModel to stabilize
 * @returns {*} stable FormModel
 */
function stabilize(formModel){
  const { models, context, values } = formModel,
    modelsIds = Object.keys(models),
    stableModels = {}, errors = {};

  for(let i = 0, l = modelsIds.length; i < l; i++){
    const id = modelsIds[i],
      stableModel = InputModel.stabilize(models[id], values, context);
    stableModels[id] = stableModel;
    if(stableModel.invalid){
      errors[id] = stableModel.errors;
    }
  }

  return {
    ...formModel,
    models: stableModels,
    errors,
    ...getFlags(stableModels)
  };
}

function setModel(formModel, model){
  const
    { id, value, valid, errors } = model,
    models = add(formModel.models, id, model);

  return {
    ...formModel,
    models,
    values: add(formModel.values, id, value),
    errors: valid ? remove(formModel.errors, id) : add(formModel.errors, id, errors),
    ...getFlags(models),
  };
}

function getFlags(models){
  const modelsIds = Object.keys(models);
  let valid = true, pristine = true, touched = false;

  for(let i = 0, l = modelsIds.length; i<l; i++){
    const m = models[modelsIds[i]];
    valid = valid && m.valid;
    pristine = pristine && m.pristine;
    touched = touched || m.touched;

    if(!valid && !pristine && touched) break; // all changed
  }

  return {
    valid, invalid: !valid,
    touched, untouched: !touched,
    pristine, dirty: !pristine
  };
}

function add(target, id, value){
  return { ...target, [id]: value }
}

function remove(target, id){
  const { [id]: ignore, ...restOfTarget} = target;
  return restOfTarget;
}
