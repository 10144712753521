const id = 'pattern';

export default {
  create(config){
    return {
      id,
      isInvalid (value, values, context, name) {
        return value && !config.test(value) && {
          id,
          message: `${ name } is not in valid format`
        };
      }
    }
  },
}
