<script>
  import {tick} from 'svelte';
  import {default as filters, enableFilter} from './Filters.store';
  import Select from '../inputs/Select.svelte';

  let options;

  $: options = $filters.inactive.map(filter => ({ value: filter.id, label: filter.label }));

  async function handleSelection(filterId){
    enableFilter($filters.inactive.find(f => f.id === filterId));
    await tick();
    const el = document.getElementById(filterId);
    if(el) el.focus();
  }
</script>

{#if options.length}
  <div class="Component">
    <Select label="New Filter"
            options="{options}"
            icon="add_circle"
            on:change={e => handleSelection(e.detail)}
    />
  </div>
{/if}


<style lang="stylus">.Component {
  margin-right: 5px;
}
.Component > :global(.rbButton ) {
  height: 26px;
  font-size: 13px;
  margin-bottom: 5px;
  background: #525b61;
}
.Component > :global(.rbButton ):before {
  width: 30px;
  font-size: 18px;
}
.Component :global(.ValueLabel ) {
  color: #cfd8dc;
}
.Component > :global(.rbButton:before ) {
  width: 30px;
  font-size: 18px;
  color: #cfd8dc;
}
</style>