<script>
  import Header from "root/pages/entities/profile/components/Header.component.svelte";
  import HotelHeader from "root/pages/hotels/profile/components/Header.component.svelte";
  import Comment from './Comment.component.svelte';
  import AddComment from './AddComment.component.svelte';

  import EditSubscriptionInformationDialog from "./EditCustomerSubscriptionNotes.form.svelte";
  import modals from "root/services/modals";
  import currentUserService from 'root/services/currentUserService.js';
  import moment from 'moment';
  import {getEntitySubscriptionOptions, getSubscriptionTermLabel, subscriptionTerms, getSubscriptionTypeLabel} from "../../../assets/data/subscription-types";

  import {
    getAccount,
    getRfpActivity,
    saveSubscriptionNotes
  } from "../search.service.js";

  import {loadComments, createComment, dropComment, updateComment} from '../comments.service.js';

  export let entity, hotel, typeName, type;

  let account = null,
    currentUser,
    rfpActivity, commentsData = {data: [], lastPage: true};

  async function load() {
    let [a, activity, user] = await Promise.all([
      getAccount(entity.id),
      getRfpActivity(entity.id, type),
      currentUserService.get()
    ]);
    account = a;
    rfpActivity = activity;
    currentUser = user;

    a && getComments(a.id, 1);
  }

  $: subscription = (account && account.subscription) || { comment: "" };
  $: activityStates =
    rfpActivity &&
    reduceStates(rfpActivity.states);

  function reduceStates(states) {
    return states
      .sort((a, b) => (a._id > b._id ? -1 : 1));
  }

  function editSubscriptionNotes() {
    modals
      .show(EditSubscriptionInformationDialog, {
        notes: subscription,
        types: getEntitySubscriptionOptions(account.type).map(({id, label}) => ({label, value: id})),
        terms: subscriptionTerms
      })
      .then(notes => {
        account.subscription = notes;
        saveSubscriptionNotes(account.id, notes);
      });
  }

  async function getComments(accountId, nextPage) {
    const {data, lastPage, page, total} = await loadComments(accountId, nextPage)
    commentsData = {
      data: [...commentsData.data, ...data],
      lastPage,
      page,
      total
    }
  }

  async function addComment({detail}) {
    const comment = await createComment(account.id, detail)
    commentsData.data = [
      comment,
      ...commentsData.data
    ];
  }

  function editComment({detail}) {
    updateComment(account.id, detail.id, detail.comment)
  }

  async function deleteComment({detail}) {
    await dropComment(account.id, detail.id);
    commentsData.data = commentsData.data.filter(({id}) => id !== detail.id);
  }

  function loadMore() {
    getComments(account.id, commentsData.page + 1);
  }

  function date(value) {
    return value? moment(value).format('MM/DD/YYYY'): "";
  }

  entity = entity || hotel;
  load();
</script>

{#if type === "HOTEL"}
  <HotelHeader hotel={entity} {typeName}>
    <slot />
    <button
      class="rbButton asDarkMain"
      data-icon="account_balance_wallet"
      on:click={editSubscriptionNotes}
    >
      Update Subscription
    </button>
  </HotelHeader>
{:else}
  <Header {entity} {typeName}>
    <slot />
    <button
      class="rbButton asDarkMain"
      data-icon="account_balance_wallet"
      on:click={editSubscriptionNotes}
    >
      Update Subscription
    </button>
  </Header>
{/if}

<div class="main dark">
  <div class="flex">
    {#if account}
      <div class="section">
        <h1 class="title">Subscription Details</h1>
        <div class="content">
          <div class="data">
            <span class="label">Subscription Type</span>
            <span class="value">
              {getSubscriptionTypeLabel(subscription.type)}
            </span>
          </div>
          <div class="data">
            <span class="label">Subscription Terms</span>
            <span class="value">
              {getSubscriptionTermLabel(subscription.terms)}
            </span>
          </div>
          <div class="data">
            <span class="label">Subscription Amount</span>
            <span class="value">{subscription.amount || ""}</span>
          </div>
          <div class="data">
            <span class="label">Last Payment Date</span>
            <span class="value">{date(subscription.lastPaymentDate)}</span>
          </div>
          <div class="data">
            <span class="label">Payment Expiring Date</span>
            <span class="value">{date(subscription.expiryDate)}</span>
          </div>
          <div class="data">
            <span class="label">Last Login Date</span>
            <span class="value">{date(account.lastLoginDate)}</span>
          </div>
          <div class="data">
            <span class="label">Comment</span>
            <span class="value">{subscription.comment || ""}</span>
          </div>
        </div>
      </div>
    {/if}
    {#if rfpActivity}
      <div class="section rfp">
        <h1 class="title">RFP Activity</h1>
        <div class="content">
          {#if rfpActivity.side === "BUYER"}
            <div class="data">
              <span class="label">Number of RFPs Created</span>
              <span class="value">{rfpActivity.rfpCount}</span>
            </div>
          {/if}
          {#each activityStates as state}
            <div class="data">
              <span class="label bid-stat">{state.label}</span>
              <span class="value">{state.count}</span>
            </div>
          {/each}
        </div>
      </div>
    {/if}
  </div>
  {#if account}
    <div class="section rfp">
      <h1 class="title">Admin Comments</h1>
      <div class="content" style="position: relative;">
        <div class="comments">
          {#each commentsData.data as comment}
            <Comment
              {comment}
              {currentUser}
              on:update={editComment}
              on:delete={deleteComment}
            />
          {/each}
          {#if !commentsData.lastPage && commentsData.total > 0}
            <button class="rbButton asDarkMain" on:click={loadMore}>
              Load More
            </button>
          {/if}
        </div>
        <AddComment on:add={addComment} />
      </div>
    </div>
  {/if}
</div>

<style lang="stylus">.label {
  color: #72828a;
  min-width: 200px;
}
.main {
  display: flex;
  flex-direction: column;
}
.value {
  color: #fff;
}
.label.bid-stat {
  text-transform: capitalize;
}
.flex {
  display: flex;
}
.section {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.comments {
  height: calc(100% - 70px);
  overflow: auto;
}
.content {
  height: 100%;
}
</style>
