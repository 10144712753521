<script>
    import {createEventDispatcher } from 'svelte';
    import Checkbox from 'root/pages/hotels/advanced-search/Checkbox.component.svelte';
    import {get} from 'lodash';
    import {openProfile} from './actions.service';

    export let entity;
    export let selectedEntities;
    export let props;

    export let open = openProfile;

    const dispatch = createEventDispatcher();

    function image({logo}) {
        return logo ? (logo.startsWith('http') ? logo : `/images/logos/${logo}`): '';
    }

    function select() {
        dispatch('select', {_id: entity._id, selected});
    }


    $: selected = selectedEntities.includes(entity._id)
</script>

<div class="entity" on:click={select}>
    <Checkbox {selected} />
    <div class="data">
        <img src={image(entity)} alt={entity.name} class="logo">
        <div class="details">
            <div class="identifier" on:click|stopPropagation={() => open(entity)}>
                <b>{entity.name} </b>&middot; {entity.location ? entity.location.fullAddress: ''}
            </div>
            <div class="props">
                {#each props as prop}
                    <div class="prop" style={`width: ${ (prop.size * 5) + 60 }px;`}>
                        <span class="label">{prop.label}:</span>
                        <span class="value">{prop.get? prop.get(entity): get(entity, prop.key, '---')}</span>
                    </div>
                {/each}
            </div>
        </div>
    </div>
</div>

<style type="text/stylus">.entity {
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 0;
  border-bottom: 1px solid rgba(176,190,197,0.239);
}
.data {
  flex: 1;
  display: flex;
  align-items: center;
}
.details {
  flex: 1;
  font-size: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.props {
  opacity: 0.6;
  display: flex;
  flex-wrap: wrap;
}
.prop {
  max-width: 360px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.prop:not(:last-child) {
  border-right: 1px solid;
}
.prop:not(:last-child) {
  margin-right: 5px;
}
.prop span {
  margin-right: 5px;
}
.logo {
  height: 69px;
  width: 80px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  margin-right: 5px;
  overflow: hidden;
  position: relative;
}
img[alt]:after {
  padding: 5px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-family: 'Helvetica';
  font-weight: 300;
  line-height: 2;
  text-align: center;
  content: attr(alt);
  font-size: 10px;
  box-sizing: border-box;
}
.identifier {
  cursor: pointer;
}
</style>
