export default [
  { 'id': 'ADVERTISING_AND_MARKETING', 'label': 'Advertising & Marketing'},
  { 'id': 'BUSINESS_PRODUCTS_AND_SERVICES', 'label': 'Business Products & Services'},
  { 'id': 'COMPUTER_HARDWARE', 'label': 'Computer Hardware'},
  { 'id': 'CONSTRUCTION', 'label': 'Construction'},
  { 'id': 'CONSUMER_PRODUCTS_AND_SERVICES', 'label': 'Consumer Products & Services'},
  { 'id': 'EDUCATION', 'label': 'Education'},
  { 'id': 'ENERGY', 'label': 'Energy'},
  { 'id': 'ENGINEERING', 'label': 'Engineering'},
  { 'id': 'ENVIRONMENTAL_SERVICES', 'label': 'Environmental Services'},
  { 'id': 'FINANCIAL_SERVICES', 'label': 'Financial Services'},
  { 'id': 'FOOD_AND_BEVERAGE', 'label': 'Food & Beverage'},
  { 'id': 'GOVERNMENT_SERVICES', 'label': 'Government Services'},
  { 'id': 'HEALTH', 'label': 'Health'},
  { 'id': 'TRAVEL_AND_HOSPITALITY', 'label': 'Hospitality & Travel'},
  { 'id': 'HUMAN_RESOURCES', 'label': 'Human Resources'},
  { 'id': 'INSURANCE', 'label': 'Insurance'},
  { 'id': 'IT_SERVICES', 'label': 'IT Services'},
  { 'id': 'LOGISTICS_AND_TRANSPORTATION', 'label': 'Logistics & Transportation'},
  { 'id': 'MANUFACTURING', 'label': 'Manufacturing'},
  { 'id': 'MEDIA', 'label': 'Media'},
  { 'id': 'NON_PROFIT', 'label': 'Non Profit'},
  { 'id': 'REAL_ESTATE', 'label': 'Real Estate'},
  { 'id': 'RETAIL', 'label': 'Retail'},
  { 'id': 'SECURITY', 'label': 'Security'},
  { 'id': 'SOFTWARE', 'label': 'Software'},
  { 'id': 'TELECOMMUNICATIONS', 'label': 'Telecommunications'},
  { 'id': 'OTHER', 'label': 'Other'}
]
