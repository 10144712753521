import { Route } from '@readybid-team/rb-spa-router';
import Mounter from '@readybid-team/rb-spa-router-svelte-mount';
import currentUserService from 'src/services/currentUserService';

const BidsExportRoute = new Route('Bids', '/bids/exports', Mounter(
    () => currentUserService.get()
      .then(() => import( 'src/pages/bids/BidsExport.page.svelte'))
  ));

export default BidsExportRoute;
