import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';
import {TYPE_TEXT} from './Filters.store';

export default function FilterText({label, test = '', value = ''}){
  this.id = uniqueId();
  this.type = TYPE_TEXT;
  this.label = label;
  this.value = value;
  this.test = (i, f) => {
    const testValue = get(i, test, '');
    return testValue && testValue.toLowerCase().startsWith(f.value.toLowerCase());
  }
  this.isEmpty = !value;

  this.setValue = (val = '') => ({
    ...this,
    value: val,
    isEmpty: !val
  });
}