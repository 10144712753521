import {
  FREE_TEXT_FILTER_TYPE,
  MULTI_SELECT_FILTER_TYPE
} from 'root/pages/hotels/advanced-search/filters/filter-types';

import industries from '../../assets/data/industries';
import accountTypes from '../../assets/data/account-types';
import subscriptionTypes, { subscriptionTerms } from '../../assets/data/subscription-types';

export default function getFilter(states, countries) {
  return Object.freeze([
    {
      label: `Name`,
      type: FREE_TEXT_FILTER_TYPE,
      id: 'name',
      value: ''
    },
    {
      label: `Industry`,
      type: MULTI_SELECT_FILTER_TYPE,
      id: 'industry',
      value: [],
      options: industries.map(({ id, label }) => ({ value: id, label }))
    },
    {
      label: `Account Type`,
      type: MULTI_SELECT_FILTER_TYPE,
      id: 'type',
      value: [],
      options: accountTypes.map(({ id, label }) => ({ value: id, label }))
    },
    {
      label: 'Address',
      type: FREE_TEXT_FILTER_TYPE,
      id: 'address',
      value: ''
    },
    {
      label: 'Zip',
      type: FREE_TEXT_FILTER_TYPE,
      id: 'zipCode',
      value: ''
    },
    {
      label: 'City',
      type: FREE_TEXT_FILTER_TYPE,
      id: 'city',
      value: ''
    },
    {
      label: 'State',
      type: MULTI_SELECT_FILTER_TYPE,
      id: 'state',
      value: [],
      options: states
    },
    {
      label: 'Region',
      type: FREE_TEXT_FILTER_TYPE,
      id: 'region',
      value: ''
    },
    {
      label: 'Country',
      type: MULTI_SELECT_FILTER_TYPE,
      id: 'country',
      value: [],
      options: countries
    },
    {
      label: 'Subscription Type',
      type: MULTI_SELECT_FILTER_TYPE,
      id: 'subType',
      value: [],
      options: list(subscriptionTypes.map(({label, id}) => ({label, value: id}))),
    },
    {
      label: 'Subscription Terms',
      type: MULTI_SELECT_FILTER_TYPE,
      id: 'subTerm',
      value: [],
      options: list(subscriptionTerms),
    },
  ])
}

function list(values) {
  return [
    {
      label: 'Not Specified',
      value: null,
    },
    ...values
  ]
}
