<script>
  import {loadCustomers} from 'src/pages/users/fix-email-address-service/report/emailAddressFixReportApi';

  export let value, name;

  let
    customers = [],
    filteredCustomers = [],
    menuVisible = false,
    query;

  $: filteredCustomers = filterCustomers(customers, query);

  loadCustomers()
    .then(r => {
      customers = r.data.map(c => ({
        ...c,
        $name: c.name.toLowerCase()
      }))
        .sort((a,b) => a.$name > b.$name ? 1 : -1);
    })


  function showMenu(){
    menuVisible = true;
  }

  function hideMenu() {
    menuVisible = false;
  }

  function selectCustomer(customer){
    query = customer.name;
    name = customer.name;
    value = customer.id;
  }

  function filterCustomers(list = [], query = ''){
    if(query){
      const pattern = new RegExp(query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')
      return list.filter(i => pattern.test(i.name));
    } else {
      return list;
    }
  }

</script>

<div class="Component" on:focusout={hideMenu}>
  <input type="text" placeholder="Search" on:click={showMenu} bind:value={query} />

  {#if menuVisible}
    <div class="Menu">
      {#each filteredCustomers as customer}
        <div class="Item" on:mousedown={() => selectCustomer(customer)}>{customer.name}</div>
      {/each}
    </div>
  {/if}
</div>


<style lang="stylus">.Component {
  display: inline;
  position: relative;
}
.Menu {
  position: absolute;
  top: 25px;
  left: 10px;
  background: #273238;
  border: 1px solid #546e7a;
  max-height: 500px;
  overflow-y: scroll;
  z-index: 10;
}
.Item {
  height: 25px;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 10px;
  max-width: 280px;
  cursor: pointer;
}
.Item:hover {
  color: #fff;
}
</style>
