<script>
    import Spinner from 'components/atoms/Spinner.svelte';
    import Select from 'components/molecules/inputs/Select.svelte';
    import orderBy from 'lodash/orderBy';
    import get from 'lodash/get';
    import moment from 'moment';

    import ActionButtons from 'root/pages/hotels/profile/components/ActionButtonsHelper.component.svelte';

    import Header from '../../components/Header.component.svelte';
    import User from './User.component.svelte';
    import Checkbox from 'root/pages/hotels/advanced-search/Checkbox.component.svelte';

    import {loadUsers} from '../../services/entity.service';
    import {getActions} from './actions.factory';
    import {createRep, createUserWithPassword} from 'root/pages/hotels/profile/modules/reps/actions.service';

    export let entity, typeName;

    let loading = true,
        props =[
            {
                label: 'Role',
                get: ({role}) => {
                    return role && role.permission === 'ACCOUNT_ADMIN'? 'Admin': 'Account Manager';
                }
            },
            {
                label: 'Job Title',
                key: 'jobTitle'
            },
            {
                label: 'Added On',
                get: ({userAccountStatus, status}) => {
                    const at = userAccountStatus? userAccountStatus.at: status.at;
                    return at && moment(at).format('Y-MM-DD') || '---';
                }
            },
            {
                label: 'Phone',
                key: 'phone'
            },
            {
                label: 'Email Address',
                key: 'emailAddress'
            }
        ],
        sorters = [
            {
                label: 'Full Name',
                value: 'fullName',
                key: 'fullName',
            },
            {
                label: 'Role',
                value: 'role',
                get: ({role}) => {
                    return role && role.permission === 'ACCOUNT_ADMIN'? 'Admin': 'Account Manager';
                }
            },
            {
                label: 'Added On',
                value: 'added',
                get: ({userAccountStatus, status}) => {
                    const at = userAccountStatus? userAccountStatus.at: status.at;
                    return at && moment(at).format('Y-MM-DD') || '---';
                }
            },
            {
                label: 'Phone',
                value: 'phone',
                key: 'phone'
            },
            {
                label: 'Job Title',
                value: 'jobTitle',
                key: 'jobTitle'
            },
            {
                label: 'Email Address',
                value: 'emailAddress',
                key: 'emailAddress'
            },
            {
                label: 'Status',
                value: 'status',
                get: ({invitation}) => (invitation? 'Pending': 'Verified')
            },
        ],
        sorter = sorters[0],
        users = {users: [], invitations: []},
        selectedList = [];

    $: props = [...users.users, ...users.invitations].reduce((acc, rep) => {
        return acc.map(prop => {
            const value = prop.get? prop.get(rep): get(rep, prop.key, ''), size = Math.max(prop.size || 0, value.length + prop.label.length);
            return {...prop, size};
        });
    }, props);
    $: actions = getActions(selectedList);
    $: showActions = actions.length > 0;
    $: params = [entity, selectedList, [...users.users, ...users.invitations]];

    load();

    function load() {
        loading = true;
        loadUsers(entity.id).then(Us => {
            users = Us;
            sort({detail: sorter.value});
            loading = false;
        });
    }

    function selectBulk(isInvitation = false) {
        if(allSelected(isInvitation)) {
            const selectedItems = selectedByType(isInvitation);
            selectedList = selectedList.filter(({id}) => !selectedItems.find(s => s.id === id));
        }else{
            const key = isInvitation? 'invitations': 'users',
                items = users[key];
            selectedList = [
                ...selectedList,
                ...items.filter(({id}) => !selectedList.find(s => s.id === id))
            ];
        }
    }

    function selectedByType(isInvitation) {
        return selectedList.filter(i => isInvitation? i.invitation: !i.invitation);
    }

    function allSelected(isInvitation = false, list) {
        const selectedItems =  selectedByType(isInvitation),
            key = isInvitation? 'invitations': 'users',
            items = users[key];
        return selectedItems.length > 0 && selectedItems.length === items.length;
    }

    function someSelected(isInvitation = false, list) {
        const selectedItems =  selectedByType(isInvitation),
            key = isInvitation? 'invitations': 'users',
            items = users[key];
        return selectedItems.length > 0 && selectedItems.length < items.length;
    }

    function sort({detail}) {
        sorter = sorters.find(({value}) => value === detail) || sorters[0];
        users = {
            users: orderBy(users.users, [sorter.get || sorter.key], ['asc']),
            invitations: orderBy(users.invitations, [sorter.get || sorter.key], ['asc'])
        };
    }

    function select(user, selected) {
        if(selected) {
            selectedList = selectedList.filter(({id}) => user.id !== id);
        }else{
            selectedList = [...selectedList, user];
        }
    }

    function actionCompleted({id, res}) {
        switch(id) {
            case 'edit':
            case 'delete':
            case 'deactivate':
            case 'activate':
            case 'grantAdmin':
            case 'revokeAdmin':
                load();
                selectedList = [];
                break
            default: break;
        }
    }

    function invite() {
        return createRep(entity).then(load);
    }

    function createUser(){
      return createUserWithPassword(entity).then(load);
    }
</script>

<Header {entity} {typeName} {showActions}>
    <div slot="actions">
        <ActionButtons {actions} {params} {actionCompleted} />
    </div>
    <slot />
    <span class="sort">
        <Select id="sort by" options={sorters} value={sorter.value} iconStart="sort" on:input={sort}>Sort By</Select>
    </span>
    <button class="rbButton asDarkMain" data-icon="person_add" on:click={invite}>Invite User</button>
    <button class="rbButton asDarkMain" data-icon="person_add_alt" on:click={createUser}>Create User</button>
</Header>

<div class="reps main">
    {#if loading}
        <div class="empty">
            <Spinner />
        </div>
    {:else}
        <div class="section" id="main">
            <div class="title">
                <Checkbox
                    selected={ allSelected(false, selectedList) }
                    indeterminate={ someSelected(false, selectedList) }
                    on:toggle={() => selectBulk(false)}
                />
                Registered {typeName} Users
            </div>
            <div class="content">
                {#each users.users as user}
                    <User {user} {selectedList} {props} on:select={(e) => select(user, e.detail)}/>
                {:else}
                    <div class="empty">
                        No Registered Users
                    </div>
                {/each}
            </div>
        </div>

        <div class="section" id="invites">
            <div class="title">
                <Checkbox
                    selected={ allSelected(true, selectedList) }
                    indeterminate={ someSelected(true, selectedList) }
                    on:toggle={() => selectBulk(true)}
                />
                Pending {typeName} User Invitations
            </div>
            <div class="content">
                {#each users.invitations as user}
                    <User {user} {selectedList} {props} on:select={(e) => select(user, e.detail)}/>
                {:else}
                    <div class="empty">
                        No pending user invitations
                    </div>
                {/each}
            </div>
        </div>
    {/if}
</div>


<style type="text/stylus">.empty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reps.main .section .content {
  padding: 0;
}
.reps.main .section .title {
  height: 42px;
  border-top: 5px solid #273238;
  background: #2c383d;
  display: flex;
  align-items: center;
  padding: 0;
}
.sort :global(.rbInput.component) {
  width: 175px;
  margin-right: 5px;
}
</style>
