<script>
    import {get} from 'lodash';
    import ProfileRoute from 'root/routes/EntityProfile';

    import FormModel from 'components/molecules/inputs/FormModel.svelte';
    import Coordinates from 'components/molecules/coordinates/Coordinates.svelte';
    import { listHMCs } from 'root/api/index.js';
    import { getModelFromObect } from 'root/utils';
    import modals from 'root/services/modals';

    import Header from '../../components/Header.component.svelte';
    import StarRating from '../../components/StarRating.component.svelte';
    import EditBasicInfoFormController from './EditBasicInformationForm.controller.svelte';
    import GdsDialog from './dialogs/gds-codes/GdsCodes.dialog.svelte';
    import DropDown from 'root/pages/hotels/profile/components/ActionsHelperDropDown.component.svelte';

    import HotelMarketTiers from 'root/assets/data/hotel-market-tiers';
    import {BASIC_INFO} from './basic-info.module';
    import { changeHotelGroup } from '../../../advanced-search/search.service';
    import rebrand from './rebrand';
    import {DateTimeFormatter} from 'src/utils/internationalization.service';

    export let hotel;

    let editing = false, editModel = {}, formModel = {};
    const formatDateTime = DateTimeFormatter().format;

    function getCode(code, prop = code) {
        const chainCode = get(hotel, `answers.${code}_CHAINCODE`, ''),
            propCode = get(hotel, `answers.${prop}_PROPCODE`, '');
        return `${chainCode} ${propCode}`;
    }

    function edit() {
        makeEditModel();
        editing = true;
    }

    function makeEditModel() {
        var res = getModelFromObect(hotel);

        editModel = {
            ...res,
            'location.coordinates.lat': res['location.coordinates.latitude'],
            'location.coordinates.lng': res['location.coordinates.longitude'],
        };
    }

    function updated({detail}) {
        editing = false;
        hotel = detail;
    }

    async function updateGdsCodes() {
      const names = [
        name('AMADEUS'),
        name('APOLLO'),
        name('SABRE'),
        name('WORLDSPAN', 'WRLDSPAN'),
        {
          prop: 'INTERNALHOTELCODE',
          propName: 'Internal Code'
        }
      ];

      const codes = {
        ...code('AMADEUS'),
        ...code('APOLLO'),
        ...code('SABRE'),
        ...code('WORLDSPAN', 'WRLDSPAN'),
        INTERNALHOTELCODE: get(hotel, 'answers.INTERNALHOTELCODE', '')
      };

      try {
        const res = await modals.show(GdsDialog, { codes, names, id: hotel.id });
        window.location.reload();
      } catch (e) {}

      function code(code, prop = code) {
        return {
          [`${code}_CHAINCODE`]: get(hotel, `answers.${code}_CHAINCODE`, ''),
          [`${prop}_PROPCODE`]: get(hotel, `answers.${prop}_PROPCODE`, ''),
        }
      }

      function name(code, prop = code) {
        return  {
          chain: `${code}_CHAINCODE`,
          prop: `${prop}_PROPCODE`,
          propName: `${code} Prop Code`,
          chainName: `${code} Chain Code`,
        }
      }
    }

    $: lastUpdated = formatLastUpdated(hotel);
    $: marketTier = get(HotelMarketTiers.find(({value}) => value === hotel.marketTier), 'label', 'Hotel Property');
    $: website = hotel.website && hotel.website.match(/^[a-zA-Z]+?:\/\//)? hotel.website: `//${hotel.website}`;
    $: coordinates = {
        lat: hotel.location.coordinates.latitude,
        lng: hotel.location.coordinates.longitude,
    }
    $: AMADEUS_CODE = getCode('AMADEUS');
    $: SABRE_CODE = getCode('SABRE');
    $: APOLLO_CODE = getCode('APOLLO');
    $: WORLDSPAN_CODE = getCode('WORLDSPAN', 'WRLDSPAN');
    $: INTERNALHOTELCODE = hotel.answers["INTERNALHOTELCODE"] || ''

    function entityHref({id}, type = "CHAIN") {
        return ProfileRoute.href({type, entityId: id, section: BASIC_INFO});
    }

    function regroup() {
      listHMCs().then(companies => changeHotelGroup([hotel], companies));
    }

    const actions = [
        {
            id: 'rebrand',
            label: 'Update Brand/Chain',
            icon: 'link',
            action: () => rebrand(hotel)
        },
        {
            id: 'reGroup',
            label: 'Update HMC',
            icon: 'apartment',
            action: regroup
        }
    ], execute = ({action}) => action();

    function formatLastUpdated(h){
      if(h === null) return '';

      if(h.updated){
        const at = new Date(h.updated.at);
        const by = `${h.updated.by.firstName} ${h.updated.by.lastName} <${h.updated.by.emailAddress}>`
        return `${by} ${isNaN(at.getTime()) ? '' : formatDateTime(at)}`
      }

      if(h.lastUpdated){
        const at = new Date(h.lastUpdated);
        return formatDateTime(at);
      }
    }

</script>

{#if !editing}
    <Header {hotel}>
        <DropDown {actions} {execute} icon="group_work" label="Rebrand" />
        <button class="rbButton asDarkMain" data-icon="edit" on:click={edit} >Edit</button>
        <slot />
    </Header>

    <div class="main rbDark" id={BASIC_INFO}>
        <div class="section" id="basic">
            <h2 class="title space">
                <span>Basic Information</span>
                {#if lastUpdated}<span class="LastUpdated">Last updated by: <b>{lastUpdated}</b></span>{/if}
            </h2>
            <div class="content">
                <div class="data">
                    <span class="label">Property Name</span>
                    <span class="value">{hotel.name}</span>
                </div>
                <div class="data">
                    <span class="label">Address</span>
                    <span class="value">{hotel.location.fullAddress}</span>
                </div>
                <div class="data">
                    <span class="label">Star Rating</span>
                    <span class="value"><StarRating rating={hotel.rating} /></span>
                </div>
                <div class="data">
                    <span class="label">Property Market Tier</span>
                    <span class="value">{marketTier}</span>
                </div>
                <div class="data">
                    <span class="label">Master Chain Name</span>
                    {#if  hotel.chain && hotel.chain.masterChainName}
                        <a target="_blank" href={entityHref(hotel.chain.masterChain)} class="value">{hotel.chain.masterChainName}</a>
                    {/if}
                </div>
                <div class="data">
                    <span class="label">Brand Name</span>
                    {#if  hotel.chain && hotel.chain.name}
                        <a target="_blank" href={entityHref(hotel.chain)} class="value">{hotel.chain.name}</a>
                    {/if}
                </div>
                <div class="data">
                    <span class="label">Phone</span>
                    <span class="value">{hotel.phone}</span>
                </div>
                <div class="data">
                    <span class="label">Email</span>
                    <span class="value">{hotel.emailAddress}</span>
                </div>
                <div class="data">
                    <span class="label">Website</span>
                    {#if hotel.website}
                        <a href={website} target="_blank" class="value">{hotel.website}</a>
                    {/if}
                </div>
                <div class="data">
                    <span class="label">Management Company</span>
                    {#if hotel.hotelManagementCompany && hotel.hotelManagementCompany.name}
                        <a target="_blank" href={entityHref(hotel.hotelManagementCompany, "HMC")} class="value">{hotel.hotelManagementCompany.name}</a>
                    {/if}
                </div>
            </div>
        </div>
        <div class="section" id="codes">
            <h2 class="title">
              <span>GDS Codes</span>
              <button class="rbButton asDarkMain" data-icon="edit" on:click={updateGdsCodes} style="margin-left: 16px;">Edit</button>
            </h2>
            <div class="content">
                <div class="data">
                    <span class="label">Amadeus</span>
                    <span class="value">{AMADEUS_CODE}</span>
                </div>
                <div class="data">
                    <span class="label">Galileo/Apollo</span>
                    <span class="value">{APOLLO_CODE}</span>
                </div>
                <div class="data">
                    <span class="label">Sabre</span>
                    <span class="value">{SABRE_CODE}</span>
                </div>
                <div class="data">
                    <span class="label">WorldSpan</span>
                    <span class="value">{WORLDSPAN_CODE}</span>
                </div>
                <div class="data">
                  <span class="label">Internal</span>
                  <span class="value">{INTERNALHOTELCODE}</span>
              </div>
            </div>
        </div>
        <Coordinates value={coordinates} zoom={13}></Coordinates>
    </div>
{:else}
    <FormModel bind:values={editModel}>
        <EditBasicInfoFormController bind:formModel {hotel} bind:editing on:updated={updated} />
    </FormModel>
{/if}

<style type="text/stylus">.label {
  color: #72828a;
  min-width: 200px;
}
a.value {
  color: #fff;
}
.main :global(.mapDiv:not(.expanded)) {
  height: 100px;
  width: 100px;
  max-width: 100px;
  min-width: 0;
  position: fixed;
  bottom: 10px;
  right: 10px;
}
.main :global(.mapDiv:not(.expanded) .coordinates),
.main :global(.mapDiv:not(.expanded) .coordinates .gm-fullscreen-control) {
  display: none;
}
.LastUpdated {
  font-size: 12px;
}
</style>
