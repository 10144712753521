import adminServer from 'root/api/AdminServer';
import {checkAndExecute} from 'root/api/helpers';

export function search(request) {
    return checkAndExecute(
      () => request,
      () => adminServer.put(`customers/search/`, request)
    )
}

export function loadAccount(entityId) {
  return checkAndExecute(
    () => entityId,
    () => adminServer.get(`customers/${entityId}/account`)
  );
}

export function fetchRfpActivity(entityId, type) {
  return checkAndExecute(
    () => type && entityId,
    () => adminServer.get(`customers/${type}/${entityId}/rfp-activity`)
  );
}

export function updateSubscriptionNotes(accountId, notes) {
  return checkAndExecute(
    () => accountId,
    () => adminServer.put(`customers/${accountId}/subscription-notes`, notes)
  );
}

export function getComments(accountId, request) {
  return checkAndExecute(
    () => accountId && request,
    () => adminServer.put(`account/${accountId}/comments`, request)
  );
}

export function addComment(accountId, comment) {
  return checkAndExecute(
    () => accountId && comment,
    () => adminServer.post(`account/${accountId}/comments`, {comment})
  );
}

export function editComment(accountId, commentId, comment) {
  return checkAndExecute(
    () => accountId && commentId && comment,
    () => adminServer.put(`account/${accountId}/comments/${commentId}`, {comment})
  );
}
export function deleteComment(accountId, commentId) {
  return checkAndExecute(
    () => accountId && commentId,
    () => adminServer.post(`account/${accountId}/comments/${commentId}`)
  );
}

export function listAccounts(types){
  return checkAndExecute(
    () => types,
    () => adminServer.get(`accounts`, {types: types.join(',')})
  );
}
