<script>
  import noop from "lodash/noop";
  import Overlay from "./Overlay.svelte";
  import ModalCard from "./ModalCard.svelte";

  export let closeOnClick = true;
  export let cancel = noop;
  export let wide = false;
  export let width = wide ? 960 : 500;
</script>

<Overlay {closeOnClick} {cancel}>
  <ModalCard {width}>
    <div class="closeButtonContainer">
      <button class="closeButton material-icons" on:click={cancel}>close</button>
    </div>

    <slot></slot>
  </ModalCard>
</Overlay>

<style type="text/stylus">.closeButtonContainer {
  position: relative;
  z-index: 1000;
}
.closeButton {
  position: absolute;
  background: transparent;
  right: 0;
  top: 0;
  padding: 5px;
  border: 0;
  outline: 0;
  color: #546e7a;
  cursor: pointer;
  transition: color 0.2s ease-out;
}
.closeButton:hover {
  color: #455a64;
}
</style>
