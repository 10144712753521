<!--suppress ES6UnusedImports -->
<script>
  import { tick } from 'svelte';

  import isNil from 'lodash/isNil';
  import noop from 'lodash/noop';
  import {fade} from 'svelte/transition';

  export let id;
  export let items = [];
  export let value = '';
  export let menuShown = false;
  export let onSelect = noop;
  export let onShowMenu = noop;

  let menuElement;

  $: scrollToSelectedElement(menuShown, value);

  function scrollToSelectedElement(shown, v){
    if(shown && !isNil(v)){
      tick().then(() => {
        scrollToElementIfNeeded(document.getElementById(createOptionElementId(v)));
      });
    }
  }

  function createOptionElementId(optionValue){
    return `${id}.${optionValue}`;
  }

  function scrollToElementIfNeeded( itemElement ){
    if(menuElement && itemElement){
      const
              itemRect = itemElement.getBoundingClientRect(),
              menuRect = menuElement.getBoundingClientRect();

      let scrollBy = 0;
      if (itemRect.bottom > menuRect.bottom){
        scrollBy = itemRect.bottom - menuRect.bottom;
      }

      else if (itemRect.top < menuRect.top){
        scrollBy = -(menuRect.top - itemRect.top)
      }
      menuElement.scrollTop += scrollBy;
    }
  }

</script>

{#if menuShown}
  <div transition:fade="{{ duration: 100 }}"
       class="Menu"
       bind:this="{menuElement}">

    {#each items as item, index}
      <div id="{createOptionElementId(item.value)}"
           class="Item"
           class:ItemSelected="{value === item.value}"
           on:click="{() => {onShowMenu(false); onSelect(index);}}">
        {item.label}
      </div>
    {/each}
  </div>
{/if}

<style type="text/stylus">.Menu {
  position: absolute;
  z-index: 10;
  margin-top: 3px;
  padding: 5px 0;
  max-height: 200px;
  overflow-y: auto;
  background: #263238;
  border: 1px solid #1a2226;
  border-radius: 5px;
  opacity: 1;
}
.Item {
  font-size: 14px;
  line-height: 33px;
  padding: 0 30px 0 15px;
  cursor: pointer;
  text-align: left;
  color: #90a4ae;
  white-space: nowrap;
  background: transparent;
}
.Item:hover {
  background: #313c42;
}
.ItemSelected {
  color: #fff;
  background: #313c42;
  cursor: default;
}
</style>
