export function approximateTextWidth(str, fontSize = 10) {
  if(!str) return 0;

  // Helvetica
  // const widths = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.2796875,0.2765625,0.3546875,0.5546875,0.5546875,0.8890625,0.665625,0.190625,0.3328125,0.3328125,0.3890625,0.5828125,0.2765625,0.3328125,0.2765625,0.3015625,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.2765625,0.2765625,0.584375,0.5828125,0.584375,0.5546875,1.0140625,0.665625,0.665625,0.721875,0.721875,0.665625,0.609375,0.7765625,0.721875,0.2765625,0.5,0.665625,0.5546875,0.8328125,0.721875,0.7765625,0.665625,0.7765625,0.721875,0.665625,0.609375,0.721875,0.665625,0.94375,0.665625,0.665625,0.609375,0.2765625,0.3546875,0.2765625,0.4765625,0.5546875,0.3328125,0.5546875,0.5546875,0.5,0.5546875,0.5546875,0.2765625,0.5546875,0.5546875,0.221875,0.240625,0.5,0.221875,0.8328125,0.5546875,0.5546875,0.5546875,0.5546875,0.3328125,0.5,0.2765625,0.5546875,0.5,0.721875,0.5,0.5,0.5,0.3546875,0.259375,0.353125,0.5890625]
  // const avg = 0.5279276315789471;

  // NotoSans
  const widths = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.30304438058671873,0.2996584098539062,0.38430767817421874,0.6010098050742188,0.6010098050742188,0.9633086734851561,0.7212117660890626,0.20654421470156248,0.3606058830445313,0.3606058830445313,0.4215533562351562,0.6314835416695312,0.2996584098539062,0.3606058830445313,0.2996584098539062,0.32674617571640624,0.6010098050742188,0.6010098050742188,0.6010098050742188,0.6010098050742188,0.6010098050742188,0.6010098050742188,0.6010098050742188,0.6010098050742188,0.6010098050742188,0.6010098050742188,0.2996584098539062,0.2996584098539062,0.6331765270359374,0.6314835416695312,0.6331765270359374,0.6010098050742188,1.098747502797656,0.7212117660890626,0.7212117660890626,0.7821592392796874,0.7821592392796874,0.7212117660890626,0.6602642928984375,0.8414137271039063,0.7821592392796874,0.2996584098539062,0.54175531725,0.7212117660890626,0.6010098050742188,0.9023612002945312,0.7821592392796874,0.8414137271039063,0.7212117660890626,0.8414137271039063,0.7821592392796874,0.7212117660890626,0.6602642928984375,0.7821592392796874,0.7212117660890626,1.0225631613093749,0.7212117660890626,0.7212117660890626,0.6602642928984375,0.2996584098539062,0.38430767817421874,0.2996584098539062,0.5163605367539063,0.6010098050742188,0.3606058830445313,0.6010098050742188,0.6010098050742188,0.54175531725,0.6010098050742188,0.6010098050742188,0.2996584098539062,0.6010098050742188,0.6010098050742188,0.2404039220296875,0.2607197464265625,0.54175531725,0.2404039220296875,0.9023612002945312,0.6010098050742188,0.6010098050742188,0.6010098050742188,0.6010098050742188,0.3606058830445313,0.54175531725,0.2996584098539062,0.6010098050742188,0.54175531725,0.7821592392796874,0.54175531725,0.54175531725,0.54175531725,0.38430767817421874,0.2810355708234375,0.3826146928078125,0.6382554831351562];
  const avg = 0.561119675384812088;

  return str
    .split('')
    .map(c => c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg)
    .reduce((cur, acc) => acc + cur) * fontSize;
}

export function approximateFieldsTextWidth(o = {}, fontSize = 10){
  return Object.keys(o).reduce( (acc, k) => {
    if(o.hasOwnProperty(k)) {
      const v = o[k];
      acc[k] = (v || v === 0 || v === false) ? approximateTextWidth( `${v}`, fontSize) : 0;
    }

    return acc;
  }, {});
}

export function approximateFieldsMaxTextWidth(a = [], fontSize = 10){
  const o = createObjectWithLongestStringsFromArrayObjects(a);
  return approximateFieldsTextWidth(o, fontSize);
}

export function determineMaxWidths(a = [], fontSize = 10, maxWidths = {}){
  const approximatedMaxWidths = approximateFieldsMaxTextWidth(a, fontSize),
    keys = [ ...new Set( [ ...Object.keys(approximatedMaxWidths), ...Object.keys(maxWidths) ]) ];
  return keys.reduce( (acc, k) => {
    const amw = Math.ceil(approximatedMaxWidths[k] || 0),
      mw = maxWidths[k] || amw;

    acc[k] = amw > mw ? mw : amw;
    return acc;
  }, {});
}

function createObjectWithLongestStringsFromArrayObjects(arr = []){
  return arr.reduce( (acc, o) => {
    Object.keys(o).forEach( k => {
      if(o.hasOwnProperty(k)) {
        const longestEncounteredValue = acc[k] || '';
        let v = o[k];
        v = (v || v === 0 || v === false) ? `${v}` : '';

        if(longestEncounteredValue.length < v.length) acc[k] = v;
      }
    });

    return acc;
  }, {});
}