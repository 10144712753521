<script>
  import { scale } from "svelte/transition";

  export let width;
</script>

  <div class="dialog"
       transition:scale="{{ duration: 300 }}"
       style="min-width:{width}px; max-width:{width}px;">
    <slot></slot>
  </div>

<style type="text/stylus">.dialog {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 6px;
  box-shadow: 0 5px 24px 0 rgba(0,0,0,0.5);
  max-height: 95vh;
  max-width: 95vh;
}
</style>
