<script>
  import noop from 'lodash/noop';
  import DialogTemplate from 'components/modals/DialogTemplate.basic.svelte';
  import InputText from "../../ui-components/molecules/inputs/InputText.svelte";
  import FormModel from "../../ui-components/molecules/inputs/FormModel.svelte";
  import {onMount} from "svelte";
  import CityInput from "components/organisms/AddressInput/CityInput.svelte";
  import CoordinatesModel from "src/pages/hotels/CoordinatesModel.component.svelte";
  import {GoogleMapsGeocode, GooglePlacesAutocomplete} from "components/utils/google/googleProvider";
  import {parseGooglePlaces} from "components/utils/google/googlePlaceParser";
  import AirportApi from './airport.api';

  import httpNotifications from 'src/api/HttpNotifications';

  export let
    cancel = () => {},
    hide = () => {},
    invalid = true,
    setValues = () => {},
    touchAll = () => {},
    untouchAll = () => {},
    airport;

  const EDIT_MODE = 'edit', CREATE_MODE = 'create', LOADING_MODE = 'loading'

  let formModel, viewMode = LOADING_MODE, saving, canUpdateCoordinates = true, coordinatesNotFound, loadingCoordinates;

  $: canUpdateCoordinates = isAbleToUpdateCoordinates(formModel);

  onMount(() => {
    if(airport == null){
      formModel = toFormModel()
      viewMode = CREATE_MODE;
    } else {
      formModel = toFormModel(airport);
      viewMode = EDIT_MODE;
    }
  })

  function submit(){
    touchAll();
    const data = fromFormModel(formModel);
    if(airport == null){
      httpNotifications.handle(AirportApi.create(data))
        .then(hide);
    } else {
      httpNotifications.handle(AirportApi.update(airport._id, data))
        .then(hide);
    }
  }

  function deleteAirport(){
    httpNotifications.handle(AirportApi.remove(airport._id))
      .then(hide);
  }

  function updateCoordinates(){
    loadingCoordinates = true;

    GooglePlacesAutocomplete({
      input: `${formModel.name} ${formModel.iata}`,
      componentRestriction: {country: formModel['location.address.country']},
      types: ['airport']}
    )
      .then(r => {
        if(r && r.length){
          return GoogleMapsGeocode({'placeId': r[0].place_id})
            .then( parseGooglePlaces )
            .then(location => {
              setValues({
                ...formModel,
                "location.coordinates.lat": location.coordinates.lat,
                "location.coordinates.lng": location.coordinates.lng
              });
              loadingCoordinates = false;
            });
        } else {
          loadingCoordinates = false;
          return Promise.reject('No results');
        }
      })
      .catch(e => {
        console.error(e);
        coordinatesNotFound = true;
        setTimeout(() => {
          coordinatesNotFound = false;
        }, 5000);
      })
  }

  function toFormModel(a){
    if(a) {
      return {
        id: a._id,
        name: a.name,
        iata: a.iata,
        'location.address.city': a.location.address.city || '',
        'location.address.state': a.location.address.state || '',
        'location.address.region': a.location.address.region || '',
        'location.address.zip': a.location.address.zip || '',
        'location.address.country': a.location.address.country || '',
        'location.coordinates.lat': a.location.coordinates.lat || 0,
        'location.coordinates.lng': a.location.coordinates.lng || 0,
      };
    } else {
      return {
        name: '',
        iata: '',
        'location.address.city': '',
        'location.address.state': '',
        'location.address.region': '',
        'location.address.zip': '',
        'location.address.country': 'US',
        'location.coordinates.lat': 0,
        'location.coordinates.lng': 0,
      };
    }
  }

  function fromFormModel(fm){
    return {
      name: fm.name,
      iata: fm.iata,
      location: {
        address: {
          city: fm['location.address.city'],
          state: fm['location.address.state'],
          region: fm['location.address.region'],
          zip: fm['location.address.zip'],
          country: fm['location.address.country'],
        },
        coordinates: {
          lat: fm['location.coordinates.lat'],
          lng: fm['location.coordinates.lng'],
        }
      }
    };
  }

  function isAbleToUpdateCoordinates(fm = {}){
    return (fm.name || fm.iata) && fm['location.address.country'];
  }

</script>

<div class="Component">
  <DialogTemplate cancel="{cancel}" closeOnClick="{false}" wide="{true}">
    <div class="Container">
      <div class="Title">
        <div data-icon-before="local_airport"
             class="TitleHeading">Airport Details</div>
      </div>

      <div class="Content">
        {#if viewMode !== LOADING_MODE}
          <FormModel bind:values={formModel} bind:invalid bind:setValues bind:untouchAll bind:touchAll>
            <form class="Form" on:submit|preventDefault="{submit}">
              <div class="twoColumns">
                <div>
                  <InputText id="name"
                             name="Airport Name"
                             maxLength="200"
                             required
                  >Airport Name</InputText>

                  <InputText id="iata"
                             name="IATA Code"
                             maxLength="3"
                             minLength="3"
                             required
                  >IATA Code</InputText>

                  <CityInput></CityInput>

                  <div class="twoColumns Addendum">
                    <div>
                      {#if airport}
                        Status:
                        {#if airport.status.value === 'ACTIVE'}
                          <b class="active">Active</b>
                        {:else if airport.status.value === 'OBSOLETE'}
                          <b class="inactive">Deleted</b>
                        {:else}
                          <b>{airport.status.value}</b>
                        {/if}
                      {/if}
                    </div>
                    <div>
                      <button type="button"
                              class="rbButton asDark asLarge"
                              data-icon="gps_fixed"
                              disabled="{!canUpdateCoordinates || loadingCoordinates}"
                              on:click={updateCoordinates}
                      >Update Coordinates</button>
                    </div>
                  </div>
                </div>

                <div class="CoordinatesContainer">
                  <CoordinatesModel></CoordinatesModel>
                  {#if coordinatesNotFound}
                    <div class="inactive">Coordinates Not Found!</div>
                  {/if}
                </div>
              </div>

              <div class="Actions">
                <div>
                  {#if viewMode === EDIT_MODE && airport.status.value === 'ACTIVE'}
                    <button type="button"
                            class="rbButton asDark asLarge"
                            data-icon="delete"
                            disabled="{saving}"
                            on:click={deleteAirport}
                    >Delete Airport</button>
                  {/if}
                </div>

                <div>
                  <button type="button"
                          class="rbButton asDark asLarge"
                          disabled="{saving}"
                          on:click={cancel}
                  >Cancel</button>

                  <button type="submit"
                          class="rbButton asDark asDarkMain asLarge"
                          data-icon="save"
                          disabled="{invalid || saving}"
                  >{viewMode === CREATE_MODE || airport.status.value === 'ACTIVE'? 'Save': 'Save and Activate'}</button>
                </div>
              </div>
            </form>
          </FormModel>
        {/if}
      </div>
    </div>
  </DialogTemplate>
</div>

<style lang="stylus">.Component :global(.dialog) {
  background: #313c42;
  border-color: #313c42;
  overflow: hidden;
}
.Container {
  font-size: 14px;
  color: #cfd8dc;
  min-height: 420px;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
}
.Title {
  height: 35px;
  padding: 0 10px;
  display: flex;
  font-size: 13px;
  border-bottom: 1px solid #546e7a;
}
.Title [data-icon-before] {
  display: flex;
}
.Title [data-icon-before]:before {
  margin-right: 5px;
  font-size: 16px;
}
.TitleHeading {
  font-weight: bold;
}
.TitleHeading:before {
  font-size: 24px;
}
.Content {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.Form {
  padding: 10px;
}
.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
.CoordinatesContainer {
  height: 285px;
  width: 520px;
  box-sizing: border-box;
}
.Actions {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Addendum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-left: 15px;
}
.active {
  color: #00a99d;
}
.inactive {
  color: #f15a24;
}
</style>
