<script>
  import {onMount} from 'svelte';
  import {init as initStore, default as store} from './Bids.store';
  import CompetitiveBidsPageComponent from './CompetitiveBidsListPageComponent.svelte';
  import Header from 'src/components/Header.svelte';
  import Loader from 'src/components/Loader.svelte';

  export let rfpId;

  onMount(() => {
    initStore(rfpId);
  });
</script>

<div class="rbPage rbDark">
  <Header />

  {#if $store.loading}
    <div class="DocumentView">
      <div class="FillAndCenter">
        <Loader />
      </div>
    </div>
  {:else if $store.ready}
    <div class="DocumentView">
      <CompetitiveBidsPageComponent />
    </div>
  {/if}
</div>
