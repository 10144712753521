import { Router } from '@readybid-team/rb-spa-router';
import Home from './Home.js';
import SignIn from './SignIn.js';
import NotFound from './NotFound.js';
import GeneralError from './GeneralError.js';
import GatewayTimeout from './GatewayTimeout.js';
import PropertiesSearch from './AdvancedHotelSearch.js'
import HotelProfile from './HotelProfile'
import CompetitiveBidsRfps from './competitive-bids/Rfps';
import CompetitiveBidsList from './competitive-bids/Bids';
import {EntitiesSearch, CorporationsActivityPage} from './EntitiesSearch';
import EntitiesAdd from './EntitiesAdd';
import EntityProfile from './EntityProfile';
import ChainsSearch from './Chains';
import HotelDirectoryReports from './HotelDirectoryReports';
import PerDiemCache from './PerDiem';
import Airports from './Airports';
import {Customers, SuspendedAccounts} from './Customers.js';
import {UserActivityReport, UsersSearch, EmailAddressWhitelist, EmailAddressBlacklist, FixEmailAddressServiceManager, FixEmailAddressServiceReport, EmailAddressIgnoreList, UserManager, UnvalidatedUsers} from './UsersSearch.js';
import { default as AdminUsersSearch, Profile } from './AdminUserSearch.js';
import Bids from './Bids';

const router = new Router();

router.add(Home);
router.setNotFound(NotFound);
router.add(GeneralError);
router.add(GatewayTimeout);
router.add(SignIn);
router.add(PropertiesSearch);
router.add(HotelProfile);
router.add(EntitiesAdd);
router.add(EntitiesSearch);
router.add(EntityProfile);
router.add(CorporationsActivityPage)
router.add(ChainsSearch);
router.add(HotelDirectoryReports);
router.add(Customers);
router.add(SuspendedAccounts);

router.add(UsersSearch);
router.add(UserActivityReport);
router.add(EmailAddressWhitelist);
router.add(EmailAddressBlacklist);
router.add(EmailAddressIgnoreList);
router.add(FixEmailAddressServiceManager);
router.add(FixEmailAddressServiceReport);

router.add(CompetitiveBidsRfps);
router.add(CompetitiveBidsList);

router.add(AdminUsersSearch);
router.add(Profile);
router.add(UserManager);
router.add(UnvalidatedUsers);
router.add(PerDiemCache);
router.add(Bids);
router.add(Airports);

router.start();

export default router;
