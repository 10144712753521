<script>
  import {default as store, handleSelection} from './Rfps.store';
  import Spinner from 'components/atoms/Spinner.svelte';
  import BidsListRoute from 'src/routes/competitive-bids/Bids';
  import getSelectionIcon from 'src/utils/selection-icons.service';

  export let rfp = {};

  let selected;

  $: selected = $store.selected.indexOf(rfp.id) !== -1;

</script>

<div class="Row" class:Selected="{selected}">
  <div class="RowSelector">
    {#if rfp.competitiveBidsStatus && rfp.competitiveBidsStatus.value === 'PENDING'}
      <div class="SelectorSpinner">
        <Spinner />
      </div>
    {:else}
      <button class="rbButton iconTransparent"
              class:Selected="{selected}"
              data-icon="{getSelectionIcon(selected)}"
              on:click={() => handleSelection(rfp.id)}
      ></button>
    {/if}
  </div>

  <div>
    {#if rfp.competitiveBidsStatus && rfp.competitiveBidsStatus.value === 'CREATED'}
      <a href="{BidsListRoute.href({ rfpId: rfp.id })}"
         class="RfpTitle"
         on:click|stopPropagation
      >{rfp.name}</a>
    {:else}
      <div class="RfpTitle">
        {rfp.name}
      </div>
    {/if}

    <div class="RfpDetails">
      <div class="Detail">
        <span class="Value Ellipsis" style="{`width:${$store.widths.companyName}px`}">{rfp.companyName}</span>
      </div>

      <div class="Detail">
        <span class="label">Launch Date: </span>
        <span class="Value" style="{`width:${$store.widths.launchDate}px`}">{rfp.launchDate}</span>
      </div>

      <div class="Detail">
        <span class="label">Due Date: </span>
        <span class="Value" style="{`width:${$store.widths.dueDate}px`}">{rfp.dueDate}</span>
      </div>

      <div class="Detail">
        <span class="label">First bid sent: </span>
        <span class="Value" style="{`width:${$store.widths.firstBidSentDate}px`}">{rfp.firstBidSentDate}</span>
      </div>

      <div class="Detail">
        <span class="label">Bids sent: </span>
        <span class="Value" style="{`width:${$store.widths.bidsSent}px`}">{rfp.bidsSent}</span>
      </div>

      <div class="Detail">
        <span class="label">COMP Sent: </span>
        <span class="Value" style="{`width:${$store.widths.competitiveBidsSent}px`}">{rfp.competitiveBidsSent}</span>
      </div>

      <div class="Detail">
        <span class="label">COMP Responded: </span>
        <span class="Value" style="{`width:${$store.widths.competitiveBidsResponded}px`}">{rfp.competitiveBidsResponded}</span>
      </div>
    </div>
  </div>
</div>

<style lang="stylus">.Row {
  height: 83px;
  border-bottom: 1px solid rgba(55,71,79,0.5);
  display: flex;
  align-items: center;
}
.Row:hover {
  background: #2c383d;
}
.Row.Selected {
  background: #2c383d;
}
.RfpTitle {
  font-size: 14px;
  line-height: 28px;
  color: #cfd8dc;
}
.RfpDetails {
  font-size: 13px;
  line-height: 16px;
  color: #72828a;
  display: flex;
  flex-wrap: wrap;
}
.Detail:before {
  content: '|';
  padding: 0 5px;
}
.Detail:first-of-type:before {
  content: '';
  padding: 0;
}
.Value {
  display: inline-block;
}
.rbButton.iconTransparent {
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0 15px;
  color: #57666f;
}
.rbButton.iconTransparent:before {
  width: 24px;
}
.rbButton.iconTransparent.Selected {
  color: #00a99d;
}
.RowSelector {
  width: 54px;
}
.SelectorSpinner {
  transform: scale(0.35);
}
</style>
