/**
 * Created by DejanK on 7/3/2017.
 */
import { isNumber, isDate } from 'lodash'
import moment from 'moment'

export { DecimalFormatter, CurrencyFormatter, PercentageFormatter, DateTimeFormatter, DateFormatter }

function CurrencyFormatter(currency, nanValue, locale) {
  const localeUsed = locale || 'en-US',
    nanValueUsed = nanValue || '---',
    currencyFormat = new Intl.NumberFormat(localeUsed, {
      style: 'currency',
      currency: currency || 'USD'
    });

  return {
    format (amount) { return isNaN(parseFloat(amount)) ? nanValueUsed : currencyFormat.format(amount) }
  }
}

function DecimalFormatter(nanValue = '---', locale = 'en-US', decimals = 2){
  const formatter = new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: decimals
  });

  return {
    format (amount) { return isNaN(parseFloat(amount)) ? nanValue : formatter.format(amount) }
  }
}

function PercentageFormatter(minimumFractionDigits, maximumFractionDigits, nanValue, locale){
  const localeUsed = locale || 'en-US',
    nanValueUsed = nanValue || '---',
    percentFormat = new Intl.NumberFormat(localeUsed,
      {
        style: 'percent',
        minimumFractionDigits: minimumFractionDigits || 2,
        maximumFractionDigits: maximumFractionDigits || 2
      });

  return {
    format (amount) { return isNumber(amount) ? percentFormat.format(amount) : nanValueUsed }
  };
}

function DateTimeFormatter(nanValue, locale){
  const localeUsed = locale || 'en-US',
    nanValueUsed = nanValue || '---',
    dateFormat = new Intl.DateTimeFormat(localeUsed,
      { year: 'numeric', month: 'numeric', day: 'numeric',
        hour: '2-digit', minute: '2-digit'
      });
  return {
    format (dateTime) {
      const dt = isDate(dateTime) ? dateTime : isNumber(dateTime) ? new Date(dateTime) : undefined;
      return dt ? dateFormat.format(dt) : nanValueUsed;
    }
  };
}

function DateFormatter(nanValue, locale, config){
  const localeUsed = locale || 'en-US',
    nanValueUsed = nanValue || '---',
    dateFormat = new Intl.DateTimeFormat(localeUsed,
      config || { year: 'numeric', month: 'numeric', day: 'numeric' });
  return {
    format: (date = false)=>{
      const m = date ? moment(date) : date;
      return m && m.isValid() ? dateFormat.format(m) : nanValueUsed;
    }
  };
}
