import states from 'components/assets/data/states';
import countries from 'components/assets/data/countries';
import getFilters from './filter-types';
import {handle} from 'root/pages/hotels/profile/services/hotel.service';

import httpErrorHandler from 'root/api/HttpErrorHandler';
import {search, loadAccount, fetchRfpActivity, updateSubscriptionNotes} from './api/end-points';
import {extractValue} from 'root/pages/hotels/advanced-search/search.service';
import HotelProfile from 'root/routes/HotelProfile';

import EntityProfileRoute from 'root/routes/EntityProfile';
import { CUSTOMER_DATA } from '../entities/profile/modules/account/account.module';

export function loadFilterTypes() {
    return getFilters(
        states.map(({country, id, label}) => ({value: id, label: `${country} - ${label}`})),
        countries.map(({id: value, name: label}) => ({value, label})),
    );
}

export function searchCustomers(filters, and, page, perPage = 100) {
  const request = {
      filters: filters.reduce((acc, {id, value}) => hasValue(value)? ({...acc, [id]: extractValue(value)}): acc, {}),
      and,
      page,
      perPage,
  };

  function hasValue(value) {
      return Array.isArray(value)? value.length > 0: !!value;
  }

  return search(request).catch(httpErrorHandler.handle);
}

export function openProfile({entityId, type}, target='_blank') {
  if(type === 'HOTEL') {
    return Promise.resolve(window.open(HotelProfile.href({hotelId: entityId, section: CUSTOMER_DATA}), target));
  } else {
    return Promise.resolve(window.open(EntityProfileRoute.href({entityId, section: CUSTOMER_DATA, type}), target));
  }
}

export function getAccount(id) {
  return handle(loadAccount(id));
}

export function getRfpActivity(id, type) {
  return handle(fetchRfpActivity(id, type));
}

export function saveSubscriptionNotes(accountId, notes) {
  return handle(updateSubscriptionNotes(accountId, notes));
}
