<script>
	import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();
    export let selected = false;
    export let indeterminate = false;
    
    function toggle() {
        dispatch('toggle', selected);
    }
</script>

<div class="check-box" on:click={toggle}>
    {#if indeterminate}
        <i class="material-icons">indeterminate_check_box</i>
    {:else if selected}
        <i class="material-icons">check_box</i>
    {:else}
        <i class="material-icons">check_box_outline_blank</i>
    {/if}
</div>

<style type="text/stylus">.check-box {
  cursor: pointer;
  width: 50px;
  text-align: center;
  display: inline-block;
}
</style>