import {
  Input,
  InputText,
  InputPassword,
  InputUrl,
  InputEmail,
} from './inputs'

export {
  Input,
  InputText,
  InputPassword,
  InputEmail,
  InputUrl,
}
