<script>
  import GeneralFaultTemplatePage from './GeneralFaultTemplatePage.svelte';
  export let goToHome = () => { console.log('clicked on homepage') };
  let data = {
    title:"Not Found",
    subtitle: "404 ERROR",
  };
</script>

<GeneralFaultTemplatePage { ...data} on:click="{goToHome}">
  The page you are trying to access is broken, no longer valid or doesn’t exist anymore.
</GeneralFaultTemplatePage>
