<script>
    import Header from '../../components/Header.component.svelte';
    import Amenities from './amenities.formatter';
    import EditAmenities from './EditAmenities.component.svelte';
    import {editImage} from '../../../advanced-search/search.service';
    import {AMENITIES} from './amenities.module';

    export let hotel;

    let editing = false;
    
    $: amenities = hotel.amenities || [];
    $: basic = Amenities.basic.map(amenity => ({...amenity, included: amenities.includes(amenity.id)}));
    $: others = Amenities.others.map(amenity => ({...amenity, included: amenities.includes(amenity.id)}));

    

    function updated({detail}) {
        hotel = detail;
        editing = false;
    }
</script>

{#if !editing}
    <Header {hotel}>
        <button class="rbButton asDarkMain" data-icon="edit" on:click={() => editing = true} >Edit Amenities</button>
        <slot />
    </Header>
    <div class="main" id={AMENITIES}>
        <div class="section" id="basic">
            <h2 class="title">Basic Amenities</h2>
            <div class="content">
                {#each basic as amenity}
                    <div class="data">
                        <div class="icon">
                            {#if amenity.included}
                                <i class="material-icons valid">check</i>
                            {:else}
                                <i class="material-icons">close</i>
                            {/if}
                        </div>
                        <div class="value"  class:excluded={!amenity.included}>
                            {amenity.label}
                        </div>
                    </div>
                {/each}
            </div>
        </div>
        <div class="section" id="others">
            <h2 class="title">Other Amenities</h2>
            <div class="content">
                {#each others as amenity}
                    <div class="data">
                        <div class="icon">
                            {#if amenity.included}
                                <i class="material-icons valid">check</i>
                            {:else}
                                <i class="material-icons">close</i>
                            {/if}
                        </div>
                        <div class="value"  class:excluded={!amenity.included}>
                            {amenity.label}
                        </div>
                    </div>
                {/each}
            </div>
        </div>
    </div>
{:else}
    <EditAmenities {hotel} bind:editing on:updated={updated}/>
{/if}

<style type="text/stylus">.icon i.material-icons.valid {
  color: #00a995;
}
.icon i.material-icons {
  font-size: 18px;
  margin-right: 10px;
}
.value.excluded {
  opacity: 0.5;
}
</style>