<script>
  import {default as store, handleDestinationSelection} from './Bids.store';
  import getSelectionIcon from 'src/utils/selection-icons.service';

  export let destination = {};

  let destinationSelection;

  $: destinationSelection = $store.selectionInDestinationsPercentage[destination.id] || 0;

</script>

<div class="Row"
     class:Disabled="{destination.profile.disabled}">
  <div class="RowSelector">
    <button class="rbButton iconTransparent"
            class:Selected="{destinationSelection === 1}"
            data-icon="{getSelectionIcon(destinationSelection)}"
            on:click={() => handleDestinationSelection(destination)}
    ></button>
  </div>

  <div>
    <div class="Title">
      <span class="Name">{destination.name} &middot; </span><span>{destination.address}</span> {#if destination.roomNightsVolume} &middot; <span>RNV: {destination.roomNightsVolume}</span>{/if}
    </div>

    <div class="Details">
      {#if destination.profile.disabled}
        <div class="DetailDisabled" data-icon-before="report_problem">Disabled</div>
      {/if}

      <div class="Detail">
        <span class="label">Max Distance: </span>
        <span class="Value" style="{`width:${$store.widths.maxDistance}px`}">{destination.profile.maxDistance}</span>
      </div>

      <div class="Detail">
        <span class="label">GBTA Hotel Market Tiers: </span>
        <span class="Value" style="{`width:${$store.widths.marketTiers}px`}">{destination.profile.marketTiers}</span>
      </div>

      <div class="Detail">
        <span class="label">Amenities: </span>
        <span class="Value" style="{`width:${$store.widths.amenities}px`}">{destination.profile.amenities}</span>
      </div>

      <div class="Detail">
        <span class="label">Excluded Brands: </span>
        <span class="Value">{destination.profile.brands}</span>
      </div>
    </div>
  </div>
</div>

<style lang="stylus">.Row {
  height: 74px;
  border-bottom: 1px solid rgba(55,71,79,0.5);
  border-top: 5px solid #263238;
  display: flex;
  align-items: center;
  background: #2c383d;
}
.Disabled .Title,
.Disabled .Details,
.Disabled .Value {
  color: #72828a !important;
}
.Title {
  font-size: 14px;
  line-height: 28px;
  color: #fff;
}
.Name {
  text-transform: uppercase;
  font-weight: bold;
}
.Details {
  font-size: 13px;
  line-height: 20px;
  color: #72828a;
  display: flex;
  flex-wrap: wrap;
}
.Detail:before {
  content: '·';
  padding: 0 5px;
}
.Detail:first-of-type:before {
  content: '';
  padding: 0;
}
.DetailDisabled:before {
  font-size: 16px;
  margin-right: 5px;
}
.Value {
  display: inline-block;
  color: #fff;
}
.rbButton.iconTransparent {
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0 15px;
  color: #57666f;
}
.rbButton.iconTransparent:before {
  width: 24px;
}
.rbButton.iconTransparent.Selected {
  color: #00a99d;
}
.RowSelector {
  width: 54px;
}
</style>
