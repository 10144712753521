import modals from 'root/services/modals.js';
import Spinner from 'components/modals/Spinner.svelte';
import httpErrorHandler from './HttpErrorHandler.js';

export default {
    handle( promise, { label, transparent, onError } = {} ){
        modals.show(Spinner, { label, transparent });
        return promise
            .then(
                r => modals.hide().then( () => r ),
                e => modals.hide().then( () => httpErrorHandler.handle(e, onError))
            )
    }
}
