<script>
  import { getContext, tick, createEventDispatcher } from 'svelte';
  import { default as brandsStore, initialize as initializeBrands } from 'src/stores/brands.store';
  import marketTierOptions from 'root/assets/data/hotel-market-tiers';

  import InputText from 'components/molecules/inputs/InputText.svelte';
  import InputPhone from 'components/molecules/inputs/InputText.svelte';
  import InputUrl from 'components/molecules/inputs/InputUrl.svelte';
  import InputEmail from 'components/molecules/inputs/InputEmail.svelte';
  import Select from 'components/molecules/inputs/SelectModel.svelte';
  import AddressSearch from 'components/molecules/AddressSearch/AddressSearch.svelte';
  import AddressInput from 'components/organisms/AddressInput/AddressInput.svelte';
  import ImagePicker from 'components/molecules/ImagePicker/ImagePicker.svelte';
  import BrandsSearch
    from 'src/pages/hotels/profile/modules/basic-info/dialogs/rebrand/BrandsSearch.formModel.svelte';

  export let addHotelView,
    image,
    hideAddressForm = true;

  const dispatch = createEventDispatcher();

  let
    formModel = getContext('formModel'),
    address;

  $: handleAddressChange(address);

  initializeBrands()

  async function handleAddressChange(address){
    if(address && Object.keys(address).length) {
      hideAddressForm = false;
      formModel.setValue('location.address.country', address.address.country);
      await tick();
      formModel.setValue('location.address.state', address.address.state);
      formModel.setValue('location.address.region', address.address.region);
      formModel.setValue('location.address.county', address.address.county);
      formModel.setValue('location.address.city', address.address.city);
      formModel.setValue('location.address.address1', address.address.address1);
      formModel.setValue('location.address.address2', address.address.address2);
      formModel.setValue('location.address.zip', address.address.zip);

      formModel.setValue('location.coordinates.lat', address.coordinates.lat);
      formModel.setValue('location.coordinates.lng', address.coordinates.lng);
    }
  }

  async function saveProperty(){
    hideAddressForm = false;
    await tick();
    formModel.touchAll();

    if($formModel.valid) {
      dispatch('submit', $formModel.values);
    }
  }
</script>


<div class="component">
  <div class="heading">
    <div>
      <ImagePicker bind:value="{image}"></ImagePicker>
    </div>
    <div class="rbInlineNotice">
      <div>
        <div class="title">Add New Property</div>
        <div>Before adding a new hotel, please make sure you tried all the possible options to find it in our database.</div>
      </div>
    </div>
  </div>

  <div class="content">
    <InputText id="name" required="true" maxLength="100">Property Name</InputText>

    <AddressSearch bind:value="{address}"></AddressSearch>

    {#if !hideAddressForm}
      <AddressInput></AddressInput>
    {/if}

    <div class="twoColumns">
      <InputUrl id="website" required="true" maxLength="256">Website</InputUrl>

      <InputPhone id="phone" required="true" maxLength="30">Phone</InputPhone>
    </div>

    <div class="twoColumns">
      <InputEmail id="emailAddress" maxLength="255">Email Address</InputEmail>

      <Select id="marketTier" options="{marketTierOptions}">Property Market Tier</Select>
    </div>

    <BrandsSearch id="brandId" name="Brand" brands="{$brandsStore.brandsOptions}" required="{true}"></BrandsSearch>

    <div class="twoColumns">
      <InputText id="sabreChainCode" maxLength="2">Sabre Chain Code</InputText>
      <InputText id="sabrePropertyCode"
                 on="{ [ ['input', ev => ev.target.value =  (ev.target.value || '').replaceAll(/\D/g, '').replaceAll(/^0/g, '') ] ] }"
                 maxLength="10"
                 pattern="{/^[1-9]\d{0,9}$/}">Sabre Property Code</InputText>
    </div>

    <div class="twoColumns">
      <InputText id="apolloChainCode" maxLength="2">Apollo Chain Code</InputText>
      <InputText id="apolloPropertyCode" maxLength="10">Apollo Property Code</InputText>
    </div>

    <div class="twoColumns">
      <InputText id="amadeusChainCode" maxLength="2">Amadeus Chain Code</InputText>
      <InputText id="amadeusPropertyCode" maxLength="10">Amadeus Property Code</InputText>
    </div>

    <div class="twoColumns">
      <InputText id="worldSpanChainCode" maxLength="2">WorldSpan Chain Code</InputText>
      <InputText id="worldSpanPropertyCode" maxLength="10">WorldSpan Property Code</InputText>
    </div>

    <div class="footer">
      <div class="rbInlineNotice" data-icon="error">
        Before adding a new hotel, please make sure your spelling is correct in all the input fields to avoid future problems and duplicates.
      </div>

      <button class="rbButton asDark" on:mousedown="{() => addHotelView = false}">Back to Search</button>

      <button class="rbButton asDarkMain" on:mousedown="{saveProperty}">Add Property</button>
    </div>
  </div>
</div>

<style type="text/stylus">.component {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  overflow: hidden;
}
.heading {
  display: flex;
  align-items: center;
  padding: 0 15px;
}
.rbInlineNotice {
  flex: 0 0 auto;
  padding: 24px 15px;
}
.title {
  font-size: 20px;
  color: #fff;
}
.content {
  margin-top: 18px;
  padding: 0 15px;
  overflow: auto;
  flex: 1 1 auto;
}
.content :global(.rbInput) {
  margin-bottom: 15px;
}
.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
.footer {
  display: flex;
  align-items: center;
}
.rbInlineNotice {
  flex: 1 1 auto;
  margin-right: 20px;
}
</style>
