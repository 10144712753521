<script>
  import noop from "lodash/noop";

  import profile from "root/api/entityProfile.js";
  import httpNotifications from "root/api/HttpNotifications.js";
  import modals from "root/services/modals.js";
  import Notification from "components/modals/Notification.basic.svelte";
  import { openProfile } from "../search/actions.service";

  import { convertModelToObect } from "root/utils";

  import CreateForm from "./CreateForm.component.svelte";

  export let name, type;

  let logo;

  function onSubmit({ detail }) {
    const request = convertModelToObect(detail);
    httpNotifications
      .handle(profile.create(logo, { ...request, type }), {
        onError: {
          "400": e =>
            modals.show(Notification, {
              type: "warning",
              title: "Not Saved!",
              subtitle: "Property",
              content: e.data.message,
              rejectLabel: "OK"
            })
        }
      })
      .then(({ data }) => openProfile(data, "_self"))
      .catch(noop);
  }
</script>

<CreateForm on:submit={onSubmit} bind:logo {name} />
