import { editImage, changeHotelGroup } from './search.service';
import HotelProfile from 'root/routes/HotelProfile';
import HotelModificationRequestVerificationDialog from './dialogs/hotel-modification-request-dialog/HotelModificationRequestVerificationDialog.svelte'
import modals from 'src/services/modals';
import {resultsTableDataService} from 'src/pages/hotels/advanced-search/results-table-data.store';

const actions = [
    {
        id: 'editImage',
        label: 'Edit Image',
        icon: 'image',
        isAvailable(hotels) {
            return hotels.length === 1;
        },
        action([hotel]) {
            return editImage(hotel);
        },
        primary: true
    },
    {
        id: 'ViewProfile',
        label: 'View Profile',
        icon: 'open_in_new',
        isAvailable(hotels) {
            return hotels.length === 1;
        },
        action([hotel], _, collection) {
            return viewProfile(hotel, undefined, collection);
        },
        primary: true
    },
    {
        id: 'ChangeGroup',
        label: 'Change Hotel Group',
        icon: 'refresh',
        isAvailable() {
            return true;
        },
        action(hotels, hotelManagementCompanies) {
            return changeHotelGroup(hotels, hotelManagementCompanies);
        },
        primary: true
  },
];

export function viewProfile({_id, id, requestType}, target = '_blank', collection) {
  if(collection === 'UnvalidatedHotel')
    return modals
      .show(HotelModificationRequestVerificationDialog, {requestId: _id || id})
      .then(() => resultsTableDataService.removeDataItemById(_id || id));
  else
    return Promise.resolve(window.open(HotelProfile.href({hotelId: _id || id, section: 'basic-info'}), target));
}

export function loadActions(selectedHotels, collection) {
    return collection === 'UnvalidatedHotel'? []: actions.filter(({isAvailable})=>isAvailable(selectedHotels));
}
