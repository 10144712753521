import {
  FREE_TEXT_FILTER_TYPE,
  SINGLE_SELECT_FILTER_TYPE
} from 'root/pages/hotels/advanced-search/filters/filter-types';

export default function getFilter() {
  return Object.freeze([
    {
      label: `First Name`,
      type: FREE_TEXT_FILTER_TYPE,
      id: 'firstName',
      value: ''
    },
    {
      label: `Last Name`,
      type: FREE_TEXT_FILTER_TYPE,
      id: 'lastName',
      value: ''
    },
    {
      label: 'Email Address',
      type: FREE_TEXT_FILTER_TYPE,
      id: 'emailAddress',
      value: ''
    },
    {
      label: `User Type`,
      type: SINGLE_SELECT_FILTER_TYPE,
      id: 'userType',
      value: {
        label: 'All',
        value: 'all'
      },
      options: [
        {
          label: 'All',
          value: 'all'
        },
        {
          label: 'Root Users',
          value: 'root'
        },
        {
          label: 'Normal Users',
          value: 'normal'
        },
      ]
    },
    {
      label: `User Status`,
      type: SINGLE_SELECT_FILTER_TYPE,
      id: 'status',
      value: {
        label: 'Active',
        value: 'ACTIVE'
      },
      options: [
        {
          label: 'Active',
          value: 'ACTIVE'
        },
        {
          label: 'Disabled',
          value: 'DISABLED'
        },
      ]
    }
  ]);
}
