import { writable } from 'svelte/store';

const
  defaultState = {
    library: [],
    active: [],
    inactive: [],
    hasSetFilters: false,
  },
  store = writable(defaultState),
  TYPE_TEXT = Symbol('TYPE_TEXT'),
  TYPE_MULTISELECT = Symbol('TYPE_MULTISELECT');

export default store;
export { TYPE_TEXT, TYPE_MULTISELECT };

/**
 * Store contains three arrays:
 * - library: array of filters as received on initialization. These filters will retain initial values and order through store life time;
 * - active: array of active filters. Only active filters can have their values changed;
 * - inactive: array of inactive filters. Inactive filters are copies of filters received on initialization and therefore contain initial values;
 *
 * Filters should have:
 * id - identifier - used as key and as DOM element id
 * label - name - shown in filter input and in filters selection
 *
 * value - string or array, depending on the type
 *
 * @param {Object[]} library - Array of Filter Objects containing: { id: filter id , label: filter label, type: filter type, fn: filter function, value: filter value }
 * @param {number[]} enabled - Array of indexes that are enabled by default
 */
export function init(library = [], enabled = []){
  const l = library.map(i => ({...i})),
    activeInactive = l.reduce( (acc, filter, index) => {
      const arr = enabled.indexOf(index) === -1 ? acc.inactive : acc.active;
      arr.push({...filter});
      return acc;
    }, { active: [], inactive: [] });

  store.set({
    library: l,
    ...activeInactive,
    hasSetFilters: false
  });
}


/**
 * Removes filter from inactive filters and adds it at the end of the active filters
 * @param {Object} filterToEnable - filter to enable
 */
export function enableFilter(filterToEnable = null) {
  if (filterToEnable === null) return;

  store.update(values => {
    const inactiveFilter = values.inactive.find( f => f.id === filterToEnable.id);
    if( !inactiveFilter ) return values;

    return {
      ...values,
      inactive: values.inactive.filter(f => f.id !== filterToEnable.id),
      active: [...values.active, filterToEnable],
    };
  });
}


/**
 * Disables filter, adds it to inactive array on it original position and reverts filter value to default value
 * @param {Object} filterToDisable - filter to disable
 */
export function disableFilter(filterToDisable = null){
  if (filterToDisable === null) return;

  store.update(values => {
    const activeFilter = values.active.find(f => f.id === filterToDisable.id);
    if (!activeFilter) return values;

    const activeInactive = values.library.reduce( (acc, f) => {

      const activatedFilter = values.active.find(f1 => f1.id !== filterToDisable.id && f1.id === f.id);
      if(activatedFilter) {
        acc.active.push(activatedFilter);
      } else  {
        acc.inactive.push({...f});
      }

      return acc;
    }, { active: [], inactive: [] });
    return {
      ...values,
      ...activeInactive,
      hasSetFilters: hasSetFilters(activeInactive.active),
    }
  });
}

/**
 *
 * @param {Object} filterToUpdate - active filter to set value to
 * @param {any} value - value to set
 */
export function setFilterValue(filterToUpdate = null, value) {
  if(filterToUpdate === null) return;

  store.update(values => {
    const activeFilter = values.active.find(f => f.id === filterToUpdate.id);
    if (!activeFilter) return values;

    const active = values.active.map(f => f.id === filterToUpdate.id ? filterToUpdate.setValue(value) : f);
    return {
      ...values,
      active: active,
      hasSetFilters: hasSetFilters(active) ,
    }
  });
}



export function clearAllFilterValues() {
  store.update(values => ({
      ...values,
      active: values.active.map(activeFilter => ({...values.library.find(lf => lf.id === activeFilter.id)})),
      hasSetFilters: false,
    })
  );
}

function hasSetFilters(activeFilters) {
  return !!activeFilters.find( f => !f.isEmpty )
}