import {
    FREE_TEXT_FILTER_TYPE,
    SINGLE_SELECT_FILTER_TYPE,
    MULTI_SELECT_FILTER_TYPE,
} from 'root/pages/hotels/advanced-search/filters/filter-types';

import industries from '../../../assets/data/industries';

export default function getFilter(states, countries) {

    return Object.freeze([
        {
            label: `Name`,
            type: FREE_TEXT_FILTER_TYPE,
            id: 'name',
            value: '',
        },
        {
            label: `Industry`,
            type: MULTI_SELECT_FILTER_TYPE,
            id: 'industry',
            value: [],
            options: industries.map(({id, label}) => ({value: id, label})),
        },
        {
            label: 'Address',
            type: FREE_TEXT_FILTER_TYPE,
            id: 'address',
            value: '',
        },
        {
            label: 'Zip',
            type: FREE_TEXT_FILTER_TYPE,
            id: 'zipCode',
            value: '',
        },
        {
            label: 'City',
            type: FREE_TEXT_FILTER_TYPE,
            id: 'city',
            value: '',
        },
        {
            label: 'State',
            type: MULTI_SELECT_FILTER_TYPE,
            id: 'state',
            value: [],
            options: states,
        },
        {
            label: 'Region',
            type: FREE_TEXT_FILTER_TYPE,
            id: 'region',
            value: '',
        },
        {
            label: 'Country',
            type: MULTI_SELECT_FILTER_TYPE,
            id: 'country',
            value: [],
            options: countries,
        },
        {
            label: 'Status',
            type: SINGLE_SELECT_FILTER_TYPE,
            id: 'status',
            value: '',
            options: [
                {
                    value: null,
                    label: 'All',
                },
                {
                    value: 'unvalidated',
                    label: 'Unvalidated',
                },
                {
                    value: 'validated',
                    label: 'Validated',
                }
            ],
        },
    ]);
}