<script>
    import Coordinates from 'components/molecules/coordinates/Coordinates.svelte';
    import { getContext } from 'svelte';
    import Required from 'components/validation/validators/required.js';

    let
            value = {lat: 40.1451, lng: -99.668},
            zoom = 3,
            formModel = getContext('formModel');

    formModel.addModel({ id: 'location.coordinates.lat', value: 40.1451, validations: [ Required.create(true) ] });
    formModel.addModel({ id: 'location.coordinates.lng', value: -99.6680, validations: [ Required.create(true) ] });

    $: handleCoordinatesModelChange($formModel.values['location.coordinates.lat'], $formModel.values['location.coordinates.lng']);

    function handleCoordinatesModelChange(lat = 40.1451, lng = -99.6680){
        if(!(value.lat === lat && value.lng === lng)){
            zoom = 17;
            value = {lat, lng};
        }
    }

    function handleCoordinatesValueChange({detail}){
        if(detail) {
            value = {...detail};
            formModel.setValue('location.coordinates.lat', detail.lat);
            formModel.setValue('location.coordinates.lng', detail.lng);
        }
    }
</script>

<Coordinates {value} {zoom} on:input="{handleCoordinatesValueChange}"></Coordinates>
