export default [
    {
        value: 'L',
        label: 'Luxury'
    },
    {
        value: 'UU',
        label: 'Upper Upscale'
    },
    {
        value: 'U',
        label: 'Upscale'
    },
    {
        value: 'UM',
        label: 'Upper Midscale'
    },
    {
        value: 'M',
        label: 'Midscale'
    },
    {
        value: 'E',
        label: 'Economy'
    },
];