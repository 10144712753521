<script>
    import {createEventDispatcher} from 'svelte';
    import Checkbox from 'root/pages/hotels/advanced-search/Checkbox.component.svelte';
    import {get} from 'lodash';

    export let user, props, selectedList;

    const dispatch = createEventDispatcher();

    $: image = user.profilePicture && `/images/users/${user.profilePicture}`;

    function select() {
        dispatch('select', selected);
    }


    $: selected = !!selectedList.find(e => e.id === user.id);

</script>

<div class="shell" on:click={select}>
    <Checkbox {selected} />
    <div class="user">
        {#if image} 
            <img src={image} alt={`${user.firstName} ${user.lastName}`} class="logo">
        {:else}
            <div class="logo empty rbDark"><i class="material-icons">person</i></div>
        {/if}
        <div class="details">
            <div class="identifier">
                <b>{user.fullName}</b>
            </div>
            <div class="props">
                <div class="prop status">
                    {#if !user.invitation}
                        <div class="label">
                            {#if user.active}
                                <i class="material-icons active">check_circle</i> 
                            {:else}                            
                                <i class="material-icons">done</i> 
                            {/if}
                        </div>
                        <div class="value">Verified</div>
                    {:else}
                        <div class="value">Pending</div>
                    {/if}
                </div>
                {#each props as prop}
                    <div class="prop" style={`width: ${ (prop.size * 5) + 70 }px;`}>
                        <span class="label">{prop.label}:</span>
                        <span class="value">{ prop.get && prop.get(user) || get(user, prop.key, '---')}</span>
                    </div>
                {/each}
            </div>
        </div>
    </div>
</div>

<style type="text/stylus">.shell {
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 0;
  border-bottom: 1px solid rgba(176,190,197,0.239);
}
.user {
  flex: 1;
  display: flex;
  align-items: center;
}
.details {
  flex: 1;
  font-size: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.identifier {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.props {
  display: flex;
  flex-wrap: wrap;
}
.prop {
  opacity: 0.6;
  display: flex;
  align-items: center;
  max-width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 100px;
}
.prop:not(:last-child) {
  border-right: 1px solid;
}
.prop:not(:last-child) {
  margin-right: 5px;
}
.prop span {
  margin-right: 5px;
}
.logo {
  height: 64px;
  width: 64px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  margin-right: 5px;
  overflow: hidden;
  position: relative;
}
.logo.empty {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo.empty .material-icons {
  font-size: 40px;
  opacity: 0.4;
}
img[alt]:after {
  padding: 5px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-family: 'Helvetica';
  font-weight: 300;
  line-height: 2;
  text-align: center;
  content: attr(alt);
  font-size: 10px;
  box-sizing: border-box;
}
.prop.status {
  opacity: 1;
}
.status .material-icons {
  font-size: 18px;
  font-weight: bold;
  margin-right: 5px;
}
.status .material-icons.active {
  color: #00a995;
}
</style>