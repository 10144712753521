<script>
  import AddComment from './AddComment.component.svelte';
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let comment = {};
  export let currentUser = {};

  let editing = false;

  function date(value) {
    return value.substring(0, 10) + " " + value.substring(11, 19);
  }

  function save({detail}) {
    edit();
    comment.comment = detail;
    dispatch('update', comment)
  }

  function edit() {
    editing = !editing
  }

  function drop() {
    if(confirm('Delete this comment?')) {
      dispatch('delete', comment)
    }
  }
</script>

{#if !editing}
  <article class="comment">
    <section class="comment-meta">
      <p class="user">{comment.created.by.fullName}</p>
      <small>{date(comment.created.at)}</small>
    </section>
    <section class="comment-data">
      <p>{comment.comment}</p>
      {#if comment.created.by.id === currentUser.id}
        <span class="link" on:click={edit}>Edit</span>
        <span class="link" on:click={drop}>Delete</span>
      {/if}
    </section>
  </article>
{:else}
  <AddComment value={comment.comment} on:add={save} editing={editing}/>
{/if}

<style lang="stylus">.comment section.comment-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comment {
  margin-bottom: 12px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
}
.comment section.comment-meta p {
  font-size: 10px;
  margin: 0;
  color: #00a99d;
  font-weight: bold;
}
.comment p {
  font-size: 14px;
  margin: 0;
}
.comment-data {
  display: flex;
  padding: 10px 0;
}
.comment-data p {
  flex: 1;
}
.link {
  min-width: 50px;
  cursor: pointer;
  font-size: 13px;
  color: #00a99d;
  text-align: right;
}
.link:hover {
  text-decoration: underline;
}
</style>
