<script>
  import {tick} from 'svelte';

  import DialogTemplate from 'components/modals/DialogTemplate.basic.svelte';
  import FormModel from 'components/molecules/inputs/FormModel.svelte';
  import EditHotelForm from './EditHotelForm.component.svelte';
  import Spinner from 'components/atoms/Spinner.svelte';
  import Heading from './Heading.svelte'
  import RejectRequestDialog from './RejectRequestDialog.svelte';
  import ErrorsModal from 'src/components/ErrorsModal.svelte';
  import Notifications from './Notifications.svelte';


  import modals from 'src/services/modals';
  import api from '../../../profile/services/hotel-requests.api';
  import httpNotifications from 'root/api/HttpNotifications.js';
  import errorHandler from 'root/api/HttpErrorHandler.js';
  import {createModel, createFormData} from './from-model-data-converter';
  import {default as brandsStore, initialize as initializeBrands} from 'root/stores/brands.store';
  import {default as hmcsStore, initialize as initializeHmcs} from 'root/stores/hmcs.store';

  export let cancel, hide, requestId;

  let
    pageState = '',
    formModel = {},
    request = {},
    toNotifyList = [],
    isFormValid = true,
    image,
    touchAll = () => {};

  $: loadRequest(requestId);

  async function loadRequest(id = ''){
    pageState = '';
    if(id){
      try {
        const [ brandsStore, hmcsStore, hotel ] = await Promise.all([
          initializeHmcs(),
          initializeBrands(),
          api.getHotelRequest(id)
        ]);

        request = hotel;
        toNotifyList = hotel.toNotifyList || [];
        formModel = createModel(request);
        pageState = 'Loaded';
        tick().then(() => touchAll());
      } catch (e) {
        console.error(e);
        errorHandler.handle(e);
      }
    }
  }

  async function validate(){
    touchAll();
    if(isFormValid){
      const formData = createFormData(request, formModel);

      await httpNotifications.handle(api.validateHotelRequest(requestId, formData, image, {toNotify: toNotifyList}), { onError: {
          '409': async function (e) {
            const message = e.data && e.data.message || '';
            await modals.show(ErrorsModal, {
              title: 'Failure to validate Request!',
              message: message === 'SABRE_CODE_IN_USE' ? `Sabre code is already in use.` : `Unexpected error happened.`,
            });

            throw e;
          }
        }});
      hide();
    }
  }

  async function markAsClosed(){
    touchAll();
    await httpNotifications.handle(api.validateHotelRemoveRequest(requestId));
    hide();
  }

  async function reject(){
    const message = await modals.show(RejectRequestDialog);
    await httpNotifications.handle(api.rejectHotelRequest(requestId, message));
    hide();
  }

</script>

<DialogTemplate {cancel} closeOnClick="{false}" width="1024">
  <div class="Container">
    {#if !pageState}
      <div class="SpinnerContainer">
        <Spinner />
      </div>
    {:else}
      <Heading {request} />

      <div class="FormContainer">
        <FormModel bind:values={formModel} bind:valid={isFormValid} bind:touchAll={touchAll} >
          <EditHotelForm bind:image
                         imageUrl="{request.imageUrl}"
                         hmcsOptions="{$hmcsStore.hmcsOptions}"
                         chainsOptions="{$brandsStore.brandsOptions}"
          />
        </FormModel>
      </div>

      {#if request.requestType === 'ADD'}
        <Notifications bind:list={toNotifyList} />
      {/if}

      <div class="ActionsContainer">
        <button class="rbButton asDark" on:click={cancel}>Cancel</button>
        <button class="rbButton asDark" on:click={reject}>Reject Request</button>

        {#if request.requestType === 'REMOVE'}
          <button class="rbButton asDark" on:click={markAsClosed}>Mark as Closed</button>
          <button class="rbButton asDark" disabled="{!isFormValid}" on:click={validate}>Rebrand Hotel</button>
        {:else}
          <button class="rbButton asDarkMain" disabled="{!isFormValid}" on:click={validate}>Validate Request</button>
        {/if}
      </div>
    {/if}
  </div>
</DialogTemplate>


<style lang="stylus">:global(.dialog) {
  border: 1px solid #000 !important;
  background: #313c42 !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.Container {
  flex: 1 1 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.SpinnerContainer {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.FormContainer {
  overflow: auto;
  border: 1px solid #808080;
  border-left: 0;
  border-right: 0;
}
.ActionsContainer {
  display: flex;
  padding: 10px 20px;
  justify-content: flex-end;
}
</style>
