<script>
    import {getContext} from 'svelte';

    import Header from '../../../../components/Header.component.svelte';
    import FormModule from '../QuestionnaireModule.component.svelte';
    import ViewQuestion from './QuestionnaireViewQuestion.component.svelte';
    
    import {GBTA_FORM} from '../../form.module';

    export let hotel, template, response, editing;

    let formModel = getContext('formModel');
</script>

<Header {hotel}>
    <slot name="search"></slot>
    <slot></slot>
</Header>

<div class="main questionnaire" id={GBTA_FORM}>
    {#each template.cells as module, index}
        <FormModule {module} {index} modulesCount={template.cells.length} {editing}>
            <div slot="question" let:question>
                <ViewQuestion {question} {response}/>
            </div>
        </FormModule>
    {/each}
</div>

<style type="text/stylus">.questionnaire {
  width: 960px;
  background-color: #fff;
  margin: 0 auto;
}
</style>