
<script>
    import FormModel from 'components/molecules/inputs/FormModel.svelte';
    import NotificationTemplate from 'components/modals/NotificationTemplate.basic.svelte';

    import EditForm from './ChooseReplacementContact.form.svelte';
    import {getModelFromObect} from 'root/utils';

    export let hide, cancel, reps, selected, label = 'Delete',
      title = 'Active Bid In Progress',
      message = selected.length === 1?
        'This representative has been set as a contact on one or more RFPs and needs to be replaced with an existing representative':
        'One or more of the selected representatives has been set as a contact on one or more RFPs and needs to be replaced with an existing representative';
    let values = getModelFromObect({})

    function save(id) {
        hide(id ? reps.find(r => r.id === id) : undefined)
    }
</script>

<NotificationTemplate>
    <FormModel bind:values>
        <div class="container">
            <div class="header">
                <div class="title">
                    {title}
                </div>
                <div class="subTitle">
                    {message}
                </div>
            </div>

            <EditForm {save} {cancel} {reps} {label}/>
        </div>
    </FormModel>
</NotificationTemplate>

<style type="text/stylus">.container {
  padding: 25px 15px 20px;
}
.header {
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 14px;
  font-weight: bold;
}
.subTitle {
  font-size: 10px;
  opacity: 0.5;
}
</style>
