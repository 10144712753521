<script>
  import store from '../users.store';
  import Spinner from 'components/atoms/Spinner.svelte';
  import modalsService from 'src/services/modals';
  import UserDetailsDialog from '../user-details/UserDetailsDialog.svelte';

  function showUserDetails(user){
    modalsService.show(UserDetailsDialog, {user});
  }

</script>

{#if $store.loading}
  <div class="Loading">
    <Spinner />
  </div>
{:else if $store.users && $store.users.length}
  <div class="Table">
    <div class="TableHeader">Status</div>
    <div class="TableHeader">Name</div>
    <div class="TableHeader">Email Address</div>
    <div class="TableHeader"># Accounts</div>
    <div class="TableHeader">Last Active</div>
    <div class="TableHeader">Created</div>

    {#each $store.users as user}
      <div data-icon-before="{user.status.icon}"
           class="Status"
           class:Warning={user.status.warning}
           class:Disabled={user.status.disabled}
      >
        <div class="rbTooltip" data-tooltip="placement:right">{user.status.label}</div>
      </div>
      <div class="Name" on:click={() => showUserDetails(user)}>{user.name}</div>
      <div data-icon-before="{user.emailAddressStatus.icon}"
           class="EmailAddress"
           class:OK={user.emailAddressStatus.ok}
           class:Bad={user.emailAddressStatus.bad}
      >
        {user.emailAddress}
        <div class="rbTooltip" data-tooltip="placement:left">{user.emailAddressStatus.label}</div>
      </div>
      <div>{user.accountsNo}</div>
      <div>{user.lastActive}</div>
      <div>{user.createdAt}</div>
    {/each}
  </div>
{/if}

<style lang="stylus">.Table {
  display: grid;
  grid-template-columns: 60px 1fr 1fr 90px 160px 100px;
  font-size: 14px;
  position: relative;
}
.Table > div {
  height: 42px;
  line-height: 42px;
  border-bottom: 1px solid #eceff1;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
div.TableHeader {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #273238;
  color: #fff;
  border-bottom: 1px solid #00a99d;
  display: flex;
  font-size: 13px;
  font-weight: bold;
  padding: 0 5px;
  line-height: 36px;
  height: 36px;
}
.Status {
  justify-content: center;
  color: #00a99d;
}
.Status.Warning {
  color: #fcee21;
}
.Status.Disabled {
  color: #e65622;
}
.EmailAddress.OK {
  color: #00a99d;
}
.EmailAddress.Bad {
  color: #e65622;
}
.EmailAddress:before {
  margin-right: 3px;
}
.Loading {
  text-align: center;
  padding-top: 100px;
}
.Name {
  text-decoration: underline;
  cursor: pointer;
}
.Name:hover {
  color: #fff;
  text-decoration: none;
}
</style>
