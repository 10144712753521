<script>
  import DialogTemplate from 'components/modals/DialogTemplate.basic.svelte';
  import {chargeableReport} from './emailAddressFixServiceApi';
  import modalsService from 'src/services/modals';
  import ErrorsModal from 'src/components/ErrorsModal.svelte';

  export let cancel;

  let state = '', model = {}, invalid;

  function runReport(m){
    state = 'LOADING';
    chargeableReport(m)
      .then(() => {
        state = 'SUCCESS';
      })
      .catch(e => {
        modalsService
          .hide()
          .then(() =>
            modalsService.show(ErrorsModal, {
              message: `Failed to create report`,
              error: e && e.data && e.data.message || null,
            }))
      })
  }

</script>

<div class="Component">
  <DialogTemplate cancel="{cancel}" closeOnClick="{false}">
    <div class="Container">
      <div class="Title">
        Fix Email Address Service Chargeable Report
      </div>

      <div class="Content">
        {#if state === 'LOADING'}
          <div class="Form">Loading...</div>
        {:else if state === 'SUCCESS'}
          <div class="Form">Report downloaded</div>
        {:else}

          <form on:submit|preventDefault="{() => runReport(model)}">
            <div class="Form">
              <div class="InputContainer">
                <div class="Label">Start Date:</div>
                <input type="date" id="dateStart" required bind:value="{model.start}" />
              </div>

              <div class="InputContainer">
                <div class="Label">End Date:</div>
                <input type="date" id="dateEnd" required bind:value="{model.end}" min="{model.start ? model.start : null}" />
              </div>
            </div>

            <div class="Actions Form">
              <button class="rbButton asDark asDarkMain" disabled="{invalid}">Run Report</button>
            </div>
          </form>
        {/if}
      </div>
    </div>
  </DialogTemplate>
</div>

<style lang="stylus">.Component :global(.dialog) {
  background: #313c42;
  border-color: #313c42;
  overflow: hidden;
}
.Container {
  font-size: 14px;
  color: #cfd8dc;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
}
.Title {
  padding: 5px 10px;
  padding-bottom: 15px;
  font-size: 13px;
  border-bottom: 1px solid #546e7a;
}
.Content {
  overflow: auto;
  padding: 0 10px;
}
.Form {
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
}
.Form :global(input) {
  padding: 3px 5px;
  border: 1px solid #b0bec5;
  border-radius: 15px;
  background: #eceff1;
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #263238;
}
.Form :global(input):focus {
  inset: none;
}
.Form.Actions {
  justify-content: flex-end;
}
.InputContainer {
  margin-bottom: 10px;
  flex: 0 0 auto;
}
</style>
