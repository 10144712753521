import {writable, get} from 'svelte/store';
import errorHandler from 'root/api/HttpErrorHandler.js';
import {listBrands} from 'src/api';

const
  CACHE_TTL = 300000, // 5 min
  initialState = {
    brands: [],
    brandsOptions: [],
    ready: false,
  },
  store = writable(initialState);

let responseCache;

export default store;

export async function initialize(){
  const
    brands = await getBrands(),
    brandsOptions = brands.reduce((acc, brand) => {
      const masterName = brand.master ? ` - ${brand.master.name}` : '';
      const option = {
        value: brand.id,
        label: `${brand.name} [${brand.code}]${masterName}`,
        masterName: masterName.toLowerCase(),
        brandCode: brand.code.toLowerCase(),
        brandName: brand.name.toLowerCase(),
        $parts: [],
      };

      option.$parts.push(option.brandCode);
      option.$parts.push(option.brandName);
      option.$parts.push(option.masterName);
      option.$parts.push(...option.label.toLowerCase().split(' '));

      acc.push(option);
      return acc;
    }, [])
      .sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }));

  store.set({ brands, brandsOptions, ready: true });

  async function getBrands(){
    if(!responseCache || responseCache.ttl < new Date().getTime()){
      try {
        const response = await listBrands();
        responseCache = {response, ttl: new Date().getTime() + CACHE_TTL};
        return response;
      } catch (e) {
        errorHandler.handle(e);
        return [];
      }
    } else {
      return responseCache.response;
    }
  }
}

function getBrandByCode(code = ''){
  const s = get(store);
  return s.brands.find(b => b.code === code);
}

function getBrandById(id = ''){
  const s = get(store);
  return s.brands.find(b => b.id === id);
}


export const brandsService = {
  getBrandByCode,
  getBrandById
};
