import {writable} from 'svelte/store';

const
  initialState = {
    data: [],
    ready: false,
  },
  store = writable(initialState);

export default store;

export async function initialize(promise){
  store.set({data: [], ready: false});
  const data = await promise;
  store.set({data, ready: true});
}

export const resultsTableDataService = {
  removeDataItemById,
}

function removeDataItemById(id = ''){
  store.update($s => ({
    ...$s,
    data: $s.data.filter(i => (i._id || i.id) !== id)
  }));
}
