<script>
    import ActionButtons from '../../components/ActionButtonsHelper.component.svelte';

    import {getActions} from './actions.factory';

    export let chainReps = [];
    export let hotelReps = [];
    export let actionCompleted, hotel, representatives;

    $: reps = [...chainReps, ...hotelReps];
    $: actions = getActions(reps);
    $: params = [hotel, reps, representatives];
</script>

<ActionButtons {actions} {params} {actionCompleted} />