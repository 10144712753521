import { Route } from '@readybid-team/rb-spa-router';
import Mounter from '@readybid-team/rb-spa-router-svelte-mount';
import currentUserService from 'src/services/currentUserService';

const CompetitiveBidsList = new Route(
  'CompetitiveBidsList',
  '/competitive-bids/:rfpId',
  Mounter(
    () => currentUserService.get()
      .then(() => import( 'src/pages/competitive-bids/bids/CompetitiveBidsListPage.svelte'))
  ));

export default CompetitiveBidsList;
