import adminServer from 'root/api/AdminServer';
import {checkAndExecute} from 'root/api/helpers';

export function search(request, url) {
    return checkAndExecute(
        () => request && url,
        () => adminServer.put(url, request)
    );
}

export function createUserWithPassword(userDetails){
  return checkAndExecute(
    () => userDetails,
    () => adminServer.post('/users/create-credentials', userDetails)
  );
}
