import api from 'src/pages/users/manager/users-manager-api';
import entityProfile from 'src/api/entityProfile';

const actions = [
  {
    label: 'Activate',
    execute: ua => api.setUserAccountStatus(ua.id, 'ACTIVE'),
    isAvailable: userAccount => userAccount.status.value !== 'ACTIVE'
  },
  {
    label: 'Disable',
    execute: ua => api.setUserAccountStatus(ua.id, 'DELETED'),
    isAvailable: userAccount => userAccount.status.value !== 'DELETED'
  },
  {
    label: 'Set UNVERIFIED',
    execute: ua => api.setUserAccountStatus(ua.id, 'UNVERIFIED'),
    isAvailable: userAccount => userAccount.status.value !== 'UNVERIFIED'
  },
  {
    label: 'Grant ADMIN status',
    execute: ua => {
      return entityProfile.grantAdminStatusToUsers(ua.entityId, ua.accountId, [ua.id])
        .then(() => api.loadUserAccountDetails(ua.id))
    },
    isAvailable: userAccount => userAccount.adminStatus === 'Manager'
  },
  {
    label: 'Revoke ADMIN status',
    execute: ua => {
      return entityProfile.revokeAdminStatusToUsers(ua.entityId, ua.accountId, [ua.id])
        .then(() => api.loadUserAccountDetails(ua.id))
    },
    isAvailable: userAccount => userAccount.adminStatus === 'Admin'
  },
]


function listAvailableActions(userAccount = {}){
  return actions.filter(a => a.isAvailable(userAccount)).map(a => a.label);
}

function executeAction(actionId, userAccount){
  const action = actions.find(a => a.label === actionId);
  return action
    ? action.execute(userAccount).then(res => res.data)
    : Promise.reject();
}

export const service = {
  listAvailableActions,
  executeAction
}
