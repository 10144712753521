import component from './Representatives.page.svelte';
export const REPRESENTATIVES = 'reps';

export default {
    component,
    id: REPRESENTATIVES,
    text: 'Representatives',
    icon: 'people',
    subItems: [
        {
            id: 'hotel',
            text: 'Hotel Representatives',
            icon: 'remove_red_eye'
        },
        {
            id: 'chain',
            text: 'Chain Representatives',
            icon: 'remove_red_eye'
        },
    ]
};