<script>
    import {getContext} from 'svelte';
    import RadioQuestion from './QuestionnaireFormRadioQuestion.component.svelte';
    export let options, id;

    const formModel = getContext('formModel');

    let value = $formModel.values[id];
    
    formModel.addModel({ id, value, validations: [] });
    
    function input({detail}) {
        formModel.setValue(id, detail);
        value = $formModel.values[id];
    }

</script>

<RadioQuestion {id} {options} {value} on:input={input}><slot/></RadioQuestion>