import modals from 'root/services/modals';
import NotificationModal from 'components/modals/Notification.basic.svelte';
import ChooseReplacementContact from './dialogs/replacement/ChooseReplacementContact.dialog.svelte';
import HttpNotification from 'root/api/HttpNotifications';

export default function deleteOrDeactivateRepresentatives({ hotelId, selectedRepresentatives, representatives, apiAction, actionLabel }){
  const action = prepareApiActionFunction(apiAction, hotelId, selectedRepresentatives);
  return executeActionWithReplacementNeededFallback(action, selectedRepresentatives, representatives, actionLabel);
}

function prepareApiActionFunction(apiFn, hotelId, selectedRepresentatives){
  return (replacementRepresentative, forceNoReplacement) =>
    apiFn(hotelId, { reps: selectedRepresentatives, replacementRepresentative, forceNoReplacement });
}

function executeActionWithReplacementNeededFallback(action, selectedRepresentatives, representatives, actionLabel){
  return HttpNotification.handle(action(), {
    onError: { '409': () => onReplacementMissing(selectedRepresentatives, representatives, action, actionLabel)}
  })
    .then( ({replacement}) => successNotification(selectedRepresentatives.length, actionLabel, replacement) );
}

function onReplacementMissing(selectedRepresentatives, representatives, action, actionLabel){
  const accountTypeOfSelection = selectedRepresentatives.length ? selectedRepresentatives[0].accountType : 'HOTEL';
  const replacementCandidates = representatives.filter(r => r.accountType === accountTypeOfSelection
    && (r.isUser || r.isInvitedUser)
    && !selectedRepresentatives.find(s => s.id === r.id));

  return modals
    .show(ChooseReplacementContact, {reps: replacementCandidates, selected: selectedRepresentatives, label: actionLabel})
    .then(replacement => {
      return HttpNotification.handle(action(replacement,  ! replacement))
        .then(() => ({replacement}))
    })
}

function successNotification(selectedLength,  actionLabel, replacement){
  modals.show(
    NotificationModal,
    {
      subTitle: 'Success',
      title: `Representative${selectedLength > 1? 's': ''} ${actionLabel.toLowerCase()}d successfully`,
      content: `The representative${selectedLength > 1? 's have': ' has'} been ${actionLabel.toLowerCase()}d successfully. ${replacement && replacement.isUser ? 'An email was sent to the selected replacement informing them of this change.': ''}`,
      rejectLabel: 'Ok'
    })
}
