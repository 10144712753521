const id = 'maxLength';

export default {
  create(config){
    return {
      id,
      hasDependencies: false,
      isInvalid (value, values, context, name) {
        return value && value.length > config && {
          id,
          message: `${ name } too long`
        };
      }
    }
  },
}
