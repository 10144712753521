import adminServer from 'root/api/AdminServer';
import {checkAndExecute} from 'root/api/helpers';


function getHotelRequest(id){
  return checkAndExecute(
    () => !!id,
    () => adminServer.get(`hotels/requests/${id}`)
  );
}

function validateHotelRequest(id, hotelData, image, toNotify){
  return checkAndExecute(
    () => !!id && hotelData,
    () => {
      const fd = new FormData();
      if(image) fd.append('image', image);
      fd.append('hotel', new Blob([JSON.stringify(hotelData)], { type: 'application/json' }));

      if(toNotify)
        fd.append('notifications', new Blob([JSON.stringify(toNotify)], { type: 'application/json' }));

      return adminServer.sendFile(`hotels/requests/${id}/validate`, fd)
    }
  );
}

function validateHotelRemoveRequest(id){
  return checkAndExecute(
    () => !!id,
    () => adminServer.post(`hotels/requests/${id}/remove-hotel`)
  );
}

function rejectHotelRequest(id, message){
  return checkAndExecute(
    () => !!id,
    () => adminServer.post(`hotels/requests/${id}/reject`, {message})
  );
}


export default {
  getHotelRequest,
  validateHotelRequest,
  validateHotelRemoveRequest,
  rejectHotelRequest
}
