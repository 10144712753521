<script>

  import {loadRfps} from 'src/pages/users/fix-email-address-service/report/emailAddressFixReportApi';

  export let
    value = [],
    account = '';

  $: loadRfpsForAccount(account)

  let
    rfps = [],
    filteredRfps = [],
    query;

  function loadRfpsForAccount(acc = ''){
    if(acc){
      value = [];
      loadRfps(acc)
        .then(r => {
          rfps = r.data
            .map(i => ({
              ...i,
              $name: i.name.toLowerCase(),
              $buyer: i.buyer.toLowerCase(),
              $filter: `${i.buyer} ${i.year} ${i.name}`
            }))
            .sort((a,b) => {
              if(a.$buyer === b.$buyer) {
                if(a.year === b.year) {
                  return a.$name > b.$name ? 1 : -1;
                } else {
                  return b.year - a.year;
                }
              } else {
                return a.$buyer > b.$buyer ? 1 : -1
              }
            });
        });
    }
  }

  $: filteredRfps = filterRfps(rfps, query);

  function filterRfps(list = [], query = ''){
    if(query){
      const pattern = new RegExp(query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i')
      return list.filter(i => pattern.test(i.$filter));
    } else {
      return list;
    }
  }

  function toggleRfp(rfp){
    const rfpId = rfp.id;
    const index = value.indexOf(rfpId);
    value = index === -1 ? [...value, rfpId] : value.filter(v => v !== rfpId);
  }

  function toggleAll(){
    if(value.length === rfps.length){
      value = [];
    } else {
      value = rfps.map(rfp => rfp.id);
    }
  }

</script>

{#if rfps.length}
  <div class="Component">
    <div class="HeadingContainer">
      <div class="CheckAll"
           on:click={toggleAll}
           data-icon-before="{value.length ===  0 ? 'check_box_outline_blank' : (rfps.length === value.length ? 'check_box' : 'indeterminate_check_box')}"
      ></div>
      <input type="text" placeholder="Filter" bind:value={query} />
    </div>

    <div class="Menu">
      {#each filteredRfps as rfp}
        <div class="Item"
             data-icon-before="{value.indexOf(rfp.id) === -1 ? 'check_box_outline_blank' : 'check_box'}"
             on:mousedown={() => toggleRfp(rfp)}
        >
          [{rfp.buyer}] [{rfp.year}] {rfp.name}
        </div>
      {/each}
    </div>
  </div>
{/if}

<style lang="stylus">.Component {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.HeadingContainer {
  display: flex;
  align-items: center;
}
.Menu {
  overflow: auto;
  margin: 10px 0;
}
.Item {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 2.3;
  font-size: 13px;
}
.Item:before {
  padding-right: 10px;
}
.Item:hover {
  color: #fff;
  cursor: pointer;
}
.CheckAll {
  margin-right: 10px;
}
.CheckAll:hover {
  color: #fff;
  cursor: pointer;
}
</style>
