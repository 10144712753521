<div class="Container" data-icon-before="search">
  <input class="Input"
         class:focused="{focused}"
         type="text"
         placeholder="Keywords here..."
         {value}
         on:input="{e => handleInput(e.target.value)}"
         on:focus="{() => focused = true}"
         on:blur="{() => focused = false}"
  />
</div>


<script>
  import {createEventDispatcher} from 'svelte';

  export let value;

  const dispatch = createEventDispatcher();

  let focused;

  function handleInput(value) {
    dispatch('change', value);
  }
</script>

<style lang="stylus">.Container {
  height: 34px;
  line-height: 34px;
  border: 1px solid #263238;
  border-radius: 30px;
  color: #546e7a;
  font-size: 13px;
  padding: 0 5px;
}
.Input {
  border: 0;
  outline: 0;
  background: transparent;
  font-family: 'Noto Sans';
  width: 160px;
  color: #fff;
  padding-left: 5px;
}
.Input::placeholder {
  color: #72828a;
}
</style>