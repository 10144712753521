<script>
  import api from 'src/pages/users/manager/users-manager-api';
  import {loadEntityType} from 'src/pages/users/manager/formatUsers';

  import Header from 'root/components/Header.svelte';
  import Navigation from 'root/components/Navigation.svelte';
  import {DateFormatter} from 'root/utils/internationalization.service';
  import httpNotifications from "src/api/HttpNotifications";

  const formatDate = DateFormatter().format;

  let userAccounts = [], ready;

  init();


  function init() {
    httpNotifications.handle(api.listUnvalidatedUserAccounts())
      .then(res => {
        userAccounts = res.data.map(ua => ({
          ...ua,
          accountType: loadEntityType(ua.accountType),
          requestedAt: formatDate(ua.userAccountStatus.at),
          status: ua.userAccountStatus.value,
        }));
        ready = true;
      });
  }

  function setUserAccountStatus(id, status){
    httpNotifications.handle(api.setUserAccountStatus(id, status))
      .then(() => {
        userAccounts = userAccounts.map(ua => (ua._id !== id) ? ua : { ...ua, status });
      });
  }

</script>

<div class="rbPage rbDark">
  <Header />

  <div class="pageContainer">
    <div class="rbCard navigation">
      <Navigation />
    </div>

    <div class="contentContainer">
      <div class="heading">
        <div class="column page-label">
          <i class="material-icons page-icon">people_alt</i>
          <div class="labels">
            <h1 class="label-title">Unvalidated Users</h1>
          </div>
        </div>
      </div>

      <div class="ListContainer" class:Spinner={!ready}>
        {#if ready}
          {#each userAccounts as ua}
            <div class="ListItem">
              <div class="Left">
                <div class="requested">Date: {ua.requestedAt}</div>
                <div class="personal">{ua.firstName} {ua.lastName}</div>
                <div class="detail">{ua.jobTitle} @ <span>{ua.accountName}</span> ({ua.accountType})</div>
                {#if ua.accountAddress}<div class="address">{ua.accountAddress}</div>{/if}
                <div class="detail">email address: <span>{ua.emailAddress}</span></div>
                {#if ua.phone}<div class="detail">phone: <span>{ua.phone}</span></div>{/if}
              </div>

              <div class="Actions">
                {#if ua.status === 'UNVERIFIED'}
                  <button class="rbButton asDarkMain asLarge"
                          on:click={() => setUserAccountStatus(ua._id, 'ACTIVE')}
                  >
                    Activate
                  </button>
                  {:else if ua.status  === 'ACTIVE'}
                  <button class="rbButton asLight asLarge"
                          on:click={() => setUserAccountStatus(ua._id, 'UNVERIFIED')}
                  >
                    Revert
                  </button>
                {/if}
              </div>
            </div>
          {:else}
            <div class="Notice">No Unvalidated User Accounts</div>
          {/each}
        {/if}
      </div>

    </div>
  </div>
</div>

<style lang="stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.contentContainer {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.page-label {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
.page-icon {
  color: #00b8ab;
  height: 40px;
  width: 40px;
  background: #273238;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.labels {
  margin-left: 10px;
}
h1.label-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
.ListContainer {
  border-top: 1px solid #00a99d;
  flex: 1 1 100%;
  overflow: auto;
  padding: 20px 10px 0;
}
.ListItem {
  background: #263238;
  border-radius: 6px;
  border: 1px solid #263238;
  margin-bottom: 10px;
  display: flex;
  padding: 10px;
  color: #b0bec5;
  font-size: 13px;
  line-height: 16px;
}
.Left {
  flex: 1 1 100%;
}
.personal {
  color: #fff;
  font-size: 15px;
  line-height: 24px;
}
.address {
  margin-bottom: 5px;
}
.detail span {
  color: #fff;
}
.Actions {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
</style>
