<script>
  import {createEventDispatcher} from "svelte";

  export let value;
  let editing = false;
  const dispatch = createEventDispatcher();

  function save() {
    console.log(value);
    dispatch("save", value);
    editing = false;
  }

</script>

{#if editing}
  <div class:editing>
    <input type="text" class="rbInput" bind:value/>
    <button class="rbButton iconOnlySmall asDarkMain"
      data-icon="save"
      on:click="{save}"
    ></button>
    <button class="rbButton iconOnlySmall ClearButton"
      data-icon="cancel"
      on:click="{() => editing = false}"
    ></button>
  </div>
{:else}
  <div class="viewing">
    <slot />
    <i class="material-icons" on:click={() => editing = true}>edit</i>
  </div>
{/if}

<style lang="stylus">div {
  display: flex;
  align-items: center;
}
.viewing:not(:hover) i {
  display: none;
}
.viewing i {
  margin-left: 10px;
  cursor: pointer;
  font-size: 18px;
}
.viewing {
  min-width: 150px;
}
input {
  margin-right: 10px;
}
</style>
