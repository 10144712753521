import component from './BasicInfo.page.svelte';
export const BASIC_INFO = 'basic-info';

export default {
    component,
    id: BASIC_INFO,
    text: 'Basic Information',
    icon: 'location_city',
    subItems: [
        {
            id: 'basic',
            text: 'Basic Information',
            icon: 'remove_red_eye'
        },
        {
            id: 'codes',
            text: 'GDS Codes',
            icon: 'remove_red_eye'
        },
    ]
};