<script>
    import route from 'root/routes/HotelProfile';
    import Header from 'root/components/Header.svelte';
    import Navigation from './Navigation.component.svelte';
    import {currentModule} from './modules/hotel-profile-modules';
    import {loadHotel} from './services/hotel.service';
    import {editImage} from '../advanced-search/search.service';

    const {params: {hotelId, section}} = route.$createStateFromURL(window.location.hash);
    let hotel, Page;

    loadHotel(hotelId).then(h => {
      hotel = h;
    });

    function updateImage() {
        editImage(hotel).then(h => hotel = h);
    }

    function activate() {
      return null;
    }

    $: Page = currentModule(section);

</script>

<div class="rbPage rbDark">
    <Header />
    <div class="pageContainer">
        <Navigation {section} {hotelId} lastUpdated={hotel && hotel.lastUpdated} />

        <div class="content">
            {#if hotel}
                <svelte:component this="{Page}" {section} {hotelId} bind:hotel type="HOTEL">
                    <button class="rbButton asDark" data-icon="add_a_photo" on:click={updateImage}> Update Photo </button>
                    {#if !hotel.active}
                        <button class="rbButton asDark" data-icon="check_circle" on:click={activate}> Validate </button>
                    {/if}
                </svelte:component>
            {/if}
        </div>
    </div>
</div>

<style type="text/stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.content :global(.section .title) {
  width: 100%;
  border-bottom: 1px solid #37474f;
  font-size: 14px;
  font-weight: normal;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.content :global(.section .title.space) {
  justify-content: space-between;
}
.content :global(.main) {
  height: calc(100vh - 158px);
  overflow: auto;
}
.content :global(.main .section .content) {
  padding: 0 15px;
  font-size: 12px;
}
.content :global(.main .section .content .data) {
  margin: 10px 0;
  display: flex;
  align-items: center;
}
</style>
