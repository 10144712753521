<script>
  import {default as store} from './SelectContact.store';
  import FormModel from 'components/molecules/inputs/FormModel.svelte';
  import InputText from 'components/molecules/inputs/InputText.svelte';
  import InputPhone from 'components/molecules/inputs/InputPhone.svelte';
  import {formContextWrapper} from 'src/utils/email-address-validity-form-wrapper';
  import {onMount} from 'svelte';

  export let cancel, saveContact, canSend;

  let invalid, touchAll, formContext, getFormModel, checkEmailAddress = () => {};

  $: checkEmailAddress(formContext && $formContext.values.emailAddress);

  onMount(() => {
    formContext = getFormModel();
    checkEmailAddress = formContextWrapper(formContext);
  })

  function saveUser(shouldSend) {
    if(invalid){
      touchAll();
    } else {
      saveContact($store.bid.id, $store.formModel, shouldSend);
    }
  }
</script>

<FormModel bind:values={$store.formModel} bind:invalid bind:touchAll bind:getFormModel>
  <div class="FormContainer">
    <div class="Header">
      <b>Edit Contact</b>
    </div>

    <div class="TwoColumns">
      <InputText id="firstName" required="true" maxLength="100">First Name</InputText>

      <InputText id="lastName" required="true" maxLength="100">Last Name</InputText>
    </div>

    <InputText id="emailAddress" maxLength="255" required="true" pattern="{/.*@.*/}">Email Address</InputText>

    <InputPhone id="phone" maxLength="30">Phone</InputPhone>

    <InputText id="jobTitle" maxLength="100">Job Title</InputText>

    <div class="ActionsContainer">
      <div>
        {#if canSend}
          <button class="rbButton asDark"
                  disabled="{invalid}"
                  on:click="{() => saveUser(true)}">Save and Send</button>
        {/if}
      </div>

      <div>
        <button class="rbButton asDark" on:click="{() => cancel()}">Cancel</button>
        <button class="rbButton asDarkMain"
                disabled="{invalid}"
                data-icon="save"
                on:click={() => saveUser()}> Save </button>
      </div>
    </div>
  </div>
</FormModel>

<style lang="stylus">.Header {
  flex: 0;
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
}
.ActionsContainer {
  flex: 0;
  display: flex;
  justify-content: space-between;
  text-align: right;
  margin: 15px 0 10px;
}
.FormContainer {
  padding: 5px 10px;
}
.TwoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
</style>
