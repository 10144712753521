import { derived } from 'svelte/store';
import SendBidsAction from './actions/SendBidsAction';

const actions = [SendBidsAction];

export default function actionsStore(bidsStore){

  const store = derived(bidsStore, values => {
    const
      selectedBids = values.selected.map(id => values.bids.find(b => b.id === id)),
      actionsAvailable = actions.filter(a => a.isAvailable(selectedBids));
    return {
      available: actionsAvailable,
      empty: !actionsAvailable.length
    };
  })

  return {subscribe: store.subscribe}
}