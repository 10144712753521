
<script>
  import {getContext} from 'svelte';

  import InputText from 'components/molecules/inputs/InputText.svelte';
  import InputPhone from 'components/molecules/inputs/InputPhone.svelte';
  import InputEmail from 'components/molecules/inputs/InputEmail.svelte';
  import Select from 'components/molecules/inputs/SelectModel.svelte';

  const accountTypes = [
    {
      value: 'ACCOUNT_ADMIN',
      label: 'Administrator'
    },
    {
      value: 'ACCOUNT_MANAGER',
      label: 'Account Manager'
    }
  ];

  export let hide, cancel;

  function sendInvitation() {
    formModel.touchAll();
    if($formModel.valid) {
      hide($formModel.values);
    }
  }

  let formModel = getContext('formModel');
</script>

<div class="content">
  <div class="twoColumns">
    <InputText id="firstName" required="true" maxLength="100">First Name</InputText>
    <InputPhone id="lastName" required="true" maxLength="100">Last Name</InputPhone>
  </div>

  <InputEmail id="emailAddress" maxLength="255" required="true">Email Address</InputEmail>


  <div class="twoColumns">
    <InputText id="title" maxLength="100">Job Title</InputText>
    <InputPhone id="phone" maxLength="30">Phone</InputPhone>
  </div>

  <Select id="role" options="{accountTypes}" required="true">Account Role</Select>
</div>

<div class="footer">
  <button class="rbButton asModal" on:click="{cancel}">Cancel</button>
  <button class="rbButton asModalMain" data-icon="send" on:click={sendInvitation}> Send Invitation </button>
</div>

<style lang="stylus">.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
.content {
  margin: 10px 0 30px;
  font-size: 13px;
  line-height: 16px;
  color: #546e7a;
}
.footer {
  text-align: right;
}
.content :global(.rbInput.component .container) {
  border: 1px solid #e6e7e8;
  background: transparent;
  color: #546e7a;
}
.content :global(.rbInput.component .container .input) {
  color: #546e7a;
}
.content :global(.rbInput.component .container .label) {
  background: #fff;
}
</style>
