<script>
  import Navigation from "components/organisms/Navigation/Navigation.svelte";
  import NavigationItem from "components/organisms/Navigation/NavigationItem.svelte";

  import Home from "../routes/Home.js";
  import PropertiesSearch from "../routes/AdvancedHotelSearch.js";
  import {EntitiesSearch, CorporationsActivityPage} from "../routes/EntitiesSearch";
  import EntitiesAdd from "../routes/EntitiesAdd";
  import Chains from "../routes/Chains";
  import HotelDirectoryReports from "../routes/HotelDirectoryReports";
  import { Customers, SuspendedAccounts } from "../routes/Customers";
  import { UsersSearch, UserActivityReport, EmailAddressWhitelist, FixEmailAddressServiceManager, EmailAddressBlacklist, EmailAddressIgnoreList, UserManager, UnvalidatedUsers } from "../routes/UsersSearch";
  import AdminUserSearch from "../routes/AdminUserSearch";
  import CompetitiveBidsRfps from '../routes/competitive-bids/Rfps'
  import currentUserService from '../services/currentUserService.js';
  import accountTypes from "../assets/data/account-types.js";
  import PerDiemCache from "src/routes/PerDiem";
  import Bids from "src/routes/Bids";
  import Airports from "src/routes/Airports";

  const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    },
    subTitle = new Date().toLocaleDateString("en-US", options);

  let menu = [
    {
      icon: "domain",
      text: "Properties",
      href: PropertiesSearch.href({collection: "Hotel"}),
      subItems: [
        {
          href: Home.href(),
          text: "Add New Properties",
          icon: "add",
          isActive: () => Home.isActive()
        },
        {
          href: PropertiesSearch.href({collection: "Hotel"}),
          text: "Search Properties",
          icon: "search",
          isActive: () =>  entityRouteActive(PropertiesSearch, "Hotel", 'collection')
        },
        {
          href: PropertiesSearch.href({collection: "UnvalidatedHotel"}),
          text: "Unvalidated Properties",
          icon: "add_task",
          isActive: () =>  entityRouteActive(PropertiesSearch, "UnvalidatedHotel", 'collection')
        },
      ]
    },
    {
      icon: "mail",
      text: "Email Addresses",
      href: FixEmailAddressServiceManager.href(),
      subItems: [
        {
          icon: 'sick',
          text: 'Fix Email Address Service Manager',
          href: FixEmailAddressServiceManager.href(),
          isActive: () => FixEmailAddressServiceManager.isActive()
        },
        {
          icon: 'notifications_paused',
          text: 'Email Address Ignore List',
          href: EmailAddressIgnoreList.href(),
          isActive: () => EmailAddressIgnoreList.isActive()
        },
        {
          icon: 'unsubscribe',
          text: 'Email Address Blacklist',
          href: EmailAddressBlacklist.href(),
          isActive: () => EmailAddressBlacklist.isActive()
        },
        {
          icon: 'fact_check',
          text: 'Email Address Whitelist',
          href: EmailAddressWhitelist.href(),
          isActive: () => EmailAddressWhitelist.isActive()
        },
      ]
    },
    {
      icon: "people",
      text: "Users",
      href: UserManager.href(),
      subItems: [
        {
          icon: 'people_alt',
          text: 'Unvalidated Users',
          href: UnvalidatedUsers.href(),
          isActive: () => UnvalidatedUsers.isActive()
        },
        {
          icon: 'people_alt',
          text: 'User Manager',
          href: UserManager.href(),
          isActive: () => UserManager.isActive()
        },
        {
          icon: 'search',
          text: 'Search',
          href: UsersSearch.href(),
          isActive: () => UsersSearch.isActive()
        },
        {
          icon: 'analytics',
          text: 'Recent Activity Report',
          href: UserActivityReport.href(),
          isActive: () => UserActivityReport.isActive()
        },
      ]
    },
    {
      icon: 'business_center',
      text: 'Corporations',
      href: EntitiesSearch.href({ type: 'COMPANY' }),
      subItems: [
        {
          icon: "add",
          text: `Add New Corporation`,
          href: EntitiesAdd.href({ type: 'COMPANY' }),
          isActive: () => entityRouteActive(EntitiesAdd, 'COMPANY')
        },
        {
          icon: "search",
          text: `Search Corporations`,
          href: EntitiesSearch.href({ type: 'COMPANY' }),
          isActive: () => entityRouteActive(EntitiesSearch, 'COMPANY')
        },
        {
          icon: "summarize",
          text: `Corporations Activity`,
          href: CorporationsActivityPage.href(),
          isActive: () => CorporationsActivityPage.isActive()
        },
      ]
    },
    entityMenuItem("TRAVEL_AGENCY", "card_travel"),
    {
      icon: "link",
      text: "Chains & Brands",
      href: Chains.href(),
      isActive: () => Chains.isActive()
    },
    {
      icon: "hotel",
      text: "Hotel Directories",
      href: HotelDirectoryReports.href(),
      isActive: () => HotelDirectoryReports.isActive()
    },
    {
      icon: 'store',
      text: 'Customers',
      href: Customers.href(),
      isActive: () => Customers.isActive() || SuspendedAccounts.isActive(),
      subItems: [
        {
          icon: "store",
          text: "Customers",
          href: Customers.href(),
          isActive: () => Customers.isActive()
        },
        {
          icon: "currency_exchange",
          text: "Suspended Accounts",
          href: SuspendedAccounts.href(),
          isActive: () => SuspendedAccounts.isActive()
        },
      ]
    },
    entityMenuItem("HMC", "flight_takeoff"),
    {
      icon: 'folder_special',
      text: 'Competitive Bids',
      href: CompetitiveBidsRfps.href(),
      isActive: () => CompetitiveBidsRfps.isActive(),
    },
    {
      icon: "price_change",
      text: "Per Diem",
      href: PerDiemCache.href(),
      isActive: () => PerDiemCache.isActive()
    },
    {
      icon: "download",
      text: "Bids Exports",
      href: Bids.href(),
      isActive: () => Bids.isActive()
    },
    {
      icon: "local_airport",
      text: "Airports",
      href: Airports.href(),
      isActive: () => Airports.isActive()
    },
  ];

  function entityMenuItem(type, icon) {
    const accountType = accountTypes.find(({id}) => id === type);
    return {
      icon,
      text: accountType.plural,
      href: EntitiesSearch.href({ type }),
      subItems: [
        {
          icon: "add",
          text: `Add New ${accountType.label}`,
          href: EntitiesAdd.href({ type }),
          isActive: () => entityRouteActive(EntitiesAdd, type)
        },
        {
          icon: "search",
          text: `Search ${accountType.plural}`,
          href: EntitiesSearch.href({ type }),
          isActive: () => entityRouteActive(EntitiesSearch, type)
        }
      ]
    }
  }

  currentUserService.get().then(user => {
    if(user.root) {
      menu = [...menu, {
        icon: "people",
        text: "Admin Portal Users",
        href: AdminUserSearch.href(),
      }];
    }
  })
  let link, minimized, item;

  function opened({ subItems }) {
    return subItems && subItems.find(({ isActive }) => isActive && isActive());
  }

  function active({ isActive }) {
    return isActive && isActive();
  }

  function entityRouteActive(route, value, key = 'type') {
    const state =
      route.isActive() && route.$createStateFromURL(window.location.hash);
    return state && state.params[key] === value;
  }
</script>

<Navigation let:minimized let:link {subTitle} links={menu}>
  <NavigationItem
    {...link}
    {minimized}
    open={opened(link) || active(link)}
    active={opened(link) || active(link)}>
    {link.text}
    <span slot="item" let:item>
      <NavigationItem
        {...item}
        {minimized}
        active={item.isActive && item.isActive()}
        isChild={true}>
        {item.text}
      </NavigationItem>
    </span>
  </NavigationItem>
</Navigation>
