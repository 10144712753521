<script>
  import NotificationTemplate from 'components/modals/NotificationTemplate.basic.svelte';
  import {CurrencyFormatter, PercentageFormatter} from 'root/utils/internationalization.service';

  export let cancel, hotel, bidsStats, title;

  let
    states = bidsStats.states,
    totalSent = bidsStats.count || 0,
    notOpened = states.SENT || states.COMPETITIVE_SENT || 0,
    opened = totalSent - notOpened,

    declinedBySupplier = states.NOT_INTERESTED || states.COMPETITIVE_DECLINED || 0,
    notResponded = states.RECEIVED || 0,
    notPayed = states.COMPETITIVE_RECEIVED || 0,
    payed = opened - declinedBySupplier - notPayed,
    payedNotResponded = states.COMPETITIVE_RECEIVED_ACCEPTED || 0,

    totalResponded = opened - declinedBySupplier - notPayed - payedNotResponded - notResponded,
    competitiveInReview = states.COMPETITIVE_RESPONDED || 0,
    competitiveDeclinedByBuyer = (states.COMPETITIVE_REFUSED || 0) + (states.COMPETITIVE_REFUSED_ACK || 0),
    competitiveAcceptedByBuyer = totalResponded - competitiveInReview - competitiveDeclinedByBuyer,

    inReviewByBuyer = states.RESPONDED || 0,
    inFinalAgreement = states.FINAL_AGREEMENT || 0,
    inNoThankYou = states.NO_THANK_YOU || 0,
    inNegotiations = (states.NEGOTIATION_SENT || 0) +
      (states.NEGOTIATION_RESPONDED || 0) +
      (states.NEGOTIATION_FINALIZED || 0) +
      (states.FINAL_AGREEMENT_PENDING || 0) +
      (states.NO_THANK_YOU_PENDING || 0) +
      (states.NO_LONGER_INTERESTED || 0),
    negotiatedByBuyer = states.NEGOTIATION_SENT || 0,
    negotiatedBySupplier = states.NEGOTIATION_SENT || 0,
    negotiationFinalized = inNegotiations - negotiatedByBuyer - negotiatedBySupplier,
    formattedOffers = formatOffers(bidsStats.offers);

  function formatOffers(offers = []) {
    return offers
      .sort((a,b) => b.year - a.year)
      .map(o => {
        const cFormatter = CurrencyFormatter(o.currency, ''),
          pFormatter = PercentageFormatter(0,0,'');

        return {
          year: o.year,
          rnv: o.rnv,
          buyer: o.buyer,
          currency: o.currency,
          lra: cFormatter.format(o.lra || null),
          lraAVG: cFormatter.format(o.lraAVG || null),
          nlra: cFormatter.format(o.nlra || null),
          nlraAVG: cFormatter.format(o.nlraAVG || null),
          dyn: pFormatter.format(o.dyn || null),
        };
      })
  }
</script>

<NotificationTemplate width="720">
  <div class="container">
    <div class="header">
      <div class="headerContent">
        <div class="title">{title}</div>
        <div class="subtitle">{hotel.name}</div>
      </div>
    </div>

    <div class="content">
      <table class="Table">
        <tbody>
        <tr><th>Total Sent:</th><th>{totalSent}</th></tr>
        <tr><td>Received:</td><td>{opened}</td></tr>
        <tr><td>Not Received:</td><td>{notOpened}</td></tr>
        </tbody>
        {#if title === 'Competitive Bids'}
          <tbody>
          <tr><th>Total Received:</th><th>{opened}</th></tr>
          <tr><td>Declined:</td><td>{declinedBySupplier}</td></tr>
          <tr><td>Not Payed:</td><td>{notPayed}</td></tr>
          <tr><td>Payed:</td><td>{payed}</td></tr>
          <tr><td>Payed but not Responded:</td><td>{payedNotResponded}</td></tr>
          <tr><td>Responded:</td><td>{totalResponded}</td></tr>
          </tbody>
          <tbody>
          <tr><th>Total Responded:</th><th>{totalResponded}</th></tr>
          <tr><td>Competitive Refused by the Buyer:</td><td>{competitiveDeclinedByBuyer}</td></tr>
          <tr><td>Competitive Reviewed by the Buyer:</td><td>{competitiveInReview}</td></tr>
          <tr><td>Competitive Accepted by the Buyer:</td><td>{competitiveAcceptedByBuyer}</td></tr>
          </tbody>
          <tbody>
          <tr><th>Total Responded Accepted:</th><th>{competitiveAcceptedByBuyer}</th></tr>
          <tr><td>Reviewed by the Buyer:</td><td>{inReviewByBuyer}</td></tr>
          <tr><td>Final Agreement:</td><td>{inFinalAgreement}</td></tr>
          <tr><td>No Thank You:</td><td>{inNoThankYou}</td></tr>
          <tr><td>Negotiated:</td><td>{inNegotiations}</td></tr>
          </tbody>
        {:else}
          <tbody>
          <tr><th>Total Received:</th><th>{opened}</th></tr>
          <tr><td>Declined:</td><td>{declinedBySupplier}</td></tr>
          <tr><td>Not Responded:</td><td>{notResponded}</td></tr>
          <tr><td>Responded:</td><td>{totalResponded}</td></tr>
          </tbody>
          <tbody>
          <tr><th>Total Responded:</th><th>{totalResponded}</th></tr>
          <tr><td>Reviewed by the Buyer:</td><td>{inReviewByBuyer}</td></tr>
          <tr><td>Final Agreement:</td><td>{inFinalAgreement}</td></tr>
          <tr><td>No Thank You:</td><td>{inNoThankYou}</td></tr>
          <tr><td>Negotiated:</td><td>{inNegotiations}</td></tr>
          </tbody>
        {/if}
        <tbody>
        <tr><th>Total Negotiated:</th><th>{inNegotiations}</th></tr>
        <tr><td>Negotiated by the Buyer:</td><td>{negotiatedByBuyer}</td></tr>
        <tr><td>Negotiated by the Supplier:</td><td>{negotiatedBySupplier}</td></tr>
        <tr><td>Negotiation Finalized:</td><td>{negotiationFinalized}</td></tr>
        </tbody>
      </table>

      {#if formattedOffers.length}
        <table class="Table">
          <tbody>
          <tr><td>
            <div class="Offers">
              <div class="oHead">Year</div>
              <div class="oHead">Customer</div>
              <div class="oHead">RNV</div>
              <div class="oHead">CUR</div>
              <div class="oHead">LRA (avg)</div>
              <div class="oHead">NLRA (avg)</div>
              <div class="oHead">DYN</div>
              {#each formattedOffers as offer}
                <div>{offer.year || '-'}</div>
                <div>{offer.buyer || '-'}</div>
                <div>{offer.rnv || '-'}</div>
                <div>{offer.currency}</div>
                <div>{offer.lra} ({offer.lraAVG})</div>
                <div>{offer.nlra} ({offer.nlraAVG})</div>
                <div>{offer.dyn}</div>
              {/each}
            </div>
          </td></tr>
          </tbody>
        </table>
      {/if}
    </div>

    <div class="footer">
      <button class="rbButton asDarkMain asModalMain" on:click="{cancel}">OK</button>
    </div>
  </div>
</NotificationTemplate>

<style lang="stylus">.container {
  padding: 25px 0 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 80vh;
  box-sizing: border-box;
}
.header {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  padding: 0 15px;
}
.headerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  line-height: 20px;
  font-size: 15px;
  vertical-align: text-bottom;
  color: #fff;
  text-shadow: 1px 0 0 rgba(0,0,0,0.15);
  font-weight: normal;
  padding: 0;
  margin: 0;
}
.subtitle {
  line-height: 15px;
  font-size: 12px;
  vertical-align: text-bottom;
  color: #b0bec5;
  text-shadow: 1px 0 0 rgba(0,0,0,0.15);
  font-weight: bold;
  padding: 0;
  margin: 0;
}
.content {
  margin: 25px 0 30px;
  font-size: 13px;
  line-height: 16px;
  color: #546e7a;
  flex: 1 1 100%;
  overflow: auto;
  padding: 0 15px;
}
.footer {
  text-align: right;
  flex: 0 0 auto;
  padding: 0 15px;
}
.Table {
  width: 100%;
  border-collapse: true;
}
.Table tbody::before {
  content: '';
  display: block;
  height: 20px;
}
.Table th {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #fff;
}
.Table td {
  font-size: 13px;
  color: #b0bec5;
}
.Offers {
  line-height: 20px;
  display: grid;
  grid-template-columns: 50px 1fr 50px 50px 1fr 1fr 45px;
}
.oHead {
  font-weight: bold;
}
</style>
