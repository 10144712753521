import {writable} from 'svelte/store';
import API from '../api/end-points';
import industries from 'src/assets/data/industries';
import {defaultDirection, defaultSort, sort as executeSort, directions} from './CorporationsActivity.sort';
import {createFilters} from 'src/pages/entities/search/CorporationsActivity.filters';

const defaultState = {
  initialized: false,
  error: false,

  sortValue: defaultSort.value,
  direction: defaultDirection,
  filters: createFilters(),

  rawData: [],
  data: []
};

const store = writable({...defaultState});

export default store;
export const noAgencyLabel = 'STANDALONE';

export async function initialize(){
  try {
    store.set(defaultState);
    const response = await API.loadCompaniesActivity();
    const rawData = response.map(prepareCompany);

    store.set({
      ...defaultState,
      initialized: true,
      filters: createFilters(rawData),
      rawData,
      data: executeSort(rawData, defaultState.sortValue, defaultDirection.value),
    });

  } catch (e) {
    store.set({
      ...defaultState,
      error: true,
    });
  }
}

function prepareCompany(company) {
  return {
    ...company,
    name: company.name.trim(),
    industry: industries.find(({id}) => id === company.industry).label,
    agency: company.agency ? company.agency : {name: noAgencyLabel},
    lastActive: company.lastActive ? company.lastActive.split('T')[0] : '---',
  }
}

export function applyFilters(){
  store.update($s => ({
    ...$s,
    data: executeSort(filter($s.filters, $s.rawData), $s.sortValue, $s.direction.value)
  }));

  function filter(filters, data){
    return filters.reduce((acc, f) => f.applyFilter(acc), data);
  }
}

export function sort(e){
  const sortValue = e.detail,
    direction = defaultDirection;

  store.update($s => ({
    ...$s,
    data: executeSort($s.data, sortValue, direction.value),
    sortValue,
    direction
  }));
}

export function sortDirection(e){
  const directionValue = e.detail;

  store.update($s => ({
    ...$s,
    data: executeSort($s.data, $s.sortValue, directionValue),
    direction: directions.find(d => d.value === directionValue)
  }));
}
