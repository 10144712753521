<script>
  import Checkbox from './Checkbox.component.svelte';
  import HotelCell from './HotelCell.component.svelte';

  export let
    collection,
    hotel = {},
    selectedList = [],
    config = {};

  let hotelId, selected;

  $: hotelId = hotel.id || hotel._id;
  $: selected = selectedList.includes(hotelId);

  function image({image}) {
    image = image && image.url || '';
    return image ? (image.startsWith('http') ? image : `/images/entities/${image}`): '/images/hotel_placeholder.png';
  }

  function select() {
    if(selected){
      selectedList = selectedList.filter(id => id !== hotelId);
    }else{
      selectedList = [...selectedList, hotelId];
    }
  }
</script>

<div class="hotel">

  <div class="StaticCell" style="width:{config.selectColumn.width}" on:click={select} >
    <Checkbox {selected} />
  </div>

  <div class="StaticCell" style="width:{config.imageColumn.width}">
    <img src={image(hotel)} alt={hotel.name} class="logo">
  </div>

  {#each config.columns as column}
    <HotelCell {collection} config="{column}" {hotel} />
  {/each}
</div>

<style type="text/stylus">.hotel {
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 0;
  border-bottom: 1px solid rgba(176,190,197,0.239);
  font-size: 12px;
  height: 85px;
}
.StaticCell {
  flex: 0 0 auto;
  overflow: hidden;
}
.logo {
  height: 69px;
  min-height: 69px;
  width: 80px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  margin-right: 5px;
  overflow: hidden;
  position: relative;
}
.logo[alt]:after {
  padding: 5px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-family: 'Helvetica';
  font-weight: 300;
  line-height: 2;
  text-align: center;
  content: attr(alt);
  font-size: 10px;
  box-sizing: border-box;
}
</style>
