import {get} from 'svelte/store';
import {isDeliverable as isDeliverableCheck} from 'src/pages/users/fix-email-address-service/emailAddressFixServiceApi';
import modalsService from 'src/services/modals';
import ErrorsModal from 'src/components/ErrorsModal.svelte';

export function formContextWrapper(formContext){
  const emailAddressCheckCache = {
    '': false
  };

  let lastCheckedEmailAddress;

  return async function checkEmailAddress(emailAddress){
    lastCheckedEmailAddress = emailAddress;
    const fc = get(formContext);
    if(!fc || fc.errors.emailAddress) return;

    if(emailAddressCheckCache.hasOwnProperty(emailAddress)){
      if(emailAddressCheckCache[emailAddress]){
        return;
      } else {
        setEmailAddressError(`${emailAddress} is a known bad email address`);
        return;
      }
    }

    setEmailAddressError(`Checking ${emailAddress}...`);

    try {
      const check = await isDeliverableCheck(emailAddress);
      emailAddressCheckCache[emailAddress] = check;

      if (lastCheckedEmailAddress === emailAddress) {
        if (check) {
          unsetError();
        } else {
          setEmailAddressError(`${emailAddress} is a known bad email address`);
        }
      }
    } catch (e) {
      modalsService.show(ErrorsModal, {
        message: `Failed to check email address`,
        error: e && e.data && e.data.message || null,
      });
    }
  }

  function setEmailAddressError(message){
    const error = {isDeliverable: {id: 'isDeliverable', message}};
    formContext.update(ffc => ({
      ...ffc,
      errors: {
        ...ffc.errors,
        emailAddress: {
          ...ffc.errors.emailAddress,
          ...error
        },
      },
      models: {
        ...ffc.models,
        emailAddress: {
          ...ffc.models.emailAddress,
          errors: {
            ...ffc.models.emailAddress.errors,
            ...error,
          },
          errorMessage: message,
          invalid: true,
          valid: false,
          touched: true,
          untouched: false,
        }
      },
      invalid: true,
      valid: false,
    }));
  }

  function unsetError(){
    formContext.update(ffc => {
      const model = ffc.models.emailAddress;
      const {isDeliverable, ...errors} = model.errors;
      if(Object.keys(errors).length){
        return {
          ...ffc,
          errors: {
            ...ffc.errors,
            emailAddress: errors
          },
          models: {
            ...ffc.models,
            emailAddress: {
              ...ffc.models.emailAddress,
              errors,
              errorMessage: errors[Object.keys(errors)[0]].message,
              invalid: true,
              valid: false,
              touched: true,
              untouched: false,
            }
          },
          invalid: true,
          valid: false,
        };
      } else {
        const {emailAddress, ...ffcErrors} = ffc.errors;
        const isValid = !Object.keys(ffcErrors).length;
        return {
          ...ffc,
          errors: ffcErrors,
          models: {
            ...ffc.models,
            emailAddress: {
              ...ffc.models.emailAddress,
              errors: {},
              errorMessage: '',
              invalid: false,
              valid: true,
              touched: true,
              untouched: false,
            }
          },
          invalid: !isValid,
          valid: isValid,
        };
      }
    });
  }
}
