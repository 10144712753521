<script>
  import GeneralFaultTemplatePage from './GeneralFaultTemplatePage.svelte';
  import { SUPPORT_NEW_TICKET_LINK } from '../config/links.js'
  export let goToHome = () => { console.log('clicked on homepage') };
  let supportLink = SUPPORT_NEW_TICKET_LINK;
  let data = {
    title:"It's not you, it's us",
    subtitle: "500 ERROR",
  };
</script>

<GeneralFaultTemplatePage { ...data} on:click="{goToHome}">
  Go to Home Page, refresh your browser and try again.
  If this problem persists, you can contact our <a href={supportLink}>support team</a>.
</GeneralFaultTemplatePage>
