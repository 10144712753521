<script>
  import currentUserService from "root/services/currentUserService";

  export let user, showActions;
  let currentUser = null;

  currentUserService.get().then(u => (currentUser = u));

  $: showEdits = currentUser && (currentUser.root || currentUser.id == user.id);
</script>

<style type="text/stylus">.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #00a995 0%, #006eb9 50%, #00aa4f 100%);
  height: 98px;
  box-sizing: border-box;
}
.entity {
  display: flex;
  align-items: flex-end;
}
.name {
  font-size: 18px;
}
.slot {
  display: flex;
  align-items: flex-end;
}
</style>

<div class="header">
  <div class="entity">
    {#if !showActions}
      <div class="details">
        <div class="name">{user.fullName}</div>
      </div>
    {:else}
      <slot name="actions" />
    {/if}
  </div>
  <div class="slot">
    {#if showEdits}
      <slot />
    {/if}
  </div>
</div>
