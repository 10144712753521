import adminServer from 'root/api/AdminServer';
import {checkAndExecute} from 'root/api/helpers';

export function sendUpdateCacheRequestForYear(year) {
    return checkAndExecute(
      () => year,
      () => adminServer.put('per-diem/cache', { year })
    )
}

export function listLatestUpdateCacheRequests() {
    return checkAndExecute(
      () => true,
      () => adminServer.get('per-diem/cache')
    )
}
