import {
  FREE_TEXT_FILTER_TYPE,
  MULTI_SELECT_FILTER_TYPE,
  SINGLE_SELECT_FILTER_TYPE
} from 'root/pages/hotels/advanced-search/filters/filter-types';
import AccountTypes from 'root/assets/data/account-types';

export default function getFilter() {
  return Object.freeze([
    {
      label: `Full Name`,
      type: FREE_TEXT_FILTER_TYPE,
      id: 'fullName',
      value: ''
    },
    {
      label: 'Email Address',
      type: FREE_TEXT_FILTER_TYPE,
      id: 'emailAddress',
      value: ''
    },
    {
      label: 'Phone Number',
      type: FREE_TEXT_FILTER_TYPE,
      id: 'phone',
      value: ''
    },
    {
      label: 'Account Type',
      type: MULTI_SELECT_FILTER_TYPE,
      id: 'accountType',
      value: [],
      options: AccountTypes.map(({ id, label }) => ({ value: id, label }))
    },
    {
      label: 'Account Name',
      type: FREE_TEXT_FILTER_TYPE,
      id: 'accountName',
      value: ''
    },
    {
      label: `Sort Order`,
      type: SINGLE_SELECT_FILTER_TYPE,
      id: 'order',
      value: {
        label: 'Most recently active',
        value: -1
      },
      options: [
        {
          label: 'Most recently active',
          value: -1
        },
        {
          label: 'Least recently active',
          value: 1
        },
      ]
    }
  ]);
}
