import { openProfile } from '../../customers/search.service';
import HttpNotifications from 'src/api/HttpNotifications';
import {verifyHotelRepresentative} from 'src/api';


export function loadActions() {
  return [
    {
      id: 'openAccount',
      label: 'Open Account',
      icon: 'visibility',
      isAvailable(users) {
        return users.length  === 1;
      },
      action: ([user]) => open(user),
    },

    {
      id: 'verifyUser',
      label: 'Verify User',
      icon: 'visibility',
      isAvailable(users) {
        return users.length === 1 && users[0].status.value === 'UNDER_REVIEW';
      },
      action: ([user]) => HttpNotifications.handle(verifyHotelRepresentative(user._id))
    },
  ];
}

export function open(user) {
  return openProfile(user.account);
}
