<script context="module">
  import noop from 'lodash/noop';
  import identity from 'lodash/identity';

  const COMPONENT_EVENT_PATTERN = /(mouse|click|context)/
</script>

<script>
  import { onMount } from 'svelte';

  export let type = 'text';
  export let name = '';
  export let id = name;
  export let value = '';
  export let error;
  export let disabled = null;
  export let readonly = null;
  export let format = identity;

  export let on = [];
  export let focus = () => elInput.focus();

  export let iconStart;
  export let iconEnd;
  export let required;


  let
          elComponent,
          elLabel,
          elInput,
          focused = false,
          notEmpty,
          inputValue,
          formattedValue;

  $: notEmpty = value || value === 0;
  $: inputValue = notEmpty ? value : '';
  $: formattedValue = focused ? inputValue : format(inputValue);
  onMount (() => setupListeners(on));


  function setupListeners(listOfListeners = []){
    const
            removeListeners = listOfListeners.map( config => {
              const length = config.length;
              if(length < 2){
                return noop;
              } else {
                let
                        paramsWithElement = length === 3,
                        target = paramsWithElement
                                ? getTarget(config[0])
                                : (COMPONENT_EVENT_PATTERN.test(config[0]) ? elComponent : elInput),
                        eventName = config[paramsWithElement ? 1 : 0],
                        fn = config[paramsWithElement ? 2 : 1];

                target.addEventListener(eventName, fn);
                return () => target.removeEventListener(eventName, fn);
              }
            });

    return () => removeListeners.forEach( rl => rl() );
  }

  function getTarget(targetOrEventName){
    switch (targetOrEventName) {
      case 'component':
        return elComponent;
      case 'label':
        return elLabel;
      case 'input':
        return elInput;
      default:
        return null;
    }
  }

  function grow (node, { duration = 200, maxHeight = 30 }) {
    return {
      duration: duration,
      fillMode: 'before',
      css: t => {
        return `
        transform: scale3d(${t}, 1, 1);
				overflow: hidden;
				max-height: ${maxHeight*t}px;
				opacity: ${t};`;
      }
    }
  }
</script>

<div class="rbInput component">
  <div class="container"
       class:leftPadding={!iconStart}
       class:rightPadding={!iconEnd}
       class:notEmpty
       class:focused
       class:error
       bind:this={elComponent}
       on:click={focus}>

    {#if (iconStart && typeof iconStart === 'string') }
      <i class="material-icons icon">{iconStart}</i>

    {:else if (iconStart && typeof iconStart === 'object' && iconStart.onClick)}
      <i class="material-icons icon clickable {iconStart.class || ''}"
         on:click|stopPropagation="{ e => iconStart.onClick && iconStart.onClick(e) }">{iconStart.name}</i>

    {:else if (iconStart && typeof iconStart === 'object')}
      <i class="material-icons icon {iconStart.class || ''}">{iconStart.name}</i>
    {/if}

    <div class="inputContainer">
      <label for="{name}"
             class="label"
             class:iconOffset={iconStart}
             class:required
             class:notEmpty
             class:focused
             class:error
             bind:this={elLabel}>
        <slot />{#if required}*{/if}
      </label>

      <input {type} {id} {name}
             class="input"
             {disabled}
             {readonly}
             value="{formattedValue}"
             on:input="{ e => value = e.target.value }"
             on:focus="{ () => focused = true }"
             on:blur="{ () => focused = false }"
             bind:this={elInput}>
    </div>

    {#if (iconEnd && typeof iconEnd === 'string') }
      <i class="material-icons icon">{iconEnd}</i>

    {:else if (iconEnd && typeof iconEnd === 'object' && iconEnd.onClick)}
      <i class="material-icons icon clickable {iconEnd.class || ''}"
         on:click|stopPropagation="{ e => iconEnd.onClick && iconEnd.onClick(e) }">{iconEnd.name}</i>

    {:else if (iconEnd && typeof iconEnd === 'object')}
      <i class="material-icons icon {iconEnd.class || ''}">{iconEnd.name}</i>
    {/if}
  </div>

  {#if error}
    <div class="errorBox"
         transition:grow>
      {error}
    </div>
  {/if}
</div>

<style type="text/stylus">.component {
  width: 100%;
}
.container {
  display: flex;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
  padding-bottom: 1px;
  height: 34px;
  border: 1px solid #263238;
  border-radius: 18px;
  background: #313c42;
  color: #727d84;
  transition: all 0.1s linear;
}
.container.leftPadding {
  padding-left: 15px;
}
.container.rightPadding {
  padding-right: 15px;
}
.container.notEmpty:before {
  color: #00a99d;
}
.container.focused {
  color: #00a99d;
  border-color: #00a99d;
}
.container.error {
  color: #f15a24;
  border-color: #f15a24;
}
.icon {
  text-align: center;
  flex: 0 0 40px;
  transition: all 0.1s linear;
  font-size: 20px;
}
.inputContainer {
  flex: 0 1 100%;
}
.label {
  position: absolute;
  top: 6px;
  left: 15px;
  background: transparent;
  font-size: 14px;
  cursor: text;
  transition: all 0.1s linear;
}
.label.required :after {
  content: '*';
}
.label.iconOffset {
  left: 40px;
}
.label.focused,
.label.notEmpty {
  top: -8px;
  left: 10px;
  padding: 0 5px;
  font-size: 11px;
  line-height: 1.2;
  background: #313c42;
}
.input {
  width: 100%;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  transition-delay: 86400s;
}
.clickable {
  cursor: pointer;
}
.errorBox {
  font-size: 12px;
  line-height: 1.2;
  color: #f15a24;
  max-height: 30px;
  text-align: left;
  padding: 2px 15px 0;
}
</style>
