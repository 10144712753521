import api from 'src/pages/users/manager/users-manager-api';
import modalsService from 'src/services/modals';
import TemporarySignInDialog from './TemporarySignInDialog.svelte';
import httpNotifications from 'src/api/HttpNotifications';

const actions = [
  {
    label: 'Activate',
    execute: withHttpHandler(user => api.setUserStatus(user.id, 'ACTIVE')),
    isAvailable: user => user.status.value !== 'ACTIVE'
  },
  {
    label: 'Disable',
    execute: withHttpHandler(user => api.setUserStatus(user.id, 'DISABLED')),
    isAvailable: user => user.status.value !== 'DISABLED'
  },
  {
    label: 'Request Email Address Verification',
    execute: withHttpHandler(user => api.setUserStatus(user.id, 'PENDING_EMAIL_VERIFICATION')),
    isAvailable: user => user.status.value !== 'PENDING_EMAIL_VERIFICATION'
  },
  {
    label: 'Temporary Sign In',
    execute: user => modalsService.show(TemporarySignInDialog, {user}),
    isAvailable: user => user.status.value === 'ACTIVE'
  },
]

function listAvailableActions(user = {}){
  return actions.filter(a => a.isAvailable(user)).map(a => a.label);
}

function executeAction(actionId, user, updateFn){
  const action = actions.find(a => a.label === actionId);
  action.execute(user, updateFn);
}

export const service = {
  listAvailableActions,
  executeAction
}

function withHttpHandler(apiCall){
  return (user, updateFn) => httpNotifications.handle(apiCall(user), {transparent: false})
    .then(res => updateFn(res.data));
}
