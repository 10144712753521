import get from 'lodash/get';

export function createStringCompareFn(key = '', order = 1){
  return (a,b) => get(a, key, '').localeCompare(get(b, key, '')) * order;
}

export function createNumberCompareFn(key = '', order = 1){
  return (a,b) => {
    const aNumber = parseFloat(get(a, key)), bNumber = parseFloat(get(b, key));
    return ( (aNumber || 0) - (bNumber || 0) ) * order;
  }
}

export function createDateCompareFn(key = '', order = 1){
  return (a,b) => {
    const aDate = new Date(get(a, key)).valueOf(), bDate = new Date(get(b, key)).valueOf();
    return ( (aDate || 0) - (bDate || 0) ) * order;
  }
}
