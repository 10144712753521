<script>
  import Spinner from 'components/atoms/Spinner.svelte';

  export let message = 'Please wait...';
</script>

<div class="Component">
  <Spinner />
  <div>{message}</div>
</div>

<style lang="stylus">.Component {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
