import { Route } from '@readybid-team/rb-spa-router';
import Mounter from '@readybid-team/rb-spa-router-svelte-mount';
import currentUserService from 'root/services/currentUserService';

const UsersSearch = new Route('UsersSearch', '/users/search', Mounter( () => currentUserService.get().then(() => import( 'root/pages/users/search/UserSearch.svelte'))));
const UserActivityReport = new Route('UserActivityReport', '/users/report', Mounter( () => currentUserService.get().then(() => import( 'root/pages/users/search/ActivityReport.svelte'))));
const UserManager = new Route('UserManagement', '/users/manager?tab', Mounter( () => currentUserService.get().then(() => import( 'root/pages/users/manager/UserManagerPage.svelte'))));
const UnvalidatedUsers = new Route('UnvalidatedUsers', '/users/unvalidated', Mounter( () => currentUserService.get().then(() => import( 'root/pages/users/unvalidated/UnvalidatedUserAccountsPage.svelte'))));

const EmailAddressWhitelist = new Route('EmailAddressWhitelist', '/email-addresses/email-address-whitelist',
  Mounter( () => currentUserService.get().then(() => import( 'root/pages/users/email-address-whitelist/EmailAddressWhitelist.svelte'))));

const EmailAddressBlacklist = new Route('EmailAddressBlacklist', '/email-addresses/blacklist',
  Mounter( () => currentUserService.get().then(() => import( 'root/pages/users/email-address-blacklist/EmailAddressBlacklist.svelte'))));

const EmailAddressIgnoreList = new Route('EmailAddressIgnoreList', '/email-addresses/ignore-list',
  Mounter( () => currentUserService.get().then(() => import( 'root/pages/users/email-address-ignore-list/EmailAddressIgnoreList.svelte'))));

const FixEmailAddressServiceManager = new Route('FixEmailAddressServiceManager', '/email-addresses/fix-email-address-service/manager',
  Mounter( () => currentUserService.get().then(() => import( 'root/pages/users/fix-email-address-service/FixEmailAddressServiceManager.svelte'))));

const FixEmailAddressServiceReport = new Route('FixEmailAddressServiceReport', '/email-addresses/fix-email-address-service/manager/report',
  Mounter( () => currentUserService.get().then(() => import( 'root/pages/users/fix-email-address-service/report/FixEmailAddressServiceReport.svelte'))));

export {
  UserActivityReport,
  UsersSearch,
  EmailAddressWhitelist,
  FixEmailAddressServiceManager,
  EmailAddressBlacklist,
  FixEmailAddressServiceReport,
  EmailAddressIgnoreList,
  UserManager,
  UnvalidatedUsers
};
