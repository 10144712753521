import {listBrands} from 'src/api';
import modals from 'src/services/modals';
import RebrandDialog from 'src/pages/hotels/profile/modules/basic-info/dialogs/rebrand/Rebrand.dialog.svelte';
import {orderBy} from 'lodash';
import {rebrandHotel, showAsyncActionMessage} from 'src/pages/hotels/profile/services/hotel.service';
import ReplacementDialog
  from 'src/pages/hotels/profile/modules/basic-info/dialogs/replacement/ChooseReplacementContact.dialog.svelte';

export default function rebrand(hotel) {
  return listBrands()
    .then(brands => modals.show(RebrandDialog, {
      hotel,
      brands: orderBy(brands.map(({name, code, id: value, master}) => ({
        value,
        label: `${name} - ${code} ${master? (`: ${  master.name  } - ${  master.code}`): ''}`
      })), ['label'], ['asc']),
    }))
    .then(({name, website, chain: {id: brandId}}) => save(name, website, brandId))
    .then(showAsyncActionMessage);


  function save(name, website, brandId, userId) {
    return rebrandHotel(hotel.id, {name, website, brandId, userId})
      .then(({data}) => {
        if(Array.isArray(data)) {
          return modals.show(ReplacementDialog, {reps: data})
            .then(id => save(name, website, brandId, id))
        }else{
          return data;
        }
      });
  }
}
