<script>
  import Header from 'root/components/Header.svelte';
  import Navigation from 'root/components/Navigation.svelte';

  import FiltersBar from 'root/pages/hotels/advanced-search/filters/SearchFilters.component.svelte';
  import Select from 'components/molecules/inputs/Select.svelte';
  import CorporationActivityItem from './CorporationActivityItem.svelte';

  import VirtualList from 'src/components/VirtualList.svelte';
  import {default as store, initialize, sort, sortDirection, applyFilters} from './CorporationsActivity.store'
  import {sorters, directions} from './CorporationsActivity.sort';
  import Spinner from 'components/atoms/Spinner.svelte';

  initialize();

</script>

<div class="rbPage rbDark">
  <Header></Header>
  <div class="pageContainer">
    <div class="rbCard navigation"><Navigation></Navigation></div>

    <div class="content">
      <div class="heading">
        <div class="column page-label" data-icon-before="summarize" >
          <div class="labels">
            <h1 class="label-title">Corporations Activity Page</h1>
            <p class="label-sub-title">Click on "filters" to see all filter options</p>
          </div>
        </div>

        <div class="column buttons">
          <div class="sorter">
            <Select id="sortBy" options={sorters} value={$store.sortValue} iconStart="sort" on:input={sort}>Sort By</Select>
          </div>

          <div class="sorter">
            <Select id="sortDir" options={directions} value={$store.direction.value} iconStart="{$store.direction.icon}" on:input={sortDirection}>Direction</Select>
          </div>
        </div>
      </div>

      {#if $store.initialized}
        <div class="table-header">
          <FiltersBar bind:filters="{$store.filters}" filterTypes="{$store.filters}">
            <button class="rbButton asDarkMain small searchButton" data-icon="search" on:click={applyFilters}>Search</button>
          </FiltersBar>
        </div>

        <VirtualList items="{$store.data}"
                     let:item
                     itemHeight={91}
        >
          <div slot="row">
            <CorporationActivityItem {item}/>
          </div>
        </VirtualList>

      {:else if $store.error}
        <div class="notificationContainer">There was an error</div>

      {:else}
        <div class="notificationContainer"><Spinner /></div>
      {/if}
    </div>
  </div>
</div>

<style lang="stylus">.notificationContainer {
  display: flex;
  justify-content: center;
  flex: 1 1 100%;
  padding-top: 33%;
  box-sizing: border-box;
}
.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  flex: 0 0 auto;
}
.page-label {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
.page-label:before {
  color: #00b8ab;
  height: 40px;
  width: 40px;
  background: #273238;
  border-radius: 100%;
  line-height: 40px;
  text-align: center;
  margin-right: 10px;
}
h1.label-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
p.label-sub-title {
  margin: 0;
  font-size: 10px;
}
.table-header {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #00a995 0%, #006eb9 50%, #00aa4f 100%) 0 1 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.buttons > :global(*) {
  flex-shrink: 0;
}
.sorter {
  display: inline-block;
  min-width: 180px;
  margin-right: 10px;
}
.sorter :global(.rbInput .container) {
  margin-top: 0;
}
.sorter :global(.rbInput .input) {
  min-width: 53px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 53px;
  width: 53px;
  text-overflow: ellipsis;
}
.small {
  height: 30px;
  font-size: 12px;
}
.small::before {
  font-size: 18px;
}
.searchButton {
  z-index: 1;
}
</style>
