<script>
  import {service} from 'src/pages/users/manager/users.store';
  import api from 'src/pages/users/manager/users-manager-api';
  import debounce from 'lodash/debounce';

  import Input from 'components/molecules/inputs/Input.svelte';
  import UsersTable from '../UsersTable.svelte';
  import Notice from 'src/pages/users/manager/tabs/Notice.svelte';


  let searchQuery = '', dListUsers = debounce(listUsers, 200), promise;
  $: dListUsers(searchQuery);

  async function listUsers(query = ''){
    if(query.length) {
      service.setLoading();
      const p = api.searchUsersByDetails(query);
      promise = p;
      const result = await promise;
      if (p === promise) service.setUsers(result.data);
    } else {
      promise = undefined;
      service.setUsers([]);
    }
  }
</script>


<div class="Container">
  <div class="UserDetailsSearch">
    <Input name="search" bind:value="{searchQuery}">Search by Name or Email Address</Input>
  </div>

  <div class="List">
    <UsersTable />
  </div>

  <Notice>* Showing only first 200 results</Notice>
</div>



<style lang="stylus">.Container {
  flex: 1 1 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.List {
  flex: 1 1 100%;
  overflow: auto;
}
.UserDetailsSearch {
  flex: 0 0 auto;
  padding: 10px 20px;
}
</style>

