import Server from '@readybid-team/rb-http-client';
import {getAndAutoSaveFileWrapper} from 'src/api/autoSaveFile';

const
    adminServer = new Server('/api'),
    storage = window.localStorage,
    TOKEN_KEY = 'authentication',
    AUTH_HEADER = 'x-auth-token';

adminServer.interceptRequest(
    config => {
        config.headers[AUTH_HEADER] = loadAuthentication();
        return config;
    },
    error => Promise.reject(error)
);

adminServer.interceptResponse (
    response => {
        saveAuthentication(response);
        return Promise.resolve(response);
    },
    error => {
        const response = error.response || { status: -1 }; // by default there is no response nor error code for Network Error
        saveAuthentication(response);
        return Promise.reject(response);
    }
);

adminServer.signOut = clearAuthentication;

getAndAutoSaveFileWrapper(adminServer);

export default adminServer;

function saveAuthentication(response){
    const headers = response.headers,
        authToken = headers ? headers[AUTH_HEADER] : false;

    if(authToken){
        storage.setItem(TOKEN_KEY, authToken);
    }
}

function loadAuthentication(){
    return storage.getItem(TOKEN_KEY);
}

function clearAuthentication(){
    return storage.removeItem(TOKEN_KEY);
}
