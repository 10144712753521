import { Profile } from 'root/routes/AdminUserSearch';
import { BASIC_INFO } from '../profile/modules/basic-info';
import {handle} from 'root/pages/hotels/profile/services/hotel.service';
import { generatePins, updateUserStatuses, updateUserDetails, updatePassword, saveUser } from '../api';
import modals from 'root/services/modals';
import CreateEditModal from '../profile/dialogs/create-edit/Create.dialog.svelte';
import PasswordModal from '../profile/dialogs/Password/Password.dialog.svelte';

export function loadActions(users) {
  return [
    {
      id: 'open',
      label: 'View Details',
      icon: 'visibility',
      isAvailable() {
        return users.length === 1;
      },
      action: ([user]) => openProfile(user)
    },
    {
      id: 'refresh',
      label: 'Refresh Pin',
      icon: 'refresh',
      isAvailable() {
        return users.length > 0;
      },
      action: refreshPins
    },
    {
      id: 'edit',
      label: 'Edit Details',
      icon: 'edit',
      isAvailable() {
        return users.length === 1;
      },
      action: ([user]) => editUserDetails(user)
    },
    {
      id: 'disable',
      label: 'Deactivate',
      icon: 'cancel',
      isAvailable() {
        return users.length > 0 && users.every(({status}) => status && status.value === 'ACTIVE');
      },
      action: (u) => setUserStatus(u, 'DISABLED')
    },
    {
      id: 'enable',
      label: 'Activate',
      icon: 'verified',
      isAvailable() {
        return users.length > 0 && users.every(({status}) => status && status.value === 'DISABLED');
      },
      action: (u) => setUserStatus(u, 'ACTIVE')
    },
    {
      id: 'password',
      label: 'Change Password',
      icon: 'password',
      isAvailable() {
        return users.length === 1;
      },
      action: ([user]) => changePassword(user)
    },
  ].filter(({isAvailable}) => isAvailable()).reverse();
}

export function openProfile(user) {
  return Promise.resolve(window.open(Profile.href({userId: user._id || user.id, section: BASIC_INFO}), '_blank'));
}

export function refreshPins(users) {
  return handle(generatePins(users.map(user => user.id || user._id)));
}

export function setUserStatus(users, status) {
  return handle(updateUserStatuses(users.map(user => user.id || user._id), status));
}

export function editUserDetails(user) {
  return modals.show(CreateEditModal, {user}).then((u) => handle(updateUserDetails(user._id || user.id, u)));
}

export function changePassword(user) {
  return modals.show(PasswordModal, {user}).then((u) => handle(updatePassword(user._id || user.id, u)));
}

export function createUser() {
  return modals.show(CreateEditModal).then((user) => handle(saveUser(user)));
}
