import adminServer from './AdminServer';
import {checkAndExecute} from './helpers';

export function signIn(email = '', password = ''){
    return checkAndExecute(
      () => email && password,
      () => adminServer.post('/sign-in', { email, password })
    );
}

export function getCurrentUser(){
    return adminServer.get('/current-user')
}

export function signOut(){
    adminServer.signOut();
}

export function searchHotels(params){
    return adminServer.get('/hotels', params);
}

export function advancedHotelSearch(params) {
    return adminServer.put('/hotels/search', params);
}

export function exportLastCreatedHotels(){
    return adminServer.getAndAutoSaveFile('/last-created-hotels');
}

export function exportLastResolvedUnvalidatedHotels(){
    return adminServer.getAndAutoSaveFile('/last-resolved-unvalidated-hotels');
}

export function addHotel(image, request){
    const formData = new FormData();

    formData.append('image', image);
    formData.append('hotel', new Blob([JSON.stringify(request)], { type: 'application/json' }));
    return adminServer.sendFile('/hotels', formData);
}

export function listBrands(){
    return adminServer.get('/hotel-chains/brands');
}

export function listChains(){
    return adminServer.get('/hotel-chains/chains');
}

export function listHMCs() {
  return adminServer.get('/hotel-groups/hmc');
}


export function listCompetitiveBidsRfps(){
    return adminServer.get('/rfps/hotel/competitive-bids/rfps');
}

export function createCompetitiveBids(rfpsIds, settings = {}){
    return adminServer.post('/rfps/hotel/competitive-bids/rfps/bids/create', { rfpsIds, ...settings });
}

export function listCompetitiveBidsForRfp(rfpId){
    return adminServer.get(`/rfps/hotel/competitive-bids/rfps/${rfpId}/bids`);
}

export function listContactsForCompetitiveBidHotel(hotelId){
    return adminServer.get(`/rfps/hotel/competitive-bids/hotels/${hotelId}/contacts`);
}

export function createCompetitiveBidContact(bidId, contactData) {
    return adminServer.put(`/rfps/hotel/competitive-bids/bids/${bidId}/contacts`, contactData);
}

export function setCompetitiveBidContact(bidId, userAccountId) {
    return adminServer.post(`/rfps/hotel/competitive-bids/bids/${bidId}/contacts`, {userAccountId});
}

export function sendCompetitiveBids(bidsIds) {
    return adminServer.post(`/rfps/hotel/competitive-bids/bids/send`, {bidsIds});
}

export function verifyHotelRepresentative(userId){
    return adminServer.post(`/users/hotel-representatives/verify`, {userId});
}
