<script>
    import Table from 'components/organisms/SimpleTable/SimpleTable.svelte';

    export let searchResult = {};

    const headers = [
        {
            key: 'sabreCode',
            title: 'Sabre Code',
            size: 'm'
        },
        {
            key: 'distance',
            title: 'Distance',
            size: 'm'
        },
        {
            key: 'name',
            title: 'Hotel Name',
            size: 'xl'
        },
        {
            key: 'location.fullAddress',
            title: 'Address',
            size: 'xl'
        },
        {
            key: 'formattedMaster',
            title: 'Master Chain',
            size: 'm'
        },
        {
            key: 'formattedBrand',
            title: 'Brand',
            size: 'm'
        },
    ];

</script>

<div class="rbInlineNotice" data-icon="search">
    <div>
        <div class="title">Search Results</div>
        <div>Add/Manage Hotels from ReadyBid Database</div>
    </div>
</div>

<Table contentPromise="{searchResult}" {headers} dark="true"></Table>

<style type="text/stylus">.rbInlineNotice {
  padding: 24px 15px;
}
.title {
  font-size: 20px;
  color: #fff;
}
</style>
