<script>
  import DialogTemplate from 'components/modals/DialogTemplate.basic.svelte';

  // export let cancel, hide, report;
  export let cancel;

</script>

<div class="Component">
  <DialogTemplate {cancel} closeOnClick="true">
    <div class="Container">
      <div class="Title">There were errors. Some bids were not sent.</div>
    </div>
  </DialogTemplate>
</div>

<style lang="stylus">.Component :global(.dialog) {
  background: #313c42;
  border-color: #313c42;
  overflow: hidden;
}
.Container {
  font-size: 14px;
  color: #cfd8dc;
/*min-height: 280px;*/
  max-height: 60vh;
  display: flex;
  flex-direction: column;
}
.Title {
  padding: 5px 10px;
}
</style>
