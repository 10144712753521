import NotificationModal from 'components/modals/Notification.basic.svelte';

import modals from 'root/services/modals';

import ChooseReplacementContact from './ChooseReplacementContact.dialog.svelte';
import {deactivateRepresentatives, deleteRepresentatives, activateRepresentatives} from '../../services/entity.service';


export const deleteReps = (...params) =>  changeRepStatus(...params, deleteRepresentatives, 'Delete');
export const deactivateReps = (...params) =>  changeRepStatus(...params, deactivateRepresentatives, 'Deactivate');

export function changeRepStatus({id, type}, reps, representatives, action, label) {
    const contacts = formatForRequest(reps);

    return apply();

    function apply(replacement) {
        return action(id, type, contacts, replacement).then(res => {
            if(!res || res.length  === 0) {
                modals.show(
                    NotificationModal,
                    {
                        subTitle: 'Success',
                        title: `Representative${reps.length > 1? 's': ''} ${label.toLowerCase()}d successfully`,
                        content: `The representative${reps.length > 1? 's have': ' has'} been ${label.toLowerCase()}d successfully. ${replacement? 'An email was sent to the selected replacement informing them of this change.': ''}`,
                        rejectLabel: 'Ok'
                    }
                )
                return res;
            }else{
                return applyWithReplacement(res.map((rep) => contacts.find(contact => contact.userId === rep.userId)));
            }
        });
    }

    function applyWithReplacement(toDelete) {

        const registeredReps = representatives.filter(({userId, invitation, active}) => !invitation && active && !reps.find(rep => rep.userId === userId));
        return modals
            .show(ChooseReplacementContact, {
                reps: registeredReps,
                toDelete,
                label,
                title: 'Active  RFPs in progress',
                message: `${toDelete.length} user${ toDelete.length > 1? 's': ''} you are trying to ${label}, is currently set as the primary contact on some RFPs. You need to choose a replacement in order to proceed.`
            })
            .then(replacement => apply(replacement));
    }
}

export function activateReps({id, type}, reps) {
    return activateRepresentatives(id, type, formatForRequest(reps));
}

function formatForRequest(reps) {
    return reps.map(rep => ({
        ...rep,
        isInvitation: rep.invitation,
        isUser: !rep.invitation,
        userId: rep.userId || rep.id,
    }))
}
