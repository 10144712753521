/**
 * @fileoverview This file contains the implementation of the user store and related functions.
 */

import {writable, get} from 'svelte/store';
import {service as userStoreService} from 'src/pages/users/manager/users.store';
import api from 'src/pages/users/manager/users-manager-api';
import debounce from 'lodash/debounce';

/**
 * Options for entity types.
 * @typedef {Object} EntityTypeOption
 * @property {string} value - The value of the entity type.
 * @property {string} label - The label of the entity type.
 * @property {string} searchPlaceholder - The search placeholder for the entity type.
 */

/** @type {EntityTypeOption[]} */
const entityOptions = [
  {value: 'HOTEL', label: 'Verified Hotels', searchPlaceholder: 'Search by Sabre Code, Name or Address'},
  {value: 'CHAIN', label: 'Verified Chains', searchPlaceholder: 'Search by Name'},
  {value: 'HMC', label: 'Verified Hotel Management Companies', searchPlaceholder: 'Search by Name'},
  {value: 'COMPANY', label: 'Verified Companies', searchPlaceholder: 'Search by Name'},
  {value: 'TRAVEL_AGENCY', label: 'Verified Travel Agencies', searchPlaceholder: 'Search by Name'},
  {value: 'TRAVEL_CONSULTANCY', label: 'Verified Travel Consultancies', searchPlaceholder: 'Search by Name'},
];

/** @type {string} */
const defaultEntityType = 'HOTEL';

/** @type {Object} */
const initialState = {
  entityOptions,
  entityType: entityOptions.find(eo => eo.value === defaultEntityType),
  query: '',
  page: 1,

  showSearch: true,
  entities: [],
  entity: undefined,
};

/** @type {Writable<Object>} */
const store = writable(initialState);

export default store;

/**
 * Initializes the user store.
 */
function initialize(){
  store.set(initialState);
  userStoreService.setUsers();
}

/**
 * Sets the entity type and performs necessary actions.
 * @param {string} type - The entity type to set.
 */
function setEntityType(type){
  const entityType = entityOptions.find(eo => eo.value === type);
  store.set({...initialState, entityType});
  userStoreService.setUsers();
}

/**
 * Performs a search with the provided query.
 * @param {string} [query=''] - The search query.
 */
async function search(query = ''){
  if(query.length) {
    const
      $store = get(store),
      entityType = $store.entityType,
      page = 1;

    if ($store.query === query) return;

    store.set({...$store, query, page});

    const result = await searchEntities(query, entityType.value, page);
    store.update($s => $s.query === query
      ? {...$s, entities: result.data, hasMore: result.total > result.data.length, page}
      : $s);

  } else {
    store.update($s => ({...initialState, entityType: $s.entityType}));
  }
}

/**
 * Performs a search for entities with the provided query, entity type, and page number.
 * @param {string} query - The search query.
 * @param {string} entityType - The entity type.
 * @param {number} page - The page number.
 * @returns {Promise<Object>} A promise that resolves to the search results.
 */
async function searchEntities(query, entityType, page) {
  const result = await api.searchEntities(query, entityType, page);
  const data = result.data.map(({entity, account}) => ({
    entityId: entity.id,
    name: entity.name,
    address: entity.fullAddress,
    sabreCode: entity.sabreCode,
    brandName: entity.chain ? entity.chain.name : undefined,
    chainName: entity.chain && entity.chain.masterChain ? `(${entity.chain.masterChain.name})` : '',
    accountId: account ? account._id : undefined,
    accountSuspended: account && account.state ? account.state.value === 'SUSPENDED' : false,
    agencyName: account && account.agency ? account.agency.name : undefined,
    userAccountsCount: account ? account.userAccounts : 0
  }));
  return { data, total: result.total};
}

/**
 * Loads more entities by performing a search with the current query, entity type, and page number.
 */
async function loadMore(){
  const $store = get(store),
    query = $store.query,
    type = $store.entityType.value,
    page = $store.page +1,
    result = await searchEntities(query, type, page);

  store.update($s => {
    const entities = [...$s.entities, ...result.data];
    return $s.query === query ? {...$s, entities, hasMore: entities.length <  result.total} : $s;
  });
}

/**
 * Selects an entity and performs necessary actions.
 * @param {Object} entity - The selected entity.
 */
async function selectEntity(entity){
  store.update($s => ({ ...$s, entity, showSearch: false, }));

  if(entity.accountId) {
    userStoreService.setLoading();
    const res = await api.listUsersForAccount(entity.accountId)
    userStoreService.setUsers(res.data);
  } else {
    userStoreService.setUsers([]);
  }
}

/**
 * Shows the search interface and performs necessary actions.
 */
function showSearch(){
  store.update($s => ({
    ...$s,
    entity: undefined,
    showSearch: true,
  }));
}

/**
 * The service object containing the exported functions.
 * @namespace service
 */
export const service = {
  initialize,
  setEntityType,
  search: debounce(search, 200),
  loadMore,
  selectEntity,
  showSearch
}

