<!--suppress ES6UnusedImports -->
<script>
    import {getContext} from 'svelte';

    import Header from '../../../../components/Header.component.svelte';
    import FormModule from '../QuestionnaireModule.component.svelte';
    import FormQuestion from './QuestionnaireFormQuestion.component.svelte';
    
    import {updateAnswers} from '../../../../services/hotel.service';
    import {GBTA_FORM} from '../../form.module';

    export let hotel, template, editing;

    let formModel = getContext('formModel'), question;

    const searcListeners = [
        ['input', ev => {
                const v = ev.target.value;
                search(v);
            }
        ]
    ];

    function search(keyword) {
        
    }

    async function save() {
        hotel = await updateAnswers(hotel.id, $formModel.values);
        back();
    }

    function back() {
        editing = false;
    }
</script>

<Header {hotel}>
    <slot name="search"/>
    <button class="rbButton asDarkMain" data-icon="save" on:click={save}>Save Changes</button>
    <button class="rbButton asDark" on:click={back}>Cancel</button>
</Header>

<div class="main questionnaire" id={GBTA_FORM}>
    {#each template.cells as module, index}
        <FormModule {module} {index} modulesCount={template.cells.length} {editing}>
            <div slot="question" let:question>
                <FormQuestion {question}/>
            </div>
        </FormModule>
    {/each}
</div>

<style type="text/stylus">.questionnaire {
  width: 960px;
  background-color: #fff;
  margin: 0 auto;
}
</style>