<script>
  import api from 'src/pages/users/manager/users-manager-api';
  import {service} from 'src/pages/users/manager/users.store';
  import UsersTable from '../UsersTable.svelte';
  import Notice from 'src/pages/users/manager/tabs/Notice.svelte';


  let countOnlyActive = true;

  $: initialize(countOnlyActive);

  async function initialize(){
    service.setLoading();
    const result = await api.listUsersWithMostUserAccounts(countOnlyActive);
    service.setUsers(result.data);
  }

</script>



<div class="Container">
  <div class="Search">
    <div class="Checkbox" class:checked="{countOnlyActive}"
         data-icon-before="{countOnlyActive ? 'check_box' : 'check_box_outline_blank'}"
         on:click="{() => {countOnlyActive = !countOnlyActive}}"
    >
      Count only active user accounts
    </div>
  </div>

  <div class="List">
    <UsersTable />
  </div>
  <Notice>* Showing only first 100 results</Notice>
</div>



<style lang="stylus">.Container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1 100%;
}
.List {
  flex: 1 1 100%;
  overflow: auto;
}
.Search {
  padding: 10px 20px;
}
.Checkbox {
  cursor: pointer;
}
.Checkbox:before {
  padding-right: 5px;
}
.Checkbox:hover {
  color: #fff;
}
.Checkbox.checked {
  color: #fff;
}
.Checkbox.checked:before {
  color: #00a99d;
}
</style>

