import {writable} from 'svelte/store';
import {formatUsers} from 'src/pages/users/manager/formatUsers';

const store = writable({ users: [], loading: false });

export default store;

function setLoading(){
  store.set({loading: true, users: []});
}

function setUsers(users = []){
  store.set({
    users: formatUsers(users),
    loading: false,
  });
}


export const service = {
  setLoading,
  setUsers,
}

