import adminServer from 'root/api/AdminServer';
import { checkAndExecute } from 'root/api/helpers';

export function search(request) {
  return checkAndExecute(
    () => request,
    () => adminServer.put('admin-users/search', request)
  );
}

export function findUser(id) {
  return checkAndExecute(
    () => id,
    () => adminServer.get(`admin-users/user/${id}`)
  );
}

export function generatePins(ids) {
  return checkAndExecute(
    () => ids,
    () => adminServer.put(`admin-users/refresh-pins`, { ids })
  );
}

export function updateUserStatuses(ids, status) {
  return checkAndExecute(
    () => ids,
    () => adminServer.put(`admin-users/set-status`, { ids, status })
  );
}

export function updateUserDetails(id, user) {
  return checkAndExecute(
    () => id && user,
    () => adminServer.put(`admin-users/user/${id}`, user)
  );
}

export function updatePassword(id, password) {
  return checkAndExecute(
    () => id && password,
    () => adminServer.put(`admin-users/change-password`, { password, id })
  );
}

export function saveUser(user) {
  return checkAndExecute(
    () => user,
    () => adminServer.post(`admin-users/user`, user)
  );
}
