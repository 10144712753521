<script>
    import {createEventDispatcher } from 'svelte';
    import Checkbox from 'root/pages/hotels/advanced-search/Checkbox.component.svelte';
    import {get} from 'lodash';
    import {showDetails, changePaymentStatus} from './service';

    export let directory;
    export let selectedDirectories = [];
    export let props = [];

    const dispatch = createEventDispatcher();
    
    function togglePaying() {
        changePaymentStatus(directory).then(() => {
            dispatch('refresh');
        });
    }

    function select() {
        dispatch('select', {_id: directory._id || directory.id, selected});
    }
    
    $: selected = selectedDirectories.includes(directory._id || directory.id)

    function open() {
        showDetails(directory);
    }

    function image({logo}) {
        return logo ? (logo.startsWith('http') ? lodo : `/images/logos/${logo}`): '';
    }
</script>

<div class="directory" on:click={select}>
    <Checkbox {selected} />
    <div class="data">
        <img src={image(directory.hotelDirectory.account)} alt={directory.hotelDirectory.account.name} class="logo">
        <div class="details">
            <div class="identifier" on:click|stopPropagation={open}>
                <b>{directory.hotelDirectory.account.name}</b>
            </div>
            <div class="props">
                {#each props as prop}
                    <div class="prop" style={`width: ${ (prop.size * 5) + 100 }px;`}>
                        <span class="label">{prop.label}:</span>
                        <span class="value">{prop.get? prop.get(directory): get(directory, prop.key, '---')}</span>
                    </div>
                {/each}
            </div>
        </div>
        <button class="rbButton asLight" on:click={togglePaying}> Mark as {directory.paying? 'Non Paying': 'Paying'}</button>
    </div>
</div>

<style type="text/stylus">.directory {
  display: flex;
  align-items: center;
  padding: 5px 5px 5px 0;
  border-bottom: 1px solid rgba(176,190,197,0.239);
}
.details {
  flex: 1;
  font-size: 12px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.props {
  opacity: 0.6;
  display: flex;
  flex-wrap: wrap;
}
.prop {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.data {
  flex: 1;
  display: flex;
  align-items: center;
}
.prop:not(:last-child) {
  border-right: 1px solid;
}
.prop:not(:last-child) {
  margin-right: 5px;
}
.prop span {
  margin-right: 5px;
}
.identifier {
  cursor: pointer;
}
.logo {
  height: 69px;
  width: 80px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  margin-right: 5px;
  overflow: hidden;
  position: relative;
}
img[alt]:after {
  padding: 5px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-family: 'Helvetica';
  font-weight: 300;
  line-height: 2;
  text-align: center;
  content: attr(alt);
  font-size: 10px;
  box-sizing: border-box;
}
</style>