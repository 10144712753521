import httpErrorHandler from 'root/api/HttpErrorHandler';
import {extractValue} from 'root/pages/hotels/advanced-search/search.service';

import getFilters from './filter-types';
import {search} from '../api';

export function loadFilterTypes() {
  return getFilters();
}

export function searchUsers(filters, and, page, perPage = 100) {
  const request = {
    filters: filters.reduce((acc, {id, value}) => hasValue(value)? ({...acc, [id]: extractValue(value)}): acc, {}),
    and,
    page,
    perPage,
  };

  function hasValue(value) {
      return Array.isArray(value)? value.length > 0: !!value;
  }

  return search(request).catch(httpErrorHandler.handle);
}
