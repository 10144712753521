import {brandsService} from 'root/stores/brands.store.js'
import {hmcsService} from 'root/stores/hmcs.store.js'
import {countriesService} from 'root/stores/countries.store.js'
import ensureHttpOnUrl from 'src/utils/ensure-http-for-url';

// eslint-disable-next-line complexity
export function createModel(source = {}){
  return {
    id: source.id,
    name: source.name,
    'location.address.address1': source.location.address.address1 || '',
    'location.address.address2': source.location.address.address2 || '',
    'location.address.city': source.location.address.city || '',
    'location.address.county': source.location.address.county || '',
    'location.address.state': source.location.address.state || '',
    'location.address.region': source.location.address.region || '',
    'location.address.zip': source.location.address.zip || '',
    'location.address.country': source.location.address.country || '',
    'location.coordinates.lat': source.location.coordinates.latitude || 0,
    'location.coordinates.lng': source.location.coordinates.longitude || 0,
    website: ensureHttpOnUrl(source.website || ''),
    emailAddress: source.emailAddress || '',
    phone: source.phone || '',
    'phone.country': source.answers.MAINPHONECOUNTRY || '',
    'phone.city': source.answers.MAINPHONECITY || '',
    'phone.number': source.answers.MAINPHONE || '',
    rating: source.rating || 0,
    marketTier: source.marketTier || '',
    brandId: source.chain ? source.chain.id : '',
    hmcId: source.hotelManagementCompany ? source.hotelManagementCompany.id : '',

    sabreChainCode: source.answers.SABRE_CHAINCODE || '',
    sabrePropertyCode: source.sabreCode || '',
    amadeusChainCode: source.answers.AMADEUS_CHAINCODE || '',
    amadeusPropertyCode: source.answers.AMADEUS_PROPCODE || '',
    apolloChainCode: source.answers.APOLLO_CHAINCODE || '',
    apolloPropertyCode: source.answers.APOLLO_PROPCODE || '',
    worldSpanChainCode: source.answers.WORLDSPAN_CHAINCODE || '',
    worldSpanPropertyCode: source.answers.WRLDSPAN_PROPCODE || '',
    internalPropertyCode: source.answers.INTERNALHOTELCODE || ''
  };
}

export function createFormData(source, formModel){
  const
    brand = brandsService.getBrandById(formModel.brandId),
    hmc = hmcsService.getHmcById(formModel.hmcId),
    countryName = countriesService.getNormalizedNameByCode(formModel['location.address.country']),
    formData = {
      name: formModel.name,
      location: {
        address: {
          address1: formModel['location.address.address1'],
          city: formModel['location.address.city'],
          country: formModel['location.address.country'],
        },
        coordinates: {
          lat: formModel['location.coordinates.lat'],
          lng: formModel['location.coordinates.lng'],
        }
      },
      brandId: formModel.brandId,

      answers: {
        ...source.answers,
        'INTERNALHOTELCODE': formModel.internalPropertyCode,
        'PROPNAME': formModel.name,
        'PROPADD1': formModel['location.address.address1'],
        'PROPADD2': formModel['location.address.address2'],
        'PROPCITY': formModel['location.address.city'],
        'PROPSTATEPROV': formModel['location.address.state'],
        'PROPCOUNTY': formModel['location.address.county'],
        'PROPREGION': formModel['location.address.region'],
        'PROPCOUNTRY': countryName,
        'PROPPOSTCODE': formModel['location.address.zip'],
        'MAINPHONECOUNTRY': formModel['phone.country'],
        'MAINPHONECITY': formModel['phone.city'],
        'MAINPHONE': formModel['phone.number'],
        'PROPURL': formModel.website,
        'PROPSALESGENREMAIL': formModel.emailAddress,
        'MKT_TIER': formModel.marketTier,
        'MGMTCOMPANY': hmc ? hmc.name : '',
        'AMADEUS_CHAINCODE': formModel.amadeusChainCode,
        'AMADEUS_PROPCODE': formModel.amadeusPropertyCode,
        'APOLLO_CHAINCODE': formModel.apolloChainCode,
        'APOLLO_PROPCODE': formModel.apolloPropertyCode,
        'SABRE_CHAINCODE': brand.code,
        'SABRE_PROPCODE': formModel.sabrePropertyCode,
        'WORLDSPAN_CHAINCODE': formModel.worldSpanChainCode,
        'WRLDSPAN_PROPCODE': formModel.worldSpanPropertyCode,
        'MASTERCHAINCODE': brand.master ? brand.master.code : ''
      }
    };

  setOrUnset(formData, 'location.address.address2', formModel['location.address.address2']);
  setOrUnset(formData, 'location.address.county', formModel['location.address.county']);
  setOrUnset(formData, 'location.address.state', formModel['location.address.state']);
  setOrUnset(formData, 'location.address.region', formModel['location.address.region']);
  setOrUnset(formData, 'location.address.zip', formModel['location.address.zip']);

  setOrUnset(formData, 'hotelManagementCompanyId', formModel.hmcId);
  setOrUnset(formData, 'website', formModel.website);
  setOrUnset(formData, 'emailAddress', formModel.emailAddress);
  setOrUnset(formData, 'phone', formModel.phone);
  setOrUnset(formData, 'rating', formModel.rating);
  setOrUnset(formData, 'marketTier', formModel.marketTier);
  setOrUnset(formData, 'sabrePropertyCode', formModel.sabrePropertyCode);

  return formData;

  function setOrUnset(fd = {}, path, value){
    const pathKeys = path.split('.');
    let o = fd;
    for(let i = 0, l = pathKeys.length-1; i<l;  i++){
      const k = pathKeys[i];
      if(!o[k]) {
        o[k] = {}
      }
      o = o[k];
    }

    if(value || value === 0){
      o[pathKeys[pathKeys.length-1]] = value;
    } else {
      delete o[pathKeys[pathKeys.length-1]];
    }
  }
}
