import { Route } from '@readybid-team/rb-spa-router';
import Mounter from '@readybid-team/rb-spa-router-svelte-mount';
import { ForbiddenPage } from 'components';
import Home from './Home';

const Forbidden = new Route('Forbidden', '/forbidden',
  Mounter(
    () => Promise.resolve({default: ForbiddenPage} ),
    () => ({
      goToHome: () => { Home.go(); }
    })
  ));

export default Forbidden;
