<script>
  import DialogTemplate from 'components/modals/DialogTemplate.basic.svelte';

  export let
    cancel,
    problem;

  problem.bids.sort((a,b) => a.rawDueDate < b.rawDueDate ? 1 : -1 )

</script>

<div class="Component">
  <DialogTemplate cancel="{cancel}" closeOnClick="{false}" width="{1024}">
    <div class="Container" style="min-height: 600px">
      <div class="Title">
        <div data-icon-before="{problem.isUserIcon}"
             class="TitleHeading">{problem.fullName} {`<${problem.emailAddress}>`}</div>
        <div data-icon-before="{problem.entityTypeIcon}"
             class="TitleHeading">{problem.entity.name}</div>
        {#if problem.entity.location && problem.entity.location.fullAddress}
          <div data-icon-before="map">{problem.entity.location.fullAddress}</div>
        {/if}

        {#if problem.entity.phone}
          <div data-icon-before="phone">{problem.entity.phone}</div>
        {/if}
        {#if problem.entity.emailAddress}
          <div data-icon-before="mail">{problem.entity.emailAddress}</div>
        {/if}
      </div>

      <div class="Content">
        <div class="Row Header">
          <div>Bid Status</div>
          <div>RFP</div>
          <div>Due Date</div>
          <div>Supplier Contact</div>
        </div>

        {#each problem.bids as bid}
          <div class="Row">
            <div>
              {bid.bidStatusFormatted}
              <div class="rbTooltip">
                <div>ID: <strong>{bid.bidId}</strong></div>
                <div>Status: <strong>{bid.bidStatusFormatted}</strong></div>
              </div>
            </div>

            <div>
              {bid.buyerRfpName}
              <div class="rbTooltip">{bid.buyerRfpName}</div>
            </div>

            <div>
              {bid.rfpDueDate}
              <div class="rbTooltip">{bid.rfpDueDate}</div>
            </div>

            <div data-icon-before="{bid.isUserIcon}">
              {`${bid.fullName} <${bid.emailAddress}>`}
              <div class="rbTooltip">{`${bid.fullName} <${bid.emailAddress}>`}</div>
            </div>
          </div>
        {/each}
      </div>
    </div>
  </DialogTemplate>
</div>

<style lang="stylus">.Component :global(.dialog) {
  background: #313c42;
  border-color: #313c42;
  overflow: hidden;
}
.Container {
  font-size: 14px;
  color: #cfd8dc;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
}
.Title {
  padding: 5px 10px;
  padding-bottom: 15px;
  font-size: 13px;
  border-bottom: 1px solid #546e7a;
}
.Title [data-icon-before] {
  display: flex;
}
.Title [data-icon-before]:before {
  margin-right: 5px;
  font-size: 16px;
}
.TitleHeading {
  font-weight: bold;
}
.TitleHeading:before {
  font-size: 24px;
}
.Content {
  overflow: auto;
  flex: 1 1 100%;
}
.Row {
  padding: 0 10px;
  display: grid;
  grid-template-columns: 200px 1fr 90px 1fr;
  height: 48px;
  align-items: center;
  position: relative;
}
.Row:nth-child(even) {
  background: rgba(255,255,255,0.03);
}
.Row div {
  white-space: nowrap;
  overflow: hidden;
  margin-right: 8px;
  text-overflow: ellipsis;
}
.Row.Header {
  position: sticky;
  top: 0;
  color: #fff;
  height: 42px;
  border-bottom: 1px solid #cfd8dc;
  background: #465055;
  z-index: 1;
}
</style>
