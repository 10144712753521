import entityProfile from 'root/api/entityProfile.js';
import {handle} from 'root/pages/hotels/profile/services/hotel.service';
import api from '../../api/end-points';

export function loadEntity(type, id) {
    return handle(entityProfile.loadEntity(type, id));
}

export function getCompanyAgency(id) {
    return api.getCompanyAgency(id);
}

export function updateBasicInfo(type, entityId, data) {
    return handle(entityProfile.updateBasicInfo(type, entityId, data));
}

export function loadUsers(id) {
    return entityProfile.loadUsers(id).then(({data: {users, invitations}}) => ({
        users,
        invitations: formatInvitations(invitations)
    }));

    function formatInvitations(invitations) {
        return invitations.map(invite => ({
            ...invite,
            invitation: true,
            role: {
                permission: invite.invitationMeta.role,
            }
        }));
    }
}

export function deleteRepresentatives(id, type, reps, replacementRepresentative) {
    return handle(api.deleteRepresentatives(id, type, {reps, replacementRepresentative}))
}

export function deactivateRepresentatives(id, type, reps, replacementRepresentative) {
    return handle(api.deactivateRepresentatives(id, type, {reps, replacementRepresentative}))
}

export function activateRepresentatives(id, type, reps) {
    return handle(api.activateRepresentatives(id, type, {reps}));
}

export function loadCompanies(id) {
    return api.loadCompanies(id);
}

export function getChain(id) {
    return api.getChain(id);
}

export function updateChainCode(id, code) {
  return handle(api.updateChainCode(id, code));
}
