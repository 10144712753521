import {writable, get} from 'svelte/store';
import allCountries from 'components/assets/data/countries.js';

const
  initialState = {
    countries: allCountries.map(c => {
      c.normalizedName = c.name.replace(/\s*\(.*?\)\s*/g, '');
      return c;
    }),
    ready: true,
  },
  store = writable(initialState);

export default store;

function getNormalizedNameByCode(code = ''){
  const s = get(store);
  const country = s.countries.find(c => c.id === code);
  return country ? country.normalizedName : '';
}

export const countriesService = {
  getNormalizedNameByCode
};
