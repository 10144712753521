<script>
    import Route from 'root/routes/Chains';
    import debounce from 'lodash/debounce';
    import get from 'lodash/get';
    import Header from 'root/components/Header.svelte';
    import Navigation from 'root/components/Navigation.svelte';

    import ResultsTable from 'root/pages/hotels/advanced-search/ResultsTable.component.svelte';
    import CheckBox from 'root/pages/hotels/advanced-search/Checkbox.component.svelte';
    import FiltersBar from 'root/pages/hotels/advanced-search/filters/SearchFilters.component.svelte';
    import Paginator from 'root/pages/hotels/advanced-search/Paginator.component.svelte';
    import Select from 'components/molecules/inputs/Select.svelte';
    import Actions from 'root/pages/hotels/advanced-search/actions/Actions.component.svelte';
    import DropDown from 'root/pages/hotels/profile/components/ActionsHelperDropDown.component.svelte';
    import Chain from './Chain.component.svelte';

    import {loadFilterTypes, searchChains} from './search.service';
    import {loadActions, createChain} from './actions.service';

    let filters = [],
        andFilters = true,
        promise = null,
        currentPage = {
            page: 1,
        },
        selectedChains = [],
        loading = false,
        sorter = {
            dir: 'asc',
            by: 'name-asc',
            key: 'name',
        },
        props = [
            {
                label: 'Type',
                get({subtype}) { return subtype === 'MASTER'? 'Master Chain': 'Brand'; }
            },
            {
                label: 'Code',
                key: 'code',
            },
            {
                label: 'Master Chain Code',
                get({master}) { return master? master.code: '---'; }
            },
            {
                label: 'Master Chain Name',
                get({master}) { return master? master.name: '---'; }
            },
        ],
        propWithSizes = [],
        creationActions = [
            {
                id: 'createBrand',
                label: `New Brand`,
                icon: 'add',
                action() { createChain('BRAND') },
            },
            {
                id: 'createChain',
                label: `New Master Chain`,
                icon: 'add',
                action: createChain,
            },
        ];

    const filterTypes = loadFilterTypes().then(types => {
        filters = types.filter(({id}) => ['subType', 'name', 'code'].includes(id));
        return types;
    });

    function clearFilters() {
        filters = [];
    }

    function applyFilters() {
        selectedChains = [];

        return search();
    }

    $: results = currentPage.data || [];
    $: allSelected = selectedChains.length > 0 && selectedChains.length === results.length;
    $: someSelected = selectedChains.length > 0 && selectedChains.length < results.length;
    $: moreActions = [
        {
            id: 'andOr',
            label: `Use ${andFilters? 'Or': 'And'} Logic`,
            icon: !andFilters? 'height': 'link',
            action() { andFilters = !andFilters },
            isAvailable() { return true; }
        },
        {
            id: 'clearFilters',
            label: `Clear All Filters`,
            icon: 'delete_sweep',
            action() { clearFilters(); },
            isAvailable() { return filters.length > 0; }
        },
    ].filter(({isAvailable}) => isAvailable());

    function search(page = 1) {
        loading = true;

        promise = searchChains(filters, andFilters, page).then((pagination) => {
            currentPage = pagination;
            loading = false;

            return pagination.data;
        });
    }

    function next() {
        search(currentPage.page + 1);
    }

    function prev() {
        search(currentPage.page - 1);
    }

    $:  propWithSizes = results.reduce((acc, chain) => {
            return acc.map(prop => {
                const value = prop.get? prop.get(chain): get(chain, prop.key, ''), size = Math.max(prop.size || 0, (value? value.length: 0) + prop.label.length);
                return {...prop, size};
            });
        }, props);
    $: sorters = (function() {
        const options = [
            {
                label: 'Name',
                value: 'name',
            },
            {
                label: 'Code',
                value: 'code',
            },
            {
                label: 'Type',
                value: 'subtype',
            },
            {
                label: 'Master Chain',
                value: 'masterChain.name',
            },
        ];

        return [
            {
                label: 'None',
                value: 'none',
                key: null,
            },
            ...options.reduce((acc, option) => [...acc, make(option), make(option, false)], [])
        ];

        function make({label, value, key}, asc = true) {
            const dir = asc? 'asc': "desc";
            return {
                label: `${label} - ${dir.toUpperCase()}`,
                value: `${value}-${dir}`,
                key: key || value,
                dir,
            }
        }
    })();
    $: selectedList = selectedChains.map(id => results.find(({_id}) => _id === id));
    $: actions = loadActions(selectedList);

    function toggleAllSelect() {
        if(!loading) {
            if(allSelected) {
                selectedChains = [];
            }else{
                selectedChains = results.map(({_id}) => _id);
            }
        }
    }

    function sort({ detail: by }){
        const option = sorters.find(({value}) => value === by);

        if(option) {
            const key = option.key,
                dir = option.dir;

            sorter = { by, ...option };
            promise = Promise.resolve(results);
        }
    }

    function actionCompleted({detail: {selected, id, action, response}}) {
        switch(id) {
            case "editLogo":
            case "changeChain":
            case "makeChain":
            case "delete":
                applyFilters();
                break;
            default:
                break;
        }
    }

    function selectChain({detail: {_id, selected}}) {
        if(selected) {
            selectedChains = selectedChains.filter(id => id !== _id);
        }else{
            selectedChains = [...selectedChains, _id];
        }
    }
</script>

<div class="rbPage rbDark">
    <Header></Header>
    <div class="pageContainer">
        <div class="rbCard navigation"><Navigation></Navigation></div>

        <div class="content">
            <div class="heading">
                {#if selectedChains.length === 0}
                    <div class="column page-label">
                        <i class="material-icons page-icon">search</i>
                        <div class="labels">
                            <h1 class="label-title">Advanced Chains Search</h1>
                            <p class="label-sub-title">Click on "filters" to see all filter options</p>
                        </div>
                    </div>
                {:else}
                    <div class="column">
                        <Actions {actions} selected={selectedList} on:completed={actionCompleted}/>
                    </div>
                {/if}

                <div class="column buttons">
                    <div class="sorter">
                        <Select id="sort by" options={sorters} value={sorter.by} iconStart="sort" on:input={sort}>Sort By</Select>
                    </div>
                    <DropDown actions={moreActions} execute={({action} )=> action()} align='right' label="More"/>
                    <DropDown actions={creationActions} execute={({action} )=> action()} align='right' icon="add" label="Create Chain" asDarkMain={true}/>
                    <Paginator results={currentPage} on:next={next} on:prev={prev} />
                </div>
            </div>
            <div class="table-header">
                <CheckBox indeterminate={someSelected} selected={allSelected} on:toggle={toggleAllSelect} />
                {#await filterTypes}
                    Loading...
                {:then types}
                    <FiltersBar bind:filters {andFilters} filterTypes={types} let:hasValues>
                        <button class="rbButton asDarkMain small searchButton" data-icon="search" on:click={applyFilters} disabled={loading}>Search</button>
                    </FiltersBar>
                {/await}
            </div>
            <ResultsTable {promise} {sorter}>
                <div slot="item" let:item>
                    <Chain
                        chain={item}
                        {selectedChains}
                        props={propWithSizes}
                        on:select={selectChain}
                    />
                </div>
            </ResultsTable>
        </div>
    </div>
</div>

<style type="text/stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.page-label {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
i.material-icons.page-icon {
  color: #00b8ab;
  height: 40px;
  width: 40px;
  background: #273238;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.labels {
  margin-left: 10px;
}
h1.label-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
p.label-sub-title {
  margin: 0;
  font-size: 10px;
}
.table-header {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #00a995 0%, #006eb9 50%, #00aa4f 100%) 0 1 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.buttons > :global(*) {
  flex-shrink: 0;
}
.sorter {
  display: inline-block;
  min-width: 200px;
  margin-right: 10px;
}
.sorter :global(.rbInput .container) {
  margin-top: 0;
}
.sorter :global(.rbInput .input) {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.small {
  height: 30px;
  font-size: 12px;
}
.small::before {
  font-size: 18px;
}
.searchButton {
  z-index: 1;
}
</style>
