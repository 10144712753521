import EntityProfileRoute from 'root/routes/EntityProfile';
import Notification from 'components/modals/Notification.basic.svelte';
import modals from 'root/services/modals';
import entityProfile from 'root/api/entityProfile.js';
import EditHotelImageDialog from 'root/pages/hotels/advanced-search/dialogs/EditImage.dialog.svelte';
import {handle} from 'root/pages/hotels/profile/services/hotel.service';

const actions = [
    {
        id: 'editImage',
        label: 'Edit Logo',
        icon: 'image',
        isAvailable(entities) {
            return entities.length === 1;
        },
        action([entity]) {
            return editLogo(entity);
        },
        primary: true
    },
    {
        id: 'ViewProfile',
        label: 'Profile',
        icon: 'open_in_new',
        isAvailable(entities) {
            return entities.length === 1;
        },
        action([entity]) {
            return openProfile(entity);
        },
        primary: true
    },
    {
        id: 'delete',
        label: 'Mark Obsolete',
        icon: 'delete',
        isAvailable(entities) {
            return entities.length > 0;
        },
        action: deleteEntities,
    },
    {
        id: 'validate',
        label: 'Validate',
        icon: 'check_circle',
        isAvailable(entities) {
            return entities.length > 0 && entities.every(({status: {value}}) => value !== 'ACTIVE');
        },
        action(entities) {
            return validateEntities(entities);
        },
        primary: true
    },
];

export function loadActions(selected) {
    return actions.filter(({isAvailable})=>isAvailable(selected));
}

export function editLogo(entity) {
    return modals.show(EditHotelImageDialog, {hotel: entity, action: 'logo'})
        .then(({id, image}) => handle(entityProfile.setLogo(id, entity.type, image)))
        .then(logo => {
            modals.show(Notification, { title: 'Saved!', subtitle:'Entity Logo', content: 'Logo was updated successfully!', resolveLabel: 'OK' })
            return {...entity, logo};
        });
}

export function validateEntities(entities) {
    const {type} = entities[0];
    return handle(entityProfile.validateEntities(type, entities.map(({_id, id}) => _id || id)));
}

export function openProfile({_id, id, type}, target='_blank') {
    return Promise.resolve(window.open(EntityProfileRoute.href({entityId: _id || id, section: 'basic-info', type}), target));
}

export async function deleteEntities(entities) {
    await modals.show(Notification, {
        title: 'Warning!',
        subtitle:`Delete entit${entities.length > 1? 'ies': 'y'}?`,
        content: `${entities.length > 1? 'These entities': 'This entity'} will be hidden from users on the site.`,
        resolveLabel: 'Yes, Continue',
        rejectLabel: 'No, Thanks'
    });
    return handle(entityProfile.makeObsolete(entities[0].type, entities.map(chain => chain._id || chain.id)))
            .then(res => {
                modals.show(Notification, {
                    title: `Entit${entities.length > 1? 'ies': 'y'} Now Obsolete`,
                    subtitle:'Success',
                    content: `Entit${entities.length > 1? 'ies': 'y'} were successfully marked as obsolete `,
                    resolveLabel: 'Ok'
                });

                return res;
            });
}
