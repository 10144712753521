import {
    FREE_TEXT_FILTER_TYPE,
    SINGLE_SELECT_FILTER_TYPE,
    MULTI_SELECT_FILTER_TYPE,
} from 'root/pages/hotels/advanced-search/filters/filter-types';

export default function getFilter(masterChains) {

    return Object.freeze([
        {
            label: `Name`,
            type: FREE_TEXT_FILTER_TYPE,
            id: 'name',
            value: '',
        },
        {
            label: 'Code',
            type: FREE_TEXT_FILTER_TYPE,
            id: 'code',
            value: '',
        },
        {
            label: 'Type',
            type: SINGLE_SELECT_FILTER_TYPE,
            id: 'subType',
            value: '',
            options: [
                {
                    value: 'MASTER',
                    label: 'Master Chain',
                },
                {
                    value: 'BRAND',
                    label: 'Brand',
                },
            ],
        },
        {
            label: 'Master Chain',
            type: MULTI_SELECT_FILTER_TYPE,
            id: 'masterChain',
            value: [],
            options: masterChains,
        },
    ]);
}