<script>
  import InputModel from "./InputModel.svelte";
  import SInput from "./Input.svelte";

  export let name = "";
  export let id = name;
  export let disabled;
  export let readonly;
  export let on = [];
  export let iconStart;
  export let iconEnd;
  export let format;

  // setup focus fn
  let elInput;

  // setup validations
  export let required;
  export let maxLength;
  export let minLength;
  export let pattern;
</script>

<InputModel {id}
            {name}
            let:value="{value}"
            {on}
            {required}
            {maxLength}
            {minLength}
            {pattern}
            let:error="{error}">
  <SInput {id}
          {name}
          {value}
          {format}
          {on}
          {iconStart}
          {iconEnd}
          {readonly}
          {disabled}
          {required}
          {error}
          bind:this="{elInput}">
    <slot />
  </SInput>
</InputModel>
