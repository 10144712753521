<script>
    import set from 'lodash/set';
    import isNil from 'lodash/isNil';
    import noop from 'lodash/noop';
    import { addHotel } from 'root/api';
    import httpNotifications from 'root/api/HttpNotifications';
    import modals from 'root/services/modals';
    import Notification from 'components/modals/Notification.basic.svelte';
    import Home from '../../routes/Home.js';
    import {viewProfile} from './advanced-search/actions.service.js';

    import CreateForm from './CreateForm.component.svelte';

    export let addHotelView;

    let image;

    function onSubmit({detail}){
        const request = prepareSaveRequest(detail);
        httpNotifications
                .handle(addHotel(image, request), { onError: { '400': e => modals.show(Notification, { type: 'warning', title: 'Not Saved!', subtitle:'Property', content: e.data.message, rejectLabel: 'OK' })}})
                .then(({data}) => {
                    return modals.show(Notification, { title: 'Saved!', subtitle:'Property', content: 'Property was saved!', resolveLabel: 'OK' })
                            .then(() => viewProfile(data, '_self'));
                })
                .catch( noop );
    }

    function prepareSaveRequest(values){
        return Object.keys(values).reduce( (acc, key) => {
            const v = values[key];

            if(!isNil(v)){
                set(acc, key, v);
            }

            return acc;
        }, {});
    }
</script>

<CreateForm on:submit="{onSubmit}" bind:addHotelView bind:image />
