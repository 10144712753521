<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let value = "", editing = false;

  $: placeholder = editing? 'Edit comment': 'Add comment';

  function addComment() {
    dispatch('add', value);
    value = ''
  }
</script>

<div class="add-comment">
  <textarea name="comment" bind:value {placeholder} />
  <button
    class="rbButton asDarkMain"
    disabled={!value}
    on:click={addComment}>
    {editing? 'Update': 'Add' }
  </button>
</div>

<style lang="scss">.add-comment {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: sticky;
  bottom: 10px; }

.add-comment textarea {
  flex: 1;
  resize: initial;
  height: 50px;
  font-size: 15px;
  outline: none;
  border-radius: 3px;
  padding: 5px;
  box-sizing: border-box; }</style>
