<script>
  import SearchSelect from 'components/molecules/inputs/SearchSelect.svelte';

  export let brands, required = false, selected, onSelect = () => {}, error;

  async function onSearch (query = '', optionsPr) {
    const
      options = await optionsPr,
      qParts = query.trim().toLowerCase().split(' ') || [];

    if(qParts.length){
      return Promise.resolve(options.filter(o => {
        let parts = o.$parts;
        if(!parts) {
          parts = [];
          o.$parts = parts;

          if(o.brandCode) parts.push(o.brandCode);
          if(o.brandName) parts.push(o.brandName);
          if(o.masterName) parts.push(o.masterName);
          parts.push(...o.label.toLowerCase().split(' '));
        }
        return qParts.every(q => parts.some(p => p.startsWith(q)));
      }));
    } else {
      return Promise.resolve(options);
    }
  }


</script>

<SearchSelect optionsPromise="{Promise.resolve(brands)}"
              {required}
              {selected}
              {onSelect}
              {onSearch}
              {error}
>
  Hotel Brand
</SearchSelect>
