import { Route } from '@readybid-team/rb-spa-router';
import Mounter from '@readybid-team/rb-spa-router-svelte-mount';
import GeneralErrorPage from 'components/pages/ServerErrorPage.svelte';
import Home from './Home';

const generalErrorPage = new Route('GeneralErrorPage', '/error',
  Mounter(
    () => Promise.resolve({default: GeneralErrorPage} ),
    () => ({
      goToHome: () => { Home.go(); }
    })
  ));

export default generalErrorPage;
