<script>
  import {LOADING_STATE, NO_CONTACTS_STATE, LIST_STATE, FORM_STATE, default as store, editContact} from './SelectContact.store';
  import Contact from './SelectContactDialogContactListItem.svelte';
  import ContactForm from './SelectContactDialogContactForm.svelte';
  import DialogTemplate from 'components/modals/DialogTemplate.basic.svelte';
  import Loader from 'src/components/Loader.svelte';

  export let cancel, setContact, saveContact, canSend;

  let isEmailAddressStatusFailed = true;

  $: isEmailAddressStatusFailed = $store.listModel && $store.listModel.emailAddressStatusFailed || null;

  function setContactFromList(shouldSend){
    setContact($store.bid.id, $store.listModel, shouldSend);
  }
</script>

<div class="Component">
  <DialogTemplate {cancel} closeOnClick="{false}">
    <div class="Container">
      <div class="Title">{$store.bid.hotelName}</div>

      {#if $store.state === LOADING_STATE}
        <div class="LoaderContainer">
          <Loader />
        </div>

      {:else if $store.state === NO_CONTACTS_STATE}
        <div class="NoContactsContainer" >
          <div class="Header">
            <div>
              <div><b>Select Hotel Representative</b></div>
              <div>or Create a new one</div>
            </div>

            <div>
              <button type="button" class="rbButton asDark"
                      data-icon="person_add"
                      on:click="{() => editContact({})}">
                New Hotel Rep
              </button>
            </div>
          </div>

          <div class="NoContacts">
            <div>
              <h3>No Contacts</h3>
              <p>No Contacts have been added yet. Create a new one to send this bid.</p>
            </div>
          </div>

          <div class="ActionsContainer">
            <button class="rbButton asDark" on:click={() => cancel()}>Cancel</button>
          </div>
        </div>

      {:else if $store.state === FORM_STATE}
        <ContactForm {cancel} {saveContact} {canSend}/>

      {:else if $store.state === LIST_STATE}
        <div class="ListContainer">
          <div class="Header">
            <div>
              <div><b>Select Hotel Representative</b></div>
              <div>or Create a new one</div>
            </div>

            <div>
              <button type="button" class="rbButton asDark"
                      data-icon="person_add"
                      on:click="{() => editContact({})}">
                New Hotel Rep
              </button>
            </div>
          </div>

          <div class="List">
            {#each $store.contacts as contact}
              <Contact {contact} selected="{false}" />
            {/each}
          </div>

          <div class="ActionsContainer">
            <div>
              {#if canSend}
                <button class="rbButton asDark"
                        disabled="{isEmailAddressStatusFailed}"
                        on:click="{() => setContactFromList(true)}">
                  Save and Send
                </button>
              {/if}
            </div>


            <div>
              <button class="rbButton asDark" on:click="{() => cancel()}">Cancel</button>
              <button class="rbButton asDarkMain"
                      data-icon="save"
                      disabled="{isEmailAddressStatusFailed}"
                      on:click={() => setContactFromList()}>
                Save
              </button>
            </div>
          </div>
        </div>
      {/if}
    </div>
  </DialogTemplate>
</div>

<style lang="stylus">.Component :global(.dialog) {
  background: #313c42;
  border-color: #313c42;
  overflow: hidden;
}
.Container {
  font-size: 14px;
  color: #cfd8dc;
  min-height: 280px;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
}
.Title {
  padding: 5px 10px;
}
.LoaderContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.NoContactsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
}
.Header {
  flex: 0;
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
}
.NoContacts {
  border: 1px solid #cfd8dc;
  border-radius: 6px;
  flex: 1;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.ActionsContainer {
  flex: 0;
  display: flex;
  justify-content: space-between;
  text-align: right;
  margin: 15px 0 10px;
}
.ListContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 5px 10px;
}
.List {
  flex: 1;
  border: 1px solid #cfd8dc;
  border-radius: 6px;
  overflow: auto;
}
</style>
