<script>
  import noop from 'lodash/noop';
  import DialogTemplate from 'components/modals/DialogTemplate.basic.svelte';
  import {setRepresentative, markEmailAsUnfixable} from './emailAddressFixServiceApi.js';
  import RepresentativeForm from './RepresentativeForm.svelte';
  import RepresentativesList from './RepresentativesList.svelte';
  import {getIsUserIcon} from './representatives';
  import modalsService from 'src/services/modals';
  import ErrorsModal from "src/components/ErrorsModal.svelte";
  import Spinner from 'components/modals/Spinner.svelte';
  import Notification from 'components/modals/Notification.basic.svelte';
  import httpNotifications from 'src/api/HttpNotifications';

  export let
    hide,
    cancel,
    problem;

  let
    defaultModel = {
      isUser: false,
      userAccountId: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      jobTitle: '',
      phone: '',
    },
    model = {...defaultModel},
    invalid = false,
    setValues,
    untouchAll,
    touchAll;

  function selectRepresentative(rep){
    setValues({
      isUser: rep.isUser,
      userAccountId: rep.id,
      firstName: rep.firstName,
      lastName: rep.lastName,
      emailAddress: rep.emailAddress,
      jobTitle: rep.jobTitle || '',
      phone: rep.phone || '',
    });
    touchAll();
  }


  function fixEmailAddress(){
    modalsService.show(Spinner);
    setRepresentative({
      bidsIds: problem.bidsIds,
      badEmailAddress: problem.emailAddress,
      entityId: problem.entityId,
      replacementUserAccountId: model.userAccountId,
      isReplacementAUser: model.isUser,
      replacementFirstName: model.firstName,
      replacementLastName: model.lastName,
      replacementEmailAddress: model.emailAddress,
      replacementPhone: model.phone ? model.phone : undefined,
      replacementJobTitle: model.jobTitle ? model.jobTitle : undefined
    })
      .then(() => {
        modalsService
          .hide()
          .then(hide)
      })
      .catch(e => {
        modalsService
          .hide()
          .then(() =>
        modalsService.show(ErrorsModal, {
          message: `Failed to apply fixes`,
          error: e && e.data && e.data.message || null,
        }))
      })
  }


  function newContact(){
    untouchAll();
    setValues({...defaultModel});
  }

  function markAsUnfixable(){
    return confirm()
      .then(markAsUnfixable)
      .catch(noop);

    function confirm(){
      return modalsService.show(Notification, {
        type: 'warning',
        subtitle: 'Warning',
        title: 'Mark Email as Unfixable?',
        content: 'Are you sure you want to mark this email as unfixable? (this action cannot be undone - if it makes sense, please try resending email from users bid manager)',
        resolveLabel: 'Yes',
        rejectLabel: 'No'
      });
    }

    function markAsUnfixable(){
      return httpNotifications.handle(markEmailAsUnfixable(problem.failureEmailId))
        .then(hide)
        .catch(e => {
          console.error(e);
          modalsService.show(ErrorsModal, {
            message: `Failed to apply fixes`,
            error: e && e.data && e.data.message || null,
          });
          return Promise.reject();
        });
    }
  }

</script>

<div class="Component">
  <DialogTemplate cancel="{cancel}" closeOnClick="{false}" wide="{true}">
    <div class="Container" style="min-height: 600px">
      <div class="Title">
        <div class="TitleMain">
          <div data-icon-before="{problem.isUserIcon}"
               class="TitleHeading">{problem.fullName} {`<${problem.emailAddress}>`}</div>
          <div data-icon-before="{problem.entityTypeIcon}"
               class="TitleHeading">{problem.entity.name}</div>
          {#if problem.entity.location && problem.entity.location.fullAddress}
            <div data-icon-before="map">{problem.entity.location.fullAddress}</div>
          {/if}

          {#if problem.entity.phone}
            <div data-icon-before="phone">{problem.entity.phone}</div>
          {/if}
          {#if problem.entity.emailAddress}
            <div data-icon-before="mail">{problem.entity.emailAddress}</div>
          {/if}
        </div>

        {#if problem.problemType === 'email'}
          <div class="TitleActions">
            <button class="rbButton asModalError asSmall" on:click={markAsUnfixable}>Mark as unfixable</button>
          </div>
        {/if}
      </div>

      <div class="Content">
        <div class="Representatives">
          <div class="SectionTitle">List of Representatives</div>

          <RepresentativesList entityId="{problem.entityId}" {selectRepresentative} />
        </div>

        <div class="FormContainer">
          <div class="SectionTitle">
            <div data-icon-before="{getIsUserIcon(model.isUser)}">
              {model.isUser ? 'USER SELECTED' : 'EDIT CONTACT'}
            </div>

            <div>
              <button class="rbButton asDark asSmall" on:click={newContact}>New Contact</button>
            </div>
          </div>

          <RepresentativeForm bind:values={model}
                              bind:invalid
                              bind:setValues
                              bind:untouchAll
                              bind:touchAll
                              {cancel}
                              {fixEmailAddress} />
        </div>
      </div>
    </div>
  </DialogTemplate>
</div>

<style lang="stylus">.Component :global(.dialog) {
  background: #313c42;
  border-color: #313c42;
  overflow: hidden;
}
.Container {
  font-size: 14px;
  color: #cfd8dc;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
}
.Title {
  padding: 5px 10px;
  padding-bottom: 15px;
  font-size: 13px;
  border-bottom: 1px solid #546e7a;
  display: flex;
}
.Title [data-icon-before] {
  display: flex;
}
.Title [data-icon-before]:before {
  margin-right: 5px;
  font-size: 16px;
}
.TitleMain {
  flex: 1 1 100%;
}
.TitleActions {
  flex: 0 0 auto;
  padding-top: 40px;
  margin-top: auto;
}
.TitleHeading {
  font-weight: bold;
}
.TitleHeading:before {
  font-size: 24px;
}
.Content {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.Representatives {
  flex: 1 1 100%;
  border-right: 1px solid #546e7a;
  display: flex;
  flex-direction: column;
}
.FormContainer {
  flex: 0 0 400px;
}
.SectionTitle {
  font-size: 14px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
