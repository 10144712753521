<!--suppress ES6UnusedImports -->
<script>
  import SInput from "../inputs/Input.svelte";
  import Spinner from "../../atoms/Spinner.svelte";
  import { fade } from 'svelte/transition';
  import { createEventDispatcher } from 'svelte';
  import noop from 'lodash/noop';
  import { debouncedGooglePlacesAutocomplete, GoogleMapsGeocode } from '../../utils/google/googleProvider';
  import { parseGooglePlaces } from '../../utils/google/googlePlaceParser';

  export let value = {},
          canAddManually = false;

  let
          focused,
          isSearchEmpty = true,
          results = [],
          viewValue = value && value.fullAddress;

  const
          dispatch = createEventDispatcher(),
          inputElementListeners = [
            ['focus', () => focused = true ],
            ['blur', () => focused = false],
            ['input', ev => {
              const v = ev.target.value;
              isSearchEmpty = !v;
              results = getPredictions(v)
            }]
          ],
          getPredictions = (() => {
            const autoComplete = debouncedGooglePlacesAutocomplete(300);
            return value => value ? autoComplete({ input: value, types: [ 'address' ]}).then( r => r || []) : [];
          })();

  function onSelect(prediction){
    GoogleMapsGeocode({'placeId': prediction.place_id})
            .then( parseGooglePlaces )
            .then( results => {
              value = results;
              value.place_id = prediction.place_id;
              viewValue = value.fullAddress;
              dispatch('input', value);
              dispatch('change', value);
            })
            .catch(noop)
  }
</script>

<div class="addressSearch">
  <SInput iconStart='location_on'
          value={viewValue}
          on={inputElementListeners}>
    <slot>
      <div>
        Search Address
        {#await results}
          <div class="spinner"><Spinner /></div>
        {:then result}
          <span></span>
        {/await}
      </div>
    </slot>
  </SInput>

  {#if focused && !isSearchEmpty}
    <div transition:fade="{{ duration: 100 }}" class="Menu">
      {#await results}
        <div class="menuSpinner">
          <Spinner />
        </div>

      {:then predictions}
        {#each predictions as prediction (prediction.place_id)}
          <div class="Item"
               class:ItemSelected="{value && prediction.place_id === value.place_id}"
               on:mousedown="{() => onSelect(prediction)}">
            {prediction.description}
          </div>
        {:else}
          <div class="emptyResults rbText">
            We are unable to find an address that matches your search.
          </div>
        {/each}

      {:catch e}
        <div class="rbError">{e}</div>
      {/await}

      {#if canAddManually}
        <div>
          <button class="rbButton asDarkMain" on:click="{ () => dispatch('addManually') }">
            Add Manually
          </button>
        </div>
      {/if}
    </div>
  {/if}
</div>

<style type="text/stylus">.Menu {
  position: absolute;
  z-index: 10;
  margin-top: 3px;
  padding: 5px 0;
  max-height: 200px;
  overflow-y: auto;
  background: #263238;
  border: 1px solid #1a2226;
  border-radius: 5px;
  opacity: 1;
}
.Item {
  font-size: 14px;
  line-height: 33px;
  padding: 0 30px 0 15px;
  cursor: pointer;
  text-align: left;
  color: #90a4ae;
  white-space: nowrap;
  background: transparent;
}
.Item:hover {
  background: #313c42;
}
.ItemSelected {
  color: #fff;
  background: #313c42;
  cursor: default;
}
.menuSpinner {
  padding: 15px 50px;
  transform: scale(0.5, 0.5);
}
.emptyResults {
  padding: 15px;
  color: #fff;
}
.spinner {
  display: inline-block;
  vertical-align: middle;
}
.spinner :global(svg) {
  height: 12px;
  width: 12px;
}
</style>
