<script>
  import Input from 'components/molecules/inputs/Input.svelte';
  import Menu from 'components/organisms/Menu/Menu.svelte';
  import {default as store, service} from './entity.store';

  let searchQuery = $store.query, loading;

  $: service.search(searchQuery);

  function setEntityType(type){
    service.setEntityType(type);
    searchQuery = '';
  }

  async function loadMore(){
    loading = true;
    await service.loadMore();
    loading = false;
  }

</script>

<div class="EntitySelector">
  <div class="EntitySearch">
    <div class="Menu">
      <Menu on:change={ev => setEntityType(ev.detail)}
            options="{$store.entityOptions}"
      >
        <span data-icon-after="expand_more">{$store.entityType.label}</span>
      </Menu>
    </div>

    <Input name="search" bind:value="{searchQuery}">{$store.entityType.searchPlaceholder}</Input>
  </div>

  {#if $store.entities.length}
    <div class="EntityList">
      <div class="EntityListHeading">Select entity to manage its Users</div>
      {#each $store.entities as e}
        <div class="Entity" on:click={() => service.selectEntity(e)}>
          {#if e.accountSuspended}<div class="AccountSuspended">Account Suspended</div>{/if}
          <div class="UserAccounts">User Accounts: <span>{e.userAccountsCount}</span></div>
          <div class="EntityName">
            {e.name}
            {#if e.type}<span>({e.type})</span>{/if}
          </div>
          {#if e.address}<div>{e.address}</div>{/if}
          {#if e.sabreCode}<div class="EntityDetail">Sabre Code: <span>{e.sabreCode}</span></div>{/if}
          {#if e.brandName}<div class="EntityDetail">Brand: <span>{e.brandName} {e.chainName}</span></div>{/if}
          {#if e.agencyName}<div class="EntityDetail">Agency: <span>{e.agencyName}</span></div>{/if}
        </div>
      {/each}
      {#if $store.hasMore && !loading}
        <div class="Entity LoadMore" on:click={loadMore}>LOAD MORE</div>
      {/if}
    </div>
  {/if}
</div>

<style lang="stylus">.EntitySelector {
  flex: 1 1 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.EntitySearch {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
.Menu {
  margin: 10px 10px 0 0;
}
.EntityList {
  flex: 1 1 100%;
  overflow: auto;
  position: relative;
}
.EntityListHeading {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #313c42;
  color: #fff;
  font-size: 14px;
  padding: 5px 10px;
  border-bottom: 1px solid #00a99d;
}
.Entity {
  padding: 10px;
  background: #263238;
  border: 1px solid #78909c;
  border-radius: 6px;
  font-size: 13px;
  color: #b0bec5;
  width: 600px;
  margin: 10px auto;
  cursor: pointer;
}
.Entity:hover {
  background: #16272b;
  border: 1px solid #00a99d;
}
.Entity.LoadMore {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}
.EntityName {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  text-decoration: underline;
}
.EntityName span {
  font-size: 13px;
  color: #b0bec5;
}
.EntityDetail span {
  color: #fff;
}
.UserAccounts span {
  color: #fff;
  font-size: 15px;
}
.AccountSuspended {
  color: #e65622;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
