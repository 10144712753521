<script>
  import BrandsSearch from './BrandsSearch.svelte';
  import {getContext} from "svelte";
  import * as Validators from 'components/validation/validators';

  export let
    brands,
    id,
    name,
    required;

  let
    formModel = getContext('formModel'),
    selectedId,
    selected,
    model,
    error;

  formModel.addModel({ id, name, validations: createValidations() });

  $: selectedId = $formModel.values[id];
  $: selected = brands.find(b => b.value === selectedId);
  $: model = $formModel.models[id] || {};
  $: error = model.dirty ? model.errorMessage : '';

  function onSelect(item){
    if(item && item.value){
      formModel.setValue(id, item.value);
    } else {
      formModel.setValue(id, '')
    }
  }

  function createValidations() {
    const v = [];
    if(required) v.push(Validators.Required.create(required));
    return v;
  }

</script>

<BrandsSearch {brands} {required} {selected} {onSelect} {error}/>
