<script>
  import NotificationTemplate from "./NotificationTemplate.svelte";
  import ModalCard from "./ModalCard.svelte";

  export let wide = false;
  export let width = wide ? 960 : 500;
</script>

<NotificationTemplate>
  <ModalCard {width}>
    <slot></slot>
  </ModalCard>
</NotificationTemplate>
