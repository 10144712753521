<script>
  import DialogTemplate from 'components/modals/DialogTemplate.basic.svelte';
  import PHOTO_NOT_AVAILABLE from 'images/no-hotel-photo-available.gif'
  import get from 'lodash/get';
  import amenities from 'root/assets/data/hotel-amenities';

  export let cancel, hotel = {}, website, gdsCodes;

  let
    image,
    chain,
    sortedAmenities,
    propertyAmenities,
    roomAmenities,
    paymentAmenities;

  $: image = `gray url(${getImage(get(hotel, 'image.url'))}) center center no-repeat`;
  $: chain = get(hotel, 'chain.name');
  $: sortedAmenities = amenities.sort((a,b) => a.label.localeCompare(b.label))
  $: propertyAmenities = sortedAmenities.filter(a => a.type === 'property' && !a.important);
  $: roomAmenities = sortedAmenities.filter(a => a.type === 'room' && !a.important);
  $: paymentAmenities = sortedAmenities.filter(a => a.type === 'payment' && !a.important);

  function getImage(image) {
    return image ? (image.startsWith('http') ? image : `/images/entities/${image}`): PHOTO_NOT_AVAILABLE;
  }
</script>

<div class="Component">
  <DialogTemplate {cancel} closeOnClick="{false}" width="560">
    <div class="DialogContainer">
      <div class="LeftColumn">

        <div class="Heading" style="background: {image}; background-size: cover">
          <div class="Title TitleDrop">
            <div><b>{hotel.name}</b></div>
            <div>{chain}</div>
          </div>
        </div>

        <div class="Section">
          <div class="Ratings">
            <div class="label">Rating: {hotel.rating  || 'NA'}</div>

            <div class="label">Market Tier: {hotel.marketTier || 'NA'}</div>
          </div>

          <div class="Detail" data-icon-before="place">{hotel.location.fullAddress}</div>
        </div>

        <div class="Section">
          {#if website}
            <div class="Detail" data-icon-before="public">
              <a class="Link" target="_blank" href="{website}">website</a>
            </div>
          {/if}

          {#if hotel.emailAddress}
            <div class="Detail" data-icon-before="mail">
              {hotel.emailAddress || 'NA'}
            </div>
          {/if}

          {#if hotel.phone}
            <div class="Detail" data-icon-before="phone">
              {hotel.phone || 'NA'}
            </div>
          {/if}
        </div>

        {#if gdsCodes}
          <div class="Section">
            <div class="SectionTitle">GDS Codes:</div>

            {#if gdsCodes.amadeusCode}
              <div class="Detail">
                <span>Amadeus: </span><strong>{gdsCodes.amadeusCode || 'NA'}</strong>
              </div>
            {/if}

            {#if gdsCodes.apolloCode}
              <div class="Detail">
                <span>Apollo/Galileo: </span><strong>{gdsCodes.apolloCode || 'NA'}</strong>
              </div>
            {/if}

            {#if gdsCodes.sabreCode}
              <div class="Detail">
                <span>Sabre: </span><strong>{gdsCodes.sabreCode || 'NA'}</strong>
              </div>
            {/if}

            {#if gdsCodes.worldspanCode}
              <div class="Detail">
                <span>WorldSpan: </span><strong>{gdsCodes.worldspanCode || 'NA'}</strong>
              </div>
            {/if}

            {#if gdsCodes.readyBidCode}
              <div class="Detail">
                <span>ReadyBid: </span><strong>{gdsCodes.readyBidCode || 'NA'}</strong>
              </div>
            {/if}

            {#if gdsCodes.internalCode}
              <div class="Detail">
                <span>Internal: </span><strong>{gdsCodes.internalCode || 'NA'}</strong>
              </div>
            {/if}
          </div>
        {/if}

      </div>

      <div class="RightColumn">

        <div class="Title" data-icon-before="local_offer">
          <b>List of Amenities</b>
        </div>

        {#if !hotel.amenities}
          <div class="Section">No data</div>

        {:else}
          <div class="Section AmenitiesImportant">
            <div class="AmenityImportant"
                 class:AmenityImportantActive="{hotel.amenities.indexOf('wifi')>-1}"
                 data-icon-before="wifi"
            >
              WiFi
            </div>

            <div class="AmenityImportant"
                 class:AmenityImportantActive="{hotel.amenities.indexOf('breakfast')>-1}"
                 data-icon-before="free_breakfast">
              Breakfast
            </div>

            <div class="AmenityImportant"
                 class:AmenityImportantActive="{hotel.amenities.indexOf('parking')>-1}"
                 data-icon-before="local_parking">
              Parking
            </div>

            <div class="AmenityImportant"
                 class:AmenityImportantActive="{hotel.amenities.indexOf('airportShuttle')>-1}"
                 data-icon-before="airport_shuttle">
              Airport Shuttle
            </div>

            <div class="AmenityImportant"
                 class:AmenityImportantActive="{hotel.amenities.indexOf('fitness')>-1}"
                 data-icon-before="fitness_center">
              Fitness
            </div>

            <div class="AmenityImportant"
                 class:AmenityImportantActive="{hotel.amenities.indexOf('businessCenter')>-1}"
                 data-icon-before="business_center">
              Business Center
            </div>
          </div>

          <div class="Section Amenities">
            {#each propertyAmenities as a}
              <div class="Amenity"
                   class:AmenityActive="{hotel.amenities.indexOf(a.id)>-1}"
                   data-icon-before="not_interested">
                {a.label}
              </div>
            {/each}

            {#each roomAmenities as a}
              <div class="Amenity"
                   class:AmenityActive="{hotel.amenities.indexOf(a.id)>-1}"
                   data-icon-before="not_interested">
                {a.label}
              </div>
            {/each}

            {#each paymentAmenities as a}
              <div class="Amenity"
                   class:AmenityActive="{hotel.amenities.indexOf(a.id)>-1}"
                   data-icon-before="not_interested">
                {a.label}
              </div>
            {/each}
          </div>
        {/if}
      </div>
    </div>
  </DialogTemplate>
</div>

<style lang="stylus">.Component :global(.dialog) {
  background: #313c42;
  border-color: #313c42;
  overflow: hidden;
}
.DialogContainer {
  font-size: 14px;
  color: #cfd8dc;
  min-height: 400px;
  max-height: 60vh;
  display: flex;
}
.LeftColumn {
  flex: 0 0 50%;
}
.Heading {
  width: 280px;
  height: 180px;
}
.Title {
  padding: 6px 8px;
  font-size: 13px;
  line-height: 15px;
  color: #fff;
  text-transform: uppercase;
}
.Title:before {
  margin-right: 10px;
}
.TitleDrop {
  height: 99px;
  background: linear-gradient(to bottom, rgba(6,18,20,0.6) 23.06%, rgba(55,71,79,0) 79.79%);
}
.Section {
  padding: 0 5px;
  border-bottom: 1px solid #cfd8dc;
  font-size: 13px;
  line-height: 15px;
  display: flex;
  flex-direction: column;
}
.Section:last-of-type {
  border-color: transparent;
}
.SectionTitle {
  margin: 10px 0;
  font-size: 14px;
  font-weight: bold;
}
.Ratings {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 10px;
}
.Detail {
  margin-top: 5px;
}
.Detail:before {
  margin-right: 5px;
}
.Detail:last-of-type {
  margin-bottom: 5px;
}
.Link {
  color: inherit;
}
.RightColumn {
  flex: 0 0 50%;
  border-left: 1px solid #cfd8dc;
  display: flex;
  flex-direction: column;
}
.AmenitiesImportant {
  line-height: 1;
  padding-right: 5px;
  box-sizing: border-box;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}
.AmenityImportant {
  display: block;
  height: 60px;
  width: 45px;
  padding: 5px 0;
  box-sizing: border-box;
  font-size: 9px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #72828a;
}
.AmenityImportant:before {
  font-size: 24px;
  display: block;
}
.AmenityImportantActive {
  color: #00b3a6;
}
.Amenities {
  overflow-y: auto;
}
.Amenity {
  margin: 3px 0;
  font-size: 12px;
  line-height: 15px;
  color: #72828a;
}
.Amenity:before {
  margin-right: 5px;
  font-size: 18px;
}
.AmenityActive {
  color: #00b3a6;
}
.AmenityActive:before {
  content: 'check_circle' !important;
}
</style>
