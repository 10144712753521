<script>
  import {default as store, service} from './entity.store';

  import EntitySearch from 'src/pages/users/manager/tabs/by-entity/EntitySearch.svelte';
  import EntityView from 'src/pages/users/manager/tabs/by-entity/EntityView.svelte';

  service.initialize();
</script>

<div class="Container">
  {#if $store.showSearch}
    <EntitySearch />
  {:else}
    <EntityView />
  {/if}
</div>

<style lang="stylus">.Container {
  flex: 1 1 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
</style>
