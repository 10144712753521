<script>
    import Spinner from 'components/atoms/Spinner.svelte';
    import {orderBy} from 'lodash';

    export let promise = null;
    export let sorter = {};

    function sort(hotels) {
        return sorter && sorter.key? orderBy(hotels, [sorter.key], [sorter.dir]): hotels
    }
</script>

<div class="hotels-list">
    {#if promise}
        {#await promise}
            <div class="empty">
                <Spinner />
            </div>
        {:then hotels}
            <slot name="head"></slot>
            {#each sort(hotels) as item}
                <slot name="item" {item}></slot>
            {:else}
                <div class="empty">There were no results found for your search</div>
            {/each}
        {/await}
    {/if}
</div>

<style type="text/stylus">.hotels-list {
  height: calc(100vh - 200px);
  overflow: auto;
  background: #fff;
  flex: 1;
  color: #546e7a;
  position: relative;
}
.hotels-list :global(.empty) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  font-size: 15px;
}
</style>
