<!--suppress ES6UnusedImports -->
<script>
    import { get, orderBy } from 'lodash';
    import moment from 'moment';
    import Spinner from 'components/atoms/Spinner.svelte';
    import Select from 'components/molecules/inputs/Select.svelte';

    import Checkbox from '../../../advanced-search/Checkbox.component.svelte';
    import Header from '../../components/Header.component.svelte';
    import Representative from './Representative.component.svelte';
    import Actions from './ActionsHelper.component.svelte';

    import {loadContacts} from '../../services/hotel.service';
    import {createRep, createUserWithPassword} from './actions.service';
    import {REPRESENTATIVES} from './reps.module';
    import {DateFormatter} from 'src/utils/internationalization.service';

    export let hotel;

    let loading = true,
        formatDate = DateFormatter('---').format,
            representatives = [],
            columns = [
               {
                    label: 'Role', key: 'role'
                },
                {
                    label: 'Added On',
                    get: user => formatDate(getDateFromId(user.id))
                },
                {
                    label: 'Phone',
                    key: 'phone'
                },
                {
                    label: 'Last Active',
                    get: ({lastActive}) => lastActive && moment(lastActive).format('Y-MM-DD') || '---'
                },
                {
                    label: 'Email Address',
                    key: 'emailAddress'
                }
            ],
            chainReps,
            hotelReps,
            selectedChainReps = [],
            selectedHotelReps = [],
            allChainSelected = false,
            allHotelSelected = false,
            sorters = [
                {
                    label: 'Full Name',
                    value: 'fullName',
                    get: ({firstName, lastName}) => `${firstName} ${lastName}`,
                },
                {
                    label: 'Role',
                    value: 'role',
                    key: 'role'
                },
                {
                    label: 'Added On',
                    value: 'added',
                    get: user => getDateFromId(user.id)
                },
                {
                    label: 'Phone',
                    value: 'phone',
                    key: 'phone'
                },
                {
                    label: 'Last Active',
                    value: 'lastActive',
                    get: ({lastActive}) => lastActive && moment(lastActive).format('Y-MM-DD')
                },
                {
                    label: 'Email Address',
                    value: 'emailAddress',
                    key: 'emailAddress'
                },
                {
                    label: 'Status',
                    value: 'status',
                    get: ({isUser, isInvitedUser}) => (isInvitedUser? 'Invited': (isUser? 'Verified': 'Bid Contact'))
                },
            ], sorter = sorters[0],
            props,
            showActions;

    loadHotelReps();

    $: chainReps = representatives.filter(({accountType}) => accountType === 'CHAIN');
    $: hotelReps = representatives.filter(({accountType}) => accountType === 'HOTEL');
    $: props = representatives.reduce((acc, rep) => {
        return acc.map(prop => {
            const value = prop.get? prop.get(rep): get(rep, prop.key, ''), size = Math.max(prop.size || 0, value.length + prop.label.length);
            return {...prop, size};
        });
    }, columns);
    $: showActions = selectedChainReps.length > 0 || selectedHotelReps.length > 0;

    function selected({userId, emailAddress}, list) {
        return list.find(rep => rep.isUser? rep.userId === userId: rep.emailAddress ===  emailAddress)
    }

    function allSelected(type) {
        if(type === 'CHAIN'){
            return selectedChainReps.length > 0 && selectedChainReps.length === chainReps.length;
        }else{
            return selectedHotelReps.length > 0 && selectedHotelReps.length === hotelReps.length;
        }
    }

    function someSelected(type) {
        if(type === 'CHAIN'){
            return selectedChainReps.length > 0 && selectedChainReps.length < chainReps.length;
        }else{
            return selectedHotelReps.length > 0 && selectedHotelReps.length < hotelReps.length;
        }
    }

    async function loadHotelReps() {
        loading = true;
        representatives = await loadContacts(hotel.id);
        loading = false;
        sort({detail: sorter.value});
    }

    function selectBulk(type) {
        if(type === 'CHAIN') {
            selectedChainReps = allSelected(type)? []: chainReps;
            allChainSelected = allSelected('CHAIN');
        }else{
            selectedHotelReps = allSelected(type)? []: hotelReps;
            allHotelSelected = allSelected('HOTEL');
        }
    }

    function selectOne(rep) {
        if(rep.accountType === 'CHAIN'){
            if(selected(rep, selectedChainReps)){
                selectedChainReps = selectedChainReps.filter(({userId, emailAddress}) => rep.isUser? userId !== rep.userId: emailAddress !== rep.emailAddress);
            }else{
                selectedChainReps = [...selectedChainReps, rep];
            }
            allChainSelected = allSelected('CHAIN');
        }else{
            if(selected(rep, selectedHotelReps)) {
                selectedHotelReps = selectedHotelReps.filter(({userId, emailAddress}) => rep.isUser? userId !== rep.userId: emailAddress !== rep.emailAddress);
            }else{
                selectedHotelReps = [...selectedHotelReps, rep];
            }
            allHotelSelected = allSelected('HOTEL');
        }
    }

    function sort({detail}) {
        sorter = sorters.find(({value}) => value === detail) || sorters[0];
        representatives = orderBy(representatives, [sorter.get || sorter.key], ['asc'])
    }

    function create(){
        return createRep(hotel).then(loadHotelReps);
    }

    function createUser(){
      return createUserWithPassword(hotel).then(loadHotelReps);
    }

    function actionCompleted({id}) {
        switch(id) {
            case 'edit':
            case 'delete':
            case 'deactivate':
            case 'activate':
            case 'grantAdmin':
            case 'revokeAdmin':
            case 'sendInvite':
                loadHotelReps();
                clearSelections();
                break;
            default: break;
        }
    }

    function clearSelections() {
        selectedChainReps = [];
        selectedHotelReps = [];
    }

    function getDateFromId(id = ''){
        return id.length ? new Date(parseInt(id.substring(0, 8), 16) * 1000) : undefined;
    }
</script>

<Header {hotel} {showActions}>
    <div slot="actions">
        <Actions hotel={hotel} {representatives} chainReps={selectedChainReps} hotelReps={selectedHotelReps} {actionCompleted} />
    </div>
    <span class="sort">
        <Select id="sort by" options={sorters} value={sorter.value} iconStart="sort" on:input={sort}>Sort By</Select>
    </span>
    <button class="rbButton asDarkMain" data-icon="person_add" on:click={create}> Add Representative </button>
    <button class="rbButton asDarkMain" data-icon="person_add_alt" on:click={createUser}>Create User</button>
  <slot />
</Header>

<div class="reps main" id={REPRESENTATIVES}>
    {#if loading}
        <div class="empty">
            <Spinner />
        </div>
    {:else}
        <div class="section" id="chain">
            <div class="section" id="hotel">
                <div class="title">
                    <Checkbox
                            selected={allHotelSelected}
                            on:toggle={() => selectBulk('HOTEL')}
                    />
                    Hotel Representatives
                </div>
                <div class="content">
                    {#each hotelReps as rep}
                        <Representative {rep} {props}  bind:selectedList={selectedHotelReps} selected={selected(rep, selectedHotelReps)} on:select={() => selectOne(rep)}/>
                    {/each}
                </div>
            </div>
            <div class="title">
                <Checkbox
                        selected={allChainSelected}
                        on:toggle={() => selectBulk('CHAIN')}
                />
                Chain Representatives
            </div>
            <div class="content">
                {#each chainReps as rep}
                    <Representative {rep} {props} selected={selected(rep, selectedChainReps)} on:select={() => selectOne(rep)}/>
                {/each}
            </div>
        </div>
    {/if}
</div>

<style lang="stylus">.empty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reps.main .section .content {
  padding: 0;
}
.reps.main .section .title {
  height: 42px;
  border-top: 5px solid #273238;
  background: #2c383d;
  display: flex;
  align-items: center;
  padding: 0;
}
.sort :global(.rbInput.component) {
  width: 175px;
  margin-right: 5px;
}
</style>
