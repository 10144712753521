import component from './GbtaForm.page.svelte';
import {getIncludedQuestionsAsMenuSubs} from './services/questions.service';

export const GBTA_FORM = 'gbta';

export default {
    component,
    id: GBTA_FORM,
    text: 'GBTA Form',
    icon: 'description',
    subItems: getIncludedQuestionsAsMenuSubs(),
};