<script>
  import Overlay from './Overlay.svelte';
  import { scale } from 'svelte/transition';
</script>

<Overlay>
  <div transition:scale="{{ duration: 300 }}">
    <slot></slot>
  </div>
</Overlay>
