<script>
  export let title = '';
  export let subtitle = '';
  export let pretitle = '';
  export let icon = 'folder';
</script>

<div class="Component">
  <div class="Icon"><i class="material-icons">{icon}</i></div>

  <div>
    <div class="Subtitle">
      <slot name="pretitle">{pretitle || ''}</slot>
    </div>

    <div class="Title">{title || ''}</div>

    <div class="Subtitle">
      <slot name="subtitle">
        {subtitle || ''}
      </slot>
    </div>
  </div>
</div>

<style lang="stylus">.Component {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 12px;
}
.Icon {
  background: radial-gradient(#313c42, #263238);
  border-radius: 90px;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00b8ab;
  margin-right: 12px;
}
.Title {
  font-size: 20px;
  color: #fff;
  line-height: 1.2;
}
.Subtitle {
  font-size: 13px;
  color: #72828a;
  line-height: 1.2;
}
</style>