<script>
  import {default as store, service} from './accounts.store';
  import UsersTable from '../UsersTable.svelte';
  import modals from 'src/services/modals';
  import AutoVerificationManagerDialog from './AutoVerificationManagerDialog.svelte'
  import noop from 'lodash/noop';


  /**
   * Manages the auto verification settings for the current account.
   */
  function manageAutoVerification(){
    modals.show(AutoVerificationManagerDialog, {account: $store.account})
      .catch(noop)
  }

</script>

<div class="Container">
  <div class="Header">
    <div class="Back">
      <button class="rbButton asDark iconOnly" data-icon="arrow_back" on:click={service.showSearch}></button>
    </div>

    <div class="Title">
      <div>{$store.account.type}</div>
      <div class="Name">{$store.account.name}</div>
      {#if $store.accountSuspended}<div class="AccountSuspended">ACCOUNT SUSPENDED</div>{/if}
    </div>

    <div class="Actions">
      {#if $store.account.type !== 'HOTEL'}
        <button class="rbButton asDark" data-icon="settings_suggest" on:click={manageAutoVerification}>
          AutoVerification&nbsp;
          <span
            class:Off={!$store.account.userAutoVerification}
            class:On={$store.account.userAutoVerification}>
          {$store.account.userAutoVerification ? 'ON' : 'OFF'}
        </span>
        </button>
      {/if}
    </div>
  </div>

  <div class="List">
    <UsersTable />
  </div>
</div>

<style lang="stylus">.Container {
  flex: 1 1 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.Header {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #00a99d;
}
.Title {
  font-size: 13px;
  padding: 0 10px;
  flex: 1 1 100%;
}
.Name {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}
.AccountSuspended {
  color: #e65622;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}
.List {
  flex: 1 1 100%;
  overflow: auto;
}
.On {
  color: #00a99d;
}
.Off {
  color: #e65622;
}
</style>
