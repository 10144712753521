<script>
    import Select from 'components/molecules/inputs/Select.svelte';

    import ResultsTable from 'root/pages/hotels/advanced-search/ResultsTable.component.svelte';
    import industries from 'root/assets/data/industries';
    import ActionButtons from 'root/pages/hotels/profile/components/ActionButtonsHelper.component.svelte';

    import Header from '../../components/Header.component.svelte';
    import Entity from '../../../search/Entity.component.svelte';
    import {get} from 'lodash';

    import {loadActions} from '../../../search/actions.service';
    import {loadCompanies} from '../../services/entity.service'

    export let entity, typeName;

    let props = [
        {
            label: 'Industry',
            get({industry}) {
                return industries.find(({id}) => id === industry).label;
            }
        },
        {
            label: 'Url',
            key: 'website',
        },
        {
            label: 'Phone',
            key: 'phone'
        },
        {
            label: 'Email',
            key: 'emailAddress'
        },
    ],
    promise = null,
    sorter = {
        dir: 'asc',
        by: 'name-asc',
        key: 'name',
    },
    selectedEntities = [],
    results = [];

    load();

    function load() {
        promise = loadCompanies(entity.id).then(companies => {
            companies = companies.reduce((acc, company) => {
                company = {...company, _id: company.entityId, status: {...status, value: 'ACTIVE'}};

                return company._id === entity.id? acc: [...acc, company];
            }, []);

            props = companies.reduce((acc, entity) => acc.map(prop => {
                const value = prop.get? prop.get(entity): get(entity, prop.key, ''),
                    size = Math.max(prop.size || 0, value.length + prop.label.length);
                return {...prop, size};
            }), props);
            results = companies;
            return companies;
        });
    }

    $: sorters = (function() {
        const options = [
            {
                label: 'Name',
                value: 'name',
            },
            {
                label: 'Industry',
                value: 'industry',
            },
            {
                label: 'Address',
                value: 'location.address.address1',
            },
            {
                label: 'City',
                value: 'location.address.city',
            },
            {
                label: 'State/Region',
                value: 'location.address.state',
                key: ({location: {address: {state, region}}}) => {
                    return state || region;
                }
            },
            {
                label: 'Country',
                value: 'location.address.country',
            },
        ];

        return [
            {
                label: 'None',
                value: 'none',
                key: null,
            },
            ...options.reduce((acc, option) => [...acc, make(option), make(option, false)], [])
        ];

        function make({label, value, key}, asc = true) {
            const dir = asc? 'asc': "desc";
            return {
                label: `${label} - ${dir.toUpperCase()}`,
                value: `${value}-${dir}`,
                key: key || value,
                dir,
            }
        }
    })();
    $: selectedList = selectedEntities.map(id => results.find(({_id}) => _id === id));
    $: actions = loadActions(selectedList);

    function sort({ detail: by }){
        const option = sorters.find(({value}) => value === by);

        if(option) {
            const key = option.key,
                dir = option.dir;

            sorter = { by, ...option };
            promise = Promise.resolve(results);
        }
    }

    function actionCompleted({id}) {
        switch(id) {
            case "editImage":
                load();
                break;
            default:
                break;
        }
    }

    function selectEntity({detail: {_id, selected}}) {
        if(selected) {
            selectedEntities = selectedEntities.filter(id => _id !== id);
        }else{
            selectedEntities = [...selectedEntities, _id];
        }
    }
</script>

<Header {entity} {typeName} showActions={actions.length > 0}>
    <div slot="actions">
        <ActionButtons {actions} params={[selectedList]} {actionCompleted} />
    </div>
    <div class="sorter">
        <Select id="sort by" options={sorters} value={sorter.by} iconStart="sort" on:input={sort}>Sort By</Select>
    </div>
    <slot />
</Header>

<ResultsTable {promise} {sorter}>
    <div slot="item" let:item>
        <Entity
            entity={item}
            {selectedEntities}
            {props}
            on:select={selectEntity}
        />
    </div>
</ResultsTable>

<style type="text/stylus">.sorter :global(.rbInput.component) {
  width: 175px;
  margin-right: 5px;
}
</style>
