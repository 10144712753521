import ModalsService from 'components/modals/ModalsService.js';
import ModalsMount from 'components/modals/ModalsMount.js';

const service = new ModalsService(ModalsMount);

export default service;

export function setRouter(router){
    router.beforeLeaving(done => {
        service.clean();
        done();
    })
}
