<script>
  import {default as store, updateBid, handleBidSelection} from './Bids.store';
  import getSelectionIcon from 'src/utils/selection-icons.service';
  import modalsService from 'src/services/modals';
  import SelectContactDialog from './SelectContactDialog.svelte';
  import HotelProfileDialog from './HotelProfileDialog.svelte';
  import noop from 'lodash/noop';

  export let bid = {};

  let selected, website;

  $: selected = $store.selected.indexOf(bid.id) !== -1;
  $: website = parseWebsite(bid.hotel.website);

  function selectContact(){
    modalsService.show(SelectContactDialog, {bid})
      .then(bid => updateBid(bid));
  }

  function parseWebsite(ws){
    if(!ws) return;
    return ws.match(/^http[s]?:\/\//i) ? ws : `http://${ws}`;
  }

  function showHotelProfile() {
    modalsService.show(HotelProfileDialog, { hotel: bid.hotel, website, gdsCodes: bid.gdsCodes })
      .catch(noop)
  }
</script>

<div class="Row"
     class:Selected="{selected}"
     class:Disabled="{bid.destinationDisabled}"
>
  <div class="RowSelector">
    <button class="rbButton iconTransparent"
            class:Selected="{selected}"
            data-icon="{getSelectionIcon(selected)}"
            on:click={() => handleBidSelection(bid)}
    ></button>
  </div>

  <div>
    <div class="Title">
        <span class="MarketTier"
              class:Matching={bid.marketTierMatching}
              class:NotAvailable={bid.marketTierNotAvailable}
        >{bid.marketTier}</span>
      <span> &middot; </span>

      <span class="Distance"
            class:Matching={bid.distanceMatching}
            class:NotMatching={!bid.distanceMatching}
      >{bid.distance}</span>
      <span> &middot; </span>

      <span class="Clickable" on:click={showHotelProfile}>{bid.hotelName}</span>

      <span> &middot; </span>

      <div class="HotelDetails">
        {#if bid.hotel.phone}
          <span>{bid.hotel.phone}</span>
          <span> &middot; </span>
        {/if}

        {#if bid.hotel.emailAddress}
          <span>{bid.hotel.emailAddress}</span>
          <span> &middot; </span>
        {/if}

        {#if website}
          <a href="{website}" target="_blank">website</a>
          <span> &middot; </span>
        {/if}
      </div>
    </div>

    <div class="Details">
      <div class="Detail" data-icon-before="{bid.destinationDisabled ? 'report_problem' : null}">
        <span class="Value Ellipsis" style="{`width:${$store.widths.status}px`}">{bid.status}</span>
      </div>

      <div class="Detail">
        <span class="label">Primary Contact: </span>
        <div class="Contact" style="{`width:${$store.widths.contactName}px;max-width:${$store.widths.contactName}px`}">
          <button class="rbButtonSmall asDark ContactButton"
                  class:Empty="{!bid.contact.set}"
                  data-icon="person_add"
                  on:click="{() => selectContact()}"
          ><span class="Value Ellipsis">{bid.contact.fullName || 'Add Contact'}</span></button>
        </div>
      </div>

      <div class="Detail">
        <span class="label">Email: </span>
        <span class="Value Ellipsis"
              class:BadEmailAddress={bid.contact.emailAddressStatusFailed}
              style="{`width:${$store.widths.contactEmail}px`}">
          {bid.contact.emailAddress || 'NA'}
        </span>
      </div>

      <div class="Detail">
        <span class="label">Phone: </span>
        <span class="Value Ellipsis" style="{`width:${$store.widths.contactPhone}px`}">{bid.contact.phone || 'NA'}</span>
      </div>

      <div class="Detail">
        <span class="label">Last seen: </span>
        <span class="Value" style="{`width:${$store.widths.contactLastSeen}px`}">{bid.contact.lastSeen || 'NA'}</span>
      </div>
    </div>
  </div>
</div>

<style lang="stylus">.Row {
  height: 79px;
  border-bottom: 1px solid rgba(55,71,79,0.5);
  display: flex;
  align-items: center;
}
.Row .rbButtonSmall.ContactButton {
  background: transparent;
  border-color: transparent;
  font-size: 13px;
  line-height: 16px;
  max-width: inherit;
  min-width: inherit;
}
.Row:hover,
.Row.Selected {
  background: #2c383d;
}
.Row:hover .rbButtonSmall.ContactButton,
.Row.Selected .rbButtonSmall.ContactButton {
  background: #263238;
  border-color: #263238;
  color: #fff;
}
.Disabled .Title,
.Disabled .Details,
.Disabled .Value,
.Disabled .HotelDetails {
  color: #72828a !important;
  opacity: 1;
}
.Contact {
  min-width: 120px;
}
.rbButtonSmall.ContactButton.Empty {
  color: #b0bec5;
}
.Title {
  font-size: 15px;
  line-height: 18px;
  color: #cfd8dc;
}
.Clickable {
  cursor: pointer;
}
.Clickable:hover {
  color: #fff;
}
.Matching {
  color: #00b8ab;
}
.NotMatching {
  color: #f15a24;
}
.NotAvailable {
  color: #fcee21;
}
.HotelDetails {
  display: inline-block;
  font-size: 13px;
  opacity: 0.5;
}
.HotelDetails a {
  color: inherit;
}
.Details {
  font-size: 13px;
  line-height: 16px;
  color: #72828a;
  display: flex;
  flex-wrap: wrap;
}
.Detail {
  display: flex;
  align-items: center;
}
.Detail[data-icon-before]:before {
  font-size: 16px;
  margin-right: 5px;
}
.Detail:not([data-icon-before]):before {
  content: '|';
  padding: 0 5px;
}
.Detail:not([data-icon-before]):first-of-type:before {
  content: '';
  padding: 0;
}
.label {
  margin-right: 3px;
}
.Value {
  display: inline-block;
  min-width: 20px;
}
.rbButton.iconTransparent {
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0 15px;
  color: #57666f;
}
.rbButton.iconTransparent:before {
  width: 24px;
}
.rbButton.iconTransparent.Selected {
  color: #00a99d;
}
.RowSelector {
  width: 54px;
}
.BadEmailAddress {
  text-decoration: line-through;
  color: #f15a24;
}
</style>
