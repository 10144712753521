import { writable, get } from 'svelte/store';
import { init as initFilters, default as filtersStore } from 'src/components/filters/Filters.store.js';
import FilterText from 'src/components/filters/FilterText.js';
import { PercentageFormatter, DateFormatter, DecimalFormatter } from 'src/utils/internationalization.service';
import { determineMaxWidths } from 'src/services/TextWidthApproximator';
import { listCompetitiveBidsRfps, createCompetitiveBids } from 'src/api';
import httpErrorHandler from 'src/api/HttpErrorHandler';
import {createStringCompareFn, createDateCompareFn, createNumberCompareFn} from 'src/utils/comparison-functions.factory';

const
  defaultState = {
    loading: false,
    ready: false,
    raw: [],
    formatted: [],
    rfps: [],
    widths: {},
    sortByOptions: [],
    sortedBy: undefined,
    textFilter: '',
    selected: [],
    selectionPercentage: 0,
    pendingCount: 0,
  },

  filters = [
    new FilterText({label: 'Company Name', test: 'companyName'}),
    new FilterText({label: 'RFP Name', test: 'name'}),
  ],

  filtersActiveByDefault = [ 0 ],

  sortByOptions = [
    {value: createStringCompareFn('name'), label: 'RFP Name ASC'},
    {value: createStringCompareFn('name', -1), label: 'RFP Name DESC'},
    {value: createStringCompareFn('companyName'), label: 'Company Name ASC'},
    {value: createStringCompareFn('companyName', -1), label: 'Company Name DESC'},
    {value: createDateCompareFn('launchDate'), label: 'Launch Date ASC'},
    {value: createDateCompareFn('launchDate', -1), label: 'Launch Date DESC'},
    {value: createDateCompareFn('dueDate'), label: 'Due Date ASC'},
    {value: createDateCompareFn('dueDate', -1), label: 'Due Date DESC'},
    {value: createDateCompareFn('firstBidSentDate'), label: 'First Bid Sent Date ASC'},
    {value: createDateCompareFn('firstBidSentDate', -1), label: 'First Bid Sent Date DESC'},
    {value: createNumberCompareFn('competitiveBidsSent'), label: 'Competitive Bids Sent ASC'},
    {value: createNumberCompareFn('competitiveBidsSent', -1), label: 'Competitive Bids Sent DESC'},
    {value: createNumberCompareFn('competitiveBidsResponded'), label: 'Competitive Bids Responded ASC'},
    {value: createNumberCompareFn('competitiveBidsResponded', -1), label: 'Competitive Bids Responded DESC'},
    {value: createNumberCompareFn('bidsSent'), label: 'Bids Sent Percentage ASC'},
    {value: createNumberCompareFn('bidsSent', -1), label: 'Bids Sent Percentage DESC'},
  ],

  defaultSort = sortByOptions.find(so => so.label === 'First Bid Sent Date DESC'),

  fontSize = 13,

  maxWidths = {
    'companyName': 200,
    'launchDate': 70,
    'dueDate': 70,
    'firstBidSentDate': 70,
    'bidsSent': 100,
    'competitiveBidsSent': 60,
    'competitiveBidsResponded': 60
  },

  dateFormatter = DateFormatter('N/A'),

  percentageFormatter = PercentageFormatter(0, 2, 'N/A'),

  numberFormatter = DecimalFormatter('N/A', undefined, 0),

  store = writable(defaultState);


let lastFetchPromise;

export default store;

export function init(list = []){
  store.update(values => {
    if(values.loading) return values;
    initFilters(filters, filtersActiveByDefault);

    const fetchPromise = (list.length ? Promise.resolve(list) : loadRfps());
    lastFetchPromise = fetchPromise;

    fetchPromise
      .then(result => {
        if(lastFetchPromise === fetchPromise){
          const formatted = result.map(r => formatRfp(r)),
            vals = {
              loading: false,
              ready: true,
              raw: result,
              formatted,
              widths: determineMaxWidths(formatted, fontSize, maxWidths),
              sortByOptions,
              sortedBy: defaultSort ? defaultSort.value : undefined,
              textFilter: '',
              selected: [],
              selectionPercentage: 0,
              pendingCount: countPending(result),
            };
          store.set({...vals, rfps: sortAndFilter(formatted, vals)});
        }
      })
      .catch(() => {
        if(lastFetchPromise === fetchPromise){
          store.set(defaultState);
        }
      });

    return {...defaultState, loading: true, ready: false};
  });
}

export function handleSelection(rfpId){
  store.update(values => {
    const oldSelected = values.selected,
      newSelected = oldSelected.indexOf(rfpId) === -1 ? [...oldSelected, rfpId] : oldSelected.filter(id => id !== rfpId);

    return {
      ...values,
      selected: newSelected,
      selectionPercentage: selectionPercentageCalculator(newSelected, values.raw),
    };
  });
}

export function handleSelectAll() {
  store.update(values => {
    const newSelected = values.selectionPercentage === 1
      ? []
      : values.raw.filter(r => !r.competitiveBidsStatus || r.competitiveBidsStatus.value !== 'PENDING').map( rfp => rfp.id );
    return {
      ...values,
      selected: newSelected,
      selectionPercentage: selectionPercentageCalculator(newSelected, values.raw)
    };
  });
}

export function applyFilters(){
  store.update( values => {
    const scrollContainerElement = document.getElementById('VirtualList');
    if(scrollContainerElement) scrollContainerElement.scrollTo(0, 0);

    return {
      ...values,
      rfps: sortAndFilter(values.formatted, values),
      selected: [],
      selectionPercentage: 0,
    };
  });
}

export function setTextFilter(textFilter = ''){
  store.update(values => ({...values, textFilter}));
  applyFilters();
}

export function setSort(sortedBy){
  store.update( values => {
    const v = {...values, sortedBy };
    return {
      ...v,
      rfps: sortAndFilter(values.formatted, v),
    };
  });
}

export function generateCompetitiveBids(settings){
  store.update( values => {
    createCompetitiveBids(values.selected, settings)
      .catch(error => httpErrorHandler.handle(error));

    const raw = updateRfpsCompetitiveBidsStatus(values.raw, values.selected, { value: 'PENDING' }),
      formatted = raw.map(r => formatRfp(r));

    return {
      ...values,
      raw,
      formatted,
      rfps: sortAndFilter(formatted, values),
      selected: [],
      selectionPercentage: 0,
    };
  });

  function updateRfpsCompetitiveBidsStatus(rfps, rfpsIds, status){
    return rfps.map(rfp => ({
      ...rfp,
      competitiveBidsStatus: rfpsIds.indexOf(rfp.id) === -1 ? rfp.competitiveBidsStatus : status,
    }))
  }
}

function loadRfps(){
  return listCompetitiveBidsRfps()
    .catch(error => httpErrorHandler.handle(error));
}

function formatRfp(rfp = {}){
  return {
    ...rfp,
    'launchDate': dateFormatter.format(rfp.launchDate),
    'dueDate': dateFormatter.format(rfp.dueDate),
    'firstBidSentDate': dateFormatter.format(rfp.firstBidSentDate),
    'bidsSent': `${percentageFormatter.format(rfp.bidsSent)} (${numberFormatter.format(rfp.bidsCount)})`,
    'competitiveBidsSent': numberFormatter.format(rfp.competitiveBidsSent),
    'competitiveBidsResponded': numberFormatter.format(rfp.competitiveBidsResponded),
  };
}

function sortAndFilter(rfps, values) {
  const textFilter = createTextFilter(values.textFilter),
    activeFilters = get(filtersStore).active.filter(f => !f.isEmpty),
    actualFilters = textFilter ? [ textFilter, ...activeFilters] : activeFilters,
    applyFiltersFn = i => actualFilters.reduce( (acc, f) => acc && f.test(i, f), true );

  const filtered = rfps.filter(applyFiltersFn);
  filtered.sort(values.sortedBy)
  return filtered;
}

function createTextFilter(textFilterValue){
  const textFilter = textFilterValue ? new RegExp(textFilterValue, 'i') : false;
  return textFilter ? { test: i => textFilter.test(`${i.name} ${i.companyName}`) } : false;
}

function selectionPercentageCalculator(selected = [], rfps = []){
  return selected.length / rfps.filter(r => !r.competitiveBidsStatus || r.competitiveBidsStatus.value !== 'PENDING').length;
}

function countPending(rfps) {
  return rfps.filter(r => r.competitiveBidsStatus && r.competitiveBidsStatus.value === 'PENDING').length;
}
