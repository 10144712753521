<script>
    import CurrentUser from '../../molecules/CurrentUser/CurrentUser.svelte';
    import rbAdminPanelLogo from 'images/rb-adminPanel.png';

    export let user = {}
</script>

<div class="rbHeader">
    <div class="logoContainer" >
        <img src="{rbAdminPanelLogo}" width="130px" alt="ReadyBid Logo">
    </div>
    <div class="currentUser">
        <CurrentUser {user}>
            <slot>
                <li class="link">
                    <a href="logout">
                        <i class="icon material-icons">logout</i><span>Logout</span>
                    </a>
                </li>
            </slot>
        </CurrentUser>
    </div>
</div>

<style type="text/stylus">.rbHeader {
  background: #263238;
  height: 50px;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0;
  font-size: 12px;
  box-sizing: border-box;
}
.logoContainer {
  margin: 12px 0 0 12px;
  width: 130px;
}
.currentUser {
  margin-top: 4px;
}
</style>
