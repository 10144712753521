import Notification from 'components/modals/Notification.basic.svelte';
import modals from 'root/services/modals';
import httpErrorHandler from 'root/api/HttpErrorHandler';
import HttpNotification from 'root/api/HttpNotifications';
import entityProfile from 'root/api/entityProfile.js';
import api from './endpoints';

export function handle(promise, options = {}) {
  return HttpNotification.handle(promise, options).catch(
    httpErrorHandler.handle
  );
}

export function loadHotel(id) {
  return handle(api.getHotel(id));
}

export function loadContacts(id) {
  return handle(api.getContacts(id)).then(r =>
    r.data.map(rep => {
      rep.isActiveUser = rep.isUser && rep.status.value === 'ACTIVE';
      rep.isInactiveUser = rep.isUser && rep.status.value !== 'ACTIVE';
      rep.isInvitedUser = rep.meta.isInvitedUser;
      rep.role = parseRole(rep.roles)

      return rep;
    })
  );

  function parseRole(roles = []){
    if(roles.indexOf('ACCOUNT_ADMIN') !== -1){
      return 'ADMIN';
    } else if(roles.indexOf('ACCOUNT_MANAGER') !== -1){
      return 'MANAGER';
    } else {
      return '';
    }
  }
}

export function updateBasicInfo(id, request) {
  return handle(api.updateBasicInfo(id, request));
}

export function rebrandHotel(id, request) {
  return handle(api.rebrandHotel(id, request));
}

export function updateAmenities(id, amenities) {
  return handle(api.updateAmenities(id, { amenities }));
}

export function createUser(id, type, request) {
  return handle(entityProfile.createUser(id, type, request));
}

export function activateRepresentatives(id, reps) {
  return handle(api.activateRepresentatives(id, { reps }));
}

export function updateAnswers(id, answers) {
  return handle(api.updateAnswers(id, { answers }));
}

export function getAnswers(id) {
  return api.getAnswers(id);
}

export function updateGdsCodes(id, codes) {
  return api.updateGdsCodes(id, codes);
}

export function showAsyncActionMessage() {
  return modals.show(Notification, {
    title: `Action in Progress`,
    subtitle: 'Ongoing',
    content: `The requested action is ongoing and will be completed in a few minutes. Please check back to confirm that the action has completed.`,
    resolveLabel: 'Ok'
  });
}
