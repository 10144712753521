export function getAndAutoSaveFileWrapper (server){
  server.getAndAutoSaveFile = async function getAndAutoSaveFile(url, params, options, progressCallback) {
    const response = await this.axios.get(url, {
      params,
      responseType: 'blob',
      onDownloadProgress(e) {
        if (progressCallback) {
          if (e.lengthComputable) {
            progressCallback(Math.round((e.loaded * 100) / e.total), e);
          } else {
            progressCallback(0, e);
          }
        }
      },
      ...options
    });
    autoSaveResponse(response);
  }
}

function autoSaveResponse(response) {
  const headers = response.headers;
  autoSave(response.data, headers['content-type'], headers['rb-filename']);
}

function autoSave(file, fileType, fileName) {
  const blob = new Blob([file], { type: fileType });

  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const downloadLink = createLink();

    document.body.appendChild(downloadLink);
    downloadLink.click();
    window.URL.revokeObjectURL(downloadLink.href);
    document.body.removeChild(downloadLink);
  }

  function createLink() {
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = fileName;
    downloadLink.style.display = 'none';
    return downloadLink;
  }
}
