<script>
    import {getContext} from 'svelte';

    import Select from 'components/molecules/inputs/SelectModel.svelte';

    export let save, cancel, reps, label;

    let formModel = getContext('formModel'),
      options = reps.map(({id, firstName, lastName}) => ({value: id, label: `${firstName} ${lastName}`}));

    function apply() {
        formModel.touchAll();
        if($formModel.valid) {
            save($formModel.values.user);
        }
    }

</script>

<div class="content">
    <Select id="user" name="Replacement" options="{options}" required="true">Select Replacement</Select>
</div>

<div class="footer">
    <button class="rbButton asModal" on:click="{cancel}">Cancel</button>
    <button class="rbButton asModalMain" data-icon="delete" on:click={apply}> {label} </button>
</div>

<style type="text/stylus">.content {
  margin: 10px 0 30px;
  font-size: 13px;
  line-height: 16px;
  color: #546e7a;
}
.footer {
  text-align: right;
}
.content :global(.rbInput.component .container) {
  border: 1px solid #e6e7e8;
  background: transparent;
  color: #546e7a;
}
.content :global(.rbInput.component .container .input) {
  color: #546e7a;
}
.content :global(.rbInput.component .container .label) {
  background: #fff;
}
</style>
