<script>
	import { slide } from 'svelte/transition';

    export let href;
    export let icon = '';
    export let active = false;
    export let minimized;
    export let isChild = false;
    export let subItems = [];
    export let open = false;
</script>

<div class="item" class:active class:minimized class:main={!isChild} class:open>
    {#if href}
        <a {href} class="link" class:active>
            <i class="material-icons">{icon}</i>
            <slot></slot>
        </a>
    {:else}
        <span class="link" on:click={() => open = !open}>
            <i class="material-icons">{icon}</i>
            <slot></slot>
        </span>
    {/if}

    {#if subItems.length > 0}
        <span class="openner" on:click={() => open = !open}>
            <i class="material-icons">{!open? 'keyboard_arrow_down': 'keyboard_arrow_up'}</i>
        </span>
    {/if}
</div>

{#if subItems.length > 0 && !minimized && open}
    <ul class="sub-items" transition:slide={{delay: 250, duration: 300}}>
        {#each subItems as item}
            <li>
                <slot name="item" {item}></slot>
            </li>
        {/each}
    </ul>
{/if}

<style type="text/stylus">.item {
  display: flex;
  align-items: center;
  color: #b0bec5;
  opacity: 0.5;
}
.item.active {
  color: #fff;
  opacity: 1;
}
.item.main.active,
.item.open {
  background: #00b8ab;
  opacity: 1;
  color: #fff;
}
.item:hover {
  opacity: 1;
}
.link {
  height: 48px;
  flex: 1;
  max-width: 275px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 12px;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s linear;
}
.link .material-icons {
  margin-right: 16px;
}
.openner {
  padding: 1em;
  cursor: pointer;
}
.sub-items {
  list-style-type: none;
}
</style>
