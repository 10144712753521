import getFilters from './filter-types';
import httpErrorHandler from 'root/api/HttpErrorHandler';
import Notification from 'components/modals/Notification.basic.svelte';
import modals from 'root/services/modals';
import {search, changeChain, useAsChain, loadBrands} from '../api/end-points';
import {extractValue} from 'root/pages/hotels/advanced-search/search.service';
import { listChains } from 'root/api/index.js';
import SelectChainDialog from './dialogs/SelectChain.dialog.svelte';
import {handle} from 'root/pages/hotels/profile/services/hotel.service';

export async function loadFilterTypes() {
    const allBrands = await getMasterChains();
    return getFilters(allBrands);
}

export async function getMasterChains() {
    const chains = await listChains();

    return chains.map(format);

    function format({name, code, id: value}) {
        return {
            value,
            label: `${code} - ${name}`,
        }
    }
}

export function searchChains(filters, and, page, perPage = 100) {
    const request = {
        filters: filters.reduce((acc, {id, value}) => hasValue(value)? ({...acc, [id]: extractValue(value)}): acc, {}),
        and,
        page,
        perPage,
    };

    function hasValue(value) {
        return Array.isArray(value)? value.length > 0: !!value;
    }

    return search(request).catch(httpErrorHandler.handle);
}

export async function changeMasterChain(brands) {
    await modals.show(Notification, {
        title: 'Warning!',
        subtitle:`Change Master Chain?`,
        content: `This action will assign brands to another master chain. Any contacts in bids sent to the former master chain will be removed. This action can not be reversed.`,
        resolveLabel: 'Yes, Proceed',
        rejectLabel: 'Cancel',
    });

    const chains = await getMasterChains(),
        {masterChainId} = await modals.show(SelectChainDialog, {chains});

    return handle(changeChain({masterChainId, brandIds: brands.map(({_id, id: v}) => _id || v)}))
            .then(res => {
                modals.show(Notification, {
                    title: `Success`,
                    subtitle:'Master chain changed',
                    content: `The master chain has been changed successfully.`,
                    resolveLabel: 'Ok',
                });

                return res;
            });
}

export async function useAsMasterChain(brand) {
    await modals.show(Notification, {
        title: 'Use Brand as Master Chain?',
        subtitle:`Confirm`,
        content: `This action will create a new master chain with name <b>${brand.name}</b> and code <b>${brand.code}</b> and assign this brand to that new chain including all chain level bids for hotels belonging to ${brand.name}`,
        resolveLabel: 'Yes, Proceed',
        rejectLabel: 'Cancel',
    });

    return handle(useAsChain(brand._id || brand._id))
            .then(res => {
                modals.show(Notification, {
                    title: `Success`,
                    subtitle:'Master chain Created',
                    content: `The new master chain has been created successfully.`,
                    resolveLabel: 'Ok',
                });

                return res;
            });
}

export function loadChainBrands(chainId) {
    return handle(loadBrands(chainId));
}
