<script>
  import {default as store, service} from './user-details.store';
  import DialogTemplate from 'components/modals/DialogTemplate.basic.svelte';
  import Spinner from 'components/atoms/Spinner.svelte';
  import Menu from 'components/organisms/Menu/Menu.svelte';

  export let cancel, user = {};

  service.initialize(user);

</script>

<DialogTemplate {cancel} closeOnClick="{false}" wide="{true}">
  <div class="DialogContainer">
    <div class="UserDetailsContainer">
      {#if $store.user.profilePicture}
        <div class="Picture">
          <img class="ProfilePicture" src="{$store.user.profilePicture}" alt="User"/>
        </div>
      {/if}
      <div class="UserDetails">
        <div class="Important">{$store.user.name}</div>

        <div><span class="Label">Email Address: </span> {$store.user.emailAddress}</div>
        <div><span class="Label">Phone: </span> {$store.user.phone || 'NA'}</div>
        <div><span class="Label">Created: </span> {$store.user.createdAt || 'NA'}</div>
        <div><span class="Label">Last Active: </span> {$store.user.lastActive || 'NA'}</div>

        <div data-icon-before="{$store.user.status.icon}"
             class="UserStatus OK"
             class:Warning={$store.user.status.warning}
             class:Bad={$store.user.status.disabled}
        >{$store.user.status.label}</div>
        <div data-icon-before="{$store.user.emailAddressStatus.icon}"
             class="UserStatus"
             class:OK={$store.user.emailAddressStatus.ok}
             class:Bad={$store.user.emailAddressStatus.bad}
        >{$store.user.emailAddressStatus.label}</div>
      </div>
      <div class="UserActions">
        <Menu on:change={ev => service.handleUserAction(ev.detail, $store.user)}
              position="END"
              options="{service.getUserActions($store.user)}"
        >User Actions</Menu>
      </div>
    </div>

    <div class="UserAccountsTable">
      {#if $store.user.userAccounts}
        {#each $store.user.userAccounts as ua}
          <div class="Card">
            <div class="CardDetails">
              <div class="UserAccountStatus"
                   class:OK="{ua.status.status === 'OK'}"
                   class:Warning="{ua.status.status === 'WARNING'}"
                   class:Bad="{ua.status.status === 'BAD'}"
              >
                {ua.status.label} {#if ua.status.at} - {ua.status.at}{/if} {#if ua.adminStatus} &bull; {ua.adminStatus} {/if}
              </div>
              <div class="UserAccountJobTitle">{ua.jobTitle} @</div>
              <div data-icon-after="{ua.entityStatus.icon}"
                   class="UserAccountName"
                   class:Warning="{ua.entityStatus.status === 'WARNING'}"
                   class:Bad="{ua.entityStatus.status === 'BAD'}"
              >
                {ua.name}
                <div class="rbTooltip" data-tooltip="placement:top-end">{ua.entityStatus.label}</div>
              </div>
              <div>{ua.type} {#if ua.address}@ {ua.address}{/if}</div>
            </div>

            <div class="CardActions">
              <button class="rbButtonSmall asDark">
                <Menu on:change={ev => service.handleUserAccountAction(ev.detail, ua)}
                      position="END"
                      style="{{button: 'rbButtonSmall asDark'}}"
                      options="{service.getUserAccountActions(ua)}"
                >Actions</Menu>
              </button>
            </div>
          </div>
        {:else}
          <div class="UserAccountsTableNotification">No User Accounts</div>
        {/each}
      {:else}
        <div class="UserAccountsTableNotification">
          <Spinner />
        </div>
      {/if}
    </div>
  </div>

</DialogTemplate>

<style lang="stylus">.DialogContainer {
  min-height: 600px;
  max-height: 70vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: #b0bec5;
}
.UserDetailsContainer {
  flex: 0 0 auto;
  display: flex;
}
.Picture {
  flex: 0 0 160px;
  height: 180px;
  background: transparent;
}
.ProfilePicture {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.UserDetails {
  flex: 1 1 100%;
  padding: 10px 15px;
  color: #eceff1;
}
.UserDetails .Important {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 5px;
}
.UserDetails .Label {
  font-size: 13px;
  color: #78909c;
}
.UserStatus {
  display: flex;
  font-size: 14px;
  line-height: 30px;
}
.UserStatus:before {
  margin-right: 5px;
}
.UserActions {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 15px;
}
.Card {
  margin: 10px 10px;
  padding: 10px;
  border: 1px solid #78909c;
  border-radius: 6px;
  font-size: 13px;
  display: flex;
}
.CardDetails {
  flex: 1 1 100%;
}
.CardActions {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.UserAccountsTable {
  overflow-y: auto;
  border-top: 1px solid #00a99d;
  flex: 1 1 100%;
}
.UserAccountsTableNotification {
  display: flex;
  justify-content: center;
  padding-top: 60px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
}
.UserAccountStatus {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  text-transfor: uppercase;
}
.UserAccountName {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 5px;
}
.UserAccountName:after {
  font-size: 14px;
  align-self: flex-start;
  margin-left: 3px;
}
.OK {
  color: #00a99d !important;
}
.Warning {
  color: #fcee21 !important;
}
.Bad {
  color: #e65622 !important;
}
</style>
