import adminServer from 'root/api/AdminServer';
import {checkAndExecute} from 'root/api/helpers';

const emailAddressWhitelistURI = '/email-address-whitelist';

export function list() {
  return adminServer.get(emailAddressWhitelistURI);
}

export function add(pattern) {
  return checkAndExecute(
    () => pattern,
    () => adminServer.put(emailAddressWhitelistURI, {pattern})
  );
}

export function remove(pattern){
  return checkAndExecute(
    () => pattern,
    () => adminServer.axios.delete(emailAddressWhitelistURI, {params: {pattern}})
  );
}
