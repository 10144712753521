<script>
  import noop from 'lodash/noop';
  import { fade } from 'svelte/transition'

  export let closeOnClick = false;
  export let cancel = noop;
  export let center = true;

  let overlayElement;

  function onClick(e){
    if(closeOnClick && e.target === overlayElement){
      cancel();
    }
  }
</script>

<div class="overlay"
     class:rbContentCentered="{center}"
     on:click="{onClick}"
     bind:this="{overlayElement}"
     transition:fade="{{ duration: 150 }}">
    <slot></slot>
</div>

<style type="text/stylus">.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(38,50,56,0.8);
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
.overlay + :global(.overlay) {
  background: rgba(0,0,0,0.05);
}
</style>
