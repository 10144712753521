<script>
  import NotificationTemplate from './NotificationTemplate.basic.svelte';
  import Icon from '../atoms/Icon.svelte';

  export let type = 'information';
  export let icon = 'exclamation';
  export let title = 'Title';
  export let subtitle;
  export let content;
  export let resolveLabel;
  export let rejectLabel;
  export let wide;

  export let hide;
  export let cancel;
</script>

<NotificationTemplate {wide}>
  <div class="container">
    <div class="header">
      <div class="icon {type}">
        <Icon name="{icon}"></Icon>
      </div>

      <div class="headerContent">
        {#if subtitle}<div class="subtitle">{subtitle}</div>{/if}

        <div class="title">{title}</div>
      </div>
    </div>

    {#if content}
      <div class="content">
        {@html content}
      </div>
    {/if}

    <div class="footer">
      {#if rejectLabel}
        <button class="rbButton asModal" on:click="{cancel}">{rejectLabel}</button>
      {/if}

      {#if resolveLabel}
        <button class="rbButton"
                class:asModalError="{ type === 'warning'}"
                class:asModalMain="{ type !== 'error'}"
                on:click="{hide}">
          {resolveLabel}
        </button>
      {/if}
    </div>
  </div>
</NotificationTemplate>

<style type="text/stylus">.container {
  padding: 25px 15px 20px;
  color: #b0bec5;
}
.header {
  display: flex;
  flex-direction: row;
}
.headerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.icon {
  flex: 0 0 52px;
  height: 52px;
  margin-right: 15px;
}
.icon.information :global(.svg) {
  width: 52px;
  height: 52px;
  fill: #00a99d;
}
.icon.warning :global(.svg) {
  width: 52px;
  height: 52px;
  fill: #f15a24;
}
.subtitle {
  height: 22px;
  font-size: 17px;
  text-transform: uppercase;
  vertical-align: text-top;
  text-shadow: 1px 0 0 rgba(0,0,0,0.15);
  font-weight: normal;
  line-height: 1;
}
.title {
  height: 30px;
  font-size: 28px;
  vertical-align: text-bottom;
  text-shadow: 1px 0 0 rgba(0,0,0,0.15);
  font-weight: normal;
  line-height: 1;
  padding: 0;
  margin: 0;
}
.content {
  margin: 25px 0 30px;
  font-size: 13px;
  line-height: 16px;
}
.footer {
  text-align: right;
}
</style>
