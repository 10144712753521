<script>
    import isEqual from 'lodash/isEqual';

    export let value, options;
</script>

<div class="radio">
    <div class="options">
        {#each options as option}
            <div class="option" class:selected={isEqual(value, option.value)}>
                <i class="material-icons">check</i>
                <div class="label">{option.label}</div>
            </div>
        {/each}
    </div>
</div>

<style type="text/stylus">.options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}
.option {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
}
.option.selected {
  color: #546e7a;
}
i.material-icons {
  margin-right: 5px;
}
.option:not(.selected) i.material-icons {
  background: #eceff1;
  color: transparent;
  height: 18px;
  width: 18px;
  border-radius: 3px;
}
</style>