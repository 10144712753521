<script>
  import {onMount} from 'svelte';
  import {init as initStore, default as store} from './Rfps.store';
  import CompetitiveBidsPageComponent from "./CompetitiveBidsRfpsPageComponent.svelte";
  import Header from 'src/components/Header.svelte';
  import Navigation from 'src/components/Navigation.svelte';
  import Loader from 'src/components/Loader.svelte';

  onMount(() => {
    initStore();
  });

</script>

<div class="rbPage rbDark">
  <Header />

  {#if $store.loading}
    <div class="DocumentView">
      <div class="FillAndCenter">
        <Loader />
      </div>
    </div>
  {:else if $store.ready}
    <div class="DocumentView">
      <div class="DocumentViewNavigation">
        <Navigation />
      </div>

      <CompetitiveBidsPageComponent />
    </div>
  {/if}
</div>
