import BasicInfo from './basic-info/basic-info.module';
import Amenities from './amenities/amenities.module';
import Representatives from './reps/reps.module';
import GBTAForm from './gbta-form/form.module';
import accountModule from './account/account.module';

const modules = {};

(function() {
    load(BasicInfo)
    load(Amenities)
    load(Representatives);
    load(GBTAForm);
    load(accountModule);

    function load(module) {
        modules[module.id] = module;
    }
})();

export function navigationItems() {
    return Object.values(modules);
}


export function currentModule(module) {
    const current = modules[module];
    return current && current.component;
}
