import * as api from './api';
import {
    FREE_TEXT_FILTER_TYPE,
    SINGLE_SELECT_FILTER_TYPE
} from 'root/pages/hotels/advanced-search/filters/filter-types';
import {extractValue} from 'root/pages/hotels/advanced-search/search.service';
import modals from 'root/services/modals';
import detailsDialog from './details.dialog.svelte';
import {handle} from 'root/pages/hotels/profile/services/hotel.service';
import moment from 'moment';

export function filterReports(filters, page = 1, and, perPage = 50) {
    const request = {
        filters: getFilterValues(filters),
        and,
        page,
        perPage,
    };

    return api.searchAndSort(request);
}

export function getFilterValues(filters) {
    return filters.reduce((acc, {id, value}) => hasValue(value)? ({...acc, [id]: extractValue(value)}): acc, {});

    function hasValue(value) {
        return Array.isArray(value)? value.length > 0: !!value;
    }
}

export function getFilterTypes() {
    const sorters = [
        {
            label: 'Number Of Bids',
            value: 'bidCounts'
        }, {
            label: 'Number of directory visits ',
            value: 'directoryOpens'
        }
    ];

    const periods = [...new Array(12)].map((_, label) => ({label: label + 1, value: label + 1}));
    const orders = [
        {
            label: 'Ascending', 
            value: 1
        }, 
        {
            label: 'Descending',
            value: -1
        }
    ];
    const paymentStatus = [
        {
            label: 'Paying', 
            value: 'paying'
        },
        {
            label: 'Non Paying', 
            value: 'nonPaying'
        }
    ]

    return [
        {
            label: 'Sort By',
            type: SINGLE_SELECT_FILTER_TYPE,
            id: 'sortBy',
            value: sorters[1],
            options: sorters
        },
        {
            label: 'Considered Period',
            type: SINGLE_SELECT_FILTER_TYPE,
            id: 'period',
            value: periods[0],
            options: periods
        },
        {
            label: 'Sort Order',
            type: SINGLE_SELECT_FILTER_TYPE,
            id: 'order',
            value: orders[1],
            options: orders
        },
        {
            label: 'Paying customer?',
            type: SINGLE_SELECT_FILTER_TYPE,
            id: 'isPaying',
            value: '',
            options: paymentStatus
        },
        {
            label: `Company Name`,
            type: FREE_TEXT_FILTER_TYPE,
            id: 'name',
            value: '',
        },
        {
            label: 'Travel Agency Name',
            type: FREE_TEXT_FILTER_TYPE,
            id: 'agency',
            value: '',
        }, 
    ];
}

export function showDetails(report) {
    return modals.show(detailsDialog, {report});
}

export function changePaymentStatus({id, paying}) {
    return handle(api.changePaymentStatus(id, {isPaying: !paying}));
}

export function cleanFilters(filters) {
    const sortFilter = filters.find(({id}) => id === 'sortBy'),
        sortBy = sortFilter? sortFilter.value.value: 'directoryOpens';

    return filters.map(({value: filterValue, ...filter}) => {
        if(filter.id === 'period') {
            let options = [];
            if(sortBy === 'bidCounts') {
                const year = moment().format('YYYY');
                options = filter.options.map(({value}) => {
                    return {
                        value,
                        label: value === 1? year: `From ${moment().subtract(value - 1, 'years').format('YYYY')} to ${year}`
                    }
                });
            } else {
                const month = moment().format('MMMM YYYY');
                options = filter.options.map(({value}) => {
                    return {
                        value,
                        label: value === 1? month: `${moment().subtract(value - 1, 'months').format('MMMM YYYY')} - ${month}`,
                        type: sortBy
                    }
                });
            }
            return {
                ...filter,
                options,
                dontSort: true,
                value: filterValue.type === sortBy? filterValue: options[0]
            }
        } else {
            return {...filter, value: filterValue}
        }
    });
}