<script>
  import NotificationTemplate from 'components/modals/NotificationTemplate.basic.svelte';

  export let hide, cancel;

  const MAX_MESSAGE_LENGTH = 1000;

  let message = '';

  $: message = limitMessage(message);

  function submit () {
    hide(message)
  }

  function limitMessage(m = ''){
    return m.length > MAX_MESSAGE_LENGTH ? m.slice(0, MAX_MESSAGE_LENGTH) : m;
  }
</script>

<NotificationTemplate>
  <div class="container">
    <div class="header">
      <div class="title">
        Reject Hotel Request
      </div>
      <div class="subTitle">
        Please specify the reason why you are rejecting this request
      </div>
    </div>
    <div class="Body">
      <label for="message">Reason For Rejection:</label>
      <textarea bind:value={message} name="message" id="message" placeholder="Rejected because..."></textarea>
      <div class="Buttons">
        <button class="rbButton asDark" on:click={cancel}>
          Cancel
        </button>
        <button class="rbButton asDarkMain" data-icon="send" on:click={submit}>
          Save and Send
        </button>
      </div>
    </div>
</div>
</NotificationTemplate>

<style lang="stylus">.container {
  padding: 10px;
  color: #fff;
}
.header {
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 14px;
  font-weight: bold;
}
.subTitle {
  font-size: 12px;
  opacity: 0.7;
}
textarea {
  height: 100px;
  margin-top: 5px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-family: inherit;
  outline: none;
}
.Body {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  font-size: 13px;
}
.Buttons {
  margin: 10px 0;
  text-align: right;
}
</style>
