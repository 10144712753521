<script>
  import debounce from "lodash/debounce";
  import moment from "moment";
  import get from "lodash/get";
  import industries from "../../assets/data/industries";
  import accountTypes from "../../assets/data/account-types";
  import Header from "root/components/Header.svelte";
  import Navigation from "root/components/Navigation.svelte";

  import ResultsTable from "root/pages/hotels/advanced-search/ResultsTable.component.svelte";
  import CheckBox from "root/pages/hotels/advanced-search/Checkbox.component.svelte";
  import FiltersBar from "root/pages/hotels/advanced-search/filters/SearchFilters.component.svelte";
  import Paginator from "root/pages/hotels/advanced-search/Paginator.component.svelte";
  import Select from "components/molecules/inputs/Select.svelte";
  import Actions from "root/pages/hotels/advanced-search/actions/Actions.component.svelte";
  import Entity from "../entities/search/Entity.component.svelte";
  import {getSubscriptionTypeLabel, getSubscriptionTermLabel} from "../../assets/data/subscription-types";

  import {
    loadFilterTypes,
    searchCustomers,
    openProfile
  } from "./search.service";
  import { loadActions } from "./actions.service";

  let filters = [],
    andFilters = true,
    promise = null,
    currentPage = {
      page: 1
    },
    selectedEntities = [],
    loading = false,
    sorter = {
      dir: "asc",
      by: "name-asc",
      key: "name"
    },
    largeButtons,
    onLarge = true,
    props = [
      {
        label: "Sign Up",
        get({ created: { at } }) {
          return moment(at).format("YYYY-MM-DD");
        }
      },
      {
        label: "Last Login",
        get({ lastActive }) {
          return moment(lastActive).format("YYYY-MM-DD HH:MM:SS");
        }
      },
      {
        label: "Visits Last 30 Days",
        get({ dailyVisits }) {
          return dailyVisits? dailyVisits.length: '';
        }
      },
      {
        label: 'Subscription Type',
        get({subscription}){ return getSubscriptionTypeLabel(subscription && subscription.type) || '---' }
      },
      {
        label: 'Subscription Term',
        get({subscription}){ return getSubscriptionTermLabel(subscription && subscription.terms) || '---' }
      },
      {
        label: "Industry",
        get({ industry }) {
          return industries.find(({ id }) => id === industry).label;
        }
      },
      {
        label: "Url",
        key: "website"
      },
      {
        label: "Type",
        get({ type }) {
          const e = accountTypes.find(({ id }) => id === type);
          return e ? e.label : "";
        }
      },
    ],
    propWithSizes = [];

  const filterTypes = loadFilterTypes();

  filters = filterTypes.filter(({ id }) =>
    ["name", "subType", "type", "subTerm"].includes(id)
  );

  function clearFilters() {
    filters = [];
  }

  function applyFilters() {
    selectedEntities = [];

    return search();
  }

  $: results = currentPage.data || [];
  $: allSelected =
    selectedEntities.length > 0 && selectedEntities.length === results.length;
  $: someSelected =
    selectedEntities.length > 0 && selectedEntities.length < results.length;

  function search(page = 1) {
    loading = true;

    promise = searchCustomers(filters, andFilters, page).then(pagination => {
      currentPage = pagination;
      loading = false;
      responsiveBar();

      return pagination.data;
    });
  }

  function next() {
    search(currentPage.page + 1);
  }

  function prev() {
    search(currentPage.page - 1);
  }

  $: propWithSizes = results.reduce((acc, entity) => {
    return acc.map(prop => {
      const value = prop.get ? prop.get(entity) : get(entity, prop.key, ""),
        size = Math.max(prop.size || 0, value.length + prop.label.length);
      return { ...prop, size };
    });
  }, props);
  $: sorters = (function() {
    const options = [
      {
        label: "Name",
        value: "name"
      },
      {
        label: "Industry",
        value: "industry"
      },
      {
        label: "Address",
        value: "location.address.address1"
      },
      {
        label: "City",
        value: "location.address.city"
      },
      {
        label: "State/Region",
        value: "location.address.state",
        key: ({
          location: {
            address: { state, region }
          }
        }) => {
          return state || region;
        }
      },
      {
        label: "Country",
        value: "location.address.country"
      },
      {
        label: "Account Type",
        value: "type"
      },
      {
        label: "Sign Up Date",
        value: "created.at"
      },
      {
        label: "Last Login Date",
        key({ lastActive }) {
          return lastActive? moment(lastActive).format("YYYY-MM-DD HH:MM:SS"): '';
        }
      },
      {
        label: "Visits Last 30 Days",
        key({ dailyVisits }) {
          return dailyVisits? dailyVisits.length: '';
        }
      },
      {
        label: 'Subscription Type',
        value: 'subscription.type'
      },
      {
        label: 'Subscription Term',
        value: 'subscription.terms'
      },
    ];

    return [
      {
        label: "None",
        value: "none",
        key: null
      },
      ...options.reduce(
        (acc, option) => [...acc, make(option), make(option, false)],
        []
      )
    ];

    function make({ label, value, key }, asc = true) {
      const dir = asc ? "asc" : "desc";
      return {
        label: `${label} - ${dir.toUpperCase()}`,
        value: `${value}-${dir}`,
        key: key || value,
        dir
      };
    }
  })();
  $: selectedList = selectedEntities.map(id =>
    results.find(({ _id }) => _id === id)
  );
  $: actions = loadActions(selectedList);

  function responsiveBar() {
    onLarge = true;
    setTimeout(() => {
      onLarge =
        !largeButtons ||
        largeButtons.offsetLeft <= largeButtons.children[0].offsetLeft;
    }, 100);
  }

  function toggleAllSelect() {
    if (!loading) {
      if (allSelected) {
        selectedEntities = [];
      } else {
        selectedEntities = results.map(({ _id }) => _id);
      }
    }
  }

  function sort({ detail: by }) {
    const option = sorters.find(({ value }) => value === by);

    if (option) {
      const key = option.key,
        dir = option.dir;

      sorter = { by, ...option };
      promise = Promise.resolve(results);
    }
  }

  function actionCompleted({ detail: { selected, id, action, response } }) {
    switch (id) {
      case "editImage":
      case "validate":
      case "delete":
        applyFilters();
        break;
      default:
        break;
    }
  }

  function selectEntity({ detail: { _id, selected } }) {
    if (selected) {
      selectedEntities = selectedEntities.filter(id => id !== _id);
    } else {
      selectedEntities = [...selectedEntities, _id];
    }
  }

  function open(e) {
    openProfile(e);
  }
</script>

<style type="text/stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.page-label {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
i.material-icons.page-icon {
  color: #00b8ab;
  height: 40px;
  width: 40px;
  background: #273238;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.labels {
  margin-left: 10px;
}
h1.label-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
p.label-sub-title {
  margin: 0;
  font-size: 10px;
}
.table-header {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #00a995 0%, #006eb9 50%, #00aa4f 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.operator.or::before {
  transform: rotate(90deg);
}
.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.buttons > :global(*) {
  flex-shrink: 0;
}
.sorter {
  display: inline-block;
  min-width: 135px;
  margin-right: 10px;
}
.sorter :global(.rbInput .container) {
  margin-top: 0;
}
.sorter :global(.rbInput .input) {
  min-width: 53px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 53px;
  width: 53px;
  text-overflow: ellipsis;
}
.column.buttons.hide {
  display: none;
}
.column.buttons.onSmall :global(.total) {
  display: none;
}
.small {
  height: 30px;
  font-size: 12px;
}
.small::before {
  font-size: 18px;
}
.searchButton {
  z-index: 1;
}
</style>

<svelte:window on:resize={debounce(responsiveBar, 100)} />

<div class="rbPage rbDark">
  <Header />
  <div class="pageContainer">
    <div class="rbCard navigation">
      <Navigation />
    </div>

    <div class="content">
      <div class="heading">
        {#if selectedEntities.length === 0}
          <div class="column page-label">
            <i class="material-icons page-icon">search</i>
            <div class="labels">
              <h1 class="label-title">Customers Search</h1>
              <p class="label-sub-title">
                Click on "filters" to see all filter options
              </p>
            </div>
          </div>
        {:else}
          <div class="column">
            <Actions
              {actions}
              selected={selectedList}
              on:completed={actionCompleted} />
          </div>
        {/if}

        <div
          class="column buttons"
          bind:this={largeButtons}
          class:hide={!onLarge}>
          <div class="sorter">
            <Select
              id="sort by"
              options={sorters}
              value={sorter.by}
              iconStart="sort"
              on:input={sort}>
              Sort By
            </Select>
          </div>
          <button
            class="rbButton asDark operator"
            class:or={!andFilters}
            data-icon={!andFilters ? 'height' : 'link'}
            on:click={() => (andFilters = !andFilters)}>
            Use &lsaquo;{andFilters ? 'AND' : 'OR'}&rsaquo; Logic
          </button>
          <button
            class="rbButton asDark"
            data-icon="delete_sweep"
            on:click={clearFilters}
            disabled={filters.length === 0}>
            Clear All Filters
          </button>
          <Paginator results={currentPage} on:next={next} on:prev={prev} />
        </div>
        <div class="column buttons onSmall" class:hide={onLarge}>
          <div class="sorter">
            <Select
              id="sort by"
              options={sorters}
              value={sorter.by}
              iconStart="sort"
              on:input={sort}>
              Sort By
            </Select>
          </div>
          <button
            class="rbButton asDark operator iconOnly"
            class:or={!andFilters}
            data-icon={!andFilters ? 'height' : 'link'}
            on:click={() => (andFilters = !andFilters)} />
          <button
            class="rbButton asDark iconOnly"
            data-icon="delete_sweep"
            on:click={clearFilters}
            disabled={filters.length === 0} />
          <Paginator results={currentPage} on:next={next} on:prev={prev} />
        </div>
      </div>
      <div class="table-header">
        <CheckBox
          indeterminate={someSelected}
          selected={allSelected}
          on:toggle={toggleAllSelect} />
        <FiltersBar bind:filters {andFilters} {filterTypes} let:hasValues>
          <button
            class="rbButton asDarkMain small searchButton"
            data-icon="search"
            on:click={applyFilters}
            disabled={loading}>
            Search
          </button>
        </FiltersBar>
      </div>
      <ResultsTable {promise} {sorter}>
        <div slot="item" let:item>
          <Entity
            entity={item}
            {open}
            {selectedEntities}
            props={propWithSizes}
            on:select={selectEntity} />
        </div>
      </ResultsTable>
    </div>
  </div>
</div>
