import adminServer from 'root/api/AdminServer';
import {checkAndExecute} from 'root/api/helpers';

export function search(request) {
    return checkAndExecute(
        () => request,
        () => adminServer.put(`chains/search/`, request)
    );
}

export function changeChain(request) {
    return checkAndExecute(
        () => request,
        () => adminServer.post(`chains/add-to-master-chain`, request)
    );
}

export function useAsChain(brandId) {
    return checkAndExecute(
        () => brandId,
        () => adminServer.post(`chains/${brandId}/use-as-master-chain`)
    );
}

export function loadBrands(chainId) {
    return checkAndExecute(
        () => chainId,
        () => adminServer.get(`chains/${chainId}/get-brands`)
    );
}

export function createNewChain({logo, chain}) {
    const formData = new FormData();

    formData.append('logo', logo);
    formData.append('chain', new Blob([JSON.stringify(chain)], { type: 'application/json' }));

    return adminServer.sendFile(`/chains/create`, formData);
}