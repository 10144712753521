import adminServer from 'root/api/AdminServer';
import {checkAndExecute} from 'root/api/helpers';


/**
 * Searches for users based on certain details.
 * @param {string} query - The search query.
 * @returns {Promise} A promise that resolves to the search results.
 */
function searchUsersByDetails(query){
  return adminServer.post('users/manager/users/search', {query});
}

/**
 * Retrieves a list of users with the most user accounts.
 * @param {boolean} active - Indicates if only active users should be included.
 * @returns {Promise} A promise that resolves to the list of users.
 */
function listUsersWithMostUserAccounts(active){
  return adminServer.get('users/manager/users/top-accounts-count', {active});
}

/**
 * Retrieves a list of users associated with the specified account ID.
 * @param {string} accountId - The ID of the account.
 * @returns {Promise} A promise that resolves to the list of users.
 */
function listUsersForAccount(accountId) {
  return adminServer.get(`users/manager/account/${accountId}/users`);
}

/**
 * Retrieves the details of a specific user.
 * @param {string} userId - The ID of the user.
 * @returns {Promise} A promise that resolves to the user details.
 */
function loadUserDetails(userId){
  return checkAndExecute(
    () => userId,
    () => adminServer.get(`users/manager/users/${userId}`)
  )
}

/**
 * Retrieves the details of a specific user account.
 * @param {string} userAccountId - The ID of the user account.
 * @returns {Promise} A promise that resolves to the user account details.
 */
function loadUserAccountDetails(userAccountId){
  return checkAndExecute(
    () => userAccountId,
    () => adminServer.get(`users/manager/user-accounts/${userAccountId}`)
  )
}

/**
 * Sets the status of a user.
 * @param {string} id - The ID of the user.
 * @param {string} status - The status to set.
 * @returns {Promise} A promise that resolves when the request is complete.
 */
function setUserStatus(id, status){
  return checkAndExecute(
    () => id && status,
    () => adminServer.put(`users/manager/users/${id}/status`, {status})
  )
}

/**
 * Retrieves a list of unvalidated user accounts.
 * @returns {Promise} A promise that resolves to the list of unvalidated user accounts.
 */
function listUnvalidatedUserAccounts(){
  return adminServer.get('users/manager/user-accounts/unvalidated');
}

/**
 * Sets the status of a user account.
 * @param {string} id - The ID of the user account.
 * @param {string} status - The status to set.
 * @returns {Promise} A promise that resolves when the request is complete.
 */
function setUserAccountStatus(id, status){
  return checkAndExecute(
    () => id && status,
    () => adminServer.put(`users/manager/user-accounts/${id}/status`, {status})
  )
}

/**
 * Searches for entities based on certain criteria.
 * @param {string} query - The search query.
 * @param {string} type - The type of entities to search for.
 * @param {number} page - The page number of the search results to retrieve.
 * @returns {Promise} A promise that resolves to the search results.
 */
function searchEntities(query, type, page){
  return adminServer.get(`users/manager/entities/search`, {type, query, page})
}

/**
 * Searches for accounts based on the provided query, type, and page.
 * @param {string} query - The search query.
 * @param {string} type - The type of accounts to search for.
 * @param {number} page - The page number of the search results to retrieve.
 * @returns {Promise} A promise that resolves to the search results.
 */
function searchAccounts(query, type, page){
  return adminServer.get(`users/manager/accounts/search`, {type, query, page})
}

/**
 * Retrieves the approved email address domains for a given account.
 * @param {string} accountId - The ID of the account.
 * @returns {Promise} A promise that resolves to the approved email address domains.
 */
function getApprovedEmailAddressDomains(accountId) {
  return adminServer.get(`users/manager/accounts/${accountId}/approved-email-address-domains`)
}

/**
 * Sets the approved email address domains for an account.
 * @param {string} accountId - The ID of the account.
 * @param {string[]} domains - The approved email address domains.
 * @returns {Promise} A promise that resolves when the request is complete.
 */
function setApprovedEmailAddressDomains (accountId, domains = []) {
  return checkAndExecute(
    () => accountId,
    () => adminServer.put(`users/manager/accounts/${accountId}/approved-email-address-domains`, {domains})
  )
}

/**
 * Provides Temporary Sign In URL
 * @param {string} userId - The ID of the user.
 * @returns {Promise} A promise that resolves when the request is complete.
 */
function getUserTemporarySignInUrl(userId){
  return checkAndExecute(
    () => userId,
    () => adminServer.post(`users/manager/sign-in/link`, {userId})
  )
}

/**
 * Send User Temporary Sign In URL in an email
 * @param {string} userId - The ID of the user.
 * @returns {Promise} A promise that resolves when the request is complete.
 */
function sendUserTemporarySignIn(userId){
  return checkAndExecute(
    () => userId,
    () => adminServer.post(`users/manager/sign-in/email`, {userId})
  )
}

/**
 * The service object containing the exported functions.
 * @namespace service
 */
export default {
  searchUsersByDetails,
  listUsersWithMostUserAccounts,
  loadUserDetails,
  loadUserAccountDetails,
  setUserStatus,
  listUnvalidatedUserAccounts,
  setUserAccountStatus,
  searchEntities,
  searchAccounts,
  listUsersForAccount,
  getApprovedEmailAddressDomains,
  setApprovedEmailAddressDomains,
  getUserTemporarySignInUrl,
  sendUserTemporarySignIn,
}
