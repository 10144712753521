export const FREE_TEXT_FILTER_TYPE = 'text',
    SINGLE_SELECT_FILTER_TYPE = 'single-select',
    MULTI_SELECT_FILTER_TYPE = 'multi-select';

export default (countries = [], states = [], brands = [], chains = [], HMCs) => Object.freeze([
    {
        label: 'Hotel Name',
        type: FREE_TEXT_FILTER_TYPE,
        id: 'name',
        value: '',
    },
    {
        label: 'Sabre Code',
        type: FREE_TEXT_FILTER_TYPE,
        id: 'sabreCode',
        value: '',
    },
    {
        label: 'Address',
        type: FREE_TEXT_FILTER_TYPE,
        id: 'address',
        value: '',
    },
    {
        label: 'Zip',
        type: FREE_TEXT_FILTER_TYPE,
        id: 'zipCode',
        value: '',
    },
    {
        label: 'City',
        type: FREE_TEXT_FILTER_TYPE,
        id: 'city',
        value: '',
    },
    {
        label: 'State',
        type: MULTI_SELECT_FILTER_TYPE,
        id: 'state',
        value: [],
        options: states,
    },
    {
        label: 'Region',
        type: FREE_TEXT_FILTER_TYPE,
        id: 'region',
        value: '',
    },
    {
        label: 'Country',
        type: MULTI_SELECT_FILTER_TYPE,
        id: 'country',
        value: [],
        options: countries,
    },
    {
        label: 'Hotel Brand',
        type: MULTI_SELECT_FILTER_TYPE,
        id: 'brands',
        value: [],
        options: brands
    },
    {
        label: 'Hotel Chain',
        type: MULTI_SELECT_FILTER_TYPE,
        id: 'chains',
        value: [],
        options: chains
    },
    {
      label: 'Hotel Management Company',
      type: MULTI_SELECT_FILTER_TYPE,
      id: 'hotelManagementCompanies',
      value: [],
      options: HMCs
  },
])
