/* eslint-disable babel/quotes,no-unused-vars */
// noinspection JSUnusedLocalSymbols
const tests = {

  "answer variable": "#answerId",
  "global variable": "$globalId",

  "if": [
    {"single if": [ "field:String", "cond:Array[func, value]", "then:Object", "else:Object"] },
    {"if": ["#RM_SRV_AVL", [ "eq","Y" ], {"regex": /^(\d{2}):(\d{2}) - (\d{2}):(\d{2})$/}]},
    {"multiple ifs": [
        [ "field:String", "cond:object", "then:Object", "else:Object"],
        [ "field:String", "cond:object", "then:Object", "else:Object"]
      ]},
    {
      "if": [
        ["#RM_SRV_AVL", [ "eq","Y" ], {"regex": /^(\d{2}):(\d{2}) - (\d{2}):(\d{2})$/}],
        ["#RM_SRV_AVL", [ "eq","N" ], {"value": "00:00 - 00:00"}],
      ]
    },
  ],

  "mandatory questions": "only from template or model, should not be in cell validations"
};

// noinspection JSUnusedGlobalSymbols
export default {
  "id": "root",
  "type": "ROOT",
  "name": "hotel",
  "version": "1.0.0",
  "cells": [
    {
      "id": "PB",
      "type": "MODULE",
      "name": "Property Basic",
      "ord": 1,
      "cells": [
        {
          "id": "PLC",
          "type": "GROUP",
          "name": "Property Location / Contact",
          "ord": 1,
          "cells": [
            {
              "id": "PROPCODE",
              "name": "Property Code",
              "type": "TEXT",
              "classes": "question-size-2",
              "ord": 1,
              "description": "Client (or client vendor) designated property code (is used as a key linking field) Must be the same for all modules. Example: Apollo GDS Property Code 123456",
              "readonly": 1,
              "locked": 1,
            },
            {
              "id": "INTERNALHOTELCODE",
              "name": "Internal Hotel Reference Code",
              "type": "TEXT",
              "classes": "question-size-2",
              "ord": 2,
              "description": "The internal hotel reference code that is assigned by the chain or parent company for tracking the individual hotel. It must be the same for all modules. It may be left blank. (Buyers, do not use this to link modules)",
              "validations": {
                "max": 20
              },
            },
            {
              "id": "PROPNAME",
              "name": "Property Name",
              "type": "TEXT",
              "classes": "question-size-4",
              "ord": 3,
              "description": "Hotel Name Example: \"Eaton Place Hotel\". Please input in appropriate case.",
              "validations": {
                "max": 100
              },
              "locked": 1,
              "readonly": 1
            },
            {
              "id": "PROPADD1",
              "name": "Property Address",
              "type": "TEXT",
              "classes": "question-size-3",
              "ord": 4,
              "description": "Hotel Street Address, Line 1 Example: 100 Main Street. Please input in appropriate case.",
              "validations": {
                "max": 50
              },
              "locked": 1,
              "readonly": 1
            },
            {
              "id": "PROPADD2",
              "name": "Additional Address",
              "type": "TEXT",
              "classes": "question-size-3",
              "ord": 5,
              "description": "Hotel Street Address, Line 2. Please input in appropriate case. (If not applicable, leave blank)",
              "validations": {
                "max": 50
              },
              "readonly":1
            },
            {
              "ord": 6,
              "id": "PROPCITY",
              "name": "City",
              "type": "TEXT",
              "validations": {
                "max": 50
              },
              "classes": "question-size-3",
              "description": "Hotel City Name Example: Toronto. Please input in appropriate case.",
              "locked": 1,
              "readonly": 1
            },
            {
              "ord": 7,
              "id": "PROPSTATEPROV",
              "name": "State/Province",
              "type": "TEXT",
              "validations": {
                "max": 2,
                // "if": [ "#PROPCOUNTRY", [ "included",["United States", "Canada"] ], { "required": true }, { "blank": true } ]
              },
              "locked":1,
              "readonly":1,
              "description": "Two character U.S. state or Canadian province abbreviation (Applies only to U.S. & Canada addresses) Example: NY"
            },
            {
              "ord": 8,
              "id": "PROPCOUNTY",
              "name": "County",
              "type": "TEXT",
              "validations": {
                "max": 50,
                // "if": [ "#PROPCOUNTRY", [ "eq","United States" ], { "required": true }, { "blank": true } ]
              },
              "classes": "question-size-3",
              "description": "Full county name Example: Montgomery",
              "readonly":1
            },
            {
              "ord": 9,
              "id": "PROPREGION",
              "name": "Region",
              "type": "TEXT",
              "validations": {
                "max": 100,
                // "if": [ "#PROPCOUNTRY", [ "included",["United States", "Canada"] ], { "blank": true } ]
              },
              "classes": "question-size-4",
              "locked":1,
              "readonly":1,
              "description": "Province or territory name if not U.S. or Canada address Example: Australian Province name"
            },
            {
              "ord": 10,
              "id": "PROPCOUNTRY",
              "name": "Country",
              "type": "TEXT",
              "validations": {
                "max": 25
              },
              "classes": "question-size-2",
              "description": "Full country name Example: United States",
              "locked": 1,
              "readonly":1
            },
            {
              "ord": 11,
              "id": "PROPPOSTCODE",
              "name": "Postal/Zip Code",
              "type": "TEXT",
              "validations": {
                "max": 10
              },
              "locked":1,
              "readonly":1,
              "description": "Hotel Postal Code or Zip Code Example: M5G 2L0 or 80202"
            },
            {
              "ord": 12,
              "id": "MAINPHONECOUNTRY",
              "name": "Phone Country Code",
              "type": "TEXT",
              "validations": {
                "max": 6
              },
              "description": "Main phone country code Example: 44"
            },
            {
              "ord": 13,
              "id": "MAINPHONECITY",
              "name": "Phone City Code",
              "type": "TEXT",
              "validations": {
                "max": 6
              },
              "description": "Main phone city code or area code Example: 625"
            },
            {
              "ord": 14,
              "id": "MAINPHONE",
              "name": "Main Phone Number",
              "type": "TEXT",
              "validations": {
                "max": 10
              },
              "description": "Main phone local number Example: 555888"
            },
            {
              "ord": 15,
              "id": "MAINFAXCOUNTRY",
              "name": "Fax Country Code",
              "type": "TEXT",
              "validations": {
                "max": 6
              },
              "description": "Main fax country code Example: 44"
            },
            {
              "ord": 16,
              "id": "MAINFAXCITY",
              "name": "Fax City/Area Code",
              "type": "TEXT",
              "validations": {
                "max": 6
              },
              "description": "Main fax city code or area code Example: 625"
            },
            {
              "ord": 17,
              "id": "MAINFAX",
              "name": "Main Fax Number",
              "type": "TEXT",
              "validations": {
                "max": 10
              },
              "description": "Main fax local number Example: 444777"
            },
            {
              "ord": 18,
              "id": "UNITOFMEAS",
              "name": "Unit of measure for distance related questions throughout profile",
              "type": "LIST",
              "options": [{ "value":"KM", "label":"Kilometers"}, {"value": "MI", "label": "Miles" }],
              "validations": {
                "included": ["KM", "MI"]
              },
              "description": "This question only needs to be answered once and will apply to all other questions where KM or MI applies.",
            },
            {
              "ord": 19,
              "id": "PROPURL",
              "name": "Property Website (URL Address)",
              "type": "TEXT",
              "validations": {
                "max": 100
              },
              "classes": "question-size-4",
              "description": "Hotel Internet Address Example: www.Hotel.com/canada/headtext.html"
            },
            {
              "ord": 20,
              "id": "PROPSALESGENREMAIL",
              "name": "Property sales general email address",
              "type": "TEXT",
              "validations": {
                "max": 100
              },
              "classes": "question-size-4",
              "description": "Generic email address of sales department on property, which will be used as a backup if the email address of the sales contact is not deliverable. Example: sales@hotel.com"
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "PO",
          "name": "Property Overview",
          "ord": 2,
          "cells": [
            {
              "ord": 21,
              "id": "FULL_OR_LIM",
              "name": "Is hotel Full or Limited Service?",
              "type": "LIST",
              "options": [{"value": "F", "label": "Full"},{"value": "L", "label": "Limited"}],
              "validations": {
                "included": ["F", "L"]
              }
            },
            {
              "ord": 22,
              "id": "ES_STAY",
              "name": "Is hotel Extended Stay or Residential Apartment?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 23,
              "id": "MKT_TIER",
              "name": "Which of the following market tier/price segments classifies your hotel",
              "type": "LIST",
              "options": [{"value": "L", "label":"Luxury"}, {"value":"UU", "label":"Upper Upscale"}, {"value":"U", "label":"Upscale"}, {"value":"UM", "label":"Upper Midscale"}, {"value":"M", "label":"Midscale"}, {"value":"E", "label":"Economy"}],
              "validations": {
                "included": ["L", "UU", "U", "UM", "M", "E"]
              },
              "description": "(U.S. hotels, please use Smith Travel Research market tier/price segment for your hotel)"
            },
            {
              "ord": 24,
              "id": "YRBUILT",
              "name": "Year the property was built",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 9999]
              },
              "description": "Example: 1984"
            },
            {
              "ord": 25,
              "id": "LASTREN",
              "name": "Year of last complete guest room renovation",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 9999]
              },
              "description": "Example: 2001"
            },
            {
              "ord": 26,
              "id": "NUMROOMS",
              "name": "Total number of rooms (including suites)",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 9999]
              },
              "description": "Example: 452"
            },
            {
              "ord": 27,
              "id": "NUMNOSMK",
              "name": "Number of non-smoking rooms (including suites)",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 9999]
              },
              "description": "Example: 175"
            },
            {
              "ord": 28,
              "id": "NUMFLRS",
              "name": "Number of floors",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 999]
              },
              "description": "Example: 8"
            },
            {
              "ord": 29,
              "id": "MGMTCOMPANY",
              "name": "Management Company",
              "type": "TEXT",
              "validations": {
                "max": 100
              },
              "classes": "question-size-4"
            },
            {
              "ord": 30,
              "id": "OWNERCOMPANY",
              "name": "Ownership Company",
              "type": "TEXT",
              "validations": {
                "max": 100
              },
              "classes": "question-size-4"
            },
            {
              "ord": 31,
              "id": "PROPOVERVIEW",
              "name": "Please provide a brief property overview",
              "type": "TEXT",
              "validations": {
                "max": 300
              },
              "classes": "question-size-4",
              "description": "Do not enter quotes in the description."
            },
            {
              "ord": 32,
              "id": "PROP_LOC",
              "name": "Property location",
              "type": "LIST",
              "options": [{"value":"A","label":"Airport"}, {"value":"D", "label":"Downtown/City Center"}, {"value":"S", "label":"Suburb"}, {"value":"R", "label":"Rural"}, {"value":"T", "label":"Resort" }],
              "validations": {
                "included": ["A", "D", "S", "R", "T"]
              }
            },
            {
              "ord": 33,
              "id": "AIRCITYCODE",
              "name": "Airport City Code for Metro Area",
              "type": "TEXT",
              "validations": {
                "max": 3
              },
              "description": "Examples: PAR (Paris), NYC (New York City) DAL (Dallas)"
            },
            {
              "ord": 34,
              "id": "AIR1CODE",
              "name": "3-letter Airport code for nearest major airport",
              "type": "TEXT",
              "validations": {
                "max": 3
              },
              "description": "Example: YYZ"
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "FPPD",
          "name": "Facilities for People with Physical Disabilities",
          "ord": 3,
          "cells": [
            {
              "ord": 35,
              "id": "DISABCOMPLY",
              "name": "Does hotel comply with all local and national disability laws?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 36,
              "id": "DISABACCSS",
              "name": "Are facilities/public areas accessible by people with physical disabilities?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 37,
              "id": "NUMDISABL",
              "name": "Total number of rooms for people with physical disabilities",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 9999]
              },
              "description": "Example: 50"
            },
            {
              "ord": 38,
              "id": "HAND_PARK",
              "name": "Does hotel have handicap parking?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "SA",
          "name": "Services / Amenities",
          "ord": 4,
          "cells": [
            {
              "ord": 39,
              "id": "REST_ONSITE",
              "name": "Restaurant on site?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 40,
              "id": "RM_SRV_AVL",
              "name": "Is Room Service available?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 41,
              "id": "RM_SRV_HRS",
              "name": "If yes, enter hours of operation (use 24h clock)",
              "type": "TEXT",
              "validations": {
                "max": 25,
                "regex": /^(\d{2}):(\d{2}) - (\d{2}):(\d{2})$/,
                "if": [
                  [ "#RM_SRV_AVL", [ "eq","N" ], { "value": "00:00 - 00:00"} ],
                ],
              },
              "classes": "question-size-2",
              "description": "(Please use 24h clock ex:00:00 - 23:59) If none, enter hours 00:00 - 00:00"
            },
            {
              "ord": 42,
              "id": "CONVENENCE_FOOD",
              "name": "Is there a food outlet that serves food to take away such as a \"Grab & Go?\"",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 43,
              "id": "BUS_CENTER",
              "name": "On-site Business Center?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 44,
              "id": "FRNT_DESK",
              "name": "Front Desk hours (use 24h clock)",
              "type": "TEXT",
              "validations": {
                "max": 25
              },
              "classes": "question-size-2",
              "description": "(Please use 24h clock ex:00:00 - 23:59)"
            },
            {
              "ord": 45,
              "id": "AIR_CONDITIONED",
              "name": "Do all guest rooms have air conditioning?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 46,
              "id": "PRIVATE_BATHSA",
              "name": "Do all guest rooms have private bath room?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "CCA",
          "name": "Credit Cards",
          "ord": 5,
          "cells": [
            {
              "ord": 47,
              "id": "AMEXCARD",
              "name": "American Express",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "Hotel accepts American Express charge card?"
            },
            {
              "ord": 48,
              "id": "DINERSCARD",
              "name": "Diners/Carte Blanche",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "Hotel accepts Diner's Club/Carte Blanche charge card?"
            },
            {
              "ord": 49,
              "id": "DISCOVERCARD",
              "name": "Discover",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "Hotel accepts Discover credit card?"
            },
            {
              "ord": 50,
              "id": "JCBCARD",
              "name": "JCB",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "Hotel accepts JCB credit card?"
            },
            {
              "ord": 51,
              "id": "MASTERCARD",
              "name": "MasterCard/Eurocard",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "Hotel accepts MasterCard/Eurocard credit card?"
            },
            {
              "ord": 52,
              "id": "VISACARD",
              "name": "VISA",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "Hotel accepts Visa credit card?"
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "PGDSCPC",
          "name": "Property's GDS Chain And Property Codes",
          "ord": 6,
          "cells": [
            {
              "ord": 53,
              "id": "AMADEUS_CHAINCODE",
              "name": "Amadeus Chain Code",
              "type": "TEXT",
              "validations": {
                "max": 2
              },
              "description": "(GDS-specific 2-letter chain code)"
            },
            {
              "ord": 54,
              "id": "AMADEUS_PROPCODE",
              "name": "Amadeus property code",
              "type": "TEXT",
              "validations": {
                "max": 6
              },
              "description": "Amadeus property code (DO NOT include chain code) enter a combination of numbers and letters Example: YY1234"
            },
            {
              "ord": 55,
              "id": "APOLLO_CHAINCODE",
              "name": "Apollo/Galileo Chain Code",
              "type": "TEXT",
              "validations": {
                "max": 2
              },
              "description": "(GDS-specific 2-letter chain code)"
            },
            {
              "ord": 56,
              "id": "APOLLO_PROPCODE",
              "name": "Apollo/Galileo Property Code",
              "type": "TEXT",
              "validations": {
                "max": 5
              },
              "description": "Apollo/Galileo Property Code (DO NOT include chain code) enter numbers only Example: 12345"
            },
            {
              "ord": 57,
              "id": "SABRE_CHAINCODE",
              "name": "Sabre Chain Code",
              "type": "TEXT",
              "validations": {
                "max": 2
              },
              "description": "(GDS-specific 2-letter chain code)"
            },
            {
              "ord": 58,
              "id": "SABRE_PROPCODE",
              "name": "Sabre Property Code",
              "type": "TEXT",
              "validations": {
                "max": 7
              },
              "description": "Sabre Property Code (DO NOT include chain code) enter numbers only Example: 1234567. This code must be all numeric. The code must be at least 1 digit and no more than 7 digits."
            },
            {
              "ord": 59,
              "id": "WORLDSPAN_CHAINCODE",
              "name": "Worldspan Chain Code",
              "type": "TEXT",
              "validations": {
                "max": 2
              },
              "description": "(GDS-specific 2-letter chain code)"
            },
            {
              "ord": 60,
              "id": "WRLDSPAN_PROPCODE",
              "name": "Worldspan Property Code",
              "type": "TEXT",
              "validations": {
                "max": 5
              },
              "description": "Worldspan Property Code (DO NOT include chain code) enter a combination of numbers and letters Example: YY123"
            },
            {
              "ord": 61,
              "id": "MASTERCHAINCODE",
              "name": "MASTER CHAIN CODE",
              "type": "TEXT",
              "validations": {
                "max": 2
              },
              "description": "Chain code that links all brands (If not applicable, leave blank)"
            }
          ]
        }
      ]
    },
    {
      "type": "MODULE",
      "id": "CS",
      "name": "Client Specific",
      "ord": 2,
      "cells": [
        {
          "type": "GROUP",
          "id": "RBO",
          "name": "Rates Being Offering to",
          "ord": 1,
          "cells": [
            {
              "ord": 62,
              "id": "CLIENT_NAME",
              "name": "Name of client these rates are being offered to",
              "type": "TEXT",
              "validations": {
                "max": 50
              },
              "classes": "question-size-3",
              "description": "Example: Some client, Inc.",
            },
            {
              "ord": 63,
              "id": "RM_NIGHTS",
              "name": "Client room nights produced at property from January 1 through June 30",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 99999999999]
              },
              "description": "Example: 362"
            },
            {
              "ord": 64,
              "id": "RATE_CURR",
              "name": "3-letter IMF Currency Code used throughout pricing profile when quoting rates/charges",
              "type": "TEXT",
              "validations": {
                "max": 3
              },
              "description": "Example: USD",
              "locked": 1,
              "req": 1
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "DP",
          "name": "Dynamic Pricing",
          "ord": 2,
          "cells": [
            {
              "ord": 65,
              "id": "DYNAMIC_PRICING",
              "name": "Would you be willing to offer a Dynamic rate option in lieu of a static negotiated rate",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 66,
              "id": "DYNAMIC_PCT_Discount",
              "name": "If a Dynamic rate option is offered, what is the percentage off BAR (best available rate)",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 9999999999],
                "if": [
                  [ "#DYNAMIC_PRICING", [ "eq","N" ], { "blank":true } ],
                ]
              }
            }
          ]
        },
        {
          "type": "HOTEL_RFP_RATE_GRID",
          "id": "RTG",
          "locked": 1,
          "ord": 3,
          "filters": {
            "rate": [ "LRA", "NLRA", "GOVT" ],
            "occupancy": [ "SGL", "DBL" ],
            "season": [ 1,2,3,4,5 ],
            "roomType": [ 1,2,3 ]
          },
          "defaultFilters": {
            "rate": [ "LRA", "NLRA"],
            "occupancy": [ "SGL" ],
            "season": [ 1,2,3,4,5 ],
            "roomType": [ 1 ]
          },
          "actions": {
            // "season": {
            //   "id": "season",
            //   "label": "How many Seasons will you allow?",
            //   "type": "filter",
            //   "options": [
            //     { "label": "One Season", "filter": [ 1 ] },
            //     { "label": "Two Seasons", "filter": [ 1,2 ] },
            //     { "label": "Three Seasons", "filter": [ 1,2,3 ] },
            //     { "label": "Four Seasons", "filter": [ 1,2,3,4 ] },
            //     { "label": "Five Seasons", "filter": [ 1,2,3,4,5 ], "default": 1 }
            //   ]
            // },
            "roomType": {
              "id": "roomType",
              "label": "How many Room Types will you allow?",
              "type": "filter",
              "options": [
                { "label": "One Room Type", "filter": [ 1 ], "default": 1},
                { "label": "Two Room Types", "filter": [ 1,2 ]},
                { "label": "Three Room Types", "filter": [ 1,2,3 ]}
              ]
            },
            "rate": {
              "id": "rate",
              "label": "Rates",
              "type": "toggle",
              "options": [
                { "id": "LRA", "label": "LRA Rate"},
                { "id": "NLRA", "label": "NLRA Rate"},
                { "id": "GOVT", "label": "GOVT Rate"}
              ]
            },
            "occupancy": {
              "id": "occupancy",
              "label": "Occupancy",
              "type": "filter",
              "options": [
                { "label": "Single Only", "default": 1, "filter": [ "SGL" ], },
                { "label": "Double Only", "filter": [ "DBL" ], },
                { "label": "Both", "filter": [ "SGL","DBL" ] },
              ]
            }
          },
          "cells": [
            {
              "type": "GROUP",
              "id": "RTD",
              "name": "Room Type Definitions",
              "ord": 3,
              "locked": 1,
              "cells": [
                {
                  "ord": 67,
                  "id": "ROOMTYPE1DEFINE",
                  "name": "Define room type 1 for your property",
                  "type": "TEXT",
                  "validations": {
                    "max": 25
                  },
                  "classes": "question-size-2",
                  "description": "Example: One king bed",
                  "locked": 1,
                },
                {
                  "ord": 68,
                  "id": "ROOMTYPE1NUMBER",
                  "name": "Number of room type 1 included in negotiated rate",
                  "type": "NUMBER",
                  "locked": 1,
                  "validations": {
                    "numeric": true,
                    "between": [0, 9999]
                  },
                },
                {
                  "ord": 69,
                  "id": "ROOMTYPE2DEFINE",
                  "name": "Define room type 2 for your property",
                  "type": "TEXT",
                  "locked": 1,
                  "validations": {
                    "max": 25
                  },
                  "classes": "question-size-2",
                  "description": "Example: Larger room than room type 1 with view",
                },
                {
                  "ord": 70,
                  "id": "ROOMTYPE2NUMBER",
                  "name": "Number of room type 2 included in negotiated rate",
                  "type": "NUMBER",
                  "locked": 1,
                  "validations": {
                    "numeric": true,
                    "between": [0, 9999]
                  },
                },
                {
                  "ord": 71,
                  "id": "ROOMTYPE3DEFINE",
                  "name": "Define room type 3 for your property",
                  "type": "TEXT",
                  "locked": 1,
                  "validations": {
                    "max": 25
                  },
                  "classes": "question-size-2",
                  "description": "Example: A double room",
                },
                {
                  "ord": 72,
                  "id": "ROOMTYPE3NUMBER",
                  "name": "Number of room type 3 included in negotiated rate",
                  "type": "NUMBER",
                  "locked": 1,
                  "validations": {
                    "numeric": true,
                    "between": [0, 9999]
                  }
                }
              ]
            },
            {
              "id": "RT",
              "type": "TABLE",
              "name": "Rate Grid",
              "classes": "center-80 mandatory",
              "manageRows": [
                { "id": "season", "label" : "Season" },
                { "id": "roomType", "label" : "Room Type" }
              ],
              "validations": [
                { "id": "RATE", "type": "maxLength", "value": 2, "message": "At least one Rate is required!"}
              ],
              "ord": 4,
              "cells": [
                {
                  "type": "ROW",
                  "classes": "row-header",
                  "cells": [
                    {
                      "colspan": 3
                    },
                    {
                      "id": "LRA_HEADER",
                      "for": {
                        "rate": "LRA"
                      },
                      "name": "LRA",
                      "description": "Client Negotiated LRA",
                      "colspan": 2,
                      "colspanId": "occupancy",
                    },
                    {
                      "id": "NLRA_HEADER",
                      "for": {
                        "rate": "NLRA"
                      },
                      "name": "NLRA",
                      "description": "Client Negotiated Non-LRA*",
                      "colspan": 2,
                      "colspanId": "occupancy",
                      "variable": true
                    },
                    {
                      "id": "GOVT_HEADER",
                      "for": {
                        "rate": "GOVT"
                      },
                      "name": "GOV",
                      "description": "Government",
                      "colspan": 2,
                      "colspanId": "occupancy",
                      "variable": true
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 1
                  },
                  "cells": [
                    {
                      "id": "Season",
                      "colspan": 2,
                      "name": "Season 1",
                      "classes": "strong"
                    },
                    {
                      "id": "RoomType",
                      "name": "RT",
                      "classes": "text-center"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL"
                      },
                      "name": "Single"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "name": "Double"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL"
                      },
                      "name": "Single"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "name": "Double"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "name": "Single"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "name": "Double"
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 1,
                    "roomType": 1
                  },
                  "cells": [
                    {
                      "rowspan": 3,
                      "rowspanId": "roomType",
                      "cell": {
                        "ord": 73,
                        "id": "SEASON1START",
                        "name": "Start",
                        "type": "DATE",
                        "placeholder": "Start Date",
                        "validations": {
                          "date_format": "yyyy-MM-dd",
                          "after": ["$programStartDate", true],
                          "before": [ "$programEndDate", true ]
                        },
                        "description": "Season 1 start date. Use ISO 8601 format - Example: 2011-12-31",
                      }
                    },
                    {
                      "rowspan": 3,
                      "rowspanId": "roomType",
                      "cell": {
                        "ord": 74,
                        "id": "SEASON1END",
                        "name": "End",
                        "type": "DATE",
                        "placeholder": "End Date",
                        "locked": 1,
                        "validations": {
                          "date_format": "yyyy-MM-dd",
                          "after": ["$programStartDate", true],
                          "before": [ "$programEndDate", true ],
                          "if": [
                            [ "#SEASON1START", [ "isBlank",false ], { "after": ["#SEASON1START", true] } ],
                            [ "#SEASON2START", [ "isBlank",false ], { "before": "#SEASON2START" } ]
                          ]
                        },
                        "description": "Season 1 end date. Use ISO 8601 format - Example: 2011-12-31"
                      }
                    },
                    {
                      "id": "RoomType",
                      "name": 1,
                      "classes": "text-center strong"
                    },
                    {
                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 75,
                        "id": "LRA_S1_RT1_SGL",
                        "name": "Client negotiated LRA rate for Room Type 1 Single in Season 1 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 76,
                        "id": "LRA_S1_RT1_DBL",
                        "name": "Client negotiated LRA rate for Room Type 1 Double in Season 1 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 81,
                        "id": "NLRA_S1_RT1_SGL",
                        "name": "Client negotiated NLRA rate for Room Type 1 Single in Season 1 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 82,
                        "id": "NLRA_S1_RT1_DBL",
                        "name": "Client negotiated NLRA rate for Room Type 1 Double in Season 1 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 87,
                        "id": "GOVT_S1_RT1_SGL",
                        "name": "Government/Military rate for Room Type 1 in Season 1 per night Single occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 88,
                        "id": "GOVT_S1_RT1_DBL",
                        "name": "Government/Military rate for Room Type 1 in Season 1 per night Double occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 1,
                    "roomType": 2
                  },
                  "cells": [
                    {
                      "id": "RoomType",
                      "name": 2,
                      "classes": "text-center strong"
                    },
                    {
                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 77,
                        "id": "LRA_S1_RT2_SGL",
                        "name": "Client negotiated LRA rate for Room Type 2 Single in Season 1 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 78,
                        "id": "LRA_S1_RT2_DBL",
                        "name": "Client negotiated LRA rate for Room Type 2 Double in Season 1 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 83,
                        "id": "NLRA_S1_RT2_SGL",
                        "name": "Client negotiated NLRA rate for Room Type 2 Single in Season 1 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 84,
                        "id": "NLRA_S1_RT2_DBL",
                        "name": "Client negotiated NLRA rate for Room Type 2 Double in Season 1 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 89,
                        "id": "GOVT_S1_RT2_SGL",
                        "name": "Government/Military rate for Room Type 2 in Season 1 per night Single occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 90,
                        "id": "GOVT_S1_RT2_DBL",
                        "name": "Government/Military rate for Room Type 2 in Season 1 per night Double occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 1,
                    "roomType": 3
                  },
                  "cells": [
                    {
                      "id": "RoomType",
                      "name": 3,
                      "classes": "text-center strong"
                    },
                    {
                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 79,
                        "id": "LRA_S1_RT3_SGL",
                        "name": "Client negotiated LRA rate for Room Type 3 Single in Season 1 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 80,
                        "id": "LRA_S1_RT3_DBL",
                        "name": "Client negotiated LRA rate for Room Type 3 Double in Season 1 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 85,
                        "id": "NLRA_S1_RT3_SGL",
                        "name": "Client negotiated NLRA rate for Room Type 3 Single in Season 1 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 86,
                        "id": "NLRA_S1_RT3_DBL",
                        "name": "Client negotiated NLRA rate for Room Type 3 Double in Season 1 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 91,
                        "id": "GOVT_S1_RT3_SGL",
                        "name": "Government/Military rate for Room Type 3 in Season 1 per night Single occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 92,
                        "id": "GOVT_S1_RT3_DBL",
                        "name": "Government/Military rate for Room Type 3 in Season 1 per night Double occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 2
                  },
                  "cells": [
                    {
                      "id": "Season",
                      "colspan": 2,
                      "name": "Season 2",
                      "classes": "strong"
                    },
                    {
                      "id": "RoomType",
                      "name": "RT",
                      "classes": "text-center"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "name": "Single"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "name": "Double"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "name": "Single"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "name": "Double"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "name": "Single"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "name": "Double"
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 2,
                    "roomType": 1
                  },
                  "cells": [
                    {
                      "rowspan": 3,
                      "rowspanId": "roomType",
                      "cell": {
                        "ord": 93,
                        "id": "SEASON2START",
                        "name": "Start",
                        "type": "DATE",
                        "placeholder": "Start Date",
                        "validations": {
                          "date_format": "yyyy-MM-dd",
                          "after": ["$programStartDate", true],
                          "before": [ "$programEndDate", true ],
                          "if": [
                            [ "#SEASON1END", [ "isBlank",false ], { "after": "#SEASON1END" } ],
                            [ "#SEASON2END", [ "isBlank",false ], { "before": ["#SEASON2END", true] } ]
                          ]
                        },
                        "description": "Season 2 start date. Use ISO 8601 format - Example: 2011-12-31",
                      }
                    },
                    {
                      "rowspan": 3,
                      "rowspanId": "roomType",
                      "cell": {
                        "ord": 94,
                        "id": "SEASON2END",
                        "name": "End",
                        "type": "DATE",
                        "placeholder": "End Date",
                        "validations": {
                          "date_format": "yyyy-MM-dd",
                          "after": ["$programStartDate", true],
                          "before": ["$programEndDate", true],
                          "if": [
                            [ "#SEASON2START", [ "isBlank",false ], { "after": ["#SEASON2START", true]} ],
                            [ "#SEASON3START", [ "isBlank",false ], { "before": "#SEASON3START" } ]
                          ]
                        },
                        "description": "Season 2 end date. Use ISO 8601 format - Example: 2011-12-31",
                      }
                    },
                    {
                      "id": "RoomType",
                      "name": 1,
                      "classes": "text-center strong"
                    },
                    {
                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 95,
                        "id": "LRA_S2_RT1_SGL",
                        "name": "Client negotiated LRA rate for Room Type 1 Single in Season 2 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 96,
                        "id": "LRA_S2_RT1_DBL",
                        "name": "Client negotiated LRA rate for Room Type 1 Double in Season 2 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 101,
                        "id": "NLRA_S2_RT1_SGL",
                        "name": "Client negotiated NLRA rate for Room Type 1 Single in Season 2 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 102,
                        "id": "NLRA_S2_RT1_DBL",
                        "name": "Client negotiated NLRA rate for Room Type 1 Double in Season 2 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 107,
                        "id": "GOVT_S2_RT1_SGL",
                        "name": "Government/Military rate for Room Type 1 in Season 2 per night Single occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 108,
                        "id": "GOVT_S2_RT1_DBL",
                        "name": "Government/Military rate for Room Type 1 in Season 2 per night Double occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 2,
                    "roomType": 2
                  },
                  "cells": [
                    {
                      "id": "RoomType",
                      "name": 2,
                      "classes": "text-center strong"
                    },
                    {
                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 97,
                        "id": "LRA_S2_RT2_SGL",
                        "name": "Client negotiated LRA rate for Room Type 2 Single in Season 2 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 98,
                        "id": "LRA_S2_RT2_DBL",
                        "name": "Client negotiated LRA rate for Room Type 2 Double in Season 2 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 103,
                        "id": "NLRA_S2_RT2_SGL",
                        "name": "Client negotiated NLRA rate for Room Type 2 Single in Season 2 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 104,
                        "id": "NLRA_S2_RT2_DBL",
                        "name": "Client negotiated NLRA rate for Room Type 2 Double in Season 2 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 109,
                        "id": "GOVT_S2_RT2_SGL",
                        "name": "Government/Military rate for Room Type 2 in Season 2 per night Single occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 110,
                        "id": "GOVT_S2_RT2_DBL",
                        "name": "Government/Military rate for Room Type 2 in Season 2 per night Double occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 2,
                    "roomType": 3
                  },
                  "cells": [
                    {
                      "id": "RoomType",
                      "name": 3,
                      "classes": "text-center strong"
                    },
                    {
                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 99,
                        "id": "LRA_S2_RT3_SGL",
                        "name": "Client negotiated LRA rate for Room Type 3 Single in Season 2 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 100,
                        "id": "LRA_S2_RT3_DBL",
                        "name": "Client negotiated LRA rate for Room Type 3 Double in Season 2 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 105,
                        "id": "NLRA_S2_RT3_SGL",
                        "name": "Client negotiated NLRA rate for Room Type 3 Single in Season 2 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 106,
                        "id": "NLRA_S2_RT3_DBL",
                        "name": "Client negotiated NLRA rate for Room Type 3 Double in Season 2 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 111,
                        "id": "GOVT_S2_RT3_SGL",
                        "name": "Government/Military rate for Room Type 3 in Season 2 per night Single occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 112,
                        "id": "GOVT_S2_RT3_DBL",
                        "name": "Government/Military rate for Room Type 3 in Season 2 per night Double occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 3
                  },
                  "cells": [
                    {
                      "id": "Season",
                      "colspan": 2,
                      "name": "Season 3",
                      "classes": "strong"
                    },
                    {
                      "id": "RoomType",
                      "name": "RT",
                      "classes": "text-center"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "name": "Single"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "name": "Double"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "name": "Single"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "name": "Double"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "name": "Single"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "name": "Double"
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 3,
                    "roomType": 1
                  },
                  "cells": [
                    {
                      "rowspan": 3,
                      "rowspanId": "roomType",

                      "cell": {
                        "ord": 113,
                        "id": "SEASON3START",
                        "name": "Start",
                        "type": "DATE",
                        "placeholder": "Start Date",
                        "validations": {
                          "date_format": "yyyy-MM-dd",
                          "after": ["$programStartDate", true],
                          "before": ["$programEndDate", true],
                          "if": [
                            [ "#SEASON2END", [ "isBlank",false ], { "after": "#SEASON2END" } ],
                            [ "#SEASON3END", [ "isBlank",false ], { "before": ["#SEASON3END",true] } ]
                          ]
                        },
                        "description": "Season 3 start date. Use ISO 8601 format - Example: 2011-12-31",
                      }
                    },
                    {
                      "rowspan": 3,
                      "rowspanId": "roomType",

                      "cell": {
                        "ord": 114,
                        "id": "SEASON3END",
                        "name": "End",
                        "type": "DATE",
                        "placeholder": "End Date",
                        "validations": {
                          "date_format": "yyyy-MM-dd",
                          "after": ["$programStartDate", true],
                          "before": ["$programEndDate", true],
                          "if": [
                            [ "#SEASON3START", [ "isBlank",false ], { "after": ["#SEASON3START",true] } ],
                            [ "#SEASON4START", [ "isBlank",false ], { "before": "#SEASON4START" } ]
                          ]
                        },
                        "description": "Season 3 end date. Use ISO 8601 format - Example: 2011-12-31",
                      }
                    },
                    {
                      "id": "RoomType",
                      "name": 1,
                      "classes": "text-center strong"
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 115,
                        "id": "LRA_S3_RT1_SGL",
                        "name": "Client negotiated LRA rate for Room Type 1 Single in Season 3 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 116,
                        "id": "LRA_S3_RT1_DBL",
                        "name": "Client negotiated LRA rate for Room Type 1 Double in Season 3 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 121,
                        "id": "NLRA_S3_RT1_SGL",
                        "name": "Client negotiated NLRA rate for Room Type 1 Single in Season 3 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 122,
                        "id": "NLRA_S3_RT1_DBL",
                        "name": "Client negotiated NLRA rate for Room Type 1 Double in Season 3 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 127,
                        "id": "GOVT_S3_RT1_SGL",
                        "name": "Government/Military rate for Room Type 1 in Season 3 per night Single occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 128,
                        "id": "GOVT_S3_RT1_DBL",
                        "name": "Government/Military rate for Room Type 1 in Season 3 per night Double occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 3,
                    "roomType": 2
                  },
                  "cells": [
                    {
                      "id": "RoomType",
                      "actions": ["RT"],
                      "name": 2,
                      "classes": "text-center strong"
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 117,
                        "id": "LRA_S3_RT2_SGL",
                        "name": "Client negotiated LRA rate for Room Type 2 Single in Season 3 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 118,
                        "id": "LRA_S3_RT2_DBL",
                        "name": "Client negotiated LRA rate for Room Type 2 Double in Season 3 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 123,
                        "id": "NLRA_S3_RT2_SGL",
                        "name": "Client negotiated NLRA rate for Room Type 2 Single in Season 3 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 124,
                        "id": "NLRA_S3_RT2_DBL",
                        "name": "Client negotiated NLRA rate for Room Type 2 Double in Season 3 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 129,
                        "id": "GOVT_S3_RT2_SGL",
                        "name": "Government/Military rate for Room Type 2 in Season 3 per night Single occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 130,
                        "id": "GOVT_S3_RT2_DBL",
                        "name": "Government/Military rate for Room Type 2 in Season 3 per night Double occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 3,
                    "roomType": 3
                  },
                  "cells": [
                    {
                      "id": "RoomType",
                      "name": 3,
                      "classes": "text-center strong"
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 119,
                        "id": "LRA_S3_RT3_SGL",
                        "name": "Client negotiated LRA rate for Room Type 3 Single in Season 3 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 120,
                        "id": "LRA_S3_RT3_DBL",
                        "name": "Client negotiated LRA rate for Room Type 3 Double in Season 3 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 125,
                        "id": "NLRA_S3_RT3_SGL",
                        "name": "Client negotiated NLRA rate for Room Type 3 Single in Season 3 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 126,
                        "id": "NLRA_S3_RT3_DBL",
                        "name": "Client negotiated NLRA rate for Room Type 3 Double in Season 3 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 131,
                        "id": "GOVT_S3_RT3_SGL",
                        "name": "Government/Military rate for Room Type 3 in Season 3 per night Single occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 132,
                        "id": "GOVT_S3_RT3_DBL",
                        "name": "Government/Military rate for Room Type 3 in Season 3 per night Double occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    }
                  ]
                },
                {
                  "for": {
                    "season": 4,
                  },
                  "type": "ROW",
                  "cells": [
                    {
                      "id": "Season",
                      "colspan": 2,
                      "name": "Season 4",
                      "classes": "strong"
                    },
                    {
                      "id": "RoomType",
                      "name": "RT",
                      "classes": "text-center"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "name": "Single"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "name": "Double"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "name": "Single"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "name": "Double"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "name": "Single"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "name": "Double"
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 4,
                    "roomType": 1
                  },
                  "cells": [
                    {
                      "rowspan": 3,
                      "rowspanId": "roomType",

                      "cell": {
                        "ord": 133,
                        "id": "SEASON4START",
                        "name": "Start",
                        "type": "DATE",
                        "placeholder": "Start Date",
                        "validations": {
                          "date_format": "yyyy-MM-dd",
                          "after": ["$programStartDate", true],
                          "before": ["$programEndDate", true],
                          "if": [
                            [ "#SEASON3END", [ "isBlank",false ], { "after": "#SEASON3END" } ],
                            [ "#SEASON4END", [ "isBlank",false ], { "before": ["#SEASON4END",true] } ]
                          ]
                        },
                        "description": "Season 4 start date. Use ISO 8601 format - Example: 2011-12-31",
                      }
                    },
                    {
                      "rowspan": 3,
                      "rowspanId": "roomType",

                      "cell": {
                        "ord": 134,
                        "id": "SEASON4END",
                        "name": "End",
                        "type": "DATE",
                        "placeholder": "End Date",
                        "validations": {
                          "date_format": "yyyy-MM-dd",
                          "after": ["$programStartDate", true],
                          "before": ["$programEndDate", true],
                          "if": [
                            [ "#SEASON4START", [ "isBlank",false ], { "after": ["#SEASON4START",true] } ],
                            [ "#SEASON5START", [ "isBlank",false ], { "before": "#SEASON5START" } ]
                          ]
                        },
                        "description": "Season 4 end date. Use ISO 8601 format - Example: 2011-12-31",
                      }
                    },
                    {
                      "id": "RoomType",
                      "name": 1,
                      "classes": "text-center strong"
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 135,
                        "id": "LRA_S4_RT1_SGL",
                        "name": "Client negotiated LRA rate for Room Type 1 Single in Season 4 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 136,
                        "id": "LRA_S4_RT1_DBL",
                        "name": "Client negotiated LRA rate for Room Type 1 Double in Season 4 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 141,
                        "id": "NLRA_S4_RT1_SGL",
                        "name": "Client negotiated NLRA rate for Room Type 1 Single in Season 4 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 142,
                        "id": "NLRA_S4_RT1_DBL",
                        "name": "Client negotiated NLRA rate for Room Type 1 Double in Season 4 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 147,
                        "id": "GOVT_S4_RT1_SGL",
                        "name": "Government/Military rate for Room Type 1 in Season 4 per night Single occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 148,
                        "id": "GOVT_S4_RT1_DBL",
                        "name": "Government/Military rate for Room Type 1 in Season 4 per night Double occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 4,
                    "roomType": 2
                  },
                  "cells": [
                    {
                      "id": "RoomType",
                      "name": 2,
                      "classes": "text-center strong"
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 137,
                        "id": "LRA_S4_RT2_SGL",
                        "name": "Client negotiated LRA rate for Room Type 2 Single in Season 4 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 138,
                        "id": "LRA_S4_RT2_DBL",
                        "name": "Client negotiated LRA rate for Room Type 2 Double in Season 4 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 143,
                        "id": "NLRA_S4_RT2_SGL",
                        "name": "Client negotiated NLRA rate for Room Type 2 Single in Season 4 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 144,
                        "id": "NLRA_S4_RT2_DBL",
                        "name": "Client negotiated NLRA rate for Room Type 2 Double in Season 4 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 149,
                        "id": "GOVT_S4_RT2_SGL",
                        "name": "Government/Military rate for Room Type 2 in Season 4 per night Single occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 150,
                        "id": "GOVT_S4_RT2_DBL",
                        "name": "Government/Military rate for Room Type 2 in Season 4 per night Double occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 4,
                    "roomType": 3
                  },
                  "cells": [
                    {
                      "id": "RoomType",
                      "actions": ["RT"],
                      "name": 3,
                      "classes": "text-center strong"
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 139,
                        "id": "LRA_S4_RT3_SGL",
                        "name": "Client negotiated LRA rate for Room Type 3 Single in Season 4 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 140,
                        "id": "LRA_S4_RT3_DBL",
                        "name": "Client negotiated LRA rate for Room Type 3 Double in Season 4 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 145,
                        "id": "NLRA_S4_RT3_SGL",
                        "name": "Client negotiated NLRA rate for Room Type 3 Single in Season 4 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 146,
                        "id": "NLRA_S4_RT3_DBL",
                        "name": "Client negotiated NLRA rate for Room Type 3 Double in Season 4 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 151,
                        "id": "GOVT_S4_RT3_SGL",
                        "name": "Government/Military rate for Room Type 3 in Season 4 per night Single occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 152,
                        "id": "GOVT_S4_RT3_DBL",
                        "name": "Government/Military rate for Room Type 3 in Season 4 per night Double occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 5
                  },
                  "cells": [
                    {
                      "id": "Season",
                      "colspan": 2,
                      "name": "Season 5",
                      "classes": "strong"
                    },
                    {
                      "id": "RoomType",
                      "name": "RT",
                      "classes": "text-center"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "name": "Single"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "name": "Double"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "name": "Single"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "name": "Double"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "name": "Single"
                    },
                    {
                      "id": "OC",
                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "name": "Double"
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 5,
                    "roomType": 1
                  },
                  "cells": [
                    {
                      "rowspan": 3,
                      "rowspanId": "roomType",

                      "cell": {
                        "ord": 153,
                        "id": "SEASON5START",
                        "name": "Start",
                        "type": "DATE",
                        "placeholder": "Start Date",
                        "validations": {
                          "date_format": "yyyy-MM-dd",
                          "after": "$programStartDate",
                          "before": "$programEndDate",
                          "if": [
                            [ "#SEASON4END", [ "isBlank",false ], { "after": "#SEASON4END" } ],
                            [ "#SEASON5END", [ "isBlank",false ], { "before": ["#SEASON5END",true] } ]
                          ]
                        },
                        "description": "Season 5 start date. Use ISO 8601 format - Example: 2011-12-31",
                      }
                    },
                    {
                      "rowspan": 3,
                      "rowspanId": "roomType",

                      "cell": {
                        "ord": 154,
                        "id": "SEASON5END",
                        "name": "End",
                        "type": "DATE",
                        "placeholder": "End Date",
                        "validations": {
                          "date_format": "yyyy-MM-dd",
                          "after": ["$programStartDate", true],
                          "before": ["$programEndDate", true],
                          "if": [
                            [ "#SEASON5START", [ "isBlank",false ], { "after": ["#SEASON5START",true] } ]
                          ]
                        },
                        "description": "Season 5 end date. Use ISO 8601 format - Example: 2011-12-31",
                      }
                    },
                    {
                      "id": "RoomType",
                      "name": 1,
                      "classes": "text-center strong"
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 155,
                        "id": "LRA_S5_RT1_SGL",
                        "name": "Client negotiated LRA rate for Room Type 1 Single in Season 5 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 156,
                        "id": "LRA_S5_RT1_DBL",
                        "name": "Client negotiated LRA rate for Room Type 1 Double in Season 5 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 161,
                        "id": "NLRA_S5_RT1_SGL",
                        "name": "Client negotiated NLRA rate for Room Type 1 Single in Season 5 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 162,
                        "id": "NLRA_S5_RT1_DBL",
                        "name": "Client negotiated NLRA rate for Room Type 1 Double in Season 5 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 167,
                        "id": "GOVT_S5_RT1_SGL",
                        "name": "Government/Military rate for Room Type 1 in Season 5 per night Single occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 168,
                        "id": "GOVT_S5_RT1_DBL",
                        "name": "Government/Military rate for Room Type 1 in Season 5 per night Double occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 5,
                    "roomType": 2
                  },
                  "cells": [
                    {
                      "id": "RoomType",
                      "name": 2,
                      "classes": "text-center strong"
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 157,
                        "id": "LRA_S5_RT2_SGL",
                        "name": "Client negotiated LRA rate for Room Type 2 Single in Season 5 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 158,
                        "id": "LRA_S5_RT2_DBL",
                        "name": "Client negotiated LRA rate for Room Type 2 Double in Season 5 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 163,
                        "id": "NLRA_S5_RT2_SGL",
                        "name": "Client negotiated NLRA rate for Room Type 2 Single in Season 5 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 164,
                        "id": "NLRA_S5_RT2_DBL",
                        "name": "Client negotiated NLRA rate for Room Type 2 Double in Season 5 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 169,
                        "id": "GOVT_S5_RT2_SGL",
                        "name": "Government/Military rate for Room Type 2 in Season 5 per night Single occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 170,
                        "id": "GOVT_S5_RT2_DBL",
                        "name": "Government/Military rate for Room Type 2 in Season 5 per night Double occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    }
                  ]
                },
                {
                  "type": "ROW",
                  "for": {
                    "season": 5,
                    "roomType": 3
                  },
                  "cells": [
                    {
                      "id": "RoomType",
                      "name": 3,
                      "classes": "text-center strong"
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 159,
                        "id": "LRA_S5_RT3_SGL",
                        "name": "Client negotiated LRA rate for Room Type 3 Single in Season 5 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "LRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 160,
                        "id": "LRA_S5_RT3_DBL",
                        "name": "Client negotiated LRA rate for Room Type 3 Double in Season 5 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 165,
                        "id": "NLRA_S5_RT3_SGL",
                        "name": "Client negotiated NLRA rate for Room Type 3 Single in Season 5 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "NLRA",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 166,
                        "id": "NLRA_S5_RT3_DBL",
                        "name": "Client negotiated NLRA rate for Room Type 3 Double in Season 5 per night",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "SGL",
                      },
                      "cell": {
                        "ord": 171,
                        "id": "GOVT_S5_RT3_SGL",
                        "name": "Government/Military rate for Room Type 3 in Season 5 per night Single occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Single occupancy"
                      }
                    },
                    {

                      "for": {
                        "rate": "GOVT",
                        "occupancy": "DBL",
                      },
                      "cell": {
                        "ord": 172,
                        "id": "GOVT_S5_RT3_DBL",
                        "name": "Government/Military rate for Room Type 3 in Season 5 per night Double occupancy",
                        "type": "DECIMAL",
                        "validations": {
                          "decimal": 2,
                          "between": [0, 999999999999]
                        },
                        "description": "Rate you are offering to this client. Double occupancy"
                      }
                    }
                  ]
                }
              ]
            },
          ]
        },
        {
          "type": "GROUP",
          "id": "RT2",
          "ord": 5,
          "cells": [
            {
              "ord": 173,
              "id": "COMMISSIONABLE",
              "name": "Are negotiated rates listed above commissionable?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 174,
              "id": "COMM_PCT_CLIENT",
              "name": "If the client negotiated rate is commissionable, what is the percentage?",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 99],
                "if": [
                  [ "#COMMISSIONABLE", [ "eq","N" ], {"value": "0"} ]
                ]
              },
              "description": "If the rate is net, enter 0"
            },
            {
              "ord": 175,
              "id": "LOAD_GDS",
              "name": "If hotel rates are accepted, will hotel load negotiated rates in specified GDS?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "OP",
          "name": "Oversold Policy",
          "ord": 6,
          "cells": [
            {
              "ord": 176,
              "id": "WALK_HOTEL",
              "name": "Property will arrange accommodations at a comparable hotel",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "In the event the hotel is oversold, will the hotel arrange for accommodations at a comparable hotel?"
            },
            {
              "ord": 177,
              "id": "WALK_TAX",
              "name": "Property will pay for one night room and tax at a comparable hotel",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "In the event the hotel is oversold, will the hotel pay for one night room and tax at a comparable hotel?"
            },
            {
              "ord": 178,
              "id": "WALK_TRANS",
              "name": "Traveler will be provided with free transportation to new hotel",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "In the event the hotel is oversold, will the hotel provide and pay for transportation to comparable hotel?"
            },
            {
              "ord": 179,
              "id": "WALK_CALL",
              "name": "Traveler will be reimbursed for at least one phone call to home or office",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "In the event the hotel is oversold, will the hotel pay/reimburse guest for at least one phone call to guest's home/office?"
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "EC",
          "name": "Hotel Fees and Taxes - Early Checkout",
          "ord": 7,
          "cells": [
            {
              "ord": 180,
              "id": "CANC_POL",
              "name": "What is hotel's cancellation policy for reservation that has been guaranteed for late arrival?",
              "type": "TEXT",
              "validations": {
                "max": 10
              },
              "description": "Examples: 4:00 PM, 6:00 PM on day of arrival or 24 Hours, 48 Hours 72 Hours, 7 days. Note: 24 HRS / 48 HRS / 72 HRS / 7 Days, etc. is that period of time prior to check-in time on day of arrival."
            },
            {
              "ord": 181,
              "id": "EARLYCK_FEE",
              "name": "Early Checkout fee",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999],
                "if": [
                  [ "#EARLYCK_UOM", [ "eq","N" ], { "value": "0"} ],
                ],
              },
              "description": "(If not applicable, enter 0)"
            },
            {
              "ord": 182,
              "id": "EARLYCK_UOM",
              "name": "Early Check out fee: Percent or fixed amount?",
              "type": "LIST",
              "options": [{"value":"P", "label":"Percent"}, {"value":"F", "label": "Fixed"}, {"value":"N", "label": "Doesn't apply/exist"}],
              "validations": {
                "included": ["P", "F", "N"],
                "if": [
                  [ "#EARLYCK_FEE", [ "eq","0" ], { "value": "N"}, { "valueNot": "N"} ],
                  // [ "#EARLYCK_FEE", [ "gt","0" ], { "required": true, "included": ["P", "F"]} ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            },
            {
              "ord": 183,
              "id": "EARLYCK_INCLUDE",
              "name": "Will you include the early check out fee in negotiated rate?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label":"No/Doesn't apply" }],
              "validations": {
                "included": ["Y", "N"],
                "if": [
                  ["#EARLYCK_FEE", ["eq", "0"], { "value": "N" }],
                  // ["#EARLYCK_FEE", ["gt", "0"], { "required": true }],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            }
          ]},
        {
          "type": "GROUP",
          "id": "LOT",
          "name": "Hotel Fees and Taxes - Lodging Tax",
          "ord": 8,
          "cells": [
            {
              "ord": 184,
              "id": "LODGTX_FEE",
              "name": "Lodging tax",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999],
                "if": [
                  [ "#LODGTX_UOM", [ "eq","N" ], { "value": "0"} ],
                ],
              },
              "description": "Lodging tax: (If not applicable, enter 0)"
            },
            {
              "ord": 185,
              "id": "LODGTX_UOM",
              "name": "Lodging tax: Percent or fixed amount?",
              "type": "LIST",
              "options": [{"value":"P", "label":"Percent"}, {"value":"F", "label": "Fixed"}, {"value":"N", "label": "Doesn't apply/exist"}],
              "validations": {
                "included": ["P", "F", "N"],
                "if": [
                  [ "#LODGTX_FEE", [ "eq","0" ], { "value": "N"}, { "valueNot": "N"} ],
                  // [ "#LODGTX_FEE", [ "gt","0" ], { "included": ["P", "F"]} ],
                  // [ "#LODGTX_FEE", [ "gt","0" ], { "required": true, "included": ["P", "F"]} ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            },
            {
              "ord": 186,
              "id": "LODGTX_INCLUDE",
              "name": "Will you include the lodging tax in the negotiated rate?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label":"No/Doesn't apply" }],
              "validations": {
                "included": ["Y", "N"],
                "if": [
                  ["#LODGTX_FEE", ["eq", "0"], { "value": "N" }],
                  // ["#LODGTX_FEE", ["gt", "0"], { "required": true }],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            }
          ]},
        {
          "type": "GROUP",
          "id": "STT",
          "name": "Hotel Fees and Taxes - State Tax",
          "ord": 9,
          "cells": [
            {
              "ord": 187,
              "id": "STATETX_FEE",
              "name": "State tax",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999],
                "if": [
                  [ "#STATETX_UOM", [ "eq","N" ], { "value": "0"} ],
                ],

              },
              "description": "(If not applicable, enter 0)"
            },
            {
              "ord": 188,
              "id": "STATETX_UOM",
              "name": "State tax: Percent or fixed amount?",
              "type": "LIST",
              "options": [{"value":"P", "label":"Percent"}, {"value":"F", "label": "Fixed"}, {"value":"N", "label": "Doesn't apply/exist"}],
              "validations": {
                "included": ["P", "F", "N"],
                "if": [
                  [ "#STATETX_FEE", [ "eq","0" ], { "value": "N" }, { "valueNot": "N"} ],
                  // [ "#STATETX_FEE", [ "gt","0" ], { "included": ["P", "F"] } ],
                  // [ "#STATETX_FEE", [ "gt","0" ], { "required": true, "included": ["P", "F"] } ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            },
            {
              "ord": 189,
              "id": "STATETX_INCLUDE",
              "name": "Will you include this state tax in negotiated rate?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label":"No/Doesn't apply" }],
              "validations": {
                "included": ["Y", "N"],
                "if": [
                  [ "#STATETX_FEE", [ "eq","0" ], { "value": "N" } ],
                  // [ "#STATETX_FEE", [ "gt","0" ], { "required": true } ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            }
          ]},
        {
          "type": "GROUP",
          "id": "CIT",
          "name": "Hotel Fees and Taxes - City Tax",
          "ord": 10,
          "cells": [
            {
              "ord": 190,
              "id": "CITYTX_FEE",
              "name": "City Tax",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999],
                "if": [
                  [ "#CITYTX_UOM", [ "eq","N" ], { "value": "0"} ],
                ],
              },
              "description": "(If not applicable, enter 0)"
            },
            {
              "ord": 191,
              "id": "CITYTX_UOM",
              "name": "City tax: Percent or fixed amount?",
              "type": "LIST",
              "options": [{"value":"P", "label":"Percent"}, {"value":"F", "label": "Fixed"}, {"value":"N", "label": "Doesn't apply/exist"}],
              "validations": {
                "included": ["P", "F", "N"],
                "if": [
                  [ "#CITYTX_FEE", [ "eq","0" ], { "value": "N" }, { "valueNot": "N"} ],
                  // [ "#CITYTX_FEE", [ "gt","0" ], { "required": true, "included": ["P", "F"]} ],
                  // [ "#CITYTX_FEE", [ "gt","0" ], { "included": ["P", "F"]} ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            },
            {
              "ord": 192,
              "id": "CITYTX_INCLUDE",
              "name": "Will you include city tax in the negotiated rate?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label":"No/Doesn't apply" }],
              "validations": {
                "included": ["Y", "N"],
                "if": [
                  [ "#CITYTX_FEE", [ "eq","0" ], { "value": "N" } ],
                  // [ "#CITYTX_FEE", [ "gt","0" ], { "required": true } ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            }
          ]},
        {
          "type": "GROUP",
          "id": "VAT",
          "name": "Hotel Fees and Taxes - VAT/GST",
          "ord": 11,
          "cells": [
            {
              "ord": 193,
              "id": "VATGSTRM_FEE",
              "name": "VAT/GST: What is the charge?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999],
                "if": [
                  [ "#VATGSTRM_UOM", [ "eq","N" ], { "value": "0"} ],
                ],
              },
              "description": "(If not applicable, enter 0)"
            },
            {
              "ord": 194,
              "id": "VATGSTRM_UOM",
              "name": "VAT/GST: Percent or fixed amount?",
              "type": "LIST",
              "options": [{"value":"P", "label":"Percent"}, {"value":"F", "label": "Fixed"}, {"value":"N", "label": "Doesn't apply/exist"}],
              "validations": {
                "included": ["P", "F", "N"],
                "if": [
                  [ "#VATGSTRM_FEE", [ "eq","0" ], { "value": "N"}, { "valueNot": "N"} ],
                  // [ "#VATGSTRM_FEE", [ "gt","0" ], { "required": true, "included": ["P", "F"]} ],
                  // [ "#VATGSTRM_FEE", [ "gt","0" ], { "included": ["P", "F"]} ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            },
            {
              "ord": 195,
              "id": "VATGSTRM_INCLUDE",
              "name": "Is VAT/GST included in the negotiated rate?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label":"No/Doesn't apply" }],
              "validations": {
                "included": ["Y", "N"],
                "if": [
                  [ "#VATGSTRM_FEE", [ "eq","0" ], { "value": "N"} ],
                  // [ "#VATGSTRM_FEE", [ "gt","0" ], { "required": true } ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            }]},
        {
          "type": "GROUP",
          "id": "VATFB",
          "name": "Hotel Fees and Taxes - VAT/GST on Food and Beverage",
          "ord": 12,
          "cells": [
            {
              "ord": 196,
              "id": "VATGSTFB_FEE",
              "name": "VAT/GST on the food and beverage: What is the charge?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999],
                "if": [
                  [ "#VATGSTFB_UOM", [ "eq","N" ], { "value": "0"} ],
                ],
              },
              "description": "(If not applicable, enter 0)"
            },
            {
              "ord": 197,
              "id": "VATGSTFB_UOM",
              "name": "Is VAT/GST on food and beverage: Percent or fixed amount?",
              "type": "LIST",
              "options": [{"value":"P", "label":"Percent"}, {"value":"F", "label": "Fixed"}, {"value":"N", "label": "Doesn't apply/exist"}],
              "validations": {
                "included": ["P", "F", "N"],
                "if": [
                  [ "#VATGSTFB_FEE", [ "eq","0" ], { "value": "N"}, { "valueNot": "N"} ],
                  // [ "#VATGSTFB_FEE", [ "gt","0" ], { "required": true, "included": ["P", "F"]} ],
                  // [ "#VATGSTFB_FEE", [ "gt","0" ], { "included": ["P", "F"]} ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            },
            {
              "ord": 198,
              "id": "VATGSTFB_INCLUDE",
              "name": "Is VAT/GST on food and beverage included in the negotiated rate?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label":"No/Doesn't apply" }],
              "validations": {
                "included": ["Y", "N"],
                "if": [
                  [ "#VATGSTFB_FEE", [ "eq","0" ], { "value": "N"} ],
                  // [ "#VATGSTFB_FEE", [ "gt","0" ], { "required": true } ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            }
          ]},
        {
          "type": "GROUP",
          "id": "SET",
          "name": "Hotel Fees and Taxes - Service Tax",
          "ord": 13,
          "cells": [
            {
              "ord": 199,
              "id": "SERVICE_FEE",
              "name": "Service/Resort Fees",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999],
                "if": [
                  [ "#SERVICE_UOM", [ "eq","N" ], { "value": "0"} ],
                ],
              },
              "description": "(If not applicable, enter 0)"
            },
            {
              "ord": 200,
              "id": "SERVICE_UOM",
              "name": "Service/Resort Fees: Percent or fixed amount?",
              "type": "LIST",
              "options": [{"value":"P", "label":"Percent"}, {"value":"F", "label": "Fixed"}, {"value":"N", "label": "Doesn't apply/exist"}],
              "validations": {
                "included": ["P", "F", "N"],
                "if": [
                  [ "#SERVICE_FEE", [ "eq","0" ], { "value": "N"}, { "valueNot": "N"} ],
                  // [ "#SERVICE_FEE", [ "gt","0" ], { "required": true, "included": ["P", "F"]} ],
                  // [ "#SERVICE_FEE", [ "gt","0" ], { "included": ["P", "F"]} ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            },
            {
              "ord": 201,
              "id": "SERVICE_INCLUDE",
              "name": "Will you include service fee/resort fee in the negotiated rate?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label":"No/Doesn't apply" }],
              "validations": {
                "included": ["Y", "N"],
                "if": [
                  [ "#SERVICE_FEE", [ "eq","0" ], { "value": "N"} ],
                  // [ "#SERVICE_FEE", [ "gt","0" ], { "required": true } ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            }
          ]},
        {
          "type": "GROUP",
          "id": "OCT",
          "name": "Hotel Fees and Taxes - Occupancy Tax",
          "ord": 14,
          "cells": [
            {
              "ord": 202,
              "id": "OCC_FEE",
              "name": "Occupancy Tax",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999],
                "if": [
                  [ "#OCC_UOM", [ "eq","N" ], { "value": "0"} ],
                ],
              },
              "description": "If not applicable, enter 0"
            },
            {
              "ord": 203,
              "id": "OCC_UOM",
              "name": "Occupancy Tax: Percent or fixed amount?",
              "type": "LIST",
              "options": [{"value":"P", "label":"Percent"}, {"value":"F", "label": "Fixed"}, {"value":"N", "label": "Doesn't apply/exist"}],
              "validations": {
                "included": ["P", "F", "N"],
                "if": [
                  [ "#OCC_FEE", [ "eq","0" ], { "value": "N"}, { "valueNot": "N"} ],
                  // [ "#OCC_FEE", [ "gt","0" ], { "required": true, "included": ["P", "F"]} ],
                  // [ "#OCC_FEE", [ "gt","0" ], { "included": ["P", "F"]} ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            },
            {
              "ord": 204,
              "id": "OCC_INCLUDE",
              "name": "Will you include occupancy tax in the negotiated rate?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label":"No/Doesn't apply" }],
              "validations": {
                "included": ["Y", "N"],
                "if": [
                  [ "#OCC_FEE", [ "eq","0" ], { "value": "N"} ],
                  // [ "#OCC_FEE", [ "gt","0" ], { "required": true} ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            }
          ]},
        {
          "type": "GROUP",
          "id": "OTT",
          "name": "Hotel Fees and Taxes - Other Tax/Fee",
          "ord": 15,
          "cells": [
            {
              "ord": 205,
              "id": "OTHERTX_FEE",
              "name": "Other Tax/Fee",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999],
                "if": [
                  [ "#OTHERTX_FEE_UOM", [ "eq","N" ], { "value": "0"} ],
                ],
              },
              "description": "(if not applicable, enter 0)"
            },
            {
              "ord": 206,
              "id": "OTHERTX_FEE_UOM",
              "name": "Other Tax/Fee: Percent or fixed amount?",
              "type": "LIST",
              "options": [{"value":"P", "label":"Percent"}, {"value":"F", "label": "Fixed"}, {"value":"N", "label": "Doesn't apply/exist"}],
              "validations": {
                "included": ["P", "F", "N"],
                "if": [
                  [ "#OTHERTX_FEE", [ "eq","0" ], { "value": "N"}, { "valueNot": "N"} ],
                  // [ "#OTHERTX_FEE", [ "gt","0" ], { "included": ["P", "F"]} ],
                  // [ "#OTHERTX_FEE", [ "gt","0" ], { "required": true, "included": ["P", "F"]} ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            },
            {
              "ord": 207,
              "id": "OTHERTX_FEE_DESC",
              "name": "Other Tax/Fee Description",
              "type": "TEXT",
              "validations": {
                "max": 50,
                "if": [
                  [ "#OTHERTX_FEE", [ "eq","0" ], { "value": "NA"} ]
                ]
              },
              "classes": "question-size-3",
              "description": "If this tax doesn't apply/exist, the answer should be \"NA\"."
            },
            {
              "ord": 208,
              "id": "OTHERTX_FEE_INCL",
              "name": "Will you include Other Tax/Fee in the negotiated rate?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label":"No/Doesn't apply" }],
              "validations": {
                "included": ["Y", "N"],
                "if": [
                  [ "#OTHERTX_FEE", [ "eq","0" ], { "value": "N"} ],
                  // [ "#OTHERTX_FEE", [ "gt","0" ], { "required": true } ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\"."
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "HACP",
          "name": "Hotel Amenities for Client - Parking",
          "ord": 16,
          "cells": [
            {
              "ord": 209,
              "id": "PARKATTEND",
              "name": "Which of the following best describes parking at your hotel?",
              "type": "TEXT",
              "validations": {
                "max": "100"
              },
              "description": "Options: Parking Lot, Parking Garage, Valet, Street Side, Public, or None Available"
            },
            {
              "ord": 210,
              "id": "PARK_FEE",
              "name": "What is the average cost for parking per car per day?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999],
                // "if": [
                //   [ "#PARKATTEND", [ "eq","None Available" ], { "value":"0" } ]
                // ]
              },
              "description": "If not applicable, enter 0"
            },
            {
              "ord": 211,
              "id": "PARK_INCLUDE",
              "name": "Will you include the daily cost to park one car in negotiated rate?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"],
                // "if": [ "#PARKATTEND", [ "eq","None Available" ], { "required":true } ]
              }
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "HACB",
          "name": "Hotel Amenities for Client - Breakfast",
          "ord": 17,
          "cells": [
            {
              "ord": 212,
              "id": "BREAK_INCLUDE",
              "name": "Will you include breakfast in the negotiate rate?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label":"No/Not applicable" }],
              "validations": {
                "included": ["Y", "N"]
              },
            },
            {
              "ord": 213,
              "id": "BREAK_FEE",
              "name": "What is the average value of the included breakfast per person?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999],
                "if": [
                  // [ "#BREAK_INCLUDE", [ "eq","Y" ], { "required":true, "between": [0, 9999999999] } ],
                  [ "#BREAK_INCLUDE", [ "eq","N" ], { "value":"0" } ],
                ]
              },
              "description": "If not included enter 0"
            },
            {
              "ord": 214,
              "id": "BREAK_TYPE",
              "name": "What type of breakfast is included in the negotiated rate?",
              "type": "LIST",
              "options": [{ "value":"C", "label":"Continental"}, {"value":"B", "label":"Buffet"}, {"value":"F", "label":"Full American"}, {"value":"N", "label":"Not Included" }],
              "validations": {
                "included": ["C", "B", "F", "N"],
                "if": [
                  // [ "#BREAK_INCLUDE", [ "eq","Y" ], { "included": ["C", "B", "F"] } ],
                  [ "#BREAK_INCLUDE", [ "eq","N" ], { "value": "N" }, { "valueNot": "N" } ]
                ]
              }
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "HACFC",
          "name": "Hotel Amenities for Client - Fitness center",
          "ord": 18,
          "cells": [
            {
              "ord": 215,
              "id": "FITON_CENT",
              "name": "Fitness center available on-site?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
            },
            {
              "ord": 216,
              "id": "FITNESS_FEE_ON",
              "name": "What is the average cost for the on-site fitness center per person?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999],
                "if": [
                  // [ "#FITON_CENT", [ "eq","Y" ], { "required":true, "between": [0, 9999999999], } ],
                  [ "#FITON_CENT", [ "eq","N" ], { "value":"0" } ],
                ]
              },
              "description": "If not applicable, enter 0"
            },
            {
              "ord": 217,
              "id": "FITNESS_INCLUDE_ON",
              "name": "Will you include use of the fitness center in the negotiated rate?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label":"No/Not applicable" }],
              "validations": {
                "included": ["Y", "N"],
                "if": [
                  // [ "#FITON_CENT", [ "eq","Y" ], { "required":true, "included": ["Y", "N"] } ],
                  [ "#FITON_CENT", [ "eq","N" ], { "value": "N" } ],
                ]
              },
              "description": "Y or N (If not applicable, enter N)"
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "HACLPC",
          "name": "Hotel Amenities for Client - Local Phone Calls",
          "ord": 19,
          "cells": [
            {
              "ord": 218,
              "id": "LOCAL_PHONE_INCLUDE",
              "name": "Are local phone calls included in the negotiated rate?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "HACTFCCC",
          "name": "Hotel Amenities for Client - Toll free / calling card calls",
          "ord": 20,
          "cells": [
            {
              "ord": 219,
              "id": "TOLL_CARD_CALL_INCLUDE",
              "name": "Are toll free/calling card calls included in the negotiated rate?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "HACWHSI",
          "name": "Hotel Amenities for Client - Wired high speed internet",
          "ord": 21,
          "cells": [
            {
              "ord": 220,
              "id": "HIGHSPEED_INROOM",
              "name": "Do you have a wired high speed internet connection in guest rooms?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
            },
            {
              "ord": 221,
              "id": "HIGHSPEED_INROOM_NUM",
              "name": "How many guest rooms have wired high speed internet connections?",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 9999],
                "if": [
                  // [ "#HIGHSPEED_INROOM", [ "eq","Y" ], { "required":true, "between": [0, 9999] } ],
                  [ "#HIGHSPEED_INROOM", [ "eq","N" ], { "value":"0" } ],
                ]
              },
              "description": "if not applicable, enter 0"
            },
            {
              "ord": 222,
              "id": "HSIA_FEE",
              "name": "What is the average fee per day for wired high speed internet access in the guest room?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999],
                "if": [
                  // [ "#HIGHSPEED_INROOM", [ "eq","Y" ], { "required":true, "between": [0, 9999999999] } ],
                  [ "#HIGHSPEED_INROOM", [ "eq","N" ], { "value":"0" } ],
                ]
              },
              "description": "If not applicable, enter 0"
            },
            {
              "ord": 223,
              "id": "HSIA_INCLUDE",
              "name": "Is wired guest room high speed internet access included in negotiated room rate?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"],
                "if": [
                  // [ "#HIGHSPEED_INROOM", [ "eq","Y" ], { "required":true, "included": ["Y", "N"] } ],
                  [ "#HIGHSPEED_INROOM", [ "eq","N" ], { "value": "N" } ],
                ]
              }
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "HACWSHSI",
          "name": "Hotel Amenities for Client - Wireless high speed internet",
          "ord": 22,
          "cells": [
            {
              "ord": 224,
              "id": "WIRELESS",
              "name": "Do you have a wireless high speed internet connection in guest rooms?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
            },
            {
              "ord": 225,
              "id": "WIRELESS_NUM",
              "name": "How many guest rooms have wireless high speed internet connections?",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 9999],
                "if": [
                  // [ "#WIRELESS", [ "eq","Y" ], { "required":true, "between": [0, 9999] } ],
                  [ "#WIRELESS", [ "eq","N" ], { "value":"0" } ],
                ]
              },
              "description": "if not applicable, enter 0"
            },
            {
              "ord": 226,
              "id": "WIRELESS_FEE",
              "name": "What is the average fee per day for wireless high speed internet access in the guest room?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999],
                "if": [
                  // [ "#WIRELESS", [ "eq","Y" ], { "required":true, "between": [0, 9999999999] } ],
                  [ "#WIRELESS", [ "eq","N" ], { "value":"0" } ],
                ]
              },
              "description": "If not applicable, enter 0"
            },
            {
              "ord": 227,
              "id": "WIRELESS_INCLUDE",
              "name": "Is wired guest room high speed internet access included in negotiated room rate?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"],
                "if": [
                  // [ "#WIRELESS", [ "eq","Y" ], { "required":true, "included": ["Y", "N"] } ],
                  [ "#WIRELESS", [ "eq","N" ], { "value": "N" } ],
                ]
              }
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "HACTTFA",
          "name": "Hotel Amenities for Client - Transportation to/from airport",
          "ord": 23,
          "cells": [
            {
              "ord": 228,
              "id": "AIRTRANS_FEE",
              "name": "What is the average cost for transportation to and from airport?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999]
              }
            },
            {
              "ord": 229,
              "id": "AIRTRANS_INCLUDE",
              "name": "Is transportation to/from airport included in negotiated rates?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 230,
              "id": "AIRTRANS_DESCRIBE",
              "name": "Describe transportation to/from airport included in negotiated rates.",
              "type": "TEXT",
              "validations": {
                "max": 50,
                "if": [
                  // [ "#AIRTRANS_INCLUDE", [ "eq","Y" ], { "required":true } ],
                  [ "#AIRTRANS_INCLUDE", [ "eq","N" ], { "value": "NA" } ],
                ]
              },
              "classes": "question-size-3",
              "description": "Examples include: Shuttle Van, Ride share service (bus or van), Private car, Taxi, or Train. (If not applicable, enter NA)"
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "HACTTFLO",
          "name": "Hotel Amenities for Client - Transportation to/from local offices",
          "ord": 24,
          "cells": [
            {
              "ord": 231,
              "id": "OFFTRANS_INCLUDE",
              "name": "Is transportation to/from local client office included in negotiated rates?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "CNRAT",
          "name": "Client negotiated rates are available to:",
          "ord": 25,
          "cells": [
            {
              "ord": 232,
              "id": "RATE_SUBS",
              "name": "Subsidiary employees with proper identification",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "Are client negotiated rates available to subsidiary employees with proper identification?"
            },
            {
              "ord": 233,
              "id": "RATE_PERSO",
              "name": "Client employee personal use with proper identification",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "Are client negotiated rates available for client employee's with proper identification for personal use?"
            },
            {
              "ord": 234,
              "id": "RATE_CONSU",
              "name": "Client employed contractors and/or consultants with proper identification",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "Are client negotiated rates available to client employed contractors/consultants with proper identification?"
            },
            {
              "ord": 235,
              "id": "RATE_RETIR",
              "name": "Client retirees with proper identification",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "Are client negotiated rates available to retirees with proper identification?"
            },
            {
              "ord": 236,
              "id": "RFP_CONTRACT",
              "name": "By checking yes to this box, the property/chain representative submits that the information here is correct and binding for the length of the agreement.",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "locked": 1,
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "CIRRRFP",
          "name": "Contact Information of the representative responsible for the RFP data",
          "ord": 26,
          "cells": [
            {
              "ord": 237,
              "id": "RFP_NAME",
              "name": "Sales Representative Name",
              "type": "TEXT",
              "validations": {
                "max": 50
              },
              "classes": "question-size-3",
              "description": "Property/Chain/Management Representative Name Responsible for the RFP data",
            },
            {
              "ord": 238,
              "id": "RFP_TITLE",
              "name": "Title of Property/Chain/Management Representative Name Responsible for the RFP data",
              "type": "TEXT",
              "validations": {
                "max": 50
              },
              "classes": "question-size-3",
            },
            {
              "ord": 239,
              "id": "RFP_PHONECOUNTRYCODE",
              "name": "Phone Country code (240) City/Area code (241) Phone Number",
              "type": "TEXT",
              "validations": {
                "max": 6
              },
              "description": "Country Code of Property/Chain/Management Representative Name Responsible for the RFP data",
            },
            {
              "ord": 240,
              "id": "RFP_PHONECITYCODE",
              "name": "City Code of Property/Chain/Management Representative Name Responsible for the RFP data",
              "type": "TEXT",
              "validations": {
                "max": 6
              },
            },
            {
              "ord": 241,
              "id": "RFP_PHONE",
              "name": "Phone Number of Property/Chain/Management Representative Name Responsible for the RFP data",
              "type": "TEXT",
              "validations": {
                "max": 10
              },
            },
            {
              "ord": 242,
              "id": "RFP_EMAIL",
              "name": "Email address of Property/Chain/Management Representative Name Responsible for the RFP data",
              "type": "TEXT",
              "validations": {
                "max": 150,
              },
              "classes": "question-size-4",
            },
            {
              "ord": 243,
              "id": "RFP_DATESUBMIT",
              "name": "Date RFP was submitted.",
              "type": "DATE",
              "validations": {
                "date_format": "yyyy-MM-dd"
              },
              "classes": "question-size-2",
              "description": "Use ISO 8601 format - Example: 2011-12-31",
              "locked": 1,
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "CUD",
          "name": "Client User Defined Questions",
          "ord": 27,
          "locked": 1,
          "cells": [
            {
              "ord": 244,
              "id": "USERDEFINED1",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 245,
              "id": "USERDEFINED2",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 246,
              "id": "USERDEFINED3",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 247,
              "id": "USERDEFINED4",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 248,
              "id": "USERDEFINED5",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 249,
              "id": "USERDEFINED6",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 250,
              "id": "USERDEFINED7",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 251,
              "id": "USERDEFINED8",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 252,
              "id": "USERDEFINED9",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 253,
              "id": "USERDEFINED10",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 254,
              "id": "USERDEFINED11",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 255,
              "id": "USERDEFINED12",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 256,
              "id": "USERDEFINED13",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 257,
              "id": "USERDEFINED14",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 258,
              "id": "USERDEFINED15",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 259,
              "id": "USERDEFINED16",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 260,
              "id": "USERDEFINED17",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 261,
              "id": "USERDEFINED18",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 262,
              "id": "USERDEFINED19",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 263,
              "id": "USERDEFINED20",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 264,
              "id": "USERDEFINED21",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 265,
              "id": "USERDEFINED22",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 266,
              "id": "USERDEFINED23",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 267,
              "id": "USERDEFINED24",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 268,
              "id": "USERDEFINED25",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 269,
              "id": "USERDEFINED26",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 270,
              "id": "USERDEFINED27",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 271,
              "id": "USERDEFINED28",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 272,
              "id": "USERDEFINED29",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 273,
              "id": "USERDEFINED30",
              "name": "User Defined",
              "type": "USER_DEFINED"
            }
          ]
        }
      ]
    },
    {
      "type": "MODULE",
      "id": "SS",
      "name": "Safety & Security",
      "ord": 3,
      "cells": [
        {
          "type": "GROUP",
          "id": "IRS",
          "name": "In-Room Safe",
          "ord": 1,
          "cells": [
            {
              "ord": 274,
              "id": "IN_SAFE",
              "name": "In-room safe?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 275,
              "id": "LAPTOP_SAFE",
              "name": "Can in room safe hold a 17 inch or 43.2 cm laptop computer?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label":"No/Not applicable" }],
              "validations": {
                "included": ["Y", "N"],
                "if": [
                  // [ "#IN_SAFE", [ "eq","Y" ], { "required":true, "included": ["Y", "N"] } ],
                  [ "#IN_SAFE", [ "eq","N" ], { "value": "N" } ],
                ]
              }
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "FS",
          "name": "Fire Safety",
          "ord": 2,
          "cells": [
            {
              "ord": 276,
              "id": "ROOMSMKDET",
              "name": "Do all guest rooms have smoke detectors?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 277,
              "id": "HALLSMKDET",
              "name": "Are all hallways equipped with smoke detectors?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 278,
              "id": "PUBSMKDET",
              "name": "Are all public areas equipped with smoke detector?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 279,
              "id": "HRDWIREDET",
              "name": "Are all smoke detectors hard-wired?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label":"No/Not applicable" }],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 280,
              "id": "FIRE_EXTIN",
              "name": "Are all public areas equipped with fire extinguishers?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 281,
              "id": "EMRGCYLITE",
              "name": "Does the hotel have emergency lighting in all public areas?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 282,
              "id": "VENT_STAIR",
              "name": "Are all hotel stairways ventilated?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 283,
              "id": "EVACPLAN",
              "name": "Does hotel have an emergency evacuation plan posted in public areas?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 284,
              "id": "FIRELAWS",
              "name": "Does the hotel comply with all country/state/local fire laws?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 285,
              "id": "VIS_ALARM",
              "name": "Does the hotel have visual alarms for the Deaf/Hard of Hearing in all guest rooms or does the hotel have designated Deaf/Hard of Hearing guest rooms with visual alarms?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 286,
              "id": "HALLVIS_ALARM",
              "name": "Does the hotel have visual alarms for the Deaf/Hard of Hearing in all public hallways?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 287,
              "id": "PUBVIS_ALARM",
              "name": "Does the hotel have visual alarms for the Deaf/Hard of Hearing in all public areas?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 288,
              "id": "AUD_ALARM",
              "name": "Does the hotel have audible smoke alarms in all guest rooms?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 289,
              "id": "HALLAUD_ALARM",
              "name": "Does hotel have audible smoke alarm in all hallways?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 290,
              "id": "PUBAUD_ALARM",
              "name": "Does the hotel have audible smoke alarms in all public areas?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 291,
              "id": "ROOMSPRINK",
              "name": "Are all guest rooms equipped with sprinklers?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 292,
              "id": "HALLSPRINK",
              "name": "Are all hallways equipped with sprinklers?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 293,
              "id": "PUBSPRINK",
              "name": "Are all public areas equipped with sprinklers?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 294,
              "id": "EMRGCYINFO",
              "name": "Do all guest rooms have emergency information posted/available?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "(Y or N)?"
            },
            {
              "ord": 295,
              "id": "FIRE_LINK",
              "name": "Is hotel auto linked to fire station?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "(Alarm automatically notifies fire station)"
            },
            {
              "ord": 296,
              "id": "MULT_EXITS",
              "name": "Are there emergency exits for each floor?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 297,
              "id": "AUTO_RECAL",
              "name": "Does the hotel have auto recall elevators?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label": "No"}, {"value": "NA", "label":"Not available" }],
              "validations": {
                "included": ["Y", "N", "NA"]
              },
              "description": "Example: elevators that automatically return to the ground floor when an emergency alarm is triggered."
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "PS",
          "name": "Property Safety",
          "ord": 3,
          "cells": [
            {
              "ord": 298,
              "id": "SECURITY",
              "name": "Does the hotel have physical security on-site?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 299,
              "id": "SECUR_HRS",
              "name": "If yes, what are the hours of operation?",
              "type": "TEXT",
              "validations": {
                "max": 25,
                "regex": /^(\d{2}):(\d{2}) - (\d{2}):(\d{2})$/,
                "if": [
                  // [ "#SECURITY", [ "eq","Y" ], { "required": true, "regex": /^(\d{2}):(\d{2}) - (\d{2}):(\d{2})$/} ],
                  [ "#SECURITY", [ "eq","N" ], { "value": "00:00 - 00:00"} ],
                ],
              },
              "classes": "question-size-2",
              "description": "(Please use 24h clock ex: 00:00 - 23:59) (If No, enter hours 00:00 - 00:00)"
            },
            {
              "ord": 300,
              "id": "DEADBOLTS",
              "name": "Do all guest room doors have deadbolts and/or secondary locks?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 301,
              "id": "INT_ACCESS",
              "name": "Are all guest rooms accessed only by interior entrances?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 302,
              "id": "DOORS_CLOSE",
              "name": "Are all entry doors to the guest rooms self-closing?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 303,
              "id": "OPEN_WINDOWS",
              "name": "Do all windows that can be opened have a locking device?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 304,
              "id": "PUBADDRESS",
              "name": "Does the hotel have a public address system?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 305,
              "id": "SAFE_CODES",
              "name": "Does the building meet all current local, state and country building codes?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 306,
              "id": "PARKGATES",
              "name": "Are there controlled access gates to enter all parking areas?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label": "No"}, {"value": "NA", "label":"Not available" }],
              "validations": {
                "included": ["Y", "N", "NA"],
                "if": [
                  [ "#PARKATTEND", [ "eq","None Available" ], { "value":"NA" }, { "valueNot": "NA" } ]
                  // [ "#PARKATTEND", [ "ne","None Available" ], { "included": ["Y", "N"] } ]
                ]
              }
            },
            {
              "ord": 307,
              "id": "PARKPATROL",
              "name": "Are all parking areas patrolled?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label": "No"}, {"value": "NA", "label":"Not available" }],
              "validations": {
                "included": ["Y", "N", "NA"],
                "if": [
                  [ "#PARKATTEND", [ "eq","None Available" ], { "value":"NA" }, { "valueNot": "NA" } ]
                  // [ "#PARKATTEND", [ "ne","None Available" ], { "included": ["Y", "N"] } ]
                ]
              }
            },
            {
              "ord": 308,
              "id": "PARKLIGHTS",
              "name": "Are all the parking areas equipped with lights?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label": "No"}, {"value": "NA", "label":"Not available" }],
              "validations": {
                "included": ["Y", "N", "NA"],
                "if": [
                  [ "#PARKATTEND", [ "eq","None Available" ], { "value":"NA" }, { "valueNot": "NA" } ]
                  // [ "#PARKATTEND", [ "ne","None Available" ], { "included": ["Y", "N"] } ]
                ]
              }
            },
            {
              "ord": 309,
              "id": "PUBCAMERA",
              "name": "Is there video surveillance in all public areas?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 310,
              "id": "PARK_FR_ENT",
              "name": "Is there video surveillance of the exterior front entrance?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 311,
              "id": "PARK_CAMERA",
              "name": "Is there video surveillance of all parking areas?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label": "No"}, {"value": "NA", "label":"Not available" }],
              "validations": {
                "included": ["Y", "N", "NA"]
              }
            },
            {
              "ord": 312,
              "id": "CAMERA_REC_24HR",
              "name": "Is video surveillance recorded 24 hrs a day?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label": "No"}, {"value": "NA", "label":"Not available" }],
              "validations": {
                "included": ["Y", "N", "NA"]
              }
            },
            {
              "ord": 313,
              "id": "CAMERA_MONT_24HR",
              "name": "Is video surveillance monitored 24 hrs a day?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label": "No"}, {"value": "NA", "label":"Not available" }],
              "validations": {
                "included": ["Y", "N", "NA"]
              }
            },
            {
              "ord": 314,
              "id": "VIEWPORTS",
              "name": "Do all the guest room entry doors have viewports?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 315,
              "id": "BACK_UP",
              "name": "Are there emergency back-up generators?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 316,
              "id": "EXT_DOOR_KEY_ACCESS",
              "name": "Do all of the exterior doors (except lobby entrance) of the facility require key access at night or automatically lock?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 317,
              "id": "STAFF_AID",
              "name": "Is hotel staff trained in first aid?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 318,
              "id": "STAFF_CPR_TRAIN",
              "name": "Is hotel staff trained in cardio pulmonary resuscitation (CPR)?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 319,
              "id": "STAFF_CPR_CERT",
              "name": "Is hotel staff Red Cross Certified in CPR?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 320,
              "id": "AED_ONSITE",
              "name": "Is there an automated external defibrillator on site (AED)?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 321,
              "id": "STAFF_AED",
              "name": "Is hotel staff trained in automated external defibrillator usage (AED)?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"],
                "if": [
                  // [ "#AED_ONSITE", [ "eq","Y" ], { "required": true } ],
                  [ "#AED_ONSITE", [ "eq","N" ], { "value":"N" } ]
                ]
              }
            },
            {
              "ord": 322,
              "id": "KEY_ID",
              "name": "Is Identification or validation required for replacing a guest room key?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 323,
              "id": "KEY_ELEVATOR",
              "name": "Does your hotel have a secure floor that is only accessible via a guest room key?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 324,
              "id": "KEY_PUBLIC",
              "name": "Do the health club facilities (pool/gym) require key access for entrance?",
              "type": "LIST",
              "options": [{"value": "Y", "label": "Yes"},{"value":"N", "label": "No"}, {"value": "NA", "label":"Not available" }],
              "validations": {
                "included": ["Y", "N", "NA"]
              }
            }
          ]
        }
      ]
    },
    {
      "type": "MODULE",
      "id": "BD",
      "name": "Blackout / Fair Dates",
      "ord": 4,
      "cells": [
        {
          "type": "GROUP",
          "id": "BFD",
          "ord": 1,
          "cells": [
            {
              "ord": 325,
              "id": "LASTROOMAVAIL_BD",
              "name": "Are the Blackout/Fair Date rates offered based on (LRA) last room availability?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            }
          ]
        },
        {
          "id": "BFDT",
          "type": "TABLE",
          "name": "Blackout/Fair Date Rates",
          "ord": 2,
          "classes": "center-80",
          "filters": {
            "blackoutDate": [ 1,2,3,4,5,6,7,8,9,10 ],
            "roomType": [ 1,2,3 ],
            "occupancy": [ 'SGL', 'DBL' ],
          },
          "defaultFilters": {
            "blackoutDate": [ 1,2,3,4,5,6,7,8,9,10 ],
            "roomType": [ 1 ],
            "occupancy": [ 'SGL' ],
          },
          "manageRows": [
            {
              "id": "blackoutDate",
              "label": "Blackout Date"
            },{
              "id": "roomType",
              "label": "Room Type"
            }
          ],
          "validations": [],
          "actions": {
            "blackoutDate": {
              "id": "blackoutDate",
              "label": "How many Blackout Date Periods will you allow?",
              "type": "filter",
              "options": [
                { "label": "One Period", "filter": [ 1 ], "default": 1 },
                { "label": "Two Periods", "filter": [ 1,2 ] },
                { "label": "Three Periods", "filter": [ 1,2,3 ] },
                { "label": "Four Periods", "filter": [ 1,2,3,4 ] },
                { "label": "Five Periods", "filter": [ 1,2,3,4,5 ] },
                { "label": "Six Periods", "filter": [ 1,2,3,4,5,6 ] },
                { "label": "Seven Periods", "filter": [ 1,2,3,4,5,6,7 ] },
                { "label": "Eight Periods", "filter": [ 1,2,3,4,5,6,7,8 ] },
                { "label": "Nine Periods", "filter": [ 1,2,3,4,5,6,7,8,9 ] },
                { "label": "Ten Periods", "filter": [ 1,2,3,4,5,6,7,8,9,10 ] }
              ]
            },
            "roomType": {
              "id": "roomType",
              "label": "How many Room Types will you allow?",
              "type": "filter",
              "options": [
                { "label": "One Room Type", "filter": [ 1 ], "default": 1},
                { "label": "Two Room Types", "filter": [ 1,2 ]},
                { "label": "Three Room Types", "filter": [ 1,2,3 ]}
              ]
            },
            "occupancy": {
              "id": "occupancy",
              "label": "Occupancy",
              "type": "filter",
              "options": [
                { "label": "Single Only", "default": 1, "filter": [ "SGL" ], },
                { "label": "Double Only", "filter": [ "DBL" ], },
                { "label": "Both", "filter": [ "SGL","DBL" ] },
              ]
            }
          },
          "cells": [
            {
              "type": "ROW",
              "classes": "row-header",
              "cells": [
                {
                  "name": "Blackout/Fair Date Period",
                  "colspan": 2
                },
                {
                  "name": "Name of Blackout/Fair Date Period"
                },
                {
                  "name": ""
                },
                {
                  "name": "Blackout/Fair Date Rate",
                  "colspan": 2,
                  "colspanId": "occupancy",
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 1
              },
              "cells":   [
                {
                  "colspan": 2,
                  "name": "1st Period"
                },
                {
                  "name": ""
                },
                {
                  "name": "RT",
                  "classes": "text-center"
                },
                {
                  "name": "Single",
                  "for": {
                    "occupancy": 'SGL'
                  },
                },
                {
                  "name": "Double",
                  "for": {
                    "occupancy": 'DBL'
                  },
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 1,
                "roomType": 1,
              },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 326,
                    "id": "BD1_START",
                    "name": "Start",
                    "type": "DATE",
                    "placeholder": "Start Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],

                    },
                    "description": "Start date of 1st Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 327,
                    "id": "BD1_END",
                    "name": "End",
                    "type": "DATE",
                    "placeholder": "End Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD1_START", [ "isBlank",false ], { "after": ["#BD1_START", true] } ],
                        [ "#BD2_START", [ "isBlank",false ], { "before": "#BD2_START" } ]
                      ]
                    },
                    "description": "End date of 1st Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 328,
                    "id": "BD1_NAME",
                    "name": "Name",
                    "type": "TEXT",
                    "placeholder": "Period Name",
                    "validations": {
                      "max": 50
                    },
                    "classes": "question-size-3",
                    "description": "Name of 1st Blackout/Fair Date Period"
                  }
                },
                {
                  "name": 1,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 329,
                    "id": "BD1_RT1_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 1st Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 330,
                    "id": "BD1_RT1_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 1st Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 1,
                "roomType": 2
              },
              "cells": [
                {
                  "name": 2,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 331,
                    "id": "BD1_RT2_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 1st Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 332,
                    "id": "BD1_RT2_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 1st Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 1,
                "roomType": 3
              },
              "cells": [
                {
                  "name": 3,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 333,
                    "id": "BD1_RT3_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 1st Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 334,
                    "id": "BD1_RT3_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 1st Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 2
              },
              "cells": [
                {
                  "colspan": 2,
                  "name": "2nd Period"
                },
                {
                  "name": ""
                },
                {
                  "name": "RT",
                  "classes": "text-center"
                },
                {
                  "name": "Single",
                  "for": {
                    "occupancy": 'SGL'
                  }
                },
                {
                  "name": "Double",
                  "for": {
                    "occupancy": 'DBL'
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 2,
                "roomType": 1
              },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 335,
                    "id": "BD2_START",
                    "name": "Start",
                    "type": "DATE",
                    "placeholder": "Start Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD1_END", [ "isBlank",false ], { "after": ["#BD1_END"] } ],
                        [ "#BD2_END", [ "isBlank",false ], { "before": ["#BD2_END",true]} ]
                      ]
                    },
                    "description": "Start date of 2nd Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 336,
                    "id": "BD2_END",
                    "name": "End",
                    "type": "DATE",
                    "placeholder": "End Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD2_START", [ "isBlank",false ], { "after": ["#BD2_START", true] } ],
                        [ "#BD3_START", [ "isBlank",false ], { "before": "#BD3_START" } ]
                      ]
                    },
                    "classes": "question-size-2",
                    "description": "End date of 2nd Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 337,
                    "id": "BD2_NAME",
                    "name": "Name",
                    "type": "TEXT",
                    "placeholder": "Period Name",
                    "validations": {
                      "max": 50
                    },
                    "classes": "question-size-3",
                    "description": "Name of 2nd Blackout/Fair Date Period"
                  }
                },
                {
                  "name": 1,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 338,
                    "id": "BD2_RT1_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 2nd Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 339,
                    "id": "BD2_RT1_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 2nd Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 2,
                "roomType": 2
              },
              "cells": [
                {
                  "name": 2,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 340,
                    "id": "BD2_RT2_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 2nd Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 341,
                    "id": "BD2_RT2_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 2nd Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 2,
                "roomType": 3
              },
              "cells": [
                {
                  "name": 3,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 342,
                    "id": "BD2_RT3_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 2nd Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 343,
                    "id": "BD2_RT3_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 2nd Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 3
              },
              "cells":  [
                {
                  "colspan": 2,
                  "name": "3rd Period"
                },
                {
                  "name": ""
                },
                {
                  "name": "RT",
                  "classes": "text-center"
                },
                {
                  "name": "Single",
                  "for": {
                    "occupancy": 'SGL'
                  }
                },
                {
                  "name": "Double",
                  "for": {
                    "occupancy": 'DBL'
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 3,
                "roomType": 1
              },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 344,
                    "id": "BD3_START",
                    "name": "Start Date",
                    "type": "DATE",
                    "placeholder": "Start Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD2_END", [ "isBlank",false ], { "after": "#BD2_END" } ],
                        [ "#BD3_END", [ "isBlank",false ], { "before": ["#BD3_END",true] } ]
                      ]
                    },
                    "description": "Start date of 3rd Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 345,
                    "id": "BD3_END",
                    "name": "End Date",
                    "type": "DATE",
                    "placeholder": "End Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD3_START", [ "isBlank",false ], { "after": ["#BD3_START",true] } ],
                        [ "#BD4_START", [ "isBlank",false ], { "before": "#BD4_START" } ]
                      ]
                    },
                    "description": "End date of 3rd Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 346,
                    "id": "BD3_NAME",
                    "name": "Name",
                    "type": "TEXT",
                    "placeholder": "Period Name",
                    "validations": {
                      "max": 50
                    },
                    "classes": "question-size-3",
                    "description": "Name of 3rd Blackout/Fair Date Period"
                  }
                },
                {
                  "name": 1,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 347,
                    "id": "BD3_RT1_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 3rd Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 348,
                    "id": "BD3_RT1_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 3rd Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 3,
                "roomType": 2
              },
              "cells":  [
                {
                  "name": 2,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 349,
                    "id": "BD3_RT2_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 3rd Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 350,
                    "id": "BD3_RT2_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 3rd Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 3,
                "roomType": 3
              },
              "cells": [
                {
                  "name": 3,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 351,
                    "id": "BD3_RT3_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 3rd Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 352,
                    "id": "BD3_RT3_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 3rd Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 4
              },
              "cells": [
                {
                  "colspan": 2,
                  "name": "4th Period"
                },
                {
                  "name": ""
                },
                {
                  "name": "RT",
                  "classes": "text-center"
                },
                {
                  "name": "Single",
                  "for": {
                    "occupancy": 'SGL'
                  }
                },
                {
                  "name": "Double",
                  "for": {
                    "occupancy": 'DBL'
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 4,
                "roomType": 1
              },
              "cells":  [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 353,
                    "id": "BD4_START",
                    "name": "Start Date",
                    "type": "DATE",
                    "placeholder": "Start Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD3_END", [ "isBlank",false ], { "after": "#BD3_END" } ],
                        [ "#BD4_END", [ "isBlank",false ], { "before": ["#BD4_END",true] } ]
                      ]
                    },
                    "description": "Start date of 4th Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 354,
                    "id": "BD4_END",
                    "name": "End Date",
                    "type": "DATE",
                    "placeholder": "End Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD4_START", [ "isBlank",false ], { "after": ["#BD4_START",true] } ],
                        [ "#BD5_START", [ "isBlank",false ], { "before": "#BD5_START" } ]
                      ]
                    },
                    "description": "End date of 4th Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 355,
                    "id": "BD4_NAME",
                    "name": "Name",
                    "type": "TEXT",
                    "placeholder": "Period Name",
                    "validations": {
                      "max": 50
                    },
                    "classes": "question-size-3",
                    "description": "Name of 4th Blackout/Fair Date Period"
                  }
                },
                {
                  "name": 1,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 356,
                    "id": "BD4_RT1_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 4th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 357,
                    "id": "BD4_RT1_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 4th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 4,
                "roomType": 2
              },
              "cells": [
                {
                  "name": 2,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 358,
                    "id": "BD4_RT2_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 4th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 359,
                    "id": "BD4_RT2_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 4th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 4,
                "roomType": 3
              },
              "cells": [
                {
                  "name": 3,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 360,
                    "id": "BD4_RT3_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 4th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 361,
                    "id": "BD4_RT3_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 4th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 5
              },
              "cells": [
                {
                  "colspan": 2,
                  "name": "5th Period"
                },
                {
                  "name": ""
                },
                {
                  "name": "RT",
                  "classes": "text-center"
                },
                {
                  "name": "Single",
                  "for": {
                    "occupancy": 'SGL'
                  }
                },
                {
                  "name": "Double",
                  "for": {
                    "occupancy": 'DBL'
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 5,
                "roomType": 1
              },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 362,
                    "id": "BD5_START",
                    "name": "Start Date",
                    "type": "DATE",
                    "placeholder": "Start Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD4_END", [ "isBlank",false ], { "after": "#BD4_END" } ],
                        [ "#BD5_END", [ "isBlank",false ], { "before":["#BD5_END",true] } ]
                      ]
                    },
                    "description": "Start date of 5th Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 363,
                    "id": "BD5_END",
                    "name": "End Date",
                    "placeholder": "End Date",
                    "type": "DATE",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD5_START", [ "isBlank",false ], { "after": ["#BD5_START",true] } ],
                        [ "#BD6_START", [ "isBlank",false ], { "before":"#BD6_START" } ]
                      ]
                    },
                    "description": "End date of 5th Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 364,
                    "id": "BD5_NAME",
                    "name": "Name",
                    "type": "TEXT",
                    "placeholder": "Period Name",
                    "validations": {
                      "max": 50
                    },
                    "classes": "question-size-3",
                    "description": "Name of 5th Blackout/Fair Date Period"
                  }
                },
                {
                  "name": 1,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 365,
                    "id": "BD5_RT1_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 5th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 366,
                    "id": "BD5_RT1_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 5th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 5,
                "roomType": 2
              },
              "cells": [
                {
                  "name": 2,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 367,
                    "id": "BD5_RT2_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 5th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 368,
                    "id": "BD5_RT2_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 5th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 5,
                "roomType": 3
              },
              "cells": [
                {
                  "name": 3,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 369,
                    "id": "BD5_RT3_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 5th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 370,
                    "id": "BD5_RT3_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 5th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 6,
                "roomType": 1
              },
              "cells": [
                {
                  "colspan": 2,
                  "name": "6th Period"
                },
                {
                  "name": ""
                },
                {
                  "name": "RT",
                  "classes": "text-center"
                },
                {
                  "name": "Single",
                  "for": {
                    "occupancy": 'SGL'
                  },
                },
                {
                  "name": "Double",
                  "for": {
                    "occupancy": 'DBL'
                  },
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 6,
                "roomType": 1
              },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 371,
                    "id": "BD6_START",
                    "name": "Start Date",
                    "type": "DATE",
                    "placeholder": "Start Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD5_END", [ "isBlank",false ], { "after": "#BD5_END" } ],
                        [ "#BD6_END", [ "isBlank",false ], { "before": ["#BD6_END",true] } ]
                      ]
                    },
                    "description": "Start date of 6th Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 372,
                    "id": "BD6_END",
                    "name": "End",
                    "type": "DATE",
                    "placeholder": "End Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD6_START", [ "isBlank",false ], { "after": ["#BD6_START",true] } ],
                        [ "#BD7_START", [ "isBlank",false ], { "before": "#BD7_START" } ]
                      ]
                    },
                    "description": "End date of 6th Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 373,
                    "id": "BD6_NAME",
                    "name": "Name",
                    "type": "TEXT",
                    "placeholder": "Period Name",
                    "validations": {
                      "max": 50
                    },
                    "classes": "question-size-3",
                    "description": "Name of 6th Blackout/Fair Date Period"
                  }
                },
                {
                  "name": 1,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 374,
                    "id": "BD6_RT1_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 6th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 375,
                    "id": "BD6_RT1_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 6th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 6,
                "roomType": 2
              },
              "cells": [
                {
                  "name": 2,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 376,
                    "id": "BD6_RT2_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 6th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 377,
                    "id": "BD6_RT2_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 6th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 6,
                "roomType": 3,
              },
              "cells": [
                {
                  "name": 3,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 378,
                    "id": "BD6_RT3_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 6th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 379,
                    "id": "BD6_RT3_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 6th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 7,
                "roomType": 1
              },
              "cells": [
                {
                  "colspan": 2,
                  "name": "7th Period"
                },
                {
                  "name": ""
                },
                {
                  "name": "RT",
                  "classes": "text-center"
                },
                {
                  "name": "Single",
                  "for": {
                    "occupancy": 'SGL'
                  }
                },
                {
                  "name": "Double",
                  "for": {
                    "occupancy": 'DBL'
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 7,
                "roomType": 1,
              },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 380,
                    "id": "BD7_START",
                    "name": "Start Date",
                    "type": "DATE",
                    "placeholder": "Start Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD6_END", [ "isBlank",false ], { "after": "#BD6_END" } ],
                        [ "#BD7_END", [ "isBlank",false ], { "before":["#BD7_END",true] } ]
                      ]
                    },
                    "description": "Start date of 7th Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 381,
                    "id": "BD7_END",
                    "name": "End Date",
                    "type": "DATE",
                    "placeholder": "End Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD7_START", [ "isBlank",false ], { "after":["#BD7_START",true] } ],
                        [ "#BD8_START", [ "isBlank",false ], { "before":"#BD8_START" } ]
                      ]
                    },
                    "description": "End"
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 382,
                    "id": "BD7_NAME",
                    "name": "Name",
                    "type": "TEXT",
                    "placeholder": "Period Name",
                    "validations": {
                      "max": 50
                    },
                    "classes": "question-size-3",
                    "description": "Name of 7th Blackout/Fair Date Period"
                  }
                },
                {
                  "name": 1,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 383,
                    "id": "BD7_RT1_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 7th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 384,
                    "id": "BD7_RT1_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 7th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 7,
                "roomType": 2
              },
              "cells": [
                {
                  "name": 2,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 385,
                    "id": "BD7_RT2_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 7th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 386,
                    "id": "BD7_RT2_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 7th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 7,
                "roomType": 3,
              },
              "cells": [
                {
                  "name": 3,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 387,
                    "id": "BD7_RT3_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 7th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 388,
                    "id": "BD7_RT3_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 7th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 8,
                "roomType": 1
              },
              "cells": [
                {
                  "colspan": 2,
                  "name": "8th Period"
                },
                {
                  "name": ""
                },
                {
                  "name": "RT",
                  "classes": "text-center"
                },
                {
                  "name": "Single",
                  "for": {
                    "occupancy": 'SGL'
                  },
                },
                {
                  "name": "Double",
                  "for": {
                    "occupancy": 'DBL'
                  },
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 8,
                "roomType": 1
              },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 389,
                    "id": "BD8_START",
                    "name": "Start Date",
                    "type": "DATE",
                    "placeholder": "Start Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD7_END", [ "isBlank",false ], { "after": "#BD7_END" } ],
                        [ "#BD8_END", [ "isBlank",false ], { "before":["#BD8_END",true] } ]
                      ]
                    },
                    "description": "Start date of 8th Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 390,
                    "id": "BD8_END",
                    "name": "End Date",
                    "type": "DATE",
                    "placeholder": "End Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD8_START", [ "isBlank",false ], { "after":["#BD8_START",true] } ],
                        [ "#BD9_START", [ "isBlank",false ], { "before":"#BD9_START" } ]
                      ]
                    },
                    "description": "End date of 8th Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 391,
                    "id": "BD8_NAME",
                    "name": "Name",
                    "type": "TEXT",
                    "placeholder": "Period Name",
                    "validations": {
                      "max": 50
                    },
                    "classes": "question-size-3",
                    "description": "Name of 8th Blackout/Fair Date Period"
                  }
                },
                {
                  "name": 1,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 392,
                    "id": "BD8_RT1_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 8th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 393,
                    "id": "BD8_RT1_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 8th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 8,
                "roomType": 2
              },
              "cells": [
                {
                  "name": 2,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 394,
                    "id": "BD8_RT2_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 8th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 395,
                    "id": "BD8_RT2_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 8th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 8,
                "roomType": 3
              },
              "cells": [
                {
                  "name": 3,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 396,
                    "id": "BD8_RT3_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 8th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 397,
                    "id": "BD8_RT3_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 8th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 9
              },
              "cells": [
                {
                  "colspan": 2,
                  "name": "9th Period"
                },
                {
                  "name": ""
                },
                {
                  "name": "RT",
                  "classes": "text-center"
                },
                {
                  "name": "Single",
                  "for": {
                    "occupancy": 'SGL'
                  },
                },
                {
                  "name": "Double",
                  "for": {
                    "occupancy": 'DBL'
                  },
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 9,
                "roomType": 1
              },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 398,
                    "id": "BD9_START",
                    "name": "Start Date",
                    "type": "DATE",
                    "placeholder": "Start Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD8_END", [ "isBlank",false ], { "after": "#BD8_END" } ],
                        [ "#BD9_END", [ "isBlank",false ], { "before":["#BD9_END",true] } ]
                      ]
                    },
                    "description": "Start date of 9th Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 399,
                    "id": "BD9_END",
                    "name": "End Date",
                    "type": "DATE",
                    "placeholder": "End Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD9_START", [ "isBlank",false ], { "after": ["#BD9_START",true] } ],
                        [ "#BD10_START", [ "isBlank",false ], { "before": "#BD10_START" } ]
                      ]
                    },
                    "description": "End date of 9th Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 400,
                    "id": "BD9_NAME",
                    "name": "Name",
                    "type": "TEXT",
                    "placeholder": "Period Name",
                    "validations": {
                      "max": 50
                    },
                    "classes": "question-size-3",
                    "description": "Name of 9th Blackout/Fair Date Period"
                  }
                },
                {
                  "name": 1,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 401,
                    "id": "BD9_RT1_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 9th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 402,
                    "id": "BD9_RT1_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 9th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 9,
                "roomType": 2
              },
              "cells": [
                {
                  "name": 2,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 403,
                    "id": "BD9_RT2_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 9th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 404,
                    "id": "BD9_RT2_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 9th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 9,
                "roomType": 3
              },
              "cells": [
                {
                  "name": 3,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 405,
                    "id": "BD9_RT3_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 9th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 406,
                    "id": "BD9_RT3_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 9th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 10,
              },
              "cells": [
                {
                  "colspan": 2,
                  "name": "10th Period"
                },
                {
                  "name": ""
                },
                {
                  "name": "RT",
                  "classes": "text-center"
                },
                {
                  "name": "Single",
                  "for": {
                    "occupancy": 'SGL'
                  },
                },
                {
                  "name": "Double",
                  "for": {
                    "occupancy": 'DBL'
                  },
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 10,
                "roomType": 1
              },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 407,
                    "id": "BD10_START",
                    "name": "Start Date",
                    "type": "DATE",
                    "placeholder": "Start Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD9_END", [ "isBlank",false ], { "after": "#BD9_END" } ],
                        [ "#BD10_END", [ "isBlank",false ], { "before": ["#BD10_END",true] } ]
                      ]
                    },
                    "description": "Start date of 10th Blackout/Fair Date Period. Use ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 408,
                    "id": "BD10_END",
                    "name": "End Date",
                    "type": "DATE",
                    "placeholder": "End Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#BD10_START", [ "isBlank",false ], { "after": ["#BD10_START",true] } ]
                      ]
                    },
                    "description": "End date of 10th Blackout/Fair Date Period. Used ISO 8601 format - Example: 2011-12-31. If the Blackout/Fair Date period is one day, Start Date and End Date are the same."
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",

                  "cell": {
                    "ord": 409,
                    "id": "BD10_NAME",
                    "name": "Name",
                    "type": "TEXT",
                    "placeholder": "Period Name",
                    "validations": {
                      "max": 50
                    },
                    "classes": "question-size-3",
                    "description": "Name of 10th Blackout/Fair Date Period"
                  }
                },
                {
                  "name": 1,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 410,
                    "id": "BD10_RT1_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 10th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 411,
                    "id": "BD10_RT1_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 1 in 10th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 10,
                "roomType": 2
              },
              "cells": [
                {
                  "name": 2,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 412,
                    "id": "BD10_RT2_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 10th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 413,
                    "id": "BD10_RT2_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 2 in 10th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": {
                "blackoutDate": 10,
                "roomType": 3
              },
              "cells": [
                {
                  "name": 3,
                  "classes": "text-center"
                },
                {

                  "for": {
                    "occupancy": 'SGL'
                  },
                  "cell": {
                    "ord": 414,
                    "id": "BD10_RT3_SGL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 10th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Single occupancy"
                  }
                },
                {

                  "for": {
                    "occupancy": 'DBL'
                  },
                  "cell": {
                    "ord": 415,
                    "id": "BD10_RT3_DBL",
                    "name": "Blackout/Fair Date rate for Room Type 3 in 10th Blackout/Fair Date Period per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client. Double occupancy"
                  }
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "type": "MODULE",
      "id": "ES",
      "name": "Extended Stay",
      "ord": 5,
      "cells": [
        {
          "type": "GROUP",
          "id": "ESSA",
          "name": "Extended Stay Services & Amenities",
          "ord": 1,
          "cells": [
            {
              "ord": 416,
              "id": "GROCERY_SVC",
              "name": "Is there a grocery shopping service available?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 417,
              "id": "STORAGE",
              "name": "Is there storage space?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 418,
              "id": "STORAGE_FEE",
              "name": "If so, what is the fee?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999],
                "if": [ "#STORAGE", [ "eq","N" ], { "value":"0" } ]
              }
            },
            {
              "ord": 419,
              "id": "LAUN_DRY",
              "name": "Is there a Laundry/Dry cleaning service available?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 420,
              "id": "LAUNDRY_SITE",
              "name": "Is there self-service laundry on-site?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 421,
              "id": "MINI_FRIG",
              "name": "Is there a mini refrigerator in room?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 422,
              "id": "MICRO",
              "name": "Is there a microwave oven in room?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 423,
              "id": "COFF_TEA",
              "name": "Coffee-Tea maker available in room?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 424,
              "id": "STOVE",
              "name": "Is there a Stove in room?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 425,
              "id": "OVEN",
              "name": "Conventional oven in room?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 426,
              "id": "TOASTER",
              "name": "Toaster in room?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 427,
              "id": "DINING_WARE",
              "name": "Plates, glassware and silverware in room?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 428,
              "id": "DISHES",
              "name": "Pots, pans, and serving dishes in room?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 429,
              "id": "HOUSEKEEPING",
              "name": "Is housekeeping service provided Daily or Weekly?",
              "type": "LIST",
              "options": [{"value":"D", "label":"Daily"}, {"value":"W", "label":"Weekly" }],
              "validations": {
                "included": ["D", "W"]
              }
            },
            {
              "ord": 430,
              "id": "HOUSE_CHARGE",
              "name": "What is the daily housekeeping fee?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999]
              }
            }
          ]
        },
        {
          "type": "TABLE",
          "id": "ALOSR",
          "name": "Additional Length of Stay Ranges",
          "classes": "center-80",
          "ord": 2,
          "cells": [
            {
              "type": "ROW",
              "classes": "row-header",
              "cells": [
                {
                  "colspan": 3,
                  "name": "Define Your Additional Length of stay Ranges Below"
                }
              ]
            },
            {
              "type": "ROW",
              "cells": [
                {
                  "name": ""
                },
                {
                  "name": "From"
                },
                {
                  "name": "To"
                }
              ]
            },
            {
              "type": "ROW",
              "cells": [
                {
                  "name": "Second (2) Length of Stay range:"
                },
                {
                  "cell": {
                    "ord": 431,
                    "id": "LOS2MIN_ES",
                    "name": "Length Of Stay 2 Minimum Nights",
                    "type": "NUMBER",
                    "validations": {
                      "numeric": true,
                      "between": [0, 999]
                    },
                    "description": "Example: 7 (LOS2 minimum must be greater than LOS1 maximum.) Note: Core module defines LOS1."
                  }
                },
                {
                  "cell": {
                    "ord": 432,
                    "id": "LOS2MAX_ES",
                    "name": "Length of Stay 2 Maximum Nights",
                    "type": "NUMBER",
                    "validations": {
                      "numeric": true,
                      "between": [0, 999]
                    },
                    "description": "Example: 13 (LOS2 maximum must be greater than LOS2 minimum.)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "cells": [
                {
                  "name": "Third (3) Length of Stay range:"
                },
                {
                  "cell": {
                    "ord": 433,
                    "id": "LOS3MIN_ES",
                    "name": "Length of Stay 3 Minimum Nights",
                    "type": "NUMBER",
                    "validations": {
                      "numeric": true,
                      "between": [0, 999]
                    },
                    "description": "Length of Stay 3 Minimum Nights. Example: 14 (LOS3 minimum must be greater than LOS2 maximum.)"
                  }
                },
                {
                  "cell": {
                    "ord": 434,
                    "id": "LOS3MAX_ES",
                    "name": "Length of Stay 3 Maximum Nights",
                    "type": "NUMBER",
                    "validations": {
                      "numeric": true,
                      "between": [0, 999]
                    },
                    "description": "Example: 20 (LOS3 maximum must be greater than LOS3 minimum.)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "cells": [
                {
                  "name": "Fourth (4) Length of Stay range:"
                },
                {

                  "cell": {
                    "ord": 435,
                    "id": "LOS4MIN_ES",
                    "name": "Length of Stay 4 Minimum Nights",
                    "type": "NUMBER",
                    "validations": {
                      "numeric": true,
                      "between": [0, 999]
                    },
                    "description": "Example: 21 (LOS4 minimum must be greater than LOS3 maximum.)"
                  }
                },
                {

                  "cell": {
                    "ord": 436,
                    "id": "LOS4MAX_ES",
                    "name": "Length of Stay 4 Maximum Nights",
                    "type": "NUMBER",
                    "validations": {
                      "numeric": true,
                      "between": [0, 999]
                    },
                    "description": "Example: 30 (LOS4 maximum must be greater than LOS4 minimum.)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "cells": [
                {
                  "colspan": 3,
                  "name": "Second Length of Stay minimum must be greater than 1. The First Length of Stay range is defined in the Client Specific Module."
                }
              ]
            }
          ]
        },
        {
          "type": "TABLE",
          "id": "ESRT",
          "ord": 3,
          "name": "Extended Stay Rates",
          "classes": "center-80",
          "filters": {
            "season": [ 1,2,3,4,5 ],
            "roomType": [ 1,2,3 ],
            "lengthOfStay": [ 2,3,4 ],
            "rate": [ "LRA", "NLRA" ],
            "occupancy": [ "SGL", "DBL" ]
          },
          "defaultFilters": {
            "season": [ 1,2,3,4,5 ],
            "roomType": [ 1 ],
            "lengthOfStay": [ 2 ],
            "rate": [ "LRA", "NLRA" ],
            "occupancy": [ "SGL" ]
          },
          "manageRows": [
            {
              "id": "season",
              "label": "Season"
            },{
              "id": "lengthOfStay",
              "label": "Length of Stay"
            },{
              "id": "roomType",
              "label": "Room Type"
            }
          ],
          "validations": [
            { "id": "RATE", "type": "maxLength", "value": 1, "message": "At least one Rate is required!"}
          ],
          "actions": {
            // "season": {
            //   "id": "season",
            //   "label": "How many Seasons will you allow?",
            //   "type": "filter",
            //   "options": [
            //     { "label": "One Season", "filter": [ 1 ] },
            //     { "label": "Two Seasons", "filter": [ 1,2 ] },
            //     { "label": "Three Seasons", "filter": [ 1,2,3 ] },
            //     { "label": "Four Seasons", "filter": [ 1,2,3,4 ] },
            //     { "label": "Five Seasons", "filter": [ 1,2,3,4,5 ], "default": 1 }
            //   ]
            // },
            "lengthOfStay": {
              "id": "lengthOfStay",
              "label": "How many \"Lenght of Stay\" will you allow?",
              "type": "filter",
              "options": [
                { "label": "One \"Length Of Stay\"", "filter": [ 2 ], "default": 1 },
                { "label": "Two \"Length Of Stays\"", "filter": [ 2,3 ] },
                { "label": "Three \"Length Of Stays\"", "filter": [ 2,3,4 ] },
              ]
            },
            "roomType": {
              "id": "roomType",
              "label": "How many Room Types will you allow?",
              "type": "filter",
              "options": [
                { "label": "One Room Type", "filter": [ 1 ], "default": 1},
                { "label": "Two Room Types", "filter": [ 1,2 ]},
                { "label": "Three Room Types", "filter": [ 1,2,3 ]}
              ]
            },
            "rate": {
              "id": "rate",
              "label": "Rates",
              "type": "toggle",
              "options": [
                { "id": "LRA", "filter": [ "LRA" ], "label": "LRA Rate"},
                { "id": "NLRA", "filter": [ "NLRA" ], "label": "NLRA Rate"}
              ]
            },
            "occupancy": {
              "id": "occupancy",
              "label": "Occupancy",
              "type": "filter",
              "options": [
                { "label": "Single Only", "default": 1, "filter": [ "SGL" ], },
                { "label": "Double Only", "filter": [ "DBL" ], },
                { "label": "Both", "filter": [ "SGL","DBL" ] },
              ]
            }
          },
          "cells": [
            {
              "type": "ROW",
              "classes": "row-header",
              "cells": [
                {
                  "colspan": 2,
                  "name": ""
                },
                {
                  "name": "",
                  "colspan": 2
                },
                {
                  "name": "LRA",
                  "for": { "rate": 'LRA' },
                  "colspan": 2,
                  "colspanId": "occupancy",
                },
                {
                  "name": "NLRA",
                  "for": { "rate": 'NLRA' },
                  "colspan": 2,
                  "colspanId": "occupancy",
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 1 },
              "cells": [
                {
                  "colspan": 2,
                  "name": "Season 1"
                },
                {
                  "name": "LOS",
                  "classes": "text-center"
                },
                {
                  "name": "RT",
                  "classes": "text-center"
                },
                {
                  "name": "Single",
                  "for": { "rate": "LRA", "occupancy": "SGL" }
                },
                {
                  "name": "Double",
                  "for": { "rate": "LRA", "occupancy": "DBL" }
                },
                {
                  "name": "Single",
                  "for": { "rate": "NLRA", "occupancy": "SGL" }
                },
                {
                  "name": "Double",
                  "for": { "rate": "NLRA", "occupancy": "DBL" }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 1, "lengthOfStay": 2, "roomType": 1 },
              "cells": [
                {

                  "rowspan": 9,
                  "rowspanIds": ["roomType", "lengthOfStay"],
                  "cell": {
                    "ord": 437,
                    "id": "SEASON1START_ES",
                    "name": "Start",
                    "type": "DATE",
                    "placeholder": "Start Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                    },
                    "description": "Season 1 start date. Use ISO 8601 format - Example: 2011-12-31"
                  }
                },
                {

                  "rowspan": 9,
                  "rowspanIds": ["roomType", "lengthOfStay"],
                  "cell": {
                    "ord": 438,
                    "id": "SEASON1END_ES",
                    "name": "End",
                    "placeholder": "End Date",
                    "type": "DATE",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#SEASON1START_ES", [ "isBlank",false ], { "after": ["#SEASON1START_ES", true] } ],
                        [ "#SEASON2START_ES", [ "isBlank",false ], { "before": "#SEASON2START_ES" } ]
                      ]
                    },
                    "description": "Season 1 end date. Use ISO 8601 format - Example: 2011-12-31"
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",
                  "name": "2",
                  "classes": "text-center"
                },
                {
                  "name": "1",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 439,
                    "id": "LRA_S1_RT1_L2_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 1, Room type 1, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 440,
                    "id": "LRA_S1_RT1_L2_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 1, Room type 1, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 445,
                    "id": "NLRA_S1_RT1_L2_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room type 1, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 446,
                    "id": "NLRA_S1_RT1_L2_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room type 1, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 1, "lengthOfStay": 2, "roomType": 2 },
              "cells": [
                {
                  "name": "2",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 441,
                    "id": "LRA_S1_RT2_L2_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 1, Room type 2, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 442,
                    "id": "LRA_S1_RT2_L2_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 1, Room type 2, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 447,
                    "id": "NLRA_S1_RT2_L2_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room type 2, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 448,
                    "id": "NLRA_S1_RT2_L2_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room type 2, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 1, "lengthOfStay": 2, "roomType": 3 },
              "cells": [
                {
                  "name": "3",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 443,
                    "id": "LRA_S1_RT3_L2_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 1, Room Type 3, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 444,
                    "id": "LRA_S1_RT3_L2_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 1, Room Type 3, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 449,
                    "id": "NLRA_S1_RT3_L2_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room Type 3, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 450,
                    "id": "NLRA_S1_RT3_L2_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room Type 3, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 1, "lengthOfStay": 3, "roomType": 1 },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",
                  "name": "3",
                  "classes": "text-center"
                },
                {
                  "name": "1",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 451,
                    "id": "LRA_S1_RT1_L3_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 1, Room type 1, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 452,
                    "id": "LRA_S1_RT1_L3_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 1, Room type 1, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 457,
                    "id": "NLRA_S1_RT1_L3_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room type 1, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 458,
                    "id": "NLRA_S1_RT1_L3_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room type 1, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 1, "lengthOfStay": 3, "roomType": 2 },
              "cells": [
                {
                  "name": "2",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 453,
                    "id": "LRA_S1_RT2_L3_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 1, Room type 2, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 454,
                    "id": "LRA_S1_RT2_L3_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 1, Room type 2, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 459,
                    "id": "NLRA_S1_RT2_L3_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room type 2, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 460,
                    "id": "NLRA_S1_RT2_L3_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room type 2, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 1, "lengthOfStay": 3, "roomType": 3 },
              "cells": [
                {
                  "name": "3",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 455,
                    "id": "LRA_S1_RT3_L3_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 1, Room Type 3, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 456,
                    "id": "LRA_S1_RT3_L3_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 1, Room Type 3, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 461,
                    "id": "NLRA_S1_RT3_L3_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room Type 3, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 462,
                    "id": "NLRA_S1_RT3_L3_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room Type 3, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 1, "lengthOfStay": 4, "roomType": 1 },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",
                  "name": "4",
                  "classes": "text-center"
                },
                {
                  "name": "1",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 463,
                    "id": "LRA_S1_RT1_L4_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 1, Room type 1, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 464,
                    "id": "LRA_S1_RT1_L4_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 1, Room type 1, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 469,
                    "id": "NLRA_S1_RT1_L4_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room type 1, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 470,
                    "id": "NLRA_S1_RT1_L4_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room type 1, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 1, "lengthOfStay": 4, "roomType": 2 },
              "cells": [
                {
                  "name": "2",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 465,
                    "id": "LRA_S1_RT2_L4_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 1, Room type 2, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 466,
                    "id": "LRA_S1_RT2_L4_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 1, Room type 2, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 471,
                    "id": "NLRA_S1_RT2_L4_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room type 2, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 472,
                    "id": "NLRA_S1_RT2_L4_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room type 2, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 1, "lengthOfStay": 4, "roomType": 3 },
              "cells": [
                {
                  "name": "3",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 467,
                    "id": "LRA_S1_RT3_L4_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 1, Room Type 3, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 468,
                    "id": "LRA_S1_RT3_L4_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 1, Room Type 3, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 473,
                    "id": "NLRA_S1_RT3_L4_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room Type 3, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 474,
                    "id": "NLRA_S1_RT3_L4_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 1, Room Type 3, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 2 },
              "cells": [
                {
                  "colspan": 2,
                  "name": "Season 2"
                },
                {
                  "name": "LOS",
                  "classes": "text-center"
                },
                {
                  "name": "RT",
                  "classes": "text-center"
                },
                {
                  "name": "Single",
                  "for": { "rate": "LRA", "occupancy": "SGL" }
                },
                {
                  "name": "Double",
                  "for": { "rate": "LRA", "occupancy": "DBL" }
                },
                {
                  "name": "Single",
                  "for": { "rate": "NLRA", "occupancy": "SGL" }
                },
                {
                  "name": "Double",
                  "for": { "rate": "NLRA", "occupancy": "DBL" }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 2, "lengthOfStay": 2, "roomType": 1 },
              "cells": [
                {

                  "rowspan": 9,
                  "rowspanIds": ["roomType", "lengthOfStay"],

                  "cell": {
                    "ord": 475,
                    "id": "SEASON2START_ES",
                    "name": "Start",
                    "type": "DATE",
                    "placeholder": "Start Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#SEASON1END_ES", [ "isBlank",false ], { "after": "#SEASON1END_ES" } ],
                        [ "#SEASON2END_ES", [ "isBlank",false ], { "before":["#SEASON2END_ES",true] } ]
                      ]
                    },
                    "description": "Season 2 start date. Use ISO 8601 format - Example: 2011-12-31"
                  }
                },
                {

                  "rowspan": 9,
                  "rowspanIds": ["roomType", "lengthOfStay"],

                  "cell": {
                    "ord": 476,
                    "id": "SEASON2END_ES",
                    "name": "End",
                    "type": "DATE",
                    "placeholder": "End Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#SEASON2START_ES", [ "isBlank",false ], { "after": ["#SEASON2START_ES", true] } ],
                        [ "#SEASON3START_ES", [ "isBlank",false ], { "before": "#SEASON3START_ES" } ]
                      ]
                    },
                    "classes": "question-size-2",
                    "description": "Season 2 end date. Use ISO 8601 format - Example: 2011-12-31"
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",
                  "name": "2",
                  "classes": "text-center"
                },
                {
                  "name": "1",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 477,
                    "id": "LRA_S2_RT1_L2_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 2, Room type 1, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 478,
                    "id": "LRA_S2_RT1_L2_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 2, Room type 1, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 483,
                    "id": "NLRA_S2_RT1_L2_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room type 1, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 484,
                    "id": "NLRA_S2_RT1_L2_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room type 1, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 2, "lengthOfStay": 2, "roomType": 2 },
              "cells": [
                {
                  "name": "2",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 479,
                    "id": "LRA_S2_RT2_L2_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 2, Room type 2, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 480,
                    "id": "LRA_S2_RT2_L2_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 2, Room type 2, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 485,
                    "id": "NLRA_S2_RT2_L2_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room type 2, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 486,
                    "id": "NLRA_S2_RT2_L2_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room type 2, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 2, "lengthOfStay": 2, "roomType": 3 },
              "cells": [
                {
                  "name": "3",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 481,
                    "id": "LRA_S2_RT3_L2_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 2, Room Type 3, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 482,
                    "id": "LRA_S2_RT3_L2_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 2, Room Type 3, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 487,
                    "id": "NLRA_S2_RT3_L2_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room Type 3, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 488,
                    "id": "NLRA_S2_RT3_L2_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room Type 3, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 2, "lengthOfStay": 3, "roomType": 1 },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",
                  "name": "3",
                  "classes": "text-center"
                },
                {
                  "name": "1",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 489,
                    "id": "LRA_S2_RT1_L3_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 2, Room type 1, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 490,
                    "id": "LRA_S2_RT1_L3_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 2, Room type 1, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 495,
                    "id": "NLRA_S2_RT1_L3_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room type 1, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 496,
                    "id": "NLRA_S2_RT1_L3_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room type 1, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 2, "lengthOfStay": 3, "roomType": 2 },
              "cells": [
                {
                  "name": "2",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 491,
                    "id": "LRA_S2_RT2_L3_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 2, Room type 2, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 492,
                    "id": "LRA_S2_RT2_L3_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 2, Room type 2, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 497,
                    "id": "NLRA_S2_RT2_L3_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room type 2, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 498,
                    "id": "NLRA_S2_RT2_L3_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room type 2, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 2, "lengthOfStay": 3, "roomType": 3 },
              "cells": [
                {
                  "name": "3",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 493,
                    "id": "LRA_S2_RT3_L3_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 2, Room Type 3, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 494,
                    "id": "LRA_S2_RT3_L3_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 2, Room Type 3, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 499,
                    "id": "NLRA_S2_RT3_L3_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room Type 3, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 500,
                    "id": "NLRA_S2_RT3_L3_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room Type 3, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 2, "lengthOfStay": 4, "roomType": 1 },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",
                  "name": "4",
                  "classes": "text-center"
                },
                {
                  "name": "1",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 501,
                    "id": "LRA_S2_RT1_L4_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 2, Room type 1, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 502,
                    "id": "LRA_S2_RT1_L4_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 2, Room type 1, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 507,
                    "id": "NLRA_S2_RT1_L4_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room type 1, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 508,
                    "id": "NLRA_S2_RT1_L4_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room type 1, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 2, "lengthOfStay": 4, "roomType": 2 },
              "cells": [
                {
                  "name": "2",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 503,
                    "id": "LRA_S2_RT2_L4_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 2, Room type 2, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 504,
                    "id": "LRA_S2_RT2_L4_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 2, Room type 2, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 509,
                    "id": "NLRA_S2_RT2_L4_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room type 2, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 510,
                    "id": "NLRA_S2_RT2_L4_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room type 2, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 2, "lengthOfStay": 4, "roomType": 3 },
              "cells": [
                {
                  "name": "3",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 505,
                    "id": "LRA_S2_RT3_L4_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 2, Room Type 3, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 506,
                    "id": "LRA_S2_RT3_L4_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 2, Room Type 3, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 511,
                    "id": "NLRA_S2_RT3_L4_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room Type 3, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 512,
                    "id": "NLRA_S2_RT3_L4_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 2, Room Type 3, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 3 },
              "cells": [
                {
                  "colspan": 2,
                  "name": "Season 3"
                },
                {
                  "name": "LOS",
                  "classes": "text-center"
                },
                {
                  "name": "RT",
                  "classes": "text-center"
                },
                {
                  "name": "Single",
                  "for": { "rate": "LRA", "occupancy": "SGL" }
                },
                {
                  "name": "Double",
                  "for": { "rate": "LRA", "occupancy": "DBL" }
                },
                {
                  "name": "Single",
                  "for": { "rate": "NLRA", "occupancy": "SGL" }
                },
                {
                  "name": "Double",
                  "for": { "rate": "NLRA", "occupancy": "DBL" }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 3, "lengthOfStay": 2, "roomType": 1 },
              "cells": [
                {

                  "rowspan": 9,
                  "rowspanIds": ["roomType", "lengthOfStay"],

                  "cell": {
                    "ord": 513,
                    "id": "SEASON3START_ES",
                    "name": "Start",
                    "type": "DATE",
                    "placeholder": "Start Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#SEASON2END_ES", [ "isBlank",false ], { "after": "#SEASON2END_ES" } ],
                        [ "#SEASON3END_ES", [ "isBlank",false ], { "before": ["#SEASON3END_ES",true] } ]
                      ]
                    },
                    "description": "Season 3 start date. Use ISO 8601 format - Example: 2011-12-31"
                  }
                },
                {

                  "rowspan": 9,
                  "rowspanIds": ["roomType", "lengthOfStay"],

                  "cell": {
                    "ord": 514,
                    "id": "SEASON3END_ES",
                    "name": "End",
                    "type": "DATE",
                    "placeholder": "End Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#SEASON3START_ES", [ "isBlank",false ], { "after": ["#SEASON3START_ES", true] } ],
                        [ "#SEASON4START_ES", [ "isBlank",false ], { "before": "#SEASON4START_ES" } ]
                      ]
                    },
                    "description": "Season 3 end date. Use ISO 8601 format - Example: 2011-12-31"
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",
                  "name": "2",
                  "classes": "text-center"
                },
                {
                  "name": "1",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 515,
                    "id": "LRA_S3_RT1_L2_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 3, Room type 1, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 516,
                    "id": "LRA_S3_RT1_L2_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 3, Room type 1, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 521,
                    "id": "NLRA_S3_RT1_L2_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room type 1, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 522,
                    "id": "NLRA_S3_RT1_L2_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room type 1, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 3, "lengthOfStay": 2, "roomType": 2 },
              "cells": [
                {
                  "name": "2",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 517,
                    "id": "LRA_S3_RT2_L2_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 3, Room type 2, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 518,
                    "id": "LRA_S3_RT2_L2_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 3, Room type 2, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 523,
                    "id": "NLRA_S3_RT2_L2_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room type 2, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 524,
                    "id": "NLRA_S3_RT2_L2_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room type 2, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 3, "lengthOfStay": 2, "roomType": 3 },
              "cells": [
                {
                  "name": "3",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 519,
                    "id": "LRA_S3_RT3_L2_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 3, Room Type 3, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 520,
                    "id": "LRA_S3_RT3_L2_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 3, Room Type 3, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 525,
                    "id": "NLRA_S3_RT3_L2_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room Type 3, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 526,
                    "id": "NLRA_S3_RT3_L2_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room Type 3, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 3, "lengthOfStay": 3, "roomType": 1 },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",
                  "name": "3",
                  "classes": "text-center"
                },
                {
                  "name": "1",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 527,
                    "id": "LRA_S3_RT1_L3_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 3, Room type 1, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 528,
                    "id": "LRA_S3_RT1_L3_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 3, Room type 1, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 533,
                    "id": "NLRA_S3_RT1_L3_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room type 1, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 534,
                    "id": "NLRA_S3_RT1_L3_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room type 1, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 3, "lengthOfStay": 3, "roomType": 2 },
              "cells": [
                {
                  "name": "2",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 529,
                    "id": "LRA_S3_RT2_L3_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 3, Room type 2, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 530,
                    "id": "LRA_S3_RT2_L3_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 3, Room type 2, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 535,
                    "id": "NLRA_S3_RT2_L3_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room type 2, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 536,
                    "id": "NLRA_S3_RT2_L3_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room type 2, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 3, "lengthOfStay": 3, "roomType": 3 },
              "cells": [
                {
                  "name": "3",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 531,
                    "id": "LRA_S3_RT3_L3_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 3, Room Type 3, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 532,
                    "id": "LRA_S3_RT3_L3_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 3, Room Type 3, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 537,
                    "id": "NLRA_S3_RT3_L3_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room Type 3, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 538,
                    "id": "NLRA_S3_RT3_L3_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room Type 3, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 3, "lengthOfStay": 4, "roomType": 1 },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",
                  "name": "4",
                  "classes": "text-center"
                },
                {
                  "name": "1",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 539,
                    "id": "LRA_S3_RT1_L4_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 3, Room type 1, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 540,
                    "id": "LRA_S3_RT1_L4_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 3, Room type 1, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 545,
                    "id": "NLRA_S3_RT1_L4_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room type 1, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 546,
                    "id": "NLRA_S3_RT1_L4_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room type 1, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 3, "lengthOfStay": 4, "roomType": 2 },
              "cells": [
                {
                  "name": "2",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 541,
                    "id": "LRA_S3_RT2_L4_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 3, Room type 2, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 542,
                    "id": "LRA_S3_RT2_L4_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 3, Room type 2, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 547,
                    "id": "NLRA_S3_RT2_L4_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room type 2, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 548,
                    "id": "NLRA_S3_RT2_L4_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room type 2, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 3, "lengthOfStay": 4, "roomType": 3 },
              "cells": [
                {
                  "name": "3",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 543,
                    "id": "LRA_S3_RT3_L4_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 3, Room Type 3, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 544,
                    "id": "LRA_S3_RT3_L4_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 3, Room Type 3, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 549,
                    "id": "NLRA_S3_RT3_L4_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room Type 3, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 550,
                    "id": "NLRA_S3_RT3_L4_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 3, Room Type 3, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 4 },
              "cells": [
                {
                  "colspan": 2,
                  "name": "Season 4"
                },
                {
                  "name": "LOS",
                  "classes": "text-center"
                },
                {
                  "name": "RT",
                  "classes": "text-center"
                },
                {
                  "name": "Single",
                  "for": { "rate": "LRA", "occupancy": "SGL" }
                },
                {
                  "name": "Double",
                  "for": { "rate": "LRA", "occupancy": "DBL" }
                },
                {
                  "name": "Single",
                  "for": { "rate": "NLRA", "occupancy": "SGL" }
                },
                {
                  "name": "Double",
                  "for": { "rate": "NLRA", "occupancy": "DBL" }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 4, "lengthOfStay": 2, "roomType": 1 },
              "cells": [
                {

                  "rowspan": 9,
                  "rowspanIds": ["roomType", "lengthOfStay"],

                  "cell": {
                    "ord": 551,
                    "id": "SEASON4START_ES",
                    "name": "Start",
                    "type": "DATE",
                    "placeholder": "Start Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#SEASON3END_ES", [ "isBlank",false ], { "after": "#SEASON3END_ES" } ],
                        [ "#SEASON4END_ES", [ "isBlank",false ], { "before": ["#SEASON4END_ES",true] } ]
                      ]
                    },
                    "description": "Season 4 start date. Use ISO 8601 format - Example: 2011-12-31"
                  }
                },
                {

                  "rowspan": 9,
                  "rowspanIds": ["roomType", "lengthOfStay"],

                  "cell": {
                    "ord": 552,
                    "id": "SEASON4END_ES",
                    "name": "End",
                    "type": "DATE",
                    "placeholder": "End Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#SEASON4START_ES", [ "isBlank",false ], { "after": ["#SEASON4START_ES", true] } ],
                        [ "#SEASON5START_ES", [ "isBlank",false ], { "before": "#SEASON5START_ES" } ]
                      ]
                    },
                    "description": "Season 4 end date. Use ISO 8601 format - Example: 2011-12-31"
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",
                  "name": "2",
                  "classes": "text-center"
                },
                {
                  "name": "1",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 553,
                    "id": "LRA_S4_RT1_L2_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 4, Room type 1, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 554,
                    "id": "LRA_S4_RT1_L2_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 4, Room type 1, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 559,
                    "id": "NLRA_S4_RT1_L2_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room type 1, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 560,
                    "id": "NLRA_S4_RT1_L2_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room type 1, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 4, "lengthOfStay": 2, "roomType": 2 },
              "cells": [
                {
                  "name": "2",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 555,
                    "id": "LRA_S4_RT2_L2_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 4, Room type 2, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 556,
                    "id": "LRA_S4_RT2_L2_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 4, Room type 2, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 561,
                    "id": "NLRA_S4_RT2_L2_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room type 2, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 562,
                    "id": "NLRA_S4_RT2_L2_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room type 2, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 4, "lengthOfStay": 2, "roomType": 3 },
              "cells": [
                {
                  "name": "3",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 557,
                    "id": "LRA_S4_RT3_L2_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 4, Room Type 3, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 558,
                    "id": "LRA_S4_RT3_L2_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 4, Room Type 3, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 563,
                    "id": "NLRA_S4_RT3_L2_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room Type 3, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 564,
                    "id": "NLRA_S4_RT3_L2_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room Type 3, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 4, "lengthOfStay": 3, "roomType": 1 },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",
                  "name": "3",
                  "classes": "text-center"
                },
                {
                  "name": "1",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 565,
                    "id": "LRA_S4_RT1_L3_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 4, Room type 1, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 566,
                    "id": "LRA_S4_RT1_L3_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 4, Room type 1, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 571,
                    "id": "NLRA_S4_RT1_L3_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room type 1, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 572,
                    "id": "NLRA_S4_RT1_L3_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room type 1, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 4, "lengthOfStay": 3, "roomType": 2 },
              "cells": [
                {
                  "name": "2",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 567,
                    "id": "LRA_S4_RT2_L3_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 4, Room type 2, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 568,
                    "id": "LRA_S4_RT2_L3_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 4, Room type 2, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 573,
                    "id": "NLRA_S4_RT2_L3_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room type 2, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 574,
                    "id": "NLRA_S4_RT2_L3_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room type 2, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 4, "lengthOfStay": 3, "roomType": 3 },
              "cells": [
                {
                  "name": "3",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 569,
                    "id": "LRA_S4_RT3_L3_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 4, Room Type 3, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 570,
                    "id": "LRA_S4_RT3_L3_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 4, Room Type 3, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 575,
                    "id": "NLRA_S4_RT3_L3_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room Type 3, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 576,
                    "id": "NLRA_S4_RT3_L3_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room Type 3, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 4, "lengthOfStay": 4, "roomType": 1 },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",
                  "name": "4",
                  "classes": "text-center"
                },
                {
                  "name": "1",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 577,
                    "id": "LRA_S4_RT1_L4_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 4, Room type 1, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 578,
                    "id": "LRA_S4_RT1_L4_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 4, Room type 1, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 583,
                    "id": "NLRA_S4_RT1_L4_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room type 1, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 584,
                    "id": "NLRA_S4_RT1_L4_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room type 1, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 4, "lengthOfStay": 4, "roomType": 2 },
              "cells": [
                {
                  "name": "2",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 579,
                    "id": "LRA_S4_RT2_L4_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 4, Room type 2, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 580,
                    "id": "LRA_S4_RT2_L4_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 4, Room type 2, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 585,
                    "id": "NLRA_S4_RT2_L4_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room type 2, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 586,
                    "id": "NLRA_S4_RT2_L4_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room type 2, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 4, "lengthOfStay": 4, "roomType": 3 },
              "cells": [
                {
                  "name": "3",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 581,
                    "id": "LRA_S4_RT3_L4_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 4, Room Type 3, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 582,
                    "id": "LRA_S4_RT3_L4_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 4, Room Type 3, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 587,
                    "id": "NLRA_S4_RT3_L4_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room Type 3, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 588,
                    "id": "NLRA_S4_RT3_L4_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 4, Room Type 3, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 5 },
              "cells": [
                {
                  "colspan": 2,
                  "name": "Season 5"
                },
                {
                  "name": "LOS",
                  "classes": "text-center"
                },
                {
                  "name": "RT",
                  "classes": "text-center"
                },
                {
                  "name": "Single",
                  "for": { "rate": "LRA", "occupancy": "SGL" }
                },
                {
                  "name": "Double",
                  "for": { "rate": "LRA", "occupancy": "DBL" }
                },
                {
                  "name": "Single",
                  "for": { "rate": "NLRA", "occupancy": "SGL" }
                },
                {
                  "name": "Double",
                  "for": { "rate": "NLRA", "occupancy": "DBL" }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 5, "lengthOfStay": 2, "roomType": 1 },
              "cells": [
                {

                  "rowspan": 9,
                  "rowspanIds": ["roomType", "lengthOfStay"],

                  "cell": {
                    "ord": 589,
                    "id": "SEASON5START_ES",
                    "name": "Start",
                    "type": "DATE",
                    "placeholder": "Start Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#SEASON4END_ES", [ "isBlank",false ], { "after": "#SEASON4END_ES" } ],
                        [ "#SEASON5END_ES", [ "isBlank",false ], { "before": ["#SEASON5END_ES",true] } ]
                      ]
                    },
                    "description": "Season 5 start date. Use ISO 8601 format - Example: 2011-12-31"
                  }
                },
                {

                  "rowspan": 9,
                  "rowspanIds": ["roomType", "lengthOfStay"],

                  "cell": {
                    "ord": 590,
                    "id": "SEASON5END_ES",
                    "name": "End",
                    "type": "DATE",
                    "placeholder": "End Date",
                    "validations": {
                      "date_format": "yyyy-MM-dd",
                      "after": ["$programStartDate", true],
                      "before": [ "$programEndDate", true ],
                      "if": [
                        [ "#SEASON5START_ES", [ "isBlank",false ], { "after": ["#SEASON5START_ES", true] } ],
                      ]
                    },
                    "description": "Season 5 end date. Use ISO 8601 format - Example: 2011-12-31"
                  }
                },
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",
                  "name": "2",
                  "classes": "text-center"
                },
                {
                  "name": "1",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 591,
                    "id": "LRA_S5_RT1_L2_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 5, Room type 1, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 592,
                    "id": "LRA_S5_RT1_L2_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 5, Room type 1, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 597,
                    "id": "NLRA_S5_RT1_L2_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room type 1, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 598,
                    "id": "NLRA_S5_RT1_L2_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room type 1, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 5, "lengthOfStay": 2, "roomType": 2 },
              "cells": [
                {
                  "name": "2",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 593,
                    "id": "LRA_S5_RT2_L2_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 5, Room type 2, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 594,
                    "id": "LRA_S5_RT2_L2_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 5, Room type 2, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 599,
                    "id": "NLRA_S5_RT2_L2_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room type 2, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 600,
                    "id": "NLRA_S5_RT2_L2_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room type 2, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 5, "lengthOfStay": 2, "roomType": 3 },
              "cells": [
                {
                  "name": "3",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 595,
                    "id": "LRA_S5_RT3_L2_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 5, Room Type 3, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 596,
                    "id": "LRA_S5_RT3_L2_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 5, Room Type 3, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 601,
                    "id": "NLRA_S5_RT3_L2_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room Type 3, Length of Stay 2, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 602,
                    "id": "NLRA_S5_RT3_L2_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room Type 3, Length of Stay 2, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 5, "lengthOfStay": 3, "roomType": 1 },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",
                  "name": "3",
                  "classes": "text-center"
                },
                {
                  "name": "1",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 603,
                    "id": "LRA_S5_RT1_L3_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 5, Room type 1, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 604,
                    "id": "LRA_S5_RT1_L3_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 5, Room type 1, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 609,
                    "id": "NLRA_S5_RT1_L3_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room type 1, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 610,
                    "id": "NLRA_S5_RT1_L3_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room type 1, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 5, "lengthOfStay": 3, "roomType": 2 },
              "cells": [
                {
                  "name": "2",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 605,
                    "id": "LRA_S5_RT2_L3_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 5, Room type 2, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 606,
                    "id": "LRA_S5_RT2_L3_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 5, Room type 2, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 611,
                    "id": "NLRA_S5_RT2_L3_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room type 2, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 612,
                    "id": "NLRA_S5_RT2_L3_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room type 2, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 5, "lengthOfStay": 3, "roomType": 3 },
              "cells": [
                {
                  "name": "3",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 607,
                    "id": "LRA_S5_RT3_L3_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 5, Room Type 3, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 608,
                    "id": "LRA_S5_RT3_L3_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 5, Room Type 3, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 613,
                    "id": "NLRA_S5_RT3_L3_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room Type 3, Length of Stay 3, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 614,
                    "id": "NLRA_S5_RT3_L3_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room Type 3, Length of Stay 3, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 5, "lengthOfStay": 4, "roomType": 1 },
              "cells": [
                {
                  "rowspan": 3,
                  "rowspanId": "roomType",
                  "name": "4",
                  "classes": "text-center"
                },
                {
                  "name": "1",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 615,
                    "id": "LRA_S5_RT1_L4_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 5, Room type 1, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 616,
                    "id": "LRA_S5_RT1_L4_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 5, Room type 1, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 621,
                    "id": "NLRA_S5_RT1_L4_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room type 1, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 622,
                    "id": "NLRA_S5_RT1_L4_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room type 1, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 5, "lengthOfStay": 4, "roomType": 2 },
              "cells": [
                {
                  "name": "2",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 617,
                    "id": "LRA_S5_RT2_L4_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 5, Room type 2, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 618,
                    "id": "LRA_S5_RT2_L4_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 5, Room type 2, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 623,
                    "id": "NLRA_S5_RT2_L4_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room type 2, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 624,
                    "id": "NLRA_S5_RT2_L4_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room type 2, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 5, "lengthOfStay": 4, "roomType": 3 },
              "cells": [
                {
                  "name": "3",
                  "classes": "text-center"
                },
                {

                  "for": { "rate": "LRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 619,
                    "id": "LRA_S5_RT3_L4_ES_SGL",
                    "name": "Client Negotiated LRA rate for Season 5, Room Type 3, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "LRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 620,
                    "id": "LRA_S5_RT3_L4_ES_DBL",
                    "name": "Client Negotiated LRA rate for Season 5, Room Type 3, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "SGL" },
                  "cell": {
                    "ord": 625,
                    "id": "NLRA_S5_RT3_L4_ES_SGL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room Type 3, Length of Stay 4, Single occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                },
                {

                  "for": { "rate": "NLRA", "occupancy": "DBL" },
                  "cell": {
                    "ord": 626,
                    "id": "NLRA_S5_RT3_L4_ES_DBL",
                    "name": "Client Negotiated non-LRA rate for Season 5, Room Type 3, Length of Stay 4, Double occupancy",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    },
                    "description": "Rate you are offering to this client (per night)"
                  }
                }
              ]
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "ADDO2P",
          "name": "Charge for additional person over 2 persons",
          "ord": 4,
          "cells": [
            {
              "ord": 627,
              "id": "ADDTL_CHARGE_R1_ES",
              "name": "Charge (if any) for each additional person over 2 persons for Room Type 1",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 999999999999]
              }
            },
            {
              "ord": 628,
              "id": "ADDTL_CHARGE_R2_ES",
              "name": "Charge (if any) for each additional person over 2 persons for Room Type 2",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 999999999999]
              }
            },
            {
              "ord": 629,
              "id": "ADDTL_CHARGE_R3_ES",
              "name": "Charge (if any) for each additional person over 2 persons for Room Type 3",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 999999999999]
              }
            },
            {
              "ord": 630,
              "id": "LOS_OCC_ES",
              "name": "Length of Stay required for occupancy tax exemption",
              "type": "TEXT",
              "validations": {
                "max": 10
              },
              "description": "What is the Length of Stay required for occupancy tax exemption? (Not applicable to non-US hotels)"
            },
            {
              "ord": 631,
              "id": "RATE_NOTES_ES",
              "name": "Notes regarding the quoted rate(s)",
              "type": "TEXT",
              "validations": {
                "max": 300
              },
              "classes": "question-size-4"
            },
            {
              "ord": 632,
              "id": "ADDL_SVC_ES",
              "name": "Additional services / amenities / facilities on property",
              "type": "TEXT",
              "validations": {
                "max": 300
              },
              "classes": "question-size-4",
              "description": "Example: BBQ"
            }
          ]
        }
      ]
    },
    {
      "type": "MODULE",
      "id": "GM",
      "name": "Group / Meetings",
      "ord": 6,
      "cells": [
        {
          "type": "GROUP",
          "id": "GI",
          "name": "General Information",
          "ord": 1,
          "cells": [
            {
              "ord": 633,
              "id": "GROUPUNITOFMEAS",
              "name": "Unit of measure for size related questions in this module",
              "type": "LIST",
              "options": [{ "value":"sm", "label":"square meter"}, {"value":"sf", "label":"square feet" }],
              "validations": {
                "included": ["sm", "sf"]
              }
            },
            {
              "ord": 634,
              "id": "MTGSPACE",
              "name": "Size of hotel total meeting space",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 9999999999]
              },
              "description": "What is the size of the hotel's meeting space?"
            },
            {
              "ord": 635,
              "id": "MTGROOMS",
              "name": "Total number of meeting room",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 9999999999]
              },
              "description": "What is the total number of meeting rooms?"
            },
            {
              "ord": 636,
              "id": "LGST_MTGRM",
              "name": "Size of hotel's largest ballroom or meeting room",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 9999999999]
              },
              "description": "What is the size of the hotel's largest ballroom or meeting room?"
            },
            {
              "ord": 637,
              "id": "BOARDROOM",
              "name": "Does hotel have a permanent boardroom set up?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "TCADDR",
          "name": "Taxes/Charges Applied to Day Delegate Rate",
          "ord": 2,
          "cells": [
            {
              "ord": 638,
              "id": "MTG_TAX_AMT",
              "name": "Tax on day delegate Rate",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999]
              },
              "description": "If not applicable, enter 0"
            },
            {
              "ord": 639,
              "id": "MTG_TAX_UOM",
              "name": "Tax on day delegate rate: Percent or fixed amount?",
              "type": "LIST",
              "options": [{"value":"P", "label":"Percent"}, {"value":"F", "label": "Fixed"}, {"value":"N", "label": "Doesn't apply/exist"}],
              "validations": {
                "included": ["P", "F", "N"],
                "if": [
                  [ "#MTG_TAX_AMT", [ "eq","0" ], { "value":"N" }, { "valueNot": "N"} ],
                  // [ "#MTG_TAX_AMT", [ "gt","0" ], { "included": ["P", "F"] } ],
                  // [ "#MTG_TAX_AMT", [ "gt","0" ], { "required": true, "included": ["P", "F"] } ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\""
            },
            {
              "ord": 640,
              "id": "MTG_TAX_INCL",
              "name": "Is the tax included in the day delegate rate",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"],
                "if": [ "#MTG_TAX_AMT", [ "eq","0" ], { "value":"N" } ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\""
            },
            {
              "ord": 641,
              "id": "MTG_SERVICE_AMT",
              "name": "Service charge on day delegate rate",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999999999]
              },
              "description": "If not applicable, enter 0"
            },
            {
              "ord": 642,
              "id": "MTG_SERVICE_UOM",
              "name": "Service charge on day delegate rate. Percent or fixed amount?",
              "type": "LIST",
              "options": [{"value":"P", "label":"Percent"}, {"value":"F", "label": "Fixed"}, {"value":"N", "label": "Doesn't apply/exist"}],
              "validations": {
                "included": ["P", "F", "N"],
                "if": [
                  [ "#MTG_SERVICE_AMT", [ "eq","0" ], { "value":"N" }, { "valueNot": "N"} ],
                  // [ "#MTG_SERVICE_AMT", [ "gt","0" ], { "included": ["P", "F"] } ],
                  // [ "#MTG_SERVICE_AMT", [ "gt","0" ], { "required": true, "included": ["P", "F"] } ],
                ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\""
            },
            {
              "ord": 643,
              "id": "MTG_SERVICE_INCL",
              "name": "Is the service charge included in the day delegate rate",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"],
                "if": [ "#MTG_SERVICE_AMT", [ "eq","0" ], { "value":"N" } ]
              },
              "description": "If this tax doesn't apply/exist, the answer should be \"N\""
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "BORC",
          "name": "Break-out Room Costs",
          "ord": 3,
          "cells": [
            {
              "ord": 644,
              "id": "BREAKOUT_10",
              "name": "What is the maximum cost for a 10 person breakout room",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 999999999999]
              }
            },
            {
              "ord": 645,
              "id": "BREAKOUT_25",
              "name": "What is the maximum cost for a 25 person breakout room",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 999999999999]
              }
            }
          ]
        },
        {
          "type": "TABLE",
          "id": "GRR",
          "name": "Guest Room Rates",
          "classes": "center-60",
          "filters": {
            "season": [ 1,2,3,4,5 ]
          },
          "defaultFilters": {
            "season": [ 1,2,3,4,5 ]
          },
          "manageRows": [
            {
              "id": "season",
              "label": "Season"
            }
          ],
          // "actions": {
          //   "season": {
          //     "id": "season",
          //     "label": "How many Seasons will you allow?",
          //     "type": "filter",
          //     "options": [
          //       { "label": "One Season", "filter": [ 1 ] },
          //       { "label": "Two Seasons", "filter": [ 1,2 ] },
          //       { "label": "Three Seasons", "filter": [ 1,2,3 ] },
          //       { "label": "Four Seasons", "filter": [ 1,2,3,4 ] },
          //       { "label": "Five Seasons", "filter": [ 1,2,3,4,5 ], "default": 1 }
          //     ]
          //   }
          // },
          "ord": 4,
          "cells": [
            {
              "type": "ROW",
              "classes": "row-header",
              "cells": [
                {
                  "name": ""
                },
                {
                  "name": "ROH Group/Meeting Negotiated Rate",
                  "colspan": 2
                }
              ]
            },
            {
              "type": "ROW",
              "cells": [
                {
                  "type": "CELL"
                },
                {
                  "name": "10 - 50 Rooms per Night"
                },
                {
                  "name": "51 - 101 Rooms per Night"
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 1 },
              "cells": [
                {
                  "name": "Season 1"
                },
                {

                  "cell": {
                    "ord": 646,
                    "id": "GROUP_S1_10-50",
                    "name": "What is the ROH group/meeting negotiated rate for season 1, for 10-50 rooms per night?",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    }
                  }
                },
                {

                  "cell": {
                    "ord": 647,
                    "id": "GROUP_S1_51-100",
                    "name": "What is the ROH group/meeting negotiated rate for season 1, for 51-100 rooms per night?",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    }
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 2 },
              "cells": [
                {
                  "name": "Season 2"
                },
                {

                  "cell": {
                    "ord": 648,
                    "id": "GROUP_S2_10-50",
                    "name": "What is the ROH group/meeting negotiated rate for season 2, for 10-50 rooms per night?",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    }
                  }
                },
                {

                  "cell": {
                    "ord": 649,
                    "id": "GROUP_S2_51-100",
                    "name": "What is the ROH group/meeting negotiated rate for season 2, for 51-100 rooms per night?",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    }
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 3 },
              "cells": [
                {
                  "name": "Season 3"
                },
                {

                  "cell": {
                    "ord": 650,
                    "id": "GROUP_S3_10-50",
                    "name": "What is the ROH group/meeting negotiated rate for season 3, for 10-50 rooms per night?",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    }
                  }
                },
                {

                  "cell": {
                    "ord": 651,
                    "id": "GROUP_S3_51-100",
                    "name": "What is the ROH group/meeting negotiated rate for season 3, for 51-100 rooms per night?",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    }
                  }
                }
              ]
            },
            {
              "type": "ROW",
              "for": { "season": 4 },
              "cells":
                [
                  {
                    "name": "Season 4"
                  },
                  {
                    "cell": {
                      "ord": 652,
                      "id": "GROUP_S4_10-50",
                      "name": "What is the ROH group/meeting negotiated rate for season 4, for 10-50 rooms per night?",
                      "type": "DECIMAL",
                      "validations": {
                        "decimal": 2,
                        "between": [0, 999999999999]
                      }
                    }
                  },
                  {
                    "cell": {
                      "ord": 653,
                      "id": "GROUP_S4_51-100",
                      "name": "What is the ROH group/meeting negotiated rate for season 4, for 51-100 rooms per night?",
                      "type": "DECIMAL",
                      "validations": {
                        "decimal": 2,
                        "between": [0, 999999999999]
                      }
                    }
                  }
                ]
            },
            {
              "type": "ROW",
              "for": { "season": 5 },
              "cells": [
                {
                  "name": "Season 5"
                },
                {
                  "cell": {
                    "ord": 654,
                    "id": "GROUP_S5_10-50",
                    "name": "What is the ROH group/meeting negotiated rate for season 5, for 10-50 rooms per night?",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    }
                  }
                },
                {

                  "cell": {
                    "ord": 655,
                    "id": "GROUP_S5_51-100",
                    "name": "What is the ROH group/meeting negotiated rate for season 5, for 51-150 rooms per night",
                    "type": "DECIMAL",
                    "validations": {
                      "decimal": 2,
                      "between": [0, 999999999999]
                    }
                  }
                }
              ]
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "DDR",
          "name": "Day Delegate Rates",
          "ord": 5,
          "cells": [
            {
              "ord": 656,
              "id": "MTGPKG_FULL_10-50",
              "name": "What is the price per person for a 10-50 full day delegate rate?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 999999999999]
              }
            },
            {
              "ord": 657,
              "id": "MTGPKG_HALF_10-50",
              "name": "What is the price per person for a 10-50 half day delegate rate?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 999999999999]
              }
            },
            {
              "ord": 658,
              "id": "MTGPKG_FULL_51-100",
              "name": "What is the price per person for a 51-100 full day delegate rate?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 999999999999]
              }
            },
            {
              "ord": 659,
              "id": "MTGPKG_HALF_51-100",
              "name": "What is the price per person for a 51-100 half day delegate rate?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 999999999999]
              }
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "CI",
          "name": "Costs / Inclusions",
          "ord": 6,
          "cells": [
            {
              "ord": 660,
              "id": "PARK_COMP_MTG",
              "name": "Will the hotel offer complimentary parking for meeting attendees that are not overnight guest",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 661,
              "id": "AV_SUPPLIER",
              "name": "What company supplies and supports the in house audio visual for groups & meetings?",
              "type": "TEXT",
              "validations": {
                "max": 100
              },
              "classes": "question-size-4"
            },
            {
              "ord": 662,
              "id": "LCD_COST",
              "name": "What is the cost per day for an LCD?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 999999999999]
              }
            },
            {
              "ord": 663,
              "id": "LCD_INCL",
              "name": "Is the LCD cost per day included in the day delegate rate?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 664,
              "id": "SCREEN_COST",
              "name": "What is the cost for a standard screen per day?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 999999999999]
              }
            },
            {
              "ord": 665,
              "id": "SCREEN_INCL",
              "name": "Is the standard screen cost per day included in the day delegate rate?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 666,
              "id": "HSIA_COST_MTG",
              "name": "What is the cost for high speed internet in the general session meeting room?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 999999999999]
              }
            },
            {
              "ord": 667,
              "id": "HSIA_INCL_MTG",
              "name": "Is the cost for high speed internet in the general session meeting room included in the day delegate rate?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "GMUDQ",
          "name": "Group / Meetings User-Defined questions",
          "ord": 7,
          "locked": 1,
          "cells": [
            {
              "ord": 668,
              "id": "MTG_UserDefined1",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 669,
              "id": "MTG_UserDefined2",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 670,
              "id": "MTG_UserDefined3",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 671,
              "id": "MTG_UserDefined4",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 672,
              "id": "MTG_UserDefined5",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 673,
              "id": "MTG_UserDefined6",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 674,
              "id": "MTG_UserDefined7",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 675,
              "id": "MTG_UserDefined8",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 676,
              "id": "MTG_UserDefined9",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 677,
              "id": "MTG_UserDefined10",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 678,
              "id": "MTG_UserDefined11",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 679,
              "id": "MTG_UserDefined12",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 680,
              "id": "MTG_UserDefined13",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 681,
              "id": "MTG_UserDefined14",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 682,
              "id": "MTG_UserDefined15",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 683,
              "id": "MTG_UserDefined16",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 684,
              "id": "MTG_UserDefined17",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 685,
              "id": "MTG_UserDefined18",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 686,
              "id": "MTG_UserDefined19",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 687,
              "id": "MTG_UserDefined20",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 688,
              "id": "MTG_UserDefined21",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 689,
              "id": "MTG_UserDefined22",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 690,
              "id": "MTG_UserDefined23",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 691,
              "id": "MTG_UserDefined24",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 692,
              "id": "MTG_UserDefined25",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 693,
              "id": "MTG_UserDefined26",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 694,
              "id": "MTG_UserDefined27",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 695,
              "id": "MTG_UserDefined28",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 696,
              "id": "MTG_UserDefined29",
              "name": "User Defined",
              "type": "USER_DEFINED"
            },
            {
              "ord": 697,
              "id": "MTG_UserDefined30",
              "name": "User Defined",
              "type": "USER_DEFINED"
            }
          ]
        }
      ]
    },
    {
      "type": "MODULE",
      "id": "CSR",
      "name": "Corporate Social Responsibility",
      "ord": 7,
      "cells": [
        {
          "type": "GROUP",
          "id": "CERT",
          "name": "Certification",
          "ord": 1,
          "cells": [
            {
              "ord": 698,
              "id": "CSR_CERTIFIED",
              "name": "Is the hotel certified by a globally recognized \"sustainable\" certification program?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 699,
              "id": "CSR_CERTIFIED_BY",
              "name": "If yes, please name all sustainable certification programs you participate in",
              "type": "TEXT",
              "validations": {
                "max": 100,
                // "if": [ "#CSR_CERTIFIED", [ "eq","Y" ], { "required": true } ]
              },
              "classes": "question-size-4",
              "description": "Examples: Global Sustainable Tourism Council-recognized programs or LEED programs."
            },
            {
              "ord": 700,
              "id": "CSR_CERTIFIED_AUDIT",
              "name": "Does your sustainable certification include a third party audit?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"],
                // "if": [ "#CSR_CERTIFIED", [ "eq","Y" ], { "required": true } ]
              }
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "CM",
          "name": "Carbon Measurement",
          "ord": 2,
          "cells": [
            {
              "ord": 701,
              "id": "MEAS_CARBON",
              "name": "Does the hotel measure carbon per room-night?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 702,
              "id": "MEAS_CARBON_AMT",
              "name": "If yes, what is the carbon per room-night measurement",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 999999],
                "if": [
                  // [ "#MEAS_CARBON", [ "eq","Y" ], { "required": true, "between": [0, 999999] } ],
                  [ "#MEAS_CARBON", [ "eq","N" ], { "value": "0" } ],
                ]
              },
              "description": "Example: 25.15 kg CO2e per room-night or 55.41 lbs CO2e per room-night. (If not measured, enter 0)"
            },
            {
              "ord": 703,
              "id": "MEAS_CARBON_UOM",
              "name": "What is the unit of measure for the carbon per room night measurement",
              "type": "LIST",
              "options": [{ "value":"kgs", "label":"kgs"}, { "value":"lbs", "label":"lbs"}, { "value":"NA", "label":"Not measured" }],
              "validations": {
                "included": ["kgs", "lbs", "NA"],
                "if": [
                  // [ "#MEAS_CARBON", [ "eq","Y" ], { "included": ["kgs", "lbs"] } ],
                  [ "#MEAS_CARBON", [ "eq","N" ], { "value": "NA" }, { "valueNot": "NA "} ],
                ]
              }
            },
            {
              "ord": 704,
              "id": "MEAS_CARBON_PD",
              "name": "What period (in months) does the Carbon measurement cover",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 99],
                "if": [
                  // [ "#MEAS_CARBON", [ "eq","Y" ], { "required": true, "between": [0, 99] } ],
                  [ "#MEAS_CARBON", [ "eq","N" ], { "value": "0" } ],
                ]
              },
              "description": "Example: 6 months/12 months/24 months. (If not measured, enter 0)"
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "WUM",
          "name": "Water Use Measurement",
          "ord": 3,
          "cells": [
            {
              "ord": 705,
              "id": "MEAS_WATER_PRN",
              "name": "Does the hotel measure water per room-night?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 706,
              "id": "MEAS_WATER_AMT",
              "name": "If yes, what is the water per room-night measurement?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 99999],
                "if": [
                  // [ "#MEAS_WATER_PRN", [ "eq","Y" ], { "required": true, "between": [0, 99999] } ],
                  [ "#MEAS_WATER_PRN", [ "eq","N" ], { "value": "0" } ],
                ]
              },
              "description": "Example: 365.60 Liters per room-night or 96.59 gallons per room-night. (If not measured, enter 0)"
            },
            {
              "ord": 707,
              "id": "MEAS_WATER_UOM",
              "name": "What is the unit of measure for water per room night?",
              "type": "LIST",
              "options": [{ "value":"liters", "label":"liters"}, {"value":"gallons", "label":"gallons"}, {"value":"NA", "label":"Not measured" }],
              "validations": {
                "included": ["liters", "gallons", "NA"],
                "if": [
                  // [ "#MEAS_WATER_PRN", [ "eq","Y" ], { "included": ["liters", "gallons"] } ],
                  [ "#MEAS_WATER_PRN", [ "eq","N" ], { "value": "NA" }, { "valueNot": "NA"} ],
                ]
              }
            },
            {
              "ord": 708,
              "id": "MEAS_WATER_PD",
              "name": "What period (in months) does the water per room night measurement cover?",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 99],
                "if": [
                  // [ "#MEAS_WATER_PRN", [ "eq","Y" ], { "required": true, "between": [0, 99] } ],
                  [ "#MEAS_WATER_PRN", [ "eq","N" ], { "value": "0" } ],
                ]
              },
              "description": "Example: 6 months/12 months/24 months. (If not measured, enter 0)"
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "EIM",
          "name": "Energy Intensity Measurement",
          "ord": 4,
          "cells": [
            {
              "ord": 709,
              "id": "MEAS_ENERGY",
              "name": "Does the hotel measure energy intensity?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              },
              "description": "(Energy Intensity is defined as the property's energy use from electricity, heating fuel, purchased steam, and chilled water and hot water, divided by the total gross conditioned floor area of the building)"
            },
            {
              "ord": 710,
              "id": "MEAS_ENERGY_AMT",
              "name": "If yes, what is the energy intensity measurement?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 999999],
                "if": [
                  // [ "#MEAS_ENERGY", [ "eq","Y" ], { "required": true, "between": [0, 999999] } ],
                  [ "#MEAS_ENERGY", [ "eq","N" ], { "value": "0" } ],
                ]
              },
              "description": "Example: 191.1 kBtu per square foot or 2233.2 MJ per square meter. (If not measured, enter 0)"
            },
            {
              "ord": 711,
              "id": "MEAS_ENERGY_UOM",
              "name": "What is the unit of measure for energy intensity?",
              "type": "LIST",
              "options": [{ "value":"BTU per Square Foot", "label":"BTU per Square Foot"}, {"value":"MJ per Square Meter", "label":"MJ per Square Meter"}, {"value":"NA", "label":"Not measured" }],
              "validations": {
                "included": ["BTU per Square Foot", "MJ per Square Meter", "NA"],
                "if": [
                  // [ "#MEAS_ENERGY", [ "eq","Y" ], { "included": ["BTU per Square Foot", "MJ per Square Meter"] } ],
                  [ "#MEAS_ENERGY", [ "eq","N" ], { "value": "NA" }, { "valueNot": "NA"} ],
                ]
              }
            },
            {
              "ord": 712,
              "id": "MEAS_ENERGY_PD",
              "name": "What period does the energy intensity measurement cover?",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 99],
                "if": [
                  // [ "#MEAS_ENERGY", [ "eq","Y" ], { "required": true, "between": [0, 99] } ],
                  [ "#MEAS_ENERGY", [ "eq","N" ], { "value": "0" } ]
                ]
              },
              "description": "Example: 6 months/12 months/24 months. (If not measured, enter 0)"
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "WDM",
          "name": "Water Diversion Measurement",
          "ord": 5,
          "cells": [
            {
              "ord": 713,
              "id": "MEAS_WASTE",
              "name": "Does the hotel measure waste diversion rate?",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 714,
              "id": "MEAS_WASTE_PCT",
              "name": "If yes, what is the waste diversion rate percentage?",
              "type": "DECIMAL",
              "validations": {
                "decimal": 2,
                "between": [0, 9999],
                "if": [
                  // [ "#MEAS_WASTE", [ "eq","Y" ], { "required": true, "between": [0, 9999] } ],
                  [ "#MEAS_WASTE", [ "eq","N" ], { "value": "0" } ]
                ]
              },
              "description": "(If not measured, enter 0)"
            },
            {
              "ord": 715,
              "id": "MEAS_WASTE_PD",
              "name": "What period does the diversion rate measurement cover?",
              "type": "NUMBER",
              "validations": {
                "numeric": true,
                "between": [0, 99],
                "if": [
                  // [ "#MEAS_WASTE", [ "eq","Y" ], { "required": true, "between": [0, 99] } ],
                  [ "#MEAS_WASTE", [ "eq","N" ], { "value": "0" } ]
                ]
              },
              "description": "Example: 6 months/12 months/24 months. (If not measured, enter 0)."
            }
          ]
        },
        {
          "type": "GROUP",
          "id": "CSRP",
          "name": "Client Social Responsibility Program",
          "ord": 6,
          "cells": [
            {
              "ord": 716,
              "id": "ENVIRO_CSR",
              "name": "Does the hotel have its own environmental or Corporate Social Responsibility program",
              "type": "LIST",
              "options":  [{"value": "Y", "label": "Yes"}, {"value":"N", "label": "No"}],
              "validations": {
                "included": ["Y", "N"]
              }
            },
            {
              "ord": 717,
              "id": "ENVIRO_CSR_PROGRAM",
              "name": "If yes, please explain",
              "type": "TEXT",
              "validations": {
                "max": 100,
                // "if": [ "#ENVIRO_CSR", [ "eq","Y" ], { "required": true } ]
              },
              "classes": "question-size-4"
            }
          ]
        }
      ]
    }
  ]
}
