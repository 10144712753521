import component from '../../../../customers/modules/AccountActivity.module.svelte';
export const CUSTOMER_DATA = 'customer-data';

export default {
    component,
    id: CUSTOMER_DATA,
    text: 'Customer Data',
    icon: 'store',
    isAvailable() { return true },
};
