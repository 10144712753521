<script>

  import Navigation from 'src/components/Navigation.svelte';
  import Header from 'src/components/Header.svelte';
  import Checkbox from 'src/pages/bids/Checkbox.svelte';
  import api from './bids-export.api';
  import httpNotifications from "src/api/HttpNotifications";


  const DIRECT = 'DIRECT', CHAIN = 'CHAIN';
  const TYPES = 'types', YEARS = 'programYears';
  const YEARS_OPTIONS = generateAvailableProgramYears();

  let
    configuration = { [YEARS]: YEARS_OPTIONS.slice(0, 2), [TYPES]: [DIRECT] },
    isValid = false;

  $: isValid = configuration[YEARS].length && configuration[TYPES].length;

  function downloadSentBids() {
    if(!isValid) return;
    httpNotifications.handle(api.exportSentBids(configuration[YEARS], configuration[TYPES]));
  }

  function downloadSentNegotiationRequests() {
    if(!isValid) return;
    httpNotifications.handle(api.exportSentNegotiationRequests(configuration[YEARS], configuration[TYPES]));
  }

  function generateAvailableProgramYears(){
    const now = new Date();
    let year = now.getFullYear() + (daysIntoYear(now) > 181? 1: 0) // if in July or later, add one more year;
    const years = [];
    for(let i = 0; i<5; i++){
      years.push(year - i);
    }
    return years;
  }

  function toggle(key, val){
    const arr = configuration[key];
    configuration = {
      ...configuration,
      [key]: arr.includes(val)? arr.filter(aI => aI !== val): [...arr, val],
    }
  }

  function daysIntoYear(date){
    return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
  }

</script>


<div class="rbPage rbDark directory-reports">
  <Header />
  <div class="pageContainer">
    <div class="rbCard navigation"><Navigation /></div>

    <div class="content">
      <div class="heading">
        <div class="column page-label">
          <i class="material-icons page-icon">download</i>
          <div class="labels">
            <h1 class="label-title">Bids Exports</h1>
          </div>
        </div>
      </div>
      <div class="table-header"></div>
      <div class="container">
        <div class="configuration">
          <div class="title">Selected Program Years:</div>
          <div class="selectGroup">
            {#each YEARS_OPTIONS as year}
              <Checkbox selected="{configuration[YEARS].includes(year)}"
                        onClick="{() => toggle(YEARS, year)}"
              >
                {year}
              </Checkbox>
            {/each}
          </div>

          <div class="title">Selected Bid Types:</div>
          <div class="selectGroup">
            <Checkbox selected="{configuration[TYPES].includes(DIRECT)}"
                      onClick="{() => toggle(TYPES, DIRECT)}"
            >
              Direct to Property Bids
            </Checkbox>
            <Checkbox selected="{configuration[TYPES].includes(CHAIN)}"
                      onClick="{() => toggle(TYPES, CHAIN)}"
            >
              Chain Bids
            </Checkbox>
          </div>
        </div>

        <div class="exports">
          <div class="title">Exports: </div>
          <button class="rbButton asDarkMain"
                  disabled="{!isValid}"
                  on:click={downloadSentBids}
          >
            Sent Bids with Bid Links
          </button>

          <button class="rbButton asDarkMain"
                  disabled="{!isValid}"
                  on:click={downloadSentNegotiationRequests}
          >
            Sent Negotiation Requests
          </button>

        </div>
      </div>
    </div>
  </div>
</div>


<style lang="stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.page-label {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
i.material-icons.page-icon {
  color: #00b8ab;
  height: 40px;
  width: 40px;
  background: #273238;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.labels {
  margin-left: 10px;
}
h1.label-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
.table-header {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #00a995 0%, #006eb9 50%, #00aa4f 100%) 0 1 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container {
  display: flex;
  max-width: 700px;
}
.configuration {
  flex: 1 1 100%;
  height: 100%;
  overflow: auto;
  border-right: 1px solid #00a995;
  padding: 15px;
}
.exports {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  padding: 15px;
}
.exports button {
  margin: 10px 0 0;
}
.title {
  font-size: 16px;
  line-height: 32px;
  color: #fff;
  font-weight: bold;
}
.selectGroup {
  padding: 10px 0 10px 20px;
  display: grid;
  row-gap: 10px;
}
</style>
