<script>
    import {createEventDispatcher} from 'svelte';

    export let value, options, id;

    const dispatch = createEventDispatcher();

    function select(option) {
        if(value != option.value){
            dispatch('input', option.value);
        }else{
            dispatch('input', null)
        }
    }
</script>

<div class="radio" id={id}>
    <span><slot /></span>
    <div class="options">
        {#each options as option}
            <div class="option" on:click={() => select(option)} class:selected={value == option.value}>
                {#if value == option.value}
                    <i class="material-icons">check_box</i>
                {:else}
                    <i class="material-icons">check_box_outline_blank</i>
                {/if}
                <div class="label">{option.label}</div>
            </div>
        {/each}
    </div>
</div>

<style type="text/stylus">span {
  color: #546e7a;
  margin-left: 2px;
}
.options {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}
.option {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
}
.option.selected {
  color: #546e7a;
}
i.material-icons {
  margin-right: 5px;
}
</style>
