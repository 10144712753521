<script>
    import {createEventDispatcher} from 'svelte';
    import Header from '../../components/Header.component.svelte';
    import Amenities from './amenities.formatter';
    import CheckBox from './CheckBox.component.svelte';
    import {updateAmenities} from '../../services/hotel.service';
    import isEqual from 'lodash/isEqual';
    import sortBy from 'lodash/sortBy';
    import {AMENITIES} from './amenities.module';


    export let hotel = [], editing;

    let amenities = hotel.amenities? Object.assign([], hotel.amenities): [];
    const dispatch = createEventDispatcher();

    $: basic = Amenities.basic.map(a => ({...a, included: amenities.includes(a.id)}));
    $: others = Amenities.others.map(a => ({...a, included: amenities.includes(a.id)}));
    $: cantSave = isEqual(sortBy(hotel.amenities), sortBy(amenities));

    function save() {
        updateAmenities(hotel.id, amenities).then(h => dispatch('updated', h));
    }

    function cancel() {
        editing = false;
    }

    function toggle({id, included}) {
        if(included) {
            amenities = amenities.filter(amenity => id !== amenity);
        }else{
            amenities = [...amenities, id];
        }
    }
</script>

<Header {hotel}>
    <button class="rbButton asDark" on:click={cancel} >Cancel</button>
    <button class="rbButton asDarkMain" data-icon="save" on:click={save} disabled={cantSave} >Save Changes</button>
</Header>

<div class="main" id={AMENITIES}>
    <div class="section">
        <h2 class="title">Basic Amenities</h2>
        <div class="content">
            {#each basic as amenity}
                <div class="data">
                    <CheckBox checked={amenity.included} on:toggle={() => toggle(amenity)} />
                    <div class="value"  class:excluded={!amenity.included}>
                        {amenity.label}
                    </div>
                </div>
            {/each}
        </div>
    </div>
    <div class="section">
        <h2 class="title">Other Amenities</h2>
        <div class="content">
            {#each others as amenity}
                <div class="data">
                    <CheckBox checked={amenity.included} on:toggle={() => toggle(amenity)} />
                    <div class="value"  class:excluded={!amenity.included}>
                        {amenity.label}
                    </div>
                </div>
            {/each}
        </div>
    </div>
</div>

<style type="text/stylus">.value.excluded {
  opacity: 0.5;
}
</style>