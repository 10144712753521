import {
  resendInvitation,
  editRep,
  deleteReps,
  deactivateReps,
  activateReps,
  revokeAdminStatusToUsers,
  grantAdminStatusToUsers,
  sendInvitation
} from './actions.service';

const actions = [
    {
        label: 'Edit',
        icon: 'edit',
        id: 'edit',
        isAvailable([rep, ..._]) {
            return _.length === 0 && rep.pending
        },
        action: (hotel, [user]) => editRep(hotel, user),
        primary: true,
    },
    {
        label: 'Delete',
        icon: 'delete',
        id: 'delete',
        isAvailable(reps) {
            const selectedAccountTypes = getSelectedRepresentativesAccountTypes(reps);
            return selectedAccountTypes.length === 1;
        },
        primary: true,
        action: deleteReps
    },
    {
        label: 'Deactivate',
        icon: 'not_interested',
        id: 'deactivate',
        isAvailable(reps) {
            const selectedAccountTypes = getSelectedRepresentativesAccountTypes(reps);
            return selectedAccountTypes.length === 1 && reps.every(({isUser}) => isUser);
        },
        primary: true,
        action: deactivateReps
    },
    {
        label: 'Activate',
        icon: 'done',
        id: 'activate',
        isAvailable(reps) {
            return reps.every(({isUser, status}) => isUser && status && !status.active);
        },
        primary: true,
        action: activateReps
    },
    {
        label: 'Resend Invite',
        icon: 'email',
        id: 'resendInvite',
        isAvailable(reps) {
            return reps.every(({isInvitedUser}) => isInvitedUser);
        },
        action: (hotel, reps) => resendInvitation(hotel.id, reps.map(({id}) => id))
    },
    {
        label: 'Send Invite',
        icon: 'email',
        id: 'sendInvite',
        isAvailable(reps) {
          if(reps.length !== 1) return false;
          const r = reps[0];
          return !r.isUser && !r.isInvitedUser
        },
        action: (entity, reps) => sendInvitation(entity, reps[0])
    },
    {
        label: 'Grant Admin Status',
        icon: 'star',
        id: 'grantAdmin',
        isAvailable: allSelectedMustBelongToTheSameEntityAndHaveRole('MANAGER'),
        action: (hotel, reps) => grantAdminStatusToUsers(reps)
    },
    {
        label: 'Revoke Admin Status',
        icon: 'star_outline',
        id: 'revokeAdmin',
        isAvailable: allSelectedMustBelongToTheSameEntityAndHaveRole('ADMIN'),
        action: (hotel, reps) => revokeAdminStatusToUsers(reps)
    },
];

export function getActions(reps) {
    return reps && reps.length > 0? actions.filter(({isAvailable}) => isAvailable(reps)): [];
}

function getSelectedRepresentativesAccountTypes(reps){
    return reps.reduce((acc,r) => {
        if(acc.indexOf(r.accountType) === -1) {
            acc.push(r.accountType);
        }

        return acc;
    }, [])
}

function allSelectedMustBelongToTheSameEntityAndHaveRole(role){
  return function(reps) {
    if(!reps.length) return false;
    const entityId = reps[0].entityId;
    return reps.every( rep => rep.entityId === entityId && rep.role === role );
  }
}
