import EntityProfileRoute from 'root/routes/EntityProfile';
import Notification from 'components/modals/Notification.basic.svelte';
import modals from 'root/services/modals';
import entityProfile from 'root/api/entityProfile.js';
import {handle} from 'root/pages/hotels/profile/services/hotel.service';
import EditHotelImageDialog from 'root/pages/hotels/advanced-search/dialogs/EditImage.dialog.svelte';
import CreateChainDialog from './add/create-edit/Create.dialog.svelte';
import {changeMasterChain, useAsMasterChain, getMasterChains} from './search.service';
import {createNewChain} from '../api/end-points';

const actions = [
    {
        id: 'editLogo',
        label: 'Edit Logo',
        icon: 'image',
        isAvailable(chains) {
            return chains.length  === 1;
        },
        action: ([chain]) => editLogo(chain),
    },
    {
        id: 'delete',
        label: 'Mark Obsolete',
        icon: 'delete',
        isAvailable(chains) {
            return chains.length > 0;
        },
        action: deleteChains,
    },
    {
        id: 'ViewProfile',
        label: 'Profile',
        icon: 'open_in_new',
        isAvailable(chains) {
            return chains.length === 1;
        },
        action([chain]) {
            return openProfile(chain);
        },
    },
    {
        id: 'changeChain',
        label: 'Change Master',
        icon: 'refresh',
        isAvailable(chains) {
            return chains.length > 0 && chains.every(({subtype}) => subtype === 'BRAND');
        },
        action: changeMasterChain,
        primary: true
    },
    {
        id: 'makeChain',
        label: 'Use as Master',
        icon: 'check_circle',
        isAvailable(chains) {
            return chains.length == 1 && chains.every(({subtype}) => subtype === 'BRAND');
        },
        action([chain]) {
            return useAsMasterChain(chain);
        },
        primary: true
    },
];

export function loadActions(selected) {
    return actions.filter(({isAvailable})=>isAvailable(selected));
}

export function editLogo(entity) {
    return modals.show(EditHotelImageDialog, {hotel: entity, action: 'logo'})
        .then(({id, image}) => handle(entityProfile.setLogo(id, entity.type, image)))
        .then(logo => {
            modals.show(Notification, { title: 'Saved!', subtitle:'Entity Logo', content: 'Logo was updated successfully!', resolveLabel: 'OK' })
            return {...entity, logo};
        });
}

export async function deleteChains(chains) {
    await modals.show(Notification, {
        title: 'Warning!',
        subtitle:`Delete Chain${chains.length > 1? 's': ''}?`,
        content: `${chains.length > 1? 'These chains': 'This chain'} will be hidden from users on the site.`,
        resolveLabel: 'Yes, Continue',
        rejectLabel: 'No, Thanks'
    });
    return handle(entityProfile.makeObsolete(chains[0].type, chains.map(chain => chain._id || chain.id)))
            .then(res => {
                modals.show(Notification, {
                    title: `Chain${chains.length > 1? 's': ''} Now Obsolete`,
                    subtitle:'Success',
                    content: `Chain${chains.length > 1? 's': ''} were successfully marked as obsolete `,
                    resolveLabel: 'Ok'
                });

                return res;
            });
}

export function validateEntities(chains) {
    const {type} = chains[0];
    return handle(entityProfile.validateEntities(type, chains.map(({_id, id}) => _id || id)));
}

export function openProfile({_id, id, type}, target='_blank') {
    return Promise.resolve(window.open(EntityProfileRoute.href({entityId: _id || id, section: 'basic-info', type}), target));
}

export async function createChain(subtype = 'MASTER') {
    const chains = subtype !== 'MASTER'? await handle(getMasterChains()): [];
    return modals.show(CreateChainDialog, {chain: {subtype}, chains})
        .then(save)
        .then(({data}) => openProfile(data, '_self'));

    function save(chain) {
        return handle(createNewChain(chain));
    }
}
