import {writable, get} from 'svelte/store';
import {init as initFilters, default as filtersStore} from 'src/components/filters/Filters.store.js';
import FilterText from 'src/components/filters/FilterText.js';
import FilterMultiSelect from 'src/components/filters/FilterMultiSelect.js';
import {DateFormatter, DecimalFormatter} from 'src/utils/internationalization.service';
import {determineMaxWidths} from 'src/services/TextWidthApproximator';
import {listCompetitiveBidsForRfp} from 'src/api';
import httpErrorHandler from 'src/api/HttpErrorHandler';
import {createStringCompareFn, createNumberCompareFn} from 'src/utils/comparison-functions.factory';
import {isFailedEmailAddressStatus} from 'src/pages/competitive-bids/bids/badEmailAddress';

const
  defaultState = {
    loading: false,
    ready: false,
    rfpId: undefined,
    rfpName: '',
    companyName: '',
    buyerDetails: '',
    raw: [],
    formatted: [],
    filtered: [],
    bids: [],
    brands: [],
    widths: {},
    sortByOptions: [],
    sortedBy: undefined,
    textFilter: '',
    selected: [],
    selectionInDestinationsPercentage: {},
    selectionPercentage: 0
  },

  store = writable(defaultState),

  filters = [
    new FilterMultiSelect({
      label: 'Destination',
      options: () => get(store).formatted.map(d => ({value: d.id, label: d.name})),
      test: (i, f) => f.value.indexOf(i.id) > -1
    }),
    new FilterText({label: 'Hotel Name', test: 'hotelName'}),
    new FilterText({label: 'Contact Name', test: 'contact.fullName'}),
    new FilterText({label: 'Contact Email', test: 'contact.emailAddress'}),
    new FilterMultiSelect({
      label: 'Market Tier Match',
      options: [
        { label: 'Matching', value: b => b.marketTierMatching },
        { label: 'Not Matching', value: b => !b.marketTierNotAvailable && !b.marketTierMatching },
        { label: 'Not Available', value: b => b.marketTierNotAvailable },
      ],
      test: (bid, filter) => filter.value.find(fv => fv(bid))
    }),
    new FilterMultiSelect({
      label: 'Max Distance Match',
      options: [
        { label: 'Matching', value: b => b.distanceMatching },
        { label: 'Not Matching', value: b => !b.distanceMatching },
      ],
      test: (bid, filter) => filter.value.find(fv => fv(bid))
    }),
    new FilterMultiSelect({
      label: 'Contact Set',
      options: [
        { label: 'Set', value: b => b.contact.set },
        { label: 'Empty', value: b => !b.contact.set },
      ],
      test: (bid, filter) => filter.value.find(fv => fv(bid))
    }),
  ],

  filtersActiveByDefault = [ 0, 1 ],

  sortByOptions = [
    {value: createStringCompareFn('hotelName'), label: 'Hotel Name ASC'},
    {value: createStringCompareFn('hotelName', -1), label: 'Hotel Name DESC'},
    {value: createNumberCompareFn('distanceMi'), label: 'Distance ASC'},
    {value: createNumberCompareFn('distanceMi', -1), label: 'Distance DESC'},
  ],

  defaultSort = sortByOptions.find(so => so.label === 'Distance ASC'),

  destinationsSort = createStringCompareFn('name'),

  fontSize = 13,

  maxWidths = {
    'maxDistance': 45,
    'marketTiers': 60,
    'amenities': 90,

    'status': 200,
    'contactName': 200,
    'contactEmail': 200,
    'contactPhone': 100,
    'contactLastSeen': 30,
  },

  dateFormatter = DateFormatter('N/A'),

  numberFormatter = DecimalFormatter('N/A', undefined, 0);


let lastFetchPromise;

export default store;

export function init(rfpId = '', data){
  store.update(values => {
    if(values.loading) return values;
    initFilters(filters, filtersActiveByDefault);

    const fetchPromise = (data ? Promise.resolve(data) : loadBids(rfpId));
    lastFetchPromise = fetchPromise;

    fetchPromise
      .then(result => {
        if(lastFetchPromise === fetchPromise){
          const formattedData = formatGroupAndCalculateWidths(result.bids, result.brands),
            vals = {
              loading: false,
              ready: true,
              rfpId,
              rfpName: result.rfpName,
              rfpDueDate: dateFormatter.format(result.rfpDueDate),
              companyName: result.companyName,
              buyerDetails: `${result.buyerFirstName} ${result.buyerLastName} - ${result.buyerEmailAddress}`,
              raw: result,
              formatted: formattedData.data,
              widths: formattedData.widths,
              sortByOptions,
              sortedBy: defaultSort ? defaultSort.value : undefined,
              textFilter: '',
              selected: [],
              selectionInDestinationsPercentage: {},
              selectionPercentage: 0,
              brands: result.brands
            };
          const filtered = sortAndFilter(vals.formatted, vals);
          store.set({...vals, filtered, bids: flatten(filtered)});
        }
      })
      .catch(() => {
        if(lastFetchPromise === fetchPromise){
          store.set(defaultState);
        }
      });

    return {...defaultState, loading: true, ready: false};
  });
}

export function handleBidSelection(bid){
  store.update(values => {
    const {id, destinationId} = bid,
      oldSelected = values.selected,
      newSelected = oldSelected.indexOf(id) === -1 ? [...oldSelected, id] : oldSelected.filter(sId => id !== sId),
      destination = values.filtered.find(d => d.id === destinationId);

    return {
      ...values,
      selected: newSelected,
      selectionInDestinationsPercentage: {
        ...values.selectionInDestinationsPercentage,
        [destinationId]: selectionInDestinationPercentageCalculator(newSelected, destination.bids),
      },
      selectionPercentage: selectionPercentageCalculator(newSelected, values.raw.bids),
    };
  });
}

export function handleDestinationSelection({id, bids}){
  store.update(values => {
    const sectionPercentage = values.selectionInDestinationsPercentage[id] || 0,
      destinationBidsIds = bids.map(b => b.id),
      {newSelected, newDestinationSelectionPercentage} =
        (sectionPercentage === 1 ? deselectAll : selectAll)(destinationBidsIds);

    return {
      ...values,
      selected: newSelected,
      selectionInDestinationsPercentage: {
        ...values.selectionInDestinationsPercentage,
        [id]: newDestinationSelectionPercentage,
      },
      selectionPercentage: selectionPercentageCalculator(newSelected, values.raw.bids),
    }

    function deselectAll(destinationBids){
      return {
        newSelected: values.selected.filter(osId => destinationBids.indexOf(osId) === -1),
        newDestinationSelectionPercentage: 0,
      };
    }

    function selectAll(destinationBids){
      return {
        newSelected: destinationBids.reduce( (acc, dbId) => {
          if (acc.indexOf(dbId) === -1) acc.push(dbId);
          return acc;
        }, [...values.selected]),
        newDestinationSelectionPercentage: 1,
      };
    }
  });
}

export function handleSelectAll() {
  store.update(values => ({
    ...values,
    ...( values.selectionPercentage === 1
      ? { selectionPercentage: 0, selected: [], selectionInDestinationsPercentage: {} }
      : {
        selectionPercentage: 1,
        ...values.bids.reduce( (acc, item) => {
          if(item.rowType === 'BID') {
            acc.selected.push(item.id);
          } else {
            acc.selectionInDestinationsPercentage[item.id] = 1;
          }
          return acc;
        }, {
          selected: [], selectionInDestinationsPercentage: {}
        })
      }),
  }));
}

export function applyFilters(){
  store.update( values => {
    const scrollContainerElement = document.getElementById('VirtualList');
    if(scrollContainerElement) scrollContainerElement.scrollTo(0, 0);

    const filtered = sortAndFilter(values.formatted, values);
    return {
      ...values,
      filtered,
      bids: flatten(filtered),
      selected: [],
      selectionInDestinationsPercentage: {},
      selectionPercentage: 0,
    };
  });
}

export function setTextFilter(textFilter = ''){
  store.update(values => ({...values, textFilter}));
  applyFilters();
}

export function setSort(sortedBy){
  store.update( values => {
    const v = {...values, sortedBy },
      filtered = sortAndFilter(values.formatted, v);

    return {
      ...v,
      filtered,
      bids: flatten(filtered),
    }
  });
}

export function updateBid(updatedBid) {
  store.update(values => {
    const updatedRawData = {...values.raw, bids: values.raw.bids.map(bid => bid._id === updatedBid._id ? updatedBid : bid)},
      formattedData = formatGroupAndCalculateWidths(updatedRawData.bids),
      vals = {
        ...values,
        raw: updatedRawData,
        formatted: formattedData.data,
        widths: formattedData.widths,
        selected: [],
        selectionInDestinationsPercentage: {},
        selectionPercentage: 0
      };
    const filtered = sortAndFilter(vals.formatted, vals);
    return {...vals, filtered, bids: flatten(filtered)};
  });
}

export function updateBids(updatedBids) {
  store.update(values => {
    const updatedRawData = {
        ...values.raw,
        bids: values.raw.bids.map(bid => {
          const updatedBid = updatedBids.find(ub => ub._id === bid._id);
          return updatedBid ? updatedBid : bid;
        })
      },
      formattedData = formatGroupAndCalculateWidths(updatedRawData.bids),
      vals = {
        ...values,
        raw: updatedRawData,
        formatted: formattedData.data,
        widths: formattedData.widths,
      };
    const filtered = sortAndFilter(vals.formatted, vals);
    return {...vals, filtered, bids: flatten(filtered)};
  });
}

function loadBids(rfpId){
  return listCompetitiveBidsForRfp(rfpId)
    .catch(error => httpErrorHandler.handle(error));
}

function formatGroupAndCalculateWidths(rawData, brands = []){
  const formatted = rawData.reduce( (acc, bid) => {
      const { formattedBid, formattedDestination, flattenBid } = formatBid(bid),
        destination = getDestination(acc, formattedDestination);

      destination.bids.push(formattedBid);
      acc.flat.push(flattenBid);
      return acc;

    }, { data: [], flat: []}),
    widths = determineMaxWidths(formatted.flat, fontSize, maxWidths)

  return { data: formatted.data, widths: {...widths, contactName: widths.contactName + 45 }};

  function getDestination({ data, flat }, bidDestination){
    let destination = data.find(d => d.id === bidDestination.id );
    if(!destination){
      destination = bidDestination;
      flat.push(bidDestination.profile);
      destination.bids = [];
      data.push(destination);
    }
    return destination;
  }

  function formatBid(bid = {}){
    const formattedBid = {
        rowType: 'BID',
        id: bid._id,
        destinationId: bid.destination._id,
        destinationDisabled: bid.destination.profile.disabled,
        marketTier: bid.marketTier || 'N/A',
        marketTierMatching: bid.destination.profile.marketTiers.indexOf(bid.marketTier) !== -1,
        marketTierNotAvailable: !bid.marketTier,
        distanceMi: bid.distanceMi,
        distance: `(${numberFormatter.format(bid.distanceMi)} mi)`,
        distanceMatching: bid.destination.profile.maxDistance >= bid.distanceMi,
        hotel: bid.hotel,
        gdsCodes: bid.gdsCodes,
        hotelId: bid.hotelId,
        hotelName: bid.hotelName,
        status: formatStatus(bid.state.status, bid.state.at),
        stateStatus: bid.state.status,
        contact: bid.contact ? {
          id: bid.contact.id,
          set: true,
          isUser: bid.contact.isUser,
          firstName: bid.contact.firstName,
          lastName: bid.contact.lastName,
          fullName: `${bid.contact.firstName} ${bid.contact.lastName}`,
          emailAddress: bid.contact.emailAddress,
          emailAddressStatus: bid.contact.emailAddressStatus,
          emailAddressStatusFailed: isFailedEmailAddressStatus(bid.contact.emailAddressStatus),
          phone: bid.contact.phone,
          jobTitle: bid.contact.jobTitle,
          lastSeen: dateFormatter.format(bid.contact.lastSeen),
        } : {
          id: null,
          set: false,
          isUser: false,
          firstName: '',
          lastName: '',
          fullName: '',
          emailAddress: '',
          emailAddressStatus: '',
          phone: '',
          jobTitle: '',
          lastSeen: '',
        },
      },
      flattenBid = {
        status: formattedBid.status,
        contactName: formattedBid.contact ? formattedBid.contact.fullName : '',
        contactEmail: formattedBid.contact ? formattedBid.contact.emailAddress : '',
        contactPhone: formattedBid.contact ? formattedBid.contact.phone : '',
        contactLastSeen: formattedBid.contact ? formattedBid.contact.lastSeen : '',
      },
      formattedDestination = {
        rowType: 'DESTINATION',
        id: bid.destination._id,
        name: bid.destination.name,
        address: bid.destination.address,
        roomNightsVolume: bid.destination.roomNightsVolume,
        profile: {
          maxDistance: `${numberFormatter.format(bid.destination.profile.maxDistance)} mi`,
          disabled: bid.destination.profile.disabled,
          marketTiers: bid.destination.profile.marketTiers.join(', '),
          amenities: bid.destination.profile.amenities.join(', '),
          brands: formatBrands(bid.destination.profile.brands),
        },
      };

    return {
      formattedBid,
      flattenBid,
      formattedDestination,
    };

    function formatStatus(state, at){
      return `${formatState(state)} - ${dateFormatter.format(at)}`;
    }

    function formatState(state){
      switch (state) {
        case 'COMPETITIVE_NEW':
          return 'New';
        case 'COMPETITIVE_SENT':
          return 'Sent';
        case 'COMPETITIVE_DELETED':
          return 'Deleted';
        case 'COMPETITIVE_DECLINED':
          return 'Supplier Declined';
        case 'COMPETITIVE_RECEIVED':
          return 'Received';
        case 'COMPETITIVE_RECEIVED_ACCEPTED':
          return 'Paid';
        case 'COMPETITIVE_RESPONDED':
          return 'On Buyer BM';
        case 'RESPONDED':
          return 'Buyer Accepted';
        case 'COMPETITIVE_REFUSED':
        case 'COMPETITIVE_REFUSED_ACK':
          return 'Buyer Refused';
        default:
          return state;
      }
    }

    function formatBrands(profileBrands = []){
      if(!profileBrands.length) return '-';
      return profileBrands
        .map(pb => {
          const brand = brands.find(b => b.id === pb.trim());
          return brand ? brand.code : undefined;
        })
        .filter(b => !!b)
        .join(', ');
    }
  }
}

function sortAndFilter(destinations, values) {
  const activeFilters = get(filtersStore).active.filter(f => !f.isEmpty),
    filtered = activeFilters.length || values.textFilter ? filter() : destinations;

  filtered.forEach(d => d.bids.sort(values.sortedBy))
  filtered.sort(destinationsSort);
  return filtered;

  function filter(){
    const filterDestinationsFn = createDestinationFilterFn(),
      filterBidsFn = createBidsFilterFn();

    return destinations.reduce((acc, d) => {
      if(!filterDestinationsFn || filterDestinationsFn(d)){
        const filteredBids = d.bids.filter(filterBidsFn);
        if(filteredBids.length) acc.push({...d, bids: filteredBids});
      }

      return acc;
    }, []);
  }

  function createDestinationFilterFn() {
    const dFilterArr = activeFilters.filter(f => f.label === 'Destination'),
      dFilter = dFilterArr.length ? dFilterArr[0] : null;
    return dFilter ? d => dFilter.test(d, dFilter) : null;
  }

  function createBidsFilterFn() {
    const textFilter = createTextFilter(values.textFilter),
      bidFilters = activeFilters.filter(f => f.label !== 'Destination'),
      actualFilters = textFilter ? [ textFilter, ...bidFilters] : bidFilters;

    return b => actualFilters.reduce( (acc, f) => acc && f.test(b, f), true );
  }

  function createTextFilter(textFilterValue){
    const textFilter = textFilterValue ? new RegExp(textFilterValue, 'i') : false;
    return textFilter ? { test: i => textFilter.test(`${i.hotelName} ${i.contact.fullName}  ${i.contact.emailAddress}`) } : false;
  }
}

function flatten(filtered){
  return filtered.reduce( (acc, b) => [...acc, b, ...b.bids], []);
}

function selectionPercentageCalculator(selected = [], bids = []){
  return selected.length / bids.length;
}

function selectionInDestinationPercentageCalculator(selected = [], destinationBids = []){
  return destinationBids.filter(db => selected.indexOf(db.id) !== -1).length / destinationBids.length;
}
