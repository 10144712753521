<script>

  import DialogTemplate from 'components/modals/DialogTemplate.basic.svelte';
  import httpNotifications from 'src/api/HttpNotifications';
  import {sendUpdateCacheRequestForYear} from "src/pages/per-diem-cache/api";

  export let cancel, hide;

  let gsaYear = getGsaYear();

  function getGsaYear(){
    const current = new Date();
    let year = current.getFullYear();
    const dayOfTheYear = (Date.UTC(year, current.getMonth(), current.getDate()) - Date.UTC(year, 0, 0)) / 86400000 // / (24 * 60 * 60 * 1000);

    if (dayOfTheYear >= 233) { // August 21st
      year++;
    }

    return year;
  }

  function submit(){
      httpNotifications.handle(sendUpdateCacheRequestForYear(gsaYear))
        .then(hide)
  }
</script>


<DialogTemplate width="600" {cancel} closeOnClick="{false}">
  <div class="Container">
    <div class="Heading">
      <div>New Per Diem Cache Request</div>
    </div>

    <div class="Body">
      <section>
        Would you like to create a cache of per diem rates for the {gsaYear} season?
      </section>
    </div>

    <div class="ModalButtons">
      <button class="rbButton asDark" on:click={cancel}>No</button>
      <button class="rbButton asDarkMain" on:click={submit}>Yes</button>
    </div>
  </div>
</DialogTemplate>

<style lang="stylus">.Container {
  display: flex;
  flex-direction: column;
}
.Heading {
  color: #fff;
  font-size: 16px;
  padding: 15px;
  flex: 0 0 auto;
}
.Body {
  padding: 0 15px;
  font-size: 13px;
  line-height: 16px;
  color: #78909c;
  flex: 1 1 100%;
}
.ModalButtons {
  display: flex;
  padding: 15px;
  justify-content: flex-end;
  flex: 0 0 auto;
}
section {
  margin-bottom: 15px;
}
</style>
