<script>
  import Header from 'root/components/Header.svelte';
  import Navigation from 'root/components/Navigation.svelte';
  import modalsService from 'src/services/modals';
  import FixEmailAddressModal from './FixEmailAddressModal.svelte';

  import {default as store, initialize, destroy, setFixed} from './manager.store';
  import noop from 'lodash/noop';
  import {onDestroy} from 'svelte';
  import {FixEmailAddressServiceReport} from 'src/routes/UsersSearch';
  import BidsList from 'src/pages/users/fix-email-address-service/BidsList.svelte';
  import Subscribers from 'src/pages/users/fix-email-address-service/Subscribers.svelte';
  import Chargeable from 'src/pages/users/fix-email-address-service/Chargeable.svelte';
  import LabeledSelect from 'src/components/inputs/LabeledSelect.svelte';

  const actions = [
      {label: 'Fix Email Address Service Report', value: showReport},
      {label: 'Subscribers', value: showSubscribers},
      {label: 'Chargeable', value: showChargeable},
    ],
    sources = ['active', 'ignored'],
    daysToShowOptions = [
      3, 7, 14, 30, 60, 0
    ];

  let action, daysToShow = daysToShowOptions[3], dataSource = sources[0];

  onDestroy(destroy);

  initialize(daysToShow);

  function fix(problem){
    modalsService.show(FixEmailAddressModal, {problem})
      .then(() => setFixed(problem, dataSource))
      .catch(noop)
  }

  function showReport(){
    FixEmailAddressServiceReport.go();
  }

  function showBidsList(problem){
    modalsService.show(BidsList, {problem})
      .catch(noop)
  }

  function showSubscribers(){
    modalsService.show(Subscribers)
      .catch(noop)
  }

  function showChargeable(){
    modalsService.show(Chargeable)
      .catch(noop)
  }

  function selectDaysToShow(day){
    if(daysToShow !== day) {
      daysToShow = day;
      initialize(day);
    }
  }

  function toggleDataSource() {
    dataSource = dataSource === sources[0] ? sources[1] : sources[0];
  }

</script>

<div class="rbPage rbDark">
  <Header></Header>
  <div class="pageContainer">
    <div class="rbCard navigation"><Navigation></Navigation></div>

    <div class="content">
      <div class="heading">
        <div class="column page-label">
          <i class="material-icons page-icon">sick</i>
          <div class="labels">
            <h1 class="label-title">Fix Emails Service Manager</h1>
          </div>
        </div>

        <div class="DaysSelection">
          <span>Days to show:</span>
          {#each daysToShowOptions as day}
            <div class:selected={day === daysToShow} on:click={() => selectDaysToShow(day)}>{day === 0 ? 'All' : day}</div>
          {/each}
          <div data-icon-before="{dataSource === sources[0] ? 'check_box_outline_blank' : 'check_box'}"
               on:click={toggleDataSource}>
            Show Ignored
          </div>
        </div>

        <div class="ActionsContainer">
          <LabeledSelect label="Reports"
                         icon="inventory"
                         options={actions}
                         on:change={ e => e.detail() }
          />
        </div>
      </div>
      <div class="table-header"></div>
      <div class="ListContainer">
        {#if $store.initialized}
          <div class="ContactRow Header">
            <div>Type</div>
            <div>Service</div>
            <div>Failure</div>
            <div>Supplier Contact</div>
            <div>Supplier Contact Company</div>
            <div>RFP Due Date</div>
            <div>Buyer - RFP</div>
            <div>Bid Status</div>
            <div></div>
          </div>

          <div class="List">
            {#each $store[dataSource] as problem}
              <div class="ContactRow">
                <div class="StandaloneIcon" data-icon-before="{problem.problemTypeIcon}"></div>

                <div class="StandaloneIcon"
                     class:InService={problem.fixClient}
                     data-icon-before="{problem.fixClientIcon}"></div>

                <div data-icon-before="{problem.failureTypeIcon}">{problem.failureTimeFormatted}</div>

                <div data-icon-before="{problem.isUserIcon}">
                  {`${problem.fullName} <${problem.emailAddress}>`}
                  <div class="rbTooltip">{`${problem.fullName} <${problem.emailAddress}>`}</div>
                </div>

                <div data-icon-before="{problem.entityTypeIcon}">
                  {problem.entity.name}
                  <div class="rbTooltip">{problem.entity.name}</div>
                </div>

                <div>{problem.rfpDueDate}</div>

                <div class="clickable" on:click={() => showBidsList(problem)}>
                  {problem.buyerRfpName}
                  <div class="rbTooltip">{problem.buyerRfpName}</div>
                </div>

                <div class="clickable" on:click={() => showBidsList(problem)}>
                  {problem.bidStatusFormatted}
                  <div class="rbTooltip">{problem.bidStatusFormatted}</div>
                </div>

                <div>
                  {#if problem.fixed}
                    <button class="rbButton asSmall asDark iconOnlySmall"
                            data-icon="done"
                            disabled>
                    </button>
                  {:else}
                    <button class="rbButton asSmall asDarkMain iconOnlySmall"
                            data-icon="build"
                            on:click={() => fix(problem)}>
                    </button>
                  {/if}
                </div>
              </div>
            {:else}
              <div class="Loading">No Problems Found</div>
            {/each}
          </div>
        {:else}
          <div class="Loading">Loading...</div>
        {/if}
      </div>
    </div>
  </div>
</div>

<style lang="stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.page-label {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
i.material-icons.page-icon {
  color: #00b8ab;
  height: 40px;
  width: 40px;
  background: #273238;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.labels {
  margin-left: 10px;
}
h1.label-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
.table-header {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #00a995 0%, #006eb9 50%, #00aa4f 100%) 0 1 100%;
}
.ListContainer {
  overflow: auto;
  height: 100%;
}
.ContactRow {
  height: 48px;
  padding: 0 15px;
  color: #cfd8dc;
  font-size: 14px;
  display: grid;
  grid-template-columns: 40px 55px 150px 1fr 1fr 100px 1fr 200px 50px;
  align-items: center;
}
.ContactRow:nth-child(even) {
  background: rgba(255,255,255,0.03);
}
.ContactRow div {
  white-space: nowrap;
  overflow: hidden;
  margin-right: 8px;
  text-overflow: ellipsis;
}
.ContactRow div[data-icon-before]:before {
  font-size: 16px;
  margin-right: 5px;
}
.ContactRow.Header {
  color: #fff;
  height: 42px;
  border-bottom: 1px solid #cfd8dc;
  background: rgba(255,255,255,0.1);
}
.StandaloneIcon {
  justify-content: center;
}
.StandaloneIcon:before {
  font-size: 24px !important;
}
.InService:before {
  color: #ffd700;
}
.Loading {
  margin: 50px;
}
.clickable {
  cursor: pointer;
  text-decoration: underline;
}
.clickable:hover {
  color: #fff;
}
.DaysSelection {
  display: flex;
  color: #fff;
}
.DaysSelection div {
  display: inline-block;
  width: 30px;
  border-right: 1px solid #b0bec5;
  text-align: center;
  color: #b0bec5;
  text-decoration: underline;
  cursor: pointer;
}
.DaysSelection div:hover {
  color: #fff;
  text-decoration: none;
}
.DaysSelection div.selected {
  color: #fff;
  text-decoration: none;
}
.DaysSelection div[data-icon-before] {
  display: inline-flex;
  width: auto;
  align-items: center;
  text-decoration: none;
  padding: 0 5px;
}
.DaysSelection div[data-icon-before]:before {
  margin-right: 5px;
  font-size: 20px;
  line-height: inherit;
}
</style>
