<script>
  import Header from 'root/components/Header.svelte';
  import Navigation from 'root/components/Navigation.svelte';
  import FixEmailAddressServiceReportForm
    from 'src/pages/users/fix-email-address-service/report/FixEmailAddressServiceReportForm.svelte';
  import {getReport} from './emailAddressFixReportApi';
  import {DateFormatter} from "src/utils/internationalization.service";

  const df = DateFormatter();

  let
    report,
    loading;

  function runReport(model){
    loading = true;
    getReport(model)
      .then(r => {
        const d = r.data;
        report = {
          $model: model,
          sent: d.sent.length ? d.sent[0].count : 0,
          failed: d.failed.length ? d.failed[0].count : 0,
          repaired: r.data.repaired.length,
          details: r.data.repaired.map(i => ({
            fixedAt: df.format(i.status.at),
            failedAt: df.format(i.fixing.at),
            admin: i.fixing.adminName.split(' ').reduce((acc, s) => {return acc + s[0]}, ''),
            failedEmailAddress: i.fixing.emailAddress,
            fixEmailAddress: i.receiver.address,
            ...parseBids(i.bids)
          }))
        };
        loading = false;
      })
  }

  function parseBids(bids = []){
    switch (bids.length){
      case 0:
        return {rfpName: 'Unknown', hotelName: 'Unknown'};
      case 1: {
        const bid = bids[0];
        return {rfpName: `${bid.competitive ? '[COMP]' : ''} ${bid.rfpName}`, hotelName: bid.bidType === 'CHAIN' ? `${bid.chainName} (NAM)` : bid.hotelName};
      }
      default: {
        const bid = bids[0];
        return {rfpName: areMultipleRfps(bids) ? 'Multiple RFPs' : bid.rfpName, hotelName: `${bid.chainName} (NAM)`}
      }
    }
  }

  function areMultipleRfps(bids){
    let rfpName = ''
    for(let i = 0, l = bids.length ; i<l; i++){
      const b = bids[i];
      if(rfpName) {
        if(rfpName !== b.rfpName) return true;
      } else {
        rfpName = b.rfpName;
      }
    }

    return false;
  }

  function exportToPdf() {
    const iframeContainer = document.querySelector('#printContainer');
    iframeContainer.childNodes.forEach(i => i.remove())
    const iframe = document.createElement('iframe');
    const finalHtml = `
<!DOCTYPE html>
<html lang="en">
<head>
    <title>Email Repair Activity</title>
    <style lang="css" media="print">
  @page {
      /*size: auto;   !* auto is the main current active printer page size *!*/
      size: landscape;
      margin: 0mm;  /* this small affects the margin in the printer IMP settings */
  }

  body {
      background-color:#FFFFFF;
      border: solid 0px #FFFFFF ;
      margin: 0px;  /* the margin style on the data content before printing */
      padding: 30px;
      color: #263238;
      font-size: 12px;
      font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
 }

.Title {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 15px;
}

.Subtitle {
      font-size: 13px;
      color: #90A4AE;
}

.HeaderContainer {
      display: flex;
      border-bottom: 1px solid #B0BEC5;
      align-items: center;
      padding-bottom: 10px;
}

.Header {
      flex: 1;
      font-size: 13px;
      font-weight: bold;
}

.Grid {
      display: grid;
      grid-template-columns: 70px 70px 50px 1fr 1fr 1fr 1fr;
}

.Cell {
      padding-right: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      line-height: 2;
}

.Heading {
      font-weight: bold;
      color: #B0BEC5;
      border-bottom: 1px solid #B0BEC5;
      height: 26px;
      align-items: center;
}

.NoPrint {
      display:none;
}
    </style>
</head>
<body>
${document.querySelector("#Report").innerHTML}
</body>
</html>`;

    const blob = new Blob([finalHtml], {type: 'text/html'});
    iframe.src = window.URL.createObjectURL(blob);
    iframeContainer.innerHTML = '';
    iframeContainer.appendChild(iframe);
    iframe.contentWindow.window.print();
  }

</script>

<div class="rbPage rbDark">
  <Header></Header>
  <div class="pageContainer">
    <div class="rbCard navigation"><Navigation></Navigation></div>

    <div class="content">
      <div class="heading">
        <div class="column page-label">
          <i class="material-icons page-icon">inventory</i>
          <div class="labels">
            <h1 class="label-title">Fix Email Address Service Report</h1>
          </div>
        </div>
      </div>
      <div class="table-header"></div>

      <div class="ContentContainer">

        <div class="FormContainer">
          <FixEmailAddressServiceReportForm runReport="{m => runReport(m)}"/>
        </div>

        {#if loading}
          <div class="Loading">Loading...</div>
        {:else if report}
          <div class="ReportContainer">
            <div id="Report" class="Report">
              <div class="Title">
                {#if report.$model.accountId && report.$model.rfps.length}
                  <div>Email Repair Activity for {report.$model.accountName}</div>
                {:else}
                  <div>Email Repair Activity</div>
                {/if}
                <div class="Subtitle">{report.$model.dateStart} - {report.$model.dateEnd}</div>
              </div>
              <div class="HeaderContainer">
                <div class="Header">
                  <div>Sent: {report.sent}</div>
                  <div>Failed: {report.failed}</div>
                  <div>Repaired: {report.repaired}</div>
                </div>
                <div class="NoPrint">
                  <button class="rbButton asDark asDarkMain " on:click={exportToPdf}>To PDF</button>
                </div>
              </div>
              <div class="Grid Heading">
                <div class="Cell">Fixed at</div>
                <div class="Cell">Failed at</div>
                <div class="Cell">Admin</div>
                <div class="Cell">RFP</div>
                <div class="Cell">Hotel/Chain</div>
                <div class="Cell">Failed email address</div>
                <div class="Cell">Fix email address</div>
              </div>

              {#each report.details as d}
                <div class="Grid">
                  <div class="Cell">{d.fixedAt}</div>
                  <div class="Cell">{d.failedAt}</div>
                  <div class="Cell">{d.admin}</div>
                  <div class="Cell">{d.rfpName}</div>
                  <div class="Cell">{d.hotelName}</div>
                  <div class="Cell">{d.failedEmailAddress}</div>
                  <div class="Cell">{d.fixEmailAddress}</div>
                </div>
              {/each}
            </div>
            <div id="printContainer" style="max-height: 0; overflow: hidden"></div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>

<style lang="stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.page-label {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
i.material-icons.page-icon {
  color: #00b8ab;
  height: 40px;
  width: 40px;
  background: #273238;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.labels {
  margin-left: 10px;
}
h1.label-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
.table-header {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #00a995 0%, #006eb9 50%, #00aa4f 100%) 0 1 100%;
}
.Report {
  padding: 20px;
}
.Title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}
.Subtitle {
  font-size: 13px;
  color: #90a4ae;
}
.HeaderContainer {
  display: flex;
  border-bottom: 1px solid #b0bec5;
  align-items: center;
  padding-bottom: 10px;
}
.Header {
  flex: 1;
  font-size: 13px;
  font-weight: bold;
}
.ContentContainer {
  display: flex;
  height: 100%;
  overflow: hidden;
}
.FormContainer {
  flex: 0 0 360px;
  overflow: auto;
  border-right: 1px solid #b0bec5;
  display: flex;
  flex-direction: column;
}
.Loading {
  padding: 50px;
}
.ReportContainer {
  flex: 1 1 100%;
  overflow: auto;
}
.Grid {
  display: grid;
  grid-template-columns: 70px 70px 50px 1fr 1fr 1fr 1fr;
}
.Cell {
  padding-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 2;
}
.Heading {
  font-weight: bold;
  color: #b0bec5;
  border-bottom: 1px solid #b0bec5;
  height: 26px;
  align-items: center;
}
</style>
