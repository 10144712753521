<div>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="-50 -50 100 100" preserveAspectRatio="xMidYMid" class="animation">
    <circle cx="0" cy="0" r="43" stroke-dasharray="223.83847656827277 74.61282552275759" fill="none"></circle>
  </svg>
</div>

<style>
@keyframes infinite-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.animation {
  display: inline-block;
  animation: infinite-spin 1s infinite;
  animation-timing-function: linear;
  height: 50px;
  width: 50px;
  stroke: #00a99d;
  stroke-width: 5px;
}
</style>
