import adminServer from 'src/api/AdminServer';

function exportSentBids(years, types){
  return adminServer.getAndAutoSaveFile('/bids/exports/sent', {years: years.join(','), types: types.join(',')});
}

function exportSentNegotiationRequests(years, types){
  return adminServer.getAndAutoSaveFile('/bids/exports/negotiation-requests', {years: years.join(','), types: types.join(',')});
}

export default {
  exportSentBids,
  exportSentNegotiationRequests,
}
