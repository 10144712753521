<script>
  import EntityProfileRoute from 'src/routes/EntityProfile';

  export let item;

  function openProfile(){
    if(window) {
      window.open(EntityProfileRoute.href({entityId: item.entityId, section: 'basic-info', type: 'COMPANY'}), '_blank')
    }
  }

</script>

<div class="entity">

  <div class="props">
    <div>Last active: <strong>{item.lastActive}</strong></div>
    <div>&nbsp;&middot;&nbsp;Industry: <strong>{item.industry}</strong></div>


  </div>
  <div class="identifier">
    <strong on:click={openProfile}>{item.name}</strong>
    &middot; {item.agency.name}
  </div>

  <div class="props">
    <div>RFPs: <strong>{item.activity.rfps}</strong></div>
    <div>&nbsp;&middot;&nbsp;Bids: <strong>{item.activity.bids}</strong></div>
    <div>&nbsp;&middot;&nbsp;Sent Bids: <strong>{item.activity.sentBids}</strong></div>
    <div>&nbsp;&middot;&nbsp;Last Bid sent date: <strong>{item.activity.lastBidSent}</strong></div>
  </div>

  <div class="props">
    <div>Competitive Received: <strong>{item.competitive.received}</strong></div>
    <div>&nbsp;&middot;&nbsp;Accepted: <strong>{item.competitive.accepted}</strong></div>
    <div>&nbsp;&middot;&nbsp;Final Agreements: <strong>{item.competitive.fa}</strong></div>
    <div>&nbsp;&middot;&nbsp;No Thank You: <strong>{item.competitive.noty}</strong></div>
  </div>
</div>

<style lang="stylus">.entity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(176,190,197,0.239);
  font-size: 12px;
  line-height: 1.4;
  min-height: 60px;
}
.props {
  opacity: 0.6;
  display: flex;
  flex-wrap: wrap;
}
.identifier {
  font-size: 14px;
}
.identifier strong {
  cursor: pointer;
  text-decoration: underline;
}
.identifier strong:hover {
  text-decoration: none;
}
</style>
