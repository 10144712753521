<script>
  import {default as store, service} from './accounts.store';

  import Search from 'src/pages/users/manager/tabs/by-account/AccountSearch.svelte';
  import View from 'src/pages/users/manager/tabs/by-account/AccountView.svelte';

  service.initialize();
</script>

<div class="Container">
  {#if $store.showSearch}
    <Search />
  {:else}
    <View />
  {/if}
</div>

<style lang="stylus">.Container {
  flex: 1 1 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
</style>
