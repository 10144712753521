import adminServer from 'root/api/AdminServer';
import {checkAndExecute} from 'root/api/helpers';

export function search(type, request) {
    return checkAndExecute(
        () => type && request,
        () => adminServer.put(`entities/search/${type}`, request)
    )
}


function updateChainCode(id, code) {
  return checkAndExecute(
    () => id && code,
    () => adminServer.put(`chains/${id}/update-code/${code}`)
  )
}

function getCompanyAgency(id) {
    return checkAndExecute(
        () => id,
        () => adminServer.get(`companies/${id}/get-agency`),
    );
}

function loadCompaniesActivity(){
  return adminServer.get('companies/activity');
}

function loadCompanies(id) {
    return checkAndExecute(
        () => id,
        () => adminServer.get(`travel-agencies/${id}/get-companies`),
    );
}


function deleteRepresentatives(id, type, request) {
    return checkAndExecute(
        () => id && request,
        () => adminServer.put(`entities/${type}/${id}/delete-reps`, request)
    );
}

function deactivateRepresentatives(id, type, request) {
    return checkAndExecute(
        () => id && request,
        () => adminServer.put(`entities/${type}/${id}/deactivate-reps`, request)
    );
}

function activateRepresentatives(id, type, request) {
    return checkAndExecute(
        () => id && request,
        () => adminServer.put(`entities/${type}/${id}/activate-reps`, request)
    );
}

function getChain(id) {
    return checkAndExecute(
        () => id,
        () => adminServer.get(`chains/get/${id}`)
    );
}

export default {
    search,
    getCompanyAgency,
    loadCompanies,
    loadCompaniesActivity,
    deactivateRepresentatives,
    activateRepresentatives,
    deleteRepresentatives,
    getChain,
    updateChainCode
}
