<script>
  import Header from "../../Header.svelte";
  import { BASIC_INFO } from ".";
  import { editUserDetails } from "../../../search/actions.service";

  export let user;

  function edit() {
    editUserDetails(user).then(u => user = u);
  }
</script>

<style type="text/stylus">.label {
  color: #72828a;
  min-width: 200px;
}
.main :global(.mapDiv:not(.expanded)) {
  height: 100px;
  width: 100px;
  max-width: 100px;
  min-width: 0;
  position: fixed;
  bottom: 10px;
  right: 10px;
}
.main :global(.mapDiv:not(.expanded) .coordinates),
.main :global(.mapDiv:not(.expanded) .coordinates .gm-fullscreen-control) {
  display: none;
}
.pin {
  font-size: 40px;
}
</style>

<Header {user}>
  <slot />
  <button class="rbButton asDarkMain" data-icon="edit" on:click={edit}>
    Edit
  </button>
</Header>

<div class="main rbDark">
  <div class="section" id="basic">
    <h2 class="title">Basic Information</h2>
    <div class="content">
      <div class="data">
        <span class="label">Full Name</span>
        <span class="value">{user.fullName}</span>
      </div>
      <div class="data">
          <div class="label">Email Address</div>
          <div class="value">{user.emailAddress}</div>
        </div>
      <div class="data">
        <span class="label">Pin Number</span>
        <span class="value pin">
          {user.pin || ''}
        </span>
      </div>
      <div class="data">
        <span class="label">Type</span>
        <span class="value">{user.root? "Root User": "Support Rep"}</span>
      </div>
    </div>
  </div>
</div>
