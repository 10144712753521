import amenities from 'root/assets/data/hotel-amenities';

export default {
    basic: amenities.filter(isBasic),
    others: amenities.filter(notBasic)
}

function isBasic({important}) {
    return important > 0;
}

function notBasic(h) {
    return !isBasic(h)
}