<script>
  import {UserManager as UserManagerRoute} from 'src/routes/UsersSearch';
  import Header from 'root/components/Header.svelte';
  import Navigation from 'root/components/Navigation.svelte';

  import TabByUserDetails from './tabs/by-user-details/TabByUserDetails.svelte';
  import TabByAccount from './tabs/by-account/TabByAccount.svelte';
  import TabByEntity from './tabs/by-entity/TabByEntity.svelte';
  import TabMostUserAccounts from './tabs/most-user-accounts/TabMostUserAccounts.svelte';

  export let tab = 'DETAILS';

  let users = [];

</script>

<div class="rbPage rbDark">
  <Header />

  <div class="pageContainer">
    <div class="rbCard navigation">
      <Navigation />
    </div>

    <div class="contentContainer">
      <div class="heading">
        <div class="column page-label">
          <i class="material-icons page-icon">people_alt</i>
          <div class="labels">
            <h1 class="label-title">User Manager</h1>
          </div>
        </div>
      </div>

      <div class="tabs">
        <a href="{UserManagerRoute.href({tab: 'DETAILS'})}" class="tab" class:selected={tab === 'DETAILS'}>By User Details</a>
        <a href="{UserManagerRoute.href({tab: 'ACCOUNTS'})}" class="tab" class:selected={tab === 'ACCOUNTS'}>By Account</a>
        <a href="{UserManagerRoute.href({tab: 'ENTITY'})}" class="tab" class:selected={tab === 'ENTITY'}>By Entity</a>
        <a href="{UserManagerRoute.href({tab: 'ACCOUNTS_COUNT'})}" class="tab" class:selected={tab === 'ACCOUNTS_COUNT'}>Users with Most Accounts</a>
      </div>

      <div class="content">
        {#if tab === 'DETAILS'}
          <TabByUserDetails />
        {:else if tab === 'ACCOUNTS'}
          <TabByAccount />
        {:else if tab === 'ENTITY'}
          <TabByEntity />
        {:else if tab === 'ACCOUNTS_COUNT'}
          <TabMostUserAccounts />
        {/if}
      </div>
    </div>
  </div>
</div>

<style lang="stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.contentContainer {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.page-label {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
.page-icon {
  color: #00b8ab;
  height: 40px;
  width: 40px;
  background: #273238;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.labels {
  margin-left: 10px;
}
h1.label-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
.content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1 100%;
}
.tabs {
  border-bottom: 1px solid #00b8ab;
}
.tab {
  font-size: 13px;
  padding: 3px 12px;
  margin-left: 5px;
  border-radius: 6px 6px 0 0;
  display: inline-block;
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #263238;
  border: 1px solid #263238;
  border-bottom: 0;
  outline: none;
  color: rgba(255,255,255,0.8);
  transition: all linear 0.1s;
}
.tab:hover {
  background: rgba(0,184,171,0.8);
  border: 1px solid rgba(0,184,171,0.8);
  color: #fff;
  padding-top: 5px;
}
.tab.selected {
  padding-top: 6px;
  background: #00b8ab;
  border: 1px solid #00b8ab;
  color: #fff;
}
</style>
