<script>
    import InputText from 'components/molecules/inputs/InputText.svelte';
    import Select from 'components/molecules/inputs/SelectModel.svelte';
    import List from './QuestionnaireFormRadioQuestionModel.component.svelte';

    export let question;
</script>

<div class="question">
    {#if question.type === 'DECIMAL'}
        <InputText id={question.id} pattern={/^[0-9]+$/}>{question.ord}. {question.name}</InputText>
    {:else if question.type === 'NUMBER'}
        <InputText id={question.id}  pattern={/^-?\d+\.?\d*$/}>{question.ord}. {question.name}</InputText>
    {:else if question.type === 'LIST'}
        <List id={question.id} options={question.options}>{question.ord}. {question.name}</List>
    {:else if question.type === 'SELECT'}
        <Select id={question.id} options="{question.options}">{question.ord}. {question.name}</Select>
    {:else if question.type === 'TEXT'}
        <InputText id={question.id}>{question.ord}. {question.name}</InputText>
    {/if}
</div>

<style type="text/stylus">.question {
  flex: 1;
  min-width: 450px;
  max-width: 450px;
  margin: 5px 0;
}
.question :global(.rbInput.component .container) {
  border: 1px solid #e6e7e8;
  background: transparent;
  color: #546e7a;
  border-radius: 10px;
}
.question :global(.rbInput.component .container .input) {
  color: #546e7a;
}
.question :global(.rbInput.component .container .label.notEmpty),
.question :global(.rbInput.component .container .label.focused) {
  background: #fff;
}
</style>
