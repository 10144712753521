<script>
  import {default as store, updateListSelection, editContact} from './SelectContact.store';
  import getSelectionIcon from 'src/utils/selection-icons.service';

  export let contact = {};

  let selected;

  $: selected = !!$store.listModel && $store.listModel.id === contact.id;
</script>

<div class="Contact"
     class:Selected="{selected}"
     on:click="{() => !contact.badEmailAddress && updateListSelection(contact)}"
>
  <div class="ContactTitleContainer"
       data-icon-before="{contact.badEmailAddress && !selected ? '' : getSelectionIcon(selected)}">
    <div class="ContactTitle">
      <div class="ContactName">{contact.fullName}</div>
      {#if contact.isUser}
        <div class="ContactClass Verified" data-icon-before="check_circle">Verified User</div>
      {:else}
        <div class="ContactClass" data-icon-before="done">Unverified User</div>
      {/if}
    </div>

    {#if !contact.isUser}
      <div>
        <button class="rbButtonSmall asDark" on:click={() => editContact(contact.raw)}>Edit</button>
      </div>
    {/if}
  </div>


  <div class="ContactDetailsContainer">
    <div class="ContactDetail">
      <span class="ContactDetailLabel">Email: </span>
      <span class:BadEmailAddress={contact.badEmailAddress}>{contact.emailAddress}</span>
    </div>

    {#if contact.phone}
      <div class="ContactDetail">
        <span class="ContactDetailLabel">Phone: </span>
        <span>{contact.phone}</span>
      </div>
    {/if}

    {#if contact.jobTitle}
      <div class="ContactDetail">
        <span class="ContactDetailLabel">Job Title: </span>
        <span>{contact.jobTitle}</span>
      </div>
    {/if}

    {#if contact.lastActive}
      <div class="ContactDetail">
        <span class="ContactDetailLabel">Last Active: </span>
        <span>{contact.lastActive}</span>
      </div>
    {/if}
  </div>

  {#if contact.invitation}
    <div class="ContactDetailsContainer">
      {#if contact.invitation.statusDate}
        <div class="ContactDetail">
          <span class="ContactDetailLabel">Bid Opened: </span>
          <span>{contact.invitation.statusDate}</span>
        </div>
      {/if}

      {#if contact.invitation.nth && contact.invitation.lastSent}
        <div class="ContactDetail">
          <span>{contact.invitation.nth} invitation sent {contact.invitation.lastSent}</span>
        </div>
      {/if}

      {#if contact.invitation.firstSent}
        <div class="ContactDetail">
          <span>{contact.invitation.first} invitation sent {contact.invitation.firstSent}</span>
        </div>
      {/if}
    </div>
  {/if}
</div>

<style lang="stylus">.Contact {
  background: #2c383d;
  border-top: 1px solid #cfd8dc;
}
.Contact:first {
  border-color: transparent;
}
.ContactTitleContainer {
  height: 60px;
  display: flex;
  padding: 5px 10px;
}
.ContactTitleContainer:before {
  width: 25px;
  cursor: pointer;
}
.ContactTitle {
  flex: 1;
  padding: 0 10px;
}
.ContactClass {
  font-size: 13px;
}
.ContactClass:before {
  font-size: 16px;
  padding-right: 5px;
}
.ContactClass.Verified:before {
  color: #00a99d;
}
.ContactDetailsContainer {
  padding: 0 10px 10px 44px;
  font-size: 12px;
  line-height: 14px;
  color: #fff;
}
.ContactDetailLabel {
  color: #cfd8dc;
}
.BadEmailAddress {
  text-decoration: line-through;
  color: #f15a24;
}
</style>
