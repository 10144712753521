<script>
  import {default as store, service} from './entity.store';

  import UsersTable from '../UsersTable.svelte';

</script>

<div class="Container">
  <div class="Header">
    <div class="Back">
      <button class="rbButton asDark iconOnly" data-icon="arrow_back" on:click={service.showSearch}></button>
    </div>

    <div class="Title">
      <div>{$store.entityType.label}</div>
      <div class="EntityName">{$store.entity.name}</div>
      {#if $store.accountSuspended}<div class="AccountSuspended">ACCOUNT SUSPENDED</div>{/if}
    </div>

    <div class="Actions">

    </div>
  </div>

  <div class="List">
    <UsersTable />
  </div>
</div>

<style lang="stylus">.Container {
  flex: 1 1 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.Header {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #00a99d;
}
.Title {
  font-size: 13px;
  padding: 0 10px;
}
.EntityName {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}
.AccountSuspended {
  color: #e65622;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
}
.List {
  flex: 1 1 100%;
  overflow: auto;
}
</style>
