<script>
  import {loadRepresentatives} from './emailAddressFixServiceApi.js';
  import {getIsUserIcon} from './representatives';
  import moment from 'moment';
  import modalsService from "src/services/modals";
  import ErrorsModal from "src/components/ErrorsModal.svelte";

  export let
    entityId = undefined,
    selectRepresentative = () => {};

  let representatives = [];

  loadRepresentatives(entityId)
    .then(r => {
      representatives = prepareRepresentatives(r.data);
    })
    .catch(e => {
      modalsService.show(ErrorsModal, {
        message: `Failed to load representatives`,
        error: e && e.data && e.data.message || null,
      });

      representatives = [];
    });

  function prepareRepresentatives(representatives){
    return representatives
      .map(r => {
        const isEmailAddressDeliverable = r.emailAddressStatus !== 'BOUNCED' && r.emailAddressStatus !== 'COMPLAINT';
        return {
          id: r.id,
          firstName: r.firstName,
          lastName: r.lastName,
          fullName: `${r.lastName} ${r.firstName}`,
          emailAddress: r.emailAddress,
          phone: r.phone,
          jobTitle: r.jobTitle,
          isEmailAddressDeliverable,
          isEmailAddressDeliverableIcon: isEmailAddressDeliverable ? 'mail' : 'report_problem',
          isUser: r.isUser,
          isUserIcon: r.isUser ? 'how_to_reg' : 'person_off',
          lastActiveISO: r.lastActive,
          lastActive: loadLastActive(r.lastActive)
        };
      })
      .sort((a,b) => {
        if(a.isEmailAddressDeliverable === b.isEmailAddressDeliverable){
          if(a.isUser === b.isUser){
            if(a.lastActiveISO || b.lastActiveISO){
              return b.lastActiveISO === a.lastActiveISO ? 0 : b.lastActiveISO > a.lastActiveISO ? 1 : -1;
            } else {
              return b.id === a.id ? 0 : b.id > a.id ? 1 : -1;
            }
          } else {
            return b.isUser - a.isUser;
          }
        } else {
          return b.isEmailAddressDeliverable - a.isEmailAddressDeliverable;
        }
      });
  }

  function loadLastActive(time){
    let result = undefined;
    if(time){
      const m = moment.parseZone(time);
      if(m.isValid()) result = m.format("L")
    }
    return result;
  }

</script>

<div class="RepresentativeList">
  {#each representatives as rep}
    <div class="Representative"
         class:Recommended={rep.isUser && rep.isEmailAddressDeliverable}
         class:Bad={!rep.isEmailAddressDeliverable}
         on:click={() => selectRepresentative(rep)}
    >
      <div data-icon-before="{getIsUserIcon(rep.isUser)}"
           class:User={rep.isUser}>
        {rep.fullName}
      </div>

      <div data-icon-before="{rep.isEmailAddressDeliverableIcon}"
           class:Undeliverable={!rep.isEmailAddressDeliverable}>
        {rep.emailAddress}
      </div>

      {#if rep.lastActive}<div data-icon-before="login">{rep.lastActive}</div>{/if}

      {#if rep.phone}<div data-icon-before="phone">{rep.phone}</div>{/if}

      {#if rep.jobTitle}<div data-icon-before="paid">{rep.jobTitle}</div>{/if}
    </div>
  {:else}
    <div class="Notification">No Representatives found</div>
  {/each}
</div>

<style lang="stylus">.RepresentativeList {
  overflow-y: auto;
}
.Representative {
  margin: 0 auto 15px;
  width: 480px;
  border: 1px solid #b0bec5;
  border-radius: 5px;
  padding: 10px;
  background: #263238;
  color: #b0bec5;
  cursor: pointer;
}
.Representative:hover {
  border: 1px solid #eceff1;
  background: #2c383d;
  color: #eceff1;
}
.Representative [data-icon-before] {
  display: flex;
}
.Representative [data-icon-before]:before {
  margin-right: 5px;
  font-size: 16px;
}
.Representative.Recommended {
  border: 1px solid #ffd700;
}
.Representative.Recommended:hover {
  border: 1px solid #ffd700;
}
.Bad {
  border: 1px solid #f15a24;
}
.Bad:hover {
  border: 1px solid #f15a24;
}
.Undeliverable {
  text-decoration: line-through;
  color: #f15a24;
}
.User:before {
  color: #ffd700;
}
.Notification {
  margin: 10px 50px;
  color: #78909c;
  font-size: 13px;
}
</style>
