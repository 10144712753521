<script>
    import Coordinates from 'components/molecules/coordinates/Coordinates.svelte';
    import { getContext } from 'svelte';
    import Required from 'components/validation/validators/required.js';

    export let value = {lat: 40.1451, lng: -99.668},
        zoom = 3;

    let formModel = getContext('formModel');

    // formModel.addModel({ id: 'location.coordinates.lat', value: value.lat, validations: [ Required.create(true) ] });
    // formModel.addModel({ id: 'location.coordinates.lng', value: value.lng, validations: [ Required.create(true) ] });

    handleCoordinatesValueChange({detail: value});

    $: handleCoordinatesModelChange($formModel.values['location.coordinates.lat'], $formModel.values['location.coordinates.lng']);

    function handleCoordinatesModelChange(lat, lng){
        if(lat && lng && !(value.lat === lat && value.lng === lng)){
            zoom = 17;
            value = {lat, lng};
        }
    }

    function handleCoordinatesValueChange({detail}){
        if(detail) {
            value = {...detail};
            formModel.setValue('location.coordinates.lat', detail.lat);
            formModel.setValue('location.coordinates.lng', detail.lng);
        }
    }
</script>

<Coordinates {value} {zoom} on:input="{handleCoordinatesValueChange}"></Coordinates>
