<script>
  import Header from 'root/components/Header.svelte';
  import Navigation from 'root/components/Navigation.svelte';
  import Spinner from 'components/atoms/Spinner.svelte';
  import SuspendAccountDialog from './SuspendAccount.dialog.svelte';

  import HttpErrorHandler from 'src/api/HttpErrorHandler';
  import API from './api/suspended-accounts';
  import modals from 'root/services/modals';

  let
    pageState = 'LOADING',
    suspendedAccounts = [];

  initialize();

  async function initialize() {
    await loadSuspendedAccounts();
  }

  async function loadSuspendedAccounts(){
    pageState = 'LOADING';

    try {
      suspendedAccounts = await API.listSuspendedAccounts();
      pageState = 'READY';
    } catch (e){
      console.error(e);
      pageState = 'ERROR';
      HttpErrorHandler.handle(e);
    }
  }

  async function suspendAccount(){
    try {
      await modals.show(SuspendAccountDialog, {suspendedAccounts});
      await loadSuspendedAccounts()
    } catch (ignore) {}
  }

  async function unsuspendAccount(accountId){
    await API.unsuspendAccount(accountId);
    await loadSuspendedAccounts()
  }

</script>

<div class="rbPage rbDark">
  <Header />

  <div class="pageContainer">
    <div class="rbCard navigation">
      <Navigation />
    </div>

    <div class="contentContainer">
      <div class="heading">
        <div class="column page-label">
          <i class="material-icons page-icon">currency_exchange</i>
          <div class="labels">
            <h1 class="label-title">Suspended Accounts</h1>
          </div>
        </div>

        <div class="column">
          <button class="rbButton asDarkMain"
                  data-icon="add"
                  on:click={suspendAccount}>
            Suspend Account
          </button>
        </div>
      </div>

      <div class="content">
        {#if pageState === 'LOADING'}
          <div class="Notice">
            <Spinner />
          </div>
        {:else if pageState === 'READY'}
          <table>
            <tr>
              <th class="tName ellipsis">Name</th>
              <th class="tType ellipsis">Type</th>
              <th class="tAction">Action</th>
            </tr>
            {#each suspendedAccounts as acc}
              <tr>
                <td class="tName ellipsis">{acc.name}</td>
                <td class="tType ellipsis">{acc.type}</td>
                <td class="tAction">
                  <button class="rbButtonSmall asDark"
                          on:click={() => unsuspendAccount(acc.id)}>
                    Remove
                  </button>
                </td>
              </tr>
            {:else}
              <tr><td colspan="3" class="tName tEmpty text-center">No Suspended Accounts.</td></tr>
            {/each}
          </table>
        {:else if pageState === 'ERROR'}
          <div class="Notice">
            There was an error....
          </div>
        {/if}

      </div>
    </div>
  </div>
</div>

<style lang="stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.contentContainer {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.page-label {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
i.material-icons.page-icon {
  color: #00b8ab;
  height: 40px;
  width: 40px;
  background: #273238;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.labels {
  margin-left: 10px;
}
h1.label-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
.content {
  border-top: 1px solid #00b8ab;
  overflow: auto;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
table {
  display: grid;
  grid-template-columns: 300px 160px 120px;
  width: 580px;
  margin: 80px auto 0;
  border: 1px solid #78909c;
  border-collapse: collapse;
}
tr {
  display: contents;
}
th {
  background: #263238;
  color: #eceff1;
  font-size: 15px;
  position: sticky;
  top: 0;
}
.tName {
  border: 1px solid #78909c;
  padding: 10px 15px;
}
td.tName {
  font-size: 15px;
  font-weight: bold;
}
.tType {
  padding: 10px 15px;
  text-transform: capitalize;
  border: 1px solid #78909c;
  text-align: center;
}
.tAction {
  padding: 10px 15px;
  text-align: center;
  border: 1px solid #78909c;
  text-transform: uppercase;
}
td.tEmpty {
  border: 0;
}
.Notice {
  margin: 80px;
  text-align: center;
  font-size: 16px;
}
</style>
