<script>
  import { tick, createEventDispatcher } from 'svelte';
  import { disableFilter, setFilterValue } from './Filters.store';
  import SelectModalMenu from 'src/components/inputs/SelectModalMenu.svelte';

  export let filter = {};

  const dispatchEvent = createEventDispatcher();

  let listOfOptions, focused, targetElement;

  $: listOfOptions = loadOptions(filter.options);

  function loadOptions(options = () => {}){
    return (Array.isArray(options) ? options : options())
      .reduce( (acc, o) => {
        acc.push(o.constructor.name === 'Object' ? o : { value: o, label: o });
        return acc;
      }, []);
  }
  async function focusOnInput(){
    await tick();
    const el = document.getElementById(filter.id);
    if(el) el.focus();
  }

  function onSelect(value) {
    const filterValue = filter.value.indexOf(value) === -1 ? [...filter.value, value] : filter.value.filter(v => v !== value);
    setFilterValue(filter, filterValue);
  }

  function clearFilter(){
    filter.isEmpty ? disableFilter(filter) : setFilterValue(filter,'');
    dispatchEvent('change');
  }
</script>

<div class="Component"
     class:Focused="{focused}"
     class:Empty="{filter.isEmpty}"
     data-icon-before="add_circle"
     bind:this={targetElement}
     on:click={() => focusOnInput()}
>
  <button id="{filter.id}"
          class="LabelButton"
          on:focus="{() => focused = true}"
          on:blur="{() => focused = false}"
  >
    {filter.label}
  </button>

  <span>&nbsp;-&nbsp;</span>

  <div class="input Ellipsis">
    {filter.value.map(fv => listOfOptions.find(o => o.value === fv).label).join(', ')}
  </div>

  <button class="rbButton iconOnlySmall ClearButton"
          data-icon="cancel"
          on:click="{() => clearFilter()}"
  ></button>
</div>

{#if focused}
  <SelectModalMenu {targetElement} isDarkStyle="{true}" placement="bottom-start">
    {#each listOfOptions as item}
      <div class="SelectOption"
           class:Selected={ filter.value.indexOf(item.value) !== -1 }
           on:mousedown|preventDefault
           on:click={() => onSelect(item.value)}>
        <span class="Ellipsis">{item.label}</span>
      </div>
    {/each}
  </SelectModalMenu>
{/if}


<style lang="stylus">.Component {
  height: 24px;
  background: #313c42;
  border: 1px solid #cfd8dc;
  color: #00a99d;
  font-size: 13px;
  display: flex;
  align-items: center;
  border-radius: 13px;
  padding-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.Component:before {
  width: 0;
  font-size: 18px;
  overflow: hidden;
}
.Component .input {
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: transparent;
  border: 0;
  outline: 0;
  color: #fff;
  font-size: inherit;
  max-width: 160px;
}
.Component .input::placeholder {
  color: #78909c;
}
.Component.Empty {
  color: #cfd8dc;
  padding-left: 0;
}
.Component.Empty:before {
  width: 26px;
}
.Component.Empty .input {
  max-width: 0;
}
.Component.Focused {
  color: #00a99d;
  padding-left: 0;
  border: 1px solid #313c42;
}
.Component.Focused:before {
  width: 26px;
}
.Component.Focused .input {
  max-width: 160px;
}
.LabelButton {
  border: 0;
  background: transparent;
  color: inherit;
  padding: 0;
  outline: 0;
  cursor: pointer;
}
.ClearButton {
  height: 24px;
  width: 26px;
  border: 0;
  background: transparent;
  opacity: 0.2;
  color: #cfd8dc;
}
.ClearButton:before {
  width: 30px;
  font-size: 18px;
}
.ClearButton:hover,
.ClearButton:focus {
  opacity: 1;
}
.SelectOption {
  display: flex;
  line-height: 36px;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 13px;
  width: 285px;
  padding: 0 15px 0 0;
}
.SelectOption:before {
  margin-right: 10px;
}
.SelectOption:hover {
  background: #313c42;
}
.SelectOption:before {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
/* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
/* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
/* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
/* Support for IE. */
  font-feature-settings: 'liga';
  content: 'check_box_outline_blank';
  font-size: 18px;
  margin: 0 10px;
}
.SelectOption.Selected {
  color: #fff;
  background: #313c42;
}
.SelectOption.Selected:before {
  content: 'check_box';
  color: #00a99d;
}
</style>
