<script>
    import {tick, onMount} from 'svelte';
    import Navigation from 'root/components/ProfileNavigation.svelte';
    import NavigationItem from 'components/organisms/Navigation/NavigationItem.svelte';
    import Route from 'root/routes/EntityProfile';
    import {navigationItems} from './modules';

    export let section;
    export let entityId, type, subType;

    $: links = [...navigationItems(subType)];
    $: console.log(links);

    function href({id}) {
        return id && Route.href({entityId, section: id, type})
    }

    function opened({subItems}) {
        return subItems && subItems.find(isActive);
    }

    function isActive({id}) {
        return section === id;
    }

    async function openSubItem({id}) {
        await tick();
        const el = document.getElementById(id);
        if(el) {
            el.scrollIntoView();
        }
    }
</script>

<div class="rbCard navigation">
    <Navigation
        let:minimized
        let:link
        links={[...links]}
    >
        <NavigationItem {...link} {minimized} open={opened(link) || isActive(link)}  active={opened(link) || isActive(link)} href={href(link)}>
            {link.text}
            <span slot='item' let:item>
                <span on:click={() => openSubItem(item)} style="display: block;" id={'nav-'+item.id}>
                    <NavigationItem href={href(link)} {...item} {minimized} isChild={true}>
                        {item.text}
                    </NavigationItem>
                </span>
            </span>
        </NavigationItem>
    </Navigation>
</div>


<style type="text/stylus">.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.navigation :global(.navigation) {
  height: calc(100vh - 45px);
}
.navigation :global(.navigation .links) {
  height: calc(100vh - 185px);
}
.navigation span :global(.item a) {
  padding: 0 15px 0 0;
}
.navigation span :global(.item.open) {
  background: inherit;
}
</style>
