
<script>
    import {getContext} from 'svelte';

    import InputText from 'components/molecules/inputs/InputText.svelte';
    import InputUrl from 'components/molecules/inputs/InputUrl.svelte';
    import BrandsSearch from './BrandsSearch.formModel.svelte';

    import { convertModelToObect } from 'root/utils';

    export let hide, cancel, brands;

    function save() {
        formModel.touchAll();
        if($formModel.valid) {
            hide(convertModelToObect($formModel.values));
        }
    }

    let formModel = getContext('formModel');
</script>

<div class="content">
    <InputText id="name" required="true" maxLength="100">Property</InputText>

  <BrandsSearch id="chain.id" name="Brand" {brands} required="{true}"/>

  <InputUrl id="website" maxLength="255" required="true">Website</InputUrl>
</div>

<div class="footer">
    <button class="rbButton asModal" on:click="{cancel}">Cancel</button>
    <button class="rbButton asModalMain" data-icon="save" on:click={save}> Save and Submit </button>
</div>

<style lang="stylus">.content {
  margin: 10px 0 30px;
  font-size: 13px;
  line-height: 16px;
  color: #546e7a;
}
.footer {
  text-align: right;
}
.content :global(.rbInput.component .container) {
  border: 1px solid #e6e7e8;
  background: transparent;
  color: #546e7a;
}
.content :global(.rbInput.component .container .input) {
  color: #546e7a;
}
.content :global(.rbInput.component .container .label) {
  background: #fff;
}
</style>
