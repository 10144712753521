<script>
  import { getContext } from "svelte";

  import InputPassword from 'components/molecules/inputs/InputPassword.svelte';

  export let hide, cancel;

  function save() {
    formModel.touchAll();
    if ($formModel.valid) {
      hide($formModel.values.password);
    }
  }

  let formModel = getContext("formModel");
</script>

<style type="text/stylus">.content {
  margin: 10px 0 30px;
  font-size: 13px;
  line-height: 16px;
  color: #546e7a;
}
.footer {
  text-align: right;
}
.content :global(.rbInput.component .container) {
  border: 1px solid #e6e7e8;
  background: transparent;
  color: #546e7a;
}
.content :global(.rbInput.component .container .input) {
  color: #546e7a;
}
.content :global(.rbInput.component .container .label) {
  background: #fff;
}
</style>

<div class="content">
  <InputPassword id="password" name="Password" maxLength="256">Password</InputPassword>
</div>

<div class="footer">
  <button class="rbButton asModal" on:click={cancel}>Cancel</button>
  <button class="rbButton asModalMain" data-icon="save" on:click={save}>
    Update Password
  </button>
</div>
