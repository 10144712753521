<script>
    import FormModel from 'components/molecules/inputs/FormModel.svelte';
    import NotificationTemplate from 'components/modals/NotificationTemplate.basic.svelte';

    import EditForm from './ChooseReplacementContact.form.svelte';
    import {getModelFromObect} from 'root/utils';

    export let hide, cancel, reps;

    let values = getModelFromObect({});
</script>

<NotificationTemplate>
    <FormModel bind:values>
        <div class="container">
            <div class="header">
                <div class="title">
                    Change hotel master chain
                </div>
                <div class="subTitle">
                    You are trying to change the hotel brand and chain.
                </div>
            </div>

            <div class="empty">
                In order to do this, you will need to select a user from the new chain to be set as primary contact for all of this hotel's bids that were sent to the old chain.
            </div>
            <EditForm {hide} {cancel} {reps}/>
        </div>
    </FormModel>
</NotificationTemplate>

<style type="text/stylus">.container {
  padding: 25px 15px 20px;
}
.header {
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 14px;
  font-weight: bold;
}
.subTitle {
  font-size: 10px;
  opacity: 0.5;
}
.empty {
  margin: 25px 0 30px;
  font-size: 13px;
  line-height: 16px;
  color: #546e7a;
}
</style>
