<script>
  import {getContext} from 'svelte';

  import InputText from 'components/molecules/inputs/InputText.svelte';

  import { convertModelToObect } from 'root/utils';
  import {updateGdsCodes} from '../../../../services/hotel.service';
  import HttpNotification from 'root/api/HttpNotifications';

  export let hide, cancel, codes, names, id;

  let sabreCodeInUseError = false;

  async function save() {
    formModel.touchAll();
    if($formModel.valid) {
      try {
        sabreCodeInUseError = false;
        const formData = convertModelToObect($formModel.values);
        if(containsChanges(formData, codes)) {
          await HttpNotification.handle(
            updateGdsCodes(id, formData),
            { onError: {
                '409': () => {
                  sabreCodeInUseError = true;
                  return Promise.reject();
              }
            }}
          );
        }

        hide();
      } catch(ignore){
        //ignore
      }
    }
  }

  function containsChanges(toSave, initial) {
    // noinspection EqualityComparisonWithCoercionJS
    return Object.keys(initial).some(iKey => initial[iKey] != toSave[iKey]);
  }

  let formModel = getContext('formModel');
</script>

<div class="content">
  {#if sabreCodeInUseError}
    <div class="err">
      <span>Sabre Code</span> <b>{$formModel.values.SABRE_PROPCODE}</b> is already in use!
    </div>
  {/if}

  {#each names as name}
    <div class="flex">
      {#if name.chain}
        <InputText id={name.chain} name={name.chainName} maxLength="5">{name.chainName}</InputText>
      {/if}

      {#if name.prop}
        {#if name.propName === 'SABRE Prop Code'}
          <InputText id={name.prop} name={name.propName}
                    maxLength="10"
                    on="{ [ ['input', ev => ev.target.value =  (ev.target.value || '').replaceAll(/\D/g, '').replaceAll(/^0/g, '') ] ] }"
                    pattern="{/^[1-9]\d{0,9}$/}">
            {name.propName}
          </InputText>
        {:else}
          <InputText id={name.prop} name={name.propName} maxLength="10">{name.propName}</InputText>
        {/if}
      {/if}
    </div>
  {/each}
</div>

<div class="footer">
  <button class="rbButton asModal" on:click="{cancel}">Cancel</button>
  <button class="rbButton asModalMain" data-icon="save" on:click={save}> Save </button>
</div>

<style lang="stylus">.content {
  margin: 10px 0 30px;
  font-size: 13px;
  line-height: 16px;
  color: #546e7a;
}
.footer {
  text-align: right;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.flex > :global(div:last-child:not(:first-child)) {
  margin-left: 10px;
}
.err {
  color: #ff4500;
  font-size: 14px;
  padding: 10px;
}
</style>
