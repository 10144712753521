<script>
    import noop from 'lodash/noop';

    export let value = '';
    export let required = false;
    export let focused = false;
    export let menuShown = false;
    export let error = false;
    export let iconStart = false;
    export let onShowMenu = noop;
</script>

<div class="container"
     class:leftPadding={!iconStart}
     class:notEmpty="{value}"
     class:focused
     class:error
     on:mousedown="{onShowMenu}">

    {#if (iconStart && typeof iconStart === 'string') }
        <i class="material-icons icon">{iconStart}</i>

    {:else if (iconStart && typeof iconStart === 'object' && iconStart.onClick)}
        <i class="material-icons icon clickable {iconStart.class || ''}"
           on:click|stopPropagation="{ e => iconStart.onClick && iconStart.onClick(e) }">{iconStart.name}</i>

    {:else if (iconStart && typeof iconStart === 'object')}
        <i class="material-icons icon {iconStart.class || ''}">{iconStart.name}</i>
    {/if}

    <div class="inputContainer">
        <div class="label clickable"
             class:iconOffset={iconStart}
             class:required
             class:notEmpty="{value}"
             class:error>
            <slot />{#if required}*{/if}
        </div>

        <div class="input">{value}</div>
    </div>

    <i class="material-icons icon" >{ menuShown ? 'expand_less' : 'expand_more' }</i>
</div>

<style type="text/stylus">.component {
  width: 100%;
}
.container {
  display: flex;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  margin-top: 10px;
  padding-bottom: 1px;
  height: 34px;
  border: 1px solid #263238;
  border-radius: 18px;
  background: #313c42;
  color: #727d84;
  transition: all 0.1s linear;
}
.container.leftPadding {
  padding-left: 15px;
}
.container.rightPadding {
  padding-right: 15px;
}
.container.notEmpty:before {
  color: #00a99d;
}
.container.focused {
  color: #00a99d;
  border-color: #00a99d;
}
.container.error {
  color: #f15a24;
  border-color: #f15a24;
}
.icon {
  text-align: center;
  flex: 0 0 40px;
  transition: all 0.1s linear;
  font-size: 20px;
}
.inputContainer {
  flex: 0 1 100%;
}
.label {
  position: absolute;
  top: 6px;
  left: 15px;
  background: transparent;
  font-size: 14px;
  cursor: text;
  transition: all 0.1s linear;
}
.label.required :after {
  content: '*';
}
.label.iconOffset {
  left: 40px;
}
.label.focused,
.label.notEmpty {
  top: -8px;
  left: 10px;
  padding: 0 5px;
  font-size: 11px;
  line-height: 1.2;
  background: #313c42;
}
.input {
  width: 100%;
  border: 0;
  outline: 0;
  background: transparent;
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  transition-delay: 86400s;
}
.clickable {
  cursor: pointer;
}
</style>
