import adminServer from './AdminServer';
import {checkAndExecute} from './helpers';

function setImage(id, type, image) {
    const formData = new FormData();

    formData.append('file', image);

    return checkAndExecute(
        () => id && image && formData && type,
        () => adminServer.post(`entity/${id}/${type}/image`, formData)
    );
}

function setLogo(id, type, image) {
    const formData = new FormData();

    formData.append('file', image);

    return checkAndExecute(
        () => id && image && formData && type,
        () => adminServer.post(`entity/${id}/${type}/logo`, formData)
    );
}

function createUser(id, type, request) {
    return checkAndExecute(
        () => id && type && request,
        () => adminServer.post(`entity/${id}/${type}/create-user`, request)
    );
}

function editUser(id, request) {
    return checkAndExecute(
        () => id && request,
        () => adminServer.put(`entity/${id}/edit-user`, request)
    );
}

function resendEmailInvite(id, ids) {
    return checkAndExecute(
        () => id && ids,
        () => adminServer.put(`entity/${id}/send-invite`, {ids})
    );
}

function create(logo, request) {
    const formData = new FormData();

    formData.append('logo', logo);
    formData.append('entity', new Blob([JSON.stringify(request)], { type: 'application/json' }));

    return adminServer.sendFile(`/entities/create`, formData);
}

function validateEntities(type, entityIds) {
    return checkAndExecute(
        () => type && entityIds,
        () => adminServer.put(`entities/${type}/validate`, {entityIds})
    );
}

function loadEntity(type, id) {
    return checkAndExecute(
        () => type && id,
        () => adminServer.get(`entity/${id}/${type}/get`)
    );
}

function updateBasicInfo(type, id, data) {
    return checkAndExecute(
        () => type && id && data,
        () => adminServer.put(`entity/${id}/${type}/update`, data)
    )
}

function makeObsolete(type, ids) {
    return checkAndExecute(
        () => type && ids,
        () => adminServer.put(`entities/${type}/mark-obsolete`, {ids})
    )
}


function loadUsers(id) {
    return checkAndExecute(
        () => id,
        () => adminServer.get(`entity/${id}/users`)
    );
}

function changeHotelGroup(hotels, hotelManagementCompany) {
    return checkAndExecute(
        () => hotels.length > 0 && hotelManagementCompany,
        () => adminServer.post(`hotels/change-hmc`, {hotelManagementCompany, hotels})
    );
}

function grantAdminStatusToUsers(entityId, accountId, userAccounts = []){
  return updateAdminStatusToUsers(entityId, accountId, userAccounts, 'grant');
}

function revokeAdminStatusToUsers(entityId, accountId, userAccounts = []){
  return updateAdminStatusToUsers(entityId, accountId, userAccounts, 'revoke');
}

function updateAdminStatusToUsers(entityId, accountId, userAccounts = [], action){
  return checkAndExecute(
    () => userAccounts.length && entityId && accountId,
    () => adminServer.put(`entity/${entityId}/users/${action}-admin`, {userAccounts, accountId})
  );
}


export default {
  setImage,
  createUser,
  resendEmailInvite,
  editUser,
  setLogo,
  create,
  validateEntities,
  loadEntity,
  updateBasicInfo,
  loadUsers,
  makeObsolete,
  changeHotelGroup,
  grantAdminStatusToUsers,
  revokeAdminStatusToUsers,
}
