<script>
  import Header from 'root/components/Header.svelte';
  import Navigation from 'root/components/Navigation.svelte';
  import FiltersBar from 'root/pages/hotels/advanced-search/filters/SearchFilters.component.svelte';
  import {FREE_TEXT_FILTER_TYPE} from 'root/pages/hotels/advanced-search/filters/filter-types';
  import ResultsTable from 'root/pages/hotels/advanced-search/ResultsTable.component.svelte';
  import AirportRow from './AirportRow.svelte';
  import AirportApi from './airport.api';
  import modalsService from "src/services/modals";
  import noop from "lodash/noop";
  import AirportDetailsModal from "src/pages/airports/AirportDetailsModal.svelte";

  const FILTERS = [
    {
      label: `IATA code`,
      type: FREE_TEXT_FILTER_TYPE,
      id: 'iata',
      value: '',
    },
    {
      label: `Name`,
      type: FREE_TEXT_FILTER_TYPE,
      id: 'name',
      value: '',
    },
    {
      label: `City`,
      type: FREE_TEXT_FILTER_TYPE,
      id: 'city',
      value: '',
    },
  ];

  let filters = FILTERS, filterTypes = FILTERS, resultsPromise;

  function search(){
    const query = filters.reduce((acc, f) => {
      acc[f.id] = f.value;
      return acc;
    }, {})

    resultsPromise = AirportApi.search(query.iata, query.name, query.city)
  }

  function createAirport(){
    modalsService.show(AirportDetailsModal)
      .then(search)
      .catch(noop)
  }

  function showDetails(airport) {
    modalsService.show(AirportDetailsModal, {airport})
      .then(search)
      .catch(noop)
  }

</script>

<div class="rbPage rbDark directory-reports">
  <Header></Header>
  <div class="pageContainer">
    <div class="rbCard navigation"><Navigation></Navigation></div>

    <div class="content">
      <div class="heading">
        <div class="column page-label">
          <i class="material-icons page-icon">local_airport</i>
          <div class="labels">
            <h1 class="label-title">Airports</h1>
          </div>
        </div>
        <div>
          <button class="rbButton asDarkMain" on:click={createAirport}>
            Add Airport
          </button>
        </div>
      </div>
      <div class="table-header">
        <FiltersBar bind:filters andFilters="{true}" {filterTypes}>
          <button class="rbButton asDarkMain small searchButton" data-icon="search" on:click={search}>Search</button>
        </FiltersBar>
      </div>
      <ResultsTable promise="{resultsPromise}">
        <div slot="item" let:item>
          <AirportRow
            airport={item}
            on:open={() => showDetails(item)}
          />
        </div>
      </ResultsTable>
    </div>
  </div>
</div>

<style lang="stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.page-label {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
i.material-icons.page-icon {
  color: #00b8ab;
  height: 40px;
  width: 40px;
  background: #273238;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.labels {
  margin-left: 10px;
}
h1.label-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
.table-header {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #00a995 0%, #006eb9 50%, #00aa4f 100%) 0 1 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
