<script>
  import Header from 'root/components/Header.svelte';
  import Navigation from 'root/components/Navigation.svelte';
  import {listLatestUpdateCacheRequests, sendUpdateCacheRequestForYear} from 'src/pages/per-diem-cache/api';
  import {getDateFromObjectId} from 'src/utils/object-id';
  import {DateTimeFormatter} from 'src/utils/internationalization.service';
  import HttpErrorHandler from 'src/api/HttpErrorHandler';
  import modals from "src/services/modals";
  import PerDiemNewCacheRequestDialog from "src/pages/per-diem-cache/PerDiemNewCacheRequestDialog.svelte";
  import noop from "lodash/noop";

  const formatDate = DateTimeFormatter().format;
  let loading = true, jobs = [];

  initialize();

  async function initialize(){
    loading = true;

    try {
      const httpResponse = await listLatestUpdateCacheRequests();
      jobs = httpResponse;
      jobs.sort((a,b) => b.year - a.year)
      loading = false;
    } catch (e){
      console.error(e);
      HttpErrorHandler.handle(e);
    }
  }

  function sendRequest() {
    modals.show(PerDiemNewCacheRequestDialog)
      .then(initialize)
      .catch(noop)
  }

  function getDateTime(oid){
    return formatDate(getDateFromObjectId(oid));
  }

</script>

<div class="rbPage rbDark directory-reports">
  <Header></Header>
  <div class="pageContainer">
    <div class="rbCard navigation"><Navigation></Navigation></div>

    <div class="content">
      <div class="heading">
        <div class="column page-label">
          <i class="material-icons page-icon">price_change</i>
          <div class="labels">
            <h1 class="label-title">Per Diem Cache</h1>
          </div>
        </div>
        <div>
          <button class="rbButton asDarkMain"
                  on:click={sendRequest}
          >
            New Request
          </button>

        </div>
      </div>
      <div class="table-header"></div>
      <div>
        {#each jobs as job}
          <div class="JobCard">
            <div>Year: {job.year}</div>
            <div>Status: {job.status.replace('_', ' ')}</div>
            <div>Requested by {job.initiator} on {getDateTime(job.id)}</div>
          </div>
        {/each}
      </div>
    </div>
  </div>
</div>

<style lang="stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.page-label {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
i.material-icons.page-icon {
  color: #00b8ab;
  height: 40px;
  width: 40px;
  background: #273238;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.labels {
  margin-left: 10px;
}
h1.label-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
.table-header {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #00a995 0%, #006eb9 50%, #00aa4f 100%) 0 1 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.JobCard {
  max-width: 600px;
  margin: 15px;
  padding: 10px;
  background: #273238;
  border-radius: 6px;
  border: 1px solid #313c42;
}
</style>
