import modals from 'root/services/modals';
import NotificationModal from 'components/modals/Notification.basic.svelte';
import ErrorsModal from 'src/components/ErrorsModal.svelte';

import CreateAndEditRepDialog from './dialogs/create-edit/CreateAndEditRep.dialog.svelte';
import SendInvitationDialog from './dialogs/send-invitation/SendInvitation.dialog.svelte';
import CreateUserWithPasswordDialog from './dialogs/create-edit/CreateUserWithPassword.dialog.svelte'

import { createUser, activateRepresentatives } from '../../services/hotel.service';
import entityProfile from 'root/api/entityProfile';
import api from '../../services/endpoints';
import HttpNotification from 'root/api/HttpNotifications';
import deleteOrDeactivateRepresentatives from './deleteOrDeactivateRepresentatives.service';

export function createRep(hotel) {
  return modals.show(CreateAndEditRepDialog, {hotel})
    .then(user => createUser(hotel.id, hotel.type, user))
    .then(({data}) => {
      modals.show(NotificationModal,
        {
          subTitle: 'Success',
          title:'Email Invitation Sent',
          content: `The user was created successfully and an invitation email was sent to them to register.`,
          rejectLabel: 'Ok'
        });
      return data;
    });
}

export function createUserWithPassword(entity){
  return modals.show(CreateUserWithPasswordDialog, {entity})
    .then(response => {
      modals.show(NotificationModal,
        response.success
          ? {
            subtitle: 'Success',
            title:'User created',
            content: `The user was created successfully.`,
            rejectLabel: 'Ok'
          }
          : {
            type: 'warning',
            subtitle: 'Failure',
            title:'User creation FAILED!',
            content: `
                <div>The user could not be created because ${response.user.emailAddress} is already in use by:</div>
                <dl>
                    <dt>Name:</dt><dd><b>${response.user.fullName}</b></dd>
                    <dt>Status:</dt><dd><b>${response.user.statusValue}</b></dd>
                </dl>
            `,
            rejectLabel: 'Ok'
          });
      return response;
    });
}

export function resendInvitation(entityId, ids) {
  return HttpNotification.handle(entityProfile.resendEmailInvite(entityId, ids))
    .then(() => modals.show( NotificationModal,
      {
        subTitle: 'Success',
        title:'Email Invitation Sent',
        content: `The user invitation has been sent successfully.`,
        rejectLabel: 'Ok'
      }
    ));
}

export function sendInvitation(entity, contact) {
  return modals.show(SendInvitationDialog, {entity, contact})
    .then(data => {
        modals.show(NotificationModal,
          {
            subTitle: 'Success',
            title: 'Email Invitation Sent',
            content: `The was invited successfully and an invitation email was sent to them to register.`,
            rejectLabel: 'Ok'
          });
        return data;
      }
    )
}

export function editRep(hotel, user) {
  return modals.show(CreateAndEditRepDialog, {user, hotel, editing: true})
    .then(rep => HttpNotification.handle(entityProfile.editUser(hotel.id, {...rep, isUser: true})));
}

export function deleteReps(hotel, selectedRepresentatives, representatives) {
  return deleteOrDeactivateRepresentatives({
    hotelId: hotel.id,
    selectedRepresentatives: formatForRequest(selectedRepresentatives),
    representatives,
    apiAction: api.deleteRepresentatives,
    actionLabel: 'Delete'
  });
}

export function deactivateReps(hotel, selectedRepresentatives, representatives) {
  return deleteOrDeactivateRepresentatives({
    hotelId: hotel.id,
    selectedRepresentatives: formatForRequest(selectedRepresentatives),
    representatives,
    apiAction: api.deactivateRepresentatives,
    actionLabel: 'Deactivate'
  });
}

export function activateReps({id}, reps) {
  return activateRepresentatives(id, formatForRequest(reps));
}

export function grantAdminStatusToUsers(reps){
  return updateAdminStatusToUsers(reps, entityProfile.grantAdminStatusToUsers);
}

export function revokeAdminStatusToUsers(reps){
  return updateAdminStatusToUsers(reps, entityProfile.revokeAdminStatusToUsers);
}

function updateAdminStatusToUsers(reps, updateFn){
  const rep = reps[0];
  return HttpNotification.handle(
    updateFn(rep.entityId, rep.accountId, reps.map(r => r.id))
  )
    .then(res => {
      const failures = res.data.failures;
      if(failures.length){
        const failedUsers = reps.reduce( (acc, r) => {
          if(failures.indexOf(r.id) === -1) return acc;
          const name = `${r.firstName} ${r.lastName}`;
          return acc ? `${acc}, ${name}` : name;
        }, '');

        modals.show(ErrorsModal, {
          title: 'Failed to update some users!',
          message: `System was not able to update ${failedUsers}.`
        });
      }

      return res;
    });
}

function formatForRequest(reps) {
  return reps.map(rep => ({
    ...rep,
    isInvitation: rep.isInvitedUser,
    userId: rep.userId || rep.id,
  }))
}
