<script>
  import {init as initStore} from './SelectContact.store';
  import {onMount} from 'svelte';
  import SelectContactDialogComponent from './SelectContactDialogComponent.svelte';
  import noop from 'lodash/noop';
  import {setCompetitiveBidContact, createCompetitiveBidContact, listContactsForCompetitiveBidHotel, sendCompetitiveBids} from 'src/api';
  import httpErrorHandler from 'src/api/HttpErrorHandler';
  import SendBidsAction from './actions/SendBidsAction';
  import httpNotification from 'src/api/HttpNotifications';

  export let hide, cancel, bid;

  onMount(() => {
    initStore(bid, listContactsForCompetitiveBidHotel(bid.hotelId))
      .catch(error => httpErrorHandler.handle(error))
      .catch(noop);
  });

  function setContact(bidId, userAccount, shouldSend){
    if(userAccount.isUser){
      hideExecutor(() => setCompetitiveBidContact(bidId, userAccount.id), shouldSend);
    } else {
      saveContact(bidId, userAccount, shouldSend);
    }
  }

  function saveContact(bidId, contact, shouldSend){
    hideExecutor(() => createCompetitiveBidContact(bidId, contact), shouldSend);
  }

  function hideExecutor(updateMethod, shouldSend){
    httpNotification.handle(updateMethod()
      .then(updatedBid => {
        if (!shouldSend) return updatedBid;

        return sendCompetitiveBids([updatedBid._id])
          .then(result => result.updates[0]);
      })
    )
      .then(updatedBid => hide(updatedBid))
      .catch(noop);
  }
</script>

<SelectContactDialogComponent {setContact} {saveContact} {cancel} canSend="{SendBidsAction.isAvailable([bid])}" />