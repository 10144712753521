<script>
  export let selected = false, onClick = () => {};
  const icons = ['check_box_outline_blank', 'check_box'];
</script>

<div class="checkbox"
     class:selected={selected}
     data-icon-before="{icons[selected? 1: 0]}"
     on:click={onClick}
>
  <slot>Label</slot>
</div>


<style lang="stylus">.checkbox {
  font-size: 14px;
  color: #b0bec5;
  cursor: pointer;
}
.checkbox.selected {
  color: #fff;
}
.checkbox.selected:before {
  color: #00a99d;
}
.checkbox:hover {
  color: #fff;
}
</style>
