import adminServer from 'root/api/AdminServer';
import {checkAndExecute} from 'root/api/helpers';

export default {
  listSuspendedAccounts: () => adminServer.get('/accounts/suspensions'),

  suspendAccounts: accountIds => checkAndExecute(
    () => accountIds,
    () => adminServer.post(`/accounts/suspend`, {ids: accountIds})
  ),

  unsuspendAccount: accountId => checkAndExecute(
    () => accountId,
    () => adminServer.axios.delete(`/accounts/${accountId}/suspension`)
  ),
}
