<script>
    export let rating = 0;
    const ratings = [1, 2, 3, 4, 5];
</script>

<div class="rating">
    {#each ratings as r}
        <i class="material-icons">{rating >= r? 'star': 'star_border'}</i>
    {/each}
</div>

<style>
.rating{
    display: inline;
}

.rating i.material-icons{
    font-size: 18px;
}
</style>