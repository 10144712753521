<script>
    import Header from 'root/components/Header.svelte';
    import Navigation from 'root/components/Navigation.svelte';
    import {get} from 'lodash';

    import CheckBox from 'root/pages/hotels/advanced-search/Checkbox.component.svelte';
    import FiltersBar from 'root/pages/hotels/advanced-search/filters/SearchFilters.component.svelte';
    import ResultsTable from 'root/pages/hotels/advanced-search/ResultsTable.component.svelte';
    import Paginator from 'root/pages/hotels/advanced-search/Paginator.component.svelte';
    import Select from 'components/molecules/inputs/Select.svelte';
    import Directory from './HotelDirectory.component.svelte';

    import {filterReports, getFilterTypes, getFilterValues, cleanFilters} from './service';
    import moment from 'moment';

    let andFilters = true;
    let loading = true;
    const types = getFilterTypes()
    let filters = cleanFilters([...types]);
    let promise;
    let currentPage = {};
    let propsWithSizes = [];
    let sortBy;
    let period;

    function updateFilters() {
        filters = cleanFilters(filters)
    }

    function applyFilters() {
        let {sortBy: s, period: p} = getFilterValues(filters);
        sortBy = s;
        period = p;
        search();
    }

    function search(page = 1) {
        loading = true;

        promise = filterReports(filters, page, andFilters).then(pagination => {
            currentPage = pagination;
            loading = false;
            propsWithSizes = getProps(pagination.data);
            return pagination.data;
        });
    }

    function next() {
        search(currentPage.page + 1);
    }

    function prev() {
        search(currentPage.page - 1);
    }

    function refresh() {
        search(currentPage.page);
    }

    applyFilters();

    function getProps(data = []) {
        const year = moment().format('YYYY');

        let props = [
            {
                label: 'Travel Agency',
                get({hotelDirectory}) { return hotelDirectory.account.agency? hotelDirectory.account.agency.name: '' }
            },
            {
                label: 'Paying Customer',
                get({paying}) { return paying? 'Yes': 'No' }
            },
        ];

        if(sortBy === 'bidCounts') {
            const years = [...new Array(period)].map((_, i) => `${year - i}`);
            const month = moment().format('MMMM').toUpperCase();

            props = [
                ...props,
                {
                    label: `Number of Bids in ${years.join(', ')}`,
                    get({bidCounts}) {
                        return Object.keys(bidCounts).reduce((acc, key) => years.includes(key)? acc + bidCounts[key]: acc, 0)
                    }
                },
                {
                    label: `Directory Visits in ${year} ${month}`,
                    key: `directoryOpens.${year}-${month}`,
                }
            ];
        } else {
            const months = [...new Array(period)].map((_, i) => {
                const date = moment().subtract(i, 'months');
                return `${date.format('YYYY')}-${date.format('MMMM').toUpperCase()}`;
            });

            const label = months.length > 1? `Number of directory visits from ${months[months.length - 1]}  to  ${months[0]}`: `Number of directory visits in ${months[0]}`;

            props = [
                ...props,
                {
                    label: `Number of Bids in ${year}`,
                    key: `bidCounts.${year}`
                },
                {
                    label,
                    get({directoryOpens}) {
                        return Object.keys(directoryOpens).reduce((acc, key) => months.includes(key)? acc + directoryOpens[key]: acc, 0)
                    }
                }
            ]
        }

        return data.reduce((acc, report) => acc.map(prop => {
            const value = prop.get? prop.get(report): get(report, prop.key, '');
            const size = Math.max(prop.size || 0, (value? `${value}`.length: 0) + prop.label.length);
            return {...prop, size};
        }), props);
    }
</script>

<div class="rbPage rbDark directory-reports">
    <Header></Header>
    <div class="pageContainer">
        <div class="rbCard navigation"><Navigation></Navigation></div>

        <div class="content">
            <div class="heading">
                <div class="column page-label">
                    <i class="material-icons page-icon">search</i>
                    <div class="labels">
                        <h1 class="label-title">Hotel Directory Reports dashboard</h1>
                        <p class="label-sub-title">search reports by changing the sorting settings</p>
                    </div>
                </div>
                <div class="column buttons">
                    <Paginator results={currentPage} on:next={next} on:prev={prev} />
                </div>
            </div>
            <div class="table-header">
                <CheckBox/>
                <FiltersBar bind:filters {andFilters} filterTypes={types} let:hasValues on:updated={updateFilters}>
                    <button class="rbButton asDarkMain small searchButton" data-icon="search" on:click={applyFilters} disabled={loading}>Search</button>
                </FiltersBar>
            </div>
            <ResultsTable {promise}>
                <div slot="item" let:item>
                    <Directory directory={item}  props={propsWithSizes} on:refresh={refresh}/>
                </div>
            </ResultsTable>
        </div>
    </div>
</div>

<style type="text/stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.page-label {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
i.material-icons.page-icon {
  color: #00b8ab;
  height: 40px;
  width: 40px;
  background: #273238;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.labels {
  margin-left: 10px;
}
h1.label-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
p.label-sub-title {
  margin: 0;
  font-size: 10px;
}
.table-header {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #00a995 0%, #006eb9 50%, #00aa4f 100%) 0 1 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.small {
  height: 30px;
  font-size: 12px;
}
.directory-reports :global(.check-box ) {
  width: 10px;
}
.directory-reports :global(.check-box .material-icons) {
  display: none;
}
</style>
