<script>
  import GeneralFaultTemplatePage from './GeneralFaultTemplatePage.svelte';
  import { SUPPORT_NEW_TICKET_LINK } from '../config/links.js'
  export let goToHome = () => { console.log('clicked on homepage') };
  let supportLink = SUPPORT_NEW_TICKET_LINK;
  let data = {
    title:"Forbidden",
    subtitle: "403 ERROR",
  };
</script>

<GeneralFaultTemplatePage { ...data} on:click="{goToHome}">
  You don’t have permission to access this page.
  If you’re unsure why, you can contact our <a href={supportLink}>support team</a>.
</GeneralFaultTemplatePage>
