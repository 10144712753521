<script>
  import Input from 'components/molecules/inputs/Input.svelte';
  import Menu from 'components/organisms/Menu/Menu.svelte';

  import {default as store, service} from './accounts.store';

  let searchQuery = $store.query, loading;

  $: service.search(searchQuery);

  /**
   * Sets the account type and performs necessary actions.
   *
   * @param {string} type - The account type to set.
   */
  function setAccountType(type){
    service.setAccountType(type);
    searchQuery = '';
  }

  /**
   * Loads more data by invoking the necessary service function.
   */
  async function loadMore(){
    loading = true;
    await service.loadMore();
    loading = false;
  }

</script>

<div class="Selector">
  <div class="Search">
    <div class="Menu">
      <Menu on:change={ev => setAccountType(ev.detail)}
            options="{$store.accountTypeOptions}"
      >        <span data-icon-after="expand_more">{$store.accountType.label}</span>
      </Menu>
    </div>

    <Input name="search" bind:value="{searchQuery}">Search by Name</Input>
  </div>

  {#if $store.accounts.length}
    <div class="List">
      <div class="ListHeading">Select account to manage its Users</div>
      {#each $store.accounts as a}
        <div class="Account" on:click={() => service.selectAccount(a)}>
          {#if a.accountSuspended}<div class="AccountSuspended">Account Suspended</div>{/if}
          <div class="UserAccounts">User Accounts: <span>{a.userAccountsCount}</span></div>
          <div class="Name">
            {a.name}
            {#if a.type}<span>({a.type})</span>{/if}
          </div>
          {#if a.address}<div>{a.address}</div>{/if}
          {#if a.brandName}<div class="Detail">Brand: <span>{a.brandName} {a.chainName}</span></div>{/if}
          {#if a.agencyName}<div class="Detail">Agency: <span>{a.agencyName}</span></div>{/if}
        </div>
      {/each}
      {#if $store.hasMore && !loading}
        <div class="Account LoadMore" on:click={loadMore}>LOAD MORE</div>
      {/if}
    </div>
  {/if}
</div>

<style lang="stylus">.Selector {
  flex: 1 1 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.Search {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
.Menu {
  margin: 10px 10px 0 0;
}
.List {
  flex: 1 1 100%;
  overflow: auto;
  position: relative;
}
.ListHeading {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #313c42;
  color: #fff;
  font-size: 14px;
  padding: 5px 10px;
  border-bottom: 1px solid #00a99d;
}
.Account {
  padding: 10px;
  background: #263238;
  border: 1px solid #78909c;
  border-radius: 6px;
  font-size: 13px;
  color: #b0bec5;
  width: 600px;
  margin: 10px auto;
  cursor: pointer;
}
.Account:hover {
  background: #16272b;
  border: 1px solid #00a99d;
}
.Account.LoadMore {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}
.Name {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  text-decoration: underline;
}
.Name span {
  font-size: 13px;
  color: #b0bec5;
}
.Detail span {
  color: #fff;
}
.UserAccounts span {
  color: #fff;
  font-size: 15px;
}
.AccountSuspended {
  color: #e65622;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
