import adminServer from 'root/api/AdminServer';
import {checkAndExecute} from 'root/api/helpers';

function getHotel(id) {
  return checkAndExecute(
    () => !!id,
    () => adminServer.get(`hotels/${id}`)
  );
}

function getContacts(id) {
  return checkAndExecute(
    () => !!id,
    () => adminServer.get(`hotels/${id}/contacts`)
  )
}

function updateBasicInfo(id, request){
  return checkAndExecute(
    () => id && request,
    () => adminServer.put(`hotels/${id}/basic-info`, request)
  );
}

function updateAmenities(id, request){
  return checkAndExecute(
    () => id && request,
    () => adminServer.put(`hotels/${id}/amenities`, request)
  );
}

function deleteRepresentatives(id, request) {
  return checkAndExecute(
    () => id && request,
    () => adminServer.put(`hotels/${id}/delete-reps`, request)
  );
}

function deactivateRepresentatives(id, request) {
  return checkAndExecute(
    () => id && request,
    () => adminServer.put(`hotels/${id}/deactivate-reps`, request)
  );
}

function activateRepresentatives(id, request) {
  return checkAndExecute(
    () => id && request,
    () => adminServer.put(`hotels/${id}/activate-reps`, request)
  );
}

function updateAnswers(id, request) {
  return checkAndExecute(
    () => id && request,
    () => adminServer.put(`hotels/${id}/answers`, request)
  );
}

function rebrandHotel(id, request) {
  return checkAndExecute(
    () => id && request,
    () => adminServer.put(`hotels/${id}/update-brand`, request),
  );
}

function getAnswers(id) {
  return checkAndExecute(
    () => id,
    () => adminServer.get(`hotels/${id}/saved-responses`),
  );
}

function updateGdsCodes(id, codes) {
  return checkAndExecute(
    () => id && codes,
    () => adminServer.put(`hotels/${id}/gds-codes`, codes)
  )
}


export default {
  getHotel,
  updateBasicInfo,
  updateAmenities,
  getContacts,
  deleteRepresentatives,
  deactivateRepresentatives,
  activateRepresentatives,
  updateAnswers,
  rebrandHotel,
  getAnswers,
  updateGdsCodes,
}
