export default [
  {'id': 'airConditioning', 'label': 'Air Conditioning', 'type': 'room', 'important': 0},
  {'id': 'airportTransportation', 'label': 'Airport transportation', 'type': 'property', 'important': 1},
  {'id': 'americanExpress', 'label': 'American Express', 'type': 'payment', 'important': 0},
  {'id': 'areaShuttle', 'label': 'Area shuttle', 'type': 'property', 'important': 0},
  {'id': 'breakfast', 'label': 'Breakfast', 'type': 'property', 'important': 1},
  {'id': 'businessCenter', 'label': 'Business Center', 'type': 'property', 'important': 1},
  {'id': 'coffeeTeaMaker', 'label': 'Coffee/Tea maker', 'type': 'room', 'important': 0},
  {'id': 'cookwareDishwareUtensils', 'label': 'Cookware, dishware, and utensils', 'type': 'room', 'important': 0},
  {'id': 'dinersClubCarteBlanche', 'label': "Diner's Club/Carte Blanche", 'type': 'payment', 'important': 0},
  {'id': 'discover', 'label': 'Discover', 'type': 'payment', 'important': 0},
  {'id': 'dryCleaningLaundryService', 'label': 'Dry Cleaning/Laundry Service', 'type': 'property', 'important': 0},
  {'id': 'fitness', 'label': 'Fitness', 'type': 'property', 'important': 1},
  {'id': 'freeInternationalCalls', 'label': 'Free international calls', 'type': 'room', 'important': 0},
  {'id': 'freeLocalCalls', 'label': 'Free local calls', 'type': 'room', 'important': 0},
  {'id': 'freeLongDistanceCalls', 'label': 'Free long-distance calls', 'type': 'room', 'important': 0},
  {'id': 'groceryConvenianceStore', 'label': 'Grocery/Conveniance store', 'type': 'property', 'important': 0},
  {'id': 'highSpeedWiredInternet', 'label': 'High Speed Wired Internet', 'type': 'room', 'important': 0},
  {'id': 'inRoomSafe', 'label': 'In-room safe', 'type': 'room', 'important': 0},
  {'id': 'inRoomSafeLaptop', 'label': 'In-room safe (laptop compatible)', 'type': 'room', 'important': 0},
  {'id': 'jcbInternational', 'label': 'JCB International', 'type': 'payment', 'important': 0},
  {'id': 'luggageStorage', 'label': 'Luggage storage', 'type': 'property', 'important': 0},
  {'id': 'masterCard', 'label': 'MasterCard', 'type': 'payment', 'important': 0},
  {'id': 'microwave', 'label': 'Microwave', 'type': 'room', 'important': 0},
  {'id': 'parking', 'label': 'Parking', 'type': 'property', 'important': 1},
  {'id': 'privateBathroom', 'label': 'Private bathroom', 'type': 'room', 'important': 0},
  {'id': 'refrigerator', 'label': 'Refrigerator', 'type': 'room', 'important': 0},
  {'id': 'restaurant', 'label': 'Restaurant', 'type': 'property', 'important': 0},
  {'id': 'roomService', 'label': 'Room Service', 'type': 'room', 'important': 0},
  {'id': 'visa', 'label': 'Visa', 'type': 'payment', 'important': 0},
  {'id': 'wheelchairAccessible', 'label': 'Wheelchair accessible', 'type': 'property', 'important': 0},
  {'id': 'wifi', 'label': 'WiFi', 'type': 'room', 'important': 1}
]
