<script>
    import InputText from './InputText.svelte';
    import { getContext} from 'svelte';
    import ensureHttpOnUrl from 'src/utils/ensure-http-for-url';

    export let name = "";
    export let id = name;
    export let disabled;
    export let readonly;
    export let on = [];
    export let iconStart;
    export let iconEnd;

    // setup validations
    export let required;
    export let maxLength;
    export let pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    export let format;

    const formModel = getContext('formModel');

    on.push(['blur', ev => {
      const
        targetValue = ev.target.value,
        url = ensureHttpOnUrl(targetValue);

      if(targetValue !== url){
        ev.target.value = url;
        if(formModel) formModel.setValue(id, url);
      }
    }]);

</script>

<InputText
    {name}
    {id}
    {disabled}
    {readonly}
    {on}
    {iconStart}
    {iconEnd}
    {required}
    {maxLength}
    {pattern}
    {format}
><slot></slot></InputText>

