export function setupTargetListerners(menu, pageActive) {
    const active = menu.find(pageActive);
    if(active && active.subItems && active.subItems.length > 0) {
        listenForActiveChildChangeOnScroll(active.id, active);
    }
}

export function cancelScrollListeners() {

}

async function listenForActiveChildChangeOnScroll(id, {subItems}) {
    const root = await getContainer(id);

    if(root) {
        const observer = new IntersectionObserver(callback, {
            root,
            threshold: 0.6,
        });

        subItems.forEach(async item => {
            const el = await getContainer(item.id);
            observer.observe(el);
        });
    }

    function callback(entries) {
        let maxRatioEntry;
        entries.forEach((entry) => {
            const el = getNav(entry.target.id);
            if(el) el.classList.remove('active');
            if(entry.isIntersecting && (!maxRatioEntry || maxRatioEntry.intersectionRatio < entry.intersectionRatio)) {
                maxRatioEntry = entry;
            }
        });

        if(maxRatioEntry) {
            const active = getNav(maxRatioEntry.target.id);
            if(active) active.classList.add('active');
        }
        
        function getNav(i) {
            const span = document.getElementById(`nav-${i}`);
            return span? span.getElementsByClassName('item')[0]: null;
        }
    }
}

function getContainer(id) {
    let c = document.getElementById(id);
    if(c) {
        return c;
    }else{
        return new Promise(resolve => {
            setTimeout(async () => {
                c = await getContainer(id);
                resolve(c);
            }, 200);
        });
    }
}