<script>
    export let title = 'Admin Dashboard';
    export let subTitle = 'subtitle';
    let minimized = false;
    export let links = [], showHeaderSlot = false;

    // // this would affect all navigations
    // const DOCK_SETTING_STORAGE = 'DOCK_SETTING';
    //
    // let autoDock = localStorage.getItem(DOCK_SETTING_STORAGE);

    // function toggleAutoDock() {
    //     autoDock = !autoDock;
    //     localStorage.setItem(DOCK_SETTING_STORAGE, autoDock);
    // }
    //


</script>

<!--{#if autoDock && navigationOpen}-->
<!--    <div class="bg" on:click={toggleNavigationOpen} transition:fade></div>-->
<!--{/if}-->

<div class="navigation" class:min={minimized}>

    <div class="header" class:min={minimized}>
        <div class="headerTextContainer" class:showHeaderSlot>
            {#if !showHeaderSlot}
                <div class="rbTitle">{title}</div>
                <div class="rbSubTitle">{subTitle}</div>
            {:else}
                <slot name="header"></slot>
            {/if}
        </div>

        <button class="rbButton asDark iconOnly toggler" class:min={minimized}
            data-icon="play_for_work"
            on:click="{() => minimized = !minimized}"></button>
    </div>

    <ul class="links">
        {#each links as link}
            <li>
                <slot {minimized} {link}></slot>
            </li>
        {/each}
    </ul>

<!--    <div class="fill"></div>-->
<!--    <div class="auto-dock-settings" on:click={toggleAutoDock}>-->
<!--        <i class="material-icons">{autoDock? 'toggle_on':'toggle_off'}</i>-->
<!--        {#if navigationOpen}-->
<!--            <div  in:scale>-->
<!--                Auto-dock: {autoDock? 'ON':'OFF'}-->
<!--            </div>-->
<!--        {/if}-->
<!--    </div>-->
</div>

<style type="text/stylus">.navigation {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 300px;
  background: #313c42;
  border-radius: 0 10px 0 0;
  box-shadow: 0 0 3px 5px rgba(0,0,0,0.1);
  overflow: hidden;
  transition: all 0.3s;
}
.navigation.min {
  max-width: 48px;
}
.header {
  box-sizing: border-box;
  flex: 1 0 90px;
  min-width: 275px;
  width: 100%;
  margin: 34px 0 17px 0;
  background: #313c42;
  display: flex;
  align-items: center;
  align-self: flex-end;
  overflow: hidden;
}
.header.min {
  margin-right: 3px;
}
.headerTextContainer {
  flex: 1 1 100%;
}
.headerTextContainer:not(.showHeaderSlot) {
  margin-right: 10px;
  margin-left: 15px;
}
.rbButton {
  flex: 0 0 40px;
}
.rbButton.min {
  background: transparent;
  border-color: transparent;
}
.rbTitle {
  color: #fff;
  font-weight: lighter;
  font-size: 25px;
  margin: 0;
}
.rbSubTitle {
  margin: 0;
}
.links {
  flex: 1 1 100%;
  padding: 0;
  list-style-type: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.toggler:not(.min) {
  margin-right: 15px;
}
.toggler::before {
  width: 40px;
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}
.toggler.min::before {
  transform: rotate(-90deg);
}
/*.auto-dock-settings{
    position: absolute;
    bottom: 0;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    color: #707d83;
    cursor: pointer;
    border-top: 2px solid #263238;
    background: #313c42;

    i.material-icons{
        margin-right: 16px;
    }

    & > div{
        flex: 1;
        text-align: left;
    }
}

.bg{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100vw;
    background: rgba(0,0,0,0.1);
}*/
</style>
