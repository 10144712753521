<script>
  import { createEventDispatcher } from 'svelte';
  import {default as filters, TYPE_TEXT, TYPE_MULTISELECT } from './Filters.store';
  import FilterSelection from './FilterSelection.svelte';
  import TextFilter from './TextFilter.svelte';
  import MultiSelectFilter from './FilterMultiSelect.svelte';

  const dispatchEvent = createEventDispatcher();

  function search(){
    dispatchEvent('change')
  }

</script>

<div class="Component">

  {#each $filters.active as filter}
    {#if filter.type === TYPE_TEXT}
      <TextFilter {filter} on:change />
    {:else if filter.type === TYPE_MULTISELECT}
      <MultiSelectFilter {filter}  on:change />
    {/if}
  {/each}

  <FilterSelection />

  <button class="rbButton asDarkMain CustomButton"
          data-icon="search"
          disabled="{!$filters.hasSetFilters || null}"
          on:click={() => search('change')}>
    Search
  </button>
</div>

<style lang="stylus">.Component {
  display: flex;
  align-items: center;
  background: #263238;
  border-radius: 16px 16px 0 0;
  flex: 1 1 100%;
  flex-wrap: wrap;
  margin-right: 15px;
  padding: 5px 5px 0;
}
.CustomButton {
  height: 26px;
  font-size: 13px;
  margin-bottom: 5px;
}
.CustomButton:before {
  width: 30px;
  font-size: 18px;
}
</style>