<script>
  import {createEventDispatcher} from 'svelte';

  export let
    position = 'START',
    style = { button: 'rbButton asDarkMain' },
    options=[{label: 'label', value: 'value'}];

  const dispatcher = createEventDispatcher();

  let
    elContainer,
    elMenu,
    menuShown = false,
    formattedOptions = options;

  $: updatePosition(elMenu)
  $: formattedOptions = formatOptions(options)

  function updatePosition(menuEl){
    if(!elContainer || !menuEl) return;
    const contRect = elContainer.getBoundingClientRect();
    if(position === 'START') {
      menuEl.style.top = `${contRect.top}px`;
      menuEl.style.left = `${contRect.left}px`;
    } else if (position === 'END') {
      menuEl.style.top = `${contRect.top}px`;
      menuEl.style.right = `calc(100% - ${contRect.right}px)`;
    }
  }

  function formatOptions(opts = []){
    return (!opts.length || typeof opts[1] !== 'string') ? opts : opts.map(v => ({value:v, label:v}));
  }
</script>

<div bind:this={elContainer}>
  <button class="{style.button}"
          on:focus={() => menuShown = true}
          on:blur={() => menuShown = false}
  ><slot>Menu</slot></button>
  {#if menuShown}
    <div class="Menu" bind:this={elMenu}>
      {#each formattedOptions as option}
        <div class="Option"
             on:mousedown={() => dispatcher('change', option.value)}
        >{option.label}</div>
      {/each}
    </div>
  {/if}
</div>

<style lang="stylus">.Menu {
  position: fixed;
  z-index: 10000;
  min-width: 150px;
  max-width: 420px;
  max-height: 400px;
  overflow: auto;
  background: #263238;
  border: 1px solid #000;
  box-shadow: 0 5px 24px 0 rgba(0,0,0,0.5);
  border-radius: 10px;
  font-size: 14px;
  color: #b0bec5;
  text-align: left;
}
.Option {
  height: 36px;
  line-height: 36px;
  padding: 0 15px;
  border-bottom: 1px solid #000;
  cursor: pointer;
}
.Option:hover {
  background: rgba(255,255,255,0.05);
  color: #fff;
}
</style>
