import { Route } from '@readybid-team/rb-spa-router';
import Mounter from '@readybid-team/rb-spa-router-svelte-mount';
import GatewayTimeoutPage from 'components/pages/GatewayTimeoutErrorPage.svelte';
import Home from './Home';

const gatewayTimeoutPage = new Route('GatewayTimeoutPage', '/error',
  Mounter(
    () => Promise.resolve({default: GatewayTimeoutPage} ),
    () => ({
      goToHome: () => { Home.go(); }
    })
  ));

export default gatewayTimeoutPage;
