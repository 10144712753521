<script>
  import { getContext, createEventDispatcher } from 'svelte';
  import mtOptions from 'src/assets/data/hotel-market-tiers';

  import InputText from 'components/molecules/inputs/InputText.svelte';
  import InputSabreCode from 'components/molecules/inputs/InputSabreCode.svelte';
  import InputPhoneDetails from 'components/molecules/inputs/InputPhoneDetails.svelte';
  import InputUrl from 'components/molecules/inputs/InputUrl.svelte';
  import InputEmail from 'components/molecules/inputs/InputEmail.svelte';
  import Select from 'components/molecules/inputs/SelectModel.svelte';
  import AddressInput from 'components/organisms/AddressInput/AddressInput.svelte';
  import BrandsSearch from 'src/pages/hotels/profile/modules/basic-info/dialogs/rebrand/BrandsSearch.formModel.svelte';
  import ImagePicker from 'components/molecules/ImagePicker/ImagePicker.svelte';
  import CoordinatesModel from '../../../CoordinatesModel.component.svelte'

  export let image, imageUrl, chainsOptions = [], hmcsOptions = [];

  const dispatch = createEventDispatcher();

  let formModel = getContext('formModel');

  const ratingOptions = [{value: 0, label: 'None'}, ...[1.0, 2.0, 3.0, 4.0, 5.0].map(v => ({value: v, label: v.toString(10)}) )];
  const marketTierOptions = [{value: '', label: 'None'}, ...mtOptions];

</script>

<div class="content">

  <InputText id="name" required="true" maxLength="100">Property Name</InputText>

  <div class="ImageContainer">
    <div>
      <ImagePicker bind:value={image} fileUrl="{imageUrl}" />
    </div>

    <div>
      <BrandsSearch id="brandId" name="Brand" brands="{chainsOptions}" required="{true}"></BrandsSearch>

      <Select id="hmcId" name="Hotel Management Company" options="{hmcsOptions}">Hotel Management Company</Select>
    </div>

  </div>

  <div class="twoColumns FormSplit">
    <div>
      <AddressInput></AddressInput>
    </div>

    <div class="CoordinatesContainer">
      <CoordinatesModel></CoordinatesModel>
    </div>
  </div>

  <div class="twoColumns FormSplit">
    <div class="layout-column">
      <InputPhoneDetails id="phone" required="true">Phone</InputPhoneDetails>

      <InputUrl id="website" maxLength="256">Website</InputUrl>

      <InputEmail id="emailAddress" maxLength="255">Email Address</InputEmail>

      <div class="twoColumns">
        <Select id="rating" options="{ratingOptions}">Star Rating</Select>

        <Select id="marketTier" options="{marketTierOptions}">Property Market Tier</Select>
      </div>
    </div>

    <div>
      <InputSabreCode id="sabrePropertyCode">Sabre Property Code</InputSabreCode>

      <div class="twoColumns">
        <InputText id="apolloChainCode" maxLength="2">Apollo Chain Code</InputText>
        <InputText id="apolloPropertyCode" maxLength="10">Apollo Property Code</InputText>
      </div>

      <div class="twoColumns">
        <InputText id="amadeusChainCode" maxLength="2">Amadeus Chain Code</InputText>
        <InputText id="amadeusPropertyCode" maxLength="10">Amadeus Property Code</InputText>
      </div>

      <div class="twoColumns">
        <InputText id="worldSpanChainCode" maxLength="2">WorldSpan Chain Code</InputText>
        <InputText id="worldSpanPropertyCode" maxLength="10">WorldSpan Property Code</InputText>
      </div>

      <InputText id="internalPropertyCode" maxLength="10">Internal Hotel Code</InputText>
    </div>
  </div>
</div>

<style lang="stylus">.ImageContainer {
  display: flex;
}
.ImageContainer div:first-of-type {
  flex: 0 0 auto;
  margin: 0 20px;
}
.ImageContainer div {
  flex: 1 1 100%;
}
.content {
  margin-top: 18px;
  padding: 0 15px;
  overflow: auto;
  flex: 1 1 auto;
}
.content :global(.rbInput) {
  margin-bottom: 15px;
}
.FormSplit {
  margin-top: 40px;
}
.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}
.CoordinatesContainer {
  height: 285px;
  width: 620px;
  box-sizing: border-box;
}
</style>
