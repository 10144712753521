<script>
  import CustomersSearch from './CustomersSearch.svelte';
  import RfpsSearch from './RfpsSearch.svelte';

  export let runReport = () => {}

  let model = { competitive: true }, invalid;
</script>

<form class="Form" on:submit|preventDefault="{() => runReport(model)}">

  <div class="InputContainer">
    <div class="Label"
         data-icon-before="{model.competitive ? 'check_box' : 'check_box_outline_blank'}"
         on:click={() => model.competitive = !model.competitive}
    >Show competitive</div>
  </div>

  <div class="InputContainer">
    <div class="Label">Start Date:</div>
    <input type="date" id="dateStart" required bind:value="{model.dateStart}" />
  </div>

  <div class="InputContainer">
    <div class="Label">End Date:</div>
    <input type="date" id="dateEnd" required bind:value="{model.dateEnd}" min="{model.dateStart ? model.dateStart : null}" />
  </div>

  <div class="InputContainer">
    <div class="Label">Select Customer: </div>
    <CustomersSearch bind:value={model.accountId} bind:name={model.accountName} />
    <input type="hidden" bind:value={model.accountId} required />
  </div>

  <div class="InputContainer">
    {#if model.accountId}
      <div class="Label"
           data-icon-before="{model.chargeable ? 'check_box' : 'check_box_outline_blank'}"
           on:click={() => model.chargeable = !model.chargeable}
      >Show only chargeable</div>
    {/if}
  </div>

  <div class="InputContainer SelectRfpContainer">
    {#if model.accountId}
      <div class="Label">Select RFP:</div>
      <RfpsSearch bind:value="{model.rfps}" account="{model.accountId}" />
    {/if}
  </div>

  <div class="Actions">
    <button class="rbButton asDark asDarkMain" disabled="{invalid}">Run Report</button>
  </div>
</form>

<style lang="stylus">.Label {
  line-height: 1.6;
}
.Label[data-icon-before] {
  cursor: pointer;
}
.Label[data-icon-before]:before {
  padding-right: 5px;
}
.Form {
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
}
.Form :global(input) {
  padding: 3px 5px;
  border: 1px solid #b0bec5;
  border-radius: 15px;
  background: #eceff1;
  font-family: "Noto Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #263238;
}
.Form :global(input):focus {
  inset: none;
}
.InputContainer {
  margin-bottom: 10px;
  flex: 0 0 auto;
}
.InputContainer.SelectRfpContainer {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.Actions {
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
}
</style>
