import {DateFormatter, DateTimeFormatter} from 'src/utils/internationalization.service';

const
  formatDateTime = DateTimeFormatter().format,
  formatDate = DateFormatter().format;

export function formatUsers(users){
  return users.map(user => ({
    ...formatUserDetails(user),
    accountsNo: user.userAccounts || 0
  }));
}

export function formatUser(user){
  return {
    ...formatUserDetails(user),
    profilePicture: user.profilePicture ? `https://www.readybid.net/images/users/${user.profilePicture}` : null,
    userAccounts: user.userAccounts.map(ua => formatUserAccount(ua))
  };
}

function formatUserDetails(user){
  return {
    id: user._id,
    name: `${user.firstName} ${user.lastName}`,
    emailAddress: user.emailAddress,
    emailAddressStatus: loadEmailAddressStatus(user.emailAddressStatus),
    phone: user.phone,
    lastActive: loadDateTime(user.lastActive),
    createdAt: user.created ? loadDate(user.created.at) : 'NA',
    status: loadUserStatus(user.status),
  };
}

function loadDateTime(d){
  if(!d) return '';
  const dt = new Date(d);
  return isNaN(dt.valueOf()) ? '' : formatDateTime(dt);
}

function loadDate(d){
  if(!d) return '';
  const dt = new Date(d);
  return isNaN(dt.valueOf()) ? '' : formatDate(dt);
}

function loadUserStatus(s){
  const at = loadDateTime(s.at);

  switch (s.value){
    case 'ACTIVE':
      return {value:s.value, label: 'Active', icon: 'person', at};
    case 'DELETED':
    case 'DISABLED':
      return {value:s.value, label: 'Disabled', icon: 'person_off', at, disabled: true};
    case 'UNDER_REVIEW':
      return {value:s.value, label: 'Under Review', icon: 'support_agent', at, warning: true};
    case 'PENDING_EMAIL_VERIFICATION':
      return {value:s.value, label: 'Email Address not verified', icon: 'email', at, warning: true};
    default:
      return {value:s.value, label: s.value};
  }
}

function loadEmailAddressStatus(eas = ''){
  switch (eas){
    case 'OK':
      return {value: eas.value, label: 'Emails Delivered', icon: 'verified', ok: true};
    case 'BOUNCED':
      return {value: eas.value, label: 'Emails Bounced', icon: 'call_missed_outgoing', bad: true};
    case 'COMPLAINT':
      return {value: eas.value, label: 'Complaint Received', icon: 'ring_volume', bad: true};
    default:
      return {value: eas.value, label: 'Unknown Deliverability', icon: 'question_mark'};
  }
}

export function formatUserAccount(ua){
  const status = loadUserAccountStatus(ua.status),
    adminStatus = loadUserAccountAdminStatus(status, ua.permission);

  return {
    id: ua._id,
    accountId: ua.accountId,
    entityId: ua.entityId,
    name: ua.accountName,
    type: loadEntityType(ua.entityType),
    address: ua.accountAddress,
    entityStatus: loadEntityStatus(ua),
    jobTitle: ua.jobTitle,
    status,
    adminStatus,
    createdAt: loadDate(ua.createdAt)
  };
}

export function loadEntityType(type){
  switch (type){
    case 'HOTEL': return 'Hotel';
    case 'CHAIN': return 'Chain';
    case 'HMC': return 'HMC';
    case 'COMPANY': return 'Company';
    case 'TRAVEL_AGENCY': return 'Agency';
    case 'TRAVEL_CONSULTANCY': return 'Consultancy';
    default: return type;
  }
}

function loadEntityStatus(userAccount){
  const entityStatus = userAccount.entityStatus;

  switch (entityStatus){
    case 'PENDING': return {entityStatus, label: 'Unverified', icon: 'gpp_maybe', status: 'WARNING'};
    case 'OBSOLETE': return {entityStatus, label: 'Closed', icon: 'gpp_bad', status: 'BAD'};
    case 'REJECTED': return {entityStatus, label: 'Rejected', icon: 'gpp_bad', status: 'BAD'};

    default:
      if(userAccount.entityType !== 'HOTEL' || entityStatus === 'ACTIVE' || entityStatus === 'PRELOADED'){
        return {entityStatus, label: 'Active', icon: 'verified_user', status: 'OK'};
      } else {
        return {entityStatus, label: entityStatus, icon: 'gpp_bad', status: 'BAD'};
      }
  }
}

function loadUserAccountStatus(s){
  const at = loadDateTime(s.at);

  switch (s.value){
    case 'ACTIVE':
      return {value:s.value, label: 'Active', icon: 'person', at, status: 'OK'};
    case 'DELETED':
      return {value:s.value, label: 'Disabled', icon: 'person_off', at, status: 'BAD'};
    case 'UNVERIFIED':
      return {value:s.value, label: 'Unverified', icon: 'support_agent', at, status: 'WARNING'};
    default:
      return {value:s.value, label: s.value};
  }
}

function loadUserAccountAdminStatus(userAccountStatus, permission){
 return userAccountStatus.status === 'OK'? (permission === 'ACCOUNT_ADMIN'? 'Admin': 'Manager'): undefined;
}
