import { getCurrentUser } from 'root/api/index.js';
import httpErrorHandler from 'root/api/HttpErrorHandler';

let currentUser = null;

export default {
  get() {
    return currentUser ? Promise.resolve(currentUser) : refresh();
  },
  refresh
};

function refresh() {
  return getCurrentUser().then(cu => {
    currentUser = cu;
    return currentUser;
  }, httpErrorHandler.handle);
}
