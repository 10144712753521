<script>
  import SInput from 'components/molecules/inputs/Input.svelte';

  export let list = [];

  let
    newEmail = '',
    newEmailError = '';

  $: newEmailError = getErrorMessage(newEmail);

  function remove(index){
    list.splice(index, 1);
    list = [...list];
  }

  function add(){
    if(!newEmailError && newEmail){
      // remove duplicate
      const ind = list.indexOf(newEmail);
      if(ind !== -1) list.splice(ind, 1);

      list = [...list, newEmail];
      newEmail = '';
    }
  }

  function getErrorMessage(emailAddress = ''){
    return !emailAddress || (/^\S+@\S+$/.test(emailAddress) && emailAddress.length <= 255) ? '' : `Invalid email address`
  }
</script>


<div class="NotifyContainer">
  <div>
    <span>Notify:</span>

    {#each list as email, index}
      <div class="EmailPill">
        {email}
        <button class="rbButton asDark iconOnlySmall"
                data-icon="remove_circle"
                on:click={() => remove(index)}
        ></button>
      </div>
    {/each}
  </div>

  <div class="AddForm">
    <SInput name="notifyEmail" bind:value={newEmail} error="{newEmailError}">
      Add email address to notify
    </SInput>

    <button class="rbButton asLarge asDarkMain"
            on:click={add}
            disabled="{!newEmail || newEmailError}"
    >Add</button>
  </div>
</div>


<style lang="stylus">.NotifyContainer {
  padding: 10px 20px 5px;
  font-size: 14px;
  color: #fff;
  border-bottom: 1px solid #808080;
}
.EmailPill {
  display: inline-block;
  padding: 0 2px 0 5px;
  margin: 3px 3px 0 0;
  border-radius: 20px;
  line-height: 20px;
  border: 1px solid #263238;
  background: #263238;
  color: #b0bec5;
}
.AddForm {
  display: flex;
  min-height: 60px;
}
.AddForm .rbButton {
  margin-top: 10px;
  margin-left: 20px;
}
</style>
