<script>
  import InputText from './InputText.svelte';
  import { getContext} from 'svelte';

  export let id;
  export let disabled;
  export let readonly;

  // setup validations
  export let required;

  const formModel = getContext('formModel');

  function onChange(ev){
    const
      eventId = ev.target.id,
      eventValue = ev.target.value,
      countryCode = getValue(eventId, eventValue, `${id}.country`) || '',
      cityCode = getValue(eventId, eventValue, `${id}.city`) || '',
      number = getValue(eventId, eventValue, `${id}.number`) || '',
      phoneNumber = countryCode && cityCode ? `+${countryCode}-${cityCode}-${number}` : `${countryCode}${cityCode}${number}`;

    formModel.setValue(eventId, eventValue);
    formModel.setValue(id, phoneNumber);

    function getValue(eId, eValue, qId){
      return eId === qId ? eValue : $formModel.values[qId];
    }
  }


</script>

<div>
  <div class="ComponentLabel">Phone Number (<span class="PhoneNumber">{$formModel.values.phone}</span>):</div>

  <div class="Layout">
    <div class="Cell">
      <InputText
        name="Country code"
        id="{`${id}.country`}"
        {disabled}
        {readonly}
        on="{[['input', onChange]]}"
        maxLength="6"
      >
        Country Code
      </InputText>
    </div>

    <div class="Cell">
      <InputText
        name="City code"
        id="{`${id}.city`}"
        {disabled}
        {readonly}
        on="{[['input', onChange]]}"
        maxLength="6"
      >
        City Code
      </InputText>
    </div>

    <div class="Cell L">
      <InputText
        name="Phone Number"
        id="{`${id}.number`}"
        {disabled}
        {readonly}
        {required}
        on="{[['input', onChange]]}"
        maxLength="10"
      >
        Phone Number
      </InputText>
    </div>
  </div>

</div>

<style lang="stylus">.ComponentLabel {
  color: #fff;
  line-height: 20px;
  margin-top: 40px;
  font-size: 13px;
  margin-left: 15px;
}
.Layout {
  display: flex;
  justify-content: space-between;
}
.Cell {
  flex: 0 0 26%;
}
.Cell.L {
  flex: 0 0 42%;
}
</style>
