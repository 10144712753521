<script>
    import Header from 'root/components/Header.svelte';
    import Navigation from 'root/components/Navigation.svelte';
    import SearchForm from './SearchForm.component.svelte';
    import Coordinates from 'components/molecules/coordinates/Coordinates.svelte';
    import CoordinatesModel from './CoordinatesModel.component.svelte';
    import SearchResultTable from './SearchResultTable.component.svelte';

    import FormModel from 'components/molecules/inputs/FormModel.svelte';
    import CreateForm from './CreateForm.controller.svelte';

    import { searchHotels } from '../../api/index.js';

    const searchProperties = createSearchPropertiesFunction();

    let
            addHotelView = false,
            searchModel = {},
            createModel = {
                'location.address.country': 'US',
                'location.coordinates.lat': 40.1451,
                'location.coordinates.lng': -99.668
            };

    $: searchResult = searchProperties(searchModel);

    function hide (node, { duration = 400 }) {
        const height = parseFloat(getComputedStyle(node).height);
        return {
            duration: duration,
            css: t => {
                return `max-height: ${height*t}px;`;
            }
        }
    }

    function createSearchPropertiesFunction(){
        let code,coordinates,results;

        return function({sabreCode, coordinates: newCoordinates}){
            const c = newCoordinates ? `${newCoordinates.lat},${newCoordinates.lng}` : undefined;
            if(code === sabreCode && c === coordinates) {
                return results || Promise.resolve([]);
            }

            code = sabreCode;
            coordinates = c;
            results = code || coordinates ? searchHotels({code, coordinates}).then(prepare) : Promise.resolve([]);

            return results;
        };

        function prepare(hotels){
            return hotels.reduce( (acc, hotel) => {
                hotel.distance = hotel.distance ? hotel.distance.toFixed(0) : hotel.distance;
                hotel.formattedBrand = `[${hotel.chain.code}] ${hotel.chain.name}`;
                hotel.formattedMaster = hotel.chain.master ? `[${hotel.chain.master.code}] ${hotel.chain.master.name}` : '';
                hotel.sabreCode === code ? acc.unshift(hotel) : acc.push(hotel);
                return acc;
            }, []);
        }
    }

</script>


<div class="rbPage rbDark">
    <Header></Header>
    <div class="pageContainer">
        <div class="rbCard navigation"><Navigation></Navigation></div>

        <div class="content">
            <FormModel bind:values={createModel}>
                <div class="inputsRow">
                    <div class="rbCard inputCard">
                        {#if addHotelView}
                            <CreateForm bind:addHotelView></CreateForm>
                        {:else}
                            <SearchForm bind:model="{searchModel}" bind:addHotelView></SearchForm>
                        {/if}
                    </div>

                    <div class="rbCard inputCard mapContainer">
                        {#if addHotelView}
                            <CoordinatesModel></CoordinatesModel>
                        {:else}
                            <Coordinates value="{searchModel.coordinates}" on:input={ ({detail}) => searchModel = { ...searchModel, coordinates:detail } } ></Coordinates>
                        {/if}
                    </div>
                </div>
            </FormModel>

            {#if !addHotelView}
                <div class="tableRow rbCard rbCardBottom" transition:hide>
                    <SearchResultTable {searchResult}></SearchResultTable>
                </div>
            {/if}
        </div>
    </div>
</div>

<style type="text/stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  padding: 7px;
  overflow: hidden;
}
.inputsRow {
  flex: 1 0 259px;
  display: flex;
  align-items: flex-start;
  transition: all 0.5s linear;
  overflow: hidden;
}
.inputCard {
  margin: 7px;
  flex: 1 1 100%;
  overflow: hidden;
  min-height: 245px;
  max-height: calc(100% - 16px);
  display: flex;
  flex-direction: column;
}
.mapContainer {
  align-self: stretch;
}
.tableRow {
  margin: 7px;
  margin-bottom: -7px;
  flex: 1 1 100%;
  max-height: 2000px;
  transition: all 0.5s linear;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
</style>
