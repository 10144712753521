<script>
  import DialogTemplate from 'components/modals/DialogTemplate.basic.svelte';

  export let
    hide,
    title = 'Error',
    message,
    error;

</script>

<div class="Component">
  <DialogTemplate cancel="{hide}" closeOnClick="true">
    <div class="Container">
      <div class="Title">
        {title}
      </div>

      {#if message}
        <div class="Message">{message}</div>
      {/if}

      {#if error}
        <div class="Message">Error message from server:</div>
        <pre class="Error">{error}</pre>
      {/if}
    </div>
  </DialogTemplate>
</div>

<style lang="stylus">.Component :global(.dialog) {
  background: #313c42;
  border-color: #313c42;
  overflow: hidden;
}
.Container {
  font-size: 14px;
  color: #cfd8dc;
/*min-height: 280px;*/
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.Title {
  padding: 5px 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
}
.Message {
  padding: 5px 10px;
  font-size: 13px;
  padding: 10px;
}
.Error {
  padding: 5px 10px;
  margin: 0 10px 15px;
  padding: 10px;
  font-size: 13px;
  color: #eceff1;
  border: 1px solid #eceff1;
  white-space: pre-wrap;
}
</style>
