import {sendCompetitiveBids} from 'src/api'
import httpNotification from 'src/api/HttpNotifications';
import noop from 'lodash/noop';
import modalsService from 'src/services/modals';
import SendBidsErrorsDialog from './../SendBidsErrorsDialog.svelte';

export default {
  isAvailable: bids => !!bids.length && !bids.find(b => b.destinationDisabled || ['COMPETITIVE_NEW', 'COMPETITIVE_SENT', 'COMPETITIVE_RECEIVED'].indexOf(b.stateStatus) === -1 ),
  icon: 'send',
  label: 'Send Bids',
  execute: sendBids
}

function sendBids(bidsIds, updateView) {
  httpNotification.handle(sendCompetitiveBids(bidsIds))
    .then(response => {
      if(Object.keys(response.errors).length > 0){
        modalsService.show(SendBidsErrorsDialog, {response})
          .catch(noop);
      }
      updateView(response.updates);
    })
    .catch(noop);
}