<script>
  import LabeledSelect from 'src/components/inputs/LabeledSelect.svelte';
  import httpNotifications from "src/api/HttpNotifications";
  import {exportLastCreatedHotels, exportLastResolvedUnvalidatedHotels} from 'src/api';

  const options = [
    {
      value: 'LAST_CREATED',
      label: 'Export Last 100 Created',
      execute: () => httpNotifications.handle(exportLastCreatedHotels(), {label: 'Exporting...'})
    },
    {
      value: 'LAST_RESOLVED',
      label: 'Export Last 200 Resolved',
      execute: () => httpNotifications.handle(exportLastResolvedUnvalidatedHotels(), {label: 'Exporting...'})
    },
  ];

  function handle(e){
    const op = options.find(o => o.value === e);
    op.execute();
  }

</script>

<div>
  <LabeledSelect label="Reports"
                 icon="inventory"
                 options={options}
                 on:change={ e => handle(e.detail) }
  />
</div>
