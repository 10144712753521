<script>
    import route from 'root/routes/EntityProfile';
    import accountTypes from 'root/assets/data/account-types';
    import Header from 'root/components/Header.svelte';
    import Navigation from './Navigation.component.svelte';
    import {currentModule} from './modules';
    import {loadEntity, getChain} from './services/entity.service';
    import {editLogo, validateEntities} from '../search/actions.service'

    const {params: {entityId, type, section}} = route.$createStateFromURL(window.location.hash);
    let entity, typeName, subType, Page;

    function refresh() {
        if(type === 'CHAIN') {
            getChain(entityId).then(h => {
                entity = h;
            });
        }else{
            loadEntity(type, entityId).then(h => {
                entity = h;
            });
        }
    }
    
    refresh();

    function updateLogo() {
        editLogo(entity).then(h => entity = h);
    }

    function activate() {
        validateEntities([entity]).then(refresh)
    }

    $: Page = currentModule(section);
    $: typeName = type !== "CHAIN"? accountTypes.find(({id}) => id === type).label: entity? (entity.subtype !== 'BRAND'? 'Master Chain': 'Brand'): 'Hotel Chain';
    $: subType = entity && entity.subtype;
</script>

<div class="rbPage rbDark">
    <Header />
    <div class="pageContainer">
        <Navigation {section} {typeName} {entityId} {type} lastUpdated={entity && entity.lastUpdated} subType={subType} />
        <div class="content">
            {#if entity}
                <svelte:component this="{Page}"  {section} {typeName} {type} bind:entity>
                    <button class="rbButton asDark" data-icon="add_a_photo" on:click={updateLogo}> Update Logo </button>
                    {#if !entity.active}
                        <button class="rbButton asDark" data-icon="check_circle" on:click={activate}> Validate </button>
                    {/if}
                </svelte:component>
            {/if}
        </div>
    </div>
</div>

<style type="text/stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.content :global(.section .title) {
  width: 100%;
  border-bottom: 1px solid #37474f;
  font-size: 14px;
  font-weight: normal;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.content :global(.main) {
  height: calc(100vh - 158px);
  overflow: auto;
}
.content :global(.main .section .content) {
  padding: 0 15px;
  font-size: 12px;
}
.content :global(.main .section .content .data) {
  margin: 10px 0;
  display: flex;
  align-items: center;
}
</style>