import ForbiddenPage from './ForbiddenPage.svelte';
import GatewayTimeoutErrorPage from './GatewayTimeoutErrorPage.svelte';
import NotFoundPage from './NotFoundPage.svelte';
import ServerErrorPage from './ServerErrorPage.svelte';
import UnauthorizedPage from './UnauthorizedPage.svelte';

export {
  ForbiddenPage,
  GatewayTimeoutErrorPage,
  NotFoundPage,
  ServerErrorPage,
  UnauthorizedPage,
}
