import adminServer from 'root/api/AdminServer';
import {checkAndExecute} from 'root/api/helpers';

export function searchAndSort(request) {
    return checkAndExecute(
        () => request,
        () => adminServer.put('hotel-directory-reports', request)
    )
}

export function changePaymentStatus(id, request) {
    return checkAndExecute(
        () => request,
        () => adminServer.put(`hotel-directory-reports/${id}/payment-status`, request)
    )
}