<script>

    /**
     *  If you get "Cannot read property 'subscribe' of undefined" error, you probably forgot to add parent FormModel.
     *
     *  Couldn't find the way to handle this gracefully, because it looks like that compiler changes order of execution
     *  in a way that both if(undefined) nor try..catch could not handle the empty value.
     */

    import { getContext, onDestroy } from "svelte";
    import Select from "./Select.svelte";
    import * as Validators from '../../validation/validators';

    export let name = "";
    export let id = name;
    export let disabled;
    export let readonly;
    export let iconStart;
    export let options;

    // setup focus fn
    export let focus;

    // setup validations
    export let required;

    let model;
    let formModel = getContext('formModel');
    formModel.addModel({ id, name, validations: createValidations() });

    onDestroy(() => { formModel.removeModel(id) });
    $: model = $formModel.models[id] || {};

    function onBlur(){
        formModel.touch(id)
    }

    function onInput(ev){
        formModel.setValue(id, ev.detail)
    }

    function createValidations(){
        const v = [];

        add(required, Validators.Required);
        add(createValidOptions(), Validators.In);

        return v;

        function add(config, validator){
            if(config){
                v.push(validator.create(config));
            }
        }

        function createValidOptions(){
          return options.length && Object.prototype.toString.call(options[0]) === '[object Object]'
            ? options.map( option => option.value)
            : options;
        }
    }
</script>

<Select {id}
        {options}
        value="{ model.value }"
        {iconStart}
        {readonly}
        {disabled}
        {required}
        errorMessage="{ model.touched ? model.errorMessage : '' }"
        bind:focus="{focus}"
        on:input="{onInput}"
        on:blur="{onBlur}">
    <slot />
</Select>

