<script>
  import SInput from "../inputs/Input.svelte";
  import Spinner from "../../atoms/Spinner.svelte";
  import { fade } from 'svelte/transition';
  import { createEventDispatcher } from 'svelte';

  export let
    icon = 'search',
    required = false,
    optionsPromise = Promise.resolve([]),
    onSearch = async (query = '', optionsPr) => {
      const
        options = await optionsPr,
        q = query.toLowerCase();
      return Promise.resolve(q.length ? options.filter(o => o.label.toLowerCase().includes(q)) : options)
    },
    onSelect = item => {console.log('SELECTED', item)},
    selected,
    error;

  let
    focused,
    query = '',
    filterPromise = Promise.resolve([]),
    options = [];

  $: filterPromise = onSearch(query, optionsPromise)

  const
    dispatch = createEventDispatcher(),
    inputElementListeners = [
      ['focus', () => focused = true ],
      ['blur', () => {
        focused = false;
        select(selected);
        query = '';
      }],
      ['input', ev => { query = ev.target.value; }]
    ];

  function select(item){
    selected = item;
    onSelect(item);
  }

</script>

<div class="SearchSelect">
  <SInput iconStart="{icon}"
          {required}
          value={selected ? selected.label : ''}
          on={inputElementListeners}
          {error}
  >
    <slot>
      <div>
        Search
        {#await filterPromise}
          <div class="spinner"><Spinner /></div>
        {:then options}
          <span></span>
        {/await}
      </div>
    </slot>
  </SInput>

  {#if focused}
    <div transition:fade="{{ duration: 100 }}" class="Menu">
      {#await filterPromise}
        <div class="menuSpinner">
          <Spinner />
        </div>

      {:then options}
        {#each options as item}
          <div class="Item"
               class:ItemSelected="{selected && item.value === selected.value}"
               on:mousedown="{() => select(item)}">
            {item.label}
          </div>
        {:else}

          <div class="emptyResults rbText">
            <slot name="onEmpty">
              <span>We are unable to find a match for your query.</span>
            </slot>
          </div>
        {/each}

      {:catch e}
        <div class="rbError">{e}</div>
      {/await}
    </div>
  {/if}
</div>

<style lang="stylus">.Menu {
  position: absolute;
  z-index: 10;
  margin-top: 3px;
  padding: 5px 0;
  max-height: 200px;
  overflow-y: auto;
  background: #263238;
  border: 1px solid #1a2226;
  border-radius: 5px;
  opacity: 1;
}
.Item {
  font-size: 14px;
  line-height: 33px;
  padding: 0 30px 0 15px;
  cursor: pointer;
  text-align: left;
  color: #90a4ae;
  white-space: nowrap;
  background: transparent;
}
.Item:hover {
  background: #313c42;
}
.ItemSelected {
  color: #fff;
  background: #313c42;
  cursor: default;
}
.menuSpinner {
  padding: 15px 50px;
  transform: scale(0.5, 0.5);
}
.emptyResults {
  padding: 15px;
  color: #fff;
}
.spinner {
  display: inline-block;
  vertical-align: middle;
}
.spinner :global(svg) {
  height: 12px;
  width: 12px;
}
</style>
