<script>
    import Header from 'root/components/Header.svelte';
    import Navigation from 'root/components/Navigation.svelte';
    import FormModel from 'components/molecules/inputs/FormModel.svelte';
    import CreateForm from './CreateForm.controller.svelte';
    import CoordinatesModel from './CoordinatesModel.component.svelte';

    import EntitiesAdd from 'root/routes/EntitiesAdd.js';
    import accountTypes from '../../../assets/data/account-types';

    let {params: {type}} = EntitiesAdd.$createStateFromURL(window.location.hash),
        name = accountTypes.find(({id}) => id === type).label;


    let createModel = {
        'location.address.country': 'US',
        'location.coordinates.lat': 40.1451,
        'location.coordinates.lng': -99.668
    };
</script>


<div class="rbPage rbDark">
    <Header></Header>
    <div class="pageContainer">
        <div class="rbCard navigation"><Navigation></Navigation></div>
        <div class="content">
            <FormModel bind:values={createModel}>
                <div class="inputsRow">
                    <div class="rbCard inputCard container">
                        <CreateForm {name} {type} />
                    </div>

                    <div class="rbCard inputCard container">
                        <CoordinatesModel></CoordinatesModel>
                    </div>
                </div>
            </FormModel>
        </div>
    </div>
</div>

<style type="text/stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  padding: 7px;
  overflow: hidden;
}
.inputsRow {
  flex: 1 0 259px;
  display: flex;
  align-items: flex-start;
  transition: all 0.5s linear;
  overflow: hidden;
}
.inputCard {
  margin: 7px;
  flex: 1 1 100%;
  overflow: hidden;
  min-height: 245px;
  max-height: calc(100% - 16px);
  display: flex;
  flex-direction: column;
}
.container {
  align-self: stretch;
}
</style>

