<script>
    import FormModel from 'components/molecules/inputs/FormModel.svelte';
    import NotificationTemplate from 'components/modals/NotificationTemplate.basic.svelte';

    import EditForm from './Create.form.svelte';
    import {getModelFromObect} from 'root/utils';

    export let hide, cancel, user = {};

    let values = getModelFromObect({...user, root: user.root? "root": "false"}), isNew = !user._id && !user.id;
</script>

<NotificationTemplate>
    <FormModel bind:values>
        <div class="container">
            <div class="header">
                <div class="title">
                    {isNew? 'Create New Admin User': 'Edit Admin User'}
                </div>
            </div>

            <EditForm {hide} {cancel} {user} {isNew} />
        </div>
    </FormModel>
</NotificationTemplate>

<style type="text/stylus">.container {
  padding: 25px 15px 20px;
}
.header {
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 14px;
  font-weight: bold;
}
</style>
