import adminServer from 'root/api/AdminServer';
import {checkAndExecute} from 'root/api/helpers';

const emailAddressIgnoreListURI = '/email-address-ignore-list';

export function list() {
  return adminServer.get(emailAddressIgnoreListURI);
}

export function add(emailAddress) {
  return checkAndExecute(
    () => emailAddress,
    () => adminServer.put(emailAddressIgnoreListURI, {emailAddress})
  );
}

export function remove(id){
  return checkAndExecute(
    () => id,
    () => adminServer.axios.delete(emailAddressIgnoreListURI, {params: {id}})
  );
}
