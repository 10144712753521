export function create(config = {}, values = {}, context) {
  const model = Object.assign(
    {
      name: '',
      alias: '',

      dirty: false,
      pristine: false,

      touched: false,
      untouched: true,
    },

    loadConfig(config, values),
  );

  return validate(model, values, context);
}

function loadConfig(config, values){
  const
    id = config.id || config.name,
    value = values[id],
    validations = (config.validations || []).reduce( (acc, v) => {
      (v.isDependencyModified ? acc.dependencyValidations : acc.validations).push(v);
      return acc;
    }, {
      validations: [],
      dependencyValidations: []
    });

  if(!id) {
    throw new Error('Cannot create Input Model without id or name!');
  }

  return {
    ...config,
    id,
    value,
    ...validations,
  }
}

export function touch(model){
  return {...model, touched: true, untouched: false};
}
export function untouch(model){
  return {...model, touched: false, untouched: true};
}

export function setValue(model, value, values, context){
  if(value === model.value){
    return model;
  }

  else {
    const
      newModel = {
        ...model,
        value: value,
        dirty: true,
        pristine: false,
      };

    return validate(newModel, values, context);
  }
}

export function stabilize(model, values, context){
  return validate(model, values, context);
}

function validate(model, values, context){
  const {validations, dependencyValidations} = model;
  return $validate(model, values, context, [...validations, ...dependencyValidations]);
}

function $validate(model, values, context, validations){
  const
    {id, name, alias, value} = model,
    fieldTitle = alias || name || id,
    errors = {};

  let errorMessage = '';

  for( let i = 0, l = validations.length; i<l; i++ ){
    const validation = validations[i],
      result = validation.isInvalid(value, values, context, fieldTitle);

    if(result){
      errors[result.id] = result;
      errorMessage = errorMessage || result.message;
    }
  }

  const valid = !errorMessage;

  return {
    ...model,
    errors,
    errorMessage,
    valid,
    invalid: !valid
  }
}
