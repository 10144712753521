<script>
    import NotificationTemplate from 'components/modals/NotificationTemplate.basic.svelte';

    export let hide, cancel, hotels, hotelManagementCompanies = [];

    let hotelManagementCompany, companies = [];

    $: companies = [...hotelManagementCompanies].sort((a,b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'accent' }))
</script>

<NotificationTemplate>
  <div class="container">
    <div class="header">
      <div class="headerContent">
        <div class="title">Change Hotel Management Company for {hotels.length === 1? hotels[0].name: `these ${hotels.length} hotels`}</div>
      </div>
    </div>

    <span class="subtitle">Select the new hotel management company from the list below</span>
    <div class="content">
      {#each companies as company}
        <div class="company" class:selected={hotelManagementCompany === company.id} on:click={() => hotelManagementCompany = company.id}>
          {company.name}
        </div>
      {/each}
    </div>

    <div class="footer">
        <button class="rbButton asModal" on:click="{cancel}">Cancel</button>
        <button class="rbButton asModalMain" data-icon="save" on:click="{() => hide({hotelManagementCompany})}" disabled={!hotelManagementCompany}> Apply </button>
    </div>
  </div>
</NotificationTemplate>

<style type="text/stylus">.container {
  padding: 25px 15px 20px;
}
.header {
  display: flex;
  flex-direction: row;
}
.headerContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  font-size: 15px;
  vertical-align: text-bottom;
  color: #dcdee0;
  text-shadow: 1px 0 0 rgba(0,0,0,0.15);
  font-weight: bold;
  padding: 0;
  margin: 0;
}
.subtitle {
  margin: 0 0 25px;
  color: #b0bec5;
  font-size: 13px;
  display: block;
}
.content {
  margin: 0 0 30px;
  font-size: 13px;
  line-height: 16px;
  color: #cfd8dc;
  max-height: 333px;
  overflow: auto;
  border: 1px solid #f0f0f0;
}
.company {
  padding: 10px;
  cursor: pointer;
}
.company:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}
.company.selected {
  background-color: #f0f0f0;
  position: sticky;
  top: 0;
  bottom: 0;
}
.footer {
  text-align: right;
}
</style>
