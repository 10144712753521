<script>
    export let module, index, modulesCount, editing;
    const modules = new Array(modulesCount);
</script>

<div id={module.id} class="module">
    <div class="header">
        {#each modules as _, i}
            {#if i === index}
                <div class="header-module open">
                    {i+1}. {module.name}
                </div>
            {:else}
                <div class="header-module">
                    {i+1}
                </div>
            {/if}
        {/each}
    </div>
    <div class="sections">
        {#each module.cells as section, i}
            <div id={section.id} class="module-section" class:editing>
                <div class="title">
                    {index + 1}.{i+1}. {section.name}                
                </div>
                <div class="questions">
                    {#each section.cells as question}
                        <slot name="question" {question} />
                    {/each}
                </div>
            </div>
        {/each}
    </div>
</div>

<style type="text/stylus">.module {
  padding: 15px;
}
.header {
  border-radius: 5px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #cfd8dc;
/*position: sticky;
    top: 5px;
    z-index: 1;*/
}
.header-module {
  min-width: 30px;
  color: #455a64;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  text-align: center;
}
.header-module.open {
  flex: 1;
  text-align: left;
  font-weight: bold;
}
.header-module:not(:last-child) {
  border-right: 1px solid #fff;
}
.sections {
  padding: 25px 0;
}
.module-section {
  position: relative;
  margin-top: 20px;
  padding: 15px 5px;
}
.module-section.editing {
  border-top: 1px solid #eceff1;
}
.module-section .title {
  position: absolute;
  background: #fff;
  top: -15px;
  left: 0;
  padding: 3px 5px;
  color: #455a64;
}
.questions {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}
.editing .questions {
  align-items: flex-start;
}
</style>
