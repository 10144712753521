import uniqueId from 'lodash/uniqueId';
import {TYPE_MULTISELECT} from './Filters.store';

export default function FilterMultiSelect({label, test = () => {}, value = [], options = () => []}){
  this.id = uniqueId();
  this.type = TYPE_MULTISELECT;
  this.label = label;
  this.options = options;
  this.value = Array.isArray(value) ? value : [];
  this.test = test;
  this.isEmpty = !this.value.length;

  this.setValue = (val = []) => {
    const newValue = Array.isArray(val) ? val : this.value;
    return ({
      ...this,
      value: newValue,
      isEmpty: !newValue.length,
    });
  };
}