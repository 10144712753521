import {
  resendInvitation,
  editRep,
  grantAdminStatusToUsers,
  revokeAdminStatusToUsers
} from 'root/pages/hotels/profile/modules/reps/actions.service';
import {deleteReps, deactivateReps, activateReps} from './actions.service';

const actions = [
    {
        label: 'Edit',
        icon: 'edit',
        id: 'edit',
        isAvailable([rep, ..._]) {
            return _.length === 0 && rep.invitation
        },
        action: (entity, [user]) => editRep(entity, {...user, role: user.role.permission}),
        primary: true,
    },
    {
        label: 'Delete',
        icon: 'delete',
        id: 'delete',
        isAvailable() {
            return true;
        },
        primary: true,
        action: deleteReps
    },
    {
        label: 'Deactivate',
        icon: 'not_interested',
        id: 'deactivate',
        isAvailable(reps) {
            return reps.every(({invitation, active}) => !invitation && active);
        },
        primary: true,
        action: deactivateReps
    },
    {
        label: 'Activate',
        icon: 'done',
        id: 'activate',
        isAvailable(reps) {
            return reps.every(({invitation, active}) => !invitation && !active);
        },
        primary: true,
        action: activateReps
    },
    {
        label: 'Resend Invite',
        icon: 'email',
        id: 'resendInvite',
        isAvailable(reps) {
            return reps.every(({invitation}) => invitation);
        },
        action: (hotel, reps) => resendInvitation(hotel.id, reps.map(({id}) => id))
    },
    {
        label: 'Grant Admin Status',
        icon: 'star',
        id: 'grantAdmin',
        isAvailable: allSelectedMustBelongToTheSameEntityAndHaveRole('ACCOUNT_MANAGER'),
        action: (hotel, reps) => grantAdminStatusToUsers(reps)
    },
    {
        label: 'Revoke Admin Status',
        icon: 'star_outline',
        id: 'revokeAdmin',
        isAvailable: allSelectedMustBelongToTheSameEntityAndHaveRole('ACCOUNT_ADMIN'),
        action: (hotel, reps) => revokeAdminStatusToUsers(reps)
    },
];

function allSelectedMustBelongToTheSameEntityAndHaveRole(role){
  return function(reps) {
    if(!reps.length) return false;
    const entityId = reps[0].entityId;
    return reps.every( rep => rep.entityId === entityId && rep.role.permission === role );
  }
}

export function getActions(reps) {
    return reps && reps.length > 0? actions.filter(({isAvailable}) => isAvailable(reps)): [];
}
