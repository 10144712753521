import countries from 'components/assets/data/countries.js';
import states from 'components/assets/data/states';
import Notification from 'components/modals/Notification.basic.svelte';

import httpErrorHandler from 'root/api/HttpErrorHandler';
import HttpNotification from 'root/api/HttpNotifications'
import modals from 'root/services/modals';

import EditHotelImageDialog from './dialogs/EditImage.dialog.svelte';
import ChangeHMC from './dialogs/ChangeHMC.dialog.svelte'
import getFilters from './filters/filter-types';
import { listBrands, advancedHotelSearch, listHMCs } from 'root/api/index.js';
import entityProfile from 'root/api/entityProfile.js';

function loadFilterTypes(HMCs) {
    return listBrands().then(brands => getFilters(
        countries.map(({id: value, name: label}) => ({value, label})),
        states.map(({country, id, label}) => ({value: id, label: `${country} - ${label}`})),
        brands.map(format),
        getChains(brands),
        HMCs.map(hmc => ({label: hmc.name, value: hmc.id}))
    ));

    function getChains(brands) {
        return Object.values(brands.reduce((acc, {master}) => {
            return !master || acc[master.id]? acc: {...acc, [master.id]: master}
        }, {})).map(format);
    }

    function format({name, code, id: value}) {
        return {
            value,
            label: `${code} - ${name}`
        }
    }
}

function loadHMCs() {
  return listHMCs();
}

function searchHotels(collection, filters, and, page, perPage = 100) {
    const request = {
        collection,
        filters: filters.reduce((acc, {id, value}) => hasValue(value)? ({...acc, [id]: extractValue(value)}): acc, {}),
        and,
        page,
        perPage,
    };

    function hasValue(value) {
        return Array.isArray(value)? value.length > 0: !!value;
    }

    return advancedHotelSearch(request).catch(httpErrorHandler.handle);
}

export function extractValue(value) {
    if(Array.isArray(value)) {
        return value.map(extractValue);
    }else if(typeof value === 'object') {
        return value.value;
    }else{
        return value;
    }
}

function editImage(hotel) {
    return modals.show(EditHotelImageDialog, {hotel})
        .then(({id, image}) => HttpNotification.handle(entityProfile.setImage(id, hotel.type, image)))
        .then(url => {
            modals.show(Notification, { title: 'Saved!', subtitle:'Property Image', content: 'Property image was updated successfully!', resolveLabel: 'OK' })

            hotel.image = hotel.image? {
                ...hotel.image,
                url
            }: {url};

            return hotel;
        });
}


function changeHotelGroup(hotels, hotelManagementCompanies) {
  return modals.show(ChangeHMC, {hotels, hotelManagementCompanies})
    .then(({hotelManagementCompany}) => HttpNotification.handle(entityProfile.changeHotelGroup(hotels.map(({_id, id}) => id || _id), hotelManagementCompany)))
    .then(() => modals.show(Notification, { title: 'Success', subtitle:'Hotel Management Group Updated', content: 'Your change to the hotel management group was successful.', resolveLabel: 'OK' }));
}


export {
    loadFilterTypes,
    loadHMCs,
    searchHotels,
    editImage,
    changeHotelGroup
}

