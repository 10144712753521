<script>
  import Header from 'root/components/Header.svelte';
  import Navigation from 'root/components/Navigation.svelte';
  import InputText from 'components/molecules/inputs/InputText.svelte';
  import FormModel from 'components/molecules/inputs/FormModel.svelte';
  import {list, add, remove} from './emailAddressIgnoreListApi.js';
  import modalsService from 'src/services/modals';
  import ErrorsModal from 'root/components/ErrorsModal.svelte';

  let
    loaded = false,
    model = { emailAddress: '' },
    invalid = false,
    emailAddresses = [],
    setValue,
    untouchAll,
    touchAll;


  list()
    .then(r => {
      emailAddresses = r.data;
      loaded = true;
    })
    .catch(e => {
      modalsService.show(ErrorsModal, {
        title: 'Error Loading Ignore List',
        error: e && e.data && e.data.message || null,
      });
    })

  function addItem(){
    const emailAddress = model.emailAddress;
    untouchAll();
    setValue('emailAddress', '');

    add(emailAddress)
      .then((r) => {
        emailAddresses = [r.data, ...emailAddresses];
      })
      .catch((e) => {
        modalsService.show(ErrorsModal, {
          message: `'${emailAddress}' was not added!`,
          error: e && e.data && e.data.errors && e.data.errors[0] && e.data.errors[0].defaultMessage || null,
        });
        setValue('emailAddress', emailAddress);
        touchAll();
      })
  }

  function removeItem(item){
    const originalList = emailAddresses;
    emailAddresses = emailAddresses.filter(ea => ea !== item);
    remove(item._id)
      .catch(e => {
        modalsService.show(ErrorsModal, {
          message: `'${item.emailAddress}' was not removed!`,
          error: e && e.data && e.data.message || null,
        });
        emailAddresses = [...originalList];
      })
  }
</script>

<div class="rbPage rbDark">
  <Header></Header>
  <div class="pageContainer">
    <div class="rbCard navigation"><Navigation></Navigation></div>

    <div class="content">
      <div class="heading">
        <div class="column page-label">
          <i class="material-icons page-icon">notifications_paused</i>
          <div class="labels">
            <h1 class="label-title">Email Address Ignore List</h1>
          </div>
        </div>
        <div class="ActionsContainer">
          <FormModel bind:values={model} bind:invalid bind:setValue bind:untouchAll bind:touchAll>
            <form class="Form" on:submit|preventDefault="{addItem}">
              <InputText id="emailAddress" name="Email Address or pattern" maxLength="256" required>Email Address to ignore</InputText>
              <button class="rbButton asDark asDarkMain iconOnlySmall" data-icon="add" disabled="{invalid}"></button>
            </form>
          </FormModel>
        </div>
      </div>
      <div class="table-header"></div>
      <div class="ListContainer">
        <div class="List">
          {#if loaded}
            {#each emailAddresses as item}
              <div class="ListItem">
                <div class="ListItemText">
                  {item.emailAddress}
                </div>
                <button
                  class="rbButton asDark iconOnlySmall ListItemButton"
                  data-icon="close"
                  on:click={() => removeItem(item)}
                ></button>
                <div class="rbTooltip">
                  <div>Email Address: <strong>{item.emailAddress}</strong></div>
                  {#if item.by}<div>Added by: <strong>{item.by}</strong></div>{/if}
                  {#if item.date}<div>Added on: <strong>{item.date}</strong></div>{/if}
                </div>
              </div>
            {:else}
              <div class="flex-center">Ignore list is empty!</div>
            {/each}
          {:else}
            <div class="flex-center">Loading...</div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="stylus">.pageContainer {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
}
.navigation {
  flex: 0 0 auto;
  border-radius: 0 6px 0 0;
  margin-top: -17.5px;
}
.content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #313c42;
}
.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.page-label {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
i.material-icons.page-icon {
  color: #00b8ab;
  height: 40px;
  width: 40px;
  background: #273238;
  border-radius: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.labels {
  margin-left: 10px;
}
h1.label-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  color: #fff;
}
.table-header {
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-image: linear-gradient(to right, #00a995 0%, #006eb9 50%, #00aa4f 100%) 0 1 100%;
}
.Form {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  min-height: 52px;
}
.Form :global(.rbInput .container) {
  margin-top: 0;
  border-radius: 18px 0 0 18px;
  width: 250px;
}
.Form .iconOnlySmall {
  height: 34px;
  width: 34px;
  border-radius: 0 18px 18px 0;
  margin-left: -1px;
  border: 0;
}
.Form .iconOnlySmall:before {
  padding-right: 3px;
}
.ListContainer {
  overflow: auto;
}
.List {
  width: 90%;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
}
.ListItem {
  height: 30px;
  width: 260px;
  background: transparent;
  border: 1px solid #263238;
  color: #b0bec5;
  transition: all 0.1s linear;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 18px;
  padding-left: 15px;
  margin-bottom: 15px;
  margin-right: 50px;
}
.ListItemText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ListItemButton {
  flex: 0;
  border-radius: 0 18px 18px 0;
}
</style>
