<script>
    /**
     *  If you get "Cannot read property 'subscribe' of undefined" error, you probably forgot to add parent FormModel.
     *
     *  Couldn't find the way to handle this gracefully, because it looks like that compiler changes order of execution
     *  in a way that both if(undefined) nor try..catch could not handle the empty value.
     */

    import { getContext, onDestroy } from "svelte";
    import * as Validators from '../../validation/validators';

    export let id;
    export let name;
    export let on = [];

    export let required;
    export let maxLength;
    export let minLength;
    export let pattern;

    let model;
    let formModel = getContext('formModel');
    formModel.addModel({ id, name, validations: createValidations() });

    on.push([ 'blur', () => { formModel.touch(id) }]);
    on.push([ 'input', ev => { formModel.setValue(id, ev.target.value) }]);
    onDestroy(() => { formModel.removeModel(id) });

    $: model = $formModel.models[id] || {};

    function createValidations(){
        const v = [];

        add(required, Validators.Required);
        add(maxLength, Validators.MaxLength);
        add(minLength, Validators.MinLength);
        add(pattern, Validators.Pattern);

        return v;

        function add(config, validator){
            if(config){
                v.push(validator.create(config));
            }
        }
    }
</script>

<slot error="{ model.touched ? model.errorMessage : '' }" value={model.value}></slot>
